import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { CheckCircle, CloseRounded as CloseRoundedIcon, Info as InfoIcon } from "@mui/icons-material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Accordion, AccordionDetails, AccordionSummary, Backdrop, Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Snackbar, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { BaselineYears, ProjectYears, SubmitButton, UploadedDocument } from "../../styles";

import BackHomeComponent from "../general/BackHomeComponent";
import LivestockNoDocumentsComponent from "../LivestockNoDocumentsComponent";
import OutFarmLivestockComponent from "../OutFarmLivestockComponent";


import { updateFarmVerificationDocuments } from "../../actions/verificationDocuments";

import { setCurMonitoringData, updateCattleAmount, updateChickenAmount, updateDairyCowsAmount, updateDucksAmount, updateGoatsAmount, updateGrazingManagement, updateSheepAmount, updateSwineAmount, updateTurkeysAmount } from "../../actions/currentMonitoringData";
import { updateMonitoringData } from "../../actions/monitoringData";


import FieldTitleWithInfo from "../general/FieldTitleWithInfo";
import NumericInput from "../general/NumericInput";
import { inFarmTempObj, outFarmTempObj } from "../general/utils/emptyTempDataObjects";
import { handleDeleteFile, handleDownload } from "../general/utils/fileOperations";
import { interdependentError } from "../general/Validations/fieldValueErrors";
import { max } from "../general/Validations/minMaxValues";
import { showExpectedValue } from "../general/Validations/validateValues";
import { cattle, chicken, goat, sheep, sow } from "./categoryArrays";
import CommonCategories from "./CommonCategories";


const InFarmLivestockComponent = () => {

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const accountSettings = useSelector((state) => state.accountSettingsReducer);
  const currentMonitoringDataObject = useSelector((state) => state.currentMonitoringDataReducer);
  const currentFarmVerificationDocuments = useSelector((state) => state.farmVerificationDocumentsReducer);
  const currentFarmDataObject = useSelector((state) => state.currentFarmDataReducer);
  const currentMonitoringYearsObject = useSelector(state => state.monitoringYearsTrackerReducer);

  const monitoringYears = currentMonitoringYearsObject.allMonitoringYears
  const isAdmin = accountSettings?.[0]?.role === 'admin'
  const allowEditing = isAdmin
  const animalsArray = ['CATTLE', 'DAIRY COWS', 'SWINE', 'SHEEP', 'GOATS', 'CHICKEN', 'TURKEYS', 'DUCKS']

  const [currentDocumentComment, setCurrentDocumentComment] = useState("");
  const [currentDocumentYear, setCurrentDocumentYear] = useState("");
  const [currentDocumentFolder, setCurrentDocumentFolder] = useState("");
  const [currentDocumentIndex, setCurrentDocumentIndex] = useState("");
  const [confirmPopUpOpen, setConfirmPopUpOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;
  const [showLoader, setShowLoader] = useState(false);
  const [saveCurrentDataFlag, setSaveCurrentDataFlag] = useState(false);
  const [expansionPanel, setExpansionPanel] = useState(Array(animalsArray.length).fill(false))

  const expansionPanels = () => {

    let baselineYear = Number(currentMonitoringDataObject.projectStartYear) - 3;
    let baselineIndex = arrDocumentAndYearsMapping[baselineYear];

    let monitoringDataObj = JSON.parse(JSON.stringify(currentMonitoringDataObject));

    const monitoredYears = monitoringDataObj.yearlyFarmData.splice(baselineIndex, monitoringDataObj.yearlyFarmData.length);

    animalsArray.forEach((animal, i) => {
      // Create an empty array for each animal
      let allYearsSpecieAmounts = [];

      monitoredYears.forEach((year) => {
        // Safely access the amounts and push them to the array
        if (year?.livestock?.inFarm[i]?.category) {
          year.livestock.inFarm[i].category.forEach((e) => {
            allYearsSpecieAmounts.push(e.amount);
          });
        }
      });

      // Check if there are any positive amounts
      const hasPositiveAmounts = allYearsSpecieAmounts.some((amount) => amount > 0);

      // Update the expansion panel state
      setExpansionPanel(prevState =>
        prevState.map((v, index) => (index === i ? hasPositiveAmounts : v))
      );
    });

  };

  const handleBackdropClose = () => {
    setSaveCurrentDataFlag(false);
  };

  const handleBackdropClickCaptureEvent = (e) => {
    e.stopPropagation();
    return false;
  };

  const handleSnackbarClose = () => {
    setState({ vertical: "top", horizontal: "center", open: false });
  };

  const handleConfirmPopUpOpen = () => {
    setConfirmPopUpOpen(true);
  };

  const handleConfirmPopUpClose = (event, reason) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    setConfirmPopUpOpen(false);
  };

  const arrDocumentAndYearsMapping = {
    2018: 0,
    2019: 1,
    2020: 2,
    2021: 3,
    2022: 4,
    2023: 5,
    2024: 6,
    2025: 7,
    2026: 8,
    2027: 9,
    2028: 10
  };

  const projectYearsWidth = {
    4: '250px',
    5: '520px',
    6: '787px',
  };

  const saveVerificationDocumentComment = () => {
    let tempObj = currentFarmVerificationDocuments;

    tempObj.yearlyVerificationDocuments[currentDocumentYear][currentDocumentFolder][currentDocumentIndex].comment = currentDocumentComment;

    dispatch(updateFarmVerificationDocuments(currentFarmVerificationDocuments._id, tempObj))
      .then((res) => {
        setCurrentDocumentComment("");
        setCurrentDocumentYear("");
        setCurrentDocumentFolder("");
        setCurrentDocumentIndex("");
      })
      .catch((error) => {
        console.error('Error updating farm verification documents:', error);
      });

    setConfirmPopUpOpen(false);
  };

  const checkLivestock = () => {
    let monitoringDataObj = JSON.parse(JSON.stringify(currentMonitoringDataObject));

    monitoringDataObj.yearlyFarmData.map((year, index) => {
      if (year?.livestock?.inFarm?.length === 0) {
        monitoringDataObj.yearlyFarmData[index].livestock.inFarm = inFarmTempObj;
      };
      if (year?.livestock?.outFarm?.length === 0) {
        monitoringDataObj.yearlyFarmData[index]?.livestock?.outFarm.push(outFarmTempObj);
      };
    });

    dispatch(setCurMonitoringData(monitoringDataObj))
      .then((res) => {
        return dispatch(updateMonitoringData(currentMonitoringDataObject._id, monitoringDataObj));
      })
      .catch((error) => {
        console.error('Error setting or updating monitoring data:', error);
      });
  };

  const handleGrazingManagementUpdate = (year, value) => {
    let tempData = {
      year: year,
      data: value
    };
    dispatch(updateGrazingManagement(tempData))
      .then((res) => {
        console.log('Grazing management updated successfully:', res);
      })
      .catch((error) => {
        console.error('Error updating grazing management:', error);
      });
  };

  const handleCattleUpdate = (year, category, value) => {

    let tempData = {
      year: year,
      category: category,
      data: value
    };

    dispatch(updateCattleAmount(tempData))
  };

  const handleDairyCowsUpdate = (year, value) => {

    let tempData = {
      year: year,
      data: value
    };

    dispatch(updateDairyCowsAmount(tempData))
  };

  const handleSwineUpdate = (year, category, value) => {

    let tempData = {
      year: year,
      category: category,
      data: value
    };

    dispatch(updateSwineAmount(tempData))
  };

  const handleSheepUpdate = (year, category, value) => {
    let tempData = {
      year: year,
      category: category,
      data: value
    };

    dispatch(updateSheepAmount(tempData))
  };

  const handleGoatsUpdate = (year, category, value) => {
    let tempData = {
      year: year,
      category: category,
      data: value
    };
    dispatch(updateGoatsAmount(tempData))
  };

  const handleChickenUpdate = (year, category, value) => {
    let tempData = {
      year: year,
      category: category,
      data: value
    };
    dispatch(updateChickenAmount(tempData)).then(res => { });
  };

  const handleTurkeysUpdate = (year, value) => {
    let tempData = {
      year: year,
      data: value
    };
    dispatch(updateTurkeysAmount(tempData)).then(res => { });
  };

  const handleDucksUpdate = (year, value) => {
    let tempData = {
      year: year,
      data: value
    };
    dispatch(updateDucksAmount(tempData)).then(res => { });
  };

  const handleSaveLivestockData = () => {
    setShowLoader(true);
    setSaveCurrentDataFlag(true);
    let tempObj = currentMonitoringDataObject;
    dispatch(updateMonitoringData(currentMonitoringDataObject._id, tempObj)).then((res) => {
      setSnackbarMessage(t("farmland_draft_submission_msg_success"));
      setState({ open: true, vertical: "top", horizontal: "center" });
      setShowLoader(false);
      setSaveCurrentDataFlag(false);
      if (!isAdmin) {
        navigate("/carbonplus/livestock/outfarm");
      }
    }).catch((e) => {
      setSnackbarMessage(t("farmland_draft_submission_msg_failure"));
      setState({ open: true, vertical: "top", horizontal: "center" });
      setSaveCurrentDataFlag(false);
      setShowLoader(false);
    });
  };


  const baselineYear = (year) => {
    if (monitoringYears[0] === year || monitoringYears[1] === year || monitoringYears[2] === year) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    // scroll all elements simultaneously

    var scrollers = document.getElementsByName('ScrollWrapper');

    var scrollerElements = Array.prototype.filter.call(scrollers, function (testElement) {
      return testElement.nodeName === 'DIV';
    });

    function scrollAll(scrollLeft) {
      scrollerElements.forEach(function (el) {
        el.scrollLeft = scrollLeft;
      });
    }

    scrollerElements.forEach(function (el) {
      el.addEventListener('scroll', function (e) {
        scrollAll(e.target.scrollLeft);
      });
    });
  });


  const showLivestock = () => {
    return isAdmin || expansionPanel.some(value => value === true)
  };

  const [isLoading, setIsLoading] = useState(true);

  const whatToShow = () => {
    expansionPanels();
    showLivestock();
    setIsLoading(false);
  };


  const [showOutFarmLivestock, setShowOutFarmLivestock] = useState(false);

  const handleOutFarmLivestock = () => {
    if (isAdmin) {
      handleSaveLivestockData();
      setShowOutFarmLivestock(true);
    } else {
      navigate("/carbonplus/livestock/outfarm");
    }
  }



  useEffect(() => {
    if (currentMonitoringDataObject.yearlyFarmData[0]?.livestock.inFarm.length === 0) {
      checkLivestock();
    };
  }, []);


  useEffect(() => {
    whatToShow()
  }, []);

  return (
    <>
      {isLoading ?
        <Backdrop
          onClickCapture={(e) => handleBackdropClickCaptureEvent(e)}
          style={{ color: "#fff", zIndex: 99999 }}
          open={true}
        >
          <CircularProgress />
        </Backdrop>
        : <>{
          showOutFarmLivestock ?
            <OutFarmLivestockComponent /> :
            <>
              {showLoader && (
                <Backdrop
                  onClickCapture={(e) => handleBackdropClickCaptureEvent(e)}
                  style={{ color: "#fff", zIndex: 99999 }}
                  open={saveCurrentDataFlag}
                  onClick={handleBackdropClose}
                >
                  <div style={{ fontSize: 20, fontWeight: 600, color: "white", marginTop: "20px" }}>
                    {t("generic_saving_data")}
                  </div>
                </Backdrop>
              )}

              <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
                key={vertical + horizontal}
              />

              {!isAdmin && (
                <Box marginLeft={6}>
                  <BackHomeComponent></BackHomeComponent>
                </Box>
              )}

              {showLivestock() ?

                <Box margin={12} marginTop={2}>
                  <Grid container spacing={2} >
                    <Grid item xs={12} sm={12} md={10} lg={10}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: 30,
                        marginBottom: 30,
                      }}
                    >
                      <Typography
                        variant="h1"
                      >
                        {t("ls_infarm_title")}
                      </Typography>
                      <Typography variant="subtitle1">
                        {t("ls_infarm_desc")}
                        <Tooltip
                          sx={{ paddingLeft: 1 }}
                          title={t("ls_infarm_info")}
                          placement="top-start"
                        >
                          <InfoIcon sx={{ fontSize: "medium", padding: "0px 0px 0px 8px" }} />
                        </Tooltip>
                      </Typography>
                    </Grid>
                  </Grid>

                  <Box marginTop={2} sx={{ display: "flex", flexDirection: "row" }}>
                    <Stack minWidth={"220px"} width={"220px"}></Stack>
                    <div
                      name="ScrollWrapper"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        overflowX: "scroll",
                      }}
                    >
                      <Stack marginLeft={2}>
                        <BaselineYears sx={{ width: "782px" }}>{t("baseline_years")}</BaselineYears>
                      </Stack>
                      <Stack>
                        <ProjectYears sx={{ marginLeft: 2, bgcolor: "#808080", minWidth: projectYearsWidth[monitoringYears.length] }}>{t("project_years")}</ProjectYears>
                      </Stack>
                    </div>
                  </Box>

                  {/*  UPLOADED DOCUMENTS & GRAZING MANAGEMENT  */}
                  <Box marginTop={2} sx={{ display: "flex", flexDirection: "row" }}>
                    <Stack minWidth={"220px"} width={"220px"}>
                      <Grid marginTop={7} item xs={12} sm={12} md={12} lg={12}>
                        <Typography variant="h3">
                          {t("ls_infarm_grazing")}
                          <Tooltip
                            sx={{ paddingLeft: 1 }}
                            title={<>{t("ls_infarm_grazing_desc_1")}<br /><br />{t("ls_infarm_grazing_desc_2")}<br /><br />{t("ls_infarm_grazing_desc_3")}</>}
                            placement="top-start"
                          >
                            <InfoIcon sx={{ fontSize: "medium", padding: "0px 0px 0px 8px" }} />
                          </Tooltip>
                        </Typography>
                      </Grid>
                      <Grid item marginTop={7} xs={12} sm={12} md={6} lg={6}>
                        <Typography variant="h3">
                          {t("ls_infarm_uploaded_docs")}
                        </Typography>
                      </Grid>
                    </Stack>
                    <div
                      name="ScrollWrapper"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        overflowX: "scroll",
                      }}
                    >
                      {monitoringYears && monitoringYears.map((year) => {
                        const livestock = currentMonitoringDataObject?.yearlyFarmData?.[arrDocumentAndYearsMapping[year]]?.livestock
                        const yearlyAnimalAmount = livestock?.inFarm?.map(spc => spc.category.map(cat => cat.amount === 0 ? '' : cat.amount)).flat()
                        return <Stack marginLeft={2} sx={{ minWidth: "250px" }} key={year}>
                          {baselineYear(year) ? (
                            <BaselineYears sx={{ marginBottom: 2 }}>{year}</BaselineYears>
                          ) : (
                            <ProjectYears sx={{ marginBottom: 2 }}>{year}</ProjectYears>
                          )}

                          <FormControl fullWidth sx={{ marginBottom: 3 }}>
                            <InputLabel size='small' id="grazing-management">
                              {t("ls_infarm_grazing")}
                            </InputLabel>
                            <Select
                              size='small'
                              name='grazing-management'
                              disabled={!allowEditing}
                              error={interdependentError(livestock?.grazingManagement, yearlyAnimalAmount)}
                              value={livestock?.grazingManagement === null ? '' : livestock?.grazingManagement}
                              onChange={(e) => handleGrazingManagementUpdate(year, e.target.value)}  // call action > change reducer
                              id="grazing-management-select" labelId='grazing-management' label="Grazing management">
                              <MenuItem value="unplanned grazing">{t("ls_infarm_grazing_unplanned")}</MenuItem>
                              <MenuItem value="planned grazing">{t("ls_infarm_grazing_planned")}</MenuItem>
                            </Select>
                          </FormControl>

                          {currentFarmVerificationDocuments?.yearlyVerificationDocuments?.[arrDocumentAndYearsMapping[year]]?.livestockTotals?.length > 0 &&
                            currentFarmVerificationDocuments?.yearlyVerificationDocuments[arrDocumentAndYearsMapping[year]]?.livestockTotals.map(
                              (ele, index) => {
                                const livestockFileInfo = currentFarmVerificationDocuments?.yearlyVerificationDocuments?.[arrDocumentAndYearsMapping[year]]?.livestockTotals[index]
                                return (
                                  <React.Fragment key={index}>
                                    <Grid marginTop={1} container spacing={2}>
                                      <Stack
                                        marginLeft={2}
                                        display={"flex"}
                                        marginBottom={1}

                                      >
                                        <UploadedDocument
                                          size="small"
                                          label={
                                            <Fragment>
                                              <CheckCircle sx={{ marginRight: 1 }} />
                                              {livestockFileInfo?.fileName}
                                            </Fragment>
                                          }
                                          disabled
                                        ></UploadedDocument>
                                        <Grid item>
                                          <Typography
                                            sx={{
                                              color: "#3e9781",
                                              cursor: "pointer",
                                              marginRight: "5px",
                                            }}
                                            fontWeight={600}
                                            fontSize={12}
                                            component="span"
                                            onClick={() => handleDownload(livestockFileInfo?.s3URL)}
                                          >
                                            {t("ver_doc_download")}
                                          </Typography>
                                          |
                                          {isAdmin ?
                                            <Typography
                                              sx={{
                                                color: "#3e9781",
                                                cursor: "pointer",
                                                marginX: "5px",
                                              }}
                                              fontWeight={600}
                                              fontSize={12}
                                              component="span"
                                              onClick={() =>
                                                handleDeleteFile(index,
                                                  livestockFileInfo?.folder,
                                                  livestockFileInfo?.fileName,
                                                  year,
                                                  currentFarmDataObject,
                                                  currentFarmVerificationDocuments,
                                                  dispatch,
                                                  setSnackbarMessage,
                                                  setSaveCurrentDataFlag,
                                                  setState,
                                                  t)
                                              }
                                            >
                                              {t("ver_doc_delete")}
                                            </Typography> :
                                            <Typography sx={{
                                              color: "#808080",
                                              userSelect: "none",
                                              marginX: "5px",
                                            }}
                                              fontWeight={600}
                                              fontSize={12}
                                              component="span"
                                            >
                                              {t("ver_doc_delete")}
                                            </Typography>
                                          }
                                          |
                                          {livestockFileInfo?.comment !== null &&
                                            livestockFileInfo?.comment !==
                                            undefined &&
                                            livestockFileInfo?.comment !== "" ? (
                                            <Typography
                                              sx={{
                                                color: "#3e9781",
                                                cursor: "pointer",
                                                marginLeft: "5px",
                                              }}
                                              fontWeight={600}
                                              fontSize={12}
                                              component="span"
                                              onClick={() => {
                                                setCurrentDocumentComment(
                                                  livestockFileInfo?.comment
                                                );
                                                setCurrentDocumentYear(arrDocumentAndYearsMapping[year]); //year index
                                                setCurrentDocumentFolder(
                                                  "livestockTotals"
                                                );
                                                setCurrentDocumentIndex(index);
                                                handleConfirmPopUpOpen();
                                              }}
                                            >
                                              {t("general_edit_comment")}
                                            </Typography>
                                          ) : (
                                            <Typography
                                              sx={{
                                                color: "#3e9781",
                                                cursor: "pointer",
                                                marginLeft: "5px",
                                              }}
                                              fontWeight={600}
                                              fontSize={12}
                                              component="span"
                                              onClick={() => {
                                                setCurrentDocumentYear(arrDocumentAndYearsMapping[year]);
                                                setCurrentDocumentFolder(
                                                  "livestockTotals"
                                                );
                                                setCurrentDocumentIndex(index);
                                                handleConfirmPopUpOpen();
                                              }}
                                            >
                                              {t("general_add_comment")}
                                            </Typography>
                                          )}
                                        </Grid>
                                      </Stack>
                                    </Grid>
                                  </React.Fragment>
                                )
                              }
                            )}
                        </Stack>
                      })}
                    </div>
                  </Box>

                  <Divider sx={{ marginY: 6 }}></Divider>

                  {/* CATTLE */}
                  {(expansionPanel[0] || isAdmin) &&
                    <>
                      <Accordion sx={{
                        boxShadow: 'none',
                        border: 'none',
                        '&:before': {
                          display: 'none',
                        }
                      }}
                        disableGutters
                      >
                        <AccordionSummary
                          sx={{
                            '&.MuiAccordionSummary-root': {
                              padding: 0,
                            }
                          }}
                          expandIcon={<ArrowDropDownIcon />}
                        >
                          <Typography variant="h2">{t("ls_infarm_cattle")}</Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            '&.MuiAccordionDetails-root': {
                              padding: 0,
                            }
                          }}
                          padding={'0px'}
                        >
                          <Box marginTop={2} sx={{ display: "flex", flexDirection: "row" }}>
                            {/* CATEGORIES */}
                            <Stack minWidth={"220px"} width={"220px"} paddingY={0.5}>
                              {cattle.map((item, index) => (
                                <Grid key={index} height={40} sx={{ my: 2 }}>
                                  <FieldTitleWithInfo title={item.title} title2={item.title2} info={item.info} />
                                </Grid>
                              ))}
                            </Stack>

                            <div
                              name="ScrollWrapper"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                overflowX: "scroll",
                              }}
                            >

                              {/* CATEGORIES' DATA */}
                              {monitoringYears && monitoringYears.map((year, index) => {
                                const livestock = currentMonitoringDataObject?.yearlyFarmData?.[arrDocumentAndYearsMapping[year]]?.livestock
                                return <Stack marginLeft={2} marginTop={2} sx={{ minWidth: "250px" }} key={year}>
                                  <NumericInput sx={{ mb: 2 }} id="cattle-mature-females" disabled={!allowEditing}
                                    max={max.inFarmAmmount}
                                    label={''}
                                    value={showExpectedValue(livestock?.inFarm[0]?.category[0].amount)}
                                    onChange={(e) => {
                                      handleCattleUpdate(year, 'MATURE FEMALES (NON-DAIRY)', e.target.value);
                                    }}
                                  ></NumericInput>
                                  <NumericInput max={max.inFarmAmmount} sx={{ my: 2 }} id="cattle-mature-males" disabled={!allowEditing}
                                    label={''}
                                    value={showExpectedValue(livestock?.inFarm[0]?.category[1].amount)}
                                    onChange={(e) => {
                                      handleCattleUpdate(year, 'MATURE MALES', e.target.value)
                                    }}
                                  ></NumericInput>
                                  <NumericInput max={max.inFarmAmmount} sx={{ my: 2 }} id="cattle-growning-cattle" disabled={!allowEditing}
                                    label={''}
                                    value={showExpectedValue(livestock?.inFarm[0]?.category[2].amount)}
                                    onChange={(e) => {
                                      handleCattleUpdate(year, 'GROWING CATTLE (6 - 24 MONTHS)', e.target.value)
                                    }}
                                  ></NumericInput>
                                  <NumericInput max={max.inFarmAmmount} sx={{ my: 2 }} id="cattle-calves"
                                    label={''}
                                    value={showExpectedValue(livestock?.inFarm[0]?.category?.[3]?.amount)}
                                    disabled={!allowEditing}
                                    onChange={(e) => {
                                      handleCattleUpdate(year, 'CALVES (< 6 MONTHS)', e.target.value)
                                    }}
                                  ></NumericInput>
                                </Stack>
                              })}
                            </div>
                          </Box>

                          <Divider sx={{ marginY: 3, borderStyle: 'dotted' }}></Divider>

                          {/* CATEGORIES 2nd part */}
                          <CommonCategories animal='CATTLE' isAdmin={isAdmin} allYears={monitoringYears} />

                        </AccordionDetails>
                      </Accordion>

                      <Divider sx={{ marginY: 6 }}></Divider>
                    </>
                  }

                  {/* DAIRY COWS */}
                  {(expansionPanel[1] || isAdmin) &&
                    <>
                      <Accordion sx={{
                        boxShadow: 'none',
                        border: 'none',
                        '&:before': {
                          display: 'none',
                        }
                      }}
                        disableGutters
                      >
                        <AccordionSummary
                          sx={{
                            '&.MuiAccordionSummary-root': {
                              padding: 0,
                            }
                          }}
                          expandIcon={<ArrowDropDownIcon />}
                        >
                          {/* <ArrowDropDownIcon></ArrowDropDownIcon> */}
                          <Typography variant="h2">{t("ls_infarm_dairy_cows")}</Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            '&.MuiAccordionDetails-root': {
                              padding: 0,
                            }
                          }}
                          padding={'0px'}
                        >
                          <Box marginTop={2} sx={{ display: "flex", flexDirection: "row" }}>
                            {/* CATEGORIES */}
                            <Stack minWidth={"220px"} width={"220px"}>
                              <Grid height={40} sx={{ my: 2 }}>
                                <FieldTitleWithInfo title="ls_infarm_dairy_cows_mature" info="ls_infarm_dairy_cows_mature_info" />
                              </Grid>
                            </Stack>

                            <div
                              name="ScrollWrapper"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                overflowX: "scroll",
                              }}
                            >

                              {/* CATEGORIES' DATA */}
                              {monitoringYears?.map((year, index) => {
                                const livestock = currentMonitoringDataObject?.yearlyFarmData?.[arrDocumentAndYearsMapping[year]]?.livestock
                                return <Stack marginLeft={2} marginTop={1} sx={{ minWidth: "250px" }} key={year}>
                                  <NumericInput max={max.inFarmAmmount} sx={{ mb: 2 }} id="dairy-cows-mature-females" disabled={!allowEditing}
                                    label={''}
                                    value={showExpectedValue(livestock?.inFarm[1]?.category?.[0]?.amount)}
                                    onChange={(e) => {
                                      handleDairyCowsUpdate(year, e.target.value)
                                    }}
                                  ></NumericInput>
                                </Stack>
                              })}
                            </div>
                          </Box>

                          <Divider sx={{ marginY: 3, borderStyle: 'dotted' }}></Divider>
                          <CommonCategories animal='DAIRY COWS' isAdmin={isAdmin} allYears={monitoringYears} />


                        </AccordionDetails>
                      </Accordion>

                      <Divider sx={{ marginY: 6 }}></Divider>
                    </>}

                  {/* SWINE */}
                  {(expansionPanel[2] || isAdmin) &&
                    <>
                      <Accordion sx={{
                        boxShadow: 'none',
                        border: 'none',
                        '&:before': {
                          display: 'none',
                        }
                      }}
                        disableGutters
                      >
                        <AccordionSummary
                          sx={{
                            '&.MuiAccordionSummary-root': {
                              padding: 0,
                            }
                          }}
                          expandIcon={<ArrowDropDownIcon />}
                        >
                          <Typography variant="h2">{t("ls_infarm_swine")}</Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            '&.MuiAccordionDetails-root': {
                              padding: 0,
                            }
                          }}
                          padding={'0px'}
                        >
                          <Box marginTop={2} sx={{ display: "flex", flexDirection: "row" }}>

                            {/* CATEGORIES */}
                            <Stack minWidth={"220px"} width={"220px"} paddingY={0.5}>
                              {sow.map((item, index) => (
                                <Grid key={index} height={24} sx={{ my: 2 }}>
                                  <FieldTitleWithInfo title={item.title} title2={item.title2} info={item.info} />
                                </Grid>
                              ))}
                            </Stack>

                            <div
                              name="ScrollWrapper"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                overflowX: "scroll",
                              }}
                            >

                              {/* CATEGORIES' DATA */}
                              {monitoringYears?.map((year, index) => {
                                const livestock = currentMonitoringDataObject?.yearlyFarmData?.[arrDocumentAndYearsMapping[year]]?.livestock
                                return <Stack marginLeft={2} marginTop={1} sx={{ minWidth: "250px" }} key={year}>
                                  <NumericInput max={max.inFarmAmmount} sx={{ mb: 2 }} id="swine-sows"
                                    disabled={!allowEditing}
                                    label={''}
                                    value={showExpectedValue(livestock?.inFarm[2]?.category?.[0]?.amount)}
                                    onChange={(e) => {
                                      handleSwineUpdate(year, 'SOWS', e.target.value)
                                    }}
                                  ></NumericInput>
                                  <NumericInput max={max.inFarmAmmount} sx={{ mb: 2 }} id="swine-boars"
                                    disabled={!allowEditing}
                                    label={''}
                                    value={showExpectedValue(livestock?.inFarm[2]?.category?.[1]?.amount)}
                                    onChange={(e) => {
                                      handleSwineUpdate(year, 'BOARS', e.target.value)
                                    }}
                                  ></NumericInput>
                                  <NumericInput max={max.inFarmAmmount} sx={{ mb: 2 }}
                                    id="swine-finishers"
                                    disabled={!allowEditing}
                                    label={''}
                                    value={showExpectedValue(livestock?.inFarm[2]?.category?.[2]?.amount)}
                                    onChange={(e) => {
                                      handleSwineUpdate(year, 'FINISHERS (3 - 6 MONTHS)', e.target.value)
                                    }}
                                  ></NumericInput>
                                  <NumericInput max={max.inFarmAmmount} sx={{ mb: 2.5 }} id="swine-other"
                                    disabled={!allowEditing}
                                    label={''}
                                    value={showExpectedValue(livestock?.inFarm[2]?.category?.[3]?.amount)}
                                    onChange={(e) => {
                                      handleSwineUpdate(year, 'OTHER SWINE', e.target.value)
                                    }}
                                  ></NumericInput>
                                  <NumericInput max={max.inFarmAmmount} sx={{ mb: 2 }} id="swine-piglets"
                                    label={''}
                                    value={showExpectedValue(livestock?.inFarm[2]?.category?.[4]?.amount)}
                                    disabled={!allowEditing}
                                    onChange={(e) => {
                                      handleSwineUpdate(year, 'PIGLETS', e.target.value)
                                    }}
                                  ></NumericInput>
                                </Stack>
                              })}
                            </div>
                          </Box>

                          <Divider sx={{ marginY: 3, borderStyle: 'dotted' }}></Divider>
                          <CommonCategories animal='SWINE' isAdmin={isAdmin} allYears={monitoringYears} />

                        </AccordionDetails>
                      </Accordion>

                      <Divider sx={{ marginY: 6 }}></Divider>
                    </>}

                  {/* SHEEP */}
                  {(expansionPanel[3] || isAdmin) &&
                    <>
                      <Accordion sx={{
                        boxShadow: 'none',
                        border: 'none',
                        '&:before': {
                          display: 'none',
                        }
                      }}
                        disableGutters
                      >
                        <AccordionSummary
                          sx={{
                            '&.MuiAccordionSummary-root': {
                              padding: 0,
                            }
                          }}
                          expandIcon={<ArrowDropDownIcon />}
                        >
                          <Typography variant="h2">{t("ls_infarm_sheep")}</Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            '&.MuiAccordionDetails-root': {
                              padding: 0,
                            }
                          }}
                          padding={'0px'}
                        >
                          <Box marginTop={2} sx={{ display: "flex", flexDirection: "row" }}>

                            {/* CATEGORIES */}
                            <Stack minWidth={"220px"} width={"220px"} paddingY={0.5}>
                              {sheep.map((item, index) => (
                                <Grid key={index} height={24} sx={{ my: 2 }}>
                                  <FieldTitleWithInfo title={item.title} title2={item.title2} info={item.info} />
                                </Grid>
                              ))}
                            </Stack>

                            <div
                              name="ScrollWrapper"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                overflowX: "scroll",
                              }}
                            >

                              {/* CATEGORIES' DATA */}
                              {monitoringYears.map((year, index) => {
                                const livestock = currentMonitoringDataObject?.yearlyFarmData?.[arrDocumentAndYearsMapping[year]]?.livestock
                                return <Stack marginLeft={2} marginTop={1} sx={{ minWidth: "250px" }} key={year}>
                                  <NumericInput max={max.inFarmAmmount} sx={{ mb: 2 }} id="sheep-mature-female"
                                    disabled={!allowEditing}
                                    label={''}
                                    value={showExpectedValue(livestock?.inFarm?.[3]?.category?.[0]?.amount)}
                                    onChange={(e) => {
                                      handleSheepUpdate(year, 'MATURE FEMALES (> 1 YEAR)', e.target.value)
                                    }}
                                  ></NumericInput>
                                  <NumericInput max={max.inFarmAmmount} sx={{ mb: 2.5 }} id="sheep-mature-males"
                                    disabled={!allowEditing}
                                    label={''}
                                    value={showExpectedValue(livestock?.inFarm?.[3]?.category?.[1]?.amount)}
                                    onChange={(e) => {
                                      handleSheepUpdate(year, 'MATURE MALES (> 1 YEAR)', e.target.value)
                                    }}
                                  ></NumericInput>
                                  <NumericInput max={max.inFarmAmmount} sx={{ mb: 2.5 }} id="sheep-lambs"
                                    disabled={!allowEditing}
                                    label={''}
                                    value={showExpectedValue(livestock?.inFarm?.[3]?.category?.[2]?.amount)}
                                    onChange={(e) => {
                                      handleSheepUpdate(year, 'LAMBS (< 1 YEAR)', e.target.value)
                                    }}
                                  ></NumericInput>
                                </Stack>
                              })}
                            </div>
                          </Box>

                          <Divider sx={{ marginY: 3, borderStyle: 'dotted' }}></Divider>
                          <CommonCategories animal='SHEEP' isAdmin={isAdmin} allYears={monitoringYears} />
                        </AccordionDetails>
                      </Accordion>

                      <Divider sx={{ marginY: 6 }}></Divider>
                    </>}

                  {/* GOATS */}
                  {(expansionPanel[4] || isAdmin) &&
                    <>
                      <Accordion sx={{
                        boxShadow: 'none',
                        border: 'none',
                        '&:before': {
                          display: 'none',
                        }
                      }}
                        disableGutters
                      >
                        <AccordionSummary
                          sx={{
                            '&.MuiAccordionSummary-root': {
                              padding: 0,
                            }
                          }}
                          expandIcon={<ArrowDropDownIcon />}
                        >
                          <Typography variant="h2">{t("ls_infarm_goats")}</Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            '&.MuiAccordionDetails-root': {
                              padding: 0,
                            }
                          }}
                          padding={'0px'}
                        >
                          <Box marginTop={2} sx={{ display: "flex", flexDirection: "row" }}>

                            {/* CATEGORIES */}
                            <Stack minWidth={"220px"} width={"220px"} paddingY={0.5}>
                              {goat.map((item, index) => (
                                <Grid key={index} height={24} sx={{ my: 2 }}>
                                  <FieldTitleWithInfo title={item.title} title2={item.title2} info={item.info} />
                                </Grid>
                              ))}
                            </Stack>

                            <div
                              name="ScrollWrapper"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                overflowX: "scroll",
                              }}
                            >

                              {/* CATEGORIES' DATA */}
                              {monitoringYears?.map((year, index) => {
                                const livestock = currentMonitoringDataObject?.yearlyFarmData?.[arrDocumentAndYearsMapping[year]]?.livestock
                                return <Stack marginLeft={2} marginTop={1} sx={{ minWidth: "250px" }} key={year}>
                                  <NumericInput max={max.inFarmAmmount} sx={{ mb: 2 }} id="goats-mature-female"
                                    disabled={!allowEditing}
                                    label={''}
                                    value={showExpectedValue(livestock?.inFarm?.[4]?.category?.[0]?.amount)}
                                    onChange={(e) => {
                                      handleGoatsUpdate(year, 'MATURE FEMALES (> 1 YEAR)', e.target.value)
                                    }}
                                  ></NumericInput>
                                  <NumericInput max={max.inFarmAmmount} sx={{ mb: 2.5 }} id="goats-mature-males"
                                    disabled={!allowEditing}
                                    label={''}
                                    value={showExpectedValue(livestock?.inFarm?.[4]?.category?.[1]?.amount)}
                                    onChange={(e) => {
                                      handleGoatsUpdate(year, 'MATURE MALES (> 1 YEAR)', e.target.value)
                                    }}
                                  ></NumericInput>
                                  <NumericInput max={max.inFarmAmmount} sx={{ mb: 2.5 }} id="goats-goat-kids"
                                    disabled={!allowEditing}
                                    label={''}
                                    value={showExpectedValue(livestock?.inFarm?.[4]?.category?.[2]?.amount)}
                                    onChange={(e) => {
                                      handleGoatsUpdate(year, 'GOAT KIDS (< 1 YEAR)', e.target.value)
                                    }}
                                  ></NumericInput>
                                </Stack>
                              })}
                            </div>
                          </Box>

                          <Divider sx={{ marginY: 3, borderStyle: 'dotted' }}></Divider>
                          <CommonCategories animal='GOATS' isAdmin={isAdmin} allYears={monitoringYears} />
                        </AccordionDetails>
                      </Accordion>

                      <Divider sx={{ marginY: 6 }}></Divider>
                    </>}

                  {/* CHICKEN */}
                  {(expansionPanel[5] || isAdmin) &&
                    <>
                      <Accordion sx={{
                        boxShadow: 'none',
                        border: 'none',
                        '&:before': {
                          display: 'none',
                        }
                      }}
                        disableGutters
                      >
                        <AccordionSummary
                          sx={{
                            '&.MuiAccordionSummary-root': {
                              padding: 0,
                            }
                          }}
                          expandIcon={<ArrowDropDownIcon />}
                        >
                          <Typography variant="h2">{t("ls_infarm_chicken")}</Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            '&.MuiAccordionDetails-root': {
                              padding: 0,
                            }
                          }}
                          padding={'0px'}
                        >
                          <Box marginTop={2} sx={{ display: "flex", flexDirection: "row" }}>

                            {/* CATEGORIES */}
                            <Stack minWidth={"220px"} width={"220px"} paddingY={0.5}>
                              {chicken.map((item, index) => (
                                <Grid key={index} height={24} sx={{ my: 2 }}>
                                  <FieldTitleWithInfo title={item.title} title2={item.title2} info={item.info} />
                                </Grid>
                              ))}
                            </Stack>

                            <div
                              name="ScrollWrapper"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                overflowX: "scroll",
                              }}
                            >

                              {/* CATEGORIES' DATA */}
                              {monitoringYears?.map((year, index) => {
                                const livestock = currentMonitoringDataObject?.yearlyFarmData?.[arrDocumentAndYearsMapping[year]]?.livestock
                                return <Stack marginLeft={2} marginTop={1} sx={{ minWidth: "250px" }} key={year}>
                                  <NumericInput max={max.inFarmAmmount} sx={{ mb: 2 }} id="chicken-layer-hens" disabled={!allowEditing}
                                    label={''}
                                    value={showExpectedValue(livestock?.inFarm?.[5]?.category?.[0]?.amount)}
                                    onChange={(e) => {
                                      handleChickenUpdate(year, 'LAYER HENS', e.target.value)
                                    }}
                                  ></NumericInput>
                                  <NumericInput max={max.inFarmAmmount} sx={{ mb: 2 }} id="chicken-broilers"
                                    disabled={!allowEditing}
                                    label={''}
                                    value={showExpectedValue(livestock?.inFarm?.[5]?.category?.[1]?.amount)}
                                    onChange={(e) => {
                                      handleChickenUpdate(year, 'BROILERS', e.target.value)
                                    }}
                                  ></NumericInput>
                                  <NumericInput max={max.inFarmAmmount} sx={{ mb: 2 }} id="chicken-other"
                                    disabled={!allowEditing}
                                    label={''}
                                    value={showExpectedValue(livestock?.inFarm?.[5]?.category?.[2]?.amount)}
                                    onChange={(e) => {
                                      handleChickenUpdate(year, 'OTHER CHICKEN', e.target.value)
                                    }}
                                  ></NumericInput>
                                </Stack>
                              })}
                            </div>
                          </Box>

                          <Divider sx={{ marginY: 3, borderStyle: 'dotted' }}></Divider>
                          <CommonCategories animal='CHICKEN' isAdmin={isAdmin} allYears={monitoringYears} />
                        </AccordionDetails>
                      </Accordion>

                      <Divider sx={{ marginY: 6 }}></Divider>

                    </>}


                  {/* TURKEYS */}
                  {(expansionPanel[6] || isAdmin) &&
                    <>
                      <Accordion sx={{
                        boxShadow: 'none',
                        border: 'none',
                        '&:before': {
                          display: 'none',
                        }
                      }}
                        disableGutters
                      >
                        <AccordionSummary
                          sx={{
                            '&.MuiAccordionSummary-root': {
                              padding: 0,
                            }
                          }}
                          expandIcon={<ArrowDropDownIcon />}
                        >
                          {/* <ArrowDropDownIcon></ArrowDropDownIcon> */}
                          <Typography variant="h2">{t("ls_infarm_turkeys")}</Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            '&.MuiAccordionDetails-root': {
                              padding: 0,
                            }
                          }}
                          padding={'0px'}
                        >
                          <Box marginTop={2} sx={{ display: "flex", flexDirection: "row" }}>

                            {/* CATEGORIES */}
                            <Stack minWidth={"220px"} width={"220px"} paddingY={0.5}>
                              <Grid height={24} sx={{ my: 2 }}>
                                <FieldTitleWithInfo title="ls_infarm_turkeys" />
                              </Grid>

                            </Stack>

                            <div
                              name="ScrollWrapper"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                overflowX: "scroll",
                              }}
                            >

                              {/* CATEGORIES' DATA */}
                              {monitoringYears?.map((year, index) => {
                                const livestock = currentMonitoringDataObject?.yearlyFarmData?.[arrDocumentAndYearsMapping[year]]?.livestock
                                return <Stack marginLeft={2} marginTop={1} sx={{ minWidth: "250px" }} key={year}>
                                  <NumericInput max={max.inFarmAmmount} sx={{ mb: 2 }} id="turkeys" disabled={!allowEditing}
                                    label={''}
                                    value={showExpectedValue(livestock?.inFarm?.[6]?.category?.[0]?.amount)}
                                    onChange={(e) => {
                                      handleTurkeysUpdate(year, e.target.value)
                                    }}
                                  ></NumericInput>
                                </Stack>
                              })}
                            </div>
                          </Box>

                          <Divider sx={{ marginY: 3, borderStyle: 'dotted' }}></Divider>
                          <CommonCategories animal='TURKEYS' isAdmin={isAdmin} allYears={monitoringYears} />
                        </AccordionDetails>
                      </Accordion>
                      <Divider sx={{ marginY: 6 }}></Divider>
                    </>}

                  {/* DUCKS */}
                  {(expansionPanel[7] || isAdmin) &&
                    <>
                      <Accordion sx={{
                        boxShadow: 'none',
                        border: 'none',
                        '&:before': {
                          display: 'none',
                        }
                      }}
                        disableGutters
                      >
                        <AccordionSummary
                          sx={{
                            '&.MuiAccordionSummary-root': {
                              padding: 0,
                            }
                          }}
                          expandIcon={<ArrowDropDownIcon />}
                        >
                          {/* <ArrowDropDownIcon></ArrowDropDownIcon> */}
                          <Typography variant="h2">{t("ls_infarm_ducks")}</Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            '&.MuiAccordionDetails-root': {
                              padding: 0,
                            }
                          }}
                          padding={'0px'}
                        >
                          <Box marginTop={2} sx={{ display: "flex", flexDirection: "row" }}>

                            {/* CATEGORIES */}
                            <Stack minWidth={"220px"} width={"220px"} paddingY={0.5}>
                              <Grid height={24} sx={{ my: 2 }}>
                                <FieldTitleWithInfo title="ls_infarm_ducks" />
                              </Grid>
                            </Stack>

                            <div
                              name="ScrollWrapper"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                overflowX: "scroll",
                              }}
                            >

                              {/* CATEGORIES' DATA */}
                              {monitoringYears?.map((year, index) => {
                                const livestock = currentMonitoringDataObject?.yearlyFarmData?.[arrDocumentAndYearsMapping[year]]?.livestock
                                return <Stack marginLeft={2} marginTop={1} sx={{ minWidth: "250px" }} key={year}>
                                  <NumericInput max={max.inFarmAmmount} sx={{ mb: 2 }} id="ducks" disabled={!allowEditing}
                                    label={''}
                                    value={showExpectedValue(livestock?.inFarm?.[7]?.category?.[0]?.amount)}
                                    onChange={(e) => {
                                      handleDucksUpdate(year, e.target.value)
                                    }}
                                  ></NumericInput>
                                </Stack>
                              })}
                            </div>
                          </Box>

                          <Divider sx={{ marginY: 3, borderStyle: 'dotted' }}></Divider>
                          <CommonCategories animal='DUCKS' isAdmin={isAdmin} allYears={monitoringYears} />
                        </AccordionDetails>
                      </Accordion>
                    </>}


                  <Box display="flex" flexDirection="row" justifyContent="end">
                    {allowEditing && !isAdmin && (<SubmitButton
                      sx={{
                        marginTop: "100px",
                        width: "300px",
                        marginRight: 2,
                        bgcolor: "white",
                        color: "#409781",
                        borderColor: "#409781",
                        border: 2,
                        "&:hover": { backgroundColor: "white" },
                      }}
                      onClick={() => {
                        handleOutFarmLivestock()
                      }}
                    >
                      {t("ls_infarm_add_out_farm")}
                    </SubmitButton>)}
                    <SubmitButton
                      sx={{
                        marginTop: "100px",
                        alignSelf: "flex-end",
                        width: "300px",
                      }}
                      onClick={handleSaveLivestockData}
                    >
                      {t("generic_save_continue")}
                    </SubmitButton>
                  </Box>



                  {/* comment pop-up */}
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Dialog
                        PaperProps={{ sx: { maxWidth: "revert", width: "700px" } }}
                        open={confirmPopUpOpen}
                        onClose={handleConfirmPopUpClose}
                        aria-labelledby="alert-dialog-confirm-popup"
                        aria-describedby="alert-dialog-confirm-popup-description"
                      >
                        <CloseRoundedIcon
                          sx={{
                            alignSelf: "flex-end",
                            padding: 2,
                            color: "#707070",
                            "&:hover": { cursor: "pointer" },
                          }}
                          onClick={() => {
                            setCurrentDocumentComment("");
                            setCurrentDocumentYear("");
                            setCurrentDocumentFolder("");
                            setCurrentDocumentIndex("");
                            setConfirmPopUpOpen(false);
                          }}
                        />
                        <DialogTitle id="alert-dialog-confirm-popup">
                          <Typography
                            paddingX={2}
                            paddingBottom={2}
                            fontWeight={800}
                            fontSize={24}
                          >
                            {t("general_add_comment")}
                          </Typography>
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description" color="black">
                            <Typography paddingX={2}>
                              {t("fu_comment_description")}
                            </Typography>
                          </DialogContentText>
                        </DialogContent>
                        <DialogContent sx={{ marginX: 2 }}>
                          <TextField
                            id="verification-document-upload"
                            multiline
                            maxRows={4}
                            sx={{ width: "100%" }}
                            value={currentDocumentComment}
                            placeholder={t("fu_comment_type_here")}
                            onChange={(e) => setCurrentDocumentComment(e.target.value)}
                          ></TextField>
                        </DialogContent>
                        <br />
                        <DialogActions style={{ padding: "0px 40px 20px 20px" }}>
                          <SubmitButton
                            disableElevation
                            disableRipple
                            onClick={saveVerificationDocumentComment}
                          >
                            {t("generic_save")}
                          </SubmitButton>
                        </DialogActions>
                      </Dialog>
                    </Grid>
                  </Grid>


                </Box> :
                <LivestockNoDocumentsComponent />
              }
            </>}
        </>
      }
    </>
  );
};

export default InFarmLivestockComponent;