export const translationES = {
  home_welcome: "Bienvenido",
  home_project_terms: "Términos y condiciones",
  home_project_terms_agree: "Al hacer clic en \"Enviar para revisión\" usted acepta nuestros ",
  "home_project_terms_details": `
  <p>
    <strong>Datos Vinculantes:</strong>  Los datos que envíe aquí son vinculantes. Usted confirma que la información es precisa y completa según su conocimiento. Puede solicitar una descarga de todos sus datos, ya que es su responsabilidad mantener un registro separado y preciso de todos los datos enviados para su referencia en caso de cualquier discrepancia técnica, pérdida de datos y posible auditoría.
  </p>
  <p>
    <strong>Archivo y Retención de Datos:</strong> Sus datos serán archivados electrónicamente y retenidos por un mínimo de cinco años después del final del último período de acreditación del proyecto agregado (hasta 2050). Este período de retención se aplica incluso si usted es excluido de un período de acreditación y ya no trabaja con Climate Farmers.
  </p>
  <p>
    <strong>Auditoría y Cumplimiento:</strong> Usted confirma que sus datos pueden ser auditados por Climate Farmers o un auditor externo designado, como TÜV Rheinland. Durante una auditoría, se puede solicitar información adicional y se pueden realizar visitas a la granja para verificar la exactitud de los datos.
  </p>
  <p>
    <strong>Consentimiento para el Uso de Datos:</strong> Al enviar sus datos en este tablero, usted consiente su uso para fines de monitoreo relacionados con el cálculo y la verificación de los Créditos Carbon+ de Climate Farmers como se describe anteriormente.
  </p>
  `,
  back_to_home: "ATRÁS",
  farminfo_tell: "Cuéntanos un poco sobre tu granja",
  farminfo_owner_fname: "Nombre del propietario de la tierra",
  farminfo_owner_lname: "Apellido del propietario de la tierra",
  farminfo_email: "Dirección de correo electrónico",
  farminfo_postal_code: "Código Postal",
  farminfo_country: "País",
  farminfo_phone: "Teléfono, incluido el código de país",
  farminfo_start_year: "Año de inicio del proyecto",
  farminfo_start_month: "Mes de inicio del proyecto",
  farminfo_duration: "Duración del proyecto",
  farminfo_challenges: "Desafíos",
  farminfo_inv_barriers: "Barreras de inversión",
  farminfo_inst_barriers: "Barreras institucionales",
  farminfo_tech_barriers: "Barreras tecnológicas",
  farminfo_reg_trad: "Barreras debidas a las tradiciones regionales",
  farminfo_mgmt_pract: "Barreras que evolucionan a través de las prácticas de gestión de tierras existentes",
  farminfo_eco_circ: "Barreras a través de circunstancias ecológicas",
  farminfo_land_ownership: "Barreras relacionadas con la propiedad de la tierra, la herencia o los derechos de propiedad",
  farminfo_no_labour: "No hay acceso a mano de obra calificada",
  farminfo_no_coaching: "No hay acceso a consultoría/coaching calificado",
  farminfo_other: "Otro - Describe",
  farminfo_no_barriers: "Sin barreras",
  farminfo_add_more: "Añadir más",
  farminfo_remove: "Eliminar",

  fu_dir_title: "Uso de combustible > Directo",
  fu_dir_description_1: "Indica el uso directo de combustible en tu finca. Esto incluye todo el combustible que compras y utilizas en tu propiedad. Sin embargo, excluye el combustible utilizado en otras fincas o por proveedores de servicios externos que trabajaron en tu finca.",
  fu_dir_description_2: "Las filas y columnas marcadas con * son obligatorias.",
  fu_dir_quantity: "Cantidad utilizada",
  fuelusage_diesel: "Diesel",
  fu_section_subtitle_1: "Indique la cantidad de litros utilizados en tu finca durante cada año *",
  fu_section_subtitle_2: "Sube las facturas correspondientes a este importe (PDF o JPG)",
  fu_section_infobox: "Ten en cuenta que, en caso de que te seleccionen para una auditoría, se te pedirá que proporciones una declaración por escrito del uso de combustible en caso de que no dispongas de facturas u otra documentación acreditativa.",
  fuelusage_petrol: "Gas",
  fu_comment_description: "Agregue un comentario si quiere dar información adicional o contextual sobre el documento proporcionado.",
  fu_comment_type_here: "Escribe aquí",

  generic_save_continue: "Guardar y continuar",

  fu_indir_title: "Consumo de combustible > Indirecto",
  fu_indir_description_1: "Proporciona información sobre todos los servicios que consumen combustible realizados por proveedores de externos en tu finca (por ejemplo: siembra, labranza, henificación, etc.). ",
  fu_indir_description_2: "Si tienes una factura por el servicio externo, puedes subirla en la primera sección. Si no tienes factura, simplemente indica el tipo de servicio, describe la operación y especifica el tamaño de la superficie en la que se llevó a cabo el servicio en la segunda sección. ",
  fu_indir_description_3: "Si no has utilizado servicios externos, puedes hacer clic en “No tengo ningún servicio externo” en la parte inferior de la página. ",
  fu_indir_description_4: "Las columnas marcadas con * son obligatorias.",

  fu_ind_section_subtitle_1: "Servicios externos con facturas",
  fu_ind_section_subtitle_2: "Servicios externos con o sin facturas",
  fu_ind_info_box_1: "Añade todos los servicios externos para los que no tengas factura seleccionando el tipo de servicio, describiendo el tipo de servicio (Ej: cosecha de grano, cosecha de heno, empacado de heno) y especificando el tamaño de la superficie en la que se llevó a cabo el servicio.",
  fu_ind_info_box_2: "Ten en cuenta que, en caso de que te seleccionen para una auditoría, se te pedirá que proporciones una declaración por escrito de la recepción de este servicio.",
  fu_ind_ext_serv: "Servicio externo",
  fu_ind_ext_serv_1: "Fertilización",
  fu_ind_ext_serv_2: "Arado",
  fu_ind_ext_serv_3: "Siembra",
  fu_ind_ext_serv_4: "Control de malas hierbas",
  fu_ind_ext_serv_5: "Cosecha",
  fu_ind_ext_serv_describe: "Describe el servicio",
  fu_ind_ext_serv_add: "+ Añadir servicio",
  fu_ind_no_ext_serv: "No tengo ningún servicio externo",

  generic_submit: "Enviar",


  general_add_comment: "Comentar",
  general_edit_comment: "Comentar",
  general_edit_upload_doc: "Cargar nuevo documento",

  fuelusage_fueltype: "Tipo de combustible",
  fuelusage_amt: "Cantidad en litros",
  fuelusage_other: "Otro",
  fuelusage_estimates: "Estimaciones de uso del combustible para el próximo año (opcional)",
  fuelusage_this_refers: "Esto se refiere a cuánto combustible estimará que usará en el futuro por año para actividades agrícolas, como maquinarias.",

  ls_add: "Agregar ganado",
  ls_edit: "Editar ganado",
  ls_view: "Ver ganado",
  ls_del: "Eliminar ganado",
  ls_no_data: "no hay información para mostrar",
  ls_current: "Actual",
  ls_livestock: "Ganado",
  ls_reset: "REINICIAR DATOS",
  ls_year1: "AÑO 1",
  ls_year2: "AÑO 2",
  ls_year3: "Año 3",
  ls_year4: "Año 4",
  ls_year5: "Año 5",
  ls_year6: "AÑO 6",
  ls_year7: "AÑO 7",
  ls_year8: "AÑO 8",
  ls_year9: "AÑO 9",
  ls_year10: "Año 10",
  ls_yes: "Sí",
  ls_no: "No",
  ls_species: "Especies",
  ls_dairy: "Vacas lecheras",
  ls_beef: "Ganado vacuno",
  ls_calves: "Terneros (menos de 1 año)",
  ls_buff: "Búfalo",
  ls_sows: "Cerdos/jabalíes",
  ls_pigs: "Cerdo",
  ls_pigglets: "Pigglets (menos de 1 año)",
  ls_lambs: "Corderos (menos de 1 año)",
  ls_goat_kids: "Niños de cabra (menos de 1 año)",
  ls_poultry: "Aves de corral",
  ls_turkey: "Pavos",
  ls_horses: "Caballos",
  ls_mules: "Mulas/culo",
  ls_camelids: "Camélidos",
  ls_value: "El valor debe ser mayor que 0",
  ls_unplanned: "Pastoreo no planificado",
  ls_adaptive: "Pastoreo de múltiples pades adaptativo / pastoreo",
  ls_rest: "Resto del año",
  ls_slaughtered: "Sacrificado",
  ls_sold: "Vendido",
  ls_moved: "Mudado",
  ls_manure: "Estiércol",
  ls_percent: "Por ciento",
  ls_onfield: "En el campo",
  ls_composted: "Compostado",
  ls_scattered: "Dispersado directamente",
  ls_collection: "Recolección y eliminación",
  lu_map_location_msg_1: "La ubicación predeterminada del mapa apunta a las coordenadas de su granja:",
  lu_map_location_msg_2: "Si cree que la ubicación es incorrecta, escriba la dirección/coordenadas en la barra de búsqueda o regrese amablemente a la sección de información de la granja para actualizar sus coordenadas. Tenga en cuenta que solo las coordenadas de la granja en la sección de información de la granja se consideran coordenadas agrícolas predeterminadas.",
  lu_search: "Buscar",
  lu_importing: "Importar un archivo KML",
  lu_importing_2: "Importar un archivo KML",
  lu_temp: "Nombre de la plantilla",
  lu_rows_per_page: "Filas por página",
  temp_template_name: "Nombre de la plantilla",
  temp_land_use: "Tipo de uso de la tierra",
  temp_agro: "Agroforestería",
  temp_forest: "Silvicultura",
  temp_type_1: "Tipo de árboles",
  temp_quercus: "Quercus",
  temp_olea: "Olea europea",
  temp_other: "Otro",
  temp_tree_name_1: "Nombre del árbol (si no está presente)",
  temp_avg_1: "Número promedio de árboles por hectárea",
  temp_irrigation: "Irrigación",
  temp_syn_fert: "Fertilizante sintético",
  temp_fert: "Fertilizante (t/año)",
  temp_nitro: "% De nitrógeno",
  temp_manure: "Aplicación de estiércol (t/ha)",
  temp_per_manure: "% De estiércol importado",
  temp_compost: "Aplicación de compost (t/ha)",
  temp_per_compost: "% De compost importado",
  temp_cash: "Cultivo comercial",
  temp_name: "Nombre si no en la lista desplegable en el calendario",
  temp_unplanned: "Pastoreo no planificado",
  temp_yields: "¿Se proporcionan los rendimientos y los valores de residuos (TC/HA) como biomasa seca (preferida) o fresca?",
  temp_jan: "Ene",
  temp_feb: "Feb",
  temp_mar: "Mar",
  temp_apr: "Abr",
  temp_jun: "Jun",
  temp_jul: "Jul",
  temp_aug: "Ago",
  temp_sep: "Sep",
  temp_oct: "Oct",
  temp_nov: "Nov",
  temp_dec: "Dic",
  temp_barley: "Cebada",
  temp_beans: "Frijoles",
  temp_beets: "Remolacha",
  temp_clover: "Trébol",
  temp_corn: "Maíz",
  temp_cowpea: "Caupí",
  temp_forage_beet: "Remolacha de forraje",
  temp_forage_kale: "Forraje col rizada",
  temp_radish: "Rábano forjado",
  temp_turnip: "Nabo de forraje",
  temp_garlic: "Ajo",
  temp_lupins: "Lupinas",
  temp_millet: "Mijo",
  temp_oats: "Avena",
  temp_onions: "Cebollas",
  temp_other_vegetables: "Otras verduras",
  temp_peanuts: "Miseria",
  temp_peas: "Guisantes",
  temp_potatoes: "Papas y tubérculos",
  temp_pumpkin: "Calabaza",
  temp_rice: "Arroz",
  temp_rye: "Centeno",
  temp_seeds: "Semillas",
  temp_sesame: "Sésamo",
  temp_shrubb: "Perennes arbustivos",
  temp_sorghum: "Sorgo",
  temp_soybean: "Haba de soja",
  temp_squash: "Calabaza",
  temp_sunflower: "Girasol",
  temp_sunn_hemp: "Cáñamo",
  temp_triticale: "Triticale",
  temp_wheat: "Trigo",
  temp_harvest_yield: "Rendimiento de cosecha (t/ha)",
  temp_grazing_yield: "Rendimiento de pastoreo (t/ha)",
  parcel_import: "Importar archivo KML",
  parcel_past: "Prácticas pasadas y actuales",
  parcel_year1: "Y-1",
  parcel_year2: "Y-2",
  parcel_year3: "Y-3",
  parcel_year4: "Y-4",
  parcel_year5: "Y-5",
  parcel_year6: "Y-6",
  parcel_year7: "Y-7",
  parcel_year8: "Y-8",
  parcel_year9: "Y-9",
  parcel_year10: "Y-10",
  parcel_coordinates: "Coordenadas",
  parcel_parcels: "Parcelas",
  parcel_copy_data: "Copiar datos",
  parcel_templates: "Plantillas",
  parcel_applying: "Aplicando estas prácticas desde (años)?*",
  parcel_like_copy: "Me gustaría copiar las prácticas actuales a los próximos 10 años.",
  parcel_are_sure: "¿Estás seguro de que quieres copiar los datos de la parcela?",
  parcel_agree: "ACEPTAR",
  parcel_are_you_import: "¿Estás seguro de que quieres importar los datos de la plantilla?",
  parcel_agree_2: "ACEPTAR",
  parcel_land_use: "Tipo de uso de la tierra",
  parcel_agro: "Agroforestería",
  parcel_forest: "Silvicultura",
  parcel_type_1: "Tipo de árboles",
  parcel_quercus: "Quercus",
  parcel_olea: "Olea europea",
  parcel_other: "Otro",
  parcel_avg_no_2: "Número promedio de árboles por hectárea",
  parcel_tree_name_1: "Nombre del árbol (si no está presente)",
  parcel_avg_1: "Número promedio de árboles por hectárea",
  parcel_tree_name_2: "Nombre del árbol (si no está presente)",
  parcel_avg_2: "Número promedio de árboles por hectárea",
  parcel_tree_name_3: "Nombre del árbol (si no está presente)",
  parcel_avg_3: "Número promedio de árboles por hectárea",
  parcel_irrigation: "Irrigación",
  parcel_syn_fert: "Fertilizante sintético",
  parcel_fert: "Fertilizante (t/año)",
  parcel_nitro: "% De nitrógeno",
  parcel_manure: "Aplicación de estiércol (t/ha)",
  parcel_per_manure: "% De estiércol importado",
  parcel_compost: "Aplicación de compost (t/ha)",
  parcel_per_compost: "% De compost importado",
  parcel_cash: "Cultivo comercial",
  parcel_name: "Nombre si no en la lista desplegable en el calendario",
  parcel_unplanned: "Pastoreo no planificado",
  parcel_yields: "¿Se proporcionan los rendimientos y los valores de residuos (TC/HA) como biomasa seca (preferida) o fresca?",
  parcel_jan: "Ene",
  parcel_feb: "Feb",
  parcel_mar: "Mar",
  parcel_apr: "Abr",
  parcel_jun: "Jun",
  parcel_jul: "Jul",
  parcel_aug: "Ago",
  parcel_sep: "Sep",
  parcel_oct: "Oct",
  parcel_nov: "Nov",
  parcel_dec: "Dic",
  parcel_barley: "Cebada",
  parcel_beans: "Frijoles",
  parcel_beets: "Remolacha",
  parcel_clover: "Trébol",
  parcel_corn: "Maíz",
  parcel_cowpea: "Caupí",
  parcel_forage_beet: "Remolacha de forraje",
  parcel_forage_kale: "Forraje col rizada",
  parcel_radish: "Rábano forjado",
  parcel_turnip: "Nabo de forraje",
  parcel_garlic: "Ajo",
  parcel_lupins: "Lupinas",
  parcel_millet: "Mijo",
  parcel_oats: "Avena",
  parcel_onions: "Cebollas",
  parcel_other_vegetables: "Otras verduras",
  parcel_peanuts: "Miseria",
  parcel_peas: "Guisantes",
  parcel_potatoes: "Papas y tubérculos",
  parcel_pumpkin: "Calabaza",
  parcel_rice: "Arroz",
  parcel_rye: "Centeno",
  parcel_seeds: "Semillas",
  parcel_sesame: "Sésamo",
  parcel_shrubb: "Perennes arbustivos",
  parcel_sorghum: "Sorgo",
  parcel_soybean: "Haba de soja",
  parcel_squash: "Calabaza",
  parcel_sunflower: "Girasol",
  parcel_sunn_hemp: "Cáñamo",
  parcel_triticale: "Triticale",
  parcel_wheat: "Trigo",
  parcel_harvest_yield: "Rendimiento de cosecha (t/ha)",
  parcel_grazing_yield: "Rendimiento de pastoreo (t/ha)",
  parcel_y1: "AÑO 1",
  parcel_y2: "AÑO 2",
  parcel_y3: "Año 3",
  parcel_y4: "Año 4",
  parcel_y5: "Año 5",
  parcel_y6: "AÑO 6",
  parcel_y7: "AÑO 7",
  parcel_y8: "AÑO 8",
  parcel_y9: "AÑO 9",
  parcel_y10: "Año 10",
  sa_clay_per: "Contenido de arcilla (%)",
  sa_sil_per: "Contenido de limo (%)",
  sa_if_clay: "Si el contenido de arcilla y limo no está disponible:",
  sa_text_desc: "Descripción de la textura",
  sa_sand: "Arena",
  sa_lo_sand: "Arena arcillosa",
  sa_sandy_lo: "Margen arenoso",
  sa_san_cl_loam: "Franco arcilloso",
  sa_loam: "Marga",
  sa_sil_loam: "Margen de limo",
  sa_silt_2: "Limo",
  sa_silt_cl_loam: "Marguinería de limo",
  sa_clay_3: "Arcilla",
  sa_sandy_clay: "Arcilla arenosa",
  sa_silty_clay: "Arcilla limosa",
  sa_bulk_density: "Densidad a Granel",
  sa_g_kg: "g / kg",
  sa_kg: "kg",
  sa_carbon: "Contenido de carbon",
  sa_kg_kg_per: "g / kg / %",
  sa_kg_2: "kg",
  sa_if_carbon: "Si el contenido de carbono no está disponible:",
  sa_organic: "Contenido de materia orgánica",
  sa_g_kg_per_2: "g / kg / %",
  sa_kg_3: "kg",
  nc_fuel_step_bold: "uso de combustible",
  nc_livestock_step_bold: "ganado",
  nc_soil_step_regular: "Compartir suelo",
  nc_soil_step_bold: "muestras",
  jan: "Enero",
  feb: "Febrero",
  mar: "Marzo",
  apr: "Abril",
  may: "Mayo",
  jun: "Junio",
  jul: "Julio",
  aug: "Agosto",
  sep: "Septiembre",
  oct: "Octubre",
  nov: "Noviembre",
  dec: "Diciembre",
  ls_first_year: "Este es mi primer año",
  ls_value_percent: "El valor debe estar entre 0 y 100",
  ls_value_year: "El valor debe estar entre 0 y 365",
  generic_edit: "Editar",
  generic_delete: "Borrar",
  values_fresh: "Fresco",
  values_dry: "Seco",
  generic_agree: "Aceptar",
  generic_disagree: "Discordar",
  ls_table_year1: "Y-1",
  ls_table_year2: "Y-2",
  ls_table_year3: "Y-3",
  ls_table_year4: "Y-4",
  ls_table_year5: "Y-5",
  ls_table_year6: "Y-6",
  ls_table_year7: "Y-7",
  ls_table_year8: "Y-8",
  ls_table_year9: "Y-9",
  ls_table_year10: "Y-10",
  carbon_y1: "Año 1",
  carbon_y2: "Año 2",
  carbon_y3: "3",
  carbon_y4: "Año 4",
  carbon_y5: "Año 5",
  carbon_y6: "Año 6",
  carbon_y7: "Año 7",
  carbon_y8: "Año 8",
  carbon_y9: "Año 9",
  carbon_y10: "Año 10",
  parcel_copy_sure: "¿Estás seguro de que quieres copiar las prácticas actuales?",
  parcel_copy_continue: "Estás a punto de copiar las prácticas actuales a los próximos 10 años. ¿Te gustaria continuar?",
  farmland_final_submission_msg_success: "Los detalles de la granja se han guardado con éxito. Ya no puede modificar los detalles de la granja. En caso de que tenga consultas, comuníquese con Climate Farmers Atención al cliente en support@climatefarmers.org",
  farmland_final_submission_msg_failure: "Hubo un error para guardar los detalles de la granja. Verifique los valores que ha ingresado o comuníquese con Climate Farmers Atención al cliente en support@climatefarmers.org",
  email_body_farmland_update: "Hola, tenemos una actualización para usted con respecto a sus tierras de cultivo. Inicie sesión en su cuenta de agricultores climáticos para ver la actualización.",
  infobox_coordinates_2: "Nota:",
  infobox_coordinates_4: "Norte",
  infobox_coordinates_6: "Este",
  infobox_ls_manure_2: "- En el campo:",
  infobox_ls_manure_3: "El estiércol se deja en pasto durante el pastoreo",
  infobox_ls_manure_4: "- Compostado:",
  infobox_ls_manure_7: "El estiércol se recolecta y aplica a campos y pastos como fertilizante",
  infobox_lu_copy_practices: "Aquí puede copiar sus entradas durante los próximos 10 años y luego hacer las correcciones para cada año y paquete. Asegúrese de deseleccionar esta opción si desea realizar cambios adicionales en los años siguientes (de lo contrario, el sistema no le permitirá hacerlo).",
  infobox_lu_type_1: "Silvopasture:",
  infobox_lu_type_2: "La integración de los árboles, el forraje y el pastoreo del ganado de una manera mutuamente beneficiosa. p.ej. El sistema Montado / Dehesa. Los pastos pueden pastar o cosechar.",
  infobox_lu_type_10: "Los pastos se pueden cosechar.",
  infobox_lu_type_11: "Silvicultura:",
  infobox_lu_type_12: "Bosques utilizados para la producción de madera. Sin pastoreo ni cosecha.",
  infobox_lu_cash_crop_1: "Cultivo comercial",

  home_your_c_app: "Tu inscripción a Carbon+",
  home_complete_onboarding: "Completa tu solicitud",
  home_tell_us: "Cuéntanos sobre tu finca",
  home_indicate: "Indica el uso de combustible",
  home_plan: "Planifica tu ganado",
  home_map: "Tus parcelas & plan de cultivos",
  home_share: "Comparte tus muestras de suelos",
  home_submit: "Envia tu solicitud",
  home_getting: "Primeros pasos",
  home_dashboard: "Guía de usuario",
  home_frequently: "Preguntas frecuentes (FAQ)",
  home_add_farm: "Agrega tu finca",
  home_welcome_to_cf: "Bienvenido a Climate Farmers",
  farminfo_please_enter: "Introduce la información sobre tu finca",
  farminfo_manager_fname: "Nombre del gerente de la finca",
  farminfo_manager_lname: "Apellido del gerente de la finca",
  farminfo_farmsize: "Tamaño de la finca en ha",
  farminfo_street: "Dirección de la finca y número de la calle (si corresponde)",
  farminfo_coord_north: "Coordenadas de la finca (°N)",
  farminfo_coord_east: "Coordenadas de la finca (°E)",
  farminfo_what_challenges: "¿Qué dificultades te impiden implementar (más) prácticas regenerativas en tu finca?",
  farminfo_describe: "Descripción",
  farminfo_save: "Guardar",
  fuelusage_save: "Guardar",
  ls_mgmt: "Géstion del ganado",
  ls_here_we: "Aquí necesitamos que nos facilites algunos detalles sobre las prácticas concretas de gestión ganadera que se aplican actualmente y los planes para los próximos 10 años.",
  ls_here_past: "Aquí necesitamos que nos facilites detalles sobre las prácticas anteriores de tu ganado y que las describas.",
  ls_how_many: "¿Desde hace cuántos años se practica la gestión ganadera existente?",
  ls_pls_desc: "Describe qué hacías de forma diferente antes de eso.",
  ls_livestock_mgmt_form: "Géstion del ganado",
  ls_autofill: "¿Te gustaría rellenar automáticamente los datos de los próximos 10 años?",
  ls_sheep: "Ovejas",
  ls_goats: "Cabras",
  ls_ducks: "Patos",
  ls_stabled_caged: "En el establo / Enjaulado",
  ls_stabled: "En el establo",
  ls_outdoors: "Corral al aire libre",
  ls_msg1: "Por favor, selecciona una fila. Si aún no hay filas, añade una nueva haciendo clic en 'Agregar Ganado'.",
  ls_msg2: "Por favor, selecciona una sola fila.",
  ls_msg3: "Por favor, selecciona una fila. Si aún no hay filas, añade una nueva haciendo clic en 'Futura gestión del ganado'.",
  ls_save: "Guardar",
  ls_close: "Cerrar",
  lu_map_location_msg: "La ubicación por defecto del mapa apunta a las coordenadas de tu finca: 7.12 °N, 12.25 °E. Si crees que la ubicación es incorrecta, escribe la dirección/coordenadas en la barra de búsqueda o vuelve a la sección 'Información basica' para actualizar tus coordenadas. Ten en cuenta que sólo las coordenadas en esta sección se consideran coordenadas predeterminadas de la finca.",

  lu_instrucns: "Instrucciones para crear una parcela",
  lu_how_parcel: "¿Cómo se crea una parcela?",
  lu_twoways: "Hay dos formas de crear una parcela. Son las siguientes:",
  lu_drawing: "Dibujar una parcela en el mapa",
  lu_make_sure: "Comprueba que tienes el archivo KML de tu finca.",
  lu_click: "Haz clic en el botón 'Importar un archivo KML'",
  lu_select: "Selecciona el archivo KML que quieres importar",
  lu_drawing_parcel: "Dibujar una parcela en el mapa",
  lu_select_drawer: "Selecciona el símbolo de dibujo en la esquina superior izquierda del mapa",
  lu_identify: "Identifica la ubicación de la parcela en tu finca y dibuja la parcela",
  lu_every: "Cada parcela se identifica por su propio color.",
  lu_when: "Cuando haces clic en una parcela, aparecerá una ventana emergente con el nombre de la parcela.",
  lu_i_have: "Tengo un archivo KMZ. ¿Puedo importarlo?",
  lu_no: "No. No puedes importar un archivo KMZ. Sin embargo, un archivo KMZ es una versión comprimida de un archivo KML. Cuando descomprimes el archivo KMZ en tu computadora, encontrarás el archivo KML. Te pedimos que subes el archivo KML.",
  lu_how_can: "¿Cómo puedo crear un archivo KML de mi finca?",
  lu_close: "Cerrar",
  lu_parcel: "Plantillas de parcela",
  lu_if_you: "Si tienes prácticas o cultivos que aplicas en varios parcelas, puedes crear y nombrar plantillas aquí y luego importarlas a tus parcelas.",
  lu_create_new: "Crear una nueva plantilla",
  lu_actions: "Acciones",
  temp_parcel_template: "Plantillas de parcela",
  temp_arable: "Cultivos herbáceos",
  temp_grass: "Praderas / Pastos",
  temp_hay: "Aplicación de paja / heno (t/ha)",
  temp_bale: "Pastoreo de pacas",
  temp_residue: "Residuos que quedan después del pastoreo de pacas (%)",
  temp_cover: "Cubierta vegetal",
  temp_desc: "Describe la mezcla (al menos 1 gramina y 1 leguminosa)",
  temp_multispecies: "Cubierta vegetal Multiespécies",
  temp_at_least: "al menos 3 tipos diferentes (familias de plantas)",
  temp_grazing_multi: "Pastoreo Multiespécies",
  temp_adaptive: "Pastoreo Adaptativo Multi-Parcelas",
  temp_cash_1: "Cultivo 1",
  temp_no_cash_1: "Sin cultivo",
  temp_aromatics: "Plantas aromáticas",
  temp_other_grains: "Otro cereal",
  temp_sorghum_sudan: "Sorghum sudanense",
  temp_cash_2: "Cultivo 2",
  temp_no_cash_2: "Sin Cultivo",
  temp_cover_crops: "Cubierta vegetal",
  temp_fallow: "Barbecho productivo ",
  temp_grazing: "Pastoreo",
  temp_whats_left: "Residuos después del pastoreo / cosecha (t/ha)",
  temp_bare_soil: "Barbecho (descubierto)",
  temp_tilling_event: "Labranza",
  temp_min_till: "Labranza minimal (<10 cm de profundidad)",
  temp_save: "Guardar",
  temp_close: "Cerrar",
  parcel_maps: "Parcelas",
  parcel_here: "Aquí te pedimos de compartir más detalles sobre tus parcelas:",
  parcel_you: "Puedes navegar entre los años en la parte superior de la ventana.",
  parcel_del: "Borrar todas las parcelas",
  parcel_actions: "Acciones",
  parcel_parcel_name: "Nombre de la parcela",
  parcel_parcelname: "Nombre de la parcela",
  parcel_area: "Superficie (m2)",
  parcel_choose: "Elijo introducir manualmente la superficie",
  parcel_manual: "Superficie introducida manualmente (m2)",
  parcel_import_data: "Importar datos",
  parcel_disagree: "Cancelar",
  parcel_disagree_2: "Cancelar",
  parcel_this_refers: "Esto se refiere al entorno agrícola de esta parcela",
  parcel_arable: "Cultivos herbáceos",
  parcel_grass: "Praderas / Pastos",
  parcel_hay: "Aplicación de paja / heno (t/ha)",
  parcel_bale: "Pastoreo de pacas",
  parcel_residue: "Residuos que quedan después del pastoreo de pacas (%)",
  parcel_cover: "Cubierta vegetal",
  parcel_desc: "Describe la mezcla (al menos 1 gramina y 1 leguminosa)",
  parcel_multispecies: "Cubierta vegetal Multiespécies",
  parcel_atleast: "al menos 3 tipos diferentes (familias de plantas)",
  parcel_adaptive: "Pastoreo Adaptativo Multi-Parcelas",
  parcel_cash_1: "Cultivo 1",
  parcel_no_cash_1: "Sin cultivo",
  parcel_aromatics: "Plantas aromáticas",
  parcel_other_grains: "Otro cereal",
  parcel_sorghum_sudan: "Sorghum sudanense",
  parcel_cash_2: "Cultivo 2",
  parcel_no_cash_2: "Sin Cultivo",
  parcel_cover_crops: "Cubierta vegetal",
  parcel_fallow: "Barbecho productivo ",
  parcel_grazing: "Pastoreo",
  parcel_whats_left: "Residuos después del pastoreo / cosecha (t/ha)",
  parcel_bare_soil: "Barbecho (descubierto)",
  parcel_tilling_event: "Labranza",
  parcel_min_till: "Labranza minimal (<10 cm de profundidad)",
  parcel_save: "Guardar",
  parcel_close: "Cerrar",
  sa_past: "Análisis de suelos anteriores",
  sa_if_you: "Si has realizado análisis de suelos en el pasado, completa la siguiente información a nivel de la finca (opcional)",
  sa_clay_loam: "Franco arcilloso",
  sa_g: "g",
  sa_g_2: "g",
  sa_per: "%",
  sa_g_3: "g",
  sa_per_3: "%",
  sa_save: "Guardar",
  generic_saving_data: "Guardando datos. Espera por favor",
  generic_save: "Guardar",
  dashboard_manual_pdf: "Guía de usuario",
  faq_pdf: "Preguntas frecuentes (FAQ)",
  nc_text_1_not_submitted: "Completa tu solicitud",
  nc_text_1_under_review: "Plan de transición enviado 🥳",
  nc_text_1_data_accepted: "Tus datos han sido aceptados",
  nc_text_2_not_submitted: "Cuéntanos sobre tu finca",
  nc_text_2_under_review: "En proceso de revisión",
  nc_text_bottom_under_review: "Tu solicitud de C+ ha sido enviada y está siendo revisada por nuestro equipo",
  nc_farminfo_step_regular: "Informaciónes básicas ",
  nc_farminfo_step_bold: "de tu finca",
  nc_fuel_step_regular: "Indica",
  nc_livestock_step_regular: "Planifica tu",
  nc_landuse_step_regular: "Mapea tus",
  nc_landuse_step_bold: "parcelas y plan de cultivo",
  generic_view: "Visualizar",
  lu_title: "Datos de gestión de la parcela",
  Close: "Cerrar",
  Disagree: "Cancelar",

  parcel_delete: "Estás a punto de borrar esta parcela. ¿Quieres continuar?",
  parcel_delete_sure: "¿Estás seguro de que quieres borrar todas las parcelas?",
  parcel_delete_continue: "Estás a punto de eliminar todas las parcelas. ¿Quieres continuar?",
  farmland_draft_submission_msg_success: "Los detalles de tu finca se han guardado como borrador. Puedes volver a visitar el formulario para realizar modificaciones antes de la submisión final",
  farmland_draft_submission_msg_failure: "Se ha producido un error al guardar los detalles del borrador. Verifica los valores que has ingresado o comunícate con nuestro equipo en support@climatefarmers.org",
  farmland_updated_by_farmer_liaison_success: "Los detalles de tu finca se actualizaron con éxito.",
  msg_mandatory_fields: "Todas las casillas marcadas como * son obligatorias y deben llenarse antes de continuar con el siguiente paso",
  email_subject: "Updates desde Climate Farmers",
  email_subject_farm_draft_submission: "Climate Farmers | Submisión de datos como borrador",
  email_subject_farm_final_submission: "Climate Farmers | Submisión final de datos",
  email_body_farmland_draft_submission: "Hola, has guardado los datos de tu finca como borrador. Puedes editar los detalles en cualquier momento. No dudes en responder a este correo electrónico si tienes alguna pregunta.",
  email_body_farmland_final_submission: "Hola, gracias por enviar los detalles de tu finca a Climate Farmers. Revisaremos la información y nos pondremos en contacto lo antes posible. Para cualquier pregunta, no dudes en responder a este correo electrónico.",
  infobox_parcel_temp_1: "Si algunas de tus parcelas tienen características recurrentes, como cultivos o prácticas, es útil crear plantillas.",
  infobox_coordinates_3: "El primer número es la coordenada",
  infobox_coordinates_5: ", y la segunda es la del",
  infobox_coordinates_7: ". Si tienes una coordenada oeste o sur, simplemente pon un negativo",
  infobox_coordinates_8: "(-) vor",
  infobox_coordinates_9: "esa coordenada. Asegúrate de ingresar cada número en la casilla correcta, de lo contrario, la ubicación de tu finca se mostrará incorrectamente en el mapa.",
  infobox_coordinates_10: 'Puedes verificar si las coordenadas son correctas en la página "Parcelas y uso de la tierra".',
  infobox_ls_mgmt_1: "Optionen zu Tierhaltungsformen",
  infobox_ls_mgmt_2: "- estabulado / enjaulado",
  infobox_ls_mgmt_4: "Esto describe la práctica de pastorear el ganado en un gran prado durante un periodo prolongado. También puedes seleccionar esta práctica si practicas pastoreo tradicional o rotacional pero sin un plan de pastoreo.",
  infobox_ls_mgmt_5: "- Pastoreo Adaptativo Multi-Parcela (Multi-Cercas)",
  infobox_ls_mgmt_6: "Se refiere a la división de grandes áreas de pastoreo en parcelas más pequeñas, utilizando vallado físico o eléctrico. La rotación del ganado se realiza de acuerdo con la densidad de población optimizada y la duración del pastoreo por parcela. La optimización se realiza en función de las características de las especies animales, los recursos naturales disponibles (evitar el sobrepastoreo y el sobredescanso), el clima y la topografía locales, el resultado deseado en la función del paisaje, las necesidades de recuperación de los pastos / la tasa de crecimiento diario de las plantas, el rendimiento del ganado y las necesidades de la fauna salvaje. Para planificar, registrar, controlar y adaptar en consecuencia el pastoreo se utiliza un  ",
  infobox_ls_mgmt_7: "plan de pastoreo",
  infobox_ls_mgmt_8: "(“Grazing Plan”) que debes enviarnos.",
  infobox_ls_manure_1: "Elige cómo se gestiona el estiércol en tu finca (en %):",
  infobox_ls_manure_5: "El estiércol se usa para el compostaje en la finca",
  infobox_ls_manure_6: "- aplicado directamente:",
  infobox_ls_manure_8: "- Colección y desecho:",
  infobox_ls_manure_9: "el estiércol se recoge y no se usa en la misma finca",
  infobox_lu_area_1: "El área de cada parcela se calculará automáticamente a partir del mapa que dibujaste. Si sientes que este número no es realista, a la derecha puedes cambiar manualmente el área de superficie",
  infobox_lu_area_2: "En metros cuadrados.",
  infobox_lu_parcels: 'Cuando dos parcelas tienen características iguales o similares, también puedez copiar rápidamente la información de una a otra seleccionando la parcela y hacer clic en "Copiar datos". De esta manera, la nueva parcela será una copia exacta de la anterior, y aún podrás hacer cambios.',
  infobox_lu_temp: 'Aquí, puedes importar cualquiera de las plantillas que has creado anteriormente seleccionándola y haciendo clic en "Importar datos". Recuerda que si modificas la plantilla después de copiarla en una parcela, los datos en ella no se verán afectados.',
  infobox_lu_type_3: "Cultivos herbáceos:",
  infobox_lu_type_4: "Cereal, judías, semillas, hortalizas o cultivos de fibra. Se puede pastorear o cosechar.",
  infobox_lu_type_5: "Pasto",
  infobox_lu_type_6: "Un área dominada por una cubierta casi continua de hierbas. Se puede pastorear o cosechar (heno).",
  infobox_lu_type_7: "Agroforestería",
  infobox_lu_per_manure: "Define cuánto (en %) del material que aplicas se trae desde fuera de la finca.",
  infobox_lu_bale_grz_1: "Pastoreo de pacas",
  infobox_lu_bale_grz_2: "El pastoreo de pacas es un método de proporcionar alimento al ganado vacuno durante los meses de invierno. Con este sistema, se permite al ganado acceder a las pacas previamente colocadas en un campo o lugar de invernada.",
  infobox_lu_bale_grz_3: "Residuos después del pastoreo de pacas (%)",
  infobox_lu_bale_grz_4: "Indica cuánto de la biomasa aplicada queda en el campo después del pastoreo.",
  infobox_lu_cash_crop_2: "Se refiere a un cultivo agrícola que se cultiva para vender con fines lucrativos.",
  infobox_lu_cover_crop_1: "Cubierta vegetal",
  infobox_lu_cover_crop_2: "Se refiere a la práctica de sembrar especies (al menos 2 especies diferentes, una gramínea y una leguminosa) cuyo uso está destinado principalmente a mejorar las características físicas, químicas y biológicas del suelo. Las cubiertas vegetales pueden ser pastoreadas, cosechadas, incorporadas al suelo, terminadas con un Roller-Crimper o dejadas como residuo después de la siega.",
  infobox_lu_multi_cover_crop_2: "Se refiere a la práctica de sembrar una mezcla diversa de especies cuyo uso está destinado principalmente a mejorar las características físicas, químicas y biológicas del suelo.",
  infobox_lu_multi_cover_crop_3: "Debe incluir ",
  infobox_lu_multi_cover_crop_4: "al menos 3 especies de plantas:",
  infobox_lu_multi_cover_crop_5: "- al menos una especie de gramínea (Poaceae)",
  infobox_lu_multi_cover_crop_7: "- al menos una de las siguientes familias Brassicaceae, Amaranthaceae, Linaceae, Plantaginaceae, Asteraceae, Polygonaceae, Boraginaceae, Malvaceae, Rosaceae, Cucurbitaceae",
  infobox_lu_unplanned_grz_2: "Se refiere al pastoreo de ganado en un prado grande durante un período prolongado.",
  infobox_lu_unplanned_grz_3: "También puedes seleccionar esta práctica si ya practicas pastoreo tradicional o rotacional pero sin un plan de pastoreo.",
  infobox_lu_multi_grz_2: "Se refiere al pastoreo de",
  infobox_lu_multi_grz_3: "al menos 2 especies diferentes",
  infobox_lu_multi_grz_4: " entre: rumiantes / ramoneadores, équidos, camélidos, cerdos de pasto o aves de corral.",
  infobox_lu_adapt_graz_1: "Pastoreo Adaptativo Multi-Parcelas (Adaptive Multi Paddock Grazing)",
  infobox_lu_adapt_graz_2: "Se refiere a la división de grandes zonas de pastoreo en parcelas más pequeñas, utilizando vallas físicas o eléctricas. La rotación del ganado se realiza de acuerdo con la densidad de población optimizada y la duración del pastoreo por parcela, teniendo en cuenta los recursos naturales disponibles (para evitar el sobrepastoreo y el sobredescanso).",
  infobox_lu_adapt_graz_3: "Para planificar, registrar, supervisar y adaptar el pastoreo en consecuencia, se utiliza un",
  infobox_lu_adapt_graz_4: "plan de pastoreo",
  infobox_lu_adapt_graz_5: "(Grazing Plan) que se debe presentar.",
  infobox_lu_check_cover: "Marca todos los meses donde cultivas una cubierta vegetal",
  infobox_lu_check_prod_fallow: "El barbecho productivo (Productive Fallow) se refiere a una cubierta vegetal que no se sembra intencionalmente, como gramíneas u otra vegetación espontánea.",
  infobox_lu_check_granzing: "Marca todos los meses con pastoreo en esta parcela.",
  infobox_lu_harvest_yield_1: "Rellena el rendimiento estimado de la cosecha (en toneladas por hectárea).",
  infobox_lu_grz_yield_1: "El rendimiento del pastoreo se refiere a lo que los animales han consumido después de estar en esta parcela.",
  infobox_lu_grz_yield_2: "Esto puede calcularse aproximadamente utilizando el número de animales y días de pastoreo o en toneladas de materia seca mediante muestreo y pesaje. La mayoría de los rumiantes consumen un 3% de su peso vivo en materia seca de forraje al día.",
  infobox_lu_whats_left: "Indica la cantidad de residuos que quedan en el campo tras la cosecha y/o el pastoreo en toneladas por hectárea.",
  infobox_lu_min_till_1: "Se refiere a una labranza reducida y de poca profundidad mediante arado, arado de disco, arado de cincel o cultivadora, con las siguientes limitaciones: hasta 10 cm de profundidad, si se labra toda la superficie; O si se labra más del 30% de la superficie, debe hacerse en franjas. “",
  infobox_lu_min_till_2: "Subsoiling'' está permitido siempre que se utilicen púas que no causen la destrucción de la estructura y estratificación del suelo.",
  pp_since: "Miembro de Carbon+ desde",
  pp_settings: "Configuración",
  nc_popup_sure: "¿Está seguro?",
  nc_popup_you: "Estás a punto de enviar tu solicitud de Carbon+",
  nc_popup_after: "Después de tu submisión, nuestro equipo comenzará a revisar tu solicitud.",
  nc_popup_not_1: "",
  nc_popup_not_2: "No",
  nc_popup_not_3: "podrás editar tu solicitud después de enviarla.",
  nc_popup_no: "No, continuar editando.",
  sb_signout: "Cerrar sesión",
  sb_home: "Inicio",
  sb_profile: "Perfil",
  pp_personal_info: "Informacion personal",
  pp_name: "Nombre oficial",
  pp_email: "Correo electrónico",
  pp_phone: "Número de teléfono",
  pp_language: "Idioma",
  generic_cancel: "Cancelar",
  pp_password: "Contraseña",

  home_provide: "Información básica sobre tu finca ",
  home_carbon_values: "Valores de carbono estimados para 10 años (toneladas)",
  ls_no_of_heads: "Número",
  ls_grazing: "Días de pastoreo por año",
  lu_a_common: "Lo más práctico para crear el archivo KML es utilizar Google Earth. Necesitas instalar Google Earth en tu ordenador. Ahí puedes crear las parcelas de tu explotación y exportar el archivo KML. En caso de que sólo puedas exportar el archivo KMZ, descomprime el archivo KMZ y sube el archivo KML presente en la carpeta descomprimida.",
  temp_silvopasture: "Sistema silvopastoril",
  temp_tree_avg_1: "DAP promedio de árbol en cm (diámetro a la altura del pecho)",
  temp_tree_avg_2: "DAP promedio de árbol en cm (diámetro a la altura del pecho)",
  temp_tree_avg_3: "DAP promedio de árbol en cm (diámetro a la altura del pecho)",
  temp_per_hay: "% de paja / heno importado",
  temp_may: "Mayo",
  temp_sillage: "Ensilaje de maíz",
  parcel_on: 'En la lista abajo, haz clic en "Editar" para agregar información a tus parcelas. Aquí, te pedimos que proporciones datos actuales (prácticas, cultivos, meses de pastoreo, etc.) y estimaciones para los próximos 10 años.',
  parcel_you_are_about: "Está a punto de copiar los datos de la parcela seleccionada a esta parcela. ¿Quieres continuar?",
  parcel_about_import: "Estás a punto de importar los datos de la plantilla a esta parcela. ¿Quieres continuar?",
  parcel_silvopasture: "Sistema silvopastoril",
  parcel_tree_avg_dbh: "DAP promedio de árbol en cm (diámetro a la altura del pecho)",
  parcel_tree_avg_1: "DAP promedio de árbol en cm (diámetro a la altura del pecho)",
  parcel_tree_avg_2: "DAP promedio de árbol en cm (diámetro a la altura del pecho)",
  parcel_per_hay: "% de paja / heno importado",
  parcel_grazing_multi: "Pastoreo Multiespécies",
  parcel_may: "Mayo",
  parcel_sillage: "ensilaje de maíz",
  parcel_pls_save: "Haz clic en el botón 'Guardar' para guardar tus datos antes de hacer clic en 'Cerrar'",

  infobox_parcel_temp_2: "De esta manera, podrás replicar estas plantillas en otras parcelas. Esta opción es útil, especialmente para rotaciones de cultivos, donde necesitas ingresar datos completamente diferentes para cada año.",
  infobox_parcel_temp_3: 'Para hacer eso, primero puedes crear una plantilla haciendo clic en el botón azul (justo debajo de tu mapa). Luego puedes crear y nombrar tantas plantillas diferentes como necesites. Recomendamos elegir nombres que sean fáciles de identificar, p. "Olivas - pastoreadas" o "cultivos cultivables - trigo".',
  infobox_coordinates_1: "Si no conoces las coordenadas, ve a Google Maps y haz clic en el botón derecho del mouse sobre tu finca. Haz clic en el botón del mouse izquierdo sobre los 2 números que aparecen en la primera línea para copiarlos y luego pégalos aquí.",
  infobox_ls_mgmt_3: "- Pastoreo Contínuo (Set Stock Grazing)",
  infobox_lu_type_8: "Un sistema en el que se cultivan árboles o arbustos entre cultivos o pastos. Incluye frutales, olivos, árboles de nueces u otras plantas perennes como las plantas aromáticas.",
  infobox_lu_type_9: "Define cuánto (en %) del material que aplica se trae desde fuera de la granja.",
  infobox_lu_multi_cover_crop_1: "Cubierta Vegetal Multiespécies",
  infobox_lu_multi_cover_crop_6: "- al menos una especie de leguminosa (Fabaceae)",
  infobox_lu_unplanned_grz_1: "Pastoreo Contínuo (Set Stock Grazing)",
  infobox_lu_multi_grz_1: "Pastoreo Multiespécies (Multi Species Grazing)",
  infobox_lu_check_bare_soil: "Marca todos los meses donde tu tierra queda descubierta.",
  infobox_lu_harvest_yield_2: "Si esta es una parcela de agroforestería, puedes dejar la casilla vacía.",
  nc_popup_yes: "Si, enviar..",

  infobox_farminfo_general: "Si hay algo incorrecto en estos datos, no dudes en ponerte en contacto con Maria a través de WhatsApp. Responderemos a tu mensaje durante el horario laboral.",
  infobox_farminfo_start_year_1: "Los créditos se calculan por años calendario. Puede elegir el año de inicio de su proyecto cuándo empezó a aplicar prácticas para mejorar el suelo y la productividad de su finca. Tenga en cuenta que los datos de los tres años anteriores al año de inicio de su proyecto se utilizarán para calcular su escenario 'de referencia', o el estado de la tierra antes de la regeneración. Por ejemplo, si inicia su proyecto en 2023, los tres años anteriores (2020, 2021 y 2022) se utilizarán para calcular los años de referencia.",
  infobox_farminfo_start_year_2: "Las mejoras con respecto a los años de referencia determinarán el número de créditos generados. Por lo tanto, es importante elegir cuidadosamente el año de inicio de su proyecto.",
  ver_doc_info_1: "Presenta ",
  ver_doc_info_2: "todos los documentos disponibles ",
  ver_doc_info_3: "que se solicitan en esta página. Si te faltan algunos documentos o necesitas ayuda, no dudes en ponerte en contacto con Maria por WhatsApp, responderemos a tu mensaje durante el horario laboral. Los documentos marcados con * son obligatorios para que puedas pasar al siguiente paso del proceso.",


  uploading_document_msg_success: "Document uploaded successfully.",
  uploading_document_msg_failure: "There was an error uploading your file. Please try again or contact the Climate Farmers Customer Support on support@climatefarmers.org",
  downloading_document_msg_success: "Document downloaded successfully.",
  downloading_document_msg_failure: "There was an error downloading your file. Please try again or contact the Climate Farmers Customer Support on support@climatefarmers.org",
  deleting_document_msg_success: "Document deleted successfully.",
  deleting_document_msg_failure: "There was an error deleting your file. Please try again or contact the Climate Farmers Customer Support on support@climatefarmers.org",

  generic_loading: "Cargando. Por favor, espera.",
  farminfo_farm_information: "Información sobre la finca",
  farminfo_farmname: "Nombre de la finca",

  ver_doc_title: "Documentos de verificación",
  ver_doc_your_farm: "Tu finca",
  ver_doc_land_ownership: "Acceso a la tierra. Documentos que demuestren que tienes acceso a la tierra al menos durante los próximos 5 años.*",

  ver_doc_land_ownership_info_box_1: "Los documentos deben reflejar el número de hectáreas para las que generará créditos.",
  ver_doc_land_ownership_info_box_2: "Si la tierra es de tu propiedad, debe ser el Extracto del registro de la propiedad a tu nombre (nombre que figura en el contrato).",
  ver_doc_land_ownership_info_box_3: "Si arriendas el terreno, el contrato no debe expirar antes de cinco años desde el año de inicio del proyecto Carbon +. Para los terrenos arrendados necesitamos:",
  ver_doc_land_ownership_info_box_4: "- El certificado catastral del propietario del terreno: Extracto del registro",
  ver_doc_land_ownership_info_box_5: "- El contrato de arrendamiento entre usted y el propietario del terreno.",
  ver_doc_land_ownership_info_box_6: "Formato: PDF/JPG",

  ver_doc_download: "Descargar",
  ver_doc_delete: "Borrar",
  ver_doc_upload: "Cargar nuevo documento",

  ver_doc_kml: "Mapa digital con parcelas.",
  ver_doc_kml_info_box_1: "Asegúrate de que el mapa contenga todas las parcelas de tu finca. En caso de que tengas las parcelas en mapas individuales KML es necesario agruparlas en un único archivo KML/KMZ.",
  ver_doc_kml_info_box_2: "Aprende a crear tu mapa digital en este vídeo: https://www.youtube.com/watch?v=4kT9JNCEHjA",
  ver_doc_kml_info_box_3: "Formato: KML/KMZ",

  ver_doc_cap: "PAC",
  ver_doc_cap_info: "Documentos de Solicitud Única para cada año del proyecto y para los tres años de referencia.",
  ver_doc_cap_info_box_1: "Los años de referencia son los tres anteriores al año de inicio de su proyecto y se utilizarán para calcular su escenario 'de referencia', es decir, el estado de la tierra antes de la regeneración.",
  ver_doc_cap_info_box_2: "Formato: PDF/JPG",

  ver_doc_livestock: "Animales",
  ver_doc_livestock_info: "Si tiene animales en su finca, suba los documentos oficiales de recuento de ganado de cada año.",
  ver_doc_livestock_info_box_1: "Para evaluar con precisión la población animal de tu finca, necesitamos documentación en la que se indique el número de animales por especie y año.",
  ver_doc_livestock_info_box_2: "Lo ideal es que estos documentos incluyan también los siguientes datos:",
  ver_doc_livestock_info_box_3: "- Fecha de nacimiento",
  ver_doc_livestock_info_box_4: "- Fecha de entrada en la finca",
  ver_doc_livestock_info_box_5: "- Fecha de salida de la finca ",
  ver_doc_livestock_info_box_6: "Esta información se puede obtener en los servicios agrícolas gubernamentales donde se registran los animales: Libro de Registro para tu C.A., o de tus documentos de la PAC.",
  ver_doc_livestock_info_box_7: "Formato: PDF/JPG",

  nc_ver_doc_step_regular: "Subir ",
  nc_ver_doc_step_bold: "documentos de verificación",

  home_ver_doc_submit: "Enviar para revisión",
  home_submit_popup_1: "Gracias por tu envío",
  home_submit_popup_2: "Nuestro equipo ha sido informado de tu envío y revisará tus datos en breve. No podrás modificar tus datos. En caso de que desees realizar algún cambio, puedes escribirnos y reabriremos la plataforma para ti.",

  baseline_years: "Años de referencia",
  project_years: "Años del proyecto",

  // farm wide info
  fwi_screen_title: "Información para toda la finca",
  fwi_screen_subtitle_1: "Facilita información de toda la finca sobre los fertilizantes sintéticos, las enmiendas orgánicas importadas, el forraje animal importados y las semillas. ",
  fwi_screen_subtitle_2: "Si no tienes factura y te seleccionan para una auditoría, tendrás que presentar una declaración por escrito de la compra de estos insumos.",
  fwi_farmSize_tooLarge: "La suma de las áreas de las parcelas ({{ calculatedFarmSize }}HA) es más del 10% mayor que el área de la granja especificada en su contrato ({{ contractFarmSize }}HA). Su mapa de la granja o contrato necesita ser revisado.",
  fwi_farmSize_largerWithinLimit: "La suma de las áreas de las parcelas ({{ calculatedFarmSize }}HA) es mayor que el área de la granja especificada en su contrato ({{ contractFarmSize }}HA) pero dentro del límite de tolerancia del 10%. Puede ajustar las áreas de las parcelas manualmente para reducir esta diferencia. De lo contrario, los tamaños de las parcelas se ajustarán automáticamente para coincidir con el tamaño de la granja.",
  fwi_nitrogen_fert_title: "Abono sintético nitrogenado*",
  fwi_nitrogen_fert_subtitle_1: "Facilita la cantidad y el % de nitrógeno de todos los abonos sintéticos nitrogenados ",
  fwi_nitrogen_fert_subtitle_2: "utilizados en ",
  fwi_nitrogen_fert_subtitle_3: "en toda la finca. Si no utilizaste ningún abono sintético a base de N, puedes indicar cero. ",
  fwi_nitrogen_fert_subtitle_4: "Sube las facturas como documentación que respalda de esta cantidad (PDF o JPG).",
  fwi_nitrogen_fert: "Abono sintético",
  fwi_quantity_tonnes: "en toneladas",
  fwi_percent_nitro: "% de nitrógeno",
  fwi_add_fert: "+ Agregar abono",
  fwi_upload_invoice: "Subir factura",
  fwi_percent_imported: "% importado",

  fwi_org_amend_title: "Fracción importada de las enmiendas orgánicas",
  fwi_org_amend_subtitle_1: "Para cada tipo de enmienda orgánica, indica el porcentaje que ",
  fwi_org_amend_subtitle_2: "importaste de fuera de tu explotación en ",
  fwi_org_amend_subtitle_3: ". Si no aplicaste la enmienda orgánica, puedes introducir cero. Si dispones de ella, sube la documentación respalda de esta cantidad (PDF o JPG).",
  fwi_org_amend_info: "Ejemplo: si compraste 5 toneladas de compost fuera de tu granja y produjiste 7 toneladas en tu granja, el porcentaje sería 5/12 = 42%.",
  fwi_biochar: "Biocarbón",
  fwi_percent: "%",
  fwi_compost: "Compost",
  fwi_manure: "Estiércol",
  fwi_mulch: "Mantillo",
  fwi_slurry: "Purín",
  fwi_documents: "Documentos",
  fwi_documents_info: "Ejemplos: facturas de compra de material orgánico, notas de campo, etc.",
  fwi_documents_upload: "Subir documento",

  fwi_animal_fod_title: "Forraje animal importado*",
  fwi_animal_fod_subtitle_1: "Indica el porcentaje de heno/paja utilizado para el pastoreo en balas que ",
  fwi_animal_fod_subtitle_2: "importaste de fuera de tu explotación en ",
  fwi_animal_fod_subtitle_3: ". Si no utilizaste heno/paja para el pastoreo en pacas, puedes introducir cero. Si dispones de ellas, sube las facturas como documentación para respaldar de esta cantidad (PDF o JPG).",
  fwi_animal_fod_info: "Ejemplo: si compraste 30 toneladas de paja fuera de tu explotación y produjiste tú mismo 20 toneladas, el porcentaje sería 30/50 = 60%",
  fwi_hay: "Heno/paja",
  fwi_invoices: "Facturas",

  fwi_seeds_title: "Semillas",
  fwi_seeds_subtitle: "Proporciona las facturas de las semillas de los cultivos anuales que cultivaste en ",


  // parcel map overview
  pmo_map_title: "Mapa de parcelas",
  pmo_map_subtitle: 'El siguiente mapa muestra todas las parcelas que has cargado. Puedes cambiar el nombre de tu parcela haciendo clic sobre ella en el mapa. Rellena la sección "Información de toda la finca" para tu finca, y para cada parcela, completa la página de "Información general" así como la de "Cultivos".',
  pmo_dig_maps: "Mapa digital con parcelas",
  pmo_map_info_1: "Asegúrate de que el mapa contenga todas las parcelas de tu finca. En caso de que tengas las parcelas en mapas individuales KML es necesario agruparlas en un único archivo KMZ.",
  pmo_map_info_2: "Aprende a crear tu mapa digital en este vídeo: https://www.youtube.com/watch?v=4kT9JNCEHjA.",

  pmo_popup_title: "Parcela",
  pmo_popup_name: "[Nombre: ",
  pmo_popup_rename: "Editar parcela",
  pmo_popup_delete: "Eliminar parcela",
  pmo_popup_search: "Buscar",
  pmo_fwi: "Información de toda la explotación",
  pmo_fwi_farm_from: "Finca de ",
  pmo_fwi_complete: "Introducir datos",
  pmo_list_title: "Lista de parcelas",
  pmo_list_subtitle: "Aquí puedes ver un resumen de tus parcelas. Comprueba si el número de hectáreas es correcto y proporciona la Información General y de Cultivos de cada parcela",
  pmo_list_info: "Haciendo clic en el icono del lápiz puedes cambiar el nombre de la parcela, indicar una superficie diferente o eliminarla.",
  pmo_list_gen_info: "Información general",
  pmo_list_crops: "Cultivos",
  pmo_edit_title: "Datos de la parcela",
  pmo_edit_description: "Puedes cambiar el nombre de la parcela, informar de una superficie diferente o eliminarla.",
  pmo_edit_ha_auto: " HA (calculado a partir del mapa)",
  pmo_edit_ha_manual: "Si la superficie de parcela calculado es incorrecto, indica a continuación el tamaño de parcela correcto (en hectáreas): ",

  pmo_delete_map_heading: "Borrar mapa",
  pmo_delete_map_message: "¿Estás seguro de que quieres borrar el mapa? Esto también eliminará toda la información ingresada en las parcelas",
  pmo_delete_map_no: "No, conservar el mapa y la información",
  pmo_delete_map_yes: "Si, borrar el mapa y la información",
  pmo_delete_parcel_message: "¿Estás seguro de que quieres borrar el mapa? Esto también eliminará toda la información ingresada en las parcelas",
  pmo_delete_parcel_no: "No, conservar la parcela y la información",
  pmo_delete_parcel_yes: "Si, borrar la parcela y la información",
  //parcels - general info
  pgi_back_button: "< Volver",
  pgi_screen_title_1: "Parcela ",
  pgi_screen_title_2: "Información general",
  pgi_crops_info_button: "Información sobre cultivos >",
  pgi_subtitle: 'Proporciona información sobre el uso de la tierra, las enmiendas orgánicas, el forraje, el pastoreo, el laboreo, el riego y los árboles talados de esta parcela. Si la información es la misma para un año o parcela completada anteriormente, puedes copiar los datos haciendo clic en "copiar datos de otro año / parcela".',
  pgi_copy_data_button_1: "Copiar datos de otro año / parcela",
  pgi_pre_fill_data_pop_up: "Copiar información de la parcela",
  pgi_pre_fill_data_pop_up_desc: 'Selecciona los años y la parcela de la cual quieres copiar información para completar esta parcela. Si seleccionas "todos los años", los datos de la parcela seleccionada se copiarán en la parcela actual para todos los años.',
  pgi_copy_to: "Completar",
  pgi_copy_data_to_parcel: "(Parcela actual)",
  pgi_copy_data_with_data: "con datos de",
  pgi_copy_data_with_years_dropdown: "Año",
  pgi_copy_data_all_years: "Todos los años",
  pgi_copy_data_button_2: "Copiar datos",
  pgi_warning_overwrite_1: "Aviso sobrescribir datos",
  pgi_warning_overwrite_2: "Ya has completado algunos datos sobre esta parcela. Al copiar datos de otra parcela, esta información se borrará, exeptuando la información sobre plantas perennes. ¿Estás seguro de que quieres continuar?",
  general_continue_button: "Continuar",
  pgi_cancel_button: "Cancelar",
  pgi_landuse_title: "Uso del suelo",
  pgi_landuse_subtitle: "Selecciona el uso del suelo primario y en caso que corresponda uno secundario de esta parcela en ",
  pgi_primary_lu: "Uso primario del suelo*",
  pgi_secondary_lu: "Uso secundario del suelo",
  pgi_lu_type_dd_name: "Tipo de uso de la tierra",
  pgi_lu_type_dd_value_1: "Silvopastoril",
  pgi_lu_type_dd_value_2: "Cultivos",
  pgi_lu_type_dd_value_3: "Pastizal",
  pgi_lu_type_dd_value_4: "Agroforestería / Cultivos arbóreos",
  pgi_lu_type_dd_value_5: "Silvicultura",
  pgi_organic_amendments_title: "Enmiendas orgánicas",
  pgi_organic_amendments_desc_1: "Proporciona información sobre toda la materia orgánica que has <strong>añadido</strong> a esta parcela como <strong>enmienda del suelo</strong> en {{year}}.<br/>Nota: Esto <strong>no incluye</strong> los residuos de cultivos <strong>dejados</strong> en la parcela tras la cosecha o la poda.",
  pgi_organic_amendments_biochar: "Biocarbón",
  pgi_organic_amendments_biochar_qty: "Cantidad añadida",
  pgi_organic_amendments_biochar_tonnes: "toneladas",
  pgi_organic_amendments_compost: "Compost",
  pgi_organic_amendments_compost_info: "Si quieres eliminar un tipo de compost, selecciona el guión (-) en el tipo de compost.",
  pgi_organic_amendments_compost_type_dd: "Tipo de compost",
  pgi_organic_amendments_compost_type_dd_1: "Compost verde / a base de plantas",
  pgi_organic_amendments_compost_type_dd_2: "Compost a base de estiércol",
  pgi_organic_amendments_compost_type_dd_3: "Compost de champiñones",
  pgi_organic_amendments_compost_type_qty: "Cantidad añadida",
  pgi_organic_amendments_compost_type_tonnes: "toneladas",
  pgi_organic_amendments_compost_type_add: "Agregar tipo de compost",


  pgi_organic_amendments_manure: "Estiércol",
  pgi_organic_amendments_manure_info: "Si quieres eliminar un tipo de estiércol, selecciona el guión (-) en el tipo de estiércol.",
  pgi_organic_amendments_manure_type_dd: "Tipo de estiércol",
  pgi_organic_amendments_manure_type_dd_1: "Estiércol de vaca",
  pgi_organic_amendments_manure_type_dd_2: "Estiércol de pollo",
  pgi_organic_amendments_manure_type_dd_3: "Estiércol de caballo",
  pgi_organic_amendments_manure_type_dd_4: "Estiércol de cerdo",
  pgi_organic_amendments_manure_type_dd_5: "Estiércol de oveja",
  pgi_organic_amendments_manure_type_qty: "Cantidad añadida",
  pgi_organic_amendments_manure_type_tonnes: "toneladas",
  pgi_organic_amendments_manure_type_add: "Agregar tipo de estiércol",

  pgi_organic_amendments_mulch: "Mantillo",
  pgi_organic_amendments_mulch_info: "Si quieres eliminar un tipo de mantillo, selecciona el guión (-) en el tipo de mantillo.",
  pgi_organic_amendments_mulch_type_dd: "Tipo de mantillo",
  pgi_organic_amendments_mulch_type_dd_1: "Heno / Paja",
  pgi_organic_amendments_mulch_type_dd_2: "Hojas",
  pgi_organic_amendments_mulch_type_dd_3: "Corteza de madera / Astillas de madera",
  pgi_organic_amendments_mulch_type_qty: "Cantidad añadida",
  pgi_organic_amendments_mulch_type_tonnes: "toneladas",
  pgi_organic_amendments_mulch_type_add: "Agregar un tipo de mantillo",

  pgi_organic_amendments_slurry: "Purín",
  pgi_organic_amendments_slurry_info: "Si quieres eliminar un tipo de purín, selecciona el guión (-) en el tipo de purín y la fila no se guardará.",
  pgi_organic_amendments_slurry_type_dd: "Tipo de purín",
  pgi_organic_amendments_slurry_type_dd_1: "Purín de vaca",
  pgi_organic_amendments_slurry_type_dd_2: "Purín de cerdo",
  pgi_organic_amendments_slurry_type_qty: "Cantidad añadida",
  pgi_organic_amendments_slurry_type_tonnes: "litros",
  pgi_organic_amendments_slurry_type_add: "Agregar un tipo de purín",

  pgi_organic_amendments_other: "Otros",
  pgi_organic_amendments_other_info: "Añade todos los tipos de enmiendas del suelo que no figuren en la lista anterior. Especifica el tipo de materia orgánica y la cantidad añadida. Ejemplos: subproductos de instalaciones de producción como el alperujo, restos de la industria alimentaria, etc.",
  pgi_organic_amendments_soil_text: "Especifica el tipo de enmienda del suelo",
  pgi_organic_amendments_other_qty: "Cantidad añadida",
  pgi_organic_amendments_other_qty_tonnes: "toneladas",
  pgi_organic_amendments_other_add: "Agregar otro",

  pgi_organic_amendments_animal_fodder: "Forraje animal",
  pgi_organic_amendments_af_desc: "Indica la cantidad de heno y paja puesta en esta parcela para el pastoreo en pacas en ",
  pgi_organic_amendments_af_info: 'Ten en cuenta que se refiere a la cantidad de heno y paja introducida en la parcela para el <strong>pastoreo en pacas</strong>. Si has añadido heno o paja como enmienda del suelo, puedes añadirlo en la sección anterior bajo "mantillo".',
  pgi_organic_amendments_af_2: "Forraje animal",
  pgi_organic_amendments_af_qty: "Cantidad de heno / paja agregada en el campo",
  pgi_organic_amendments_af_tonnes: "toneladas",


  pgi_organic_amendments_grazing: "Pastoreo",
  pgi_organic_amendments_grazing_desc_1: "Selecciona los meses en los que se pastoreó esta parcela durante el ",
  pgi_organic_amendments_grazing_desc_2: ", si lo hubo.",
  pgi_organic_amendments_grazing_months: "Meses de pastoreo",
  pgi_organic_amendments_grazing_choose: "Elige",
  pgi_organic_amendments_grazing_months_dd: "Meses (se permite selección múltiple)",
  pgi_organic_amendments_grazing_months_1: "Enero",
  pgi_organic_amendments_grazing_months_2: "Febrero",
  pgi_organic_amendments_grazing_months_3: "Marzo",
  pgi_organic_amendments_grazing_months_4: "Abril",
  pgi_organic_amendments_grazing_months_5: "Mayo",
  pgi_organic_amendments_grazing_months_6: "Junio",
  pgi_organic_amendments_grazing_months_7: "Julio",
  pgi_organic_amendments_grazing_months_8: "Agosto",
  pgi_organic_amendments_grazing_months_9: "Septiembre",
  pgi_organic_amendments_grazing_months_10: "Octubre",
  pgi_organic_amendments_grazing_months_11: "Noviembre",
  pgi_organic_amendments_grazing_months_12: "Diciembre",

  pgi_organic_amendments_tillage: "Labranza",
  pgi_organic_amendments_tillage_desc_1: "Añade todos los eventos de labranza en esta parcela durante el ",
  pgi_organic_amendments_tillage_desc_2: ", si los hubo.",
  pgi_organic_amendments_tillage_events: "Eventos de labranza",
  pgi_organic_amendments_tillage_info: "Para cada evento de labranza indica:<br/><br/>Mes - El mes en que se labró el suelo.<br/><br/>Tipo - Laboreo profundo si la alteración del suelo fue superior a 10 cm por debajo de la superficie y Laboreo mínimo si fue inferior a 10 cm de profundidad.<br/><br/>Porcentaje - Porcentaje de la superficie de la parcela que se labró. Si se labró toda la parcela, puedes indicar el 100%. Si sólo se labró una parte de la parcela, (ej: por riesgo de incendio o por laboreo en franjas) indica el porcentaje.<br/><br/>Si quieres eliminar un evento de labranza, selecciona el guión (-) en el mes.",
  pgi_organic_amendments_tillage_months: "Mes",
  pgi_organic_amendments_tillage_months_1: "Enero",
  pgi_organic_amendments_tillage_months_2: "Febrero",
  pgi_organic_amendments_tillage_months_3: "Marzo",
  pgi_organic_amendments_tillage_months_4: "Abril",
  pgi_organic_amendments_tillage_months_5: "Mayo",
  pgi_organic_amendments_tillage_months_6: "Junio",
  pgi_organic_amendments_tillage_months_7: "Julio",
  pgi_organic_amendments_tillage_months_8: "Agosto",
  pgi_organic_amendments_tillage_months_9: "Septiembre",
  pgi_organic_amendments_tillage_months_10: "Octubre",
  pgi_organic_amendments_tillage_months_11: "Noviembre",
  pgi_organic_amendments_tillage_months_12: "Diciembre",
  pgi_organic_amendments_tillage_type_dd: "Tipo de labranza",
  pgi_organic_amendments_tillage_type_dd_1: "Labranza mínima (<10 cm)",
  pgi_organic_amendments_tillage_type_dd_2: "Labranza profunda (>10 cm)",
  pgi_organic_amendments_tillage_percentage: "Porcentaje",
  pgi_organic_amendments_tillage_add: "Agregar evento de labranza",

  pgi_organic_amendments_irrigation: "Riego",
  pgi_organic_amendments_irrigation_desc: "Selecciona si esta parcela se regó o no en el ",
  pgi_organic_amendments_irrigation_label: "Riego",
  pgi_organic_amendments_irrigation_dd_1: "Sí",
  pgi_organic_amendments_irrigation_dd_2: "No",

  pgi_organic_amendments_felled_trees: "Árboles talados",
  pgi_organic_amendments_felled_trees_desc_1: "Si se talaron árboles en esta parcela en ",
  pgi_organic_amendments_felled_trees_desc_2: ", indica por especie de árbol el número de árboles talados y el diámetro medio a la altura del pecho (DAP) de los árboles en cm.",
  pgi_organic_amendments_felled_trees_label: "Árboles talados",
  pgi_organic_amendments_felled_trees_info: "Diámetro a la Altura del Pecho (DAP) es el diámetro del tronco de un árbol a 1,3 metros del suelo. Indica el DAP medio de los árboles talados en cm.<br/><br/>Si quieres eliminar una especie de árbol, selecciona el guión (-) en el desplegable de especies de árboles y la fila no se guardará.",
  pgi_organic_amendments_felled_trees_dd: "Especies de árboles",
  pgi_organic_amendments_felled_trees_dd_1: "Fresno",
  pgi_organic_amendments_felled_trees_dd_2: "Eucalipto",
  pgi_organic_amendments_felled_trees_dd_3: "Algarrobo",
  pgi_organic_amendments_felled_trees_dd_4: "Alcornoque Roble",
  pgi_organic_amendments_felled_trees_dd_5: "Ciprés",
  pgi_organic_amendments_felled_trees_dd_6: "Encina",
  pgi_organic_amendments_felled_trees_dd_7: "Arce/Sicómoro",
  pgi_organic_amendments_felled_trees_dd_8: "Pino",
  pgi_organic_amendments_felled_trees_dd_9: "Frutal - Manzano",
  pgi_organic_amendments_felled_trees_dd_10: "Frutal - Albaricoque",
  pgi_organic_amendments_felled_trees_dd_11: "Frutal - Aguacate",
  pgi_organic_amendments_felled_trees_dd_12: "Frutal - Clementina",
  pgi_organic_amendments_felled_trees_dd_13: "Frutal - Palmera datilera",
  pgi_organic_amendments_felled_trees_dd_14: "Frutal - Higo",
  pgi_organic_amendments_felled_trees_dd_15: "Frutal - Limonero",
  pgi_organic_amendments_felled_trees_dd_16: "Frutal - Mandarina",
  pgi_organic_amendments_felled_trees_dd_17: "Frutal - Mango",
  pgi_organic_amendments_felled_trees_dd_18: "Frutal - Olivo",
  pgi_organic_amendments_felled_trees_dd_19: "Frutal - Naranjo",
  pgi_organic_amendments_felled_trees_dd_20: "Frutal - Melocotón",
  pgi_organic_amendments_felled_trees_dd_21: "Frutal - Pera",
  pgi_organic_amendments_felled_trees_dd_22: "Frutal - Granada",
  pgi_organic_amendments_felled_trees_dd_23: "Frutal - Pomelo",
  pgi_organic_amendments_felled_trees_dd_24: "Frutal - Otro",
  pgi_organic_amendments_felled_trees_dd_25: "Frutal - Almendro",
  pgi_organic_amendments_felled_trees_dd_26: "Nogal - Castaño",
  pgi_organic_amendments_felled_trees_dd_27: "Nogal - Avellano",
  pgi_organic_amendments_felled_trees_dd_28: "Nogal - Pistacho",
  pgi_organic_amendments_felled_trees_dd_29: "Nogal",
  pgi_organic_amendments_felled_trees_dd_30: "Nogal - Otro",
  pgi_organic_amendments_felled_trees_dd_31: "Otro árbol",
  pgi_organic_amendments_felled_trees_specific_name: "Nombre específico",
  pgi_organic_amendments_felled_trees_number: "Número de árboles",
  pgi_organic_amendments_felled_trees_add_another: "Agregar otra especie de árbol",
  pgi_organic_amendments_save: "Guardar",

  //parcels - crop info
  pci_back: "< Volver",
  pci_screen_title_1: "Parcela ",
  pci_screen_title_2: "Información sobre cultivos",
  pci_crops_info_btn: "Información general de la parcela >",
  pci_subtitle: 'Proporciona información sobre cultivos perennes y anuales, pastos, praderas permanentes y periodos de barbecho para esta parcela específica. Si la información es la misma para un año o parcela enviada anteriormente, puedes copiar los datos haciendo clic en "copiar datos de otro año / parcela".',
  pci_copy_data: "Copiar datos de otro año / parcela",
  pci_pre_fill: "Copiar información de la parcela",
  pci_pre_fill_1: "Selecciona los años y la parcela de la cual quieres copiar información para completar esta parcela.",
  pci_pre_fill_2: 'Si seleccionas "todos los años", los datos de la parcela seleccionada se copiarán en la parcela actual para todos los años.',
  pci_year: "Año",
  pci_all_years: "Todos los años",
  pci_copy_data_btn: "Copiar datos",
  pci_copy_data_overwrite_heading: "Aviso sobrescribir datos",
  pci_copy_data_overwrite_msg: "Ya has completado algunos datos sobre esta parcela. Al copiar datos de otra parcela, esta información se borrará, exeptuando la información sobre plantas perennes. ¿Estás seguro de que quieres continuar?",
  pci_copy_data_overwrite_continue: "Continuar",
  pci_copy_data_overwrite_cancel: "Cancelar",
  pci_logic: 'Árbol',
  pci_perennial_title_1: "Árboles y cultivos perennes | Total ",
  pci_perennial_title_2: " árboles",
  pci_perennial_tree_label: "Nuevo árbol o cultivo perenne",
  pci_perennial_subtitle: "Añade todos los árboles y cultivos perennes de esta parcela. Puedes excluir los árboles de sistemas diversos en los que la edad o la especie de los árboles sean variables (p. ej. dehesa/montado o sistemas forestales). Los cultivos arbóreos se agrupan en cohortes por especie y año de plantación (p. ej. Frutal - Olivo, 2019).",
  pci_perennial_info: "Los cultivos arbóreos se agrupan en cohortes por especie y año de plantación (por ejemplo, Árbol Frutal - Olivo, 2019) y aparecerán automáticamente en los próximos años. En los próximos años puedes ajustar:<br/>- El número de árboles si se eliminaron árboles de esta grupo<br/>- El Diámetro a la Altura del Pecho (DAP) en función del crecimiento de los árboles<br/>También puedes eliminar la cohorte si todos los árboles ya no están en la parcela.<br/><br/>Los cultivos perennes añadidos en años anteriores aparecerán automáticamente en los años siguientes. Puedes borrarlos si ya no están en la parcela.",
  pci_perennial_subsection_heading: "Cultivo perenne / Tipo de árbol / Año de plantación",
  pci_perennial_subsection_delete: "Borrar",
  pci_perennial_subsection_desc: "Descripción*",
  pci_perennial_subsection_info: "Vida útil de los árboles - Si la plantación se replanta periódicamente, indica la edad típica de los árboles a la que está planeado renovarlos (por ejemplo, vida útil de los manzanos - 13 años)<br/><br/>Si los árboles son permanentes y no se retiran periódicamente, puedes dejar este campo en blanco.",
  pci_perennial_tree_list_dd: "Plantas y árboles perennes*",
  "herbaceous perennials - strawberry": "Herbáceas perennes - Fresa",
  "herbaceous perennials - other": "Herbáceas perennes - Otros",
  "bushes/shrubs - blackberry": "Arbustos - Zarzamora",
  "bushes/shrubs - blueberry": "Arbustos - Arándano",
  "bushes/shrubs - broom": "Arbustos - Retama",
  "bushes/shrubs - chamise/greasewood": "Arbustos - Chamizo / Adelfa",
  "bushes/shrubs - heather/erica": "Arbustos - Brezo",
  "bushes/shrubs - laustrine": "Arbustos - Lauro",
  "bushes/shrubs - lavender": "Arbustos - Lavanda",
  "bushes/shrubs - lentisk/mastic": "Arbustos - Lentisco",
  "bushes/shrubs - rockrose": "Arbustos - Jara",
  "bushes/shrubs - rosemary": "Arbustos - Romero",
  "bushes/shrubs - sagebrush": "Arbustos - Artemisa",
  "bushes/shrubs - other": "Arbustos - Otros",
  "fruit tree - apple": "Frutal - Manzano",
  "fruit tree - apricot": "Frutal - Albaricoque",
  "fruit tree - avocado": "Frutal - Aguacate",
  "fruit tree - clementine": "Frutal - Clementina",
  "fruit tree - date palm": "Frutal - Palmera datilera",
  "fruit tree - fig": "Frutal - Higo",
  "fruit tree - lemon": "Frutal - Limón",
  "fruit tree - mandarin": "Frutal - Mandarina",
  "fruit tree - mango": "Frutal - Mango",
  "fruit tree - olive": "Frutal - Oliva",
  "fruit tree - orange": "Frutal - Naranja",
  "fruit tree - peach": "Frutal - Melocotón",
  "fruit tree - pear": "Frutal - Pera",
  "fruit tree - pomegranate": "Frutal - Granada",
  "fruit tree - pomelo": "Frutal - Pomelo",
  "fruit tree - other": "Frutal - Otros",
  "nut tree - almond": "Árbol de frutos secos - Almendra",
  "nut tree - chestnut": "Árbol de frutos secos - Castaño / Marrón",
  "nut tree - hazelnut": "Árbol de frutos secos - Avellano",
  "nut tree - pistachio": "Árbol de frutos secos - Pistacho",
  "nut tree - walnut": "Árbol de frutos secos - Nuez",
  "nut tree - other": "Árbol de frutos secos - Otros",
  "tree - ash": "Árbol - Fresno",
  "tree - eucalyptus": "Árbol - Eucalipto",
  "tree - carob": "Árbol - Algarrobo",
  "tree - cork oak": "Árbol - Alcornoque",
  "tree - cypress": "Árbol - Ciprés",
  "tree - holm oak": "Árbol - Encina",
  "tree - maple/sycamore": "Árbol - Arce / Plátano",
  "tree - pine": "Árbol - Pino",
  "tree - poplar": "Árbol - Álamo",
  "tree - other": "Árbol - Otros",
  "vine - grapevine": "Vid - Viñas",
  "vine - other": "Vid - Otros",
  "other perennial crop": "Otro cultivo perenne",
  pci_perennial_tree_list_specific: "Nombre específico",
  pci_perennial_tree_list_year_planting: "Año de plantación*",
  pci_perennial_tree_list_year_before: "Antes de 2018",
  pci_perennial_tree_list_tree_span: "Vida útil del árbol",
  pci_perennial_tree_list_tree_span_yrs: "años",
  pci_perennial_tree_list_number_growth: "Número y crecimiento*",
  pci_perennial_tree_list_number_growth_info: "El diámetro a la altura del pecho (DAP) es el diámetro del tallo de un árbol a 1,3 metros del suelo. Indica el DAP medio de la cohorte de árboles en cm. Para los cultivos de olivos, indica el diámetro medio del tronco del árbol en su base en cm.",
  pci_perennial_tree_list_number_trees: "Número de árboles",
  pci_perennial_tree_list_dbh_field: "DAP medio",
  pci_perennial_tree_list_dbh_field_cm: "cm",
  pci_perennial_tree_list_harvest: "Cosecha*",
  pci_perennial_tree_list_harvest_desc: "Material orgánico que se retiró de la parcela",
  pci_perennial_tree_list_harvest_info: "Cosecha se define como la materia orgánica que se retiró de la parcela. Agrega todas las cosechas de esta perenne para este año indicando:<br/><br/>Mes - Mes en el que tuvo lugar la cosecha.<br/><br/>Tipo de cosecha - Tipo de material que se cosechó.<br/><br/>Cantidad - La cantidad que se cosechó en toneladas de peso fresco o seco. Ten en cuenta que seco significa completamente deshidratado para que no quede humedad.<br/><br/>Si no hubo cosecha, indica cero como cantidad.<br/><br/>Si quieres borrar una cosecha, selecciona el guión (-) en el mes.",
  pci_perennial_tree_list_harvest_month: "Mes",
  pci_perennial_tree_list_harvest_month_jan: "Enero",
  pci_perennial_tree_list_harvest_month_feb: "Febrero",
  pci_perennial_tree_list_harvest_month_mar: "Marzo",
  pci_perennial_tree_list_harvest_month_apr: "Abril",
  pci_perennial_tree_list_harvest_month_may: "Mayo",
  pci_perennial_tree_list_harvest_month_jun: "Junio",
  pci_perennial_tree_list_harvest_month_jul: "Julio",
  pci_perennial_tree_list_harvest_month_aug: "Agosto",
  pci_perennial_tree_list_harvest_month_sep: "Septiembre",
  pci_perennial_tree_list_harvest_month_oct: "Octubre",
  pci_perennial_tree_list_harvest_month_nov: "Noviembre",
  pci_perennial_tree_list_harvest_month_dec: "Diciembre",
  pci_perennial_tree_list_harvest_type: "Tipo de cosecha",
  pci_perennial_tree_list_harvest_type_dd_1: "Fruta",
  pci_perennial_tree_list_harvest_type_dd_2: "Frutos secos",
  pci_perennial_tree_list_harvest_type_dd_3: "Corteza / Corcho",
  pci_perennial_tree_list_harvest_type_dd_4: "Hojas, tallos, hierbas (no leñosas)",
  pci_perennial_tree_list_harvest_type_dd_5: "Poda o material leñoso",
  pci_perennial_tree_list_harvest_type_dd_6: "Hierba",
  pci_perennial_tree_list_harvest_type_qty: "Cantidad",
  pci_perennial_tree_list_harvest_fresh_dry_1: "Toneladas - Fresco",
  pci_perennial_tree_list_harvest_fresh_dry_2: "Toneladas - Seco",
  pci_perennial_tree_list_harvest_fresh_add: "Agregar cosecha",
  pci_perennial_tree_list_residue: "Residuo",
  pci_perennial_tree_list_residue_desc: "Material orgánico que queda en el campo",
  pci_perennial_tree_list_residue_info: "Residuo se define como el material vegetal/orgánico que se dejó en el campo. Agrega todos los residuos de esta planta perenne para el año que estás editando e indícalo:<br/><br/>Mes - El mes en el que el residuo se dejó en el campo.<br/><br/>Tipo de cosecha - Tipo de material del residuo.<br/><br/>Cantidad - La cantidad de residuos que se dejó en el campo en toneladas de peso fresco o seco. Ten en cuenta que seco significa completamente deshidratado, de modo que no queda humedad.<br/><br/>Si no hubo residuos, indica cero en la cantidad o deja esta fila en blanco.<br/><br/>Si quieres eliminar un residuo, selecciona el guión (-) en el mes.",
  pci_perennial_tree_list_residue_month: "Mes",
  pci_perennial_tree_list_residue_month_jan: "Enero",
  pci_perennial_tree_list_residue_month_feb: "Febrero",
  pci_perennial_tree_list_residue_month_mar: "Marzo",
  pci_perennial_tree_list_residue_month_apr: "Abril",
  pci_perennial_tree_list_residue_month_may: "Mayo",
  pci_perennial_tree_list_residue_month_jun: "Junio",
  pci_perennial_tree_list_residue_month_jul: "Julio",
  pci_perennial_tree_list_residue_month_aug: "Agosto",
  pci_perennial_tree_list_residue_month_sep: "Septiembre",
  pci_perennial_tree_list_residue_month_oct: "Octubre",
  pci_perennial_tree_list_residue_month_nov: "Noviembre",
  pci_perennial_tree_list_residue_month_dec: "Diciembre",
  pci_perennial_tree_list_residue_dd_type: "Tipo de residuo",
  pci_perennial_tree_list_residue_type_dd_1: "Fruta",
  pci_perennial_tree_list_residue_type_dd_2: "Frutos secos",
  pci_perennial_tree_list_residue_type_dd_3: "Corteza / Corcho",
  pci_perennial_tree_list_residue_type_dd_4: "Hojas, tallos, hierbas (no leñosas)",
  pci_perennial_tree_list_residue_type_dd_5: "Poda o material leñoso",
  pci_perennial_tree_list_residue_type_dd_6: "Hierba",
  pci_perennial_tree_list_residue_type_qty: "Cantidad",
  pci_perennial_tree_list_residue_fresh_dry_1: "Toneladas - Fresco",
  pci_perennial_tree_list_residue_fresh_dry_2: "Toneladas - Seco",
  pci_perennial_tree_list_residue_fresh_add: "Agregar residuos",
  pci_perennial_tree_list_add_new: "Agregar árbol u otro cultivo perenne",

  pci_annual_crops_title_1: "Cultivos anuales (",
  pci_annual_crops_title_2: ")",
  pci_annual_crops_subtitle: "Añade todos los cultivos anuales que <strong>se cosecharon</strong> en {{year}} en esta parcela. Si sembraste el cultivo en {{year}} y cosechaste en {{yearAfter}}, puedes sumarlo en {{yearAfter}}.",
  pci_annual_crops_label: "Cultivo anual",
  pci_annual_crops_delete: "Borrar",
  pci_annual_crops_desc: "Descripción*",
  pci_annual_crops_info: 'Selecciona el tipo de cultivo anual de la lista. Si tu cultivo no está en la lista, selecciona el tipo seguido de ""otro"" (por ejemplo, Leguminosas - otro) e indica el nombre específico.<br/><br/>Un cultivo de cobertura puede ser:<br/>- Una o varias gramíneas (Poaceae)<br/>- Leguminosas simples o múltiples (Fabaceae)<br/>- Mezcla de gramíneas y leguminosas (Poaceae y Fabaceae)<br/>- El Cultivo de Cobertura Multiespecie debe ser una mezcla de al menos 3 especies de plantas, siendo las dos primeras una gramínea (Poaceae) y una leguminosa (Fabaceae), y la tercera de una de las siguientes familias: Brassicaceae, Amaranthaceae, Linaceae, Plantaginaceae, Asteraceae, Polygonaceae, Boraginaceae, Malvaceae, Rosaceae, Cucurbitaceae - también llamadas hierbas no leguminosas',
  pci_annual_crops_dd_list: "Lista de cultivos anuales",
  "aromatics": "Aromáticas",
  "cover crop - single or multiple grasses": "Cultivo de cobertura - Hierbas simples o múltiples",
  "cover crop - single or multiple legumes": "Cultivo de cobertura - Leguminosas simples o múltiples",
  "cover crop - mix of grass and legumes": "Cultivo de cobertura - Mezcla de gramíneas y leguminosas",
  "cover crop - multi-species cover crop": "Cultivo de cobertura - Cultivo de cobertura multiespecie",
  "cover crop - other": "Cultivo de cobertura - Otros",
  "forage - grass-clover mixtures": "Forraje - Mezcla de gramíneas y trébol",
  "forage - italian ryegrass": "Forraje - Ballico italiano",
  "forage - n-fixing forages": "Forraje - Forrajes fijadores de N",
  "forage - non-n-fixing forages": "Forraje - Forrajes no fijadores de N",
  "forage - non-legume hay": "Forraje - Heno no leguminoso",
  "forage - sudangrass": "Forraje - Pasto sudán",
  "forage - other": "Forraje - Otros",
  "grains/cereals - barley": "Granos/Cereales - Cebada",
  "grains/cereals - maize": "Granos/Cereales - Maíz",
  "grains/cereals - millet": "Granos/Cereales - Mijo",
  "grains/cereals - oats": "Granos/Cereales - Avena",
  "grains/cereals - rice": "Granos/Cereales - Arroz",
  "grains/cereals - rye": "Granos/Cereales - Centeno",
  "grains/cereals - sorghum": "Granos/Cereales - Sorgo",
  "grains/cereals - spring wheat": "Granos/Cereales - Trigo de primavera",
  "grains/cereals - triticale": "Granos/Cereales - Triticale",
  "grains/cereals - wheat": "Granos/Cereales - Trigo",
  "grains/cereals - winter wheat": "Granos/Cereales - Trigo de invierno",
  "grains/cereals - other": "Granos/Cereales - Otros",
  "legumes - alfalfa": "Leguminosas - Alfalfa",
  "legumes - beans": "Leguminosas - Judías",
  "legumes - cowpea": "Leguminosas - Caupí",
  "legumes - lupins": "Leguminosas - Altramuces",
  "legumes - peas": "Leguminosas - Guisantes",
  "legumes - soybeans": "Leguminosas - Soja",
  "legumes - vetch": "Leguminosas - Arveja",
  "legumes - other": "Leguminosas - Otras",
  "roots/tubers - potatoes": "Raíces/Tubérculos - Patatas",
  "roots/tubers - beets": "Raíces/Tubérculos - Remolacha",
  "roots/tubers - other": "Raíces/Tubérculos - Otros",
  "fruits and vegetables - squash": "Frutas y verduras - Calabaza",
  "fruits and vegetables - other fruit": "Frutas y verduras - Otras frutas",
  "fruits and vegetables - other vegetable": "Frutas y hortalizas - Otras hortalizas",
  "oilseeds - peanuts": "Semillas oleaginosas - Cacahuetes",
  "oilseeds - rapeseed": "Semillas oleaginosas - Colza",
  "oilseeds - sunflower": "Semillas oleaginosas - Girasol",
  "oilseeds - other": "Semillas oleaginosas - Otras",
  "other annual crop": "Otros cultivos anuales",
  pci_annual_crops_specific_name: "Nombre específico",
  pci_annual_crops_crops_cycle: "Ciclo de cultivo*",
  pci_annual_crops_crops_cycle_info: "Ten en cuenta que sólo puedes seleccionar meses de cosecha en el año que estás editando actualmente. Si sembraste un cultivo este año pero lo cosechaste el año siguiente, añádelo al editar el año siguiente.",
  pci_annual_crops_harvest_month_dd: "Mes de cosecha",
  pci_annual_crops_harvest_month_jan: "Enero",
  pci_annual_crops_harvest_month_feb: "Febrero",
  pci_annual_crops_harvest_month_mar: "Marzo",
  pci_annual_crops_harvest_month_apr: "Abril",
  pci_annual_crops_harvest_month_may: "Mayo",
  pci_annual_crops_harvest_month_jun: "Junio",
  pci_annual_crops_harvest_month_jul: "Julio",
  pci_annual_crops_harvest_month_aug: "Agosto",
  pci_annual_crops_harvest_month_sep: "Septiembre",
  pci_annual_crops_harvest_month_oct: "Octubre",
  pci_annual_crops_harvest_month_nov: "Noviembre",
  pci_annual_crops_harvest_month_dec: "Diciembre",

  pci_annual_crops_sowing_month_dd: "Mes de siembra",
  pci_annual_crops_sowing_month_jan: "Enero",
  pci_annual_crops_sowing_month_feb: "Febrero",
  pci_annual_crops_sowing_month_mar: "Marzo",
  pci_annual_crops_sowing_month_apr: "Abril",
  pci_annual_crops_sowing_month_may: "Mayo",
  pci_annual_crops_sowing_month_jun: "Junio",
  pci_annual_crops_sowing_month_jul: "Julio",
  pci_annual_crops_sowing_month_aug: "Agosto",
  pci_annual_crops_sowing_month_sep: "Septiembre",
  pci_annual_crops_sowing_month_oct: "Octubre",
  pci_annual_crops_sowing_month_nov: "Noviembre",
  pci_annual_crops_sowing_month_dec: "Diciembre",

  pci_annual_crops_harvest_label: "Cosecha*",
  pci_annual_crops_harvest_label_desc: "Material orgánico que se retiró de la parcela",
  pci_annual_crops_harvest_label_info: 'Cosecha se define como la materia orgánica que se retiró de la parcela. Agrega todos los tipos de cosecha de este cultivo e indica la cantidad que se cosechó en toneladas de peso fresco o seco. Ten en cuenta que seco significa completamente deshidratado, de modo que no queda humedad.<br/><br/><strong>Si no hubo cosecha, indica cero como cantidad.</strong><br/><br/>Si quieres eliminar una cosecha, selecciona el guión (-) en la lista "Tipo de cosecha"',

  pci_annual_crops_harvest_type_dd: "Tipo de cosecha",
  pci_annual_crops_harvest_type_dd_1: "Granos/Cereales",
  pci_annual_crops_harvest_type_dd_2: "Semillas",
  pci_annual_crops_harvest_type_dd_3: "Frutas/Verduras",
  pci_annual_crops_harvest_type_dd_4: "Raíces/Tubérculos",
  pci_annual_crops_harvest_type_dd_5: "Hierba",
  pci_annual_crops_harvest_type_dd_6: "Hojas, tallos, hierbas (no leñosas)",
  pci_annual_crops_harvest_type_qty: "Cantidad",
  pci_annual_crops_harvest_qty_fresh: "Toneladas - Fresco",
  pci_annual_crops_harvest_qty_dry: "Toneladas - Seco",
  pci_annual_crops_harvest_add_new: "Añadir cosecha",
  pci_annual_crops_residue_label: "Residuos",
  pci_annual_crops_residue_label_desc: "Materia orgánica dejada en el campo",
  pci_annual_crops_residue_info_1: "Residuo se define como material vegetal/orgánico que se dejó en el campo. Agrega todos los tipos de residuos de este cultivo e indica la cantidad que se dejó en el campo en toneladas de peso fresco o seco. Ten en cuenta que seco significa completamente deshidratado, de modo que no queda humedad.",
  pci_annual_crops_residue_info_2: "Si no había residuos, indica cero en la cantidad.",
  pci_annual_crops_residue_info_3: "Si quieres eliminar un residuo, selecciona el guión (-) en la lista Tipo de residuo.",
  pci_annual_crops_residue_type_dd: "Tipo de residuo",
  pci_annual_crops_residue_type_dd_1: "Granos/Cereales",
  pci_annual_crops_residue_type_dd_2: "Semillas",
  pci_annual_crops_residue_type_dd_3: "Frutas/Hortalizas",
  pci_annual_crops_residue_type_dd_4: "Raíces/Tubérculos",
  pci_annual_crops_residue_type_dd_5: "Hierba",
  pci_annual_crops_residue_type_dd_6: "Hojas, tallos, hierbas (no leñosas)",
  pci_annual_crops_residue_type_qty: "Cantidad",
  pci_annual_crops_residue_qty_fresh: "Toneladas - Fresco",
  pci_annual_crops_residue_qty_dry: "Toneladas - Seco",
  pci_annual_crops_residue_add_new: "Agregar residuo",
  pci_annual_crops_add_new_row: "Añade un cultivo anual",


  pci_pastures_title_1: "Pastos y praderas",
  pci_pastures_subtitle_1: "Si (una parte de) esta parcela tenía pastos o hierbas permanentes durante el  ",
  pci_pastures_subtitle_2: ", indica el porcentaje de la superficie de la parcela, la productividad y la hierba cosechada.",
  pci_pastures_permanent_pastures: "Porcentaje de la parcela que tenía pastos o hierbas permanentes",
  pci_pastures_permanent_pastures_info: "Estima la superficie de la parcela que era pastura o pastos. Si toda la parcela está formada por pastos, es el 100%. Si no, proporciona la mejor aproximación de la superficie cubierta de pastura.",
  pci_pastures_permanent_pastures_percent: "Porcentaje",
  pci_pastures_permanent_pastures_peak: "Productividad TOTAL estimada*",
  pci_pastures_permanent_pastures_peak_info_1: `Estima aquí la cantidad TOTAL de biomasa que ha producido tu pasto en este año. Si se cosecha o pastorea esta parcela varias veces, añade varios valores de productividad O súmalos todos en un valor TOTAL.<br/>
Las cantidades de cosecha (biomasa retirada de la parcela) deben indicarse a continuación. Las cantidades de pastoreo no se indican aquí ya que el modelo las calcula automáticamente.`,
  pci_pastures_permanent_pastures_peak_month: "Mes",
  pci_pastures_permanent_pastures_peak_month_jan: "Enero",
  pci_pastures_permanent_pastures_peak_month_feb: "Febrero",
  pci_pastures_permanent_pastures_peak_month_mar: "Marzo",
  pci_pastures_permanent_pastures_peak_month_apr: "Abril",
  pci_pastures_permanent_pastures_peak_month_may: "Mayo",
  pci_pastures_permanent_pastures_peak_month_jun: "Junio",
  pci_pastures_permanent_pastures_peak_month_jul: "Julio",
  pci_pastures_permanent_pastures_peak_month_aug: "Agosto",
  pci_pastures_permanent_pastures_peak_month_sep: "Septiembre",
  pci_pastures_permanent_pastures_peak_month_oct: "Octubre",
  pci_pastures_permanent_pastures_peak_month_nov: "Noviembre",
  pci_pastures_permanent_pastures_peak_month_dec: "Diciembre",
  pci_pastures_permanent_pastures_peak_qty: "Cantidad",
  pci_pastures_permanent_pastures_peak_fresh: "kg/m² - Fresco",
  pci_pastures_permanent_pastures_peak_dry: "kg/m² - Seco",
  pci_pastures_permanent_pastures_add_estimate: "Añade otra estimación (máx. 5)",
  pci_pastures_grass_harvested: "Pastura cosechada",
  pci_pastures_grass_harvested_desc: "Cantidad de pasto cosechada en esta parcela. No incluye el pastoreo.",
  pci_pastures_grass_harvested_info_1: "Suma todas las pasturas cosechadas en esta parcela e indica la cantidad que se cosechó en toneladas de peso fresco o seco. Ten en cuenta que seco significa completamente deshidratado, de modo que no quede humedad.",
  pci_pastures_grass_harvested_info_2: "Si no hubo cosecha, indica cero en la cantidad o deja esta fila en blanco.",
  pci_pastures_grass_harvested_info_3: "Si quieres borrar una cosecha, selecciona el guión (-) en el mes y la fila no se guardará.",
  pci_pastures_grass_harvested_month: "Mes",
  pci_pastures_grass_harvested_month_jan: "Enero",
  pci_pastures_grass_harvested_month_feb: "Febrero",
  pci_pastures_grass_harvested_month_mar: "Marzo",
  pci_pastures_grass_harvested_month_apr: "Abril",
  pci_pastures_grass_harvested_month_may: "Mayo",
  pci_pastures_grass_harvested_month_jun: "Junio",
  pci_pastures_grass_harvested_month_jul: "Julio",
  pci_pastures_grass_harvested_month_aug: "Agosto",
  pci_pastures_grass_harvested_month_sep: "Septiembre",
  pci_pastures_grass_harvested_month_oct: "Octubre",
  pci_pastures_grass_harvested_month_nov: "Noviembre",
  pci_pastures_grass_harvested_month_dec: "Diciembre",
  pci_pastures_grass_harvested_qty: "Cantidad",
  pci_pastures_grass_harvested_qty_fresh: "Toneladas - Fresco",
  pci_pastures_grass_harvested_qty_dry: "Toneladas - Seco",
  pci_pastures_grass_harvested_add_new: "Añade otra cosecha",

  pci_pastures_fallow_title: "Periodos de barbecho",
  pci_pastures_fallow_subtitle: "Añade todos los periodos que esta parcela estuvo en barbecho en ",
  pci_pastures_fallow_info: "En un periodo de barbecho la tierra se deja sin sembrar ni cultivar durante un periodo de tiempo definido.",
  pci_pastures_fallow_period_label: "Periodo de barbecho",
  pci_pastures_fallow_period_delete: "Borrar",
  pci_pastures_fallow_period_desc: "Descripción*",
  pci_pastures_fallow_period_info: "Indica el estado del suelo durante el periodo de barbecho eligiendo el tipo de barbecho:<br/><br/>Cubierta vegetal espontánea - El suelo está parcial o totalmente cubierto de vegetación espontánea o residuos.<br/><br/>Suelo desnudo - El suelo está completamente descubierto.<br/>Si seleccionas suelo desnudo, indica el porcentaje de la superficie que estaba desnuda en esta parcela.",
  pci_pastures_fallow_period_type_dd: "Tipo de barbecho",
  pci_pastures_fallow_period_type_dd_1: "Cubierta vegetal espontánea",
  pci_pastures_fallow_period_type_dd_2: "Suelo desnudo",
  pci_pastures_fallow_period_percent: "Porcentaje %",
  pci_pastures_fallow_period_cycle: "Ciclo*",
  pci_pastures_fallow_period_cycle_info_1: "Indica el mes de finalización e inicio del periodo de barbecho.",
  pci_pastures_fallow_period_cycle_info_2: "Si la parcela estuvo en barbecho menos de 1 mes, elige este mes como mes final e inicial.",
  pci_pastures_fallow_period_cycle_info_3: "Si la parcela estuvo en barbecho durante todo el año o más, elige diciembre como mes final y enero como mes inicial. Añade el periodo de barbecho restante en el año siguiente mientras editas la pestaña del año siguiente.",
  pci_pastures_fallow_period_end_month: "Mes final",
  pci_pastures_fallow_period_end_month_jan: "Enero",
  pci_pastures_fallow_period_end_month_feb: "Febrero",
  pci_pastures_fallow_period_end_month_mar: "Marzo",
  pci_pastures_fallow_period_end_month_apr: "Abril",
  pci_pastures_fallow_period_end_month_may: "Mayo",
  pci_pastures_fallow_period_end_month_jun: "Junio",
  pci_pastures_fallow_period_end_month_jul: "Julio",
  pci_pastures_fallow_period_end_month_aug: "Agosto",
  pci_pastures_fallow_period_end_month_sep: "Septiembre",
  pci_pastures_fallow_period_end_month_oct: "Octubre",
  pci_pastures_fallow_period_end_month_nov: "Noviembre",
  pci_pastures_fallow_period_end_month_dec: "Diciembre",
  pci_pastures_fallow_period_start_month: "Mes de inicio",
  pci_pastures_fallow_period_start_month_jan: "Enero",
  pci_pastures_fallow_period_start_month_feb: "Febrero",
  pci_pastures_fallow_period_start_month_mar: "Marzo",
  pci_pastures_fallow_period_start_month_apr: "Abril",
  pci_pastures_fallow_period_start_month_may: "Mayo",
  pci_pastures_fallow_period_start_month_jun: "Junio",
  pci_pastures_fallow_period_start_month_jul: "Julio",
  pci_pastures_fallow_period_start_month_aug: "Agosto",
  pci_pastures_fallow_period_start_month_sep: "Septiembre",
  pci_pastures_fallow_period_start_month_oct: "Octubre",
  pci_pastures_fallow_period_start_month_nov: "Noviembre",
  pci_pastures_fallow_period_start_month_dec: "Diciembre",
  pci_pastures_fallow_period_harvest: "Cosecha",
  pci_pastures_fallow_period_harvest_desc: "Material orgánico que se retiró de la parcela",
  pci_pastures_fallow_period_harvest_info_1: "Cosecha se define como la materia orgánica que se retiró de la parcela. Suma todas las cosechas del barbecho productivo de esta parcela e indica la cantidad que se cosechó en toneladas de peso fresco o seco. Ten en cuenta que seco significa completamente deshidratado, de modo que no quede humedad.",
  pci_pastures_fallow_period_harvest_info_2: "Si no hubo cosecha, indica cero en la cantidad o deja esta fila en blanco.",
  pci_pastures_fallow_period_harvest_info_3: "Si quieres borrar una cosecha, selecciona el guión (-) en el mes.",
  pci_pastures_fallow_period_harvest_type_dd: "Cosecha",
  pci_pastures_fallow_period_harvest_type_dd_1: "Hojas, tallos, hierbas (no leñosas)",
  pci_pastures_fallow_period_harvest_type_dd_2: "Hierba",
  pci_pastures_fallow_period_harvest_type_qty: "Cantidad",
  pci_pastures_fallow_period_harvest_type_qty_fresh: "Toneladas - Fresco",
  pci_pastures_fallow_period_harvest_type_qty_dry: "Toneladas - Seco",
  pci_pastures_fallow_period_harvest_add_harvest: "Agregar cosecha",
  pci_pastures_fallow_period_residue: "Residuo",
  pci_pastures_fallow_period_residue_desc: "Material orgánico que queda en el campo",
  pci_pastures_fallow_period_residue_info_1: "Residuo se define como material vegetal/orgánico que se dejó en el campo. Agrega todos los tipos de residuos de este cultivo e indica la cantidad que se dejó en el campo en toneladas de peso fresco o seco. Ten en cuenta que seco significa completamente deshidratado, de modo que no queda humedad.",
  pci_pastures_fallow_period_residue_info_2: "Si no había residuos, indica cero en la cantidad o deja esta fila en blanco.",
  pci_pastures_fallow_period_residue_info_3: "Si quieres borrar una cosecha, selecciona el guión (-) en el mes.",
  pci_pastures_fallow_period_residue_type_dd: "Residuos",
  pci_pastures_fallow_period_residue_type_dd_1: "Hojas, tallos, hierbas (no leñosas)",
  pci_pastures_fallow_period_residue_type_dd_2: "Hierba",
  pci_pastures_fallow_period_residue_qty: "Cantidad",
  pci_pastures_fallow_period_residue_qty_fresh: "Toneladas - Fresco",
  pci_pastures_fallow_period_residue_qty_dry: "Toneladas - Seco",
  pci_pastures_fallow_period_residue_add_new: "Agregar residuos",
  pci_pastures_fallow_period_add_new: "Agregar periodo de barbecho",
  pci_save_button: "Guardar",
  pci_percent_parcel: "% de parcela",


  // livestock - in farm
  ls_infarm_title: "Tu ganado",
  ls_infarm_desc: "Aquí encontrarás los documentos relativos a tus animales. Debajo de esta sección, encontrarás el recuento de tu ganado basado en los documentos que has proporcionado. Actualisa esta página proporcionando detalles sobre tu manejo y prácticas ganaderas.",
  ls_infarm_info: "Si el número de animales no se corresponde con la realidad de la finca, ponte en contacto con Iris o María.",
  ls_infarm_grazing: "Manejo del pastoreo",
  ls_infarm_uploaded_docs: "Documentos cargados",
  ls_infarm_grazing_desc_1: "Indica la principal forma de manejo del pastoreo en tu explotación. Si realizas pastoreo planificado para al menos una especie, selecciona 'Pastoreo planificado'. Ten en cuenta que en la información sobre las parcelas habrá más detalle de los efectos del pastoreo planificado.",
  ls_infarm_grazing_desc_2: "Pastoreo no planificado - Generalmente se deja pastar a los animales en superficies más grandes. No se ha hecho ninguna planificación, o ésta ha sido mínima, para desplazar a los animales con el fin de optimizar la productividad de los pastos y minimizar el sobrepastoreo.",
  ls_infarm_grazing_desc_3: "Pastoreo planificado - Las zonas de pastoreo se subdividen en parcelas más pequeñas. Los animales se trasladan regularmente entre estas parcelas para optimizar la productividad de los pastos y minimizar el sobrepastoreo.",
  ls_infarm_grazing_unplanned: "Pastoreo no planificado",
  ls_infarm_grazing_planned: "Pastoreo planificado",
  ls_infarm_cattle: "Ganado",
  ls_infarm_cattle_females: "Hembras maduras, no lecheras",
  ls_infarm_cattle_females2: "(> 2 años)",
  ls_infarm_cattle_males: "Machos maduros (> 2 años)",
  ls_infarm_cattle_males_info: "Bovinos machos maduros de cualquier especie ( > 2 años).",
  ls_infarm_cattle_growing: "Ganado en crecimiento",
  ls_infarm_cattle_growing2: "(6 - 24 meses)",
  ls_infarm_cattle_growing_info: "Bovinos en crecimiento de todos los sexos y de cualquier especie (6 - 24 meses).",
  ls_infarm_cattle_calves: "Terneros (< 6 meses)",
  ls_infarm_cattle_calves_info: "El número de animales jóvenes se calcula automáticamente multiplicando el índice de fertilidad de las hembras por el número de hembras maduras (lecheras y no lecheras). Por favor, introduce la información de fertilidad de las hembras de vacuno y vacas lecheras para ver un número aquí.<br><br>Si el número no se corresponde con la realidad, ponte en contacto con Iris o María.",
  ls_infarm_grazing_days: "Días de pastoreo al año",
  ls_infarm_grazing_days_info: "El número de días que tus animales estuvieron pastando en tu finca, es decir, no estabulados o fuera de la explotación.",
  ls_infarm_grazing_days_unit: "días",
  ls_infarm_manure: "Método principal de tratamiento del estiércol de los animales estabulados",
  ls_infarm_manure_info_1: "Se refiere al estiércol de los animales estabulados o estabulados en el interior, no al estiércol de los animales que pastan. Si tus animales están siempre pastando, puedes dejar este campo en blanco.",
  ls_infarm_manure_info_2: "De lo contrario, selecciona la forma principal en que trató el estiércol:",
  ls_infarm_manure_info_3: "Exportación desde la explotación - El estiércol se recoge y exporta desde la explotación.",
  ls_infarm_manure_info_4: "Almacenamiento de sólidos - El estiércol y la orina se excretan en un establo. Los sólidos (con o sin yacija) se recogen y almacenan a granel durante mucho tiempo (meses).",
  ls_infarm_manure_info_5: "Almacenamiento en fosa > 1 mes - El estiércol y la orina se almacenan en una fosa debajo de los establos durante más de un mes.",
  ls_infarm_manure_info_6: "Líquido / purín - El estiércol y la orina se recogen y se transportan como líquido a tanques para su almacenamiento. El líquido puede almacenarse durante mucho tiempo (meses).",
  ls_infarm_manure_info_7: "Lote seco - Los animales están en cebaderos sin pavimentar donde se deja secar el estiércol hasta que se retira periódicamente.",
  ls_infarm_manure_info_8: "Digestión anaeróbica - biogás - El estiércol y la orina en estado líquido / purín se recogen y se digieren anaeróbicamente. El CH4 puede quemarse, quemarse en antorcha o ventilarse.",
  ls_infarm_manure_info_9: "Lagunas anaerobias - El estiércol se transporta a las lagunas mediante un sistema de lavado con agua. El estiércol permanece en la laguna durante al menos un mes. El agua de la laguna puede reciclarse como agua de lavado o utilizarse para regar y fertilizar los campos.",
  ls_infarm_manure_info_10: "Esparcimiento diario - El estiércol y la orina se recogen diariamente, por ejemplo, mediante raspado. Los residuos recogidos se aplican a los campos.",
  ls_infarm_manure_info_11: "Compostaje - Intensivo - El estiércol y la orina se recogen y se colocan en un recipiente o túnel, se produce una aireación forzada del estiércol.",
  ls_infarm_manure_info_12: "Compostaje - Extensivo - El estiércol y la orina se recogen, se apilan y se voltean regularmente para su aireación.",
  ls_infarm_manure_info_13: "Lecho profundo - El estiércol y la orina del ganado vacuno/porcino se excretan en el suelo del establo. Los residuos acumulados se retiran al cabo de mucho tiempo.",
  ls_infarm_manure_info_14: "Tratamiento aeróbico - El estiércol y la orina se recogen en forma líquida. Los residuos se someten a aireación forzada, o se tratan en estanques aeróbicos o sistemas de humedales para proporcionar nitrificación y desnitrificación.",
  ls_infarm_manure_treatment: "Tratamiento del estiércol",
  ls_infarm_manure_exported: "Exportado de la finca",
  ls_infarm_manure_solid: "Almacenamiento sólido",
  ls_infarm_manure_pit: "Almacenamiento en fosa > 1 mes",
  ls_infarm_manure_liquid: "Líquido / purín",
  ls_infarm_manure_dry: "Lote seco",
  ls_infarm_manure_biogas: "Digestión anaerobia - biogás",
  ls_infarm_manure_anae_lagoon: "Laguna anaeróbica",
  ls_infarm_manure_spread: "Esparcimiento diario",
  ls_infarm_manure_comp_intensive: "Compostaje - Intensivo",
  ls_infarm_manure_comp_extensive: "Compostaje - Extensivo",
  ls_infarm_manure_deep_litter: "Lecho profundo",
  ls_infarm_manure_aerobic: "Tratamiento aeróbico",
  ls_infarm_fertility: "Tasa de fertilidad de las hembras",
  ls_infarm_fertility_info_1: "Es el número medio de crías por hembra en edad reproductiva de esta especie. Por ejemplo, si nacen 9 terneros de 20 vacas, el índice de fertilidad de las hembras es 9/20 = 0,45.",
  ls_infarm_fertility_info_2: "A partir del índice de fertilidad, se calcula el número de terneros.",
  ls_infarm_dairy_cows: "Vacas lecheras",
  ls_infarm_dairy_cows_mature: "Hembras maduras (> 2 años)",
  ls_infarm_dairy_cows_mature_info: "Vacas lecheras hembras maduras ( > 2 años).",
  ls_infarm_swine: "Porcino",
  ls_infarm_swine_sows: "Cerdas (> 6 meses)",
  ls_infarm_swine_sows_info: "Cerdas maduras para la reproducción ( > 6 meses).",
  ls_infarm_swine_boars: "Verracos (> 6 meses)",
  ls_infarm_swine_boars_info: "Cerdos maduros para reproducción ( > 6 meses).",
  ls_infarm_swine_finishers: "Acabadores (3 - 6 meses)",
  ls_infarm_swine_finishers_info: "Cerdos no reproductores para carne, entre 3 - 6 meses de edad (todos los sexos).",
  ls_infarm_swine_other: "Otros porcinos",
  ls_infarm_swine_other_info: "No se dispone de información para clasificar estos animales en finalizadores, cerdas o verracos.",
  ls_infarm_swine_piglets: "Lechones",
  ls_infarm_young_animals_info: "El número de animales jóvenes se calcula multiplicando el índice de fertilidad de las hembras por el número de hembras maduras. Por favor, introduce la información de fertilidad de las hembras en la sección de animales maduros para ver un número aquí. <br> <br> Si el número no se corresponde con la realidad, ponte en contacto con Iris o María.",
  ls_infarm_purchased_info: "Es el número de animales jóvenes que has comprado directamente, que no han nacido en tu granja. <br> <br> Si el número no se corresponde con la realidad, ponte en contacto con Iris o María.",
  ls_infarm_swine_fertility_info_1: "El número medio de crías por hembra en edad reproductiva de esta especie. Por ejemplo, si nacen 32 lechones de 4 cerdas, el índice de fertilidad de las hembras es 32/4 = 8.",
  ls_infarm_swine_fertility_info_2: "En función del índice de fertilidad, se calcula el número de lechones.",
  ls_infarm_chicken: "Gallinas",
  ls_infarm_chicken_layer: "Gallinas ponedoras",
  ls_infarm_chicken_layer_info: "Gallinas para puesta de huevos.",
  ls_infarm_chicken_broilers: "Gallinas de engorde",
  ls_infarm_chicken_broilers_info: "Gallinas para carne",
  ls_infarm_chicken_other: "Otros pollos",
  ls_infarm_chicken_other_info: "No se dispone de información para clasificar estos pollos en gallinas ponedoras o pollos de engorde.",
  ls_infarm_sheep: "Ovejas",
  ls_infarm_sheep_females: "Hembras maduras (> 1 año)",
  ls_infarm_sheep_males: "Machos maduros (> 1 año)",
  ls_infarm_sheep_lambs: "Corderos (< 1 año)",
  ls_infarm_sheep_fertility_info_1: "Es el número medio de crías por hembra en edad reproductiva de esta especie. Por ejemplo, si nacen 25 corderos de 20 ovejas, el índice de fertilidad de las hembras es 25/20 = 1,25.",
  ls_infarm_sheep_fertility_info_2: "A partir del índice de fertilidad, se calcula el número de corderos.",
  ls_infarm_goats: "Cabras",
  ls_infarm_goats_females: "Hembras maduras (> 1 año)",
  ls_infarm_goats_males: "Machos maduros (> 1 año)",
  ls_infarm_goats_kids: "Cabritos (< 1 año)",
  ls_infarm_goats_fertility_info_1: "El número medio de crías por hembra en edad reproductiva de esta especie. Por ejemplo, si tiene 25 cabritos nacidos de 20 cabras maduras, el índice de fertilidad de la hembra es 25/20 = 1,25.",
  ls_infarm_goats_fertility_info_2: "En función del índice de fertilidad, se calcula el número de cabritos.",
  ls_infarm_turkeys: "Pavos",
  ls_infarm_ducks: "Patos",
  ls_infarm_add_out_farm: "Añadir animales externos",

  ls_no_docs_title: "Ganado",
  ls_no_docs_1: "Tenga en cuenta que el proceso de recopilación de datos ganaderos consta de dos pasos:",
  ls_no_docs_2: "Paso 1. Usted carga sus documentos ganaderos oficiales. Basándose en estos documentos, nuestro agrónomo calculará el número de sus animales.",
  ls_no_docs_3: "Paso 2. Usted proporciona información sobre el manejo de sus animales.",
  ls_no_docs_4: "Actualmente, seguimos revisando sus documentos ganaderos. Cuando los hayamos revisado, podrá pasar al paso 2.",
  ls_no_docs_5: "Si no ha subido todos sus documentos ganaderos, súbalos haciendo clic en el botón de abajo. Notifique a Iris o María cuando los haya subido.",
  ls_no_docs_6: 'Si has permitido que animales de fuera de tu granja pasten en ella, puedes añadir detalles al respecto haciendo clic en "Añadir animales externos" a continuación',
  ls_no_docs_button: "Ir a la pantalla de documentos",

  ls_outfarm_title: "Animales de fuera de tu finca",
  ls_outfarm_desc: `Si en tus parcelas pastó ganado de otro agricultor, añádalo a continuación. Selecciona la especie y facilita el número de animales y días de pastoreo de cada año.`,
  ls_outfarm_species: "Especie",
  ls_outfarm_cattle: "Vacuno (no lechero)",
  ls_outfarm_dairy_cows: "Vacas lecheras",
  ls_outfarm_swine: "Porcino",
  ls_outfarm_sheep: "Ovino",
  ls_outfarm_goats: "Caprino",
  ls_outfarm_num_animals: "Número de animales",
  ls_outfarm_info: 'Si quieres eliminar una especie, selecciona el guión (-) en la lista "Especies" y la fila no se guardará.',
  ls_outfarm_grazing_days: "Días de pastoreo al año",
  ls_outfarm_grazing_days_info: "Número de días que los animales han pastado en tu finca. Por ejemplo: no estabulados o fuera de la finca.",
  ls_outfarm_grazing_days_num: "Número de días de pastoreo",
  ls_outfarm_add: "+ Añadir especies de ganado",
  ls_outfarm_no_animals: "No hubo animales otros animales en la finca",

  generic_litres: 'litros',
  generic_area: 'Área',
  generic_hectares: 'hectáreas',
  generic_complete_fields: 'Completa los campos marcados en rojo para poder guardar la información completa de tu finca.',
  generic_complete_yes: 'Completar información faltante',
  generic_complete_no: 'Borrar la información incompleta',
  generic_complete_title: 'Faltan datos',
  general_parcel: "Parcela",
  generic_calculated: 'calculados',
  generic_purchased: 'comprados',

  "productive fallow": "Cubierta vegetal espontánea",
  "bare soil": "Suelo desnudo",

  validation_fuel: 'El icono de esta página se volverá verde cuando se hayan indicado los datos sobre el uso de gasolina y gasóleo para todos los años.',
  validation_livestock: 'El icono de esta página se volverá verde cuando se hayan indicado datos sobre las prácticas de gestión ganadera de todas las especies para todos los años.',
  validation_map: `El icono de esta página se volverá verde cuando se hayan indicado datos válidos en todas sus parcelas (páginas de información general y de cultivos) para todos los años.  
<br/><br/>
Además, la página de Información para toda la finca debe contener datos válidos de todos los años`,
  validation_farmwide: 'El icono de esta página se volverá verde cuando se hayan indicado los datos sobre el uso de fertilizantes sintéticos y el porcentaje importado de enmiendas orgánicas de toda la finca para todos los años.',
  validation_parcel: 'El icono de esta parcela se volverá verde cuando tanto la página de información general como la de cultivos contengan datos válidos. Pase el ratón sobre los indicadores de progreso de las páginas respectivas para saber más.',
  validation_crops: `El icono de esta página se volverá verde cuando se haya indicado información válida en al menos uno de los siguientes tipos de uso del suelo de la parcela para cada año:
<br/><br/>
- Plantas perennes y árboles<br/>
- Cultivos anuales<br/>
- Pastos<br/>
- Periodos de barbecho
<br/><br/>
Si ha empezado a introducir datos para cualquiera de los tipos de uso del suelo anteriores, debe completarlos.`,
  validation_landuse: 'El icono de esta página se volverá verde cuando se indiquen los datos sobre el uso principal de la tierra y el riego para todos los años.',

  documents_collection: "Colección de documentos",
  data_collection: "Recolección de datos",
  submitted_for_review: "Enviar a revisión",
  internal_review: "Revisión interna",
  audit: "Auditoría",
  carbon_plus_credit_posting: "Contabilización de Créditos de Carbon+",
  carbon_plus_credit_sales: "Ventas de Créditos de Carbon+",

  subtitle_documents_collection: "",
  subtitle_farm_data_yet_to_submit: 'La plataforma está actualmente abierta para el ingreso de datos. Haz clic en "Enviar para revisión" para enviar tus datos.',
  subtitle_submitted_review: "Pronto revisaremos tus datos. Ponte en contacto con nosotros si necesitas hacer algún cambio.",
  subtitle_internal_review: "Estamos revisando tus datos y documentos. Ponte en contacto con nosotros si necesitas hacer algún cambio.",
  subtitle_audit: "Tus datos han sido enviados al auditor externo. En caso necesario, nos pondremos en contacto contigo para darte más información.",
  subtitle_carbon_credit_posting: "Tus Créditos Carbon+ han sido contabilizados en el registro. Ponte en contacto con nosotros para conocer los números de tus créditos.",
  subtitle_carbon_sales: "Se han vendido tus Créditos Carbon+ y los ingresos se han transferido a tu cuenta.",
  next_steps: "Próximos pasos",

};