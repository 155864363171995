import HttpService from "../services/HttpService";


const updateProjectStartYear = (id, data) => {
    return HttpService.getAxiosClient().patch(`/api/partial/monitoringdata/projectstartyear/${id}`, data);
};

const updateYearlyFarmData = (id, data) => {
    return HttpService.getAxiosClient().patch(`/api/partial/monitoringdata/yearlyfarmdata/${id}`, data);
};


const PartialMonitoringDataUpdatesDataService = {
    updateProjectStartYear,
    updateYearlyFarmData
};

export default PartialMonitoringDataUpdatesDataService;