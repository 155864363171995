import produce from "immer";
import { SET_ALL_MONITORING_YEARS } from "../actions/types";

var initialState = {
    "allMonitoringYears": []
};


function monitoringYearsTrackerReducer(curMonitoringYears = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SET_ALL_MONITORING_YEARS:
            return produce(curMonitoringYears, (draft) => {
                draft.allMonitoringYears = payload;
            });
        default:
            return curMonitoringYears;
    }
};

export default monitoringYearsTrackerReducer;