const typography = {
    h1: {
        fontSize: '26px',
        fontWeight: 800,
        lineHeight: 3,
    },
    h2: {
        fontSize: '20px',
        fontWeight: 800,
        lineHeight: 2,
    },
    h3: {
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: 1,
    },
    h4: {
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: 1,
    },
    subtitle1: {
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: 1.5,
    },
    subtitle2: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: 1.125,
    },
    subtitle3: {
        fontSize: '0.75rem',
        fontWeight: 400,
        lineHeight: 0.75,
    },
    button: {
        fontSize: '2rem',
        fontWeight: 400,
        lineHeight: 1.5,
    },
    fontFamily: [
        'Montserrat',
        'Gotham Book',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif'
    ].join(','),
}

export default typography
