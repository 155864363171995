import {
  CREATE_EMPTY_FARMLAND,
  SET_CUR_FARM_DATA,
  UPDATE_CUR_FARM_DATA,
  UPDATE_CUR_FARM_INFO,
  UPDATE_CUR_SUBMISSION_DATE,
  GET_EXISTING_FARMLAND,
  GET_EMPTY_FARMLAND,
  RESET_FARMLAND_DATA,
  CREATE_EMPTY_FARMLAND_FROM_LANDBOT,
} from "../actions/types";
import produce from "immer";

var initialState = {
  dateOfSubmission: new Date(),
  farmInfo: {
    farmId: "",
    farmName: "",
    uniqueCfFarmId: "",
    farmManagerFirstName: "",
    farmManagerLastName: "",
    landOwnerFirstName: "",
    landOwnerLastName: "",
    relationshipManagerOrOwner: "",
    email: "",
    farmSize: "",
    address: "",
    postalCode: "",
    farmCoordinatesNorth: "",
    farmCoordinatesEast: "",
    country: "",
    telephone: "",
    creationDate: "",
  },
};

function currentFarmDataReducer(curFarmlandData = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case CREATE_EMPTY_FARMLAND:
      return produce(curFarmlandData, (draft) => {
        draft.farmInfo.farmId = payload.farmId;
        draft.farmInfo.uniqueCfFarmId = payload.uniqueCfFarmId;
        draft.farmInfo.email = payload.emailAddress;
        draft.farmInfo.projectDuration = payload.projectDuration;
      });
    case CREATE_EMPTY_FARMLAND_FROM_LANDBOT:
      return produce(curFarmlandData, (draft) => {
        draft.farmInfo.farmId = payload.farmId;
        draft.farmInfo.email = payload.email;
        draft.farmInfo.farmSize = payload.farmSize;
        draft.farmInfo.country = payload.country;
        draft.farmInfo.farmCoordinatesNorth = payload.farmCoordinatesNorth;
        draft.farmInfo.farmCoordinatesEast = payload.farmCoordinatesEast;
        // draft.farmInfo.farmManagerFirstName = payload.firstName;
        // draft.farmInfo.farmManagerLastName = payload.lastName;
        draft.farmInfo.telephone = payload.phoneNumber;
        draft.farmInfo.projectDuration = payload.projectDuration;
      });
    case GET_EMPTY_FARMLAND:
      return curFarmlandData;

    case SET_CUR_FARM_DATA:
      return curFarmlandData;

    case UPDATE_CUR_FARM_INFO:
      return produce(curFarmlandData, (draft) => {
        draft.farmInfo = payload;
      });
    case UPDATE_CUR_SUBMISSION_DATE:
      return produce(curFarmlandData, (draft) => {
        draft.dateOfSubmission = payload;
      });

    case UPDATE_CUR_FARM_DATA:
      if (payload._id !== undefined && payload._id !== null) {
        return produce(curFarmlandData, (draft) => {
          draft._id = payload._id;
          draft.farmInfo = payload.farmInfo;
        });
      }

    case GET_EXISTING_FARMLAND:
      return payload;

    case RESET_FARMLAND_DATA:
      return initialState;

    default:
      return curFarmlandData;
  }
}

export default currentFarmDataReducer;
