import { emptyNumericFieldErrorZero } from "../general/Validations/fieldValueErrors";

export const getTotalAreaOfTheFarm = (data) => {
    const farmData = data?.yearlyFarmData?.[4]?.parcelLevelData;
    if (farmData && farmData.length) {
        let totalArea = 0;
        farmData.forEach(parcel => {
            const manualEntryArea = parcel?.parcelFixedValues?.areaManualEntry?.area;
            const geoFileArea = parcel?.parcelFixedValues?.areaGeoFile?.area;

            const isValidManualEntryArea = !emptyNumericFieldErrorZero(manualEntryArea);

            totalArea += isValidManualEntryArea ? Number(manualEntryArea) : Number(geoFileArea);
        });
        return totalArea;
    }
    return 0;
};