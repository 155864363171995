import { default as React, useState } from "react";

import mapboxgl from "mapbox-gl";

import { Backdrop, Box, Grid } from "@mui/material";
import "../../css/MapParcelList.css";

import "../../css/LiveStockPopUp.css";

import { MapParcelsListContext } from "../../contexts/MapParcelListContext";


//import DOMParser from 'xmldom';

//do not delete the comments below - very imp!!!
//import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
//import 'mapbox-gl/dist/mapbox-gl.css';
// eslint-disable-next-line import/no-webpack-loader-syntax

import { ParcelTemplateContext } from "../../contexts/ParcelTemplateContext";
import ParcelListUnderMap from "./ParcelListUnderMap";
/* eslint import/no-webpack-loader-syntax: off */
mapboxgl.workerClass =
    require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;


const MapParcelsListComponent = () => {

    const [currentlyEditingRowID, setCurrentlyEditingRowID] = useState(null);
    const [currentlyEditingRowName, setCurrentlyEditingRowName] = useState(null);
    const [arrCashCropMonths1, setArrCashCropMonths1] = useState(
        Array(12).fill(null)
    );
    const [arrCashCropMonths2, setArrCashCropMonths2] = useState(
        Array(12).fill(null)
    );
    const [arrCoverCropMonths, setArrCoverCropMonths] = useState(
        Array(12).fill(false)
    );
    const [arrProductiveFallowMonths, setArrProductiveFallowMonths] = useState(
        Array(12).fill(false)
    );
    const [arrGrazingMonths, setArrGrazingMonths] = useState(
        Array(12).fill(false)
    );
    const [arrHarvestGrazingYield, setArrHarvestGrazingYield] = useState(
        Array(12).fill("0")
    );
    const [
        arrEstimatedPlantResidueLeftOnField,
        setArrEstimatedPlantResidueLeftOnField,
    ] = useState(Array(12).fill("0"));
    const [arrResidueGrazedOrHarvested, setArrResidueGrazedOrHarvested] =
        useState(Array(12).fill(false));
    const [
        arrEstimationOfWhatsLeftAfterResidueGrazingHarvest,
        setArrEstimationOfWhatsLeftAfterResidueGrazingHarvest,
    ] = useState(Array(12).fill("0"));
    const [arrBareSoil, setArrBareSoil] = useState(Array(12).fill(false));
    const [arrTillingEvent, setArrTillingEvent] = useState(Array(12).fill(false));
    const [arrMinimumTillingEvent, setArrMinimumTillingEvent] = useState(
        Array(12).fill(false)
    );

    const [setYearlyIDTab, setSetYearlyIDTab] = useState(null);
    const [callingFromTab, setCallingFromTab] = useState(false);
    const [landSummaryID, setLandSummaryID] = useState(null);

    const [editDataOpen, setEditDataOpen] = useState(false);

    const [backdropParcelCreation, setBackdropParcelCreation] = useState(false);



    const handleBackdropClose = () => {
        setBackdropParcelCreation(false);
    };

    const [showParcelCreationLoader, setShowParcelCreationLoader] =
        useState(false);

    const handleBackdropClickCaptureEvent = (e) => {
        e.stopPropagation();
        return false;
    };

    const [newParcelTemplateOpen, setNewParcelTemplateOpen] = useState(false);


    return (
        <>
            {showParcelCreationLoader && (
                <Backdrop
                    style={{ color: "#fff", zIndex: 99999 }}
                    onClickCapture={(e) => handleBackdropClickCaptureEvent(e)}
                    open={backdropParcelCreation}
                    onClick={handleBackdropClose}
                >
                    <div style={{ fontSize: 20, fontWeight: 600, color: "white", marginTop: "20px" }}>
                        Parcel Creation is in Progress. Please Wait
                    </div>
                </Backdrop>
            )}
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box
                    sx={{

                        width: 0.99,
                        marginTop: 3,
                        marginBottom: 25,
                    }}
                >
                    <ParcelTemplateContext.Provider
                        value={{
                            newParcelTemplateOpen,
                            setNewParcelTemplateOpen,
                        }}
                    >
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                        </Grid>
                    </ParcelTemplateContext.Provider>
                    <MapParcelsListContext.Provider
                        value={{
                            currentlyEditingRowName,
                            setCurrentlyEditingRowName,
                            currentlyEditingRowID,
                            setCurrentlyEditingRowID,
                            editDataOpen,
                            setEditDataOpen,
                            arrCashCropMonths1,
                            setArrCashCropMonths1,
                            arrCashCropMonths2,
                            setArrCashCropMonths2,
                            arrCoverCropMonths,
                            setArrCoverCropMonths,
                            arrProductiveFallowMonths,
                            setArrProductiveFallowMonths,
                            arrGrazingMonths,
                            setArrGrazingMonths,
                            arrHarvestGrazingYield,
                            setArrHarvestGrazingYield,
                            arrEstimatedPlantResidueLeftOnField,
                            setArrEstimatedPlantResidueLeftOnField,
                            arrResidueGrazedOrHarvested,
                            setArrResidueGrazedOrHarvested,
                            arrEstimationOfWhatsLeftAfterResidueGrazingHarvest,
                            setArrEstimationOfWhatsLeftAfterResidueGrazingHarvest,
                            arrBareSoil,
                            setArrBareSoil,
                            arrTillingEvent,
                            setArrTillingEvent,
                            arrMinimumTillingEvent,
                            setArrMinimumTillingEvent,
                            setYearlyIDTab,
                            setSetYearlyIDTab,
                            callingFromTab,
                            setCallingFromTab,
                            landSummaryID,
                            setLandSummaryID,
                        }}
                    >
                        <ParcelListUnderMap />
                    </MapParcelsListContext.Provider>
                </Box>
            </Grid>
        </>
    );
};

export default MapParcelsListComponent;
