import { GET_FARM_STATUS, RESET_FARM_STATUS, SET_FARM_STATUS } from "../actions/types";
import { FARM_DATA_IS_YET_TO_BE_SUBMITTED } from "../constants/constants";

const initialState = {
    status: FARM_DATA_IS_YET_TO_BE_SUBMITTED,
    allowEditing: false,
    editFarm: false,
}

function farmStatusReducer(farmStatus = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case SET_FARM_STATUS:
            farmStatus.status = payload.status;
            farmStatus.allowEditing = payload.allowEditing;
            farmStatus.editFarm = payload.editFarm;
            return farmStatus;
        case GET_FARM_STATUS:
            return farmStatus;
        case RESET_FARM_STATUS:
            return initialState;
        default:
            return farmStatus;
    }
};

export default farmStatusReducer;