
import { Info as InfoIcon } from "@mui/icons-material";
import { Tooltip, Typography } from '@mui/material';
import { Trans, useTranslation } from "react-i18next";



const FieldTitleWithInfo = ({ title, info, title2 }) => {
    const { t } = useTranslation();
    return (
        <Typography variant="h3">
            {title2 ? <>{t(title)}<br />{t(title2)} </> :
                <> {t(title)}</>}
            {info && <Tooltip
                sx={{ paddingLeft: 1 }}
                title={<Trans i18nKey={info} />}
                placement="top-start"
            >
                <InfoIcon sx={{ fontSize: "medium", padding: "0px 0px 0px 8px" }} />
            </Tooltip>}
        </Typography>
    )
}

export default FieldTitleWithInfo