import { getFarmVerificationDocumentsByEmail, setFarmVerificationDocuments } from "../../../actions/verificationDocuments";

export const getVerificationDocuments = (dispatch, email, farmIndex) => {
    dispatch(getFarmVerificationDocumentsByEmail(email))
        .then((res) => {
            return dispatch(setFarmVerificationDocuments(res[farmIndex]));
        })
        .then((res) => {
            console.log('Farm verification documents set successfully:', res);
        })
        .catch((error) => {
            console.error('Error retrieving or setting farm verification documents:', error);
        });
};