import HttpService from "../services/HttpService";


const updateFarmInfo = (id, data) => {
    return HttpService.getAxiosClient().patch(`/api/partial/farmlands/farminfo/${id}`, data);
};

const updateEnergyUsage = (id, data) => {
    return HttpService.getAxiosClient().patch(`/api/partial/farmlands/energyusage/${id}`, data);
};

const updateLivestock = (id, data) => {
    return HttpService.getAxiosClient().patch(`/api/partial/farmlands/livestock/${id}`, data);
};

const updateLandUse = (id, data) => {
    return HttpService.getAxiosClient().patch(`/api/partial/farmlands/landuse/${id}`, data);
};

const updateSoilAnalysis = (id, data) => {
    return HttpService.getAxiosClient().patch(`/api/partial/farmlands/soilanalysis/${id}`, data);
};


const PartialFarmUpdatesDataService = {
    updateFarmInfo,
    updateEnergyUsage,
    updateLivestock,
    updateLandUse,
    updateSoilAnalysis
};

export default PartialFarmUpdatesDataService;