import AccountSettingsDataService from "../farmservices/AccountSettingsDataService";
import { CREATE_ACCOUNT_SETTINGS, DELETE_ACCOUNT_SETTINGS, RETRIEVE_ACCOUNT_SETTINGS, UPDATE_ACCOUNT_SETTINGS } from "./types";

export const createAccountSettings = (data) => async (dispatch) => {
    try {
        const res = await AccountSettingsDataService.create(data);
        dispatch({
            type: CREATE_ACCOUNT_SETTINGS,
            payload: res.data
        });
        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const retrieveAccountSettings = (email) => async (dispatch) => {
    try {
        const res = await AccountSettingsDataService.get(email);
        dispatch({
            type: RETRIEVE_ACCOUNT_SETTINGS,
            payload: res.data,
        });
        return Promise.resolve(res);
    } catch (error) {
        console.log(error);
    }
};

export const updateAccountSettings = (id, data) => async (dispatch) => {
    try {
        const res = await AccountSettingsDataService.update(id, data);
        dispatch({
            type: UPDATE_ACCOUNT_SETTINGS,
            payload: data,
        });
        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const deleteAccountSettings = (id) => async (dispatch) => {
    try {
        await AccountSettingsDataService.remove(id);
        dispatch({
            type: DELETE_ACCOUNT_SETTINGS,
            payload: {
                id
            },
        });
    } catch (error) {
        console.log(error);
    }
};
