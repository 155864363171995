export const translationPT = {
  home_welcome: "Bem-vindo",
  home_your_c_app: "A sua monitorização do Carbon+",
  home_complete_onboarding: "Complete a sua monitorização",
  home_tell_us: "Conte-nos sobre a sua exploração",
  home_provide: "Informações básicas ",
  home_indicate: "Indicar uso de combustível",
  home_plan: "Planear gado",
  home_map: "Mapear campos e planear colheitas",
  home_share: "Partilhar informações sobre o solo",
  home_submit: "Enviar candidatura",
  home_getting: "Começar",
  home_dashboard: "Manual do site",
  home_frequently: "Perguntas frequentes (FAQ)",
  home_add_farm: "Adicionar exploração",
  home_carbon_values: "Os valores de carbono previstos para 10 anos (toneladas)",
  home_project_terms: "Termos e condições",
  home_project_terms_agree: "Ao clicar em \"Enviar para revisão\" você concorda com nossos ",
  "home_project_terms_details": `
  <p>
    <strong>Dados Vinculativos:</strong> Os dados que você enviar aqui são vinculativos. Você confirma que as informações são precisas e completas com o melhor de seu conhecimento. Você pode solicitar o descarregamento de todos os seus dados, pois é da sua responsabilidade manter um registro separado e preciso de todos os dados enviados para sua referência em caso de quaisquer discrepâncias técnicas, perda de dados e possível auditoria.
  </p>
  <p>
    <strong>Arquivamento e Retenção de Dados:</strong> Seus dados serão arquivados eletronicamente e retidos por um mínimo de cinco anos após o término do último período de crédito agregado do projeto (até 2050). Este período de retenção se aplica mesmo se você for excluído de um período de crédito e não estiver mais trabalhando com a Climate Farmers.
  </p>
  <p>
    <strong>Auditoria e Conformidade:</strong> Você confirma que seus dados podem ser auditados pela Climate Farmers ou por um auditor terceirizado designado, como a TÜV Rheinland. Durante uma auditoria, informações adicionais podem ser solicitadas e visitas à fazenda podem ser realizadas para verificar a precisão dos dados.
  </p>
  <p>
    <strong>Consentimento para o Uso de Dados:</strong> Ao enviar seus dados neste painel, você consente com seu uso para fins de monitoramento relacionados ao cálculo e verificação dos Créditos Carbon+ da Climate Farmers, conforme descrito acima.
  </p>
  `,
  home_welcome_to_cf: "Bem-vindo à Climate Farmers",
  back_to_home: "Voltar",
  farminfo_tell: "Conte-nos um pouco sobre a sua exploração",
  farminfo_please_enter: "Por favor, insira as informações sobre a sua exploração/negócio.",
  farminfo_manager_fname: "Nome do gerente da exploração",
  farminfo_manager_lname: "Sobrenome do gerente da exploração",
  farminfo_owner_fname: "Nome do proprietário",
  farminfo_owner_lname: "Sobrenome do proprietário",
  farminfo_email: "Endereço de e-mail",
  farminfo_farmsize: "Tamanho da exploração em hectares",
  farminfo_street: "Morada da exploração (se aplicável)",
  farminfo_postal_code: "Código postal",
  farminfo_coord_north: "Coordenadas da exploração (° N)",
  farminfo_coord_east: "Coordenadas da exploração (° E)",
  farminfo_country: "País",
  farminfo_phone: "Telefone, incluindo o prefixo",
  farminfo_start_year: "Ano de início do projeto",
  farminfo_start_month: "Mês de início do projeto",
  farminfo_duration: "Duração do projeto",
  farminfo_challenges: "Desafios",
  farminfo_what_challenges: "Que desafios estão atualmente a impedi-lo/a de implementar (mais) práticas regenerativas na sua exploração?",
  farminfo_inv_barriers: "Barreiras de investimento",
  farminfo_inst_barriers: "Barreiras institucionais",
  farminfo_tech_barriers: "Barreiras tecnológicas",
  farminfo_reg_trad: "Barreiras relacionadas com tradições regionais",
  farminfo_mgmt_pract: "Barreiras que evoluem através das práticas de gestão existentes na exploração",
  farminfo_eco_circ: "Barreiras devido a circunstâncias ecológicas",
  farminfo_land_ownership: "Barreiras ligadas à propriedade fundiária, herança ou direitos de propriedade",
  farminfo_no_labour: "Sem acesso a mão de obra qualificada",
  farminfo_no_coaching: "Sem acesso a consultoria/treinamento qualificado",
  farminfo_other: "Outro - descreva",
  farminfo_no_barriers: "Sem barreiras",
  farminfo_describe: "Descrever",
  farminfo_add_more: "Adicionar outro",
  farminfo_remove: "Remover",
  farminfo_save: "Salvar",

  fu_dir_title: "Consumo de combustível > Direto",
  fu_dir_description_1: "Indique a utilização direta de combustível na sua exploração. Isto inclui todo o combustível que compra e utiliza na sua quinta. No entanto, exclua qualquer combustível utilizado noutras explorações ou por prestadores de serviços externos que trabalhem na sua exploração.",
  fu_dir_description_2: "As linhas e colunas assinaladas com * são obrigatórias.",
  fu_dir_quantity: "Quantidade usada",
  fuelusage_diesel: "Gasóleo",
  fu_section_subtitle_1: "Indique a quantidade de litros utilizados na sua exploração em cada ano *",
  fu_section_subtitle_2: "Adicione facturas como documentação de apoio para este montante (PDF ou JPG)",
  fu_section_infobox: "Tenha em atenção que, caso seja selecionado para verificação, ser-lhe-á pedido para apresentar uma declaração escrita relativa à utilização de combustível, caso não disponha de facturas ou outros documentos comprovativos.",
  fuelusage_petrol: "Gasolina",
  fu_comment_description: "Se pretender fornecer informações adicionais ou pormenores contextuais sobre o documento fornecido, comente abaixo.",
  fu_comment_type_here: "Escreva aqui",

  generic_save_continue: "Guardar e continuar",

  fu_indir_title: "Consumo de combustível > Indireto",
  fu_indir_description_1: "Forneça informações sobre todos os serviços que consumam combustível efectuados por prestadores de serviços externos na sua exploração (por exemplo, sementeira, lavoura, fenação, etc.). ",
  fu_indir_description_2: "Se tiver uma fatura do serviço externo, pode adicioná-la na primeira secção. Se não tiver uma fatura, basta indicar o tipo de serviço, descrever a operação de campo e especificar a dimensão da área onde o serviço foi efectuado na segunda secção. ",
  fu_indir_description_3: 'Se não utilizou serviços externos, pode clicar em "Não tive serviços externos" na parte inferior da página.',
  fu_indir_description_4: "As colunas assinaladas com * são obrigatórias.",
  fu_ind_section_subtitle_1: "Serviços externos com facturas",
  fu_ind_section_subtitle_2: "Serviços externos com ou sem facturas",
  fu_ind_info_box_1: "Adicione todos os serviços externos para os quais não tem fatura, selecione o tipo de serviço, descreva a atividade específica (por exemplo, colheita de cereais, colheita de feno, enfardamento de feno) e especifique a dimensão da área onde o serviço foi realizado.",
  fu_ind_info_box_2: "Tenha em atenção que, caso seja selecionado para verificação, ser-lhe-á pedido que forneça uma declaração escrita para a receção deste serviço",
  fu_ind_ext_serv: "Serviço externo",
  fu_ind_ext_serv_1: "Fertilização",
  fu_ind_ext_serv_2: "Lavoura",
  fu_ind_ext_serv_3: "Plantação",
  fu_ind_ext_serv_4: "Controlo das ervas daninhas",
  fu_ind_ext_serv_5: "Colheita",
  fu_ind_ext_serv_describe: "Descreva a atividade",
  fu_ind_ext_serv_add: "+ Adicionar um serviço",
  fu_ind_no_ext_serv: "Não tive serviços externos",

  generic_submit: "Enviar",


  general_download: "Descarregar",
  general_delete: "Eliminar",
  general_add_comment: "Comentar",
  general_edit_comment: "Comentar",
  general_edit_upload_doc: "Adicionar novo documento",

  fuelusage_fueltype: "Tipo de combustível",
  fuelusage_amt: "Quantidade em litros",
  fuelusage_other: "Outro",
  fuelusage_estimates: "Estimativas de utilização de combustível para o próximo ano (opcional)",
  fuelusage_this_refers: "Refere-se a quanto combustível estima utilizar no futuro por ano para atividades agrícolas, como máquinas",
  fuelusage_save: "Salvar",

  ls_mgmt: "Gestão do gado",
  ls_here_we: "Aqui precisamos que insira alguns detalhes sobre as práticas específicas de gestão de gado que estão atualmente em vigor e que também serão implementadas na exploraçãonos próximos 10 anos.",
  ls_add: "Adicionar gado",
  ls_edit: "Editar gado",
  ls_view: "Ver gado",
  ls_del: "Excluir gado",
  ls_no_data: "Sem dados para mostrar",
  ls_past: "Gestão de gado no passado",
  ls_here_past: "Aqui precisamos que indique e descreva práticas anteriores, relacionadas à gestão do gado.",
  ls_how_many: "Há quantos anos pratica a atual gestão de gado?",
  ls_pls_desc: "Descreva o que fazia de maneira diferente antes disso?",
  ls_current: "Atual",
  ls_livestock: "Gado",
  ls_livestock_mgmt_form: "Formulário de gestão de gado",
  ls_reset: "LIMPAR DADOS",
  ls_year1: "ANO 1",
  ls_year2: "Ano 2",
  ls_year3: "Ano 3",
  ls_year4: "Ano 4",
  ls_year5: "Ano 5",
  ls_year6: "Ano 6",
  ls_year7: "ANO 7",
  ls_year8: "Ano 8",
  ls_year9: "ANO 9",
  ls_year10: "Ano 10",
  ls_autofill: "Gostaria de preencher automaticamente os detalhes dos próximos 10 anos?",
  ls_yes: "Sim",
  ls_no: "Não",
  ls_species: "Espécies",
  ls_dairy: "Gado leiteiro",
  ls_beef: "Gado bovino",
  ls_calves: "Bezerros (menos de 1 ano)",
  ls_buff: "Búfalos",
  ls_sows: "Porcos selvagens/javalis",
  ls_pigs: "Porcos",
  ls_pigglets: "Leitões (menos de 1 ano)",
  ls_sheep: "Ovelhas",
  ls_lambs: "Cordeiros (menos de 1 ano)",
  ls_goats: "Cabras",
  ls_goat_kids: "Cabritos (menos de 1 ano)",
  ls_poultry: "Aves de capoeira",
  ls_turkey: "Perus",
  ls_ducks: "Patos",
  ls_horses: "Cavalos",
  ls_mules: "Mulas/Burros",
  ls_camelids: "Camelídeos",
  ls_no_of_heads: "Número de cabeças",
  ls_value: "O valor deve ser maior que 0",
  ls_stabled_caged: "Em estábulo/enjaulado",
  ls_unplanned: "Pastagem não planeada",
  ls_adaptive: "Pastagem adaptável multi-paddock",
  ls_grazing: "Dias de pastoreio por ano",
  ls_rest: "Resto do ano",
  ls_stabled: "Em estábulo",
  ls_slaughtered: "Abatido",
  ls_outdoors: "Paddock ao ar livre",
  ls_sold: "Vendido",
  ls_moved: "Movido",
  ls_manure: "Estrume",
  ls_percent: "Percentagem",
  ls_onfield: "No campo",
  ls_composted: "Compostado",
  ls_scattered: "Espalhado diretamente",
  ls_collection: "Coletado e descartado",
  ls_msg1: "Selecione uma linha na tabela de gestão de gado. Se ainda não houver linhas, adicione uma nova linha ao clicar em Adicionar gado.",
  ls_msg2: "Selecione uma única linha da tabela de gestão de gado",
  ls_msg3: "Selecione uma linha na tabela de gestão de gado para excluir. Se ainda não houver linhas, adicione uma nova linha ao clicar em adicionar gado.",
  ls_save: "GUARDAR",
  ls_close: "FECHAR",
  lu_map_location_msg_1: "A localização predefinida do mapa aponta para as coordenadas da exploração: ",
  lu_map_location_msg_2: "Se o local lhe parece incorreto, insira o endereço/coordenadas na barra de pesquisa ou volte à página de informações básicas da exploração e atualize as coordenadas. Observe que apenas as coordenadas da exploração na página mencionada serão consideradas como coordenadas predefinidas da exploração.",
  lu_search: "Procurar",
  lu_instrucns: "Instruções para criar uma parcela",
  lu_how_parcel: "Como faço para criar uma parcela?",
  lu_twoways: "Existem duas maneiras de criar uma parcela:",
  lu_importing: "Importar um ficheiro KML",
  lu_drawing: "Desenhar uma parcela no mapa",
  lu_importing_2: "Importar um ficheiro KML",
  lu_make_sure: "Certifique-se que tem o ficheiro KML da exploração",
  lu_click: "Clique no botão Importar um ficheiro KML",
  lu_select: "Selecione o ficheiro KML que deseja importar",
  lu_drawing_parcel: "Desenhar uma parcela no mapa",
  lu_select_drawer: "Clique no ícone no canto superior esquerdo do mapa",
  lu_identify: "Identifique o local da parcela da exploração e desenhe a parcela",
  lu_every: "Cada parcela é identificada por uma cor diferente.",
  lu_when: "Quando clicar numa parcela, um pop-up com o nome da parcela será exibido.",
  lu_i_have: "Tenho um ficheiro KMZ. Posso importar?",
  lu_no: "Não. Não pode importar um ficheiro KMZ. No entanto, um arquivo KMZ é uma versão zip de um ficheiro KML. Ao descompactar o ficheiro KMZ no seu computador, encontrará o arquivo KML na pasta não compactada. Solicitamos que envie o arquivo KML.",
  lu_how_can: "Como posso criar um ficheiro KML da minha exploração?",
  lu_a_common: "Uma forma simples de criar o ficheiro KML é utilizando o Google Earth. Para tal, instale o Google Earth no seu computador, crie as suas parcelas agrícolas e exporte o ficheiro KML que contém as parcelas da sua exploração. Caso possa exportar apenas o ficheiro KMZ, descompacte o ficheiro KMZ e faça o upload do ficheiro KML presente na pasta não compactada.",
  lu_close: "FECHAR",
  lu_parcel: "Modelos de parcelas",
  lu_if_you: "Caso possua práticas ou culturas que aplica em vários campos, pode criar modelos e, posteriormente, importá-los para diferentes parcelas.",
  lu_create_new: "Criar novo modelo",
  lu_actions: "Ações",
  lu_temp: "Nome do modelo",
  lu_rows_per_page: "Linhas por página",
  temp_parcel_template: "Modelos de parcelas",
  temp_template_name: "Nome do modelo",
  temp_land_use: "Tipo de uso do solo",
  temp_silvopasture: "Silvopastorícia",
  temp_arable: "Cultura arável",
  temp_grass: "Pastagens",
  temp_agro: "Agroflorestal",
  temp_forest: "Silvicultura",
  temp_type_1: "Tipo de árvores",
  temp_quercus: "Quercus",
  temp_olea: "Olea Europea",
  temp_other: "Outra",
  temp_tree_name_1: "Nome da árvore (se não estiver presente)",
  temp_avg_1: "Número médio de árvores por hectare",
  temp_tree_avg_1: "DAP média da árvore em cm (diâmetro à altura do peito)",
  temp_irrigation: "Irrigação",
  temp_syn_fert: "Fertilizante sintético",
  temp_fert: "Fertilizante (t/ano)",
  temp_nitro: "% Nitrogénio",
  temp_manure: "Aplicação de estrume (t/ha)",
  temp_per_manure: "% de estrume importado",
  temp_compost: "Aplicação de composto (t/ha)",
  temp_per_compost: "% de composto importado",
  temp_hay: "Aplicação de feno (t/ha)",
  temp_per_hay: "% de feno importado",
  temp_bale: "Pastagem de fardo",
  temp_residue: "Resíduo deixado após pastagem de fardo (%)",
  temp_cash: "Cultura comercial",
  temp_name: "Nome se não estiver na lista",
  temp_cover: "Cultura de cobertura",
  temp_desc: "Descreva a mistura (pelo menos 1 grama e 1 leguminos)",
  temp_multispecies: "Cultura de cobertura multiespécies",
  temp_at_least: "Pelo menos 3 tipos diferentes",
  temp_unplanned: "Pastagem não planeada",
  temp_grazing_multi: "Pastagem multiespécies",
  temp_adaptive: "Pastagem adaptável multi-paddock",
  temp_yields: "Os rendimentos e os valores de resíduos (t/ha) são fornecidos como biomassa seca (de preferência) ou fresca?",
  temp_jan: "Jan",
  temp_feb: "Fev",
  temp_mar: "Mar",
  temp_apr: "Abr",
  temp_may: "Mai",
  temp_jun: "Jun",
  temp_jul: "Jul",
  temp_aug: "Ago",
  temp_sep: "Set",
  temp_oct: "Out",
  temp_nov: "Nov",
  temp_dec: "Dez",
  temp_cash_1: "Cultura comercial - 1",
  temp_no_cash_1: "Sem culturas para comércio - 1",
  temp_aromatics: "Aromáticas",
  temp_barley: "Cevada",
  temp_beans: "Feijão",
  temp_beets: "Beterraba",
  temp_clover: "Trevo",
  temp_corn: "Milho",
  temp_cowpea: "Feijão frade",
  temp_forage_beet: "Beterraba de forragem",
  temp_forage_kale: "Couve de forragem",
  temp_radish: "Rabanete de forragem",
  temp_turnip: "Nabo de forragem",
  temp_garlic: "Alho",
  temp_lupins: "Tremoços",
  temp_millet: "Milho painço",
  temp_oats: "Aveia",
  temp_onions: "Cebola",
  temp_other_grains: "Outros grãos",
  temp_other_vegetables: "Outros vegetais",
  temp_peanuts: "Amendoim",
  temp_peas: "Ervilha",
  temp_potatoes: "Batatas e tubérculos",
  temp_pumpkin: "Abóbora",
  temp_rice: "Arroz",
  temp_rye: "Centeio",
  temp_seeds: "Sementes",
  temp_sesame: "Sésamo",
  temp_shrubb: "Arbustos perenes",
  temp_sillage: "Milho para silagem",
  temp_sorghum_sudan: "Sorgo Sudão",
  temp_sorghum: "Sorgo",
  temp_soybean: "Soja",
  temp_squash: "",
  temp_sunflower: "Girassol",
  temp_sunn_hemp: "Cânhamo sunn",
  temp_triticale: "Triticale",
  temp_wheat: "Trigo",
  temp_cash_2: "Cultura comercial - 2",
  temp_no_cash_2: "Sem culturas para comércio - 2",
  temp_cover_crops: "Culturas de cobertura",
  temp_fallow: "Pousio produtivo",
  temp_grazing: "Pastagem",
  temp_harvest_yield: "Rendimento da colheita (t/ha)",
  temp_grazing_yield: "Rendimento de pastagem (t/ha)",
  temp_whats_left: "O que resta após o pastoreio/colheita de resíduos (t/ha)",
  temp_bare_soil: "Solo descoberto em pousio",
  temp_tilling_event: "Lavoração do solo",
  temp_min_till: "Lavoração mínima do solo? (<10 cm de profundidade)",
  temp_save: "Guardar",
  temp_close: "Fechar",
  parcel_maps: "Mapa de parcelas",
  parcel_here: "Aqui precisamos que nos forneça mais alguns detalhes sobre as suas parcelas:",
  parcel_on: 'Na lista abaixo, clique em "Editar" para adicionar informações às suas parcelas. Aqui, pedimos que forneça dados atuais (quais práticas, culturas, meses de pastagem etc. gerencia no momento) e estimativas para os próximos 10 anos.',
  parcel_you: "Pode navegar entre os separadores no topo do painel para cada ano.",
  parcel_import: "Importar ficheiro KML",
  parcel_del: "Eliminar todas as parcelas",
  parcel_actions: "Ações",
  parcel_parcel_name: "Nome da parcela",
  parcel_past: "Práticas passadas e atuais",
  parcel_year1: "Ano 1",
  parcel_year2: "Ano 2",
  parcel_year3: "Ano 3",
  parcel_year4: "Ano 4",
  parcel_year5: "Ano 5",
  parcel_year6: "Ano 6",
  parcel_year7: "Ano 7",
  parcel_year8: "Ano 8",
  parcel_year9: "Ano 9",
  parcel_year10: "Ano 10",
  parcel_parcelname: "Nome da parcela",
  parcel_coordinates: "Coordenadas",
  parcel_area: "Área (m2)",
  parcel_choose: "Escolho inserir a área manualmente",
  parcel_manual: "Área inserida manualmente (m2)",
  parcel_parcels: "Parcelas",
  parcel_copy_data: "Copiar dados",
  parcel_templates: "Modelos",
  parcel_import_data: "IMPORTAR DADOS",
  parcel_applying: "Aplica estas práticas desde (anos)?*",
  parcel_like_copy: "Gostaria de copiar práticas atuais para os próximos 10 anos",
  parcel_are_sure: "Tem certeza de que deseja copiar os dados da parcela?",
  parcel_you_are_about: "Está prestes a copiar os dados da parcela selecionada para parcela atual. Deseja continuar?",
  parcel_agree: "Concordar",
  parcel_disagree: "Discordar",
  parcel_are_you_import: "Tem a certeza de que deseja importar os dados do modelo?",
  parcel_about_import: "Está prestes a importar os dados do modelo para a parcela atual para o ano fornecido. Deseja continuar?",
  parcel_agree_2: "Concordar",
  parcel_disagree_2: "Discordar",
  parcel_land_use: "Tipo de uso do solo",
  parcel_this_refers: "Isto refere-se ao cenário agrícola da parcela da exploração",
  parcel_silvopasture: "Silvopastorícia",
  parcel_arable: "Cultura arável",
  parcel_grass: "Pastagem",
  parcel_agro: "Agroflorestal",
  parcel_forest: "Silvicultura",
  parcel_type_1: "Tipo de árvores",
  parcel_quercus: "Quercus",
  parcel_olea: "Olea Europea",
  parcel_other: "Outro",
  parcel_avg_no_2: "Número médio de árvores por hectare",
  parcel_tree_avg_dbh: "DAP média da árvore em cm (diâmetro à altura do peito)",
  parcel_tree_name_1: "Nome da árvore (se não estiver presente)",
  parcel_avg_1: "Número médio de árvores por hectare",
  parcel_tree_avg_1: "DAP média da árvore em cm (diâmetro à altura do peito)",
  parcel_tree_name_2: "Nome da árvore (se não estiver presente)",
  parcel_avg_2: "Número médio de árvores por hectare",
  parcel_tree_avg_2: "DAP média da árvore em cm (diâmetro à altura do peito)",
  parcel_tree_name_3: "Nome da árvore (se não estiver presente)",
  parcel_avg_3: "Número médio de árvores por hectare",
  parcel_tree_avg_3: "DAP média da árvore em cm (diâmetro à altura do peito)",
  parcel_irrigation: "Irrigação",
  parcel_syn_fert: "Fertilizante sintético",
  parcel_fert: "Fertilizante (t/ano)",
  parcel_nitro: "% Nitrogénio",
  parcel_manure: "Aplicação de estrume (t/ha)",
  parcel_per_manure: "% de estrume importado",
  parcel_compost: "Aplicação de composto (t/ha)",
  parcel_per_compost: "% composto importado",
  parcel_hay: "Aplicação de feno (t/ha)",
  parcel_per_hay: "% de feno importado",
  parcel_bale: "Pastagem de fardo",
  parcel_residue: "Resíduo deixado após pastagem de fardo (%)",
  parcel_cash: "Cultura comercial",
  parcel_name: "Nome se não estiver na lista",
  parcel_cover: "Cultura de cobertura",
  parcel_desc: "Descreva a mistura (pelo menos 1 grama e 1 leguminos)",
  parcel_multispecies: "Cultura de cobertura multiespécies",
  parcel_atleast: "Pelo menos 3 tipos diferentes",
  parcel_unplanned: "Pastagem não planeada",
  parcel_grazing_multi: "Pastagem multi-espécies",
  parcel_adaptive: "Pastagem adaptável multi-paddock",
  parcel_yields: "Os rendimentos e os valores de resíduos (t/ha) são fornecidos como biomassa seca (de preferência) ou fresca?",
  parcel_jan: "Jan",
  parcel_feb: "Fev",
  parcel_mar: "Mar",
  parcel_apr: "Abr",
  parcel_may: "Mai",
  parcel_jun: "Jun",
  parcel_jul: "Jul",
  parcel_aug: "Ago",
  parcel_sep: "Set",
  parcel_oct: "Out",
  parcel_nov: "Nov",
  parcel_dec: "Dez",
  parcel_cash_1: "Cultura Comercial - 1",
  parcel_no_cash_1: "Sem culturas para comércio - 1",
  parcel_aromatics: "Aromáticas",
  parcel_barley: "Cevada",
  parcel_beans: "Feijão",
  parcel_beets: "Beterraba",
  parcel_clover: "Trevo",
  parcel_corn: "Milho",
  parcel_cowpea: "Feijão frade",
  parcel_forage_beet: "Beterraba de forragem",
  parcel_forage_kale: "Couve de forragem",
  parcel_radish: "Rabanete de forragem",
  parcel_turnip: "Nabo de forragem",
  parcel_garlic: "Alho",
  parcel_lupins: "Tremoços",
  parcel_millet: "Milho painço",
  parcel_oats: "Aveia",
  parcel_onions: "Cebola",
  parcel_other_grains: "Outros grãos",
  parcel_other_vegetables: "Outros vegetais",
  parcel_peanuts: "Amendoim",
  parcel_peas: "Ervilha",
  parcel_potatoes: "Batatas e tubérculos",
  parcel_pumpkin: "Abóbora",
  parcel_rice: "Arroz",
  parcel_rye: "Centeio",
  parcel_seeds: "Sementes",
  parcel_sesame: "Sésamo",
  parcel_shrubb: "Arbustos perenes",
  parcel_sillage: "Milho para silagem",
  parcel_sorghum_sudan: "Sorgo Sudão",
  parcel_sorghum: "Sorgo",
  parcel_soybean: "Soja",
  parcel_squash: "",
  parcel_sunflower: "Girassol",
  parcel_sunn_hemp: "Cânhamo sunn",
  parcel_triticale: "Triticale",
  parcel_wheat: "Trigo",
  parcel_cash_2: "Cultura comercial - 2",
  parcel_no_cash_2: "Sem culturas para comércio - 2",
  parcel_cover_crops: "Culturas de cobertura",
  parcel_fallow: "Pousio produtivo",
  parcel_grazing: "Pastagem",
  parcel_harvest_yield: "Rendimento da colheita (t/ha)",
  parcel_grazing_yield: "Rendimento de pastagem (t/ha)",
  parcel_whats_left: "O que resta após o pastoreio/colheita de resíduos (t/ha)",
  parcel_bare_soil: "Solo descoberto em pousio",
  parcel_tilling_event: "Lavoração do solo",
  parcel_min_till: "Lavoração mínima do solo? (<10 cm de profundidade)",
  parcel_save: "Guardar",
  parcel_close: "Fechar",
  parcel_y1: "Ano 1",
  parcel_y2: "Ano 2",
  parcel_y3: "Ano 3",
  parcel_y4: "Ano 4",
  parcel_y5: "Ano 5",
  parcel_y6: "Ano 6",
  parcel_y7: "Ano 7",
  parcel_y8: "Ano 8",
  parcel_y9: "Ano 9",
  parcel_y10: "Ano 10",
  parcel_pls_save: 'Clique nem "Guardar" para salvar os seus dados antes de clicar em fechar',
  sa_past: "Análises anterior ao solo",
  sa_if_you: "Se, no passado, fez análises ao solo preencha as seguintes informações (opcional)",
  sa_clay_per: "Conteúdo de argila (%)",
  sa_sil_per: "Conteúdo de silte (%)",
  sa_if_clay: "Se o conteúdo de argila e silte não estiver disponível:",
  sa_text_desc: "Descrição da textura",
  sa_sand: "Areia",
  sa_lo_sand: "Areia barrenta",
  sa_sandy_lo: "Barro arenoso",
  sa_san_cl_loam: "Barro argiloso arenoso",
  sa_loam: "Barro",
  sa_sil_loam: "Barro siltado",
  sa_silt_2: "Silte",
  sa_silt_cl_loam: "Barro siltado argiloso",
  sa_clay_3: "Argila",
  sa_clay_loam: "Argila barrenta",
  sa_sandy_clay: "Barro arenoso",
  sa_silty_clay: "Barro siltada",
  sa_bulk_density: "Densidade aparente",
  sa_g_kg: "g / kg",
  sa_g: "g",
  sa_kg: "kg",
  sa_carbon: "Teor de carbono",
  sa_kg_kg_per: "g / kg / %",
  sa_g_2: "g",
  sa_kg_2: "kg",
  sa_per: "%",
  sa_if_carbon: "Se o conteúdo de carbono não estiver disponível:",
  sa_organic: "Conteúdo de matéria orgânica",
  sa_g_kg_per_2: "g / kg / %",
  sa_g_3: "g",
  sa_kg_3: "kg",
  sa_per_3: "%",
  sa_save: "Guardar",
  generic_saving_data: "A guardar os dados. Por favor, aguarde.",
  generic_save: "Guardar",
  dashboard_manual_pdf: "Manual do painel",
  faq_pdf: "Perguntas frequentes (FAQ)",
  nc_text_1_not_submitted: "Complete a sua candidatura",
  nc_text_1_under_review: "Plano agrícola submetido 🥳",
  nc_text_1_data_accepted: "A sua candidatura foi aceite",
  nc_text_2_not_submitted: "Conte-nos sobre a sua exploração",
  nc_text_2_under_review: "Em análise",
  nc_text_bottom_under_review: "A sua candidatura ao Carbon+ foi enviada e a nossa equipa científica está a analisá-la.",
  nc_farminfo_step_regular: "Informações básicas ",
  nc_farminfo_step_bold: "da sua exploração",
  nc_fuel_step_regular: "Indicar",
  nc_fuel_step_bold: "uso de combustível",
  nc_livestock_step_regular: "Planear o",
  nc_livestock_step_bold: "gado",
  nc_landuse_step_regular: "Mapear ",
  nc_landuse_step_bold: "campos e planear colheitas",
  nc_soil_step_regular: "Compartilhe ",
  nc_soil_step_bold: "amostras do solo",
  jan: "Janeiro",
  feb: "Fevereiro",
  mar: "Março",
  apr: "Abril",
  may: "Maio",
  jun: "Junho",
  jul: "Julho",
  aug: "Agosto",
  sep: "Setembro",
  oct: "Outubro",
  nov: "Novembro",
  dec: "Dezembro",
  ls_first_year: "Este é o meu primeiro ano",
  ls_value_percent: "O valor deve ser entre 0 e 100",
  ls_value_year: "O valor deve serentre 0 e 365",
  generic_edit: "Editar",
  generic_view: "Visualizar",
  generic_delete: "Eliminar",
  lu_title: "Sumário dos dados de gestão da terra",
  values_fresh: "Fresco",
  values_dry: "Seco",
  generic_close: "Fechar",
  generic_agree: "Concordar",
  generic_disagree: "Discordar",
  ls_table_year1: "Ano 1",
  ls_table_year2: "Ano 2",
  ls_table_year3: "Ano 3",
  ls_table_year4: "Ano 4",
  ls_table_year5: "Ano 5",
  ls_table_year6: "Ano 6",
  ls_table_year7: "Ano 7",
  ls_table_year8: "Ano 8",
  ls_table_year9: "Ano 9",
  ls_table_year10: "Ano 10",
  carbon_y1: "Ano 1",
  carbon_y2: "Ano 2",
  carbon_y3: "Ano 3",
  carbon_y4: "Ano 4",
  carbon_y5: "Ano 5",
  carbon_y6: "Ano 6",
  carbon_y7: "Ano 7",
  carbon_y8: "Ano 8",
  carbon_y9: "Ano 9",
  carbon_y10: "Ano 10",
  parcel_delete: "Está prestes a eliminar a parcela. Deseja continuar?",
  parcel_copy_sure: "Tem a certeza de que deseja copiar as práticas atuais?",
  parcel_copy_continue: "Está prestes a copiar as práticas atuais para os próximos 10 anos. Gostaria de continuar?",
  parcel_delete_sure: "Tem a certeza de que deseja eliminar todas as parcelas?",
  parcel_delete_continue: "Está prestes a eliminar todas as parcelas. Deseja continuar?",
  farmland_draft_submission_msg_success: "Os detalhes da exploração foram guardados como rascunho com sucesso. Pode revisitar o formulário para fazer modificações antes da submissão final.",
  farmland_draft_submission_msg_failure: "Houve um erro ao guardar o rascunho dos detalhes da exploração. Por favor, verifique os valores introduzidos ou contacte o Apoio ao Cliente da Climate Farmers através do email support@climatefarmers.org",
  farmland_final_submission_msg_success: "Os detalhes da exploração foram guardados com sucesso. Já não poderá editar os detalhes da exploração. Caso tenha alguma dúvida, por favor contacte o Apoio ao Cliente da Climate Farmers através do email support@climatefarmers.org",
  farmland_final_submission_msg_failure: "Houve um erro ao guardar os detalhes da exploração. Por favor, verifique os valores introduzidos ou contacte o Apoio ao Cliente da Climate Farmers através do email support@climatefarmers.org",
  farmland_updated_by_farmer_liaison_success: "Os detalhes da exploração foram atualizados com sucesso.",
  msg_mandatory_fields: "Todos os campos marcados como * são obrigatórios e precisam ser preenchidos antes de prosseguir para a próxima etapa",
  email_subject: "Updates da Climate Farmers",
  email_subject_farm_draft_submission: "Climate Farmers | Submissão de dados como um rascunho",
  email_subject_farm_final_submission: "Climate Farmers | Submissão final de dados",
  email_body_farmland_update: "Olá, temos uma atualização sobre a sua exploração. Por favor, faça login na sua conta Climate Farmers para ver a atualização.",
  email_body_farmland_draft_submission: "Olá, guardou os detalhes da sua exploração como um rascunho. Pode editar os detalhes da sua exploração em qualquer altura. Sinta-se à vontade para responder a este e-mail se tiver alguma dúvida.",
  email_body_farmland_final_submission: "Olá, obrigado por submeter os detalhes da sua exploração à Climate Farmers. Iremos rever a informação e entraremos em contacto consigo o mais brevemente possível. Para quaisquer outras questões, por favor, sinta-se à vontade para responder a este e-mail.",
  infobox_parcel_temp_1: "Quando alguns dos seus campos têm características recorrentes, tais como certas árvores, culturas ou práticas, é útil criar modelos.",
  infobox_parcel_temp_2: "Desta forma, poderá replicar estes modelos noutros campos. Esta opção é útil especialmente quando se pratica rotações de culturas, e por isso precisa de introduzir dados completamente diferentes para cada ano.",
  infobox_parcel_temp_3: 'Para fazê-lo, pode primeiro criar um modelo clicando em "Criar novo modelo" (mesmo por baixo do seu mapa). Pode então criar e nomear tantos modelos diferentes quantos necessitar. Recomendamos que escolha nomes que sejam fáceis de identificar, por exemplo "Azeitonas - Pastagens" ou "Culturas Aráveis - Trigo".',
  infobox_coordinates_1: "Se não sabe as coordenadas da sua exploração, vá ao Google Maps e prima o botão direito do rato sobre a sua exploração. Prima o botão esquerdo do rato sobre os 2 números que aparecem na primeira linha para copiá-los e depois cole-os aqui.",
  infobox_coordinates_2: "Observação:",
  infobox_coordinates_3: "O primeiro número é a coordenada",
  infobox_coordinates_4: "Norte",
  infobox_coordinates_5: ", e o segundo é a coordenada ",
  infobox_coordinates_6: "Este",
  infobox_coordinates_7: ". Se tiver alguma coordenada Oeste ou Sul, basta colocar um negativo",
  infobox_coordinates_8: "(-) antes",
  infobox_coordinates_9: "dessa coordenada. Certifique-se de que insere cada número no campo correto, caso contrário, o local do seu mapa será exibido incorretamente.",
  infobox_coordinates_10: 'Pode verificar se as coordenadas estão corretas na página "Mapear campos e planear colheitas".',
  infobox_ls_mgmt_1: "Opções de gestão de gado:",
  infobox_ls_mgmt_2: "- em estábulo/enjaulado",
  infobox_ls_mgmt_3: "- pastagem não planeada",
  infobox_ls_mgmt_4: "Isto descreve a prática de pastoreio do gado num grande paddock durante um período prolongado. Também pode seleccionar esta prática se já praticar o pastoreio tradicional ou rotativo, mas sem um plano / gráfico de pastoreio.",
  infobox_ls_mgmt_5: "- pastagem adaptável multi-paddock / pastagens",
  infobox_ls_mgmt_6: "Isto refere-se à divisão de grandes áreas de pastagem em parcelas menores, utilizando vedações físicas ou eléctricas. A rotação do gado é realizada de acordo com a densidade de ocupação e duração de pastoreio otimizada por cercado, tendo em conta fatores como os recursos naturais disponíveis (evitando sobrepastoreio e sobrepousio). Um",
  infobox_ls_mgmt_7: "gráfico de pastagem",
  infobox_ls_mgmt_8: " é usado para planear, registar e monitorar, e precisa ser-nos enviado.",
  infobox_ls_manure_1: "Por favor, escolha a forma como gere o estrume na sua exploração (em %):",
  infobox_ls_manure_2: "- No campo:",
  infobox_ls_manure_3: " o estrume é deixado no campo durante o pastoreio",
  infobox_ls_manure_4: "- Compostado:",
  infobox_ls_manure_5: " o estrume é usado para compostagem na exploração",
  infobox_ls_manure_6: "- Espalhado diretamente:",
  infobox_ls_manure_7: "o estrume é recolhido e aplicado nos campos e pastagens como fertilizante",
  infobox_ls_manure_8: "- Coleta e descarte:",
  infobox_ls_manure_9: "o estrume é recolhido e não é utilizado no local",
  infobox_lu_area_1: "A área de cada parcela será automaticamente calculada a partir da parcela que criou no mapa. No entanto, se achar que este número não é realista, à direita pode introduzir manualmente a sua área de superfície",
  infobox_lu_area_2: "em metros quadrados (m2).",
  infobox_lu_parcels: 'Quando duas parcelas têm características iguais, ou semelhantes, pode também copiar rapidamente a informação de uma para a outra, seleccionando a parcela de onde a obter, e clicando em "COPIAR DADOS". Desta forma, a nova parcela será uma cópia exacta da anterior, e ainda poderá fazer alterações à mesma.',
  infobox_lu_copy_practices: "Aqui pode copiar os detalhes inseridos para os próximos 10 anos e depois fazer as correcções para cada ano e parcela. Certifique-se que desmarca esta opção se quiser fazer alterações adicionais nos anos seguintes (caso contrário, o sistema não lhe permitirá fazê-lo).",
  infobox_lu_temp: 'Aqui, pode importar qualquer um dos modelos que criou anteriormente seleccionando-o e clicando em "IMPORTAR DADOS". Lembre-se que se modificar o modelo depois de o copiar para uma parcela, os dados aqui não serão afectados.',
  infobox_lu_type_1: "Silvopastorícia: ",
  infobox_lu_type_2: "A integração de árvores, forragem e pastoreio do gado de uma forma mutuamente benéfica. por exemplo, o sistema Montado / Dehesa. As pastagens podem ser pastoreados ou colhidos.",
  infobox_lu_type_3: "Cultura arável:",
  infobox_lu_type_4: "Grãos, feijões, sementes, leguminosas ou culturas de fibras. Podem ser pastoreados ou colhidos.",
  infobox_lu_type_5: "Pastagens:",
  infobox_lu_type_6: "Uma área em que a vegetação é dominada por uma cobertura quase contínua de gramíneas. Pode ser pastoreado ou colhido (feno).",
  infobox_lu_type_7: "Agroflorestal:",
  infobox_lu_type_8: "Um sistema de uso do solo em que árvores ou arbustos são cultivados em redor ou entre culturas ou pastagens. Inclui árvores de fruta ou de nozes ou outras perenes, tais como aromáticas.",
  infobox_lu_type_9: "Sem pastoreio.",
  infobox_lu_type_10: " As pastagens podem ser colhidas.",
  infobox_lu_type_11: "Silvicultura:",
  infobox_lu_type_12: "Florestas utilizadas para a produção de madeira. Sem pastoreio ou colheita.",
  infobox_lu_per_manure: "Por favor, defina quanto (em %) do material que aplica é trazido do exterior da exploração.",
  infobox_lu_bale_grz_1: "Pastagem de fardo",
  infobox_lu_bale_grz_2: "nos campos é um método de fornecimento de ração ao gado de carne durante os meses de Inverno. Com este sistema, o gado tem acesso a fardos previamente colocados num campo ou local de invernada.",
  infobox_lu_bale_grz_3: "Resíduo deixado após pastagem de fardo (%)",
  infobox_lu_bale_grz_4: "Por favor, indique quanto da biomassa aplicada é deixada no campo após o pastoreio.",
  infobox_lu_cash_crop_1: "Cultura comercial",
  infobox_lu_cash_crop_2: "Refere-se a uma cultura agrícola que é cultivada para ser vendida com fins lucrativos.",
  infobox_lu_cover_crop_1: "Cultura de cobertura",
  infobox_lu_cover_crop_2: "Refere-se à prática da sementeira de espécies vegetais (pelo menos 2 espécies diferentes, uma erva e uma leguminosa) cuja utilização se destine principalmente a melhorar as características físicas, químicas e biológicas do solo. As culturas de cobertura podem ser pastoreadas, colhidas, incorporadas no solo, terminadas com um roller-crimper ou deixadas como resíduos após o corte.",
  infobox_lu_multi_cover_crop_1: "Multiespécies cobrem a colheita",
  infobox_lu_multi_cover_crop_2: "Refere -se à prática de semear uma mistura diversificada de espécies de plantas cujo uso se destina principalmente a melhorar as características físicas, químicas e biológicas do solo.",
  infobox_lu_multi_cover_crop_3: "Precisa incluir ",
  infobox_lu_multi_cover_crop_4: "pelo menos 3 espécies de plantas:",
  infobox_lu_multi_cover_crop_5: "- pelo menos uma espécie de gramíneas (Poaceae)",
  infobox_lu_multi_cover_crop_6: "- pelo menos uma espécie de leguminosas (Fabaceae)",
  infobox_lu_multi_cover_crop_7: "- Pelo menos uma das seguintes famílias: Brassicaceae, Amaranthaceae,  Linaceae, Plantaginaceae, Asteraceae, Polygonaceae, Boraginaceae, Malvaceae, Rosaceae, Cucurbitaceae.",
  infobox_lu_unplanned_grz_1: "Pastagem não planeada",
  infobox_lu_unplanned_grz_2: "Isto descreve a prática de pastoreio do gado num grande paddock durante um período prolongado.",
  infobox_lu_unplanned_grz_3: "Também pode seleccionar esta prática se já praticar o pastoreio tradicional ou rotativo, mas sem um plano/gráfico de pastoreio.",
  infobox_lu_multi_grz_1: "Pastagem Multiespécies",
  infobox_lu_multi_grz_2: "Refere-se ao pastoreio de",
  infobox_lu_multi_grz_3: " pelo menos 2 espécies diferentes ",
  infobox_lu_multi_grz_4: "entre: ruminantes de pastoreio, equídeos, camelídeos, porcos ou aves de capoeira pastoreadas.",
  infobox_lu_adapt_graz_1: "Pastagem Adaptável Multi-Paddock",
  infobox_lu_adapt_graz_2: "Refere-se à divisão de grandes áreas de pastagem em parcelas menores, utilizando vedações físicas ou eléctricas. A rotação do gado é efetuada de acordo com a densidade animal e duração de pastoreio otimizada por cercado, tendo em conta fatores como os recursos naturais disponíveis (evitar o sobrepastoreio e o sobrepousio).",
  infobox_lu_adapt_graz_3: "Um",
  infobox_lu_adapt_graz_4: "gráfico de pastagem",
  infobox_lu_adapt_graz_5: " é usado para planear, registar e monitorizar, e precisa ser-nos enviado.",
  infobox_lu_check_cover: "Por favor, assinale todos os meses em que pratica Culturas de Cobertura ou Culturas de Cobertura Multiespécies",
  infobox_lu_check_prod_fallow: "O pousio produtivo refere-se a uma cobertura do solo que não é semeada intencionalmente, tal como erva ou outra vegetação espontânea.",
  infobox_lu_check_bare_soil: "Por favor, assinale todos os meses em que seu solo é deixado descoberto.",
  infobox_lu_check_granzing: "Por favor, assinale todos os meses em que ocorre pastoreio nesta parcela.",
  infobox_lu_harvest_yield_1: "Por favor, adicione os rendimentos estimados de culturas colhidas (culturas aráveis, culturas de cobertura, feno, etc) em toneladas por hectare (t/ha).",
  infobox_lu_harvest_yield_2: "Se esta for uma parcela agro-florestal, pode deixar o campo vazio.",
  infobox_lu_grz_yield_1: "O rendimento de pastoreio refere-se ao que os animais consumiram depois de estarem nesta parcela.",
  infobox_lu_grz_yield_2: "Isto pode ser calculado aproximadamente utilizando o número de animais e dias de pastagem, ou em toneladas de MS (matéria seca) por amostragem e pesagem. A maioria dos animais ruminantes consome 3% do seu peso vivo em matéria seca de forragem por dia.",
  infobox_lu_whats_left: "Por favor, acrescente a quantidade de resíduos que são deixados no campo após colheita e/ou pastoreio, em toneladas por hectare (t/ha).",
  infobox_lu_min_till_1: "Isto refere-se a uma pequena perturbação do solo utilizando um arado, arado de disco, arado de cinzel, ou motocultivador, com as seguintes limitações: até 10 cm de profundidade, se toda a área for lavrada; OU se mais de 30% da área for lavrada, então deve ser feita em tiras.",
  infobox_lu_min_till_2: "A subsolagem é permitida desde que se utilizem dentes que não causem destruição da estrutura e estratificação do solo.",

  sb_signout: "Terminar sessão",
  sb_home: "Página inicial",
  sb_profile: "Perfil",
  pp_since: "Membro do Carbon+ desde",
  pp_personal_info: "Informações pessoais",
  pp_name: "Nome Oficial",
  pp_email: "Email",
  pp_phone: "Número de telefone",
  pp_settings: "Definições",
  pp_language: "Idioma",
  generic_cancel: "Cancelar",
  pp_password: "Palavra-passe",
  nc_popup_sure: "Tem a certeza?",
  nc_popup_you: "Está prestes a submeter a sua Candidatura ao Carbon+.",
  nc_popup_after: "Após submisssão, a nossa equipa científica iniciará a revisão da candidatura.",
  nc_popup_not_1: "",
  nc_popup_not_2: "Não",
  nc_popup_not_3: " poderá editar a sua candidatura após submissão.",
  nc_popup_yes: "Sim, submeter",
  nc_popup_no: "Não, continuar a editar",

  FARM_DATA_IS_YET_TO_BE_SUBMITTED: "Farm Data is yet to be submitted",
  FARM_DATA_UNDER_REVIEW: "Farm Data is under Review",
  FARM_DATA_ACCEPTED: "Farm Data has been accepted",
  PREPARATION_OF_CONTRACT: "Contract Preparation is in progress",
  SIGNING_OF_CONTRACT: "Contract Signing is in progress",
  PREPARATION_OF_PROJECT_DESCRIPTION: "Preparation of Project Description is in progress",
  ISSUANCE_OF_CERTIFICATE: "Carbon+ Certificate has been issued",

  infobox_farminfo_general: "Se notar alguma imprecisão nas informações aqui presentes, não hesite em entrar em contacto com a Iris/Maria através do WhatsApp. Responderemos à sua mensagem durante o horário de trabalho.",
  infobox_farminfo_start_year_1: "Os créditos são calculados com base no ano civil. Tem a flexibilidade de selecionar o ano de início do seu projeto, dependendo de quando começou a implementar práticas para melhorar a qualidade do solo e a produtividade da exploração. É importante notar que os dados dos três anos anteriores ao ano de início do seu projeto serão utilizados para estabelecer o seu cenário de 'base', representando o estado da terra antes do início dos esforços de regeneração. Por exemplo, se o seu projeto tiver início em 2023, os dados dos anos 2020, 2021 e 2022 serão considerados para efeitos de cálculo da sua base de referência. O ano de início do projeto mais cedo possível é 2021.",
  infobox_farminfo_start_year_2: "As melhorias em relação à sua base de referência determinarão o número de créditos criados. Por conseguinte, é importante escolher cuidadosamente o ano de início do seu projeto",
  ver_doc_info_1: "Apresente ",
  ver_doc_info_2: "todos os documentos disponíveis ",
  ver_doc_info_3: "solicitados nesta página. Se lhe faltarem alguns documentos ou precisar de ajuda, não hesite em contactar-nos. Pode iniciar uma conversa com o nosso chatbot no canto inferior direito ou contactar a Iris/Maria através do WhatsApp. Responderemos à sua mensagem durante o horário de trabalho. Os documentos assinalados com * são obrigatórios para passar à fase seguinte do processo.",

  uploading_document_msg_success: "Document uploaded successfully.",
  uploading_document_msg_failure: "There was an error uploading your file. Please try again or contact the Climate Farmers Customer Support on support@climatefarmers.org",
  downloading_document_msg_success: "Document downloaded successfully.",
  downloading_document_msg_failure: "There was an error downloading your file. Please try again or contact the Climate Farmers Customer Support on support@climatefarmers.org",
  deleting_document_msg_success: "Document deleted successfully.",
  deleting_document_msg_failure: "There was an error deleting your file. Please try again or contact the Climate Farmers Customer Support on support@climatefarmers.org",

  generic_loading: "A carregar. Por favor, aguarde.",
  farminfo_farm_information: "Informações sobre a exploração",
  farminfo_farmname: "Nome da exploração",

  ver_doc_title: "Documentos de verificação",
  ver_doc_your_farm: "A sua exploração",
  ver_doc_land_ownership: "Acesso à terra. Documentos comprovativos de que tem acesso à terra durante, pelo menos, os próximos 5 anos.*",

  ver_doc_land_ownership_info_box_1: "Os documentos devem refletir o número de hectares para os quais irá obter créditos.",
  ver_doc_land_ownership_info_box_2: "Se for proprietário da exploração, deve apresentar a certidão permanente do registo predial em seu nome (nome no contrato).",
  ver_doc_land_ownership_info_box_3: "Se a exploração for arrendada, o contrato não pode terminar no prazo de cinco anos a contar do ano de início do projeto. Para explorações arrendadas solicitamos:",
  ver_doc_land_ownership_info_box_4: "- A Certidão permanente do registo predial.",
  ver_doc_land_ownership_info_box_5: "- O contrato de arrendamento entre si e o proprietário do terreno.",
  ver_doc_land_ownership_info_box_6: "Formato: PDF/JPG",

  ver_doc_download: "Descarregar",
  ver_doc_delete: "Eliminar",
  ver_doc_upload: "Adicionar novo documento",

  ver_doc_kml: "Mapa digital com parcelas.",
  ver_doc_kml_info_box_1: "Certifique-se de que todas as parcelas da sua exploração estão incluídas como parcelas individuais no mapa e que os ficheiros KML das suas parcelas estão agrupados num único ficheiro KML/KMZ. ",
  ver_doc_kml_info_box_2: "Saiba como criar o seu mapa digital neste vídeo: https://www.youtube.com/watch?v=FC8t5WofL-c",
  ver_doc_kml_info_box_3: "Formato: KML/KMZ",

  ver_doc_cap: "PAC",
  ver_doc_cap_info: "Carregue os documentos do Pedido Único para cada ano do projeto e para os três anos de referência.",
  ver_doc_cap_info_box_1: "Os anos de referência são os três anteriores ao ano de inicio do seu projecto e irão utilizar-se para calcular o seu cenário 'de referência', isto é, o estado da terra antes da regeneração.",
  ver_doc_cap_info_box_2: "Formato: PDF/JPG",

  ver_doc_livestock: "Animais",
  ver_doc_livestock_info: "Se tiver animais na sua exploração, adicione os documentos oficiais de contagem de animais para cada ano.",
  ver_doc_livestock_info_box_1: "Para avaliar a população animal na sua exploração corretamente, necessitamos de documentação para cada espécie, indicando o número de animais por ano.",
  ver_doc_livestock_info_box_2: "Preferencialmente, estes documentos devem também incluir os seguintes dados:",
  ver_doc_livestock_info_box_3: "- Data de nascimento",
  ver_doc_livestock_info_box_4: "- Data de entrada na exploração",
  ver_doc_livestock_info_box_5: "- Data de saída da exploração",
  ver_doc_livestock_info_box_6: "Pode obter estas informações nos serviços agrícolas públicos onde os seus animais estão registados: SNIRA - Sistema Nacional de Informação e Registo Animal, ou nos seus documentos da PAC.",
  ver_doc_livestock_info_box_7: "Formato: PDF/JPG",

  nc_ver_doc_step_regular: "Carregar ",
  nc_ver_doc_step_bold: "documentos de verificação",

  home_ver_doc_submit: "Enviar para revisão",
  home_submit_popup_1: "Obrigado pelo seu envio",
  home_submit_popup_2: "A nossa equipa foi informada da sua apresentação e irá analisar os seus dados em breve. Não poderá alterar os seus dados. Se desejar efetuar alterações, pode escrever-nos e reabriremos a plataforma para si.",

  // farm wide info
  fwi_screen_title: "Informações gerais sobre a sua exploração",
  fwi_farmSize_tooLarge: "A soma das áreas das parcelas ({{ calculatedFarmSize }}HA) é mais de 10% maior do que a área da fazenda especificada em seu contrato ({{ contractFarmSize }}HA). Seu mapa da fazenda ou contrato precisa ser revisado.",
  fwi_farmSize_largerWithinLimit: "A soma das áreas das parcelas ({{ calculatedFarmSize }}HA) é maior do que a área da fazenda especificada em seu contrato ({{ contractFarmSize }}HA), mas dentro do limite de tolerância de 10%. Você pode ajustar manualmente as áreas das parcelas para reduzir essa diferença. Caso contrário, os tamanhos das parcelas serão ajustados automaticamente para corresponder ao tamanho da fazenda.",
  fwi_screen_subtitle_1: "Forneça informações sobre os fertilizantes sintéticos, os correctivos orgânicos importados, as forragens importadas e as sementes.",
  fwi_screen_subtitle_2: "Se não tiver uma fatura e for selecionado para uma auditoria, terá de apresentar uma declaração escrita relativa à compra dos factores de produção abaixo indicados.",

  fwi_nitrogen_fert_title: "Fertilizante sintético à base de azoto*",
  fwi_nitrogen_fert_subtitle_1: "Forneça a quantidade e a percentagem de azoto de todos os fertilizantes sintéticos à base de azoto ",
  fwi_nitrogen_fert_subtitle_2: "utilizados em ",
  fwi_nitrogen_fert_subtitle_3: "em toda a exploração. Se não tiver utilizado qualquer fertilizante sintético à base de azoto, pode introduzir zero. ",
  fwi_nitrogen_fert_subtitle_4: "Adicione as facturas como documentação de apoio para esta quantidade (PDF ou JPG)",
  fwi_nitrogen_fert: "Fertilizante sintético",
  fwi_quantity_tonnes: " toneladas",
  fwi_percent_nitro: "%",
  fwi_add_fert: "+ Adicione o fertilizante",
  fwi_upload_invoice: "Adicionar fatura",
  fwi_percent_imported: "% importada",

  fwi_org_amend_title: "Fração importada de correctivos orgânicos",
  fwi_org_amend_subtitle_1: "Para cada tipo de corretivo orgânico, indique a percentagem que ",
  fwi_org_amend_subtitle_2: "importou do exterior da sua exploração em ",
  fwi_org_amend_subtitle_3: ". Se não aplicou a alteração orgânica, pode introduzir zero. Se tiver, adicione documentos comprovativos desta quantidade (PDF ou JPG).",
  fwi_org_amend_info: "Exemplo: se comprou 5 toneladas de composto fora da sua exploração e produziu 7 toneladas na sua exploração, a percentagem será de 5/12 = 42%",
  fwi_biochar: "Biochar",
  fwi_percent: "Percentagem %",
  fwi_compost: "Composto",
  fwi_manure: "Estrume",
  fwi_mulch: "Cobertura vegetal",
  fwi_slurry: "Chorume",
  fwi_documents: "Documentos",
  fwi_documents_info: "Exemplos: facturas de compra de material orgânico, notas de campo, etc.",
  fwi_documents_upload: "Adiconar documento",

  fwi_animal_fod_title: "Forragem para animais importada*",
  fwi_animal_fod_subtitle_1: "Indique a percentagem de feno/palha utilizado para pastagem em fardos que ",
  fwi_animal_fod_subtitle_2: "importou de fora da sua exploração em ",
  fwi_animal_fod_subtitle_3: ". Se não tiver utilizado feno/palha para pastagem em fardos, pode indicar zero. Se tiver, adicione facturas como documentação de apoio para este montante (PDF ou JPG).",
  fwi_animal_fod_info: "Exemplo: se comprou 30 toneladas de palha fora da sua exploração e produziu 20 toneladas, a percentagem será 30/50 = 60%",
  fwi_hay: "Feno/palha",
  fwi_invoices: "Facturas",

  fwi_seeds_title: "Sementes",
  fwi_seeds_subtitle: "Forneça as facturas das sementes para as culturas anuais que colheu em ",


  pmo_map_title: "Mapa de parcelas",
  pmo_map_subtitle: 'O mapa que se segue apresenta todas as parcelas que adicionou. Pode mudar o nome da sua parcela clicando na mesma no mapa. Preencha a secção "Informações gerais da exploração" para a sua exploração e, para cada parcela, preencha as páginas "Informações gerais" e "Culturas".',
  pmo_dig_maps: "Mapa digital com parcelas",
  pmo_map_info_1: "Certifique-se de que todas as parcelas da sua exploração estão incluídas como parcelas individuais no mapa e que os ficheiros KML das suas parcelas estão agrupados num único ficheiro KMZ.",
  pmo_map_info_2: "Saiba como criar o seu mapa digital neste vídeo: https://www.youtube.com/watch?v=FC8t5WofL-c",

  pmo_popup_title: "Parcela",
  pmo_popup_name: "[Nome: ",
  pmo_popup_rename: "Editar parcela",
  pmo_popup_delete: "Eliminar parcela",
  pmo_popup_search: "Pesquisar",
  pmo_fwi: "Informações gerais sobre a sua exploração ",
  pmo_fwi_farm_from: "Exploração de ",
  pmo_fwi_complete: "Introduzir dados",
  pmo_list_title: "Lista de parcelas",
  pmo_list_subtitle: "Aqui encontra a lista das suas parcelas. Verifique se o número de hectares está correto e forneça informações gerais e sobre as culturas para cada parcela.",
  pmo_list_info: "Ao clicar no ícone do lápis, pode mudar o nome da parcela, indicar um tamanho de parcela diferente ou eliminá-la.",
  pmo_list_gen_info: "Informação geral",
  pmo_list_crops: "Culturas",
  pmo_edit_title: "Dados específicos da parcela",
  pmo_edit_description: "Pode mudar o nome da parcela, comunicar um tamanho de parcela diferente ou eliminar.",
  pmo_edit_ha_auto: " HA (calculada com base no mapa)",
  pmo_edit_ha_manual: "Se a dimensão da parcela calculada estiver incorrecta, indique em seguida a dimensão correcta da parcela (em hectares):",

  pmo_delete_map_heading: "Apagar o mapa",
  pmo_delete_map_message: "Tem a certeza de que pretende apagar o mapa? Isto também eliminará todas as informações introduzidas nas parcelas.",
  pmo_delete_map_no: "Não, manter o mapa e as informações",
  pmo_delete_map_yes: "Sim, apagar o mapa e as informações",
  pmo_delete_parcel_message: "Tem a certeza de que pretende apagar a parcela? Isto também eliminará todas as informações introduzidas.",
  pmo_delete_parcel_no: "Não, manter a parcela e as informações",
  pmo_delete_parcel_yes: "Sim, apagar a parcela e as informações",



  //parcels - general info
  pgi_back_button: "<Voltar",
  pgi_screen_title_1: "Parcela ",
  pgi_screen_title_2: "Informações gerais",
  pgi_crops_info_button: "Informações sobre as culturas >",
  pgi_subtitle: 'Forneça informações sobre a utilização do solo, as alterações orgânicas, as forragens, o pastoreio, a lavoura, a irrigação e as árvores abatidas para esta parcela específica. Se a informação for a mesma para um ano anterior ou parcela, pode copiar os dados clicando em "copiar dados de outro ano / parcela".',
  pgi_copy_data_button_1: "Copiar dados de outro ano / parcela",
  pgi_pre_fill_data_pop_up: "Copiar informações da parcela",
  pgi_pre_fill_data_pop_up_desc: 'Escolha os anos e o nome da parcela para copiar os dados para a parcela que está modificando atualmente. Se selecionar "todos os anos", os dados da parcela selecionada serão copiados para a parcela atual para todos os anos.',
  pgi_copy_to: "Copiar para",
  pgi_copy_data_to_parcel: "(Parcela atual)",
  pgi_copy_data_with_data: "com dados de",
  pgi_copy_data_with_years_dropdown: "Ano",
  pgi_copy_data_all_years: "Todos os anos",
  pgi_copy_data_button_2: "Copiar dados",
  pgi_warning_overwrite_1: "Aviso de sobrescrita de dados",
  pgi_warning_overwrite_2: "Já completou algumas informações sobre esta parcela. Ao copiar dados de outra parcela, estas informações serão eliminadas, exceto as informações relativas às plantas perenes, que se manterão. Tem a certeza de que pretende continuar?",
  general_continue_button: "Continuar",
  pgi_cancel_button: "Cancelar",
  pgi_landuse_title: "Utilização do solo",
  pgi_landuse_subtitle: "Seleccione a utilização principal e, se aplicável, secundária do solo para esta parcela em ",
  pgi_primary_lu: "Utilização principal do solo*",
  pgi_secondary_lu: "Utilização secundária do solo",
  pgi_lu_type_dd_name: "Tipo de uso da terra",
  pgi_lu_type_dd_value_1: "Silvopastoril",
  pgi_lu_type_dd_value_2: "Culturas",
  pgi_lu_type_dd_value_3: "Pastagem",
  pgi_lu_type_dd_value_4: "Agrofloresta / Culturas arbóreas",
  pgi_lu_type_dd_value_5: "Silvicultura",
  pgi_organic_amendments_title: "Alterações orgânicas",
  pgi_organic_amendments_desc_1: "Forneça informações sobre toda a matéria orgânica que <strong>adicionou</strong> a esta parcela como <strong>corretor do solo</strong> em {{year}}.<br/>Nota: Isto <strong>não inclui</strong> os resíduos de culturas <strong>deixados</strong> na parcela após a colheita ou a poda.",
  pgi_organic_amendments_biochar: "Biochar",
  pgi_organic_amendments_biochar_qty: "Quantidade adicionada",
  pgi_organic_amendments_biochar_tonnes: "toneladas",
  pgi_organic_amendments_compost: "Composto",
  pgi_organic_amendments_compost_info: "Se pretender eliminar um tipo de composto, seleccione o hífen (-) no tipo de composto.",
  pgi_organic_amendments_compost_type_dd: "Tipo de composto",
  pgi_organic_amendments_compost_type_dd_1: "Composto verde / à base de plantas",
  pgi_organic_amendments_compost_type_dd_2: "Composto à base de esterco",
  pgi_organic_amendments_compost_type_dd_3: "Composto de cogumelos",
  pgi_organic_amendments_compost_type_qty: "Quantidade adicionada",
  pgi_organic_amendments_compost_type_tonnes: "toneladas",
  pgi_organic_amendments_compost_type_add: "Adicione um tipo de composto",


  pgi_organic_amendments_manure: "Estrume",
  pgi_organic_amendments_manure_info: "Se pretender eliminar um tipo de estrume, seleccione o hífen (-) no tipo de composto.",
  pgi_organic_amendments_manure_type_dd: "Tipo de estrume",
  pgi_organic_amendments_manure_type_dd_1: "Estrume de gado",
  pgi_organic_amendments_manure_type_dd_2: "Estrume de galinha",
  pgi_organic_amendments_manure_type_dd_3: "Estrume de cavalo",
  pgi_organic_amendments_manure_type_dd_4: "Estrume de porco",
  pgi_organic_amendments_manure_type_dd_5: "Estrume de ovelha",
  pgi_organic_amendments_manure_type_qty: "Quantidade adicionada",
  pgi_organic_amendments_manure_type_tonnes: "toneladas",
  pgi_organic_amendments_manure_type_add: "Adicione um tipo de estrume",

  pgi_organic_amendments_mulch: "Cobertura vegetal",
  pgi_organic_amendments_mulch_info: "Se pretender eliminar um tipo de cobertura, seleccione o hífen (-) no tipo de cobertura.",
  pgi_organic_amendments_mulch_type_dd: "Tipo de cobertura",
  pgi_organic_amendments_mulch_type_dd_1: "Feno / Palha",
  pgi_organic_amendments_mulch_type_dd_2: "Folhas",
  pgi_organic_amendments_mulch_type_dd_3: "Cascas de madeira / Cavacos de madeira",
  pgi_organic_amendments_mulch_type_qty: "Quantidade adicionada",
  pgi_organic_amendments_mulch_type_tonnes: "toneladas",
  pgi_organic_amendments_mulch_type_add: "Adicione um tipo de cobertura vegetal",

  pgi_organic_amendments_slurry: "Chorume",
  pgi_organic_amendments_slurry_info: "Se pretender eliminar um tipo de chorume, seleccione o hífen (-) no tipo de composto.",
  pgi_organic_amendments_slurry_type_dd: "Tipo de chorume",
  pgi_organic_amendments_slurry_type_dd_1: "Chorume de gado",
  pgi_organic_amendments_slurry_type_dd_2: "Chorume de porco",
  pgi_organic_amendments_slurry_type_qty: "Quantidade adicionada",
  pgi_organic_amendments_slurry_type_tonnes: "litros",
  pgi_organic_amendments_slurry_type_add: "Adicione um tipo de chorume",

  pgi_organic_amendments_other: "Outro",
  pgi_organic_amendments_other_info: "Adicione todos os tipos de correctivos do solo que não estejam enumerados em cima. Especifique o tipo de matéria orgânica e a quantidade adicionada. Exemplos: subprodutos de instalações de produção como o alperujo, restos da indústria agroalimentar, etc.",
  pgi_organic_amendments_soil_text: "Especifique o tipo de corretivo do solo",
  pgi_organic_amendments_other_qty: "Quantidade adicionada",
  pgi_organic_amendments_other_qty_tonnes: "toneladas",
  pgi_organic_amendments_other_add: "Adicione outros",

  pgi_organic_amendments_animal_fodder: "Forragem para animais",
  pgi_organic_amendments_af_desc: "Indique a quantidade de feno e palha colocados nesta parcela para pastoreio em fardos em ",
  pgi_organic_amendments_af_info: 'Note que isto se refere à quantidade de feno e palha colocada na parcela para <strong>pastagem com fardos</strong>. Se adicionou feno ou palha como corretivo do solo, pode adicioná-lo na secção anterior, em "cobertura vegetal".',
  pgi_organic_amendments_af_2: "Forragem para animais",
  pgi_organic_amendments_af_qty: "Quantidade de feno / palha colocada no campo",
  pgi_organic_amendments_af_tonnes: "toneladas",


  pgi_organic_amendments_grazing: "Pastoreio",
  pgi_organic_amendments_grazing_desc_1: "Seleccione os meses em que esta parcela foi pastoreada durante o ",
  pgi_organic_amendments_grazing_desc_2: ", se for caso disso.",
  pgi_organic_amendments_grazing_months: "Meses de pastoreio",
  pgi_organic_amendments_grazing_choose: "Seleccione",
  pgi_organic_amendments_grazing_months_dd: "Meses (é permitida a seleção múltipla)",
  pgi_organic_amendments_grazing_months_1: "janeiro",
  pgi_organic_amendments_grazing_months_2: "fevereiro",
  pgi_organic_amendments_grazing_months_3: "março",
  pgi_organic_amendments_grazing_months_4: "abril",
  pgi_organic_amendments_grazing_months_5: "maio",
  pgi_organic_amendments_grazing_months_6: "junho",
  pgi_organic_amendments_grazing_months_7: "julho",
  pgi_organic_amendments_grazing_months_8: "agosto",
  pgi_organic_amendments_grazing_months_9: "setembro",
  pgi_organic_amendments_grazing_months_10: "outubro",
  pgi_organic_amendments_grazing_months_11: "novembro",
  pgi_organic_amendments_grazing_months_12: "dezembro",

  pgi_organic_amendments_tillage: "Lavoura",
  pgi_organic_amendments_tillage_desc_1: "Adicione todos os eventos de lavoura nesta parcela durante ",
  pgi_organic_amendments_tillage_desc_2: " caso existam.",
  pgi_organic_amendments_tillage_events: "Eventos de lavoura",
  pgi_organic_amendments_tillage_info: "Para cada evento de lavoura, indique:<br/><br/>Mês - O mês em que o solo foi lavrado.<br/><br/>Tipo - Lavoura profunda se a perturbação do solo foi mais profunda do que 10 cm abaixo da superfície e lavoura mínima se foi menos profunda do que 10 cm.<br/><br/>Percentagem - A percentagem da área da parcela que foi lavrada. Se toda a parcela foi lavrada, preencha com 100%. Se apenas uma parte da parcela foi lavrada, por exemplo, por risco de incêndio ou por lavoura em faixas, calcule a percentagem que foi lavrada.<br/><br/>Se quiser eliminar um evento de lavoura, seleccione o hífen (-) no mês.",
  pgi_organic_amendments_tillage_months: "Mês",
  pgi_organic_amendments_tillage_months_1: "Janeiro",
  pgi_organic_amendments_tillage_months_2: "Fevereiro",
  pgi_organic_amendments_tillage_months_3: "Março",
  pgi_organic_amendments_tillage_months_4: "Abril",
  pgi_organic_amendments_tillage_months_5: "Maio",
  pgi_organic_amendments_tillage_months_6: "Junho",
  pgi_organic_amendments_tillage_months_7: "Julho",
  pgi_organic_amendments_tillage_months_8: "Agosto",
  pgi_organic_amendments_tillage_months_9: "Setembro",
  pgi_organic_amendments_tillage_months_10: "Outubro",
  pgi_organic_amendments_tillage_months_11: "Novembro",
  pgi_organic_amendments_tillage_months_12: "Dezembro",
  pgi_organic_amendments_tillage_type_dd: "Tipo de lavoura",
  pgi_organic_amendments_tillage_type_dd_1: "Lavoura mínima (<10 cm)",
  pgi_organic_amendments_tillage_type_dd_2: "Lavoura profunda (>10 cm)",
  pgi_organic_amendments_tillage_percentage: "Percentagem %",
  pgi_organic_amendments_tillage_add: "Adicione um evento de lavoura",

  pgi_organic_amendments_irrigation: "Irrigação",
  pgi_organic_amendments_irrigation_desc: "Seleccione se esta parcela foi ou não irrigada em ",
  pgi_organic_amendments_irrigation_label: "Irrigação",
  pgi_organic_amendments_irrigation_dd_1: "Sim",
  pgi_organic_amendments_irrigation_dd_2: "Não",

  pgi_organic_amendments_felled_trees: "Árvores abatidas",
  pgi_organic_amendments_felled_trees_desc_1: "Se foram abatidas árvores nesta parcela em ",
  pgi_organic_amendments_felled_trees_desc_2: ", indique, por espécie de árvore, o número de árvores abatidas e o diâmetro médio à altura do peito (DAP) das árvores em cm.",
  pgi_organic_amendments_felled_trees_label: "Árvores abatidas",
  pgi_organic_amendments_felled_trees_info: "O diâmetro à altura do peito (DAP) é o diâmetro do tronco de uma árvore a 1,3 metros do solo. Indique o DAP médio das árvores abatidas, em cm.<br/><br/>Se pretender eliminar uma espécie de árvore, seleccione o hífen (-) na lista de espécies de árvores.",

  pgi_organic_amendments_felled_trees_dd: "Espécies de árvores",
  pgi_organic_amendments_felled_trees_dd_1: "Freixo",
  pgi_organic_amendments_felled_trees_dd_2: "Eucalipto",
  pgi_organic_amendments_felled_trees_dd_3: "Alfarroba",
  pgi_organic_amendments_felled_trees_dd_4: "Cortiça Carvalho",
  pgi_organic_amendments_felled_trees_dd_5: "Cipreste",
  pgi_organic_amendments_felled_trees_dd_6: "Azinheira",
  pgi_organic_amendments_felled_trees_dd_7: "Ácer/Sicómoro",
  pgi_organic_amendments_felled_trees_dd_8: "Pinheiro",
  pgi_organic_amendments_felled_trees_dd_9: "Macieira",
  pgi_organic_amendments_felled_trees_dd_10: "Damasqueiro",
  pgi_organic_amendments_felled_trees_dd_11: "Abacateiro",
  pgi_organic_amendments_felled_trees_dd_12: "Clementina",
  pgi_organic_amendments_felled_trees_dd_13: "Tamareira",
  pgi_organic_amendments_felled_trees_dd_14: "Figueira",
  pgi_organic_amendments_felled_trees_dd_15: "Limoeiro",
  pgi_organic_amendments_felled_trees_dd_16: "Tangerineira",
  pgi_organic_amendments_felled_trees_dd_17: "Mangueira",
  pgi_organic_amendments_felled_trees_dd_18: "Oliveira",
  pgi_organic_amendments_felled_trees_dd_19: "Laranjeira",
  pgi_organic_amendments_felled_trees_dd_20: "Pessegueiro",
  pgi_organic_amendments_felled_trees_dd_21: "Pera",
  pgi_organic_amendments_felled_trees_dd_22: "Romãzeira",
  pgi_organic_amendments_felled_trees_dd_23: "Pomeleiro",
  pgi_organic_amendments_felled_trees_dd_24: "Árvore de fruta - Outra",
  pgi_organic_amendments_felled_trees_dd_25: "Amendoeira",
  pgi_organic_amendments_felled_trees_dd_26: "Castanheiro",
  pgi_organic_amendments_felled_trees_dd_27: "Avelã",
  pgi_organic_amendments_felled_trees_dd_28: "Pistácio",
  pgi_organic_amendments_felled_trees_dd_29: "Nogueira",
  pgi_organic_amendments_felled_trees_dd_30: "Frutos secos - Outros",
  pgi_organic_amendments_felled_trees_dd_31: "Outra árvore",
  pgi_organic_amendments_felled_trees_specific_name: "Nome específico",
  pgi_organic_amendments_felled_trees_number: "Número de árvores",
  pgi_organic_amendments_felled_trees_add_another: "Adicione outra espécie de árvore",
  pgi_organic_amendments_save: "Guardar",

  //parcels - crop info
  pci_back: "<Voltar",
  pci_screen_title_1: "Parcela ",
  pci_screen_title_2: "Informações sobre as culturas",
  pci_crops_info_btn: "Informações gerais >",
  pci_subtitle: 'Forneça informações sobre culturas perenes e anuais, pastagens e gramíneas permanentes e períodos de repouso para esta parcela específica. Se as informações forem iguais às anteriormente fornecidas para um outro ano ou parcela, pode copiar os dados clicando em "copiar dados de outro ano / parcela".',
  pci_copy_data: "Copiar dados de outro ano / parcela",
  pci_pre_fill: "Copiar informações da parcela",
  pci_pre_fill_1: "Escolha os anos e o nome da parcela para copiar os dados para a parcela que está modificando atualmente.",
  pci_pre_fill_2: "Se selecionar 'todos os anos', os dados da parcela selecionada serão copiados para a parcela atual para todos os anos.",
  pci_year: "Ano",
  pci_all_years: "Todos os anos",
  pci_copy_data_btn: "Copiar dados",
  pci_copy_data_overwrite_heading: "Aviso de sobrescrita de dados",
  pci_copy_data_overwrite_msg: "Já completou algumas informações sobre esta parcela. Ao copiar dados de outra parcela, estas informações serão eliminadas, exceto as informações relativas às plantas perenes, que se manterão. Tem a certeza de que pretende continuar?",
  pci_copy_data_overwrite_continue: "Continuar",
  pci_copy_data_overwrite_cancel: "Cancelar",
  pci_logic: 'Árvore',
  pci_perennial_title_1: "Árvores e outras culturas perenes | Total ",
  pci_perennial_title_2: " árvores",
  pci_perennial_tree_label: "Nova árvore ou cultura perene",
  pci_perennial_subtitle: "Adicione todas as árvores e culturas perenes desta parcela. Pode excluir árvores de sistemas diversos em que a idade ou as espécies das árvores são variáveis (por exemplo, sistemas de montado ou florestais). As culturas de árvores são agrupadas em grupos por espécie e ano de plantação (por exemplo, Árvore de fruta - Oliveira, 2019).",
  pci_perennial_info: "Estas aparecerão automaticamente nos anos seguintes. Para os anos seguintes, pode ajustar: <br/>- o número de árvores se as árvores deste grupo tiverem sido removidas <br/>- o diâmetro à altura do peito (DAP), dependendo do crescimento das árvores.<br/>Também pode eliminar o grupo se tiver removido todas as árvores. <br/>As culturas perenes adicionadas aos anos anteriores aparecerão automaticamente nos anos seguintes. Pode eliminá-las se as tiver retirado da parcela",
  pci_perennial_subsection_heading: "Cultura perene / Tipo de árvore / Ano de plantação",
  pci_perennial_subsection_delete: "Eliminar",
  pci_perennial_subsection_desc: "Descrição*",
  pci_perennial_subsection_info: "Período de vida das árvores - Se a plantação for replantada periodicamente, indique a idade típica em que se prevê a renovação das árvores (por exemplo, período de vida das macieiras - 13 anos).<br/><br/>Se as árvores forem permanentes e não forem removidas periodicamente, pode deixar este campo em branco.",
  pci_perennial_tree_list_dd: "Plantas e árvores perenes*",
  "herbaceous perennials - strawberry": "Herbáceas perenes - Morango",
  "herbaceous perennials - other": "Herbáceas perenes - Outras",
  "bushes/shrubs - blackberry": "Arbustos - Amora",
  "bushes/shrubs - blueberry": "Arbustos - Mirtilo",
  "bushes/shrubs - broom": "Arbustos - Tojo",
  "bushes/shrubs - chamise/greasewood": "Arbustos - Chamise / Vedal",
  "bushes/shrubs - heather/erica": "Arbustos - Urze / Erica",
  "bushes/shrubs - laustrine": "Arbustos - Laustrine",
  "bushes/shrubs - lavender": "Arbustos - Lavanda",
  "bushes/shrubs - lentisk/mastic": "Arbustos - Lentisco",
  "bushes/shrubs - rockrose": "Arbustos - Esteva",
  "bushes/shrubs - rosemary": "Arbustos - Alecrim",
  "bushes/shrubs - sagebrush": "Arbustos - Sálvia",
  "bushes/shrubs - other": "Arbustos - Outros",
  "fruit tree - apple": "Macieira",
  "fruit tree - apricot": "Damasqueiro",
  "fruit tree - avocado": "Abacateiro",
  "fruit tree - clementine": "Clementina",
  "fruit tree - date palm": "Tamareira",
  "fruit tree - fig": "Figueira",
  "fruit tree - lemon": "Limoeiro",
  "fruit tree - mandarin": "Tangerineira",
  "fruit tree - mango": "Mangueira",
  "fruit tree - olive": "Oliveira",
  "fruit tree - orange": "Laranjeira",
  "fruit tree - peach": "Pessegueiro",
  "fruit tree - pear": "Pera",
  "fruit tree - pomegranate": "Romãzeira",
  "fruit tree - pomelo": "Pomeleiro",
  "fruit tree - other": "Árvore de fruta - Outra",
  "nut tree - almond": "Amendoeira",
  "nut tree - chestnut": "Castanheiro",
  "nut tree - hazelnut": "Avelã",
  "nut tree - pistachio": "Pistácio",
  "nut tree - walnut": "Nogueira",
  "nut tree - other": "Frutos secos - Outros",
  "tree - ash": "Árvore - Freixo",
  "tree - eucalyptus": "Árvore - Eucalipto",
  "tree - carob": "Árvore - Alfarrobeira",
  "tree - cork oak": "Árvore - Sobreiro",
  "tree - cypress": "Árvore - Cipreste",
  "tree - holm oak": "Árvore - Azinheira",
  "tree - maple/sycamore": "Árvore - Bordo / Plátano",
  "tree - pine": "Árvore - Pinheiro",
  "tree - poplar": "Árvore - Choupo",
  "tree - other": "Árvore - Outras",
  "vine - grapevine": "Videira - Videiras",
  "vine - other": "Videira - Outras",
  "other perennial crop": "Outra cultura perene",
  pci_perennial_tree_list_specific: "Nome específico",
  pci_perennial_tree_list_year_planting: "Ano de plantação*",
  pci_perennial_tree_list_year_before: "Antes de 2018",
  pci_perennial_tree_list_tree_span: "Vida útil das árvores",
  pci_perennial_tree_list_tree_span_yrs: "anos",
  pci_perennial_tree_list_number_growth: "Número e crescimento*",
  pci_perennial_tree_list_number_growth_info: "O diâmetro à altura do peito (DAP) é o diâmetro do tronco de uma árvore a 1,3 metros do solo. Indique o DAP médio do grupo de árvores em cm. Para as culturas de oliveiras, indique o diâmetro médio do tronco da árvore na sua base, em cm.",
  pci_perennial_tree_list_number_trees: "Número de árvores",
  pci_perennial_tree_list_dbh_field: "DAP médio",
  pci_perennial_tree_list_dbh_field_cm: "cm",
  pci_perennial_tree_list_harvest: "Colheita*",
  pci_perennial_tree_list_harvest_desc: "Material orgânico que foi retirado da parcela",
  pci_perennial_tree_list_harvest_info: "A colheita é definida como a matéria orgânica retirada da parcela. Adicione todas as colheitas desta planta perene para o ano que está a editar e indique:<br/><br/>Mês - O mês em que a colheita foi efectuada.<br/><br/>Tipo de colheita - Tipo de matéria que foi colhida.<br/><br/>Quantidade - A quantidade colhida em toneladas de peso fresco ou seco. Note que seco aqui significa completamente desidratado, de modo que não reste nenhuma humidade.<br/><br/>Se não houve colheita, indique zero como quantidade.<br/><br/>Se pretender eliminar uma colheita, seleccione o hífen (-) no mês.",
  pci_perennial_tree_list_harvest_month: "Mês",
  pci_perennial_tree_list_harvest_month_jan: "Janeiro",
  pci_perennial_tree_list_harvest_month_feb: "Fevereiro",
  pci_perennial_tree_list_harvest_month_mar: "Março",
  pci_perennial_tree_list_harvest_month_apr: "Abril",
  pci_perennial_tree_list_harvest_month_may: "Maio",
  pci_perennial_tree_list_harvest_month_jun: "Junho",
  pci_perennial_tree_list_harvest_month_jul: "Julho",
  pci_perennial_tree_list_harvest_month_aug: "Agosto",
  pci_perennial_tree_list_harvest_month_sep: "Setembro",
  pci_perennial_tree_list_harvest_month_oct: "Outubro",
  pci_perennial_tree_list_harvest_month_nov: "Novembro",
  pci_perennial_tree_list_harvest_month_dec: "Dezembro",
  pci_perennial_tree_list_harvest_type: "Tipo de colheita",
  pci_perennial_tree_list_harvest_type_dd_1: "Fruta",
  pci_perennial_tree_list_harvest_type_dd_2: "Noz",
  pci_perennial_tree_list_harvest_type_dd_3: "Casca / Cortiça",
  pci_perennial_tree_list_harvest_type_dd_4: "Folhas, caules, ervas (não lenhosas)",
  pci_perennial_tree_list_harvest_type_dd_5: "Podas ou materiais lenhosos",
  pci_perennial_tree_list_harvest_type_dd_6: "Erva",
  pci_perennial_tree_list_harvest_type_qty: "Quantidade",
  pci_perennial_tree_list_harvest_fresh_dry_1: "Toneladas - Fresco",
  pci_perennial_tree_list_harvest_fresh_dry_2: "Toneladas - Seco",
  pci_perennial_tree_list_harvest_fresh_add: "Adicione a colheita",
  pci_perennial_tree_list_residue: "Resíduos",
  pci_perennial_tree_list_residue_desc: "Material orgânico deixado no campo",
  pci_perennial_tree_list_residue_info: "O resíduo é definido como o material vegetal/orgânico que foi deixado no campo. Adicione todos os resíduos desta planta perene para o ano que está a editar e indique:<br/><br/>Mês - O mês em que o resíduo foi deixado no campo.<br/><br/>Tipo de colheita - Tipo de material do resíduo.<br/><br/>Quantidade - A quantidade de resíduos que foi deixada no campo em toneladas de peso fresco ou seco. Note que seco significa completamente desidratado, de modo a que não resta qualquer humidade.<br/><br/>Se não deixou resíduos, indique zero na quantidade ou deixe esta linha em branco.<br/><br/>Se pretender eliminar um resíduo, seleccione o hífen (-) no mês.",
  pci_perennial_tree_list_residue_month: "Mês",
  pci_perennial_tree_list_residue_month_jan: "Janeiro",
  pci_perennial_tree_list_residue_month_feb: "Fevereiro",
  pci_perennial_tree_list_residue_month_mar: "Março",
  pci_perennial_tree_list_residue_month_apr: "Abril",
  pci_perennial_tree_list_residue_month_may: "Maio",
  pci_perennial_tree_list_residue_month_jun: "Junho",
  pci_perennial_tree_list_residue_month_jul: "Julho",
  pci_perennial_tree_list_residue_month_aug: "Agosto",
  pci_perennial_tree_list_residue_month_sep: "Setembro",
  pci_perennial_tree_list_residue_month_oct: "Outubro",
  pci_perennial_tree_list_residue_month_nov: "Novembro",
  pci_perennial_tree_list_residue_month_dec: "Dezembro",
  pci_perennial_tree_list_residue_dd_type: "Tipo de resíduo",
  pci_perennial_tree_list_residue_type_dd_1: "Fruta",
  pci_perennial_tree_list_residue_type_dd_2: "Noz",
  pci_perennial_tree_list_residue_type_dd_3: "Casca / Cortiça",
  pci_perennial_tree_list_residue_type_dd_4: "Folhas, caules, ervas (não lenhosas)",
  pci_perennial_tree_list_residue_type_dd_5: "Podas ou materiais lenhosos",
  pci_perennial_tree_list_residue_type_dd_6: "Erva",
  pci_perennial_tree_list_residue_type_qty: "Quantidade",
  pci_perennial_tree_list_residue_fresh_dry_1: "Toneladas - Fresco",
  pci_perennial_tree_list_residue_fresh_dry_2: "Toneladas - Seco",
  pci_perennial_tree_list_residue_fresh_add: "Adicione resíduos",
  pci_perennial_tree_list_add_new: "Adicione uma árvore ou outra cultura perene",

  pci_annual_crops_title_1: "Culturas anuais (",
  pci_annual_crops_title_2: ")",
  pci_annual_crops_subtitle: "Adicione todas as culturas anuais que <strong>foram colhidas</strong> em {{year}} nesta parcela. Se semeou a cultura em {{year}} e colheu em {{yearAfter}}, pode adicioná-la em {{yearAfter}}.",

  pci_annual_crops_label: "Cultura anual",
  pci_annual_crops_delete: "Eliminar",
  pci_annual_crops_desc: "Descrição*",
  pci_annual_crops_info: 'Seleccione o tipo de cultura anual da lista. Se a sua cultura não se encontrar na lista, seleccione o tipo seguido de "outro" (por exemplo, Leguminosas - outro) e indique o nome específico.<br/><br/>Uma cultura de cobertura pode ser:<br/>- Uma ou várias gramíneas (Poaceae).<br/>- Uma ou várias leguminosas (Fabaceae)<br/>- Uma mistura de gramíneas e leguminosas (Poaceae e Fabaceae).<br/>- A cultura de cobertura multi-espécies deve ser uma mistura de, pelo menos, 3 espécies de plantas, sendo as duas primeiras uma gramínea (Poaceae) e uma leguminosa (Fabaceae) e a terceira de uma das seguintes famílias: Brassicaceae, Amaranthaceae, Linaceae, Plantaginaceae, Asteraceae, Polygonaceae, Boraginaceae, Malvaceae, Rosaceae, Cucurbitaceae - também designadas por gramíneas não leguminosas.',

  pci_annual_crops_dd_list: "Lista das culturas anuais",
  "aromatics": "Aromáticas",
  "cover crop - single or multiple grasses": "Culturas de cobertura - gramíneas simples ou múltiplas",
  "cover crop - single or multiple legumes": "Cultura de cobertura - leguminosas simples ou múltiplas",
  "cover crop - mix of grass and legumes": "Cultura de cobertura - Mistura de gramíneas e leguminosas",
  "cover crop - multi-species cover crop": "Cultura de cobertura - Cultura de cobertura multi-espécies",
  "cover crop - other": "Cultura de cobertura - Outra",
  "forage - grass-clover mixtures": "Forragem - Misturas de gramíneas e trevos",
  "forage - italian ryegrass": "Forragem - Azevém italiano",
  "forage - n-fixing forages": "Forragem - Forragens fixadoras de N",
  "forage - non-n-fixing forages": "Forragem - Forragens não fixadoras de N",
  "forage - non-legume hay": "Forragem - Feno não leguminoso",
  "forage - sudangrass": "Forragem - Erva-do-sudão",
  "forage - other": "Forragem - Outras",
  "grains/cereals - barley": "Grãos/Cereais - Cevada",
  "grains/cereals - maize": "Grãos/cereais - Milho",
  "grains/cereals - millet": "Grãos/cereais - Milho painço",
  "grains/cereals - oats": "Grãos/Cereais - Aveia",
  "grains/cereals - rice": "Grãos/Cereais - Arroz",
  "grains/cereals - rye": "Grãos/Cereais - Centeio",
  "grains/cereals - sorghum": "Grãos/Cereais - Sorgo",
  "grains/cereals - spring wheat": "Grãos/Cereais - Trigo de primavera",
  "grains/cereals - triticale": "Grãos/Cereais - Triticale",
  "grains/cereals - wheat": "Grãos/Cereais - Trigo",
  "grains/cereals - winter wheat": "Grãos/Cereais - Trigo de inverno",
  "grains/cereals - other": "Grãos/cereais - Outros",
  "legumes - alfalfa": "Leguminosas - Alfafa",
  "legumes - beans": "Leguminosas - Feijão",
  "legumes - cowpea": "Leguminosas - Feijão-frade",
  "legumes - lupins": "Leguminosas - Tremoços",
  "legumes - peas": "Leguminosas - Ervilhas",
  "legumes - soybeans": "Leguminosas - Soja",
  "legumes - vetch": "Leguminosas - Ervilhaca",
  "legumes - other": "Leguminosas - Outras",
  "roots/tubers - potatoes": "Raízes/Tubérculos - Batatas",
  "roots/tubers - beets": "Raízes/Tubérculos - Beterrabas",
  "roots/tubers - other": "Raízes/Tubérculos - Outros",
  "fruits and vegetables - squash": "Frutas e produtos hortícolas - Abóbora",
  "fruits and vegetables - other fruit": "Frutas e produtos hortícolas - Outros frutos",
  "fruits and vegetables - other vegetable": "Frutas e produtos hortícolas - Outros produtos hortícolas",
  "oilseeds - peanuts": "Sementes oleaginosas - Amendoim",
  "oilseeds - rapeseed": "Sementes oleaginosas - Colza",
  "oilseeds - sunflower": "Sementes oleaginosas - Girassol",
  "oilseeds - other": "Sementes oleaginosas - Outras",
  "other annual crop": "Outras culturas anuais",
  pci_annual_crops_specific_name: "Nome específico",
  pci_annual_crops_crops_cycle: "Ciclo da cultura*",
  pci_annual_crops_crops_cycle_info: "Note que só pode selecionar os meses de colheita no ano que está a editar atualmente. Se semeou uma cultura este ano mas a colheu no ano seguinte, adicione-a ao editar o ano seguinte.",
  pci_annual_crops_harvest_month_dd: "Mês da colheita",
  pci_annual_crops_harvest_month_jan: "Janeiro",
  pci_annual_crops_harvest_month_feb: "Fevereiro",
  pci_annual_crops_harvest_month_mar: "Março",
  pci_annual_crops_harvest_month_apr: "Abril",
  pci_annual_crops_harvest_month_may: "Maio",
  pci_annual_crops_harvest_month_jun: "Junho",
  pci_annual_crops_harvest_month_jul: "Julho",
  pci_annual_crops_harvest_month_aug: "Agosto",
  pci_annual_crops_harvest_month_sep: "Setembro",
  pci_annual_crops_harvest_month_oct: "Outubro",
  pci_annual_crops_harvest_month_nov: "Novembro",
  pci_annual_crops_harvest_month_dec: "Dezembro",

  pci_annual_crops_sowing_month_dd: "Mês da sementeira",
  pci_annual_crops_sowing_month_jan: "Janeiro",
  pci_annual_crops_sowing_month_feb: "Fevereiro",
  pci_annual_crops_sowing_month_mar: "Março",
  pci_annual_crops_sowing_month_apr: "Abril",
  pci_annual_crops_sowing_month_may: "Maio",
  pci_annual_crops_sowing_month_jun: "Junho",
  pci_annual_crops_sowing_month_jul: "Julho",
  pci_annual_crops_sowing_month_aug: "Agosto",
  pci_annual_crops_sowing_month_sep: "Setembro",
  pci_annual_crops_sowing_month_oct: "Outubro",
  pci_annual_crops_sowing_month_nov: "Novembro",
  pci_annual_crops_sowing_month_dec: "Dezembro",

  pci_annual_crops_harvest_label: "Colheita*",
  pci_annual_crops_harvest_label_desc: "Material orgânico que foi retirado da parcela",
  pci_annual_crops_harvest_label_info: "A colheita é definida como a matéria orgânica que foi retirada da parcela. Adicione todos os tipos de colheita desta cultura e indique a quantidade que foi colhida em toneladas de peso fresco ou seco. Note que seco significa completamente desidratado, de modo a que não reste qualquer humidade.<br/><br/><strong>Se não houve colheita, indique zero como quantidade.</strong><br/><br/>Se quiser apagar uma colheita, seleccione o hífen (-) na lista de tipos de colheita.",

  pci_annual_crops_harvest_type_dd: "Tipo de colheita",
  pci_annual_crops_harvest_type_dd_1: "Grãos/Cereais",
  pci_annual_crops_harvest_type_dd_2: "Sementes",
  pci_annual_crops_harvest_type_dd_3: "Frutas/Vegetais",
  pci_annual_crops_harvest_type_dd_4: "Raízes/Tubérculos",
  pci_annual_crops_harvest_type_dd_5: "Erva",
  pci_annual_crops_harvest_type_dd_6: "Folhas, caules, ervas (não lenhosas)",
  pci_annual_crops_harvest_type_qty: "Quantidade",
  pci_annual_crops_harvest_qty_fresh: "Toneladas - Fresco",
  pci_annual_crops_harvest_qty_dry: "Toneladas - Seco",
  pci_annual_crops_harvest_add_new: "Adicione a colheita",
  pci_annual_crops_residue_label: "Resíduos",
  pci_annual_crops_residue_label_desc: "Material orgânico deixado no campo",
  pci_annual_crops_residue_info_1: "O resíduo é definido como material vegetal/orgânico deixado no campo. Adicione todos os tipos de resíduos desta cultura e indique a quantidade deixada no campo em toneladas de peso fresco ou seco. Note que seco significa completamente desidratado, de modo que não reste nenhuma humidade.",
  pci_annual_crops_residue_info_2: "Se não deixou resíduos, indique zero na quantidade.",
  pci_annual_crops_residue_info_3: "Se pretender eliminar um resíduo, seleccione o traço (-) na lista Tipo de resíduo.",
  pci_annual_crops_residue_type_dd: "Tipo de resíduo",
  pci_annual_crops_residue_type_dd_1: "Grãos/Cereais",
  pci_annual_crops_residue_type_dd_2: "Sementes",
  pci_annual_crops_residue_type_dd_3: "Frutas/Vegetais",
  pci_annual_crops_residue_type_dd_4: "Raízes/Tubérculos",
  pci_annual_crops_residue_type_dd_5: "Erva",
  pci_annual_crops_residue_type_dd_6: "Folhas, caules, ervas (não lenhosas)",
  pci_annual_crops_residue_type_qty: "Quantidade",
  pci_annual_crops_residue_qty_fresh: "Toneladas - Fresco",
  pci_annual_crops_residue_qty_dry: "Toneladas - Seco",
  pci_annual_crops_residue_add_new: "Adicione resíduos",
  pci_annual_crops_add_new_row: "Adicione uma cultura anual",


  pci_pastures_title_1: "Pastagens e gramíneas",
  pci_pastures_subtitle_1: "Se (parte de) esta parcela tinha pastagens ou ervas permanentes durante ",
  pci_pastures_subtitle_2: ", indique a percentagem da área da parcela, a produtividade e a erva colhida.",
  pci_pastures_permanent_pastures: "Percentagem da parcela que tinha pastagens ou ervas permanentes",
  pci_pastures_permanent_pastures_info: "Calcule a área da parcela que constituía pasto ou pastagem. Se toda a parcela for pastagem, é 100%. Caso contrário, é o valor mais próximo da área coberta por pastagens.",
  pci_pastures_permanent_pastures_percent: "Percentagem",
  pci_pastures_permanent_pastures_peak: "Produtividade TOTAL estimada*",
  pci_pastures_permanent_pastures_peak_info_1: `Por favor, estime aqui a quantidade TOTAL de biomassa que a sua pastagem produziu neste ano. Se colher ou pastorear esta parcela várias vezes, adicione vários valores de produtividade OU adicione-os todos num valor TOTAL.<br/>
As quantidades de colheita (biomassa removida da parcela) devem ser indicadas abaixo. As quantidades de pastagem não são pedidas aqui, uma vez que são automaticamente calculadas pelo modelo.`,
  pci_pastures_permanent_pastures_peak_month: "Mês",
  pci_pastures_permanent_pastures_peak_month_jan: "Janeiro",
  pci_pastures_permanent_pastures_peak_month_feb: "Fevereiro",
  pci_pastures_permanent_pastures_peak_month_mar: "Março",
  pci_pastures_permanent_pastures_peak_month_apr: "Abril",
  pci_pastures_permanent_pastures_peak_month_may: "Maio",
  pci_pastures_permanent_pastures_peak_month_jun: "Junho",
  pci_pastures_permanent_pastures_peak_month_jul: "Julho",
  pci_pastures_permanent_pastures_peak_month_aug: "Agosto",
  pci_pastures_permanent_pastures_peak_month_sep: "Setembro",
  pci_pastures_permanent_pastures_peak_month_oct: "Outubro",
  pci_pastures_permanent_pastures_peak_month_nov: "Novembro",
  pci_pastures_permanent_pastures_peak_month_dec: "Dezembro",
  pci_pastures_permanent_pastures_peak_qty: "Quantidade",
  pci_pastures_permanent_pastures_peak_fresh: "kg/m² - Fresco",
  pci_pastures_permanent_pastures_peak_dry: "kg/m² - Seco",
  pci_pastures_permanent_pastures_add_estimate: "Acrescente outra estimativa (máx. 5)",
  pci_pastures_grass_harvested: "Pastagem colhida",
  pci_pastures_grass_harvested_desc: "A quantidade de pastagem colhida nesta parcela. Não inclui o pastoreio.",
  pci_pastures_grass_harvested_info_1: "Adicione todas as pastagens colhidas nesta parcela e indique a quantidade colhida em toneladas de peso fresco ou seco. Note que seco significa completamente desidratado, de modo que não reste nenhuma humidade.",
  pci_pastures_grass_harvested_info_2: "Se não houve colheita, indique zero na quantidade ou deixe esta linha em branco.",
  pci_pastures_grass_harvested_info_3: "Se pretender apagar uma colheita, seleccione o traço (-) no mês.",
  pci_pastures_grass_harvested_month: "Mês",
  pci_pastures_grass_harvested_month_jan: "Janeiro",
  pci_pastures_grass_harvested_month_feb: "Fevereiro",
  pci_pastures_grass_harvested_month_mar: "Março",
  pci_pastures_grass_harvested_month_apr: "Abril",
  pci_pastures_grass_harvested_month_may: "Maio",
  pci_pastures_grass_harvested_month_jun: "Junho",
  pci_pastures_grass_harvested_month_jul: "Julho",
  pci_pastures_grass_harvested_month_aug: "Agosto",
  pci_pastures_grass_harvested_month_sep: "Setembro",
  pci_pastures_grass_harvested_month_oct: "Outubro",
  pci_pastures_grass_harvested_month_nov: "Novembro",
  pci_pastures_grass_harvested_month_dec: "Dezembro",
  pci_pastures_grass_harvested_qty: "Quantidade",
  pci_pastures_grass_harvested_qty_fresh: "Toneladas - Fresco",
  pci_pastures_grass_harvested_qty_dry: "Toneladas - Seco",
  pci_pastures_grass_harvested_add_new: "Acrescente outra colheita",

  pci_pastures_fallow_title: "Períodos de repouso",
  pci_pastures_fallow_subtitle: "Adicione todos os períodos em que esta parcela foi deixada em repouso em ",
  pci_pastures_fallow_info: "Num período de repouso, o solo é deixado sem ser semeado ou cultivado durante um determinado período de tempo.",
  pci_pastures_fallow_period_label: "Período de repouso",
  pci_pastures_fallow_period_delete: "Eliminar",
  pci_pastures_fallow_period_desc: "Descrição*",
  pci_pastures_fallow_period_info: "Indique o estado do solo durante o período de repouso, seleccionando o tipo de repouso:<br/><br/>Repouso produtivo - O solo está parcialmente ou totalmente coberto por vegetação espontânea ou resíduos.<br/><br/>Solo descoberto - O solo está completamente descoberto<br/>Se selecionar solo descoberto, indique a percentagem da área que estava descoberta nesta parcela.",
  pci_pastures_fallow_period_type_dd: "Tipo de repouso",
  pci_pastures_fallow_period_type_dd_1: "Repouso produtivo",
  pci_pastures_fallow_period_type_dd_2: "Solo descoberto",
  pci_pastures_fallow_period_percent: "Percentagem %",
  pci_pastures_fallow_period_cycle: "Ciclo*",
  pci_pastures_fallow_period_cycle_info_1: "Indique o mês final e o mês inicial do período de repouso.",
  pci_pastures_fallow_period_cycle_info_2: "Se a parcela esteve em repouso durante menos de 1 mês, escolha esse mês como mês de fim e de início.",
  pci_pastures_fallow_period_cycle_info_3: "Se a parcela esteve em repouso durante todo o ano ou mais, escolha dezembro como o mês final e janeiro como o mês inicial. Adicione o período de repouso restante no ano seguinte enquanto edita o registo do ano seguinte.",
  pci_pastures_fallow_period_end_month: "Mês de fim",
  pci_pastures_fallow_period_end_month_jan: "Janeiro",
  pci_pastures_fallow_period_end_month_feb: "Fevereiro",
  pci_pastures_fallow_period_end_month_mar: "Março",
  pci_pastures_fallow_period_end_month_apr: "Abril",
  pci_pastures_fallow_period_end_month_may: "Maio",
  pci_pastures_fallow_period_end_month_jun: "Junho",
  pci_pastures_fallow_period_end_month_jul: "Julho",
  pci_pastures_fallow_period_end_month_aug: "Agosto",
  pci_pastures_fallow_period_end_month_sep: "Setembro",
  pci_pastures_fallow_period_end_month_oct: "Outubro",
  pci_pastures_fallow_period_end_month_nov: "Novembro",
  pci_pastures_fallow_period_end_month_dec: "Dezembro",
  pci_pastures_fallow_period_start_month: "Mês de início",
  pci_pastures_fallow_period_start_month_jan: "Janeiro",
  pci_pastures_fallow_period_start_month_feb: "Fevereiro",
  pci_pastures_fallow_period_start_month_mar: "Março",
  pci_pastures_fallow_period_start_month_apr: "Abril",
  pci_pastures_fallow_period_start_month_may: "Maio",
  pci_pastures_fallow_period_start_month_jun: "Junho",
  pci_pastures_fallow_period_start_month_jul: "Julho",
  pci_pastures_fallow_period_start_month_aug: "Agosto",
  pci_pastures_fallow_period_start_month_sep: "Setembro",
  pci_pastures_fallow_period_start_month_oct: "Outubro",
  pci_pastures_fallow_period_start_month_nov: "Novembro",
  pci_pastures_fallow_period_start_month_dec: "Dezembro",
  pci_pastures_fallow_period_harvest: "Colheita",
  pci_pastures_fallow_period_harvest_desc: "Material orgânico que foi retirado da parcela",
  pci_pastures_fallow_period_harvest_info_1: "A colheita é definida como a matéria orgânica removida da parcela. Some todas as colheitas do repouso produtivo nesta parcela e indique a quantidade colhida em toneladas de peso fresco ou seco. Note que seco significa completamente desidratado, de modo que não reste qualquer humidade.",
  pci_pastures_fallow_period_harvest_info_2: "Se não houve colheita, indique zero na quantidade ou deixe esta linha em branco.",
  pci_pastures_fallow_period_harvest_info_3: "Se pretender apagar uma colheita, seleccione o traço (-) no mês.",
  pci_pastures_fallow_period_harvest_type_dd: "Colheita",
  pci_pastures_fallow_period_harvest_type_dd_1: "Folhas, caules, ervas (não lenhosas)",
  pci_pastures_fallow_period_harvest_type_dd_2: "Erva",
  pci_pastures_fallow_period_harvest_type_qty: "Quantidade",
  pci_pastures_fallow_period_harvest_type_qty_fresh: "Toneladas - Fresco",
  pci_pastures_fallow_period_harvest_type_qty_dry: "Toneladas - Seca",
  pci_pastures_fallow_period_harvest_add_harvest: "Adicione a colheita",
  pci_pastures_fallow_period_residue: "Resíduos",
  pci_pastures_fallow_period_residue_desc: "Material orgânico deixado no campo",
  pci_pastures_fallow_period_residue_info_1: "O resíduo é definido como material vegetal/orgânico deixado no campo. Adicione todos os tipos de resíduos desta cultura e indique a quantidade deixada no campo em toneladas de peso fresco ou seco. Note-se que seco significa completamente desidratado, de modo que não reste qualquer humidade.",
  pci_pastures_fallow_period_residue_info_2: "Se não restarem resíduos, indique zero na quantidade ou deixe esta linha em branco.",
  pci_pastures_fallow_period_residue_info_3: "Se pretender apagar uma colheita, seleccione o traço (-) no mês.",
  pci_pastures_fallow_period_residue_type_dd: "Resíduos",
  pci_pastures_fallow_period_residue_type_dd_1: "Folhas, caules, ervas (não lenhosas)",
  pci_pastures_fallow_period_residue_type_dd_2: "Erva",
  pci_pastures_fallow_period_residue_qty: "Quantidade",
  pci_pastures_fallow_period_residue_qty_fresh: "Toneladas - Fresco",
  pci_pastures_fallow_period_residue_qty_dry: "Toneladas - Seco",
  pci_pastures_fallow_period_residue_add_new: "Adicione resíduos",
  pci_pastures_fallow_period_add_new: "Adicione um período de repouso",
  pci_save_button: "Guardar",
  pci_percent_parcel: "% da parcela",

  // livestock - in farm
  ls_infarm_title: "O seu gado",
  ls_infarm_desc: "Aqui encontrará os documentos do seu gado. A seguir a esta secção, encontrará a sua contagem de animais com base nos documentos que forneceu. Actualize esta página fornecendo informações sobre as suas práticas de gestão e de criação.",
  ls_infarm_info: "Se o número de animais não corresponder à realidade da sua exploração, contactar a Iris ou a Maria.",
  ls_infarm_grazing: "Gestão do pastoreio",
  ls_infarm_uploaded_docs: "Documentos adicionados",
  ls_infarm_grazing_desc_1: 'Indique a forma principal de gestão do pastoreio na sua exploração. Se efetuar um pastoreio planeado para pelo menos uma espécie, seleccione "Pastoreio planeado". Note que as informações sobre as parcelas contêm mais pormenores sobre os efeitos do pastoreio planeado.',
  ls_infarm_grazing_desc_2: "Pastoreio não planeado - Os animais são geralmente pastoreados em áreas maiores. Houve pouco ou nenhum planeamento para deslocar os animais de modo a otimizar a produtividade das pastagens e minimizar o excesso de pastagem.",
  ls_infarm_grazing_desc_3: "Pastoreio planeado - As áreas de pastagem são divididas em parcelas mais pequenas. Os animais são deslocados regularmente entre estas parcelas para otimizar a produtividade das pastagens e minimizar o excesso de pastagem.",
  ls_infarm_grazing_unplanned: "Pastoreio não planeado",
  ls_infarm_grazing_planned: "Pastoreio planeado",
  ls_infarm_cattle: "Gado",
  ls_infarm_cattle_females: "Fêmeas maduras, não leiteiras",
  ls_infarm_cattle_females2: "(> 2 anos)",
  ls_infarm_cattle_males: "Machos maduros (> 2 anos)",
  ls_infarm_cattle_males_info: "Bovinos machos maduros de qualquer espécie (> 2 anos).",
  ls_infarm_cattle_growing: "Gado em crescimento",
  ls_infarm_cattle_growing2: "(6 - 24 meses)",
  ls_infarm_cattle_growing_info: "Bovinos em crescimento de todos os géneros e de qualquer espécie (6 - 24 meses).",
  ls_infarm_cattle_calves: "Vitelos (< 6 meses)",
  ls_infarm_cattle_calves_info: "O número de animais jovens é calculado automaticamente através da multiplicação do índice de fertilidade das fêmeas pelo número de fêmeas adultas (leiteiras e não leiteiras). Por favor, introduza a informação sobre a fertilidade das fêmeas bovinas e das vacas leiteiras para ver um número aqui.<br><br>Se o número não corresponder à realidade, contacte a Iris ou a Maria.",
  ls_infarm_grazing_days: "Dias de pastagem por ano",
  ls_infarm_grazing_days_info: "O número de dias em que os seus animais estiveram a pastar na sua exploração, ou seja, não estiveram abrigados ou fora da exploração.",
  ls_infarm_grazing_days_unit: "dias",
  ls_infarm_manure: "Principal método de tratamento do estrume de animais mantidos em estábulos",
  ls_infarm_manure_info_1: "Aqui refere-se ao estrume de animais entabulados ou alojados em recintos fechados, e não ao estrume de animais em pastoreio. Se os seus animais estiverem sempre a pastar, pode deixar este campo em branco.",
  ls_infarm_manure_info_2: "Caso contrário, seleccione a principal forma de tratamento do estrume:",
  ls_infarm_manure_info_3: "Exportação da exploração - O estrume é recolhido e exportado da exploração.",
  ls_infarm_manure_info_4: "Armazenamento de sólidos - O estrume e a urina são excretados num estábulo. Os sólidos (com ou sem cama) são recolhidos e armazenados a granel durante um longo período de tempo (meses). ",
  ls_infarm_manure_info_5: "Armazenamento em fossa > 1 mês - O estrume e a urina são armazenados numa fossa debaixo dos estábulos durante mais de um mês.",
  ls_infarm_manure_info_6: "Líquido / chorume - O estrume e a urina são coletados e transportados em forma líquida para tanques de armazenamento. O líquido pode ser armazenado por um longo período (meses).",
  ls_infarm_manure_info_7: "Lote seco - Os animais são mantidos em parques de alimentação não pavimentados onde o estrume é deixado a secar até ser periodicamente removido.",
  ls_infarm_manure_info_8: "Digestão anaeróbia - biogás - O estrume e a urina líquida são recolhidos e digeridos anaerobicamente. O CH4 pode ser queimado ou ventilado.",
  ls_infarm_manure_info_9: "Lagoas anaeróbias - O estrume é transportado para lagoas através de um sistema de lavagem com água. O estrume permanece na lagoa durante, pelo menos, um mês. A água da lagoa pode ser reciclada em água de lavagem ou utilizada para irrigar e fertilizar os campos.",
  ls_infarm_manure_info_10: "Aplicação diária - O estrume e a urina são recolhidos diariamente, por exemplo, por raspagem. Os resíduos recolhidos são aplicados nos campos.",
  ls_infarm_manure_info_11: "Compostagem - Intensiva - O estrume e a urina são recolhidos e colocados num contentor ou num túnel, sendo efectuada uma aeração forçada do estrume.",
  ls_infarm_manure_info_12: "Compostagem - Extensiva - O estrume e a urina são recolhidos, acumulados e virados regularmente para aeração.",
  ls_infarm_manure_info_13: "Cama profunda - O estrume e a urina dos bovinos/porcinos são excretados no chão do estábulo. Os resíduos acumulados são removidos após um longo período de tempo.",
  ls_infarm_manure_info_14: "Tratamento aeróbico - O estrume e a urina são recolhidos em forma líquida. Os excrementos são sujeitos a um arejamento forçado ou tratados em tanques aeróbicos, ou em sistemas de zonas húmidas para proporcionar a nitrificação e a desnitrificação.",
  ls_infarm_manure_treatment: "Tratamento do estrume",
  ls_infarm_manure_exported: "Exportação da exploração",
  ls_infarm_manure_solid: "Armazenamento sólido",
  ls_infarm_manure_pit: "Armazenamento em fossa > 1 mês",
  ls_infarm_manure_liquid: "Líquido / Chorume",
  ls_infarm_manure_dry: "Lote seco",
  ls_infarm_manure_biogas: "Digestão anaeróbia - biogás",
  ls_infarm_manure_anae_lagoon: "Lagoa anaeróbia",
  ls_infarm_manure_spread: "Espalhamento diário",
  ls_infarm_manure_comp_intensive: "Compostagem - Intensiva",
  ls_infarm_manure_comp_extensive: "Compostagem - Extensiva",
  ls_infarm_manure_deep_litter: "Cama profunda",
  ls_infarm_manure_aerobic: "Tratamento aeróbico",
  ls_infarm_fertility: "Taxa de fertilidade das fêmeas",
  ls_infarm_fertility_info_1: "Este é o número médio de crias por fêmea em idade reprodutiva desta espécie. Por exemplo, se tiver 9 vitelos nascidos de 20 vacas, a sua taxa de fertilidade feminina é 9/20 = 0,45",
  ls_infarm_fertility_info_2: "Com base na taxa de fertilidade, é calculado o número de vitelos.",
  ls_infarm_dairy_cows: "Vacas leiteiras",
  ls_infarm_dairy_cows_mature: "Fêmeas maduras (> 2 anos)",
  ls_infarm_dairy_cows_mature_info: "Vacas leiteiras fêmeas maduras ( > 2 anos).",
  ls_infarm_swine: "Suínos",
  ls_infarm_swine_sows: "Porcas (> 6 meses)",
  ls_infarm_swine_sows_info: "Suínas maduras para reprodução (> 6 meses).",
  ls_infarm_swine_boars: "Varrascos (> 6 meses)",
  ls_infarm_swine_boars_info: "Suínos machos maduros para reprodução ( > 6 meses).",
  ls_infarm_swine_finishers: "Acabadores (3 - 6 meses)",
  ls_infarm_swine_finishers_info: "Suínos não reprodutores para carne, entre 3 e 6 meses de idade (todos os géneros).",
  ls_infarm_swine_other: "Outros suínos",
  ls_infarm_swine_other_info: "Não existe informação disponível que permita classificar estes animais em finalizadores, suínas ou varrascos.",
  ls_infarm_swine_piglets: "Leitões",
  ls_infarm_young_animals_info: "O número de animais jovens é calculado multiplicando a taxa de fertilidade das fêmeas pelo número de fêmeas adultas. Introduza a informação sobre a fertilidade das fêmeas na secção de animais adultos para ver um número aqui. <br> <br> Se o número não corresponder à realidade, entre em contacto com a Iris ou a Maria.",
  ls_infarm_purchased_info: "Este é o número de animais jovens que comprou diretamente e que não nasceram na sua exploração. <br> <br> Se o número não corresponder à realidade, entre em contacto com a Iris ou a Maria.",
  ls_infarm_swine_fertility_info_1: "É o número médio de crias por fêmea em idade reprodutiva desta espécie. Por exemplo, se tiver 32 leitões nascidos de 4 suínas, a taxa de fertilidade das fêmeas é 32/4 = 8",
  ls_infarm_swine_fertility_info_2: "Com base na taxa de fertilidade, calcula-se o número de leitões.",
  ls_infarm_chicken: "Galinhas",
  ls_infarm_chicken_layer: "Galinhas poedeiras",
  ls_infarm_chicken_layer_info: "Galinhas para pôr ovo",
  ls_infarm_chicken_broilers: "Frangos de carne",
  ls_infarm_chicken_broilers_info: "Frangos para carne",
  ls_infarm_chicken_other: "Outras galinhas",
  ls_infarm_chicken_other_info: "Não existe informação disponível para classificar estes frangos em galinhas poedeiras ou frangos de carne.",
  ls_infarm_sheep: "Ovelhas",
  ls_infarm_sheep_females: "Fêmeas maduras (> 1 ano)",
  ls_infarm_sheep_males: "Machos maduros (> 1 ano)",
  ls_infarm_sheep_lambs: "Borregos (< 1 ano)",
  ls_infarm_sheep_fertility_info_1: "Este é o número médio de crias por fêmea em idade reprodutiva desta espécie. Por exemplo, se tiver 25 borregos nascidos de 20 ovelhas, a sua taxa de fertilidade feminina é 25/20 = 1,25",
  ls_infarm_sheep_fertility_info_2: "Com base na taxa de fertilidade, calcula-se o número de borregos.",
  ls_infarm_goats: "Cabras",
  ls_infarm_goats_females: "Fêmeas maduras (> 1 ano)",
  ls_infarm_goats_males: "Machos maduros (> 1 ano)",
  ls_infarm_goats_kids: "Cabritos (< 1 ano)",
  ls_infarm_goats_fertility_info_1: "É o número médio de crias por fêmea em idade reprodutiva desta espécie. Por exemplo, se tiver 25 cabritos nascidos de 20 cabras adultas, a taxa de fertilidade das fêmeas é 25/20 = 1,25",
  ls_infarm_goats_fertility_info_2: "Com base na taxa de fertilidade, calcula-se o número de cabritos.",
  ls_infarm_turkeys: "Perus",
  ls_infarm_ducks: "Patos",
  ls_infarm_add_out_farm: "Adicionar animais externos",


  ls_no_docs_title: "Gado",
  ls_no_docs_1: "Note que o processo de recolha de dados relativos aos animais de gado é um processo de duas etapas:",
  ls_no_docs_2: "Etapa 1. Adicione os seus documentos oficiais de gado. Com base nesses documentos, o nosso agrónomo calculará o número de animais.",
  ls_no_docs_3: "Etapa 2. Fornece informações sobre a gestão dos seus animais.",
  ls_no_docs_4: "Atualmente, ainda estamos a analisar os seus documentos relativos ao gado. Após os termos analisado, pode avançar para a segunda etapa.",
  ls_no_docs_5: "Se não tiver adicionado todos os seus documentos de gado, carregue-os clicando no botão abaixo. Informe a Iris ou a Maria quando os tiver adicionado.",
  ls_no_docs_6: 'Se permitiu que animais de fora da sua exploração pastassem na sua exploração, pode adicionar detalhes relativos a essa prática clicando em "Adicionar animais externos" abaixo.',
  ls_no_docs_button: "Ir para o ecrã de upload",

  ls_outfarm_title: "Animais não pertencentes à sua exploração",
  ls_outfarm_desc: `Se nas suas parcelas pastaram animais pertencentes a outro agricultor, indique-os abaixo. Seleccione as espécies e indique o número de animais e de dias de pastagem para cada ano.`,
  ls_outfarm_species: "Espécies",
  ls_outfarm_cattle: "Bovinos (não leiteiros)",
  ls_outfarm_dairy_cows: "Vacas leiteiras",
  ls_outfarm_swine: "Suínos",
  ls_outfarm_sheep: "Ovelhas",
  ls_outfarm_goats: "Cabras",
  ls_outfarm_num_animals: "Número de animais",
  ls_outfarm_info: "Se pretender eliminar uma espécie, seleccione o hífen (-) na lista Espécies e a linha não será guardada.",
  ls_outfarm_grazing_days: "Dias de pastagem por ano",
  ls_outfarm_grazing_days_info: "O número de dias que os animais estiveram a pastar na sua exploração, ou seja, não estiveram estabulados ou fora da exploração.",
  ls_outfarm_grazing_days_num: "Número de dias de pastagem",
  ls_outfarm_add: "+ Adicionar espécies de animais",
  ls_outfarm_no_animals: "Não tenho animais não pertencentes à exploração",

  baseline_years: "Base de referência",
  project_years: "Anos de projeto",

  generic_litres: 'litros',
  generic_area: 'Área',
  generic_hectares: 'hectares',
  generic_complete_fields: 'Preencha os campos assinalados a vermelho para guardar as informações completas sobre a sua exploração.',
  generic_complete_yes: 'Completar as informações em falta',
  generic_complete_no: 'Eliminar informações incompletas',
  generic_complete_title: 'Dados em falta',
  general_parcel: "Parcela",
  generic_calculated: 'calculados',
  generic_purchased: 'comprados',

  "productive fallow": "Repouso produtivo",
  "bare soil": "Solo descoberto",

  validation_fuel: 'O ícone nesta página ficará verde quando os dados sobre a utilização de gasolina e gasóleo para todos os anos tiverem sido comunicados.',
  validation_livestock: 'O ícone desta página ficará verde quando os dados sobre as práticas de maneio do gado para todas as espécies tiverem sido indicados para todos os anos.',
  validation_map: `O ícone nesta página ficará verde quando os dados válidos sobre todas as suas parcelas (páginas de informações gerais e sobre as culturas) tiverem sido introduzidos para todos os anos.  
<br/><br/>
Além disso, a página Informações de toda a exploração deve conter dados válidos para todos os anos`,
  validation_farmwide: 'O ícone desta página ficará verde quando os dados de utilização de fertilizantes sintéticos e a percentagem importada de correctivos orgânicos de toda a exploração para todos os anos tiverem sido indicados.',
  validation_parcel: 'O ícone desta parcela ficará verde quando as páginas de informações gerais e de culturas contiverem dados válidos. Passe o rato sobre os indicadores de progresso nas respectivas páginas para saber mais.',
  validation_crops: `O ícone desta página ficará verde quando forem indicadas informações válidas sobre, pelo menos, um dos seguintes tipos de utilização das terras da parcela para cada ano:
<br/><br/>
- Plantas e árvores perenes<br/>
- Culturas anuais<br/>
- Prados<br/>
- Períodos de pousio
<br/><br/>
Se começou a introduzir dados para qualquer um dos tipos de utilização do solo acima referidos, deve completá-los.`,
  validation_landuse: 'O ícone nesta página ficará verde quando forem indicados os principais dados de utilização do solo e de irrigação para todos os anos.',


  documents_collection: "Recolha de documentos",
  data_collection: "Recolha de dados",
  submitted_for_review: "Submeter para revisão",
  internal_review: "Revisão interna",
  audit: "Auditoria",
  carbon_plus_credit_posting: "Registo de Créditos de Carbon+",
  carbon_plus_credit_sales: "Vendas de Créditos de Carbon+",

  subtitle_documents_collection: "",
  subtitle_farm_data_yet_to_submit: "A plataforma está atualmente aberta à recolha de dados. Clique em 'Submeter para revisão' para submeter os seus dados",
  subtitle_submitted_review: "Em breve estaremos a rever os seus dados. Contacte-nos se precisar de fazer alguma alteração.",
  subtitle_internal_review: "Estamos a rever os seus dados e documentos. Contacte-nos se precisar de fazer alguma alteração.",
  subtitle_audit: "Os seus dados foram submetidos ao auditor. Entraremos em contacto consigo com mais informações.",
  subtitle_carbon_credit_posting: "Os seus Créditos de Carbon+ foram lançados no registo. Por favor contacte-nos para saber os seus números de crédito.",
  subtitle_carbon_sales: "Os seus Créditos de Carbon+ foram vendidos e o vencimento da venda foi transferido para a sua conta.",
  next_steps: "Próximos passos",
};