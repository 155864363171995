export const inFarmTempObj = [
    {
        species: 'cattle',
        grazingDays: -9999,
        manureTreatment: null,
        fertilityRate: -9999,
        category: [
            { name: 'mature females (non-dairy)', amount: -9999 },
            { name: 'mature males', amount: -9999 },
            { name: 'growing cattle (6 - 24 months)', amount: -9999 },
            { name: 'calves (< 6 months)', amount: -9999 },
        ]
    },
    {
        species: 'dairy cows',
        grazingDays: -9999,
        manureTreatment: null,
        fertilityRate: -9999,
        category: [
            { name: 'mature females (> 2 years)', amount: -9999 }
        ]
    },
    {
        species: 'swine',
        grazingDays: -9999,
        manureTreatment: null,
        fertilityRate: -9999,
        category: [
            { name: 'sows', amount: -9999 },
            { name: 'boars', amount: -9999 },
            { name: 'finishers (3 - 6 months)', amount: -9999 },
            { name: 'other swine', amount: -9999 },
            { name: 'piglets', amount: -9999 },
        ]
    },
    {
        species: 'sheep',
        grazingDays: -9999,
        manureTreatment: null,
        fertilityRate: -9999,
        category: [
            { name: 'mature females (> 1 year)', amount: -9999 },
            { name: 'mature males (> 1 year)', amount: -9999 },
            { name: 'lambs (< 1 year)', amount: -9999 },
        ]
    },
    {
        species: 'goats',
        grazingDays: -9999,
        manureTreatment: null,
        fertilityRate: -9999,
        category: [
            { name: 'mature females (> 1 year)', amount: -9999 },
            { name: 'mature males (> 1 year)', amount: -9999 },
            { name: 'goat kids (< 1 year)', amount: -9999 },
        ]
    },
    {
        species: 'chicken',
        grazingDays: -9999,
        manureTreatment: null,
        fertilityRate: -9999,
        category: [
            { name: 'layer hens', amount: -9999 },
            { name: 'broilers', amount: -9999 },
            { name: 'other chicken', amount: -9999 }
        ]
    },
    {
        species: 'turkeys',
        grazingDays: -9999,
        manureTreatment: null,
        fertilityRate: -9999,
        category: [
            { name: 'turkeys', amount: -9999 }
        ]
    },
    {
        species: 'ducks',
        grazingDays: -9999,
        manureTreatment: null,
        fertilityRate: -9999,
        category: [
            { name: 'ducks', amount: -9999 }
        ]
    }
];


export const outFarmTempObj = {
    species: null,
    amount: -9999,
    grazingDays: -9999
};

export const calvesObj = { name: 'calves (< 6 months) purchased', amount: -9999 }
export const pigletsObj = { name: 'piglets purchased', amount: -9999 }
export const lambsObj = { name: 'lambs (< 1 year) purchased', amount: -9999 }
export const goatKidsObj = { name: 'goat kids (< 1 year) purchased', amount: -9999 }

export const yearlyFarmData = [
    {
        year: 2018,
        fuelUsage: {
            direct: {
                diesel: {
                    amount: -9999,
                    units: "litres",
                },
                petrol: {
                    amount: -9999,
                    units: "litres",
                },
            },
            indirect: [
                {
                    service: "",
                    area: {
                        amount: -9999,
                        units: "hectares",
                    },
                    serviceDetail: "",
                    serviceCategory: "",
                },
            ],
        },
        fertilizerUsage: [],
        importedOrganicMatter: [],
        livestock: {
            grazingManagement: null,
            inFarm: [],
            outFarm: [],
        },
        parcelLevelData: [],
    },
    {
        year: 2019,
        fuelUsage: {
            direct: {
                diesel: {
                    amount: -9999,
                    units: "litres",
                },
                petrol: {
                    amount: -9999,
                    units: "litres",
                },
            },
            indirect: [
                {
                    service: "",
                    area: {
                        amount: -9999,
                        units: "hectares",
                    },
                    serviceDetail: "",
                    serviceCategory: "",
                },
            ],
        },
        fertilizerUsage: [],
        importedOrganicMatter: [],
        livestock: {
            grazingManagement: null,
            inFarm: [],
            outFarm: [],
        },
        parcelLevelData: [],
    },
    {
        year: 2020,
        fuelUsage: {
            direct: {
                diesel: {
                    amount: -9999,
                    units: "litres",
                },
                petrol: {
                    amount: -9999,
                    units: "litres",
                },
            },
            indirect: [
                {
                    service: "",
                    area: {
                        amount: -9999,
                        units: "hectares",
                    },
                    serviceDetail: "",
                    serviceCategory: "",
                },
            ],
        },
        fertilizerUsage: [],
        importedOrganicMatter: [],
        livestock: {
            grazingManagement: null,
            inFarm: [],
            outFarm: [],
        },
        parcelLevelData: [],
    },
    {
        year: 2021,
        fuelUsage: {
            direct: {
                diesel: {
                    amount: -9999,
                    units: "litres",
                },
                petrol: {
                    amount: -9999,
                    units: "litres",
                },
            },
            indirect: [
                {
                    service: "",
                    area: {
                        amount: -9999,
                        units: "hectares",
                    },
                    serviceDetail: "",
                    serviceCategory: "",
                },
            ],
        },
        fertilizerUsage: [],
        importedOrganicMatter: [],
        livestock: {
            grazingManagement: null,
            inFarm: [],
            outFarm: [],
        },
        parcelLevelData: [],
    },
    {
        year: 2022,
        fuelUsage: {
            direct: {
                diesel: {
                    amount: -9999,
                    units: "litres",
                },
                petrol: {
                    amount: -9999,
                    units: "litres",
                },
            },
            indirect: [
                {
                    service: "",
                    area: {
                        amount: -9999,
                        units: "hectares",
                    },
                    serviceDetail: "",
                    serviceCategory: "",
                },
            ],
        },
        fertilizerUsage: [],
        importedOrganicMatter: [],
        livestock: {
            grazingManagement: null,
            inFarm: [],
            outFarm: [],
        },
        parcelLevelData: [],
    },
    {
        year: 2023,
        fuelUsage: {
            direct: {
                diesel: {
                    amount: -9999,
                    units: "litres",
                },
                petrol: {
                    amount: -9999,
                    units: "litres",
                },
            },
            indirect: [
                {
                    service: "",
                    area: {
                        amount: -9999,
                        units: "hectares",
                    },
                    serviceDetail: "",
                    serviceCategory: "",
                },
            ],
        },
        fertilizerUsage: [],
        importedOrganicMatter: [],
        livestock: {
            grazingManagement: null,
            inFarm: [],
            outFarm: [],
        },
        parcelLevelData: [],
    },
]

export const docTypes = {
    year: "",
    capDocument: [],
    livestockTotals: [],
    ampGrazingCharts: [],
    fertilizerInvoices: [],
    dieselInvoices: [],
    petrolInvoices: [],
    externalServicesInvoices: [],
    seedsInvoices: [],
};

