import React from 'react'
import { Alert, Grid } from '@mui/material';


const FinalStepInOnboardingComponent = ({ farmerName }) => {
  return (
    <div>

      <Grid container spacing={2} style={{ marginTop: '4px', marginBottom: '20px' }}>
        <Grid item xs={12} sm={12} md={12} lg={12} style={{ justifyContent: 'center' }}>
          <Alert icon={false} severity="warning">
            <div style={{ fontWeight: 'bold' }}>
              <div>

                Hi {farmerName},
              </div>
              <div>Thank your for applying to the Carbon+ Credit program.</div>
              <div>If you would like to come back later and edit your information, click now on "Save as draft".</div>
              <div>If you wish to submit the information to Climate Farmers, click "Submit Details"</div>
              <div>Please note that once you have submitted the information, you will not be able to edit it. Once we have reviewed the information we will be able to assess the suitability for the program and you will be able to view the estimate of tonnes of CO2 sequestered.
              </div>
              <div>
                When you already have submitted the details, and still have further questions, please contact Cina or Maria.
              </div>
              <div>
                For any further questions, you can also contact us on: support@climatefarmers.org
              </div>
            </div>
          </Alert>
        </Grid>
      </Grid>


    </div>
  )
}

export default FinalStepInOnboardingComponent