
import { updateFarmVerificationDocuments } from "../../../actions/verificationDocuments"
import ConvertKMZtoKMLService from "../../../farmservices/ConvertKMZtoKMLService"

export const kmzToKml = async (notKml, dispatch, currentFarmVerificationDocuments) => {
    const kmlExtention = notKml?.fileName.slice(-3)
    if (kmlExtention === "kmz") {
        const kmzFile = notKml
        const kmlFile = kmzFile.s3URL.slice(0, -3) + 'kml'
        const kmlkmzObject = {
            "kmzFilePath": kmzFile.s3URL,
            "kmlFilePath": kmlFile
        }
        await ConvertKMZtoKMLService.convertKMZtoKML(kmlkmzObject)
        const tempObj = currentFarmVerificationDocuments
        const kmlFileName = notKml?.fileName.slice(0, -1) + 'l'
        tempObj.kmlFiles[0].s3URL = kmlFile
        tempObj.kmlFiles[0].fileName = kmlFileName


        await dispatch(
            updateFarmVerificationDocuments(
                currentFarmVerificationDocuments._id,
                tempObj
            )
        ).then((res) => {
            console.log('success');

        })
            .catch((e) => {
                console.log('failure')
            });


    } else {
        console.log('Format not accepted, has to be either kml or kmz')
    }

}