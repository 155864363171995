import { SubmitButton } from "../../styles"

const { Box } = require("@material-ui/core")

const Button = ({ onClick, text }) => {
    return <SubmitButton
        sx={{ marginTop: "20px", alignSelf: "flex-end", width: "200px" }}
        onClick={onClick}>
        {text}
    </SubmitButton>

}


export default Button