import { getVerificationDocumentURL } from "../../general/utils/fileOperations";

export const getKmlObjectBody = async (currentFarmVerificationDocuments) => {
    const path = currentFarmVerificationDocuments.kmlFiles[0].s3URL;
    let key = path.split(
        "https://farms-verification-documents.s3.eu-central-1.amazonaws.com/"
    )[1];

    try {
        const object = await getVerificationDocumentURL(key);
        return object;
    } catch (error) {
        // Handle errors if necessary
        console.error('Error fetching KML object:', error);
        throw error; // Rethrow the error to propagate it upwards
    }
};