import {
    FormControl,
    MenuItem,
    Select
} from "@mui/material";
import React, { useEffect, useState } from 'react';

import { useUser } from "@clerk/clerk-react";
import { useDispatch, useSelector } from "react-redux";
import { getExistingFarmland, updateCurrentFarmData } from "../../actions/currentFarmland";
import { setCurMonitoringData } from "../../actions/currentMonitoringData";
import { findFarmlandByEmail } from "../../actions/farmlands";
import { findSettingsByFarmerPrimaryId } from "../../actions/farmSettings";
import { findMonitoringDataByFarmId } from "../../actions/monitoringData";

const FarmChange = () => {

    const dispatch = useDispatch();
    const { user } = useUser();
    const email = user?.emailAddresses[0]?.emailAddress;
    const currentFarmDataObject = useSelector(
        (state) => state.currentFarmDataReducer
    );

    const [farms, setFarms] = useState([]);
    const [selectedFarm, setSelectedFarm] = useState(currentFarmDataObject?.farmInfo?.farmId || ''); // Store only farmId

    const getFarmList = () => {
        dispatch(findFarmlandByEmail(email))
            .then(res => setFarms(res))
            .catch(err => console.log('error', err))
    }

    useEffect(() => {
        getFarmList();
    }, []);

    useEffect(() => {
        if (farms.length > 0 && !selectedFarm) {
            setSelectedFarm(farms[0].farmInfo.farmId);
        }
    }, [farms]);

    const handleFarmChange = (event) => {
        const selectedFarmId = event.target.value;
        setSelectedFarm(selectedFarmId);

        const selectedFarmObject = farms.find(farm => farm.farmInfo.farmId === selectedFarmId);

        dispatch(updateCurrentFarmData(selectedFarmObject));
        editFarmData(selectedFarmObject);
    };

    const editFarmData = (farm) => {
        dispatch(findSettingsByFarmerPrimaryId(farm._id))
            .then(res => {
                dispatch(getExistingFarmland(farm))
                    .then(newFarm => {
                        console.log("New farm data:", newFarm);
                    });
                dispatch(findMonitoringDataByFarmId(farm._id))
                    .then(res => {
                        dispatch(setCurMonitoringData(res.data[0]));
                    });

            })
            .catch(error => {
                console.error(error);
            });
    };


    return (
        <FormControl>
            <Select
                size="small"
                sx={{
                    borderRadius: "10px",
                    background: 'white'
                }}
                value={selectedFarm}
                onChange={handleFarmChange}
            >
                {farms.map(farm => (
                    <MenuItem key={farm.farmInfo.farmId} value={farm.farmInfo.farmId}>
                        {farm.farmInfo.farmName}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default FarmChange;
