import { useContext, useEffect } from "react";
import { useSelector } from "react-redux";

import { MapContext } from "../MapParcelsComponent";

import { NavigationControl } from "mapbox-gl";
//import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
//import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from "mapbox-gl";
import { useDispatch } from "react-redux";
import { arrColors } from "../../../constants/colors";
/* eslint import/no-webpack-loader-syntax: off */
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const MapControl = (props) => {
  const {
    mapRef,
    drawRef,

  } = useContext(MapContext);

  const currentMonitoringDataObject = useSelector(
    (state) => state.currentMonitoringDataReducer
  );

  const currentMonitoringYearsObject = useSelector(state => state.monitoringYearsTrackerReducer);

  const dispatch = useDispatch();

  let { map } = mapRef?.current?.state;

  useEffect(() => {
    map.addControl(props.control);

    map.addControl(new NavigationControl());
  }, []);



  const handleMapParcelPolygons = () => {

    var yearlyFarmDataList =
      currentMonitoringDataObject?.yearlyFarmData[
      currentMonitoringDataObject?.yearlyFarmData?.length - 1
      ];

    drawRef?.current?.draw?.deleteAll()

    if (yearlyFarmDataList.parcelLevelData?.length > 0) {

      yearlyFarmDataList.parcelLevelData?.map((row, index) => {
        if (
          map.getLayer(row.id) === null ||
          map.getLayer(row.id) === undefined
        ) {
          let arrRowCoordinates = Array();
          for (
            let i = 0;
            i < row?.parcelFixedValues?.coordinates?.length;
            i++
          ) {
            arrRowCoordinates.push(row?.parcelFixedValues?.coordinates[i]);
          }
          map.addSource(row.id, {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: [
                {
                  type: "Feature",
                  name: row.id,
                  geometry: {
                    type: "Polygon",
                    coordinates: [arrRowCoordinates],
                  },
                  properties: {
                    name: row.id,
                    label: row.id,
                  },
                },
              ],
            },
          });

          //A permanent fix for the different colors is create an arary of 250 parcels (I assume farmers would not have more)
          //Each array value is a different hex color code
          //Take the value and replace it
          let outLineId = "outline".concat(row.id);
          map.addLayer({
            id: row.id,
            type: "fill",
            source: row.id, // reference the data source
            layout: {},
            paint: {
              //'fill-color': randomHexColor(),
              "fill-color": arrColors[index],
              "fill-opacity": 0.4,
            },
          });

          map.addLayer({
            id: outLineId,
            type: "line",
            source: row.id,
            layout: {},
            paint: {
              "line-color": "#000",
              "line-width": 5,
            },
          });

          map.addLayer({
            id: "hover_".concat(row.id),
            type: "fill",
            source: row.id, // reference the data source
            layout: {},
            paint: {
              //'fill-color': randomHexColor(),
              "fill-color": "yellow",
              "fill-opacity": 1,
            },
            filter: ["==", "name", ""],
          });

          map.on("mousemove", row.id, function (e) {
            map.getCanvas().style.cursor = "pointer";
            map.setFilter("hover_".concat(row.id), ["==", "name", row.id]);
          });

          // Reset the state-fills-hover layer's filter when the mouse leaves the layer.
          map.on("mouseleave", row.id, function () {
            map.getCanvas().style.cursor = "";
            map.setFilter("hover_".concat(row.id), ["==", "name", ""]);
          });


          map.on("click", row.id, (e) => {

            let tmpMapData = {
              selectedRow: row,
              longitude: e?.lngLat?.lng,
              latitude: e?.lngLat?.lat,
              curMonitoringDataOb: currentMonitoringDataObject
            };
            props.setMapPopUpForParcel(tmpMapData)
            props.setViewState({
              longitude: e?.lngLat?.lng,
              latitude: e?.lngLat?.lat,
              zoom: [14]
            })
          });
        }
      });
    }
  };

  useEffect(() => {
    handleMapParcelPolygons();
  }, []);

  useEffect(() => {
    handleMapParcelPolygons();
  }, [currentMonitoringDataObject?.yearlyFarmData[currentMonitoringYearsObject?.allMonitoringYears[currentMonitoringYearsObject?.allMonitoringYears?.length - 1]?.parcelLevelData?.length]]);


  return null;
};

export { MapControl };
