import area from "@turf/area";
import { polygon } from "@turf/helpers";
import { v4 as uuidv4 } from "uuid";

export const KMLFileToParcels = (kml) => {
    let finalCoordinatesArray = [];
    let parcelNamesAndCoordinates = {};


    var elementsByTagNameAsPlacemark = kml.getElementsByTagName("Placemark");
    for (var i = 0; i < elementsByTagNameAsPlacemark.length; i++) {
        let tmpParcelName = "";
        var childNodes = elementsByTagNameAsPlacemark[i].childNodes;
        for (var j = 0; j < childNodes.length; j++) {
            if (childNodes[j].nodeName === "name") {
                if (childNodes[j].innerHTML !== "") {
                    tmpParcelName = childNodes[j].innerHTML + '_' + Math.floor(Math.random() * 100)
                }
            }
            if (childNodes[j].nodeName === "MultiGeometry") {
                var multiChildNodes = childNodes[j].childNodes;
                for (var x = 0; x < multiChildNodes.length; x++) {
                    if (multiChildNodes[x].nodeName === "Polygon") {
                        var outerBoundaryIsNodes =
                            multiChildNodes[x].getElementsByTagName("outerBoundaryIs");
                        for (var k = 0; k < outerBoundaryIsNodes.length; k++) {
                            var linearRingNodes =
                                outerBoundaryIsNodes[k].getElementsByTagName("LinearRing");
                            for (var l = 0; l < linearRingNodes.length; l++) {
                                var coordinatesNodes =
                                    linearRingNodes[l].getElementsByTagName("coordinates");
                                var coordinatesRawValues = coordinatesNodes[0].innerHTML
                                    .toString()
                                    .trim();
                                var coordinatesRawArray = coordinatesRawValues.split(" ");
                                let parcelParentArray = [];
                                for (var m = 0; m < coordinatesRawArray.length; m++) {
                                    var tmpCoordinateArray =
                                        coordinatesRawArray[m].split(",");
                                    parcelParentArray.push([
                                        parseFloat(tmpCoordinateArray[0]),
                                        parseFloat(tmpCoordinateArray[1]),
                                    ]);
                                }
                                finalCoordinatesArray.push([parcelParentArray]);

                                tmpParcelName ? tmpParcelName = tmpParcelName : tmpParcelName = "Parcel ".concat(
                                    (Math.random() + 1).toString(36).substring(2)
                                );
                                parcelNamesAndCoordinates[tmpParcelName] =
                                    finalCoordinatesArray.pop();
                            }
                        }
                    }
                }
            } else if (childNodes[j].nodeName === "Polygon") {
                var outerBoundaryIsNodes =
                    childNodes[j].getElementsByTagName("outerBoundaryIs");
                for (var k = 0; k < outerBoundaryIsNodes.length; k++) {
                    var linearRingNodes =
                        outerBoundaryIsNodes[k].getElementsByTagName("LinearRing");
                    for (var l = 0; l < linearRingNodes.length; l++) {
                        var coordinatesNodes =
                            linearRingNodes[l].getElementsByTagName("coordinates");
                        var coordinatesRawValues = coordinatesNodes[0].innerHTML
                            .toString()
                            .trim();
                        var coordinatesRawArray = coordinatesRawValues.split(" ");
                        let parcelParentArray = [];
                        for (var m = 0; m < coordinatesRawArray.length; m++) {
                            var tmpCoordinateArray = coordinatesRawArray[m].split(",");
                            parcelParentArray.push([
                                parseFloat(tmpCoordinateArray[0]),
                                parseFloat(tmpCoordinateArray[1]),
                            ]);
                        }
                        finalCoordinatesArray.push([parcelParentArray]);
                        tmpParcelName ? tmpParcelName = tmpParcelName : tmpParcelName = "Parcel ".concat(
                            (Math.random() + 1).toString(36).substring(2)
                        );
                        parcelNamesAndCoordinates[tmpParcelName] =
                            finalCoordinatesArray.pop();
                    }
                }
            } else if (childNodes[j].nodeName === "LineString") {
                var coordinatesNodes =
                    childNodes[j].getElementsByTagName("coordinates");
                var coordinatesRawValues = coordinatesNodes[0].innerHTML
                    .toString()
                    .trim();
                var coordinatesRawArray = coordinatesRawValues.split(" ");
                let parcelParentArray = [];
                for (var p = 0; p < coordinatesRawArray.length; p++) {
                    var tmpCoordinateArray = coordinatesRawArray[p].split(",");
                    parcelParentArray.push([
                        parseFloat(tmpCoordinateArray[0]),
                        parseFloat(tmpCoordinateArray[1]),
                    ]);
                }
                parcelParentArray.push(parcelParentArray[0]);
                finalCoordinatesArray.push([parcelParentArray]);
                tmpParcelName = "Parcel ".concat(
                    (Math.random() + 1).toString(36).substring(2)
                );

                parcelNamesAndCoordinates[tmpParcelName] =
                    finalCoordinatesArray.pop();
            }
        }
    }
    var arrParcelLevelData = [];
    //Take project year; subtract by 3 and add parcel level details for each of the years
    Object.entries(parcelNamesAndCoordinates).map(([k, v]) => {
        var polygon2 = polygon(v);
        var calculatedArea = Math.round(area(polygon2) * 100) / 100 / 10000;
        //createNewParcel(k, calculatedArea, v);
        //createNewParcel2(k, calculatedArea, v);
        arrParcelLevelData.push({
            id: uuidv4(),
            parcelFixedValues: {
                parcelName:
                    k || "Parcel ".concat(
                        (Math.random() + 1).toString(36).substring(2)
                    ),
                parcelID: uuidv4(),
                areaGeoFile: {
                    area: calculatedArea !== null ? calculatedArea : 0,
                    units: "HA",
                },
                areaManualEntry: { area: -9999, units: null },
                coordinates: v !== null ? v[0] : [],
            },
            yearParcelData: {
                primaryLandUse: null,
                secondaryLandUse: null,
                addedOrganicMatter: [
                    {
                        id: uuidv4(),
                        type: "biochar",
                        subType: "",
                        other: "",
                        amount: -9999,
                        units: "tonnes",
                    },
                    {
                        id: uuidv4(),
                        type: "compost",
                        subType: "",
                        other: "",
                        amount: -9999,
                        units: "tonnes",
                    },
                    {
                        id: uuidv4(),
                        type: "manure",
                        subType: "",
                        other: "",
                        amount: -9999,
                        units: "",
                    },
                    {
                        id: uuidv4(),
                        type: "mulch",
                        subType: "",
                        other: "",
                        amount: -9999,
                        units: "tonnes",
                    },
                    {
                        id: uuidv4(),
                        type: "slurry",
                        subType: "",
                        other: "",
                        amount: -9999,
                        units: "litres",
                    },
                    {
                        id: uuidv4(),
                        type: "other",
                        subType: "",
                        other: "",
                        amount: -9999,
                        units: "tonnes",
                    },
                ],
                animalFodder: { amount: -9999, units: null },
                grazingMonthlyEvent: [
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                ],
                tillage: [
                    {
                        type: null,
                        date: null,
                        percent: -9999,
                    },
                ],
                irrigation: null,
                treeFelling: [
                    {
                        species: null,
                        other: null,
                        treeNumber: -9999,
                        DBHmean: { diameter: -9999, units: null },
                    },
                ],
                perennialCrops: [
                    {
                        cohortID: null,
                        species: null,
                        other: null,
                        plantingYear: null,
                        rotationYears: -9999,
                        hasRotation: false,
                        treeNumber: -9999,
                        DBHMean: {
                            diameter: -9999,
                            units: null,
                        },
                        harvest: [
                            {
                                date: null,
                                type: null,
                                quantity: -9999,
                                units: "",
                                dry: false,
                            },
                        ],
                        residue: [
                            {
                                date: null,
                                type: null,
                                quantity: -9999,
                                units: "",
                                dry: false,
                            },
                        ],
                    },
                ],
                annualCrops: [
                    {
                        species: null,
                        other: null,
                        sowingDate: null,
                        harvestDate: null,
                        harvest: [
                            {
                                date: null,
                                type: null,
                                quantity: -9999,
                                units: "",
                                dry: false,
                            },
                        ],
                        residue: [
                            {
                                date: null,
                                type: null,
                                quantity: -9999,
                                units: "",
                                dry: false,
                            },
                        ],
                    },
                ],
                pastures: [
                    {
                        percentArea: -9999,
                        productivity: [
                            {
                                date: null,
                                type: null,
                                amount: -9999,
                                units: "",
                                dry: false,
                            },
                        ],
                        harvest: [
                            {
                                date: null,
                                type: null,
                                amount: -9999,
                                units: "",
                                dry: false,
                            },
                        ],
                    },
                ],
                fallow: [
                    {
                        type: null,
                        percentArea: null,
                        startDate: null,
                        endDate: null,
                        harvest: [
                            {
                                date: null,
                                type: null,
                                quantity: -9999,
                                units: "",
                                dry: false,
                            },
                        ],
                        residue: [
                            {
                                date: null,
                                type: null,
                                quantity: -9999,
                                units: "",
                                dry: false,
                            },
                        ],
                    },
                ],
            },
        });
    });

    return arrParcelLevelData

};