import { addNewEmptyFallowData } from "../../../actions/currentMonitoringData";
import { emptyFallowObject } from "./tmpData";

export const addNewFallow = (year, dispatch, parcelid) => {
    let tmpData = {
        year: year,
        data: emptyFallowObject,
        parcelId: parcelid
    };
    dispatch(addNewEmptyFallowData(tmpData))
        .then(res => {
            console.log('New empty fallow data added successfully:', res);
        })
        .catch(error => {
            console.error('Error adding new empty fallow data:', error);
        });
};