const palette = {
    mode: 'light',
    primary: {
        main: '#409781',
        light: '#80c3ab',
    },
    secondary: {
        main: '#808080',

    },
    text: {
        // secondary: '#ffffff',
        primary: '#000000',
        light: '#707070'
    },
}

export default palette