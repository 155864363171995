import { addNewEmptyResidueToPerennialTreeAndCropData } from "../../../actions/currentMonitoringData";
import { tmpData } from "./tmpData";

export const addNewPerennialResidue = (e, i, year, dispatch, parcelid) => {

    let funParam = {
        year: year,
        data: tmpData,
        parcelId: parcelid,
        perennialCropsId: i
    }
    dispatch(addNewEmptyResidueToPerennialTreeAndCropData(funParam))
}