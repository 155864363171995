import {
    ADD_EMPTY_ANNUAL_CROPS,
    ADD_EMPTY_FALLOW,
    ADD_EMPTY_FELLED_TREES,
    ADD_EMPTY_FERTILIZER_USAGE,
    ADD_EMPTY_HARVEST_TO_ANNUAL_CROPS,
    ADD_EMPTY_HARVEST_TO_FALLOW,
    ADD_EMPTY_HARVEST_TO_PASTURES,
    ADD_EMPTY_HARVEST_TO_PERENNIAL_TREE_AND_CROPS,
    ADD_EMPTY_IMPORTED_ORGANIC_MATTER,
    ADD_EMPTY_OM,
    ADD_EMPTY_OM_COMPOST,
    ADD_EMPTY_OM_MANURE,
    ADD_EMPTY_OM_MULCH,
    ADD_EMPTY_OM_OTHER,
    ADD_EMPTY_OM_SLURRY,
    ADD_EMPTY_PASTURES,
    ADD_EMPTY_PERENNIAL_TREE_AND_CROPS,
    ADD_EMPTY_PRODUCTIVITY_TO_PASTURES,
    ADD_EMPTY_RESIDUE_TO_ANNUAL_CROPS,
    ADD_EMPTY_RESIDUE_TO_FALLOW,
    ADD_EMPTY_RESIDUE_TO_PERENNIAL_TREE_AND_CROPS,
    ADD_EMPTY_TILLING_EVENT,
    ADD_PARCELS_TO_YEARS,
    COPY_DATA_BETWEEN_PARCELS,
    COPY_DATA_BETWEEN_PARCELS_FOR_LAND_USE,
    CREATE_EMPTY_MONITORING_DATA,
    DELETE_ANNUAL_CROPS,
    DELETE_FALLOW,
    DELETE_LIVESTOCK_OUT_FARM_ADD_SPECIES,
    DELETE_PARCEL,
    DELETE_PASTURES,
    DELETE_PERENNIAL_TREE_AND_CROPS,
    GET_ANNUAL_CROPS_BY_PARCELID,
    GET_ANNUAL_CROPS_BY_YEAR,
    GET_FALLOW_BY_PARCELID,
    GET_FALLOW_BY_YEAR,
    GET_MONITORING_DATA,
    GET_PASTURES_BY_PARCELID,
    GET_PASTURES_BY_YEAR,
    GET_PERENNIAL_TREE_AND_CROPS_BY_PARCELID,
    GET_PERENNIAL_TREE_AND_CROPS_BY_YEAR,
    RESET_CURRENT_MONITORING_DATA,
    RESET_FALLOW_WHEN_FALLOW_CHANGES,
    RESET_PASTURES_AND_GRASSES_PRODUCTIVITY_HARVESTED,
    SET_CUR_MONITORING_DATA,
    UPDATE_ANIMAL_FODDER_BY_YEAR,
    UPDATE_ANNUAL_CROPS,
    UPDATE_CATTLE_AMOUNT,
    UPDATE_CHICKEN_AMOUNT,
    UPDATE_CUR_YEARLY_FARM_DATA,
    UPDATE_DAIRY_COWS_AMOUNT,
    UPDATE_DUCKS_AMOUNT,
    UPDATE_EMPTY_CUR_PROJECT_START_YEAR,
    UPDATE_FALLOW,
    UPDATE_FELLED_TREES,
    UPDATE_FERTILIZER_USAGE,
    UPDATE_GOATS_AMOUNT,
    UPDATE_GRAZING_MANAGEMENT,
    UPDATE_GRAZING_MONTHS,
    UPDATE_HARVEST_DATA_OF_ANNUAL_CROPS,
    UPDATE_HARVEST_DATA_OF_FALLOW,
    UPDATE_HARVEST_DATA_OF_PASTURES,
    UPDATE_HARVEST_DATA_OF_PERENNIAL_TREE_AND_CROPS,
    UPDATE_IMPORTED_ORGANIC_MATTER,
    UPDATE_IRRIGATION,
    UPDATE_LIVESTOCK,
    UPDATE_LIVESTOCK_FERTILITY,
    UPDATE_LIVESTOCK_GRAZING_DAYS,
    UPDATE_LIVESTOCK_MANURE,
    UPDATE_LIVESTOCK_OUT_FARM_ADD_SPECIES,
    UPDATE_LIVESTOCK_OUT_FARM_GRAZING_DAYS,
    UPDATE_LIVESTOCK_OUT_FARM_NUMBER_OF_ANIMALS,
    UPDATE_LIVESTOCK_OUT_FARM_SPECIES,
    UPDATE_OM_AMOUNT,
    UPDATE_OM_BIOCHAR,
    UPDATE_OM_COMPOST,
    UPDATE_OM_MANURE,
    UPDATE_OM_MULCH,
    UPDATE_OM_OTHER,
    UPDATE_OM_SLURRY,
    UPDATE_OM_SUBTYPE,
    UPDATE_PARCEL_AREA,
    UPDATE_PARCEL_NAME,
    UPDATE_PASTURES,
    UPDATE_PERENNIAL_TREE_AND_CROPS,
    UPDATE_PRIMARY_LAND_USE,
    UPDATE_PRODUCTIVITY_DATA_OF_PASTURES,
    UPDATE_RESIDUE_DATA_OF_ANNUAL_CROPS,
    UPDATE_RESIDUE_DATA_OF_FALLOW,
    UPDATE_RESIDUE_DATA_OF_PERENNIAL_TREE_AND_CROPS,
    UPDATE_SECONDARY_LAND_USE,
    UPDATE_SHEEP_AMOUNT,
    UPDATE_SPECIES_AND_RESET_ANNUAL_CROPS,
    UPDATE_SPECIES_AND_RESET_PERENNIAL_TREE_AND_CROPS,
    UPDATE_SWINE_AMOUNT,
    UPDATE_TILLING_EVENT,
    UPDATE_TURKEYS_AMOUNT
} from "./types";


export const createEmptyMonitoringData = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: CREATE_EMPTY_MONITORING_DATA,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};


export const getMonitoringData = () => async (dispatch) => {
    try {
        const res = dispatch({
            type: GET_MONITORING_DATA
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const setCurMonitoringData = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: SET_CUR_MONITORING_DATA,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updateCurrentProjectStartYear = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_EMPTY_CUR_PROJECT_START_YEAR,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updateCurrentYearlyFarmData = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_CUR_YEARLY_FARM_DATA,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const addCreatedParcelsToYearlyFarmData = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: ADD_PARCELS_TO_YEARS,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const addNewEmptyPerennialTreeAndCropData = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: ADD_EMPTY_PERENNIAL_TREE_AND_CROPS,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const deletePerennialTreeAndCropData = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: DELETE_PERENNIAL_TREE_AND_CROPS,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updatePerennialTreeAndCropData = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_PERENNIAL_TREE_AND_CROPS,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const getPerennialTreeAndCropDataByYear = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: GET_PERENNIAL_TREE_AND_CROPS_BY_YEAR,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};


export const getPerennialTreeAndCropDataByParcelID = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: GET_PERENNIAL_TREE_AND_CROPS_BY_PARCELID,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const addNewEmptyHarvestToPerennialTreeAndCropData = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: ADD_EMPTY_HARVEST_TO_PERENNIAL_TREE_AND_CROPS,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const addNewEmptyResidueToPerennialTreeAndCropData = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: ADD_EMPTY_RESIDUE_TO_PERENNIAL_TREE_AND_CROPS,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};


export const updateResidueDataOfPerennialTreeAndCropData = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_RESIDUE_DATA_OF_PERENNIAL_TREE_AND_CROPS,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updateHarvestDataOfPerennialTreeAndCropData = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_HARVEST_DATA_OF_PERENNIAL_TREE_AND_CROPS,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};



export const updateSpeciesAndResetPerennialTreeAndCropData = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_SPECIES_AND_RESET_PERENNIAL_TREE_AND_CROPS,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updateSpeciesAndResetAnnualCropData = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_SPECIES_AND_RESET_ANNUAL_CROPS,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updatePasturesAndResetGrassesProductivityAndHarvestedData = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: RESET_PASTURES_AND_GRASSES_PRODUCTIVITY_HARVESTED,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updateAndResetFallowData = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: RESET_FALLOW_WHEN_FALLOW_CHANGES,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const addNewEmptyAnnualCropData = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: ADD_EMPTY_ANNUAL_CROPS,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const deleteAnnualCropData = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: DELETE_ANNUAL_CROPS,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updateAnnualCropData = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_ANNUAL_CROPS,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const getAnnualCropDataByYear = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: GET_ANNUAL_CROPS_BY_YEAR,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};


export const getAnnualCropDataByParcelID = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: GET_ANNUAL_CROPS_BY_PARCELID,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const addNewEmptyHarvestToAnnualCropData = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: ADD_EMPTY_HARVEST_TO_ANNUAL_CROPS,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const addNewEmptyResidueToAnnualCropData = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: ADD_EMPTY_RESIDUE_TO_ANNUAL_CROPS,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};


export const updateResidueDataOfAnnualCropData = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_RESIDUE_DATA_OF_ANNUAL_CROPS,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updateHarvestDataOfAnnualCropData = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_HARVEST_DATA_OF_ANNUAL_CROPS,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};




export const addNewEmptyFallowData = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: ADD_EMPTY_FALLOW,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const deleteFallowData = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: DELETE_FALLOW,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updateFallowData = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_FALLOW,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const getFallowDataByYear = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: GET_FALLOW_BY_YEAR,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};


export const getFallowDataByParcelID = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: GET_FALLOW_BY_PARCELID,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const addNewEmptyHarvestToFallowData = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: ADD_EMPTY_HARVEST_TO_FALLOW,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const addNewEmptyResidueToFallowData = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: ADD_EMPTY_RESIDUE_TO_FALLOW,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};


export const updateResidueDataOfFallowData = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_RESIDUE_DATA_OF_FALLOW,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updateHarvestDataOfFallowData = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_HARVEST_DATA_OF_FALLOW,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};




export const addNewEmptyPasturesData = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: ADD_EMPTY_PASTURES,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const deletePasturesData = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: DELETE_PASTURES,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updatePasturesData = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_PASTURES,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const getPasturesDataByYear = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: GET_PASTURES_BY_YEAR,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};


export const getPasturesDataByParcelID = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: GET_PASTURES_BY_PARCELID,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const addNewEmptyHarvestToPasturesData = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: ADD_EMPTY_HARVEST_TO_PASTURES,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const addNewEmptyProductivityToPasturesData = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: ADD_EMPTY_PRODUCTIVITY_TO_PASTURES,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};


export const updateProductivityDataOfPasturesData = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_PRODUCTIVITY_DATA_OF_PASTURES,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updateHarvestDataOfPasturesData = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_HARVEST_DATA_OF_PASTURES,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};


export const updatePrimaryLandUse = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_PRIMARY_LAND_USE,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updateSecondaryLandUse = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_SECONDARY_LAND_USE,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updateAnimalFodderByYear = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_ANIMAL_FODDER_BY_YEAR,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updateGrazingMonthsByYear = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_GRAZING_MONTHS,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const addEmptyTillingEvent = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: ADD_EMPTY_TILLING_EVENT,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updateTillingEvent = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_TILLING_EVENT,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

/*export const resetTillingEvent = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: RESET_TILLING_EVENT,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};*/

export const updateIrrigation = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_IRRIGATION,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const addEmptyFelledTrees = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: ADD_EMPTY_FELLED_TREES,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updateFelledTrees = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_FELLED_TREES,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const addEmptyFertilizerUsage = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: ADD_EMPTY_FERTILIZER_USAGE,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updateFertilizerUsage = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_FERTILIZER_USAGE,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const addEmptyImportedOrganicMatter = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: ADD_EMPTY_IMPORTED_ORGANIC_MATTER,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updateImportedOrganicMatter = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_IMPORTED_ORGANIC_MATTER,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updateAddedOMBiochar = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_OM_BIOCHAR,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const addNewEmptyOmCompost = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: ADD_EMPTY_OM_COMPOST,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updateOmCompost = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_OM_COMPOST,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const addNewEmptyOmManure = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: ADD_EMPTY_OM_MANURE,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updateOmManure = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_OM_MANURE,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const addNewEmptyOmMulch = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: ADD_EMPTY_OM_MULCH,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updateOmMulch = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_OM_MULCH,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const addNewEmptyOmSlurry = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: ADD_EMPTY_OM_SLURRY,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updateOmSlurry = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_OM_SLURRY,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const addNewEmptyOmOther = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: ADD_EMPTY_OM_OTHER,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updateOmOther = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_OM_OTHER,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const addNewEmptyOm = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: ADD_EMPTY_OM,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updateOMSubType = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_OM_SUBTYPE,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updateOMAmount = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_OM_AMOUNT,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const copyDataBetweenParcels = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: COPY_DATA_BETWEEN_PARCELS,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const copyDataBetweenParcelsForLandUse = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: COPY_DATA_BETWEEN_PARCELS_FOR_LAND_USE,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updateParcelName = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_PARCEL_NAME,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updateParcelArea = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_PARCEL_AREA,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const deleteParcel = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: DELETE_PARCEL,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updateLivestock = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_LIVESTOCK,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updateGrazingManagement = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_GRAZING_MANAGEMENT,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updateCattleAmount = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_CATTLE_AMOUNT,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};
export const updateDairyCowsAmount = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_DAIRY_COWS_AMOUNT,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};
export const updateSwineAmount = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_SWINE_AMOUNT,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};
export const updateSheepAmount = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_SHEEP_AMOUNT,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};
export const updateGoatsAmount = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_GOATS_AMOUNT,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};
export const updateChickenAmount = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_CHICKEN_AMOUNT,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};
export const updateTurkeysAmount = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_TURKEYS_AMOUNT,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updateDucksAmount = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_DUCKS_AMOUNT,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updateLivestockGrazingDays = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_LIVESTOCK_GRAZING_DAYS,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updateLivestockManure = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_LIVESTOCK_MANURE,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updateLivestockFertility = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_LIVESTOCK_FERTILITY,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updateLivestockOutFarmSpecies = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_LIVESTOCK_OUT_FARM_SPECIES,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updateLivestockOutFarmNumberOfAnimals = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_LIVESTOCK_OUT_FARM_NUMBER_OF_ANIMALS,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updateLivestockOutFarmGrazingDays = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_LIVESTOCK_OUT_FARM_GRAZING_DAYS,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const addSpeciesLivestockOutFarm = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: UPDATE_LIVESTOCK_OUT_FARM_ADD_SPECIES,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const deleteSpeciesLivestockOutFarm = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: DELETE_LIVESTOCK_OUT_FARM_ADD_SPECIES,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const resetCurrentMonitoringData = () => async (dispatch) => {
    try {
        const res = dispatch({
            type: RESET_CURRENT_MONITORING_DATA
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

