import { SET_ALL_MONITORING_YEARS } from "./types";

export const setAllMonitoringYears = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: SET_ALL_MONITORING_YEARS,
            payload: data
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};