import { CONVERT_KMZ_TO_KML, CREATE_FARM_VERIFICATION_DOCUMENTS, GET_FARM_VERIFICATION_DOCUMENTS, GET_FARM_VERIFICATION_DOCUMENTS_BY_EMAIL, RESET_FARM_VERIFICATION_DOCUMENTS, SET_FARM_VERIFICATION_DOCUMENTS, UPDATE_FARM_VERIFICATION_DOCUMENTS } from "../actions/types";
import FarmVerificationDocumentsDataService from "../farmservices/VerificationDocumentsUploadService";

export const createFarmVerificationDocuments = (data) => async (dispatch) => {
    try {
        const res = await FarmVerificationDocumentsDataService.create(data);
        dispatch({
            type: CREATE_FARM_VERIFICATION_DOCUMENTS,
            payload: res.data
        });
        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

// get from S3 bucket
export const getFarmVerificationDocuments = (data) => async (dispatch) => {

    try {
        const res = await FarmVerificationDocumentsDataService.get(data);
        dispatch({
            type: GET_FARM_VERIFICATION_DOCUMENTS,
            payload: res.data,
        });
        return Promise.resolve(res);
    } catch (error) {
        console.log(error);
    }
};

export const updateFarmVerificationDocuments = (id, data) => async (dispatch) => {
    try {
        const res = await FarmVerificationDocumentsDataService.update(id, data);
        dispatch({
            type: UPDATE_FARM_VERIFICATION_DOCUMENTS,
            payload: data,
        });
        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const getFarmVerificationDocumentsByEmail = (email) => async (dispatch) => {
    try {
        const res = await FarmVerificationDocumentsDataService.findByFarmerEmail(email);
        dispatch({
            type: GET_FARM_VERIFICATION_DOCUMENTS_BY_EMAIL,
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const setFarmVerificationDocuments = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: SET_FARM_VERIFICATION_DOCUMENTS,
            payload: data,
        });
        return Promise.resolve(res);
    } catch (error) {
        console.log(error);
    }
};

export const convertKmzToKMlFileAndSave = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: CONVERT_KMZ_TO_KML,
            payload: data,
        });
        return Promise.resolve(res);
    } catch (error) {
        console.log(error);
    }
};

export const resetFarmVerificationDocuments = () => async (dispatch) => {
    try {
        const res = dispatch({
            type: RESET_FARM_VERIFICATION_DOCUMENTS
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

