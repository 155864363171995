import { CREATE_ACCOUNT_SETTINGS, RETRIEVE_ACCOUNT_SETTINGS, UPDATE_ACCOUNT_SETTINGS, DELETE_ACCOUNT_SETTINGS } from "../actions/types";
const initialState = [];

function accountSettingsReducer(accountSettings = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case CREATE_ACCOUNT_SETTINGS:
            return [...accountSettings, payload];
        case RETRIEVE_ACCOUNT_SETTINGS:
            return payload;
        case UPDATE_ACCOUNT_SETTINGS:
            return accountSettings.map((setting) => {
                if (setting.id === payload.id) {
                    return {
                        ...setting, ...payload
                    };
                } else {
                    return setting;
                }
            });
        case DELETE_ACCOUNT_SETTINGS:
            return accountSettings.filter(({
                id
            }) => id !== payload.id);
        default:
            return accountSettings;
    }
};

export default accountSettingsReducer;