import { applyMiddleware } from "redux";
import axiosMiddleware from "redux-axios-middleware";
import thunk from "redux-thunk";
import rootReducer from "../reducers";
import HttpService from "./HttpService";
import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'
import localforage from 'localforage';
const initialState = {};
const persistConfig = {
  key: 'root',
  storage: localforage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const setup = () => {
  return configureStore({
    reducer: persistedReducer,
    preloadedState: initialState,
    middleware: [thunk, axiosMiddleware(HttpService.getAxiosClient())],
  });
};

const StoreService = {
  setup,
};

export default StoreService;
