import { Button, TextField } from "@mui/material";
import { styled } from '@mui/material/styles';

export const SubmitButton = styled(Button)({
  color: '#ffff',
  borderRadius: '8px',
  height: '36px',
  textTransform: 'none',
  fontSize: 14,
  fontWeight: 600,
  padding: '6px 20px',
  backgroundColor: '#409781',
  '&:hover': {
    backgroundColor: '#80C3AB',
  },
  '&:active': {
    backgroundColor: '#80C3AB',
    color: '#ffff'
  },
  '&:focus': {
    backgroundColor: '#80C3AB',
    color: '#ffff'
  }
});

export const NoButton = styled(Button)({
  color: '#000000',
  borderRadius: '8px',
  height: '36px',
  textTransform: 'none',
  fontSize: 14,
  fontWeight: 600,
  padding: '6px 30px',
  backgroundColor: '#DBDBDB',
  '&:hover': {
    color: '#ffff',
    backgroundColor: '#21272C',
  },
  '&:active': {
    color: '#ffff',
    backgroundColor: '#21272C',
  },
  '&:focus': {
    color: '#ffff',
    backgroundColor: '#21272C',
  }
});


export const BaselineYears = styled(Button)({
  color: '#000000',
  borderRadius: '0px',
  height: '36px',
  textTransform: 'none',
  fontSize: 14,
  fontWeight: 600,
  padding: '6px 30px',
  backgroundColor: '#e6e6e6',
  '&:hover': {
    color: '#000000',
    backgroundColor: '#e6e6e6',
    cursor: 'default'
  },
});

export const ProjectYears = styled(Button)({
  color: '#000000',
  borderRadius: '0px',
  height: '36px',
  textTransform: 'none',
  fontSize: 14,
  fontWeight: 600,
  padding: '6px 30px',
  backgroundColor: '#808080',
  '&:hover': {
    color: '#000000',
    backgroundColor: '#808080',
    cursor: 'default'
  },
});

export const UploadedDocument = styled(TextField)({
  '& .MuiInputLabel-outlined.Mui-disabled': {
    color: '#000000',
    textDecoration: 'underline',
    display: 'flex'
  },
  border: 'none',
  maxHeight: "50px",
  width: "250px",
  '& fieldset': {
    border: 'none'
  },
  input: {
    '&:disabled': {
      backgroundColor: '#e6e6e6',
      borderRadius: '10px',
    },
  }
});

export const GoBackButton = styled(Button)({
  color: '#000000',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '0px',
  textTransform: 'none',
  textDecoration: 'none',
  fontSize: 16,
  padding: '6px 28px 6px 20px',
  backgroundColor: '#ffff',
  '&:hover': {
    backgroundColor: '#ffff',
  },
  '&:active': {
    backgroundColor: '#ffff',
  },
  '&:focus': {
    backgroundColor: '#ffff',
  }
});