import { CREATE_FARM_SETTINGS, RETRIEVE_FARM_SETTINGS, UPDATE_FARM_SETTINGS, DELETE_FARM_SETTINGS } from "./types";
import FarmSettingsDataService from "../farmservices/FarmSettingsDataService";

export const createFarmSettings = (data) => async (dispatch) => {
    try {
        
        const res = await FarmSettingsDataService.create(data);
        dispatch({
            type: CREATE_FARM_SETTINGS,
            payload: res.data

        });
        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const retrieveFarmSettings = () => async (dispatch) => {
    try {
        const res = await FarmSettingsDataService.getAll();
        dispatch({
            type: RETRIEVE_FARM_SETTINGS,
            payload: res.data,
        });
        return Promise.resolve(res);
    } catch (error) {
        console.log(error);
    }
};

export const updateFarmSettings = (id, data) => async (dispatch) => {
    try {
        const res = await FarmSettingsDataService.update(id, data);
        dispatch({
            type: UPDATE_FARM_SETTINGS,
            payload: data,
        });
        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const deleteFarmSettings= (id) => async (dispatch) => {
    try {
        await FarmSettingsDataService.remove(id);
        dispatch({
            type: DELETE_FARM_SETTINGS,
            payload: {
                id
            },
        });
    } catch (error) {
        console.log(error);
    }
};


export const findSettingsByFarmerPrimaryId = (id) => async(dispatch) => {
    try {
        const res = await FarmSettingsDataService.findByFarmPrimaryId(id);
        dispatch({
            type: RETRIEVE_FARM_SETTINGS,
            payload: res.data,
        });
        return res.data;
    } catch (error) {
        console.log(error);
    }
}