import { createTheme } from '@mui/material/styles';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import AppRoutes from './AppRoutes';
import palette from './muiTheme/palette';
import typography from './muiTheme/typography';
import StoreService from "./services/StoreService";
import { translationDE } from "./translations/de";
import { translationEN } from "./translations/en";
import { translationES } from "./translations/es";
import { translationPT } from "./translations/pt";

const store = StoreService.setup();
let persistor = persistStore(store);

export const theme = createTheme({
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  palette,
  typography
});

function App() {

  i18n.use(initReactI18next).init({
    resources: {
      en_EN: {
        translation: translationEN,
      },
      pt_PT: {
        translation: translationPT,
      },
      es_ES: {
        translation: translationES,
      },
      de_DE: {
        translation: translationDE,
      },
    },
    lng: "en_EN",
    fallbackLng: "en_EN",
    interpolation: { escapeValue: false },
  });

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
