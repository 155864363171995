import { CREATE_FARMER_ACCEPT_CONDITIONS, RETRIEVE_FARMER_ACCEPT_CONDITIONS, UPDATE_FARMER_ACCEPT_CONDITIONS, DELETE_FARMER_ACCEPT_CONDITIONS } from "./types";
import FarmerAcceptConditionsDataService from "../farmservices/FarmerAcceptConditionsDataService";

export const createFarmerAcceptConditions = (data) => async (dispatch) => {
    try {
        
        const res = await FarmerAcceptConditionsDataService.createFarmerConditions(data);
        dispatch({
            type: CREATE_FARMER_ACCEPT_CONDITIONS,
            payload: res.data

        });
        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};


export const updateFarmerAcceptConditions = (id, data) => async (dispatch) => {
    try {
        const res = await FarmerAcceptConditionsDataService.updateFarmerConditions(id, data);
        dispatch({
            type: UPDATE_FARMER_ACCEPT_CONDITIONS,
            payload: data,
        });
        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const deleteFarmerAcceptConditions = (id) => async (dispatch) => {
    try {
        await FarmerAcceptConditionsDataService.removeFarmerConditions(id);
        dispatch({
            type: DELETE_FARMER_ACCEPT_CONDITIONS,
            payload: {
                id
            },
        });
    } catch (error) {
        console.log(error);
    }
};

export const findFarmerConditionsByEmail = (email) => async(dispatch) => {
    try {
        const res = await FarmerAcceptConditionsDataService.findByFarmerAcceptConditionsEmail(email)
        dispatch({
            type: RETRIEVE_FARMER_ACCEPT_CONDITIONS,
            payload: res.data,
        });
        return res.data;
    } catch (error) {
        console.log(error);
    }
};