
import { Tooltip } from "@mui/material";
import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { theme } from "../../../App";
import { dashedCircle } from "../../../assets/svgs";
import { emptyNumericFieldErrorZero } from "./fieldValueErrors";
import { interdependencyValidation, isAllFilled, isSomeFilled } from "./validateValues";


const SubmitValidation = ({ page, valid, setValid, parcelId, infoText }) => {
    const { t } = useTranslation();

    const currentMonitoringDataObject = useSelector(
        (state) => state.currentMonitoringDataReducer
    );
    const currentFarmDataObject = useSelector(
        (state) => state.currentFarmDataReducer
    );

    const currentMonitoringYearsObject = useSelector(state => state.monitoringYearsTrackerReducer);
    const allMonitoringYears = currentMonitoringYearsObject.allMonitoringYears
    const getYearsArray = (endYear) => {
        const years = [];
        for (let year = 2018; year < endYear; year++) {
            years.push(year);
        }
        return years;
    }
    const currentYear = new Date().getFullYear();
    const allDBYears = getYearsArray(currentYear);

    const yearlyData = currentMonitoringDataObject?.yearlyFarmData

    const isFarmInfoFilled = currentMonitoringDataObject?.projectStartYear
        && currentFarmDataObject?.farmInfo?.farmName
        && currentFarmDataObject?.farmInfo?.farmSize


    const isFuelFilled = () => {
        let fuelAmounts = []
        allDBYears.forEach((year, i) => {
            const yearlyFuel = yearlyData?.[i]?.fuelUsage?.direct
            fuelAmounts.push(allMonitoringYears.includes(year) ?
                { diesel: yearlyFuel?.diesel?.amount, petrol: yearlyFuel?.petrol?.amount } : { diesel: 0, petrol: 0 })
        });
        const isFilled = isAllFilled(fuelAmounts)
        return isFilled
    }

    const isLivestockFilled = () => {
        if (!yearlyData || !yearlyData[0]) {
            return true
        }
        let livestockAmounts = []
        const noFertilityArray = ['turkeys', 'chicken', 'ducks']
        let allYearlyAnimalAmount = [[], [], [], [], [], [], [], []]

        allDBYears.forEach((year, i) => {
            const yearlyLivestock = yearlyData?.[i]?.livestock
            yearlyLivestock?.inFarm.map((animal, j) => allYearlyAnimalAmount[j].push(animal.category.map((cat) => cat.amount)))

        });


        allDBYears.forEach((year, yearIndex) => {
            const yearlyLivestock = yearlyData?.[yearIndex]?.livestock
            let isYearAnyAnimal = []

            // only check grazing managment if there are animals that year
            // livestockAmounts.push(allMonitoringYears.includes(year) ?
            //     { grazingManagement: yearlyLivestock?.grazingManagement } : { grazingManagement: 0 })
            livestockAmounts.push(allMonitoringYears.includes(year) ?
                yearlyLivestock?.inFarm.map((animal, animalIndex) => {

                    const allCategoriesYearlyAnimal = allYearlyAnimalAmount[animalIndex][yearIndex]
                    const isYearAnimal = allCategoriesYearlyAnimal?.some(num => !emptyNumericFieldErrorZero(num))
                    isYearAnyAnimal.push(isYearAnimal)

                    return {
                        grazingDays: isYearAnimal ? animal?.grazingDays : 0,
                        manureTreatment: isYearAnimal ? animal?.manureTreatment : 0,
                        fertilityRate: noFertilityArray.includes(animal?.species) || !isYearAnimal ? 0 : animal?.fertilityRate
                    }
                }) : { animalInfo: 0 },
                isYearAnyAnimal.includes(true) ? { grazingManagement: yearlyLivestock?.grazingManagement } : { grazingManagement: 0 }
            )
        });
        const flattenedLivestock = livestockAmounts.flat()
        const isFilled = isAllFilled(flattenedLivestock, true)
        return isFilled
    }


    const isFarmWideFilled = () => {
        let farmWideAmounts = []
        allDBYears.forEach((year, i) => {
            farmWideAmounts.push(allMonitoringYears.includes(year) ?
                { amount: yearlyData?.[i]?.fertilizerUsage[0]?.amount, percent: yearlyData?.[i].fertilizerUsage[0]?.percentN } : { amount: 0, percent: 0 })
            farmWideAmounts.push(allMonitoringYears.includes(year) ? yearlyData?.[i].importedOrganicMatter?.map(matter => { return { [matter.type]: matter.percentImported } }) : { nonMonitored: 0 })
        });
        const flattenedfarmWideAmounts = farmWideAmounts.flat()
        const isFilled = isAllFilled(flattenedfarmWideAmounts)
        return isFilled
    }


    const isParcelFilled = (pId) => {
        let parcelCropsData = []
        let parcelGeneralData = []
        allDBYears.forEach((year, i) => {
            const yearlyParcelInfo = yearlyData?.[i]?.parcelLevelData.filter(parcel => parcel.id === pId)[0]?.yearParcelData
            parcelCropsData.push(allMonitoringYears.includes(year) ?
                isSomeFilled([[yearlyParcelInfo?.annualCrops.map(crop => crop.species),
                yearlyParcelInfo?.fallow.map(fallow => fallow.type),
                yearlyParcelInfo?.perennialCrops.map(crop => crop.species),
                yearlyParcelInfo?.pastures.map(pasture => pasture.percentArea)].flat()])  //all main crops fields of a parcel in an array
                :
                true)
            parcelGeneralData.push(allMonitoringYears.includes(year) ?
                { primaryLandUse: yearlyParcelInfo?.primaryLandUse, irrigation: yearlyParcelInfo?.irrigation } :
                { primaryLandUse: 0, irrigation: 0 })
                ;
        })
        const flattenedGenralInfo = parcelGeneralData.flat()
        const isFilled = isAllFilled(flattenedGenralInfo)
        return isFilled && parcelCropsData?.every(Boolean)
    }

    const isParcelInterdependentComplete = (pId) => {
        let isAllYearsFilled = []
        allDBYears.forEach((year, i) => {
            const parcelData = yearlyData?.[i]?.parcelLevelData.filter(parcel => parcel.id === pId)[0]?.yearParcelData

            if (allMonitoringYears.includes(year)) {
                const perennialCrops = parcelData?.perennialCrops.map(tree => {
                    if (tree?.species?.includes('tree')) {
                        return {
                            species: tree?.species,
                            other: tree?.species?.includes('other') ? tree?.other : tree?.species !== '-' ? 'No other' : '',
                            plantingYear: tree?.plantingYear,
                            treeNumber: tree?.treeNumber,
                            DBHmean: tree?.DBHmean.diameter,
                        };
                    } else {
                        return {
                            species: tree?.species,
                            other: tree?.species?.includes('other') ? tree?.other : !tree?.species || tree?.species === '-' ? '' : 'No other',
                        };
                    }
                });
                const annualCrops = parcelData?.annualCrops.map(crop => {
                    return {
                        species: crop?.species,
                        other: crop?.species?.includes('other') ? crop?.other : !crop?.species || crop?.species === '-' ? '' : 'No other',
                        sowingDate: crop?.sowingDate,
                        harvestDate: crop?.harvestDate,
                    };
                });
                const pastures = parcelData?.pastures.map(pasture => {
                    return {
                        percentArea: pasture?.percentArea === 0 ? '' : pasture?.percentArea,  // 0 is accepted as a value in all other cases
                        productivityAmount: !pasture?.percentArea ? '' : pasture?.productivity[0].amount
                    }
                })
                const fallow = parcelData?.fallow.map(fallow => {
                    return {
                        type: fallow?.type,
                        percentArea: fallow?.type === 'productive fallow' ? 'No area' : fallow?.percentArea,
                        startDate: fallow?.startDate,
                        endDate: fallow?.endDate,
                    }
                })
                const toCheckArray = [perennialCrops, annualCrops, pastures, fallow]  //  fallow
                let isAllFilledForCrops = [[], [], [], []]

                toCheckArray.forEach((parcelDataSegment, index) => {
                    const parcelDataSegmentLength = parcelDataSegment?.length
                    for (let i = 0; i < parcelDataSegmentLength; i++) {
                        // check for each rows if they are valid (all filled or all empty)
                        isAllFilledForCrops[index].push(interdependencyValidation([parcelDataSegment[i]]))
                    }
                })
                //are all sections complete (full or empty)
                const isAllComplete = isAllFilledForCrops.flat().includes(false)
                isAllYearsFilled.push(!isAllComplete)
            }
        })
        return !isAllYearsFilled.flat().includes(false)
    }

    const isAllParcelFilled = () => {
        const allParcelIDs = yearlyData[5]?.parcelLevelData.map(parcel => parcel.id)
        const isEachParcelFilled = allParcelIDs?.map(id => isParcelInterdependentComplete(id) && isParcelFilled(id))
        return isEachParcelFilled?.every(Boolean)
    }

    const isGeneralInfoFilled = (pId) => {
        let generalInfo = []
        allDBYears.forEach((year, i) => {
            const parcelData = yearlyData?.[i]?.parcelLevelData.filter(parcel => parcel.id === pId)[0]?.yearParcelData

            generalInfo.push(allMonitoringYears.includes(year) ?
                { primaryLandUse: parcelData?.primaryLandUse, irrigation: parcelData?.irrigation } :
                { primaryLandUse: 0, irrigation: 0 })
        });
        const flattenedGenralInfo = generalInfo.flat()
        const isFilled = isAllFilled(flattenedGenralInfo)
        return isFilled
    }
    const isCropsFilled = (pId) => {
        let cropsData = []
        allDBYears.forEach((year, i) => {
            const parcelData = yearlyData?.[i]?.parcelLevelData.filter(parcel => parcel.id === pId)[0]?.yearParcelData
            cropsData.push(allMonitoringYears.includes(year) ?
                isSomeFilled([[parcelData?.annualCrops[0]?.species,
                parcelData?.fallow[0]?.type,
                parcelData?.perennialCrops[0]?.species,
                parcelData?.pastures[0]?.percentArea]])   //all main crops fields of a parcel in an array
                :
                true)
        });
        return cropsData?.every(Boolean)
    }


    const validatePage = (page, isValid) => {
        setValid(prevState => ({
            ...prevState,
            [page]: isValid
        }));
    };
    const handlePageValidate = (page) => {
        switch (page) {
            case "farminfo":
                validatePage("farminfo", !!isFarmInfoFilled);
                break;
            case "ver_doc":
                validatePage("ver_doc", true);
                break;
            case "fuel":
                validatePage("fuel", isFuelFilled());
                break;
            case "livestock":
                validatePage("livestock", isLivestockFilled());
                break;
            case "farmwide":
                validatePage("farmwide", isFarmWideFilled());
                break;
            case "parcel":
                validatePage("parcel", isParcelInterdependentComplete(parcelId) && isParcelFilled(parcelId));
                break;
            case "landuse":
                validatePage("landuse", isAllParcelFilled() && isFarmWideFilled());
                break;
            case "general":
                validatePage("general", isGeneralInfoFilled(parcelId));
                break;
            case "crops":
                validatePage("crops", isCropsFilled(parcelId) && isParcelInterdependentComplete(parcelId));
                break;
            default:
                console.log("Invalid page");
        }
    };

    useEffect(() => { handlePageValidate(page) }, [currentFarmDataObject, yearlyData])


    return <Tooltip title={<Trans i18nKey={infoText} />}>
        <p style={{
            width: '35px', marginRight: '4px'
        }} >
            {valid ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
                < circle cx="128" cy="128" r="128" fill={theme.palette.primary.main} />
            </svg > : dashedCircle
            }         </p >
    </Tooltip>;
};

export default SubmitValidation;
