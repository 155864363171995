import { Dialog, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { copyDataBetweenParcels, copyDataBetweenParcelsForLandUse } from "../../actions/currentMonitoringData";
import { SubmitButton } from "../../styles";

const CopyFromOtherYear = ({ page, parcelid, year, openCopyDataParcelsDialog, setOpenCopyDataParcelsDialog, setShowLoader, setSaveCurrentDataFlag, setSnackbarMessage, setState }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const currentMonitoringYearsObject = useSelector(state => state.monitoringYearsTrackerReducer);
    const currentMonitoringDataObject = useSelector(state => state.currentMonitoringDataReducer);


    const [copyFromParcelDataYearValue, setCopyFromParcelDataYearValue] = useState("")
    const [copyFromParcelIDValue, setCopyFromParcelIDValue] = useState("");
    const [copyToParcelDataYearValue, setCopyToParcelDataYearValue] = useState(year)
    const [copyToParcelIDValue, setCopyToParcelIDValue] = useState(parcelid);
    const [overwriteDataDialogOpen, setOverwriteDataDialogOpen] = useState(false)

    useEffect(() => { setCopyToParcelDataYearValue(year) }, [year])

    const handleCopyDataDialogClose = () => {
        setOpenCopyDataParcelsDialog(false);
    }

    const handleCopyDataDialogSave = () => {
        setOverwriteDataDialogOpen(true)
    }

    const handleOverwriteDataDialogClose = () => {
        setOverwriteDataDialogOpen(false)
    }


    //Crops
    const handleOverwriteDataDialogContinue = () => {
        setShowLoader(true);
        setSaveCurrentDataFlag(true);
        setCopyToParcelIDValue(parcelid)
        let tmpData = {
            copyFromParcelID: copyFromParcelIDValue,
            copyFromYearValue: copyFromParcelDataYearValue,
            copyToParcelID: parcelid,
            copyToYearValue: copyToParcelDataYearValue
        };
        if (page === 'crops') {
            dispatch(copyDataBetweenParcels(tmpData))
                .then(res => {
                    console.log('Data copied between parcels successfully:', res);
                    setSnackbarMessage(t("farmland_draft_submission_msg_success"));
                    setState({ snackBarOpen: true, vertical: "top", horizontal: "center" });
                    setShowLoader(false);
                    setSaveCurrentDataFlag(false);
                })
                .catch(error => {
                    console.error('Error copying data between parcels:', error);
                    setSnackbarMessage('ERROR');
                    setShowLoader(false);
                });
        } else {
            dispatch(copyDataBetweenParcelsForLandUse(tmpData))
                .then(res => {
                    console.log('Data copied between parcels for land use successfully:', res);
                    setSnackbarMessage(t("farmland_draft_submission_msg_success"));
                    setState({ snackBarOpen: true, vertical: "top", horizontal: "center" });
                    setShowLoader(false);
                    setSaveCurrentDataFlag(false);
                })
                .catch(error => {
                    console.error('Error copying data between parcels for land use:', error);
                    setSnackbarMessage('ERROR');
                    setShowLoader(false);
                });
        }

        setOverwriteDataDialogOpen(false)
        setOpenCopyDataParcelsDialog(false)
    }

    const handleCopyToParcelYearChange = (e) => {
        setCopyToParcelDataYearValue(e.target.value)
        setCopyFromParcelDataYearValue(null);
    }

    const handleCopyFromParcelNameChange = (e) => {
        setCopyFromParcelIDValue(e.target.value)
        setCopyFromParcelDataYearValue(null)
        if (copyToParcelDataYearValue === "allyears") {
            setCopyToParcelDataYearValue(year);
        }
    }

    const handleCopyFromYearsChange = (e) => {
        setCopyFromParcelDataYearValue(e.target.value)
        if (e.target.value === "allyears") {
            setCopyToParcelDataYearValue("allyears")
        } else {
            if (copyToParcelDataYearValue === "allyears") {
                setCopyToParcelDataYearValue(year)
            }
        }
    }
    return <>   <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
            <Dialog
                PaperProps={{ sx: { maxWidth: 'revert', width: '700px' } }}
                open={openCopyDataParcelsDialog}
                onClose={handleCopyDataDialogClose}
                aria-labelledby="alert-dialog-confirm-popup"
                aria-describedby="alert-dialog-confirm-popup-description"
            >
                <DialogTitle id="alert-dialog-confirm-popup">
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={6} lg={6}>
                            <Typography variant="h2">{t("pgi_pre_fill_data_pop_up")}</Typography>

                        </Grid>
                        <Grid style={{ alignContent: "right", textAlign: "right" }} item xs={6} md={6} lg={6}>
                            <span onClick={handleCopyDataDialogClose} style={{ marginRight: "3px", cursor: "pointer", fontWeight: "bold" }} >X</span>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="subtitle2">
                        {t("pgi_pre_fill_data_pop_up_desc")}
                    </Typography>
                </DialogContent>
                <Grid container margin={4} spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                        <span style={{ fontWeight: "bold" }}>{t("pgi_copy_to")}</span>
                    </Grid>
                    <Grid item xs={10} md={10} lg={10}>
                        <FormControl fullWidth>
                            <Select
                                name="copyToParcel"
                                value={parcelid}
                                size="small"
                                disabled={true}
                                labelId="copy-to-label"
                                id="copy-to-select"
                            >
                                <MenuItem key={parcelid} value={parcelid}> {currentMonitoringDataObject?.yearlyFarmData.find(yFD => yFD.year === year).parcelLevelData.find(eleParcelData => eleParcelData?.parcelFixedValues?.parcelID === parcelid)?.parcelFixedValues?.parcelName} {t("pgi_copy_data_to_parcel")}</MenuItem>
                            </Select >
                        </FormControl >
                    </Grid >
                    <Grid item xs={10} md={10} lg={10}>
                        <FormControl fullWidth>
                            <InputLabel size='small' id="copy-to-years-label">{t("pgi_copy_data_with_years_dropdown")}</InputLabel>
                            <Select
                                name="copy-to-years"
                                value={copyToParcelDataYearValue}
                                size="small"
                                disabled={copyToParcelDataYearValue === "allyears"}
                                labelId="copy-to-years-label"
                                id="copy-to-years-select"
                                label={t("pgi_copy_data_with_years_dropdown")}
                                onChange={(e) => handleCopyToParcelYearChange(e)}
                            >
                                {
                                    currentMonitoringYearsObject.allMonitoringYears.map(ele =>
                                        <MenuItem key={ele} value={ele}>{ele}</MenuItem>)
                                }
                                {copyFromParcelDataYearValue === "allyears" && <MenuItem key="allyears" value="allyears">{t("pgi_copy_data_all_years")}</MenuItem>}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={10} md={10} lg={10}>
                        <span style={{ fontWeight: "bold" }}>{t("pgi_copy_data_with_data")}</span>
                    </Grid>
                    <Grid item xs={10} md={10} lg={10}>
                        <FormControl fullWidth>
                            <Select
                                name="copyFromParcel"
                                size="small"
                                value={copyFromParcelIDValue}
                                labelId="copy-from-label"
                                id="copy-from-select"
                                onChange={(e) => handleCopyFromParcelNameChange(e)}
                            >
                                {currentMonitoringDataObject && currentMonitoringDataObject?.yearlyFarmData[currentMonitoringDataObject?.yearlyFarmData?.length - 2]?.parcelLevelData.map(parcelElement =>
                                    <MenuItem key={parcelElement?.parcelFixedValues?.parcelID} value={parcelElement?.parcelFixedValues?.parcelID}> {parcelElement?.parcelFixedValues?.parcelName}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={10} md={10} lg={10}>
                        <FormControl fullWidth>
                            <InputLabel size='small' id="copy-from-years-label">{t("pgi_copy_data_with_years_dropdown")}</InputLabel>
                            <Select
                                name="copy-from-years"
                                value={copyFromParcelDataYearValue}
                                disabled={!copyFromParcelIDValue}
                                size="small"
                                labelId="copy-from-years-label"
                                id="copy-from-years-select"
                                label={t("pgi_copy_data_with_years_dropdown")}
                                onChange={(e) => handleCopyFromYearsChange(e)}
                            >
                                {copyFromParcelIDValue === parcelid &&
                                    currentMonitoringYearsObject.allMonitoringYears.map(ele => Number(ele) !== Number(copyToParcelDataYearValue) &&

                                        <MenuItem key={ele} value={ele}>{ele}</MenuItem>)
                                }
                                {copyFromParcelIDValue !== parcelid &&
                                    currentMonitoringYearsObject.allMonitoringYears.map(ele =>

                                        <MenuItem key={ele} value={ele}>{ele}</MenuItem>)
                                }
                                {copyFromParcelIDValue !== parcelid && <MenuItem key="allyears" value="allyears">{t("pgi_copy_data_all_years")}</MenuItem>}
                            </Select>
                        </FormControl>

                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <SubmitButton fullWidth
                            disabled={!copyFromParcelIDValue ||
                                !copyFromParcelDataYearValue ||
                                !copyToParcelIDValue ||
                                !copyToParcelDataYearValue
                            }
                            style={{ maxWidth: '580px' }} disableElevation disableRipple onClick={handleCopyDataDialogSave}>{t("pci_copy_data_btn")}</SubmitButton>
                    </Grid>
                </Grid >
            </Dialog >
        </Grid >
    </Grid >
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Dialog
                    PaperProps={{ sx: { maxWidth: 'revert', width: '700px' } }}
                    open={overwriteDataDialogOpen}
                    onClose={handleOverwriteDataDialogClose}
                    aria-labelledby="alert-dialog-confirm-popup"
                    aria-describedby="alert-dialog-confirm-popup-description"
                >
                    <DialogTitle id="alert-dialog-confirm-popup">
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={6} lg={6}>
                                <span style={{ fontWeight: "bold" }}>{t("pgi_warning_overwrite_1")}</span>

                            </Grid>
                            <Grid style={{ alignContent: "right", textAlign: "right" }} item xs={6} md={6} lg={6}>
                                <span onClick={handleOverwriteDataDialogClose} style={{ marginRight: "3px", fontWeight: "bold", cursor: "pointer" }} >X</span>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent>
                        <div>
                            <span>
                                {t("pgi_warning_overwrite_2")}
                            </span>
                        </div>
                        <Grid container margin={2} spacing={2}>
                            <Grid item xs={12} md={12} lg={12}>
                                <SubmitButton fullWidth style={{ maxWidth: '580px' }} disableElevation disableRipple onClick={handleOverwriteDataDialogContinue} >{t("general_continue_button")}</SubmitButton>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <SubmitButton fullWidth style={{ maxWidth: '580px' }} disableElevation disableRipple onClick={handleOverwriteDataDialogClose} >{t("pgi_cancel_button")}</SubmitButton>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </Grid>
        </Grid> </>
}

export default CopyFromOtherYear