import HttpService from '../services/HttpService';


const createFarmerConditions = data => {
    return HttpService.getAxiosClient().post("/api/conditions", data);
};

const updateFarmerConditions = (id, data) => {
    return HttpService.getAxiosClient().patch(`/api/conditions/${id}`, data);
};

const removeFarmerConditions = id => {
    return HttpService.getAxiosClient().delete(`/api/conditions/${id}`);
};

const findByFarmerAcceptConditionsEmail = param => {
    return HttpService.getAxiosClient().get(`/api/conditions?email=${param}`);
};

const FarmerAcceptConditionsDataService = {
    createFarmerConditions,
    updateFarmerConditions,
    removeFarmerConditions,
    findByFarmerAcceptConditionsEmail
};

export default FarmerAcceptConditionsDataService;
