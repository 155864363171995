import { setFarmVerificationDocuments, updateFarmVerificationDocuments } from "../../../actions/verificationDocuments";
import { defaultValues } from "../../../constants/verificationDocsDefaultValues";
import VerificationDocumentsUploadService from "../../../farmservices/VerificationDocumentsUploadService";
import { getYearsArray } from "./allDBYears";

const allDBYears = getYearsArray()

// Main file change handler

export const handleFileChange = (
    e,
    subfolder,
    year,
    currentFarmDataObject,
    currentFarmVerificationDocuments,
    dispatch,
    setSaveCurrentDataFlag,
    setSnackbarMessage,
    setState,
    t
) => {
    setSaveCurrentDataFlag(true);

    let email = currentFarmDataObject.farmInfo.email.replace("@", "__");
    let file = e.target.files[0];

    let fileNameArr = e.target.files[0].name.split(".").map((ele) => {
        return ele.replace(/[^a-zA-Z0-9]/g, "");
    });

    let fileName = fileNameArr.join(".");

    const handleFileUpload = async (file, fileName, subfolder, email, year) => {
        const formData = new FormData();
        const server =
            process.env.REACT_APP_NODE_ENV === "production"
                ? "monitoring_round_2024_uploads"
                : "test";

        let tempData = {
            dateUploaded: Date.now().toString(),
            cfDocumentName: defaultValues[subfolder],
            folder: subfolder,
            fileName: fileName,
            documentType: "",
            s3URL: "",
            year: year,
            comment: "",
        };

        formData.append("subfolder", subfolder);
        if (year !== undefined && year !== "") {
            formData.append("year", year);
        }
        formData.append("email", email);
        formData.append("server", server);
        formData.append("file", file);

        try {
            const response = await VerificationDocumentsUploadService.upload(formData);
            tempData.s3URL = response.data.message;
            tempData.documentType = response.data.data.mimetype;

            const monitoringYear = allDBYears.indexOf(year);
            let tempObj = { ...currentFarmVerificationDocuments };

            switch (subfolder) {
                case "landOwnership":
                    tempObj.landOwnership.push(tempData);
                    break;
                case "kmlFiles":
                    tempObj.kmlFiles.push(tempData);
                    break;
                case "capDocument":
                    tempObj.yearlyVerificationDocuments[monitoringYear].capDocument.push(
                        tempData
                    );
                    break;
                case "livestockTotals":
                    tempObj.yearlyVerificationDocuments[monitoringYear].livestockTotals.push(
                        tempData
                    );
                    break;
                default:
                    break;
            }

            await dispatch(updateFarmVerificationDocuments(currentFarmVerificationDocuments._id, tempObj));
            setSnackbarMessage(t("uploading_document_msg_success"));
        } catch (e) {
            setSnackbarMessage(t("uploading_document_msg_failure"));
        } finally {
            setState({ open: true, vertical: "top", horizontal: "center" });
            setSaveCurrentDataFlag(false);
        }
    };

    if (year !== undefined && year !== "" && year !== null) {
        handleFileUpload(file, fileName, subfolder, email, year);
    } else {
        handleFileUpload(file, fileName, subfolder, email);
    }
};

export const handleDeleteFile = async (
    index,
    subfolder,
    fileName,
    year,
    currentFarmDataObject,
    currentFarmVerificationDocuments,
    dispatch,
    setSnackbarMessage,
    setSaveCurrentDataFlag,
    setState,
    t
) => {
    setSaveCurrentDataFlag(true);

    let server = '';
    let email = currentFarmDataObject.farmInfo.email.replace("@", "__");
    let tempObj = { ...currentFarmVerificationDocuments };
    const monitoringYear = allDBYears.indexOf(year);

    if (subfolder === "landOwnership") {
        server = tempObj.landOwnership[index].s3URL.split('/')[3];
        tempObj.landOwnership.splice(index, 1);
    }
    if (subfolder === "kmlFiles") {
        server = tempObj.kmlFiles[index].s3URL.split('/')[3];
        tempObj.kmlFiles.splice(index, 1);
    }
    if (subfolder === "capDocument") {
        for (let i = 0; i < tempObj.yearlyVerificationDocuments[monitoringYear].capDocument.length; i++) {
            if (tempObj.yearlyVerificationDocuments[monitoringYear].capDocument[i].fileName === fileName) {
                server = tempObj.yearlyVerificationDocuments[monitoringYear].capDocument[i].s3URL.split('/')[3];
                tempObj.yearlyVerificationDocuments[monitoringYear].capDocument.splice(i, 1);
            }
        }
    }
    if (subfolder === "livestockTotals") {
        for (let i = 0; i < tempObj.yearlyVerificationDocuments[monitoringYear].livestockTotals.length; i++) {
            if (tempObj.yearlyVerificationDocuments[monitoringYear].livestockTotals[i].fileName === fileName) {
                server = tempObj.yearlyVerificationDocuments[monitoringYear].livestockTotals[i].s3URL.split('/')[3];
                tempObj.yearlyVerificationDocuments[monitoringYear].livestockTotals.splice(i, 1);
            }
        }
    }
    if (subfolder === "dieselInvoices") {
        for (let i = 0; i < tempObj.yearlyVerificationDocuments[monitoringYear].dieselInvoices.length; i++) {
            if (tempObj.yearlyVerificationDocuments[monitoringYear].dieselInvoices[i].fileName === fileName) {
                server = tempObj.yearlyVerificationDocuments[monitoringYear].dieselInvoices[i].s3URL.split('/')[3];
                tempObj.yearlyVerificationDocuments[monitoringYear].dieselInvoices.splice(i, 1);
            }
        }
    }
    if (subfolder === "petrolInvoices") {
        for (let i = 0; i < tempObj.yearlyVerificationDocuments[monitoringYear].petrolInvoices.length; i++) {
            if (tempObj.yearlyVerificationDocuments[monitoringYear].petrolInvoices[i].fileName === fileName) {
                server = tempObj.yearlyVerificationDocuments[monitoringYear].petrolInvoices[i].s3URL.split('/')[3];
                tempObj.yearlyVerificationDocuments[monitoringYear].petrolInvoices.splice(i, 1);
            }
        }
    }

    if (subfolder === 'fertiliserInvoices') {
        for (let i = 0; i < tempObj.yearlyVerificationDocuments[monitoringYear].fertiliserInvoices.length; i++) {
            if (tempObj.yearlyVerificationDocuments[monitoringYear].fertiliserInvoices[i].fileName === fileName) {
                server = tempObj.yearlyVerificationDocuments[monitoringYear].fertiliserInvoices[i].s3URL.split('/')[3];
                tempObj.yearlyVerificationDocuments[monitoringYear].fertiliserInvoices.splice(i, 1)
            }
        };
    };
    if (subfolder === 'importedOrganicAmendmentsInvoices') {
        for (let i = 0; i < tempObj.yearlyVerificationDocuments[monitoringYear].importedOrganicAmendmentsInvoices.length; i++) {
            if (tempObj.yearlyVerificationDocuments[monitoringYear].importedOrganicAmendmentsInvoices[i].fileName === fileName) {
                server = tempObj.yearlyVerificationDocuments[monitoringYear].importedOrganicAmendmentsInvoices[i].s3URL.split('/')[3];
                tempObj.yearlyVerificationDocuments[monitoringYear].importedOrganicAmendmentsInvoices.splice(i, 1)
            }
        };
    };
    if (subfolder === 'animalFodderInvoices') {
        for (let i = 0; i < tempObj.yearlyVerificationDocuments[monitoringYear].animalFodderInvoices.length; i++) {
            if (tempObj.yearlyVerificationDocuments[monitoringYear].animalFodderInvoices[i].fileName === fileName) {
                server = tempObj.yearlyVerificationDocuments[monitoringYear].animalFodderInvoices[i].s3URL.split('/')[3];
                tempObj.yearlyVerificationDocuments[monitoringYear].animalFodderInvoices.splice(i, 1)
            }
        };

    };
    if (subfolder === 'seedsInvoices') {
        for (let i = 0; i < tempObj.yearlyVerificationDocuments[monitoringYear].seedsInvoices.length; i++) {
            if (tempObj.yearlyVerificationDocuments[monitoringYear].seedsInvoices[i].fileName === fileName) {
                server = tempObj.yearlyVerificationDocuments[monitoringYear].seedsInvoices[i].s3URL.split('/')[3];
                tempObj.yearlyVerificationDocuments[monitoringYear].seedsInvoices.splice(i, 1)
            }
        };
    };
    if (subfolder === "externalServicesInvoices") {
        for (
            let i = 0; i < tempObj.yearlyVerificationDocuments[monitoringYear].externalServicesInvoices.length; i++) {
            if (tempObj.yearlyVerificationDocuments[monitoringYear].externalServicesInvoices[i].fileName === fileName) {
                server = tempObj.yearlyVerificationDocuments[monitoringYear].externalServicesInvoices[i].s3URL.split('/')[3];
                tempObj.yearlyVerificationDocuments[monitoringYear].externalServicesInvoices.splice(i, 1);
            }
        }
    }

    const data = {
        subfolder: subfolder,
        year: year,
        server: server,
        filename: fileName,
        email: email,
        data: tempObj,
    };

    try {
        const response = await VerificationDocumentsUploadService.deleteFile(currentFarmVerificationDocuments._id, data);

        if (response.status === 200) {
            await dispatch(setFarmVerificationDocuments(response.data));
            setSnackbarMessage(t("deleting_document_msg_success"));
        } else {
            setSnackbarMessage(t("deleting_document_msg_failure"));
        }
    } catch (error) {
        console.error('Error deleting file:', error);
        setSnackbarMessage(t("deleting_document_msg_failure"));
    } finally {
        setState({ open: true, vertical: "top", horizontal: "center" });
        setSaveCurrentDataFlag(false);
    }
};


export const getVerificationDocumentURL = async (key) => {
    let tempArr = key.split("/");

    let tempObj = {
        server: "",
        email: "",
        subfolder: "",
        filename: "",
        year: "",
    };

    if (tempArr.length === 4) {
        tempObj.server = tempArr[0];
        tempObj.email = tempArr[1];
        tempObj.subfolder = tempArr[2];
        tempObj.filename = tempArr[3];
    } else if (tempArr.length === 5) {
        tempObj.server = tempArr[0];
        tempObj.email = tempArr[1];
        tempObj.subfolder = tempArr[2];
        tempObj.year = tempArr[3];
        tempObj.filename = tempArr[4];
    }

    try {
        const response = await VerificationDocumentsUploadService.get(tempObj);
        return response.data;
    } catch (error) {
        console.error("Error fetching verification document URL:", error);
        throw error;
    }
};

export const handleDownload = async (path) => {
    try {
        const key = path.split(
            "https://farms-verification-documents.s3.eu-central-1.amazonaws.com/"
        )[1];

        const downloadURL = await getVerificationDocumentURL(key);

        const link = document.createElement("a");
        link.href = downloadURL;
        link.download = key.split("/").pop(); // Set the filename for the download
        link.click();
    } catch (error) {
        console.error("Error downloading file:", error);
    }
};

