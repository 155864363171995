import React, { forwardRef } from 'react';


import '../../css/Dashboard.css';

import {
    Alert,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Paper,
    Slide,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import { SubmitButton } from '../../styles';


const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CarbonResultsDialog = ({ carbonResultsOpen, carbonResults, handleCarbonResultsDialogClose }) => {
    return (
        <Dialog open={carbonResultsOpen} fullWidth TransitionComponent={Transition} onClose={handleCarbonResultsDialogClose}>
            <DialogTitle>
                <Alert icon={false} severity="warning">
                    <div style={{ fontWeight: 'bold' }}>
                        <h2><span>Carbon Values</span></h2>
                    </div>
                </Alert>
            </DialogTitle>
            <DialogContent>
            </DialogContent>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    {!carbonResults && <div>
                        <span style={{ display: 'flex', justifyContent: 'center' }}>Results are not available yet</span>
                    </div>}
                    {carbonResults && <div style={{ marginLeft: '25px', marginRight: '25px' }}>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Alert severity='success' style={{ marginBottom: '10px' }}>
                                    Results have been generated in {carbonResults.resultsGenerationYear}
                                </Alert>
                            </Grid>
                        </Grid>

                        <TableContainer component={Paper}>
                            <Table aria-label="Carbon Results Table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ display: 'flex', justifyContent: 'center' }} >Year</TableCell>
                                        <TableCell align="left">Carbon (tons/hectare)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                    <TableRow>
                                        <TableCell style={{ display: 'flex', justifyContent: 'center' }} component="th" scope="row">
                                            Year 1
                                        </TableCell>
                                        <TableCell align="left">{carbonResults.yearlyCarbonResults[0]}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ display: 'flex', justifyContent: 'center' }} component="th" scope="row">
                                            Year 2
                                        </TableCell>
                                        <TableCell align="left">{carbonResults.yearlyCarbonResults[1]}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ display: 'flex', justifyContent: 'center' }} component="th" scope="row">
                                            Year 3
                                        </TableCell>
                                        <TableCell align="left">{carbonResults.yearlyCarbonResults[2]}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ display: 'flex', justifyContent: 'center' }} component="th" scope="row">
                                            Year 4
                                        </TableCell>
                                        <TableCell align="left">{carbonResults.yearlyCarbonResults[3]}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ display: 'flex', justifyContent: 'center' }} component="th" scope="row">
                                            Year 5
                                        </TableCell>
                                        <TableCell align="left">{carbonResults.yearlyCarbonResults[4]}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ display: 'flex', justifyContent: 'center' }} component="th" scope="row">
                                            Year 6
                                        </TableCell>
                                        <TableCell align="left">{carbonResults.yearlyCarbonResults[5]}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ display: 'flex', justifyContent: 'center' }} component='th' scope="row">
                                            Year 7
                                        </TableCell>
                                        <TableCell align="left">{carbonResults.yearlyCarbonResults[6]}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ display: 'flex', justifyContent: 'center' }} component="th" scope="row">
                                            Year 8
                                        </TableCell>
                                        <TableCell align="left">{carbonResults.yearlyCarbonResults[7]}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ display: 'flex', justifyContent: 'center' }} component="th" scope="row">
                                            Year 9
                                        </TableCell>
                                        <TableCell align="left">{carbonResults.yearlyCarbonResults[8]}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ display: 'flex', justifyContent: 'center' }} component="th" scope="row">
                                            Year 10
                                        </TableCell>
                                        <TableCell align="left">{carbonResults.yearlyCarbonResults[9]}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer> </div>}
                </Grid>
            </Grid>
            <DialogActions>
                <div style={{ marginTop: '20px' }}>

                    <SubmitButton style={{ backgroundColor: 'red' }} onClick={handleCarbonResultsDialogClose}>Close</SubmitButton>
                </div>
            </DialogActions>
        </Dialog>
    )
}

export default CarbonResultsDialog