import InfoIcon from "@mui/icons-material/Info";
import {
  Backdrop,
  Box,
  FormControl,
  FormLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "../css/FormFields.css";
import { SubmitButton } from "../styles";

//import Select from 'react-select';
import { updateCurrentFarmInfo } from "../actions/currentFarmland";
import {
  updateFarmMonitoringDetails,
} from "../actions/farmMonitoringDetails";
import {
  partialUpdateFarmInfo,
  partialUpdateProjectStartYear,
} from "../actions/partialUpdates";
import { allMonitoringYears, fetchMonitoringDetails, findFarmIndex } from "./general/utils/farmHandlers";


import { setCurMonitoringData } from "../actions/currentMonitoringData";
import { findMonitoringDataByFarmId } from "../actions/monitoringData";
import { verifyNumberInput } from "../helper";
import BackHomeComponent from "./general/BackHomeComponent";
import Button from "./general/Button";
import NumericInput from "./general/NumericInput";
import { max } from "./general/Validations/minMaxValues";
import { getVerificationDocuments } from "./general/utils/getVerificationDocuments";

const Step1FarmInfoComponent = () => {
  const { t } = useTranslation();
  const accountSettings = useSelector((state) => state.accountSettingsReducer);
  const isAdmin = accountSettings?.[0]?.role === 'admin'


  const currentFarmMonitoringDetails = useSelector(
    (state) => state.farmMonitoringDetailsReducer
  );
  const [submitted, setSubmitted] = useState(false);
  const currentFarmDataObject = useSelector(
    (state) => state.currentFarmDataReducer
  );

  const currentMonitoringDataObject = useSelector(
    (state) => state.currentMonitoringDataReducer
  );


  const dispatch = useDispatch();

  const [farmSize, setFarmSize] = useState(
    currentFarmDataObject?.farmInfo?.farmSize
  );
  const [farmName, setFarmName] = useState(
    currentFarmDataObject?.farmInfo?.farmName
  );
  const [farmCoordinatesNorth, setFarmCoordinatesNorth] = useState(
    currentFarmDataObject?.farmInfo?.farmCoordinatesNorth
  );
  const [farmCoordinatesEast, setFarmCoordinatesEast] = useState(
    currentFarmDataObject?.farmInfo?.farmCoordinatesEast
  );
  const [startYear, setStartYear] = useState(
    currentMonitoringDataObject?.projectStartYear
  );
  const [startYearExists, setStartYearExists] = useState(true);

  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;
  const [showLoader, setShowLoader] = useState(false);
  const [saveCurrentDataFlag, setSaveCurrentDataFlag] = useState(false);

  const handleBackdropClose = () => {
    setSaveCurrentDataFlag(false);
  };

  const handleBackdropClickCaptureEvent = (e) => {
    e.stopPropagation();
    return false;
  };

  const handleSnackbarClose = () => {
    setState({ vertical: "top", horizontal: "center", open: false });
  };

  const getFarmlandDetails = () => {
    //check for admin role for admins
    let infoData = currentFarmDataObject.farmInfo;
    setFarmSize(infoData.farmSize);
    setFarmName(infoData.farmName);
    setFarmCoordinatesNorth(infoData.farmCoordinatesNorth);
    setFarmCoordinatesEast(infoData.farmCoordinatesEast);
    setStartYear(currentMonitoringDataObject.projectStartYear);

    if (!currentMonitoringDataObject.projectStartYear) {
      setStartYearExists(false);
    }
  };

  const handlePartialUpdateFarmInfo = async () => {
    setShowLoader(true);
    setSaveCurrentDataFlag(true);
    let data = currentFarmMonitoringDetails[0];
    data.startYear = startYear.toString();

    let monitoringData = { projectStartYear: startYear };

    dispatch(
      partialUpdateFarmInfo(
        currentFarmDataObject._id,
        currentFarmDataObject.farmInfo
      )
    )
      .then((res) => {
        dispatch(
          updateFarmMonitoringDetails(currentFarmMonitoringDetails[0]._id, data)
        ).then((res) => {
          dispatch(
            partialUpdateProjectStartYear(
              currentMonitoringDataObject._id,
              monitoringData
            )
          ).then((res) => {
            setSnackbarMessage(t("farmland_draft_submission_msg_success"));
            setState({ open: true, vertical: "top", horizontal: "center" });
            setShowLoader(false);
            setSaveCurrentDataFlag(false);
            setStartYearExists(true);
          });
        });
      })
      .catch((e) => {
        setSnackbarMessage(t("farmland_draft_submission_msg_failure"));
        setState({ open: true, vertical: "top", horizontal: "center" });
        setSaveCurrentDataFlag(false);
        setShowLoader(false);
      });
  };

  const fetchMonitoringData = () => {
    dispatch(findMonitoringDataByFarmId(currentFarmDataObject._id))
      .then((res) => {
        return dispatch(setCurMonitoringData(res.data[0]))
      })
      .then((res) => {
        setStartYear(res.payload.projectStartYear);
        console.log('Monitoring data and current monitoring data set successfully:', res);
      })
      .catch((error) => {
        console.error('Error retrieving or setting monitoring data:', error);
      });
  }

  useEffect(() => {
    fetchMonitoringDetails(dispatch, currentFarmDataObject.farmInfo.email);
  }, [currentFarmDataObject.farmInfo.email]);

  useEffect(() => {
    if (!isAdmin) {
      getFarmlandDetails();
    } else {
      findFarmIndex(dispatch, currentFarmDataObject.farmInfo.email, currentFarmDataObject._id)
        .then((farmIndex) => {
          console.log('farmIndex', farmIndex)
          getVerificationDocuments(dispatch, currentFarmDataObject.farmInfo.email, farmIndex)

          fetchMonitoringData();
        })
        .catch((error) => {
          console.error("Error finding farm index:", error);
        });
    }
  }, []);

  useEffect(() => {
    if (isAdmin && startYear !== -9999) {
      allMonitoringYears(dispatch, startYear);
    }
  }, [startYear]);

  useEffect(() => {

    let tmpData = {
      ...currentFarmDataObject?.farmInfo,
      farmSize,
      farmName,
      farmCoordinatesNorth,
      farmCoordinatesEast,
    };

    dispatch(updateCurrentFarmInfo(tmpData))
      .then((res) => {
        console.log('Current farm info updated successfully:', res);
      })
      .catch((error) => {
        console.error('Error updating current farm info:', error);
      });
  }, [farmSize, farmName, farmCoordinatesNorth, farmCoordinatesEast]);

  return (
    <>
      {showLoader && (
        <Backdrop
          onClickCapture={(e) => handleBackdropClickCaptureEvent(e)}
          style={{ color: "#fff", zIndex: 99999 }}
          open={saveCurrentDataFlag}
          onClick={handleBackdropClose}
        >
          <div style={{ fontSize: 20, fontWeight: 600, color: "white", marginTop: "20px" }}>
            {t("generic_saving_data")}
          </div>
        </Backdrop>
      )}

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        key={vertical + horizontal}
      />

      {!isAdmin && (
        <Box marginLeft={6}>
          <BackHomeComponent></BackHomeComponent>
        </Box>
      )}

      <Box margin={12} marginTop={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormControl fullWidth>
            <FormLabel>
              <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  style={{ justifyContent: "center", marginTop: "20px" }}
                >
                  <Typography variant="h1" color="#000">
                    {t("farminfo_farm_information")}
                  </Typography>
                </Grid>
              </Grid>
            </FormLabel>
          </FormControl>
        </Grid>

        {submitted ? <span>You submitted successfully</span> : ""}

        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <Grid
              item
              xs={12}
              sm={12}
              md={9}
              lg={9}
              marginTop={2}
              display={"flex"}
            >
              <FormControl fullWidth>
                <TextField
                  size="small"
                  label={t("farminfo_farmname")}
                  disabled={
                    !isAdmin
                  }
                  value={farmName}
                  onChange={(e) => setFarmName(e.target.value)}
                />
              </FormControl>
              {!isAdmin && (
                <Tooltip
                  title={<>{t("infobox_farminfo_general")}</>}
                  placement="top-start"
                >
                  <InfoIcon
                    fontSize="small"
                    sx={{
                      padding: "8px 0px 0px 8px",
                      color: "#707070",
                    }}
                  />
                </Tooltip>
              )}
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={9}
              lg={9}
              marginTop={2}
              display={"flex"}
            >
              <FormControl fullWidth>
                <NumericInput
                  max={max.farmSize}
                  size="small"
                  decimalScale={3}
                  label={t("farminfo_farmsize")}
                  disabled={
                    !isAdmin
                  }
                  value={farmSize}
                  onChange={(e) => {
                    if (!verifyNumberInput(e.target.value)) return;
                    setFarmSize(e.target.value);
                  }}
                />
              </FormControl>
              {!isAdmin && (
                <Tooltip
                  title={<>{t("infobox_farminfo_general")}</>}
                  placement="top-start"
                >
                  <InfoIcon
                    fontSize="small"
                    sx={{
                      padding: "8px 0px 0px 8px",
                      color: "#707070",
                    }}
                  />
                </Tooltip>
              )}
            </Grid>

            {isAdmin && (
              <>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={9}
                  lg={9}
                  marginTop={2}
                  display="flex"
                >
                  <FormControl fullWidth>
                    <NumericInput
                      max={max.coordinateN}
                      decimalScale={10}
                      size="small"
                      sx={{ mb: 2 }}
                      label={t("farminfo_coord_north")}

                      value={farmCoordinatesNorth}
                      onChange={(e) => setFarmCoordinatesNorth(e.target.value)}
                      allowNegative={true}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip
                              title={
                                <>
                                  {t("infobox_coordinates_1")}
                                  <br />
                                  <br />
                                  <b>{t("infobox_coordinates_2")}</b>
                                  {t("infobox_coordinates_3")}
                                  <b>{t("infobox_coordinates_4")}</b>
                                  {t("infobox_coordinates_5")}
                                  <b>{t("infobox_coordinates_6")}</b>
                                  {t("infobox_coordinates_7")}
                                  <b>{t("infobox_coordinates_8")}</b>
                                  {t("infobox_coordinates_9")}
                                  <br />
                                  <br />
                                  {t("infobox_coordinates_10")}
                                </>
                              }
                              placement="top-start"
                            >
                              <InfoIcon
                                fontSize="small"
                                style={{
                                  paddingLeft: "4px",
                                  cursor: "default",
                                }}
                              />
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <NumericInput
                      size="small"
                      max={max.coordinateE}
                      decimalScale={10}
                      label={t("farminfo_coord_east")}
                      allowNegative={true}

                      value={farmCoordinatesEast}
                      onChange={(e) => setFarmCoordinatesEast(e.target.value)}
                    />
                  </FormControl>
                </Grid>
              </>
            )}

            {!isAdmin && startYearExists ? (
              <Grid
                item
                xs={12}
                sm={12}
                md={9}
                lg={9}
                marginTop={2}
                display={"flex"}
              >
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    label={t("farminfo_start_year")}
                    disabled={true}
                    value={startYear}
                  />
                </FormControl>
                <Tooltip
                  title={
                    <>
                      {t("infobox_farminfo_start_year_1")}
                      <br />
                      <br />
                      {t("infobox_farminfo_start_year_2")}
                    </>
                  }
                  placement="top-start"
                >
                  <InfoIcon
                    fontSize="small"
                    sx={{
                      padding: "8px 0px 0px 8px",
                      color: "#707070",
                    }}
                  />
                </Tooltip>
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                sm={12}
                md={9}
                lg={9}
                marginTop={2}
                display={"flex"}
              >
                <FormControl fullWidth>
                  <InputLabel size="small" id="startYearSelectLabel">
                    {t("farminfo_start_year")}
                  </InputLabel>
                  <Select
                    size="small"
                    label={t("farminfo_start_year")}
                    labelId="startYearSelectLabel"

                    id="startYearSelectLabel"
                    value={startYear}
                    onChange={(e) => setStartYear(e.target.value)}
                  >
                    <MenuItem value={2021}>2021</MenuItem>
                    <MenuItem value={2022}>2022</MenuItem>
                    <MenuItem value={2023}>2023</MenuItem>
                    {/* <MenuItem value={2024}>2024</MenuItem>
                            <MenuItem value={2025}>2025</MenuItem> */}
                  </Select>
                </FormControl>
                <Tooltip
                  title={
                    <>
                      {t("infobox_farminfo_start_year_1")}
                      <br />
                      <br />
                      {t("infobox_farminfo_start_year_2")}
                    </>
                  }
                  placement="top-start"
                >
                  <InfoIcon
                    fontSize="small"
                    sx={{
                      padding: "8px 0px 0px 8px",
                      color: "#707070",
                    }}
                  />
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </Grid>

        {isAdmin && (
          <Box display="flex" flexDirection="column">
            <SubmitButton
              sx={{ marginTop: "20px", alignSelf: "flex-end", width: "200px" }}
              onClick={handlePartialUpdateFarmInfo}
            >
              {t("farminfo_save")}{" "}
            </SubmitButton>
          </Box>
        )}

        {!isAdmin && !startYearExists && (
          <Box display="flex" flexDirection="column">
            <Button onClick={handlePartialUpdateFarmInfo} text={t("farminfo_save")} />

          </Box>
        )}
      </Box>
    </>
  );
};

export default Step1FarmInfoComponent;
