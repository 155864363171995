import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';


import {
    Info as InfoIcon,
} from "@mui/icons-material";
import { Collapse, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, Tooltip, Typography } from '@mui/material';


import DropDownLabel from "../general/DropDownLabel";
import NumericInput from "../general/NumericInput";


import React, { useState } from "react";
import { emptyNumericFieldErrorZero, interdependentError } from '../general/Validations/fieldValueErrors';
import { max, maxDynamic } from "../general/Validations/minMaxValues";
import { addNewPasturesEstimatedProductivity } from "./addNewEmptySection/addNewPasturesEstimatedProductivity";
import { addNewPasturesHarvest } from "./addNewEmptySection/addNewPasturesHarvest";
import { monthArray1 } from "./cropsArrays";
import { handlePasturesHarvestRowChange, handlePasturesProductivityRowChange, handlePasturesRowChange } from "./handleRowChange";

const PasturesSection = ({ year, tabValue, parcelArea }) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    let { parcelid } = useParams();


    const currentMonitoringDataObject = useSelector(state => state.currentMonitoringDataReducer);
    const currentFarmStatusReducer = useSelector(
        (state) => state.farmStatusReducer
    );


    const accountSettings = useSelector((state) => state.accountSettingsReducer);
    const isAdmin = accountSettings?.[0]?.role === 'admin'
    const allowEditing = currentFarmStatusReducer.allowEditing || isAdmin

    const [arrPasturesCollapsibleButtonStatus, setArrPasturesCollapsibleButtonStatus] = useState(Array(100).fill(true))

    const togglePasturesCollapsibleState = (toggleIndex) => {
        setArrPasturesCollapsibleButtonStatus(
            arrPasturesCollapsibleButtonStatus.map((v, i) => (i === toggleIndex ? !v : v))
        );
    }
    return (

        <Grid>
            <Typography marginBottom={1} variant="h2">
                {t("pci_pastures_title_1")}{' ('}{year}{')'}
            </Typography>
            <Typography variant='subtitle2' marginBottom={2}>
                {t("pci_pastures_subtitle_1")} {year}
            </Typography>

            {currentMonitoringDataObject?.yearlyFarmData.find(yFD => yFD.year === year).parcelLevelData.find(eleParcelData => eleParcelData?.parcelFixedValues?.parcelID === parcelid) &&
                currentMonitoringDataObject?.yearlyFarmData.find(yFD => yFD.year === year).parcelLevelData.find(eleParcelData => eleParcelData?.parcelFixedValues?.parcelID === parcelid)?.yearParcelData?.pastures?.map((val, i) =>
                    <React.Fragment key={i}>
                        <Grid marginBottom={2} >
                            <DropDownLabel onClick={() => togglePasturesCollapsibleState(i)} status={arrPasturesCollapsibleButtonStatus[i]} text={t("pci_pastures_title_1")} />
                        </Grid >
                        <Collapse in={arrPasturesCollapsibleButtonStatus[i]} timeout="auto"
                        >
                            <Grid marginLeft={1} item xs={12} md={12} lg={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={3} md={3} lg={3}>
                                        <Typography variant="h3">{t("pci_pastures_permanent_pastures")}
                                            <Tooltip title={t("pci_pastures_permanent_pastures_info")}>
                                                <InfoIcon sx={{
                                                    fontSize: 'medium',
                                                    padding: '0px 0px 0px 8px',
                                                }} />
                                            </Tooltip>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3} md={3} lg={3}>
                                        <FormControl fullWidth>
                                            <NumericInput max={max.percent}
                                                value={val.percentArea === -9999 ? '' : val.percentArea}
                                                name="percentArea"
                                                onChange={(e) => { handlePasturesRowChange(e, i, year, dispatch, parcelid) }}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                }}
                                                fullWidth placeholder={t("pci_pastures_permanent_pastures_percent")} label={t("pci_pastures_permanent_pastures_percent")} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6} md={6} lg={6}>
                                    </Grid>
                                    {val?.productivity?.map((pValue, j) => {
                                        return (<React.Fragment key={j}>
                                            {j !== 0 && <Grid item xs={3} md={3} lg={3}>
                                            </Grid>}
                                            {j === 0 && <Grid item xs={3} md={3} lg={3}>
                                                <Typography variant="h3">{t("pci_pastures_permanent_pastures_peak")}

                                                </Typography>
                                                <Typography variant="subtitle3"><Trans i18nKey={"pci_pastures_permanent_pastures_peak_info_1"} /></Typography>
                                            </Grid>}
                                            <Grid item xs={3} md={3} lg={3}>
                                                <FormControl fullWidth>
                                                    <NumericInput
                                                        error={interdependentError(pValue.amount, [pValue.date, val.percentArea]) && j === 0 && val.percentArea !== 0}
                                                        max={max.pasturesT}
                                                        decimalScale={3}
                                                        value={pValue.amount === -9999 ? '' : pValue.amount}
                                                        disabled={emptyNumericFieldErrorZero(val.percentArea) || !allowEditing}
                                                        name="amount"
                                                        onChange={(e) => { handlePasturesProductivityRowChange(e, i, j, year, dispatch, parcelid) }}
                                                        fullWidth placeholder={t("pci_pastures_permanent_pastures_peak_qty")} label={t("pci_pastures_permanent_pastures_peak_qty")} />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={2} md={2} lg={2}>
                                                <FormControl fullWidth>
                                                    <Select
                                                        name="units"
                                                        size="small"
                                                        error={interdependentError(pValue.units, [pValue.amount])}
                                                        value={pValue.units}
                                                        disabled={emptyNumericFieldErrorZero(val.percentArea) || !allowEditing || pValue.date === "-"}
                                                        labelId="pastures-estimated-peak-productivity-unit-label"
                                                        id="pastures-estimated-peak-productivity-unit-select"
                                                        onChange={(e) => handlePasturesProductivityRowChange(e, i, j, year, dispatch, parcelid)}
                                                    >
                                                        <MenuItem
                                                            value="kg/m² - dry"
                                                            key="kg/m² - dry">
                                                            {t("pci_pastures_permanent_pastures_peak_dry")}
                                                        </MenuItem>
                                                        <MenuItem
                                                            value="kg/m² - fresh"
                                                            key="kg/m² - fresh">
                                                            {t("pci_pastures_permanent_pastures_peak_fresh")}
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4} md={4} lg={4}>
                                            </Grid>

                                        </React.Fragment>)
                                    })}
                                    <Grid item xs={3} md={3} lg={3}>
                                    </Grid>
                                    <Grid item marginBottom={2} style={{ paddingTop: 8 }} xs={6} md={6} lg={6}>
                                        {val?.productivity?.length <= 5 && allowEditing && <div>
                                            <span onClick={(e) => addNewPasturesEstimatedProductivity(e, i, year, dispatch, parcelid)} style={{ color: "#409781", fontWeight: 'bold', cursor: 'pointer', fontSize: 14 }}>+ {t("pci_pastures_permanent_pastures_add_estimate")} </span>
                                        </div>}
                                    </Grid>
                                    <Grid item xs={3} md={3} lg={3}>
                                    </Grid>
                                    {val?.harvest?.map((hValue, j) => {
                                        return (<React.Fragment key={j}>
                                            {j !== 0 && <Grid item xs={3} md={3} lg={3}>
                                            </Grid>}
                                            {j === 0 && <Grid item xs={3} md={3} lg={3}>
                                                <Typography variant="h3">{t("pci_pastures_grass_harvested")}
                                                    <Tooltip title={<>{t("pci_pastures_grass_harvested_info_1")}<br /><br />{t("pci_pastures_grass_harvested_info_2")}<br /><br />{t("pci_pastures_grass_harvested_info_3")}</>}>
                                                        <InfoIcon sx={{
                                                            fontSize: 'medium',
                                                            padding: '0px 0px 0px 8px',
                                                        }} />
                                                    </Tooltip>
                                                </Typography>
                                                <Typography variant="subtitle3">{t("pci_pastures_grass_harvested_desc")}</Typography>
                                            </Grid>}
                                            <Grid item xs={3} md={3} lg={3}>
                                                <FormControl fullWidth>
                                                    <InputLabel shrink={hValue?.date} size='small' id="pastures-grass-harvested-month-label">{t("pci_pastures_grass_harvested_month")}</InputLabel>
                                                    <Select
                                                        name="date"
                                                        value={hValue.date}
                                                        size="small"
                                                        disabled={emptyNumericFieldErrorZero(val.percentArea) || !allowEditing}
                                                        labelId="pastures-grass-harvested-month-label"
                                                        id="pastures-grass-harvested-month-select"
                                                        label={t("pci_pastures_grass_harvested_month")}
                                                        onChange={(e) => handlePasturesHarvestRowChange(e, i, j, year, dispatch, parcelid)}
                                                    >
                                                        {monthArray1.map((item) => {
                                                            return <MenuItem
                                                                value={tabValue + item.value}
                                                                key={tabValue + item.value}>
                                                                {t(item.text)}{" "}{tabValue}
                                                            </MenuItem>
                                                        })}
                                                        <MenuItem
                                                            value="-"
                                                            key="-">
                                                            -
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>

                                            </Grid>
                                            <Grid item xs={3} md={3} lg={3}>
                                                <FormControl fullWidth>
                                                    <NumericInput max={maxDynamic.harvestT * parcelArea} decimalScale={3}
                                                        error={interdependentError(hValue.amount, [hValue.date])}
                                                        value={hValue.amount === -9999 ? '' : hValue.amount}
                                                        name="amount"
                                                        size="small"
                                                        disabled={Number(val?.percentArea) <= 0 || String(hValue.date) === "-"}
                                                        onChange={(e) => { handlePasturesHarvestRowChange(e, i, j, year, dispatch, parcelid) }}
                                                        fullWidth placeholder={t("pci_pastures_grass_harvested_qty")} label={t("pci_pastures_grass_harvested_qty")} />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={2} md={2} lg={2}>
                                                <FormControl fullWidth>
                                                    <Select
                                                        name="units"
                                                        value={hValue.units}
                                                        error={interdependentError(hValue.units, [hValue.date])}

                                                        size="small"
                                                        disabled={emptyNumericFieldErrorZero(val.percentArea) || !allowEditing || String(hValue.date) === "-"}
                                                        labelId="pastures-grass-harvested-unit-label"
                                                        id="pastures-grass-harvested-unit-select"

                                                        onChange={(e) => handlePasturesHarvestRowChange(e, i, j, year, dispatch, parcelid)}
                                                    >
                                                        <MenuItem
                                                            value="tonnes - dry"
                                                            key="tonnes - dry">
                                                            {t("pci_pastures_grass_harvested_qty_dry")}
                                                        </MenuItem>
                                                        <MenuItem
                                                            value="tonnes - fresh"
                                                            key="tonnes - fresh">
                                                            {t("pci_pastures_grass_harvested_qty_fresh")}
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={1} md={1} lg={1}></Grid>
                                        </React.Fragment>)
                                    })}
                                    <Grid item xs={3} md={3} lg={3}>
                                    </Grid>
                                    <Grid item marginBottom={2} style={{ paddingTop: 8 }} xs={6} md={6} lg={6}>
                                        {allowEditing && <span onClick={(e) => addNewPasturesHarvest(e, i, year, dispatch, parcelid)} style={{ color: "#409781", fontWeight: 'bold', cursor: 'pointer', fontSize: 14 }}>+ {t("pci_pastures_grass_harvested_add_new")}</span>}
                                    </Grid>
                                    <Grid item xs={3} md={3} lg={3}>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Collapse>
                    </React.Fragment>
                )
            }
        </Grid>
    )
}

export default PasturesSection