import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { SubmitButton } from "../styles";

const LivestockNoDocumentsComponent = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();

  const goToVerificationDocs = () => {
    navigate("/carbonplus/verificationdocuments")
  }

  const goToExternalFarmDocs = () => {
    navigate("/carbonplus/livestock/outfarm")
  }

  return (
    <Box margin={12} marginTop={2}>
      <Grid container spacing={2} >
        <Grid item xs={12} sm={12} md={10} lg={10}
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: 30,
            marginBottom: 30,
          }}
        >
          <Typography
            variant='h2'
            color="#000000"
          >
            {t("ls_no_docs_title")}
          </Typography>
          <Typography marginBottom={2} fontSize={15}>{t("ls_no_docs_1")}</Typography>
          <Typography marginBottom={2} fontSize={15}>{t("ls_no_docs_2")}</Typography>
          <Typography marginBottom={2} fontSize={15}>{t("ls_no_docs_3")}</Typography>
          <Typography marginBottom={2} fontSize={15}>{t("ls_no_docs_4")}</Typography>
          <Typography marginBottom={5} fontSize={15}>{t("ls_no_docs_5")}</Typography>
          <Typography marginBottom={5} fontSize={15}>{t("ls_no_docs_6")}</Typography>


        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <SubmitButton onClick={goToVerificationDocs} sx={{ maxWidth: '300px' }}>{t("ls_no_docs_button")}</SubmitButton>
          <SubmitButton
            sx={{

              width: "400px",
              marginLeft: 2,
              bgcolor: "white",
              color: "#409781",
              borderColor: "#409781",
              border: 2,
              "&:hover": { backgroundColor: "white" },
            }}
            onClick={goToExternalFarmDocs}
          >
            {t("ls_infarm_add_out_farm")}
          </SubmitButton>

        </Grid>
        <Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default LivestockNoDocumentsComponent;