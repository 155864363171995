export const CREATE_FARMLAND = "CREATE_FARMLAND";
export const RETRIEVE_FARMLANDS = "RETRIEVE_FARMLANDS";
export const UPDATE_FARMLAND = "UPDATE_FARMLAND";
export const DELETE_FARMLAND = "DELETE_FARMLAND";
export const FIND_FARMLAND_BY_ID = "FIND_FARMLAND_BY_ID";
export const SET_ALL_MONITORING_YEARS = "SET_ALL_MONITORING_YEARS"

export const CREATE_FARM_SETTINGS = "CREATE_FARM_SETTINGS";
export const RETRIEVE_FARM_SETTINGS = "RETRIEVE_FARM_SETTINGS";
export const UPDATE_FARM_SETTINGS = "UPDATE_FARM_SETTINGS";
export const DELETE_FARM_SETTINGS = "DELETE_FARM_SETTINGS";
export const RETRIEVE_FARM_AND_FARM_SETTINGS = 'RETRIEVE_FARM_AND_FARM_SETTINGS';
export const SET_FARM_STATUS = 'SET_FARM_STATUS';
export const GET_FARM_STATUS = 'GET_FARM_STATUS';
export const UPDATE_MONTHLY_DATA = 'UPDATE_MONTHLY_DATA';
export const RESET_MONTHLY_DATA = 'RESET_MONTHLY_DATA';
export const RETRIEVE_CARBON_VALUES_BY_FARM_ID = "RETRIEVE_CARBON_VALUES_BY_FARM_ID";
export const CREATE_FARMER_ACCEPT_CONDITIONS = "CREATE_FARMER_ACCEPT_CONDITIONS";
export const RETRIEVE_FARMER_ACCEPT_CONDITIONS = "RETRIEVE_FARMER_ACCEPT_CONDITIONS";
export const DELETE_FARMER_ACCEPT_CONDITIONS = "CREATE_FARMER_ACCEPT_CONDITIONS";
export const UPDATE_FARMER_ACCEPT_CONDITIONS = "UPDATE_FARMER_ACCEPT_CONDITIONS";
export const CREATE_FARM_TEMPLATE = "CREATE_FARM_TEMPLATE";
export const RETRIEVE_FARM_TEMPLATES = "RETRIEVE_FARM_TEMPLATES";
export const RETRIEVE_FARM_TEMPLATE = "RETRIEVE_FARM_TEMPLATE";
export const UPDATE_FARM_TEMPLATE = "UPDATE_FARM_TEMPLATE";
export const DELETE_FARM_TEMPLATE = "DELETE_FARM_TEMPLATE";
export const SET_CUR_FARM_DATA = "SET_CUR_FARM_DATA";
export const UPDATE_CUR_FARM_DATA = "UPDATE_CUR_FARM_DATA";
export const SET_CUR_MONITORING_DATA = "SET_CUR_MONITORING_DATA";
export const UPDATE_CUR_MONITORING_DATA = "UPDATE_CUR_MONITORING_DATA";
export const UPDATE_CUR_FARM_INFO = "UPDATE_CUR_FARM_INFO";
export const UPDATE_CUR_ENERGY_USAGE = "UPDATE_CUR_ENERGY_USAGE";
export const UPDATE_CUR_LIVESTOCK = "UPDATE_CUR_LIVESTOCK";
export const UPDATE_CUR_LIVESTOCK_BY_SPECIFIC_ID = "UPDATE_CUR_LIVESTOCK_BY_SPECIFIC_ID";
export const REMOVE_LIVESTOCK_BY_SPECIFIC_ID = "REMOVE_LIVESTOCK_BY_SPECIFIC_ID";
export const UPDATE_FUT_LIVESTOCK = "UPDATE_FUT_LIVESTOCK";
export const UPDATE_FUT_LIVESTOCK_BY_SPECIFIC_ID = "UPDATE_FUT_LIVESTOCK_BY_SPECIFIC_ID";
export const UPDATE_PAST_LIVESTOCK = "UPDATE_PAST_LIVESTOCK";
export const UPDATE_CUR_LAND_USE = "UPDATE_CUR_LAND_USE";
export const UPDATE_CUR_LAND_USE_BY_ID = "UPDATE_CUR_LAND_USE_BY_ID";
export const REMOVE_ALL_MAP_PARCELS = "REMOVE_ALL_MAP_PARCELS";
export const REMOVE_MAP_PARCEL_BY_ID = "REMOVE_MAP_PARCEL_BY_ID";
export const ADD_NEW_PARCEL = "ADD_NEW_PARCEL";
export const UPDATE_CUR_SOIL_ANALYSIS = "UPDATE_CUR_SOIL_ANALYSIS";
export const UPDATE_CUR_SUBMISSION_DATE = "UPDATE_CUR_SUBMISSION_DATE";
export const CREATE_EMPTY_FARMLAND = "CREATE_EMPTY_FARMLAND";
export const GET_EXISTING_FARMLAND = "GET_EXISTING_FARMLAND";
export const GET_EMPTY_FARMLAND = "GET_EMPTY_FARMLAND";
export const ADD_PARCELS_TO_YEARS = "ADD_PARCELS_TO_YEARS";

export const RESET_FARMLAND_DATA = "RESET_FARMLAND_DATA";
export const RESET_FARMLANDS = "RESET_FARMLANDS";
export const PARTIAL_UPDATE_FARM_INFO = 'PARTIAL_UPDATE_FARM_INFO';
export const PARTIAL_UPDATE_ENERGY_USAGE = 'PARTIAL_UPDATE_ENERGY_USAGE';
export const PARTIAL_UPDATE_LAND_USE = 'PARTIAL_UPDATE_LAND_USE';

export const CREATE_EMPTY_MONITORING_DATA = "CREATE_EMPTY_MONITORING_DATA";
export const GET_EMPTY_MONITORING_DATA = "GET_EMPTY_MONITORING_DATA";
export const SET_EMPTY_CUR_MONITORING_DATA = "SET_EMPTY_CUR_MONITORING_DATA";
export const UPDATE_EMPTY_CUR_PROJECT_START_YEAR = "UPDATE_EMPTY_CUR_PROJECT_START_YEAR";
export const UPDATE_EMPTY_CUR_YEARLY_FARM_DATA = "UPDATE_EMPTY_CUR_YEARLY_FARM_DATA";
export const GET_MONITORING_DATA = "GET_MONITORING_DATA";
export const ADD_EMPTY_PERENNIAL_TREE_AND_CROPS = "ADD_EMPTY_PERENNIAL_TREE_AND_CROPS";
export const UPDATE_PERENNIAL_TREE_AND_CROPS = "UPDATE_PERENNIAL_TREE_AND_CROPS";
export const DELETE_PERENNIAL_TREE_AND_CROPS = 'DELETE_PERENNIAL_TREE_AND_CROPS';

export const COPY_DATA_BETWEEN_PARCELS = "COPY_DATA_BETWEEN_PARCELS";
export const COPY_DATA_BETWEEN_PARCELS_FOR_LAND_USE = "COPY_DATA_BETWEEN_PARCELS_FOR_LAND_USE";
export const CREATE_MONITORING_DATA = "CREATE_MONITORING_DATA";
export const FIND_MONITORING_DATA_BY_FARM_ID = "FIND_MONITORING_DATA_BY_FARM_ID";
export const FIND_MONITORING_DATA_BY_EMAIL = "FIND_MONITORING_DATA_BY_EMAIL";
export const UPDATE_CUR_YEARLY_FARM_DATA = "UPDATE_CUR_YEARLY_FARM_DATA";
export const ADD_YEARLY_FARM_DATA = "ADD_YEARLY_FARM_DATA";
export const UPDATE_MONITORING_DATA = "UPDATE_MONITORING_DATA";
export const ADD_NEW_PARCEL_DATA_OBJECT = "ADD_NEW_PARCEL_DATA_OBJECT";
export const GET_PERENNIAL_TREE_AND_CROPS_BY_YEAR = "GET_PERENNIAL_TREE_AND_CROPS_BY_YEAR";
export const GET_PERENNIAL_TREE_AND_CROPS_BY_PARCELID = "GET_PERENNIAL_TREE_AND_CROPS_BY_PARCELID";
export const ADD_EMPTY_HARVEST_TO_PERENNIAL_TREE_AND_CROPS = "ADD_EMPTY_HARVEST_TO_PERENNIAL_TREE_AND_CROPS";
export const ADD_EMPTY_RESIDUE_TO_PERENNIAL_TREE_AND_CROPS = "ADD_EMPTY_RESIDUE_TO_PERENNIAL_TREE_AND_CROPS";
export const UPDATE_MONITORING_DATA_FERTILIZER_USAGE = "UPDATE_MONITORING_DATA_FERTILIZER_USAGE";
export const UPDATE_HARVEST_DATA_OF_PERENNIAL_TREE_AND_CROPS = "UPDATE_HARVEST_DATA_OF_PERENNIAL_TREE_AND_CROPS";
export const UPDATE_RESIDUE_DATA_OF_PERENNIAL_TREE_AND_CROPS = "UPDATE_RESIDUE_DATA_OF_PERENNIAL_TREE_AND_CROPS";
export const ADD_EMPTY_ANNUAL_CROPS = "ADD_EMPTY_ANNUAL_CROPS";
export const UPDATE_SPECIES_AND_RESET_PERENNIAL_TREE_AND_CROPS = "UPDATE_SPECIES_AND_RESET_PERENNIAL_TREE_AND_CROPS";
export const UPDATE_SPECIES_AND_RESET_ANNUAL_CROPS = "UPDATE_SPECIES_AND_RESET_ANNUAL_CROPS";
export const RESET_PASTURES_AND_GRASSES_PRODUCTIVITY_HARVESTED = "RESET_PASTURES_AND_GRASSES_PRODUCTIVITY_HARVESTED";
export const RESET_FALLOW_WHEN_FALLOW_CHANGES = "RESET_FALLOW_WHEN_FALLOW_CHANGES";

export const GET_ANNUAL_CROPS_BY_YEAR = "GET_ANNUAL_CROPS_BY_YEAR";
export const GET_ANNUAL_CROPS_BY_PARCELID = "GET_ANNUAL_CROPS_BY_PARCELID";
export const ADD_ANNUAL_CROPS = "ADD_ANNUAL_CROPS";
export const DELETE_ANNUAL_CROPS = "DELETE_ANNUAL_CROPS";
export const UPDATE_ANNUAL_CROPS = "UPDATE_ANNUAL_CROPS";
export const ADD_EMPTY_RESIDUE_TO_ANNUAL_CROPS = "ADD_EMPTY_RESIDUE_TO_ANNUAL_CROPS";
export const ADD_EMPTY_HARVEST_TO_ANNUAL_CROPS = "ADD_EMPTY_HARVEST_TO_ANNUAL_CROPS";
export const UPDATE_HARVEST_DATA_OF_ANNUAL_CROPS = "UPDATE_HARVEST_DATA_OF_ANNUAL_CROPS";
export const UPDATE_RESIDUE_DATA_OF_ANNUAL_CROPS = "UPDATE_RESIDUE_DATA_OF_ANNUAL_CROPS";

export const GET_FALLOW_BY_YEAR = "GET_FALLOW_BY_YEAR";
export const GET_FALLOW_BY_PARCELID = "GET_FALLOW_BY_PARCELID";
export const ADD_FALLOW = "ADD_FALLOW";
export const ADD_EMPTY_FALLOW = "ADD_EMPTY_FALLOW";
export const DELETE_FALLOW = "DELETE_FALLOW";
export const UPDATE_FALLOW = "UPDATE_FALLOW";
export const ADD_EMPTY_RESIDUE_TO_FALLOW = "ADD_EMPTY_RESIDUE_TO_FALLOW";
export const ADD_EMPTY_HARVEST_TO_FALLOW = "ADD_EMPTY_HARVEST_TO_FALLOW";
export const UPDATE_HARVEST_DATA_OF_FALLOW = "UPDATE_HARVEST_DATA_OF_FALLOW";
export const UPDATE_RESIDUE_DATA_OF_FALLOW = "UPDATE_RESIDUE_DATA_OF_FALLOW";


export const GET_PASTURES_BY_YEAR = "GET_PASTURES_BY_YEAR";
export const GET_PASTURES_BY_PARCELID = "GET_PASTURES_BY_PARCELID";
export const ADD_PASTURES = "ADD_PASTURES";
export const ADD_EMPTY_PASTURES = "ADD_EMPTY_PASTURES";
export const DELETE_PASTURES = "DELETE_PASTURES";
export const UPDATE_PASTURES = "UPDATE_PASTURES";
export const ADD_EMPTY_PRODUCTIVITY_TO_PASTURES = "ADD_EMPTY_PRODUCTIVITY_TO_PASTURES";
export const ADD_EMPTY_HARVEST_TO_PASTURES = "ADD_EMPTY_HARVEST_TO_PASTURES";
export const UPDATE_PRODUCTIVITY_DATA_OF_PASTURES = "UPDATE_PRODUCTIVITY_DATA_OF_PASTURES";
export const UPDATE_HARVEST_DATA_OF_PASTURES = "UPDATE_HARVEST_DATA_OF_PASTURES";

export const UPDATE_PRIMARY_LAND_USE = "UPDATE_PRIMARY_LAND_USE";
export const UPDATE_SECONDARY_LAND_USE = "UPDATE_SECONDARY_LAND_USE";
export const UPDATE_ANIMAL_FODDER_BY_YEAR = "UPDATE_ANIMAL_FODDER_BY_YEAR";
export const UPDATE_GRAZING_MONTHS = "UPDATE_GRAZING_MONTHS";

export const ADD_EMPTY_TILLING_EVENT = "ADD_EMPTY_TILLING_EVENT";
export const UPDATE_TILLING_EVENT = "UPDATE_TILLING_EVENT";
//export const RESET_TILLING_EVENT = "RESET_TILLING_EVENT";

export const UPDATE_IRRIGATION = "UPDATE_IRRIGATION";
export const ADD_EMPTY_FELLED_TREES = "ADD_EMPTY_FELLED_TREES";
export const UPDATE_FELLED_TREES = "UPDATE_FELLED_TREES";

export const ADD_EMPTY_FERTILIZER_USAGE = "ADD_EMPTY_FERTILIZER_USAGE";
export const UPDATE_FERTILIZER_USAGE = "UPDATE_FERTILIZER_USAGE";

export const ADD_EMPTY_IMPORTED_ORGANIC_MATTER = "ADD_EMPTY_IMPORTED_ORGANIC_MATTER";
export const UPDATE_IMPORTED_ORGANIC_MATTER = "UPDATE_IMPORTED_ORGANIC_MATTER";

export const UPDATE_OM_BIOCHAR = "UPDATE_OM_BIOCHAR";

export const ADD_EMPTY_OM = "ADD_EMPTY_OM"
export const UPDATE_OM_SUBTYPE = "UPDATE_OM_SUBTYPE"
export const UPDATE_OM_AMOUNT = "UPDATE_OM_AMOUNT"

export const ADD_EMPTY_OM_COMPOST = "ADD_EMPTY_OM_COMPOST";
export const UPDATE_OM_COMPOST = "UPDATE_OM_COMPOST";

export const ADD_EMPTY_OM_MANURE = "ADD_EMPTY_OM_MANURE";
export const UPDATE_OM_MANURE = "UPDATE_OM_MANURE";

export const ADD_EMPTY_OM_MULCH = "ADD_EMPTY_OM_MULCH";
export const UPDATE_OM_MULCH = "UPDATE_OM_MULCH";

export const ADD_EMPTY_OM_SLURRY = "ADD_EMPTY_OM_SLURRY";
export const UPDATE_OM_SLURRY = "UPDATE_OM_SLURRY";

export const ADD_EMPTY_OM_OTHER = "ADD_EMPTY_OM_OTHER";
export const UPDATE_OM_OTHER = "UPDATE_OM_OTHER";

export const UPDATE_PARCEL_NAME = "UPDATE_PARCEL_NAME";
export const UPDATE_PARCEL_AREA = "UPDATE_PARCEL_AREA";
export const DELETE_PARCEL = "DELETE_PARCEL";

export const CONVERT_KMZ_TO_KML = "CONVERT_KMZ_TO_KML";

export const PARTIAL_UPDATE_DIRECT_FUEL_USAGE = 'PARTIAL_UPDATE_DIRECT_FUEL_USAGE';
export const PARTIAL_UPDATE_INDIRECT_FUEL_USAGE = 'PARTIAL_UPDATE_INDIRECT_FUEL_USAGE';
export const PARTIAL_UPDATE_FERTILIZER_USAGE = 'PARTIAL_UPDATE_FERTILIZER_USAGE';
export const PARTIAL_UPDATE_IMPORTED_ORGANIC_MATTER = 'PARTIAL_UPDATE_IMPORTED_ORGANIC_MATTER';
export const PARTIAL_UPDATE_LIVESTOCK = 'PARTIAL_UPDATE_LIVESTOCK';
export const PARTIAL_UPDATE_PARCEL_LEVEL_DATA = 'PARTIAL_UPDATE_PARCEL_LEVEL_DATA';
export const PARTIAL_UPDATE_PROJECT_START_YEAR = 'PARTIAL_UPDATE_PROJECT_START_YEAR';
export const PARTIAL_UPDATE_YEARLY_FARM_DATA = 'PARTIAL_UPDATE_YEARLY_FARM_DATA';





export const PARTIAL_UPDATE_SOIL_ANALYSIS = 'PARTIAL_UPDATE_SOIL_ANALYSIS';
export const RETRIEVE_FARMER_FROM_LANDBOT = 'RETRIEVE_FARMER_FROM_LANDOBT';
export const CREATE_EMPTY_FARMLAND_FROM_LANDBOT = 'CREATE_EMPTY_FARMLAND_FROM_LANDBOT';
export const CREATE_ACCOUNT_SETTINGS = 'CREATE_ACCOUNT_SETTINGS';
export const RETRIEVE_ACCOUNT_SETTINGS = 'RETRIEVE_ACCOUNT_SETTINGS';
export const UPDATE_ACCOUNT_SETTINGS = 'UPDATE_ACCOUNT_SETTINGS';
export const DELETE_ACCOUNT_SETTINGS = 'DELETE_ACCOUNT_SETTINGS';
export const GET_FARM_LANGUAGE = 'GET_FARM_LANGUAGE';
export const CREATE_FARM_MONITORING_DETAILS = 'CREATE_FARM_MONITORING_DETAILS';
export const GET_FARM_MONITORING_DETAILS = 'GET_FARM_MONITORING_DETAILS';
export const UPDATE_FARM_MONITORING_DETAILS = 'UPDATE_FARM_MONITORING_DETAILS';
export const DELETE_FARM_MONITORING_DETAILS = 'DELETE_FARM_MONITORING_DETAILS';
export const GET_FARM_MONITORING_DETAILS_BY_EMAIL = 'GET_FARM_MONITORING_DETAILS_BY_EMAIL';
export const SET_FARM_MONITORING_DETAILS = 'SET_FARM_MONITORING_DETAILS';
export const GET_FARM_VERIFICATION_DOCUMENTS = 'GET_FARM_VERIFICATION_DOCUMENTS';
export const CREATE_FARM_VERIFICATION_DOCUMENTS = 'CREATE_FARM_VERIFICATION_DOCUMENTS';
export const UPDATE_FARM_VERIFICATION_DOCUMENTS = 'UPDATE_FARM_VERIFICATION_DOCUMENTS';
export const GET_FARM_VERIFICATION_DOCUMENTS_BY_EMAIL = 'GET_FARM_VERIFICATION_DOCUMENTS_BY_EMAIL';
export const SET_FARM_VERIFICATION_DOCUMENTS = 'SET_FARM_VERIFICATION_DOCUMENTS';

export const UPDATE_LIVESTOCK = 'UPDATE_LIVESTOCK';
export const UPDATE_GRAZING_MANAGEMENT = 'UPDATE_GRAZING_MANAGEMENT';
export const UPDATE_CATTLE_AMOUNT = 'UPDATE_CATTLE_AMOUNT';
export const UPDATE_DAIRY_COWS_AMOUNT = 'UPDATE_DAIRY_COWS_AMOUNT';
export const UPDATE_SWINE_AMOUNT = 'UPDATE_SWINE_AMOUNT';
export const UPDATE_SHEEP_AMOUNT = 'UPDATE_SHEEP_AMOUNT';
export const UPDATE_GOATS_AMOUNT = 'UPDATE_GOATS_AMOUNT';
export const UPDATE_CHICKEN_AMOUNT = 'UPDATE_CHICKEN_AMOUNT';
export const UPDATE_TURKEYS_AMOUNT = 'UPDATE_TURKEYS_AMOUNT';
export const UPDATE_DUCKS_AMOUNT = 'UPDATE_DUCKS_AMOUNT';
export const UPDATE_LIVESTOCK_IN_FARM = 'UPDATE_LIVESTOCK_IN_FARM';
export const UPDATE_LIVESTOCK_GRAZING_DAYS = 'UPDATE_LIVESTOCK_GRAZING_DAYS';
export const UPDATE_LIVESTOCK_MANURE = 'UPDATE_LIVESTOCK_MANURE';
export const UPDATE_LIVESTOCK_FERTILITY = 'UPDATE_LIVESTOCK_FERTILITY';
export const UPDATE_LIVESTOCK_OUT_FARM_SPECIES = 'UPDATE_LIVESTOCK_OUT_FARM_SPECIES';
export const UPDATE_LIVESTOCK_OUT_FARM_NUMBER_OF_ANIMALS = 'UPDATE_LIVESTOCK_OUT_FARM_NUMBER_OF_ANIMALS';
export const UPDATE_LIVESTOCK_OUT_FARM_GRAZING_DAYS = 'UPDATE_LIVESTOCK_OUT_FARM_GRAZING_DAYS';
export const UPDATE_LIVESTOCK_OUT_FARM_ADD_SPECIES = 'UPDATE_LIVESTOCK_OUT_FARM_ADD_SPECIES';
export const DELETE_LIVESTOCK_OUT_FARM_ADD_SPECIES = 'DELETE_LIVESTOCK_OUT_FARM_ADD_SPECIES';

export const RESET_FARM_STATUS = "RESET_FARM_STATUS";
export const RESET_CURRENT_MONITORING_DATA = "RESET_CURRENT_MONITORING_DATA";
export const RESET_FARM_VERIFICATION_DOCUMENTS = "RESET_FARM_VERIFICATION_DOCUMENTS";

// Latestmapmetadata action types
export const CREATE_LATESTMAP_METADATA = "CREATE_LATESTMAP_METADATA";
export const UPDATE_LATESTMAP_METADATA = "UPDATE_LATESTMAP_METADATA";
export const GET_LATESTMAP_METADATA = "GET_LATESTMAP_METADATA";

// Mapdata action types
export const CREATE_MAP_DATA = "CREATE_MAP_DATA";
export const FIND_MAP_DATA_BY_FARM_ID = "FIND_MAP_DATA_BY_FARM_ID";
export const UPDATE_MAP_DATA = "UPDATE_MAP_DATA";

// Combinedparcelsmetadata action types
export const CREATE_COMBINED_PARCELES_METADATA = "CREATE_COMBINED_PARCELES_METADATA";
export const DELETE_COMBINED_PARCELES_METADATA = "DELETE_COMBINED_PARCELES_METADATA";
export const FETCH_COMBINED_PARCELES_METADATA = "FETCH_COMBINED_PARCELES_METADATA";
export const UPDATE_COMBINED_PARCELES_METADATA = "UPDATE_COMBINED_PARCELES_METADATA";



