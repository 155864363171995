import HttpService from '../services/HttpService';

const getAll = () => {
    return HttpService.getAxiosClient().get("/api/detailsandsettings/farmdetailswithsettings/");
};
const FarmAndFarmSettingsDataService = {
    getAll,
};

export default FarmAndFarmSettingsDataService;
