export const cattle = [
    { title: "ls_infarm_cattle_females", title2: "ls_infarm_cattle_females2" },
    { title: "ls_infarm_cattle_males", info: "ls_infarm_cattle_males_info" },
    { title: "ls_infarm_cattle_growing", title2: "ls_infarm_cattle_growing2", info: "ls_infarm_cattle_growing_info" },
    { title: "ls_infarm_cattle_calves" },
];

export const sow = [
    {
        title: "ls_infarm_swine_sows",
        info: "ls_infarm_swine_sows_info"
    },
    {
        title: "ls_infarm_swine_boars",
        info: "ls_infarm_swine_boars_info"
    },
    {
        title: "ls_infarm_swine_finishers",
        info: "ls_infarm_swine_finishers_info"
    },
    {
        title: "ls_infarm_swine_other",
        info: "ls_infarm_swine_other_info"
    },
    {
        title: "ls_infarm_swine_piglets",
    }
];

export const sheep = [
    {
        title: "ls_infarm_sheep_females",
    },
    {
        title: "ls_infarm_sheep_males",
    },
    {
        title: "ls_infarm_sheep_lambs",
    },

];

export const goat = [
    {
        title: "ls_infarm_goats_females",
    },
    {
        title: "ls_infarm_goats_males",
    },
    {
        title: "ls_infarm_goats_kids",
    },

];

export const chicken = [
    {
        title: "ls_infarm_chicken_layer",
        info: "ls_infarm_chicken_layer_info",
    },
    {
        title: "ls_infarm_chicken_broilers",
        info: "ls_infarm_chicken_broilers_info",
    },
    {
        title: "ls_infarm_chicken_other",
        info: "ls_infarm_chicken_other_info",
    }
];
