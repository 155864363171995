import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CircleIcon from '@mui/icons-material/Circle'; // Or any other icon
import { Box, List, ListItem, ListItemIcon, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AUDIT, CARBON_PLUS_CREDIT_POSTING, CARBON_PLUS_CREDIT_SALES, DOCUMENTS_COLLECTION, FARM_DATA_IS_YET_TO_BE_SUBMITTED, FARM_STATUS, INTERNAL_REVIEW, SUBMITTED_FOR_REVIEW } from '../constants/constants';

import { styled } from '@mui/system';

const StyledList = styled(List)({
    position: 'relative',
    '& .MuiListItemIcon-root': {
        position: 'relative',
        zIndex: 1,
        '&::before': {
            content: '""',
            position: 'absolute',
            top: '0%',
            left: '20%',
            transform: 'translateY(-86%)',
            width: '2px',
            height: 'calc(100% + 16px)', // Adjust height based on padding/margin
            backgroundColor: '#c9c7c7', // Line color
            zIndex: -1,
        },
    },
    '& .MuiListItem-root:first-of-type .MuiListItemIcon-root::before': {
        height: '50%',
        top: '50%',
    },
});

const StatusProgress = () => {
    const { t } = useTranslation();

    const currentFarmStatusReducer = useSelector(state => state.farmStatusReducer);
    const status = currentFarmStatusReducer?.status

    const titles = [
        t("documents_collection"),
        t("data_collection"),
        t("submitted_for_review"),
        t("internal_review"),
        t("audit"),
        t("carbon_plus_credit_posting"),
        t("carbon_plus_credit_sales")
    ]

    const subtitle = {
        [DOCUMENTS_COLLECTION]: t("subtitle_documents_collection"),
        [FARM_DATA_IS_YET_TO_BE_SUBMITTED]: t("subtitle_farm_data_yet_to_submit"),
        [SUBMITTED_FOR_REVIEW]: t("subtitle_submitted_review"),
        [INTERNAL_REVIEW]: t("subtitle_internal_review"),
        [AUDIT]: t("subtitle_audit"),
        [CARBON_PLUS_CREDIT_POSTING]: t("subtitle_carbon_credit_posting"),
        [CARBON_PLUS_CREDIT_SALES]: t("subtitle_carbon_sales"),
    };
    const statusIndex = FARM_STATUS.indexOf(status);
    const [showList, setShowList] = useState(false);
    const handleNextStepsVisibility = () => {
        setShowList(!showList)
    };

    return (

        <Box sx={{
            border: 1,
            borderColor: '#DBDBDB',
            borderRadius: '12px',
            display: 'flex',
            flexDirection: 'column',
            padding: '1.5rem',
            width: '350px',
        }}>
            <Typography variant='h2'>
                {titles[statusIndex]}
            </Typography>
            <Typography variant='subtitle2'>
                {subtitle[status]}
            </Typography>
            <Box style={{ padding: '16px , 0' }}  >
                {/* <LinearProgressBar variant="determinate" value={0} /> */}
            </Box>
            <Typography onClick={handleNextStepsVisibility} sx={{ cursor: 'pointer', mb: 1, mt: 2, fontWeight: 'bold', color: '#409781' }}>
                <Box sx={{
                    display: "flex",
                    flexDirection: "row"
                }}> {t("next_steps")} {showList ? <ArrowDropDownIcon /> : <ArrowRightIcon />} </Box>
            </Typography>
            {showList && <StyledList>
                <List style={{ paddingBottom: 15 }}>
                    {FARM_STATUS.map((ele, index) => {
                        return <ListItem
                            key={index}
                            id={ele}
                            sx={{
                                color: index < statusIndex ? '#409781' : index > statusIndex ? '#707070' : 'black',
                                fontWeight: ele == [status] ? 'bold' : '',
                                height: 56,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>

                                <ListItemIcon>
                                    <CircleIcon sx={{ fontSize: '1.5rem', color: index < statusIndex ? '#409781' : index > statusIndex ? '#707070' : 'black' }} />
                                </ListItemIcon><p style={{ fontFamily: 'Montserrat' }}>{titles[index]}</p>
                            </div>

                        </ListItem>
                    })
                    }
                </List>
            </StyledList>}
        </Box >

    )
}

export default StatusProgress