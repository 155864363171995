import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";


import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
    Box,
    Grid,
    Stack,
    Typography
} from "@mui/material";
import "../../css/FormFields.css";
import { BaselineYears, ProjectYears, SubmitButton, UploadedDocument } from "../../styles";



import { CheckCircle } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { updateFarmVerificationDocuments } from "../../actions/verificationDocuments";
import { defaultValues } from "../../constants/verificationDocsDefaultValues";
import VerificationDocumentsUploadService from "../../farmservices/VerificationDocumentsUploadService";
import { getYearsArray } from "../general/utils/allDBYears";
import { handleDeleteFile, handleDownload } from "../general/utils/fileOperations";

const YearlyInvoices = ({ setState,
    setShowLoader,
    setSaveCurrentDataFlag,
    setSnackbarMessage,
    setCurrentDocumentComment,
    setCurrentDocumentYear,
    setCurrentDocumentFolder,
    setCurrentDocumentIndex,
    handleConfirmPopUpOpen,
    documentYear
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const currentMonitoringYearsObject = useSelector(state => state.monitoringYearsTrackerReducer);
    const currentFarmVerificationDocuments = useSelector((state) => state.farmVerificationDocumentsReducer);
    const currentFarmDataObject = useSelector((state) => state.currentFarmDataReducer);

    const allYears = currentMonitoringYearsObject.allMonitoringYears
    const baselineYear = (year) => {
        if (allYears[0] === year || allYears[1] === year || allYears[2] === year) {
            return true;
        } else {
            return false;
        }
    };

    const allDBYears = getYearsArray()
    const currentYear = allDBYears[documentYear]

    const handleFileChange = (e, subfolder, year) => {
        // if no year default value

        setShowLoader(true);
        setSaveCurrentDataFlag(true);

        let email = currentFarmDataObject.farmInfo.email.replace("@", "__");

        let file = e.target.files[0];

        let fileNameArr = e.target.files[0].name.split(".").map((ele) => {
            return ele.replace(/[^a-zA-Z0-9]/g, "");
        });

        let fileName = fileNameArr.join(".");

        if (year) {
            handleFileUpload(file, fileName, subfolder, email, year);
        } else {
            handleFileUpload(file, fileName, subfolder, email);
        }
    };
    const handleFileUpload = async (file, fileName, subfolder, email, year) => {
        const formData = new FormData();

        const server = process.env.REACT_APP_NODE_ENV === 'production' ? 'monitoring_round_2024_uploads' : 'test';

        let tempData = {
            dateUploaded: Date.now().toString(),
            cfDocumentName: defaultValues[subfolder],

            folder: subfolder,
            fileName: fileName,
            documentType: "",
            s3URL: "",
            year: year,
            comment: "",
        };

        formData.append("subfolder", subfolder);
        if (year !== undefined && year !== "") {
            formData.append("year", year);
        }
        formData.append("email", email);
        formData.append("server", server);
        formData.append("file", file);

        const response = await VerificationDocumentsUploadService.upload(formData);

        tempData.s3URL = response.data.message;
        tempData.documentType = response.data.data.mimetype;


        const monitoringYear = allDBYears.indexOf(year);


        let tempObj = currentFarmVerificationDocuments;

        if (subfolder === "externalServicesInvoices") {
            tempObj.yearlyVerificationDocuments[
                monitoringYear
            ].externalServicesInvoices.push(tempData);
        }

        // upload to mongoDB
        dispatch(
            updateFarmVerificationDocuments(
                currentFarmVerificationDocuments._id,
                tempObj
            )
        )
            .then((res) => {
                setSnackbarMessage(t("uploading_document_msg_success"));
                setShowLoader(false);
                setState({ open: true, vertical: "top", horizontal: "center" });
                setSaveCurrentDataFlag(false);
            })
            .catch((e) => {
                setSnackbarMessage(t("uploading_document_msg_failure"));
                setShowLoader(false);
                setState({ open: true, vertical: "top", horizontal: "center" });
                setSaveCurrentDataFlag(false);
            });
    };


    return (
        <>   {allYears.includes(currentYear) && (
            <Stack marginLeft={2} sx={{ minWidth: "250px" }}>
                {baselineYear(currentYear) ? (
                    <BaselineYears sx={{ marginBottom: 2 }}>{currentYear}</BaselineYears>
                ) : (
                    <ProjectYears sx={{ marginBottom: 2 }}>{currentYear}</ProjectYears>
                )}

                {currentFarmVerificationDocuments
                    ?.yearlyVerificationDocuments?.[documentYear]?.externalServicesInvoices
                    ?.length > 0 &&
                    currentFarmVerificationDocuments.yearlyVerificationDocuments[documentYear].externalServicesInvoices.map(
                        (ele, index) => {
                            return (
                                <Box key={index}>
                                    <Grid container spacing={2}>
                                        <Stack
                                            marginLeft={2}
                                            display={"flex"}
                                            marginBottom={1}
                                            marginTop={1.5}
                                        >
                                            <UploadedDocument
                                                size="small"
                                                label={
                                                    <Fragment>
                                                        <CheckCircle sx={{ marginRight: 1 }} />
                                                        {currentFarmVerificationDocuments
                                                            ?.yearlyVerificationDocuments?.[documentYear]
                                                            ?.externalServicesInvoices[index].fileName}
                                                    </Fragment>
                                                }
                                                disabled
                                            ></UploadedDocument>
                                            <Grid item marginBottom={1.5}>
                                                <Typography
                                                    sx={{
                                                        color: "#3e9781",
                                                        cursor: "pointer",
                                                        marginRight: "5px",
                                                    }}
                                                    fontWeight={600}
                                                    fontSize={12}
                                                    component="span"
                                                    onClick={() =>
                                                        handleDownload(
                                                            currentFarmVerificationDocuments
                                                                .yearlyVerificationDocuments[documentYear]
                                                                .externalServicesInvoices[index].s3URL
                                                        )
                                                    }
                                                >
                                                    {t("ver_doc_download")}
                                                </Typography>
                                                |
                                                <Typography
                                                    sx={{
                                                        color: "#3e9781",
                                                        cursor: "pointer",
                                                        marginX: "5px",
                                                    }}
                                                    fontWeight={600}
                                                    fontSize={12}
                                                    component="span"
                                                    onClick={() =>
                                                        handleDeleteFile(
                                                            index,
                                                            currentFarmVerificationDocuments
                                                                .yearlyVerificationDocuments[documentYear]
                                                                .externalServicesInvoices[index]
                                                                .folder,
                                                            currentFarmVerificationDocuments
                                                                .yearlyVerificationDocuments[documentYear]
                                                                .externalServicesInvoices[index]
                                                                .fileName,
                                                            currentYear, currentFarmDataObject,
                                                            currentFarmVerificationDocuments,
                                                            dispatch,
                                                            setSnackbarMessage,
                                                            setSaveCurrentDataFlag,
                                                            setState,
                                                            t
                                                        )
                                                    }
                                                >
                                                    {t("ver_doc_delete")}
                                                </Typography>
                                                |
                                                {currentFarmVerificationDocuments
                                                    .yearlyVerificationDocuments[documentYear]
                                                    .externalServicesInvoices[index].comment
                                                    ? (
                                                        <Typography
                                                            sx={{
                                                                color: "#3e9781",
                                                                cursor: "pointer",
                                                                marginLeft: "5px",
                                                            }}
                                                            fontWeight={600}
                                                            fontSize={12}
                                                            component="span"
                                                            onClick={() => {
                                                                setCurrentDocumentComment(
                                                                    currentFarmVerificationDocuments
                                                                        .yearlyVerificationDocuments[documentYear]
                                                                        .externalServicesInvoices[index]
                                                                        .comment
                                                                );
                                                                setCurrentDocumentYear(documentYear); // year index
                                                                setCurrentDocumentFolder(
                                                                    "externalServicesInvoices"
                                                                );
                                                                setCurrentDocumentIndex(index);
                                                                handleConfirmPopUpOpen();
                                                            }}
                                                        >
                                                            {t("general_edit_comment")}
                                                        </Typography>
                                                    ) : (
                                                        <Typography
                                                            sx={{
                                                                color: "#3e9781",
                                                                cursor: "pointer",
                                                                marginLeft: "5px",
                                                            }}
                                                            fontWeight={600}
                                                            fontSize={12}
                                                            component="span"
                                                            onClick={() => {
                                                                setCurrentDocumentYear(documentYear);
                                                                setCurrentDocumentFolder(
                                                                    "externalServicesInvoices"
                                                                );
                                                                setCurrentDocumentIndex(index);
                                                                handleConfirmPopUpOpen();
                                                            }}
                                                        >
                                                            {t("general_add_comment")}
                                                        </Typography>
                                                    )}
                                            </Grid>
                                        </Stack>
                                    </Grid>
                                </Box>
                            );
                        }
                    )}
                <Grid item style={{ marginBottom: "10px", display: "flex" }}>
                    <SubmitButton
                        sx={{ fontSize: 13 }}
                        fullWidth
                        component="label"
                        variant="contained"
                        startIcon={<CloudUploadIcon />}
                        onChange={(e) =>
                            handleFileChange(e, "externalServicesInvoices", currentYear)
                        }
                    >
                        {t("ver_doc_upload")}
                        <input
                            type="file"
                            accept="application/msword,
                          application/pdf, 
                          application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                          image/png, 
                          image/jpeg"
                            hidden
                        />
                    </SubmitButton>
                </Grid>
            </Stack>
        )}</>
    )
}

export default YearlyInvoices