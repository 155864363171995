import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Grid, IconButton, Typography } from "@mui/material";

const DropDownLabel = ({ onClick, status, text, color }) => {
    return <Grid direction="row" container alignItems="center">
        <IconButton
            onClick={onClick}
            aria-label="expand"
            size="small"
        >
            {status ? <KeyboardArrowUpIcon />
                : <KeyboardArrowDownIcon />}
        </IconButton>

        <Typography fontSize={18} fontWeight={800} style={{ color: color }}>{text}</Typography>
    </Grid>
}

export default DropDownLabel