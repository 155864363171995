import {
    FormControl,
    MenuItem,
    Select
} from "@mui/material";
import React, { useEffect, useState } from 'react';

import { useUser } from "@clerk/clerk-react";
import "moment/locale/de";
import "moment/locale/es";
import "moment/locale/pt";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { retrieveAccountSettings, updateAccountSettings } from "../../actions/accountSettings";

const LanguageChange = () => {
    const { i18n } = useTranslation();
    const [language, setLanguage] = useState(i18n?.language)
    const dispatch = useDispatch();
    const { user } = useUser();
    const email = user?.emailAddresses[0]?.emailAddress;

    const accountSettings = useSelector((state) => state.accountSettingsReducer);
    const isAdmin = accountSettings?.[0]?.role === 'admin'


    const currentFarmDataObject = useSelector(
        (state) => state.currentFarmDataReducer
    );

    useEffect(() => {
        setLanguage(i18n?.language)
    }, [i18n?.language]);

    const languages = [
        ["en_EN", "English"],
        ["pt_PT", "Português"],
        ["es_ES", "Español"]
    ];

    const handleLanguageChange = (e) => {
        const selectedLanguage = e.target?.value;
        setLanguage(selectedLanguage)

        i18n.changeLanguage(selectedLanguage);

        if (!isAdmin) {
            const tmpData = {
                farmPrimaryIdFromMongoose: currentFarmDataObject._id,
                email: email,
                language: i18n.language,
            };
            dispatch(retrieveAccountSettings(email)).then(res => {
                console.log('role', res.data[0].role)
                dispatch(updateAccountSettings(res.data[0]._id, tmpData))
            })
                .then(res => {
                    console.log('language changed successfully:', res);
                })
                .catch(error => {
                    console.error('Errorchanging language:', error);
                });
        }
    };

    return (
        <FormControl>
            <Select
                size="small"
                sx={{
                    borderRadius: "10px",
                    background: 'white'
                }}
                value={language}
                onChange={handleLanguageChange}
            >
                {languages.map(lang => (
                    <MenuItem key={lang[0]} value={lang[0]}>
                        {lang[1]}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default LanguageChange;
