import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateMonitoringData } from "../../actions/monitoringData";
import { GoBackButton } from '../../styles';

const BackToMapsComponent = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const currentMonitoringDataObject = useSelector(state => state.currentMonitoringDataReducer);

    const saveAndGoBack = () => {
        navigate("/carbonplus/landuse")
        dispatch(updateMonitoringData(currentMonitoringDataObject._id, currentMonitoringDataObject))
            .then(res => {
                console.log('Monitoring data updated successfully:', res);
            })
            .catch(error => {
                console.error('Error updating monitoring data:', error);
            });
    };


    return (
        <Box marginTop='80px' marginLeft={2.8} display='flex' alignItems='center'>
            <GoBackButton onClick={saveAndGoBack}>
                <ArrowBackIosRoundedIcon />
                <Typography sx={{ fontWeight: 800, paddingLeft: 2 }}>{t("back_to_home")}</Typography>
            </GoBackButton>
        </Box>
    )
}

export default BackToMapsComponent