
export const defaultValues = {
    digitalFarmMap: 'Map of the Farm',
    digitalMapPerParcel: 'Map of the Parcel',
    kmlFiles: 'Map of the Farm and Parcels',
    landOwnership: 'Proof of land access - lease, land ownership contract',
    capDocument: 'CAP document',
    animalTotals: 'Total number/counts of Animals',
    ampGrazingCharts: 'Grazing charts',
    invoiceSyntheticFertilizers: 'Fertilizer Receipt/Invoice from baseline years + crediting years',
    dieselInvoices: 'Diesel Receipt/Invoice from baseline years + crediting years (exludes third party services)',
    petrolInvoices: 'Petrol fuels Receipt/Invoice from baseline years + crediting years (excludes third party services)',
    externalServicesInvoices: 'External service Receipt/Invoice for fossil fuels consumption from baseline years + crediting years',
    invoiceCoverCropsSeeds: 'Cover Crops seeds Receipt/Invoice or photographs',
    invoiceProduceSale: 'Produce sales Receipt/Invoice',
    invoiceProduceTransport: 'Transport slip of the produce or photographs',
    seedsInvoices: 'Cover Crops seeds Receipt/Invoice or photographs',
    fertiliserInvoices: 'Fertiliser Receipt/Invoice from baseline years + crediting years',
    importedOrganicAmendmentsInvoices: 'Imported organic amendments receipt/invoice from baseline years + crediting years',
    animalFodderInvoices: 'Animal fodder receipt/invoicefrom baseline years + crediting years',

};
