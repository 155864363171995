import { CREATE_MONITORING_DATA, FIND_MONITORING_DATA_BY_EMAIL, FIND_MONITORING_DATA_BY_FARM_ID, UPDATE_MONITORING_DATA } from "../actions/types";

const initialState = {
    farmId: "",
    email: "",
    uniqueCfFarmId: "",
    projectStartYear: -9999,
    yearlyFarmData: [
        {
            year: 2018,
            fuelUsage: {
                direct: {
                    diesel: {
                        amount: -9999,
                        units: "litres"
                    },
                    petrol: {
                        amount: -9999,
                        units: "litres"
                    }
                },
                indirect: [
                    {
                        service: "",
                        area: {
                            amount: -9999,
                            units: "hectares",
                        },
                        serviceDetail: "",
                        serviceCategory: ""
                    }
                ]
            },
            fertilizerUsage: [],
            importedOrganicMatter: [],
            livestock: {
                grazingManagement: null,
                inFarm: [],
                outFarm: []
            },
            parcelLevelData: []
        },
        {
            year: 2019,
            fuelUsage: {
                direct: {
                    diesel: {
                        amount: -9999,
                        units: "litres"
                    },
                    petrol: {
                        amount: -9999,
                        units: "litres"
                    }
                },
                indirect: [
                    {
                        service: "",
                        area: {
                            amount: -9999,
                            units: "hectares",
                        },
                        serviceDetail: "",
                        serviceCategory: ""
                    }
                ]
            },
            fertilizerUsage: [],
            importedOrganicMatter: [],
            livestock: {
                grazingManagement: null,
                inFarm: [],
                outFarm: []
            },
            parcelLevelData: []
        },
        {
            year: 2020,
            fuelUsage: {
                direct: {
                    diesel: {
                        amount: -9999,
                        units: "litres"
                    },
                    petrol: {
                        amount: -9999,
                        units: "litres"
                    }
                },
                indirect: [
                    {
                        service: "",
                        area: {
                            amount: -9999,
                            units: "hectares",
                        },
                        serviceDetail: "",
                        serviceCategory: ""
                    }
                ]
            },
            fertilizerUsage: [],
            importedOrganicMatter: [],
            livestock: {
                grazingManagement: null,
                inFarm: [],
                outFarm: []
            },
            parcelLevelData: []
        },
        {
            year: 2021,
            fuelUsage: {
                direct: {
                    diesel: {
                        amount: -9999,
                        units: "litres"
                    },
                    petrol: {
                        amount: -9999,
                        units: "litres"
                    }
                },
                indirect: [
                    {
                        service: "",
                        area: {
                            amount: -9999,
                            units: "hectares",
                        },
                        serviceDetail: "",
                        serviceCategory: ""
                    }
                ]
            },
            fertilizerUsage: [],
            importedOrganicMatter: [],
            livestock: {
                grazingManagement: null,
                inFarm: [],
                outFarm: []
            },
            parcelLevelData: []
        },
        {
            year: 2022,
            fuelUsage: {
                direct: {
                    diesel: {
                        amount: -9999,
                        units: "litres"
                    },
                    petrol: {
                        amount: -9999,
                        units: "litres"
                    }
                },
                indirect: [
                    {
                        service: "",
                        area: {
                            amount: -9999,
                            units: "hectares",
                        },
                        serviceDetail: "",
                        serviceCategory: ""
                    }
                ]
            },
            fertilizerUsage: [],
            importedOrganicMatter: [],
            livestock: {
                grazingManagement: null,
                inFarm: [],
                outFarm: []
            },
            parcelLevelData: []
        },
        {
            year: 2023,
            fuelUsage: {
                direct: {
                    diesel: {
                        amount: -9999,
                        units: "litres"
                    },
                    petrol: {
                        amount: -9999,
                        units: "litres"
                    }
                },
                indirect: [
                    {
                        service: "",
                        area: {
                            amount: -9999,
                            units: "hectares",
                        },
                        serviceDetail: "",
                        serviceCategory: ""
                    }
                ]
            },
            fertilizerUsage: [],
            importedOrganicMatter: [],
            livestock: {
                grazingManagement: null,
                inFarm: [],
                outFarm: []
            },
            parcelLevelData: []
        },
    ]
};

function farmMonitoringDataReducer(monitoringData = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case CREATE_MONITORING_DATA:
            return [...monitoringData, payload];
        case FIND_MONITORING_DATA_BY_FARM_ID:
            return payload;
        case FIND_MONITORING_DATA_BY_EMAIL:
            return payload;
        case UPDATE_MONITORING_DATA:
            return {
                ...monitoringData, ...payload
            };
        default:
            return monitoringData;
    }
};

export default farmMonitoringDataReducer;