import HttpService from '../services/HttpService';

const getAll = () => {
    return HttpService.getAxiosClient().get("/api/farmmonitoringdetails/");
};

const get = id => {
    return HttpService.getAxiosClient().get(`/api/farmmonitoringdetails?farmPrimaryIdFromMongoose=${id}`);
};

const create = data => {
    return HttpService.getAxiosClient().post("/api/farmmonitoringdetails/", data);
};

const update = (id, data) => {
    return HttpService.getAxiosClient().patch(`/api/farmmonitoringdetails/${id}`, data);
};


const findByFarmerEmail = param => {
    return HttpService.getAxiosClient().get(`/api/farmmonitoringdetails?email=${param}`);
};

const FarmMonitoringDetailsDataService = {
    getAll,
    get,
    create,
    update,
    findByFarmerEmail
};

export default FarmMonitoringDetailsDataService;
