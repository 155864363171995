import { SignedIn, SignedOut, SignIn, useAuth } from '@clerk/clerk-react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import i18n from "i18next";
import { useEffect } from 'react';
import { initReactI18next } from "react-i18next";
import AppBarComponent from "./components/AppBarComponent";
import FooterComponent from "./components/FooterComponent";

import { useDispatch } from 'react-redux';
import { resetFarmlandData } from './actions/currentFarmland';
import { resetCurrentMonitoringData } from './actions/currentMonitoringData';
import { resetFarmStatus } from './actions/farmStatus';
import { resetFarmVerificationDocuments } from './actions/verificationDocuments';
import palette from './muiTheme/palette';
import typography from './muiTheme/typography';
import HttpService from './services/HttpService';
import { translationDE } from "./translations/de";
import { translationEN } from "./translations/en";
import { translationES } from "./translations/es";
import { translationPT } from "./translations/pt";



export const theme = createTheme({
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  palette,
  typography
});

function AppRoutes() {
  const { getToken, isSignedIn } = useAuth();

  i18n.use(initReactI18next).init({
    resources: {
      en_EN: {
        translation: translationEN,
      },
      pt_PT: {
        translation: translationPT,
      },
      es_ES: {
        translation: translationES,
      },
      de_DE: {
        translation: translationDE,
      },
    },
    lng: "en_EN",
    fallbackLng: "en_EN",
    interpolation: { escapeValue: false },
  });

  const dispatch = useDispatch();

  // Set the auth token when the user is signed in
  useEffect(() => {
    const setToken = async () => {
      if (isSignedIn) {
        try {
          const token = await getToken();
          HttpService.setAuthToken(token);
        } catch (error) {
          console.error('Error setting auth token:', error);
        }
      } else {
        dispatch(resetFarmlandData());
        dispatch(resetFarmStatus());
        dispatch(resetCurrentMonitoringData());
        dispatch(resetFarmVerificationDocuments());
      }
    };

    setToken();
  }, [isSignedIn, getToken]);

  return (

    <div className="container">
      <SignedOut>
        <SignIn />
      </SignedOut>
      <SignedIn>
        <ThemeProvider theme={theme}>
          <AppBarComponent />
        </ThemeProvider>
      </SignedIn>
      {<FooterComponent />}
    </div>

  );
}

export default AppRoutes;
