import axios from 'axios';

const _axios = axios.create({
  baseURL: process.env.REACT_APP_NODE_API,
  headers: {
    'Content-type': 'application/json',
  },
});

const setAuthToken = (token) => {
  _axios.interceptors.request.use(
    (config) => {
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

const getAxiosClient = () => _axios;

const HttpService = {
  getAxiosClient,
  setAuthToken,
};

export default HttpService;
