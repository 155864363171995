import { GET_FARM_STATUS, RESET_FARM_STATUS, SET_FARM_STATUS } from "./types";

export const setFarmStatus = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: SET_FARM_STATUS,
            payload: data,
        });
        return Promise.resolve(res);
    } catch (error) {
        console.log(error);
    }
};

export const getFarmStatus = () => async (dispatch) => {
    try {
        const res = dispatch({
            type: GET_FARM_STATUS
        });
        return Promise.resolve(res);
    } catch (error) {
        console.log(error);
    }
};


export const resetFarmStatus = () => async (dispatch) => {
    try {
        const res = dispatch({
            type: RESET_FARM_STATUS
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};