import { useTranslation } from "react-i18next";
import { manualIcon } from "../assets/svgs";

import { Box, Link, List, ListItem, Typography } from "@mui/material";

const GettingStartedComponent = () => {
  const { t, i18n } = useTranslation();


  const lang = i18n?.language

  const tutorial = [
    { title: "Tutorial - PT", link: "https://docs.google.com/document/d/1mOQ7pNzDdfesuSli3WlvDEBbrJu4uZEqhImB9VLz5xc/edit#heading=h.921g3b4dzgt8", },
    { title: "Tutorial - ES", link: "https://docs.google.com/document/d/16Qwf72fDhIQj0n5exV2kkMpNLsXpmOKsHabnlPzuteI/edit", },
  ]

  return (
    <Box
      sx={{
        padding: 2,
        border: 1,
        borderColor: "#DBDBDB",
        borderRadius: "12px",
        display: "flex",
        flexDirection: "column",
        mr: 2,
      }}
    >
      <Typography variant="h3">{t("home_getting")}</Typography>
      <List>
        {tutorial.map(lang =>
          <ListItem key={lang.title} sx={{ padding: "6px 0px" }}>
            {manualIcon}
            <Typography
              fontSize={14}
              color="#000000"
              sx={{ textDecorationColor: "#000000", pl: 2 }}
              component={Link}
              target="_blank"
              href={lang.link}
            >
              {lang.title}
            </Typography>
          </ListItem>)}
      </List>
    </Box>
  );
};

export default GettingStartedComponent;
