export const cropsArray = [
    "herbaceous perennials - strawberry",
    "herbaceous perennials - other",
    "bushes/shrubs - blackberry",
    "bushes/shrubs - blueberry",
    "bushes/shrubs - broom",
    "bushes/shrubs - chamise/greasewood",
    "bushes/shrubs - heather/erica",
    "bushes/shrubs - laustrine",
    "bushes/shrubs - lavender",
    "bushes/shrubs - lentisk/mastic",
    "bushes/shrubs - rockrose",
    "bushes/shrubs - rosemary",
    "bushes/shrubs - sagebrush",
    "bushes/shrubs - other",
    "fruit tree - apple",
    "fruit tree - apricot",
    "fruit tree - avocado",
    "fruit tree - clementine",
    "fruit tree - date palm",
    "fruit tree - fig",
    "fruit tree - lemon",
    "fruit tree - mandarin",
    "fruit tree - mango",
    "fruit tree - olive",
    "fruit tree - orange",
    "fruit tree - peach",
    "fruit tree - pear",
    "fruit tree - pomegranate",
    "fruit tree - pomelo",
    "fruit tree - other",
    "nut tree - almond",
    "nut tree - chestnut",
    "nut tree - hazelnut",
    "nut tree - pistachio",
    "nut tree - walnut",
    "nut tree - other",
    "tree - ash",
    "tree - eucalyptus",
    "tree - carob",
    "tree - cork oak",
    "tree - cypress",
    "tree - holm oak",
    "tree - maple/sycamore",
    "tree - pine",
    "tree - poplar",
    "tree - other",
    "vine - grapevine",
    "vine - other",
    "other perennial crop"
]

export const annualCropsArray = [
    "aromatics",
    "cover crop - single or multiple grasses",
    "cover crop - single or multiple legumes",
    "cover crop - mix of grass and legumes",
    "cover crop - multi-species cover crop",
    "cover crop - other",
    "forage - grass-clover mixtures",
    "forage - italian ryegrass",
    "forage - n-fixing forages",
    "forage - non-n-fixing forages",
    "forage - non-legume hay",
    "forage - sudangrass",
    "forage - other",
    "grains/cereals - barley",
    "grains/cereals - maize",
    "grains/cereals - millet",
    "grains/cereals - oats",
    "grains/cereals - rice",
    "grains/cereals - rye",
    "grains/cereals - sorghum",
    "grains/cereals - spring wheat",
    "grains/cereals - triticale",
    "grains/cereals - wheat",
    "grains/cereals - winter wheat",
    "grains/cereals - other",
    "legumes - alfalfa",
    "legumes - beans",
    "legumes - cowpea",
    "legumes - lupins",
    "legumes - peas",
    "legumes - soybeans",
    "legumes - vetch",
    "legumes - other",
    "roots/tubers - potatoes",
    "roots/tubers - beets",
    "roots/tubers - other",
    "fruits and vegetables - squash",
    "fruits and vegetables - other fruit",
    "fruits and vegetables - other vegetable",
    "oilseeds - peanuts",
    "oilseeds - rapeseed",
    "oilseeds - sunflower",
    "oilseeds - other",
    "other annual crop"
]

export const monthArray = [
    { value: "-01-01", text: "pci_pastures_fallow_period_end_month_jan" },
    { value: "-02-01", text: "pci_pastures_fallow_period_end_month_feb" },
    { value: "-03-01", text: "pci_pastures_fallow_period_end_month_mar" },
    { value: "-04-01", text: "pci_pastures_fallow_period_end_month_apr" },
    { value: "-05-01", text: "pci_pastures_fallow_period_end_month_may" },
    { value: "-06-01", text: "pci_pastures_fallow_period_end_month_jun" },
    { value: "-07-01", text: "pci_pastures_fallow_period_end_month_jul" },
    { value: "-08-01", text: "pci_pastures_fallow_period_end_month_aug" },
    { value: "-09-01", text: "pci_pastures_fallow_period_end_month_sep" },
    { value: "-10-01", text: "pci_pastures_fallow_period_end_month_oct" },
    { value: "-11-01", text: "pci_pastures_fallow_period_end_month_nov" },
    { value: "-12-01", text: "pci_pastures_fallow_period_end_month_dec" }
]

export const monthArray1 = [
    { value: "-01-01", text: "pci_pastures_grass_harvested_month_jan" },
    { value: "-02-01", text: "pci_pastures_grass_harvested_month_feb" },
    { value: "-03-01", text: "pci_pastures_grass_harvested_month_mar" },
    { value: "-04-01", text: "pci_pastures_grass_harvested_month_apr" },
    { value: "-05-01", text: "pci_pastures_grass_harvested_month_may" },
    { value: "-06-01", text: "pci_pastures_grass_harvested_month_jun" },
    { value: "-07-01", text: "pci_pastures_grass_harvested_month_jul" },
    { value: "-08-01", text: "pci_pastures_grass_harvested_month_aug" },
    { value: "-09-01", text: "pci_pastures_grass_harvested_month_sep" },
    { value: "-10-01", text: "pci_pastures_grass_harvested_month_oct" },
    { value: "-11-01", text: "pci_pastures_grass_harvested_month_nov" },
    { value: "-12-01", text: "pci_pastures_grass_harvested_month_dec" }
]
export const monthArray2 = [
    { value: "-01-01", text: "pci_perennial_tree_list_harvest_month_jan" },
    { value: "-02-01", text: "pci_perennial_tree_list_harvest_month_feb" },
    { value: "-03-01", text: "pci_perennial_tree_list_harvest_month_mar" },
    { value: "-04-01", text: "pci_perennial_tree_list_harvest_month_apr" },
    { value: "-05-01", text: "pci_perennial_tree_list_harvest_month_may" },
    { value: "-06-01", text: "pci_perennial_tree_list_harvest_month_jun" },
    { value: "-07-01", text: "pci_perennial_tree_list_harvest_month_jul" },
    { value: "-08-01", text: "pci_perennial_tree_list_harvest_month_aug" },
    { value: "-09-01", text: "pci_perennial_tree_list_harvest_month_sep" },
    { value: "-10-01", text: "pci_perennial_tree_list_harvest_month_oct" },
    { value: "-11-01", text: "pci_perennial_tree_list_harvest_month_nov" },
    { value: "-12-01", text: "pci_perennial_tree_list_harvest_month_dec" }
]

export const monthArray3 = [
    { value: "-01-01", text: "pci_perennial_tree_list_residue_month_jan" },
    { value: "-02-01", text: "pci_perennial_tree_list_residue_month_feb" },
    { value: "-03-01", text: "pci_perennial_tree_list_residue_month_mar" },
    { value: "-04-01", text: "pci_perennial_tree_list_residue_month_apr" },
    { value: "-05-01", text: "pci_perennial_tree_list_residue_month_may" },
    { value: "-06-01", text: "pci_perennial_tree_list_residue_month_jun" },
    { value: "-07-01", text: "pci_perennial_tree_list_residue_month_jul" },
    { value: "-08-01", text: "pci_perennial_tree_list_residue_month_aug" },
    { value: "-09-01", text: "pci_perennial_tree_list_residue_month_sep" },
    { value: "-10-01", text: "pci_perennial_tree_list_residue_month_oct" },
    { value: "-11-01", text: "pci_perennial_tree_list_residue_month_nov" },
    { value: "-12-01", text: "pci_perennial_tree_list_residue_month_dec" }
]


export const monthArray4 = [
    { value: "-01-01", text: "pci_annual_crops_harvest_month_jan" },
    { value: "-02-01", text: "pci_annual_crops_harvest_month_feb" },
    { value: "-03-01", text: "pci_annual_crops_harvest_month_mar" },
    { value: "-04-01", text: "pci_annual_crops_harvest_month_apr" },
    { value: "-05-01", text: "pci_annual_crops_harvest_month_may" },
    { value: "-06-01", text: "pci_annual_crops_harvest_month_jun" },
    { value: "-07-01", text: "pci_annual_crops_harvest_month_jul" },
    { value: "-08-01", text: "pci_annual_crops_harvest_month_aug" },
    { value: "-09-01", text: "pci_annual_crops_harvest_month_sep" },
    { value: "-10-01", text: "pci_annual_crops_harvest_month_oct" },
    { value: "-11-01", text: "pci_annual_crops_harvest_month_nov" },
    { value: "-12-01", text: "pci_annual_crops_harvest_month_dec" }
]

export const monthArray5 = [
    { value: "-01-01", text: "pci_pastures_permanent_pastures_peak_month_jan" },
    { value: "-02-01", text: "pci_pastures_permanent_pastures_peak_month_feb" },
    { value: "-03-01", text: "pci_pastures_permanent_pastures_peak_month_mar" },
    { value: "-04-01", text: "pci_pastures_permanent_pastures_peak_month_apr" },
    { value: "-05-01", text: "pci_pastures_permanent_pastures_peak_month_may" },
    { value: "-06-01", text: "pci_pastures_permanent_pastures_peak_month_jun" },
    { value: "-07-01", text: "pci_pastures_permanent_pastures_peak_month_jul" },
    { value: "-08-01", text: "pci_pastures_permanent_pastures_peak_month_aug" },
    { value: "-09-01", text: "pci_pastures_permanent_pastures_peak_month_sep" },
    { value: "-10-01", text: "pci_pastures_permanent_pastures_peak_month_oct" },
    { value: "-11-01", text: "pci_pastures_permanent_pastures_peak_month_nov" },
    { value: "-12-01", text: "pci_pastures_permanent_pastures_peak_month_dec" }
]

export const residueTypeArray = [
    { value: "fruit", text: "pci_perennial_tree_list_residue_type_dd_1" },
    { value: "seeds", text: "pci_perennial_tree_list_residue_type_dd_2" },
    { value: "fruits / vegetables", text: "pci_perennial_tree_list_residue_type_dd_3" },
    { value: "leaves, stalks, herbs (non-woody)", text: "pci_perennial_tree_list_residue_type_dd_4" },
    { value: "pruning or woody material", text: "pci_perennial_tree_list_residue_type_dd_5" },
    { value: "grass", text: "pci_perennial_tree_list_residue_type_dd_6" }
];

