import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import dateFormat from "dateformat";

import '../css/Dashboard.css';

import {
  Alert,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from "@mui/material";

import { retrieveFarmAndFarmSettings } from '../actions/farmAndFarmSettings';

import { findCarbonByFarmId } from '../actions/carbonResults';
import { getExistingFarmland, resetFarmlandData } from '../actions/currentFarmland';
import { resetCurrentMonitoringData, setCurMonitoringData } from '../actions/currentMonitoringData';
import { findSettingsByFarmerPrimaryId } from '../actions/farmSettings';
import { resetFarmStatus } from '../actions/farmStatus';
import { findMonitoringDataByFarmId } from '../actions/monitoringData';
import { resetFarmVerificationDocuments } from '../actions/verificationDocuments';
import CarbonResultsDialog from './PopUps/CarbonResultsDialog';
import FarmStatusDialog from './PopUps/FarmStatusDialog';


const AdminDashboardComponent = () => {

  const [farmStatusOpen, setFarmStatusOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [farmers, setFarmers] = useState([]);
  const [allFarmersData, setAllFarmersData] = useState([]);
  const [farmID, setFarmID] = useState(null);
  const [farmSettingsStatus, setFarmSettingsStatus] = useState(null);
  const [allowEditing, setAllowEditing] = useState(null);
  const [farmAndFarmSettingsData, setFarmAndFarmSettingsData] = useState(null);

  let navigate = useNavigate();
  const columns = [
    { id: 'id', hide: true },
    { id: 'status', label: 'Status', minWidth: 200 },
    { id: 'landOwner', label: 'Land Owner', minWidth: 100 },

    {
      id: 'email',
      label: 'Email',
      minWidth: 100,
      align: 'right',
    },
    {
      id: 'country',
      label: 'Country',
      minWidth: 100,
      align: 'right',
    },
    {
      id: 'date',
      label: 'Farm Data Updated Date',
      minWidth: 100,
      align: 'right',
    }

  ]

  const [carbonResults, setCarbonResults] = useState(null);
  const [carbonResultsOpen, setCarbonResultsOpen] = useState(false);
  const dispatch = useDispatch();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  const fetchAllFarmers = () => {
    dispatch(retrieveFarmAndFarmSettings())
      .then(res => {
        if (res?.data) {
          const tmpFullFarmerData = [];
          const tmpArr = [];
          setFarmAndFarmSettingsData(res.data);
          res.data.forEach(item => {
            const farm = item.farm[0];
            tmpFullFarmerData.push(farm);
            const arr = {
              id: farm._id,
              status: item.farmStatus,
              landOwner: farm.farmInfo.landOwnerFirstName,
              email: farm.farmInfo.email,
              country: farm.farmInfo.country,
              date: dateFormat(farm.dateOfSubmission, "dd/mm/yyyy"),
            };
            tmpArr.push(arr);
          });
          setFarmers(tmpArr);
          setAllFarmersData(tmpFullFarmerData);
        } else {
          console.log('No farm and farm settings data found.');
        }
      })
      .catch(error => {
        console.error('Error retrieving farm and farm settings:', error);
      });
  };

  const viewCarbonResults = (farmId) => {
    let farmPrimaryId = null;
    for (let i = 0; i < allFarmersData.length; i++) {
      let row = allFarmersData[i];
      if (row._id === farmId) {
        farmPrimaryId = row.farmInfo.farmId;
        break;
      }
    }

    if (farmPrimaryId !== null) {
      dispatch(findCarbonByFarmId(farmPrimaryId))
        .then(carbon => {
          if (carbon) {
            setCarbonResults(carbon[0]);
          } else {
            setCarbonResults(null);
          }
          setCarbonResultsOpen(true);
        })
        .catch(error => {
          console.error('Error:', error);
          setCarbonResults(null);
        });
    } else {
      setCarbonResults(null);
    }
  }


  const editFarmData = (farmId, button) => {
    allFarmersData.forEach(row => {
      if (row._id === farmId) {
        dispatch(findSettingsByFarmerPrimaryId(farmId))
          .then(res => {
            dispatch(getExistingFarmland(row))
              .then(newFarm => {
                console.log("New farm data:", newFarm);
              });
            dispatch(findMonitoringDataByFarmId(farmId))
              .then(res => {
                dispatch(setCurMonitoringData(res.data[0]));
              });
            button === 'edit' ?
              navigate('/farmland', { state: { currentFarmStatus: res[0] } }) :
              setAllowEditing(res[0].allowEditing)
          })
          .catch(error => {
            console.error(error);
          });
      }
    });

  };

  const updateFarmStatus = (id, status) => {
    setFarmID(id);
    editFarmData(id, 'status')
    setFarmSettingsStatus(status);
    setFarmStatusOpen(true);
  }

  const handleCarbonResultsDialogClose = () => {
    setCarbonResults(null);
    setCarbonResultsOpen(false);
  };

  useEffect(() => {
    fetchAllFarmers();
  }, [])

  useEffect(() => {
    // Dispatch actions to reset state
    dispatch(resetFarmlandData());
    dispatch(resetFarmStatus());
    dispatch(resetCurrentMonitoringData());
    dispatch(resetFarmVerificationDocuments());
  }, [dispatch]);

  return (

    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} style={{ justifyContent: 'center', alignContent: 'center', marginLeft: '10px' }}>
          <Alert icon={false} severity="warning">
            <div style={{ fontWeight: 'bold' }}>
              <h2><span>List of Farmers</span></h2>
            </div>
          </Alert>
        </Grid>
        <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: '1%', marginLeft: '2%' }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {farmers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                        {columns.map((column) => {

                          const value = row[column.id];
                          if (column.id === 'id') {
                            return (
                              <TableCell key={column.id} align={column.align}>
                                <Button size='large' color='success' onClick={() => updateFarmStatus(value, row['status'])}>Status</Button>
                                <Button size='large' color='error' onClick={() => viewCarbonResults(row.id)} >Carbon</Button>
                                <Button size='large' color='warning' onClick={() => editFarmData(row.id, 'edit')} >Edit Farm</Button>

                              </TableCell>)
                          } else {
                            return (
                              <TableCell key={column.id} align={column.align}>{value}</TableCell>
                            );
                          }


                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={farmers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>

        <FarmStatusDialog farmStatusOpen={farmStatusOpen} setFarmStatusOpen={setFarmStatusOpen} farmAndFarmSettingsData={farmAndFarmSettingsData} farmSettingsStatus={farmSettingsStatus} farmID={farmID} setFarmSettingsStatus={setFarmSettingsStatus} fetchAllFarmers={fetchAllFarmers} allowEditing={allowEditing} />
        <CarbonResultsDialog carbonResultsOpen={carbonResultsOpen} carbonResults={carbonResults} handleCarbonResultsDialogClose={handleCarbonResultsDialogClose} />
      </Grid>
    </div >
  )
}

export default AdminDashboardComponent