import MonitoringDataService from "../farmservices/MonitoringDataService";
import { CREATE_MONITORING_DATA, FIND_MONITORING_DATA_BY_FARM_ID, UPDATE_MONITORING_DATA } from "./types";

export const createMonitoringData = (data) => async (dispatch) => {
    try {

        const res = await MonitoringDataService.create(data);
        dispatch({
            type: CREATE_MONITORING_DATA,
            payload: data

        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updateMonitoringData = (id, data) => async (dispatch) => {
    try {
        const res = await MonitoringDataService.update(id, data);
        dispatch({
            type: UPDATE_MONITORING_DATA,
            payload: data,
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const findMonitoringDataByFarmId = (data) => async (dispatch) => {
    try {
        const res = await MonitoringDataService.findByFarmId(data);
        dispatch({
            type: FIND_MONITORING_DATA_BY_FARM_ID,
            payload: data,
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};


