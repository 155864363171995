
import React, {
  createContext,
  useEffect,
  useRef,
  useState
} from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import mapboxgl from "mapbox-gl";
import ReactMapboxGl, { Popup } from "react-mapbox-gl";
import { MapControl } from "./Map/MapControl";

import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";

import {
  Box,
  Grid,
  Typography
} from "@mui/material";

import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "../../css/LiveStockPopUp.css";
import "../../css/MapParcel.css";

import BackHomeComponent from "../general/BackHomeComponent";
import Step4MapParcelsListComponent from "./ParcelsListComponent";


import { setFarmStatus } from "../../actions/farmStatus";

import { useUser } from "@clerk/clerk-react";
import {
  addCreatedParcelsToYearlyFarmData,
  deleteParcel,
  getMonitoringData
} from "../../actions/currentMonitoringData";
import { updateMonitoringData } from "../../actions/monitoringData";
import { FARM_DATA_IS_YET_TO_BE_SUBMITTED } from "../../constants/constants";
import { SubmitButton } from "../../styles";
import ParcelEditDialog from "../PopUps/ParcelEditDialog";
import ConfirmDialog from "../general/ConfirmDialog";
import SubmitValidation from "../general/Validations/SubmitValidation";
import DigitalMaps from "./DigitalMaps";
import { KMLFileToParcels } from "./KMLFileToParcels";
import { getTotalAreaOfTheFarm } from "./getTotalAreaOfTheFarm";
import { getKmlObjectBody } from './unpackKML/getKmlObjectBody';
import { kmzToKml } from "./unpackKML/kmzToKml";

/* eslint import/no-webpack-loader-syntax: off */
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
//import 'mapbox-gl/dist/mapbox-gl.css';

const TOKEN = process.env.REACT_APP_MAPBOX_API_KEY; // Set your mapbox token here
export const MapContext = createContext({});
var Map = ReactMapboxGl({
  accessToken: TOKEN,
});

const MapParcelsComponent = () => {
  const { t, i18n } = useTranslation();

  const { user } = useUser();
  const email = user?.emailAddresses[0]?.emailAddress;

  const currentFarmDataObject = useSelector(
    (state) => state.currentFarmDataReducer
  );
  const currentFarmStatusReducer = useSelector(
    (state) => state.farmStatusReducer
  );
  const currentMonitoringDataObject = useSelector(
    (state) => state.currentMonitoringDataReducer
  );
  const currentFarmVerificationDocuments = useSelector(
    (state) => state.farmVerificationDocumentsReducer
  );

  const [creatingNewParcels, setCreatingNewParcels] = useState(false);
  const [viewState, setViewState] = useState({
    longitude: -4,
    latitude: 40,
    zoom: [14]
  });

  var mapRef = useRef();
  var drawRef = useRef();
  const [valid, setValid] = useState({ farmwide: false });

  const [farmCoordinatesInDecimal, setFarmCoordinatesInDecimal] = useState([]);
  const [currentlyEditingParcelName, setCurrentlyEditingParcelName] =
    useState(null);
  const [
    currentlyEditingParcelManualArea,
    setCurrentlyEditingParcelManualArea,
  ] = useState(0);
  //used to track feature id to polygon id to enable deletion
  //const [featureIDToPolygonIDHashMap, setFeatureIDToPolygonIDHashMap] = useState(Map());

  const [featureIDsToParceIDsHashMap, setFeatureIDsToParceIDsHashMap] = useState([]);
  const [deleteMapDialogOpen, setDeleteMapDialogOpen] = useState(false);
  const [mapPopUpForParcel, setMapPopUpForParcel] = useState(null)
  const [backdropParcelCreation, setBackdropParcelCreation] = useState(false);
  const [showMapParcelPopUp, setShowMapParcelPopUp] = useState(false);
  const [selectedMapParcelPopUpCoordinates, setSelectedMapParcelPopUpCoordinates,] = useState(null);
  const [selectedMapParcelPopUpRow, setSelectedMapParcelPopUpRow] = useState(null);
  const [editParcelNamePopUpOpen, setEditParcelNamePopUpOpen] = useState(false);


  const accountSettings = useSelector((state) => state.accountSettingsReducer);
  const isAdmin = accountSettings?.[0]?.role === 'admin'

  const renameParcelName = (e) => {
    const tempParcelName = currentMonitoringDataObject?.yearlyFarmData[currentMonitoringDataObject?.yearlyFarmData?.length - 1]?.parcelLevelData.find(ele => ele.id === mapPopUpForParcel?.selectedRow?.id)
    if (tempParcelName) {
      setCurrentlyEditingParcelName(
        tempParcelName?.parcelFixedValues?.parcelName
      );
      setCurrentlyEditingParcelManualArea(tempParcelName?.parcelFixedValues?.areaManualEntry?.area)
      setEditParcelNamePopUpOpen(true);
    }
  };

  const delParcelFromMap = (parcelIdToDelete) => {
    if (
      mapRef?.current?.state?.map?.getSource(parcelIdToDelete)
    ) {
      mapRef?.current?.state?.map?.removeLayer(parcelIdToDelete);
      let outlineId = "outline".concat(parcelIdToDelete);
      let hoverId = "hover_".concat(parcelIdToDelete);
      mapRef?.current?.state?.map?.removeLayer(outlineId);
      mapRef?.current?.state?.map?.removeLayer(hoverId);
      mapRef?.current?.state?.map?.removeSource(parcelIdToDelete);
    }
  };

  const handleDeleteMapParcel = () => {
    let tmpFunParam = {
      parcel: mapPopUpForParcel?.selectedRow,
    };
    delParcelFromMap(mapPopUpForParcel?.selectedRow?.id)
    setEditParcelNamePopUpOpen(false);
    setMapPopUpForParcel(null)
    dispatch(deleteParcel(tmpFunParam))
      .then((res) => {
        return dispatch(getMonitoringData());
      })
      .then((res2) => {
        return dispatch(updateMonitoringData(currentMonitoringDataObject._id, currentMonitoringDataObject));
      })
      .then((res3) => {
        setDeleteMapDialogOpen(false);
      })
      .catch((error) => {
        console.error('Error in the parcel deletion process:', error);
      });
  };


  const dispatch = useDispatch();
  const [allowEditing, setAllowEditing] = useState(true);


  const getFarmlandDetails = () => {
    if (!isAdmin) {
      if (
        currentFarmStatusReducer?.status === FARM_DATA_IS_YET_TO_BE_SUBMITTED &&
        currentFarmStatusReducer?.allowEditing === false &&
        currentFarmStatusReducer?.editFarm === false
      ) {
        let tmpData = {
          status: FARM_DATA_IS_YET_TO_BE_SUBMITTED,
          allowEditing: true,
          editFarm: true,
        };
        dispatch(setFarmStatus(tmpData))
          .then((res) => {
            console.log('Farm status set successfully:', res);
          })
          .catch((error) => {
            console.error('Error setting farm status:', error);
          });
      }
    }
    setAllowEditing(
      currentFarmStatusReducer.allowEditing && currentFarmStatusReducer.editFarm
    );
  };

  const getFarmCoordinates = () => {
    if (
      currentMonitoringDataObject?.yearlyFarmData[
        currentMonitoringDataObject?.yearlyFarmData.length - 1
      ].parcelLevelData.length !== 0
    ) {
      setFarmCoordinatesInDecimal([]);
      const lng =
        currentMonitoringDataObject?.yearlyFarmData[
          currentMonitoringDataObject?.yearlyFarmData.length - 1
        ].parcelLevelData[0]?.parcelFixedValues.coordinates[0][0];
      const lat =
        currentMonitoringDataObject?.yearlyFarmData[
          currentMonitoringDataObject?.yearlyFarmData.length - 1
        ].parcelLevelData[0]?.parcelFixedValues.coordinates[0][1];

      //setFarmCoordinatesInDecimal([, currentMonitoringDataObject?.yearlyFarmData[currentMonitoringDataObject?.yearlyFarmData.length-1].parcelLevelData[0]?.parcelFixedValues?.coordinates[0][1]])
      setFarmCoordinatesInDecimal([...farmCoordinatesInDecimal, lat]);
      setFarmCoordinatesInDecimal([...farmCoordinatesInDecimal, lng]);
      setViewState({
        longitude: lng,
        latitude: lat,
        zoom: [14]
      })
    }
    if (farmCoordinatesInDecimal === null) {
    } else {
    }
  };

  getTotalAreaOfTheFarm(currentMonitoringDataObject)


  useEffect(() => {
    getFarmlandDetails();
    getFarmCoordinates();

  }, []);

  const kmlFromVerification = currentFarmVerificationDocuments.kmlFiles && currentFarmVerificationDocuments?.kmlFiles[0]

  useEffect(() => {
    fetchData()
  }, [kmlFromVerification]);


  useEffect(() => { getFarmCoordinates() }, [currentMonitoringDataObject?.yearlyFarmData[
    currentMonitoringDataObject?.yearlyFarmData?.length - 1
  ]?.parcelLevelData?.length])


  const nrOfParcels = currentMonitoringDataObject?.yearlyFarmData?.[4].parcelLevelData.length


  const [showParcelCreationLoader, setShowParcelCreationLoader] =
    useState(false);
  const currentMonitoringYearsObject = useSelector(state => state.monitoringYearsTrackerReducer);
  const allMonitoringYears = currentMonitoringYearsObject.allMonitoringYears

  const KMLFileParse = (fileOrText) => {
    setCreatingNewParcels(true)
    const parseText = (text) => {
      const parser = new DOMParser();
      const kml = parser.parseFromString(text, "application/xml");
      const arrParcelLevelData = KMLFileToParcels(kml);
      let tmpParcelArrayData = {
        years: allMonitoringYears,
        parcels: arrParcelLevelData,
      };
      dispatch(addCreatedParcelsToYearlyFarmData(tmpParcelArrayData))
        .then((ans) => {
          console.log('Parcels added to yearly farm data:', ans);
        })
        .catch((error) => {
          console.error('Error adding parcels to yearly farm data:', error);
        })
        .finally(() => {
          setShowParcelCreationLoader(false);
          setBackdropParcelCreation(false);
        });
    };

    if (typeof fileOrText === 'string') {
      // Text content is provided directly
      parseText(fileOrText);
    } else {
      // File is provided
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target.result;
        parseText(text);
      };
      reader.readAsText(fileOrText);
      setShowParcelCreationLoader(true);
      setBackdropParcelCreation(true);
    }
  };


  const fetchData = async () => {
    if (currentFarmVerificationDocuments.email !== currentMonitoringDataObject.email) {
      return
    } else {
      try {
        if (!currentMonitoringDataObject?.yearlyFarmData[currentMonitoringDataObject?.yearlyFarmData.length - 1].parcelLevelData.length && kmlFromVerification) {
          const kmlExtention = kmlFromVerification?.fileName.slice(-3)
          if (kmlExtention !== "kml") { await kmzToKml(kmlFromVerification, dispatch, currentFarmVerificationDocuments); }
          const kmlBody = await getKmlObjectBody(currentFarmVerificationDocuments);
          KMLFileParse(kmlBody);
        }
      } catch (error) {
      }
    }

  };

  const contractFarmSize = currentFarmDataObject.farmInfo.farmSize
  const calculatedFarmSize = getTotalAreaOfTheFarm(currentMonitoringDataObject)

  const isCalculatedAreaCorrect = calculatedFarmSize <= contractFarmSize
  const isCalculatedAreaInRange = calculatedFarmSize <= contractFarmSize * 1.1

  useEffect(() => {
    if (creatingNewParcels) {
      dispatch(
        updateMonitoringData(
          currentMonitoringDataObject._id,
          currentMonitoringDataObject
        )
      )
        .then((res) => {
          console.log('Monitoring data updated successfully:', res);
        })
        .catch((error) => {
          console.error('Error updating monitoring data:', error);
        })
        .finally(() => {
          setCreatingNewParcels(false);
        });
    }
  }, [currentMonitoringDataObject?.yearlyFarmData]);

  return (
    <>
      {!isAdmin && (
        <Box marginLeft={6}>
          <BackHomeComponent></BackHomeComponent>
        </Box>
      )}

      <Box margin={12} marginTop={5}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography
              variant='h1'
            >
              {t("pmo_map_title")}
            </Typography>


            <>
              <Typography variant="subtitle1">
                {t("pmo_map_subtitle")}
              </Typography>
              <MapContext.Provider value={{
                mapRef,
                drawRef
              }}
              >
                {isAdmin && < DigitalMaps currentFarmVerificationDocuments={currentFarmVerificationDocuments} page='Maps' />}
              </MapContext.Provider>
            </>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Map
              {...viewState}
              center={[viewState?.longitude, viewState?.latitude]}
              mapboxAccessToken={TOKEN}
              style="mapbox://styles/mapbox/satellite-v9" // eslint-disable-line
              containerStyle={{
                height: "60vh",
              }}
              displayControlsDefault={false}
              ref={mapRef}
            >

              {mapPopUpForParcel && (
                <Popup
                  coordinates={[
                    mapPopUpForParcel?.longitude,
                    mapPopUpForParcel?.latitude,
                  ]}
                  anchor="bottom"

                  style={{ width: "250px", height: "450px" }}
                >
                  <div>
                    <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "bold", cursor: "pointer" }}>
                      <Typography variant="h3">{t("pmo_popup_title")}</Typography> <Typography variant="subtitle3" style={{ cursor: "pointer" }} onClick={() => setMapPopUpForParcel(null)}>X</Typography>
                    </div>
                    <div>
                      {(currentMonitoringDataObject?.yearlyFarmData[currentMonitoringDataObject?.yearlyFarmData.length - 1]?.parcelLevelData?.find(pOb => pOb.id === mapPopUpForParcel?.selectedRow?.id))?.parcelFixedValues?.parcelName}
                    </div>
                    <div style={{ margin: "5px" }}>
                      <SubmitButton
                        onClick={(e) => renameParcelName(e)}
                        style={{ width: "180px" }}
                      >
                        {t("pmo_popup_rename")}
                      </SubmitButton>
                    </div>
                    {isAdmin && <div style={{ margin: "5px" }}>
                      <SubmitButton
                        onClick={() => setDeleteMapDialogOpen(true)}
                        style={{ width: "180px" }}
                      >
                        {t("pmo_popup_delete")}
                      </SubmitButton>
                    </div>}
                  </div>
                </Popup>
              )}
              <MapContext.Provider
                value={{
                  mapRef,
                  featureIDsToParceIDsHashMap,
                  setFeatureIDsToParceIDsHashMap,
                  drawRef,
                  showMapParcelPopUp,
                  setShowMapParcelPopUp,
                  selectedMapParcelPopUpCoordinates,
                  setSelectedMapParcelPopUpCoordinates,
                  selectedMapParcelPopUpRow,
                  setSelectedMapParcelPopUpRow,
                }}
              >
                <MapControl
                  control={
                    new MapboxGeocoder({
                      accessToken: TOKEN,
                      reverseGeocode: true,
                      marker: false,
                      // add condition depending on user accountSettings
                      language: "en",
                      country: "EN",
                    })

                  }
                  setMapPopUpForParcel={setMapPopUpForParcel}
                  setViewState={setViewState}
                />
              </MapContext.Provider>
            </Map>
          </Grid>
          <Grid
            marginTop={2}
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            display="flex"
            sx={{ flexDirection: "column" }}
          >
            <Typography variant="h2">
              {t("fwi_screen_title")}
            </Typography>

            <Typography variant="h3" sx={{
              color: isCalculatedAreaInRange ? "" : "#db6160",
            }}>
              {isCalculatedAreaCorrect ? '' : (
                isCalculatedAreaInRange ? (
                  <Trans i18nKey="fwi_farmSize_largerWithinLimit" values={{ calculatedFarmSize, contractFarmSize }}>
                    The sum of parcel areas ({{ calculatedFarmSize }}HA) is larger than the farm area specified in your contract ({{ contractFarmSize }}HA) but within a 10% tolerance limit. You can adjust the parcel areas manually to reduce this difference. Otherwise, parcel sizes will be adjusted automatically to match the farm size.
                  </Trans>
                ) : (
                  <Trans i18nKey="fwi_farmSize_tooLarge" values={{ calculatedFarmSize, contractFarmSize }}>
                    The sum of parcel areas ({{ calculatedFarmSize }}HA) is over 10% larger than the farm area specified in your contract ({{ contractFarmSize }}HA). Your farm map or contract needs to be revised.
                  </Trans>
                )
              )}
            </Typography>


            <Box marginTop={2} spacing={2} alignItems='center' display='flex' gap={2}>

              <Box sx={{ p: 1, border: '1px solid grey', borderRadius: 1 }} width={600} >
                <Typography variant="subtitle2">
                  {i18n.language === "en_EN" ?
                    ` ${currentFarmDataObject?.farmInfo?.farmManagerFirstName}'s farm | ${nrOfParcels} ${nrOfParcels > 1 ? 'Parcels' : 'Parcel'} |  ${getTotalAreaOfTheFarm(currentMonitoringDataObject).toFixed(2)} HA`
                    :
                    ` ${t("pmo_fwi_farm_from")} ${currentFarmDataObject?.farmInfo?.farmManagerFirstName} | ${nrOfParcels} ${nrOfParcels > 1 ? 'Parcelas' : 'Parcela'} |  ${getTotalAreaOfTheFarm(currentMonitoringDataObject)} HA`
                  }

                </Typography>
              </Box>
              <SubmitValidation page='farmwide' valid={valid['farmwide']} setValid={setValid} infoText='validation_farmwide' />
              <Link
                style={{
                  textDecoration: "none",
                  fontSize: '20px',
                  color: "#409781",
                  fontWeight: "bold",
                }}
                to={{
                  pathname: "/carbonplus/farmwideinformation"

                }}
              >
                {t("pmo_fwi_complete")}
              </Link>

            </Box>

          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            display="flex"
            sx={{ flexDirection: "column" }}
          >
            <div>
              <Typography marginBottom={1} marginTop={2} variant="h2">
                {t("pmo_list_title")}
              </Typography>
              <Typography fontSize={15}>
                {t("pmo_list_subtitle")}
              </Typography>
            </div>

            {mapRef && (
              <MapContext.Provider
                value={{
                  mapRef,
                  featureIDsToParceIDsHashMap,
                  setFeatureIDsToParceIDsHashMap,
                  drawRef,
                  showMapParcelPopUp,
                  setShowMapParcelPopUp,
                  selectedMapParcelPopUpCoordinates,
                  setSelectedMapParcelPopUpCoordinates,
                  selectedMapParcelPopUpRow,
                  setSelectedMapParcelPopUpRow,
                }}
              >
                <Step4MapParcelsListComponent />
              </MapContext.Provider>
            )}
          </Grid>
        </Grid>
      </Box >


      <ParcelEditDialog
        currentlyEditingParcelName={currentlyEditingParcelName}
        setCurrentlyEditingParcelName={setCurrentlyEditingParcelName}
        currentlyEditingParcelManualArea={currentlyEditingParcelManualArea}
        setCurrentlyEditingParcelManualArea={setCurrentlyEditingParcelManualArea}
        parcel={mapPopUpForParcel?.selectedRow}
        setMapPopUpForParcel={setMapPopUpForParcel}
        editParcelNamePopUpOpen={editParcelNamePopUpOpen}
        setEditParcelNamePopUpOpen={setEditParcelNamePopUpOpen}
        setDeleteMapDialogOpen={setDeleteMapDialogOpen}
      />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <ConfirmDialog onClose={() => setDeleteMapDialogOpen(false)} open={deleteMapDialogOpen} textQuestion={t("pmo_delete_parcel_message")} textYes={t("pmo_delete_parcel_yes")} textNo={t("pmo_delete_parcel_no")} handleDelete={handleDeleteMapParcel} textTitle={t("pmo_delete_map_heading")} />
        </Grid>
      </Grid>
    </>

  );
};

export default MapParcelsComponent;