import { addNewEmptyHarvestToPasturesData } from "../../../actions/currentMonitoringData";
import { tmpData } from "./tmpData";

export const addNewPasturesHarvest = (e, i, year, dispatch, parcelid) => {

    let funParam = {
        year: year,
        data: tmpData,
        parcelId: parcelid,
        pasturesId: i
    }
    dispatch(addNewEmptyHarvestToPasturesData(funParam))
};