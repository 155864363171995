// INDIRECT FUEL

export const externalServicesArray = [
    { value: "fertilization", text: "fu_ind_ext_serv_1" },
    { value: "tillage", text: "fu_ind_ext_serv_2" },
    { value: "planting", text: "fu_ind_ext_serv_3" },
    { value: "weed control", text: "fu_ind_ext_serv_4" },
    { value: "harvesting", text: "fu_ind_ext_serv_5" }
]

export const ServiceCategoryArray = [
    { value: "fertilization-spreading-dry-fertilizer-bulk-cart", category: "fertilization" },
    { value: "fertilization-anhydrous-ammonia-30-inch-spacing", category: "fertilization" },
    { value: "tillage-shredding-cornstalks", category: "tillage" },
    { value: "tillage-moldboard-plow", category: "tillage" },
    { value: "tillage-subsoiler-ripper", category: "tillage" },
    { value: "tillage-disk-chisel-plow", category: "tillage" },
    { value: "tillage-chisel-plow", category: "tillage" },
    { value: "tillage-offset-disk", category: "tillage" },
    { value: "tillage-tandem-disk-plowed-field", category: "tillage" },
    { value: "tillage-tandem-disk-tilled-field", category: "tillage" },
    { value: "tillage-tandem-disk-cornstalks", category: "tillage" },
    { value: "tillage-field-cultivate-plowed-field", category: "tillage" },
    { value: "tillage-field-cultivate-tilled-field", category: "tillage" },
    { value: "tillage-seedbed-conditioner", category: "tillage" },
    { value: "planting-planter-seed-only-tilled-seedbed", category: "planting" },
    { value: "planting-plant-with-fertilizer-and-pesticide-attachments-tilled-seedbed", category: "planting" },
    { value: "planting-till-planter", category: "planting" },
    { value: "planting-no-till-planter", category: "planting" },
    { value: "planting-grain-drill", category: "planting" },
    { value: "planting-broadcast-seeder", category: "planting" },
    { value: "planting-air-drill", category: "planting" },
    { value: "weed-control-sprayer-trailer-type", category: "weed control" },
    { value: "weed-control-rotary-hoe", category: "weed control" },
    { value: "weed-control-row-crop-cultivator", category: "weed control" },
    { value: "harvesting-mower", category: "harvesting" },
    { value: "harvesting-mower-conditioner-pto", category: "harvesting" },
    { value: "harvesting-self-propelled-windrower", category: "harvesting" },
    { value: "harvesting-rake", category: "harvesting" },
    { value: "harvesting-baler", category: "harvesting" },
    { value: "forage-harvester-green-forage", category: "harvesting" },
    { value: "forage-harvester-haylage", category: "harvesting" },
    { value: "forage-harvester-corn-silage", category: "harvesting" },
    { value: "forage-harvester-high-moisture-ground-ear-corn", category: "harvesting" },
    { value: "forage-blower-green-forage", category: "harvesting" },
    { value: "forage-blower-haylage", category: "harvesting" },
    { value: "forage-blower-corn-silage", category: "harvesting" },
    { value: "forage-blower-high-moisture-ground-ear-corn", category: "harvesting" },
    { value: "harvesting-combine-soybeans", category: "harvesting" },
    { value: "harvesting-combine-corn", category: "harvesting" },
    { value: "hauling-green-forage", category: "harvesting" },
    { value: "hauling-haylage", category: "harvesting" },
    { value: "hauling-corn-silage", category: "harvesting" },
    { value: "hauling-corn-grain", category: "harvesting" },
    { value: "hauling-soybeans", category: "harvesting" },
    { value: "harvesting-other", category: "harvesting" },
    { value: "fertilization-other", category: "fertilization" },
    { value: "tillage-other", category: "tillage" },
    { value: "planting-other", category: "planting" },
    { value: "weed-control-other", category: "weed control" },
];

