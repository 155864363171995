import {
  GET_FARM_MONITORING_DETAILS,
  CREATE_FARM_MONITORING_DETAILS,
  UPDATE_FARM_MONITORING_DETAILS,
  DELETE_FARM_MONITORING_DETAILS,
  GET_FARM_MONITORING_DETAILS_BY_EMAIL,
  SET_FARM_MONITORING_DETAILS,
} from "../actions/types";
import produce from "immer";

const initialState = {
  _id: "",
  farmPrimaryIdFromMongoose: "",
  email: "",
  monitoringRound: "",
  startYear: "",
};

function farmMonitoringDetailsReducer(
  farmMonitoringDetails = initialState,
  action
) {
  const { type, payload } = action;
  switch (type) {
    case CREATE_FARM_MONITORING_DETAILS:
      return [...farmMonitoringDetails, payload];
    case GET_FARM_MONITORING_DETAILS:
      return payload;
    case GET_FARM_MONITORING_DETAILS_BY_EMAIL:
      return payload;
    case SET_FARM_MONITORING_DETAILS:
      return payload;
    case UPDATE_FARM_MONITORING_DETAILS:
      return farmMonitoringDetails.map((monitoringDetails) => {
        if (monitoringDetails.id === payload.id) {
          return {
            ...monitoringDetails,
            ...payload,
          };
        } else {
          return monitoringDetails;
        }
      });
    case DELETE_FARM_MONITORING_DETAILS:
      return farmMonitoringDetails.filter(({ id }) => id !== payload.id);
    default:
      return farmMonitoringDetails;
  }
}

export default farmMonitoringDetailsReducer;
