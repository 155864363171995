import { combineReducers } from "redux";
import accountSettingsReducer from "./accountSettings";
import currentFarmDataReducer from './curFarmDataSetterReducer';
import currentMonitoringDataReducer from './curMonitoringDataSetterReducer';
import farmAndFarmSettingsReducer from './farmAndFarmSettings';
import farmMonitoringDetailsReducer from "./farmMonitoringDetails";
import farmSettingsReducer from './farmSettings';
import farmStatusReducer from "./farmStatus";
import farmTemplatesReducer from './farmTemplates';
import farmlandReducer from "./farmlands";
import farmMonitoringDataReducer from './monitoringData';
import monitoringYearsTrackerReducer from './monitoringYearsTrackerReducer';
import farmVerificationDocumentsReducer from './verificationDocuments';

export default combineReducers({
    farmlandReducer,
    farmSettingsReducer,
    farmAndFarmSettingsReducer,
    farmTemplatesReducer,
    currentFarmDataReducer,
    currentMonitoringDataReducer,
    farmStatusReducer,
    accountSettingsReducer,
    farmMonitoringDetailsReducer,
    farmVerificationDocumentsReducer,
    farmMonitoringDataReducer,
    monitoringYearsTrackerReducer
});