import PartialFarmUpdatesDataService from "../farmservices/PartialFarmUpdatesDataService";
import PartialMonitoringDataUpdatesDataService from "../farmservices/PartialMonitoringDataUpdatesDataService";
import { PARTIAL_UPDATE_FARM_INFO, PARTIAL_UPDATE_ENERGY_USAGE, PARTIAL_UPDATE_LIVESTOCK, PARTIAL_UPDATE_LAND_USE, PARTIAL_UPDATE_SOIL_ANALYSIS, PARTIAL_UPDATE_PROJECT_START_YEAR, PARTIAL_UPDATE_YEARLY_FARM_DATA } from '../actions/types';

export const partialUpdateFarmInfo = (id, data) => async (dispatch) => {
    try {
        const res = await PartialFarmUpdatesDataService.updateFarmInfo(id, data);
        dispatch({
            type: PARTIAL_UPDATE_FARM_INFO,
            payload: data,
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const partialUpdateEnergyUsage = (id, data) => async (dispatch) => {
    try {
        const res = await PartialFarmUpdatesDataService.updateEnergyUsage(id, data);
        dispatch({
            type: PARTIAL_UPDATE_ENERGY_USAGE,
            payload: data,
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const partialUpdateLivestock = (id, data) => async (dispatch) => {
    try {
        const res = await PartialFarmUpdatesDataService.updateLivestock(id, data);
        dispatch({
            type: PARTIAL_UPDATE_LIVESTOCK,
            payload: data,
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const partialUpdateLandUse = (id, data) => async (dispatch) => {
    try {
        const res = await PartialFarmUpdatesDataService.updateLandUse(id, data);
        dispatch({
            type: PARTIAL_UPDATE_LAND_USE,
            payload: data,
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const partialUpdateSoilAnalysis = (id, data) => async (dispatch) => {
    try {
        const res = await PartialFarmUpdatesDataService.updateSoilAnalysis(id, data);
        dispatch({
            type: PARTIAL_UPDATE_SOIL_ANALYSIS,
            payload: data,
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const partialUpdateProjectStartYear = (id, data) => async (dispatch) => {
    try {
        const res = await PartialMonitoringDataUpdatesDataService.updateProjectStartYear(id, data);
        dispatch({
            type: PARTIAL_UPDATE_PROJECT_START_YEAR,
            payload: data,
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const partialUpdateYearlyFarmData = (id, data) => async (dispatch) => {
    try {
        const res = await PartialMonitoringDataUpdatesDataService.updateYearlyFarmData(id, data);
        dispatch({
            type: PARTIAL_UPDATE_YEARLY_FARM_DATA,
            payload: data,
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};