import { Info as InfoIcon } from "@mui/icons-material";
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { Backdrop, Box, Divider, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, Snackbar, Stack, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { addSpeciesLivestockOutFarm, deleteSpeciesLivestockOutFarm, setCurMonitoringData, updateLivestockOutFarmGrazingDays, updateLivestockOutFarmNumberOfAnimals, updateLivestockOutFarmSpecies } from "../actions/currentMonitoringData";
import { findMonitoringDataByFarmId, updateMonitoringData } from "../actions/monitoringData";
import { BaselineYears, GoBackButton, ProjectYears, SubmitButton } from "../styles";
import ConfirmDialog from "./general/ConfirmDialog";
import NumericInput from "./general/NumericInput";
import { interdependentError } from "./general/Validations/fieldValueErrors";
import { max } from "./general/Validations/minMaxValues";
import { interdependencyValidation } from "./general/Validations/validateValues";


const OutFarmLivestockComponent = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentMonitoringDataObject = useSelector((state) => state.currentMonitoringDataReducer);
  const currentFarmStatusReducer = useSelector((state) => state.farmStatusReducer);
  const currentMonitoringYearsObject = useSelector(state => state.monitoringYearsTrackerReducer);

  const monitoringYears = currentMonitoringYearsObject.allMonitoringYears


  const [showLoader, setShowLoader] = useState(false);
  const [saveCurrentDataFlag, setSaveCurrentDataFlag] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isFilled, setIsFilled] = useState([]);
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;

  const accountSettings = useSelector((state) => state.accountSettingsReducer);
  const isAdmin = accountSettings?.[0]?.role === 'admin'
  const allowEditing = currentFarmStatusReducer?.allowEditing || isAdmin

  const handleBackdropClose = () => {
    setSaveCurrentDataFlag(false);
  };

  const handleBackdropClickCaptureEvent = (e) => {
    e.stopPropagation();
    return false;
  };

  const handleSnackbarClose = () => {
    setState({ vertical: "top", horizontal: "center", open: false });
  };

  // this needs to be in the database as a separate collection — e.g monitoring round info

  const arrDocumentAndYearsMapping = {
    2018: 0,
    2019: 1,
    2020: 2,
    2021: 3,
    2022: 4,
    2023: 5,
    2024: 6,
    2025: 7,
    2026: 8,
    2027: 9,
    2028: 10
  };

  const projectYearsWidth = {
    4: '250px',
    5: '520px',
    6: '787px',
  };

  const handleOutFarmSpeciesUpate = (index, value) => {
    let tempData = {
      index: index,
      data: value
    }
    dispatch(updateLivestockOutFarmSpecies(tempData));
  };

  const handleNumberOfAnimalsUpdate = (year, speciesIndex, value) => {
    let tempData = {
      year: year,
      index: speciesIndex,
      data: value
    }
    dispatch(updateLivestockOutFarmNumberOfAnimals(tempData));
  };


  const handleDaysGrazingUpdate = (year, speciesIndex, value) => {
    let tempData = {
      year: year,
      index: speciesIndex,
      data: value
    }
    dispatch(updateLivestockOutFarmGrazingDays(tempData));
  };

  const handleAddSpecies = () => {
    let tempData = {
      data: {
        species: null,
        amount: -9999,
        grazingDays: -9999
      }
    }
    dispatch(addSpeciesLivestockOutFarm(tempData));
  };

  const handleDeleteSpecies = (speciesIndex) => {
    dispatch(deleteSpeciesLivestockOutFarm(speciesIndex));
  };


  const handleValidate = (path) => {
    let tempObj = currentMonitoringDataObject;

    const outFarmData = tempObj.yearlyFarmData
      .filter(year => monitoringYears.includes(year.year))
      .map(year => year.livestock.outFarm);

    const outFarmDataSpeciesLength = outFarmData[0].length
    let isFilledArray = []
    for (let i = 0; i < outFarmDataSpeciesLength; i++) {
      //get all data belonging to one specie across all years
      const oneSpecies = outFarmData.map(species => { return species[i] })
      // check for each speacie if they are valid (all filled or all empty)
      const isAllFilledForSpecie = interdependencyValidation(oneSpecies)
      // boolean array of isValid 
      isFilledArray.push(isAllFilledForSpecie)
    }
    //get the index of the species that are not filled
    const falseIndices = isFilledArray.map((value, index) => value === false ? index : '').filter(index => index !== '');
    setIsFilled(falseIndices)
    if (isFilledArray.includes(false)) {
      setDialogOpen(true)
    } else if (path) {
      handleSaveLivestockData()
      navigate(path)
    } else {
      handleSaveLivestockData()
    }
  }

  const handleDeleteUnFilled = () => {
    isFilled.reverse().forEach((i) => {
      handleDeleteSpecies(i);
    })
    handleSaveLivestockData()
    setDialogOpen(false)
  }


  const handleSaveLivestockData = () => {
    setShowLoader(true);
    setSaveCurrentDataFlag(true);
    let tempObj = currentMonitoringDataObject;
    dispatch(updateMonitoringData(currentMonitoringDataObject._id, tempObj))
      .then((res) => {
        setSnackbarMessage(t("farmland_draft_submission_msg_success"));
        setState({ open: true, vertical: "top", horizontal: "center" });
        setShowLoader(false);
        setSaveCurrentDataFlag(false);
        if (isAdmin) {
          navigate("/carbonplus/");
        }
      }).catch((e) => {
        setSnackbarMessage(t("farmland_draft_submission_msg_failure"));
        setState({ open: true, vertical: "top", horizontal: "center" });
        setSaveCurrentDataFlag(false);
        setShowLoader(false);
      });
  };

  const baselineYear = (year) => {
    return monitoringYears[0] === year || monitoringYears[1] === year || monitoringYears[2] === year
  }


  // scroll all elements simultaneously
  const scrollersRef = useRef([]);

  useEffect(() => {
    scrollersRef.current = document.getElementsByName('ScrollWrapper');

    const scrollAll = (scrollLeft) => {
      scrollersRef.current.forEach((el) => {
        el.scrollLeft = scrollLeft;
      });
    }

    scrollersRef.current.forEach((el) => {
      el.addEventListener('scroll', (e) => {
        scrollAll(e.target.scrollLeft);
      });
    });

    // Cleanup function to remove event listeners
    return () => {
      scrollersRef.current.forEach((el) => {
        el.removeEventListener('scroll', (e) => {
          scrollAll(e.target.scrollLeft);
        });
      });
    };
  }, [currentMonitoringDataObject.yearlyFarmData[0].livestock.outFarm.length]);

  const currentFarmDataObject = useSelector((state) => state.currentFarmDataReducer);

  const fetchMonitoringData = () => {
    dispatch(findMonitoringDataByFarmId(currentFarmDataObject._id))
      .then((res) => {
        const monitoringData = res.data[0];
        dispatch(setCurMonitoringData(monitoringData));
      })
      .then(() => {
        console.log('Monitoring data retrieved and set successfully.');
      })
      .catch((error) => {
        console.error('Error retrieving or setting monitoring data:', error);
      });
  };

  useEffect(() => {
    if (isAdmin) {
      fetchMonitoringData()
    }
  }, []);


  return (
    <>
      {showLoader && (
        <Backdrop
          onClickCapture={(e) => handleBackdropClickCaptureEvent(e)}
          style={{ color: "#fff", zIndex: 99999 }}
          open={saveCurrentDataFlag}
          onClick={handleBackdropClose}
        >
          <div style={{ fontSize: 20, fontWeight: 600, color: "white", marginTop: "20px" }}>
            {t("generic_saving_data")}
          </div>
        </Backdrop>
      )}

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleSnackbarClose}
        autoHideDuration={3000}
        message={snackbarMessage}
        key={vertical + horizontal}
      />
      {!isAdmin && (
        <Box marginLeft={6} marginTop='80px' display='flex' alignItems='center'>
          <GoBackButton onClick={() => handleValidate("/carbonplus")}>
            <ArrowBackIosRoundedIcon />
            <Typography sx={{ fontWeight: 800, paddingLeft: 2 }}>{t("back_to_home")}</Typography>
          </GoBackButton>
        </Box>
      )}

      <Box margin={12} marginTop={2}>
        <Grid container item spacing={2} >
          <Grid item xs={12} sm={12} md={10} lg={10}
            style={{
              display: "flex",
              flexDirection: "column",

            }}
          >
            <Typography
              variant="h1"
            >
              {t("ls_outfarm_title")}
            </Typography>
            <Typography variant="subtitle1">
              <Trans i18nKey={"ls_outfarm_desc"}></Trans>
            </Typography>
          </Grid>
        </Grid>

        <Box marginTop={2} sx={{ display: "flex", flexDirection: "row" }}>
          <Stack minWidth={"220px"} width={"220px"}></Stack>
          <div
            name="ScrollWrapper"
            style={{
              display: "flex",
              flexDirection: "row",
              overflowX: "scroll"
            }}
          >
            <Stack marginLeft={2}>
              <BaselineYears sx={{ width: "782px" }}>{t("baseline_years")}</BaselineYears>
            </Stack>
            <Stack>
              <ProjectYears sx={{ marginLeft: 2, bgcolor: "#808080", minWidth: projectYearsWidth[monitoringYears.length] }}>{t("project_years")}</ProjectYears>
            </Stack>
          </div>
        </Box>

        <Box marginTop={2} sx={{ display: "flex", flexDirection: "row", overflow: 'auto' }}>
          <Stack minWidth={"220px"} width={"220px"}></Stack>
          <div
            name="ScrollWrapper"
            style={{
              display: "flex",
              flexDirection: "row",
              overflowX: "scroll"
            }}
          >
            {monitoringYears?.map((year, index) => {
              return <Stack sx={{ minWidth: "250px", ml: 2 }} key={year}>
                {baselineYear(year) ? (
                  <BaselineYears sx={{ marginBottom: 2 }}>{year}</BaselineYears>
                ) : (
                  <ProjectYears sx={{ marginBottom: 2 }}>{year}</ProjectYears>
                )}
              </Stack>
            })}</div>
        </Box>
        {/*  OUT OF FARM ANIMALS  */}
        {currentMonitoringDataObject.yearlyFarmData[0].livestock.outFarm.map((specie, speciesIndex) => {
          return <React.Fragment key={speciesIndex}>
            <Box sx={{ display: "flex", flexDirection: "row", mt: 2 }} >
              <Stack minWidth={"220px"} width={"220px"}>
                <Grid marginTop={1} item xs={12} sm={12} md={12} lg={12}>
                  <FormControl fullWidth sx={{ marginBottom: 1.5 }}>
                    <InputLabel size='small' id="out-farm-species">
                      {t("ls_outfarm_species")}
                    </InputLabel>
                    <Select size='small' name='out-farm-species' disabled={!allowEditing}
                      error={!currentMonitoringDataObject.yearlyFarmData[0].livestock.outFarm[speciesIndex].species}
                      value={currentMonitoringDataObject.yearlyFarmData[0].livestock.outFarm[speciesIndex].species === null ? '' : currentMonitoringDataObject.yearlyFarmData[0].livestock.outFarm[speciesIndex].species}
                      onChange={(e) => handleOutFarmSpeciesUpate(speciesIndex, e.target.value)}
                      id="out-farm-species-select" labelId='out-farm-species' label="out-farm-species">
                      <MenuItem value="cattle non-dairy">{t("ls_outfarm_cattle")}</MenuItem>
                      <MenuItem value="dairy cows">{t("ls_outfarm_dairy_cows")}</MenuItem>
                      <MenuItem value="swine">{t("ls_outfarm_swine")}</MenuItem>
                      <MenuItem value="sheep">{t("ls_outfarm_sheep")}</MenuItem>
                      <MenuItem value="goats">{t("ls_outfarm_goats")}</MenuItem>
                    </Select>
                    <Tooltip
                      sx={{ paddingLeft: 1 }}
                      title={t("ls_outfarm_info")}
                      placement="top-start"
                    >
                      <InfoIcon sx={{ fontSize: "medium", padding: "0px 0px 0px 8px" }} />
                    </Tooltip>
                  </FormControl>
                </Grid>
                <Grid>
                  <Typography variant="h3">
                    {t("ls_outfarm_grazing_days")}
                    <Tooltip
                      sx={{ paddingLeft: 1 }}
                      title={t("ls_outfarm_grazing_days_info")}
                      placement="top-start"
                    >
                      <InfoIcon sx={{ fontSize: "medium", padding: "0px 0px 0px 8px" }} />
                    </Tooltip>
                  </Typography>
                </Grid>
              </Stack>
              <div
                name="ScrollWrapper"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  overflowX: "scroll",
                }}
              >
                {/* SPECIES' DATA */}
                {monitoringYears.map((year) => {
                  const specie = currentMonitoringDataObject?.yearlyFarmData?.[arrDocumentAndYearsMapping[year]]?.livestock?.outFarm?.[speciesIndex]
                  return <Stack marginLeft={2} sx={{ minWidth: "250px" }} key={year}>

                    <Stack marginLeft={0} marginTop={1} sx={{ minWidth: "220px" }}>
                      <NumericInput max={max.outFarmAmmount} sx={{ mb: 2 }} id="number-of-animals"
                        error={interdependentError(specie?.amount, [specie?.species, specie?.grazingDays])}
                        label={t("ls_outfarm_num_animals")}
                        value={specie?.amount === -9999 ? '' : specie?.amount}
                        onChange={(e) => {
                          handleNumberOfAnimalsUpdate(year, speciesIndex, e.target.value)
                        }}
                      />
                      <NumericInput
                        max={max.yearDays}
                        sx={{ mb: 2 }}
                        id="days-grazing"
                        error={interdependentError(specie?.grazingDays, [specie?.species, specie?.amount])}

                        InputProps={{
                          endAdornment: <InputAdornment position="start">{t("ls_infarm_grazing_days_unit")}</InputAdornment>,
                        }}
                        value={specie?.grazingDays === -9999 ? '' : specie?.grazingDays}
                        onChange={(e) => {
                          handleDaysGrazingUpdate(year, speciesIndex, e.target.value)
                        }}
                      />
                    </Stack>
                  </Stack>
                })}
              </div>
            </Box>
            {allowEditing && <Typography fontWeight={800} fontSize={18} color="#db6160" component="span" sx={{ cursor: "pointer" }} onClick={() => handleDeleteSpecies(speciesIndex)}>
              {t("generic_delete")}</Typography>}

            <Divider sx={{ marginY: 6 }}></Divider>
          </React.Fragment>
        })}

        {allowEditing && <Typography fontWeight={800} fontSize={20} color="#409781" component="span" sx={{ cursor: "pointer" }} onClick={() => handleAddSpecies()}>
          {t("ls_outfarm_add")}</Typography>}

        <ConfirmDialog onClose={() => setDialogOpen(false)} open={dialogOpen} textQuestion={t("generic_complete_fields")} textYes={t("generic_complete_no")} textNo={t("generic_complete_yes")} textTitle={t("generic_complete_title")} handleDelete={handleDeleteUnFilled} />



        <Box display="flex" flexDirection="row" justifyContent="end">
          <SubmitButton
            sx={{
              marginTop: "100px",
              alignSelf: "flex-end",
              width: "200px",
            }}
            onClick={handleValidate}
          >
            {t("generic_save")}
          </SubmitButton>
        </Box>
      </Box >
    </>
  )
}

export default OutFarmLivestockComponent;