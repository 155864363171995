export const arrColors = [
    "#ffb347",
    "#fadfad",
    "#ff6e4a",
    "#848482",
    "#d2691e",
    "#86608e",
    "#d19fe8",
    "#a32638",
    "#002147",
    "#4169e1",
    "#645452",
    "#f0ffff",
    "#ffb300",
    "#ffa6c9",
    "#0070ff",
    "#bfff00",
    "#76ff7a",
    "#e5b73b",
    "#fc9",
    "#0892d0",
    "#deaa88",
    "#ae2029",
    "#986960",
    "#796878",
    "#e6e6fa",
    "#536872",
    "#9f1d35",
    "#66b032",
    "#ff8243",
    "#483c32",
    "#2f847c",
    "#b57281",
    "#0f4d92",
    "#0093af",
    "#21abcd",
    "#00308f",
    "#c3b091",
    "#a4dded",
    "#997a8d",
    "#f96",
    "#003153",
    "#ff4f00",
    "#fc89ac",
    "#9c2542",
    "#cd9575",
    "#355e3b",
    "#918151",
    "#00009c",
    "#df00ff",
    "#f00",
    "#fefe22",
    "#008000",
    "#c32148",
    "#21421e",
    "#967117",
    "#ff007f",
    "#843f5b",
    "#3fff00",
    "#0072bb",
    "#cf1020",
    "#dda0dd",
    "#ffa07a",
    "#66424d",
    "#ffff31",
    "#007ba7",
    "#007fbf",
    "#cba135",
    "#b666d2",
    "#de3163",
    "#d99058",
    "#007fff",
    "#fcf75e",
    "#ae0c00",
    "#483d8b",
    "#3c341f",
    "#addfad",
    "#9370db",
    "#48d1cc",
    "#ff6fff",
    "#800080",
    "#ffefd5",
    "#c0362c",
    "#4682b4",
    "#fad6a5",
    "#967117",
    "#e6e8fa",
    "#b06500",
    "#af4035",
    "#483c32",
    "#ee82ee",
    "#f5fffa",
    "#cd5b45",
    "#0014a8",
    "#967117",
    "#808080",
    "#339",
    "#872657",
    "#e49b0f",
    "#e7feff",
    "#004242",
    "#c00",
    "#ffd300",
    "#800080",
    "#722f37",
    "#bcd4e6",
    "#b784a7",
    "#6e7f80",
    "#c4c3d0",
    "#ff2800",
    "#ff0028",
    "#e32636",
    "#4997d0",
    "#c19a6b",
    "#a1caf1",
    "#536878",
    "#a4c639",
    "#f78fa7",
    "#eedc82",
    "#964b00",
    "#30d5c8",
    "#a57164",
    "#69c",
    "#009000",
    "#ffdf00",
    "#915f6d",
    "#c5b358",
    "#446ccf",
    "#674846",
    "#d9603b",
    "#3d0c02",
    "#e9d66b",
    "#000f89",
    "#ffc87c",
    "#cd5c5c",
    "#f4a460",
    "#a67b5b",
    "#ffc1cc",
    "#0ff",
    "#74c365",
    "#00ced1",
    "#da8a67",
    "#0ff",
    "#36454f",
    "#0087bd",
    "#8a496b",
    "#40826d",
    "#a52a2a",
    "#00a86b",
    "#f0f",
    "#556b2f",
    "#900",
    "#483c32",
    "#f8de7e",
    "#dda0dd",
    "#ba55d3",
    "#ff0800",
    "#add8e6",
    "#f0e130",
    "#c19a6b",
    "#a76bcf",
    "#aa381e",
    "#d3d3d3",
    "#ff0",
    "#ffffe0",
    "#ff8c00",
    "#69359c",
    "#e03c31",
    "#91a3b0",
    "#2f4f4f",
    "#c54b8c",
    "#50c878",
    "#a40000",
    "#f4bbff",
    "#bf4f51",
    "#967117",
    "#ff004f",
    "#8c92ac",
    "#e6e6fa",
    "#a99a86",
    "#c0c",
    "#b31b1b",
    "#014421",
    "#d3003f",
    "#007aa5",
    "#560319",
    "#4f666a",
    "#704241",
    "#ff0",
    "#e08d3c",
    "#f96",
    "#a52a2a",
    "#fada5e",
    "#f5deb3",
    "#ffc40c",
    "#ecebbd",
    "#8f00ff",
    "#e3ff00",
    "#fad6a5",
    "#353839",
    "#b5a642",
    "#0bda51",
    "#f400a1",
    "#ffe135",
    "#ace1af",
    "#9f00c5",
    "#ffb6c1",
    "#e0b0ff",
    "#d70a53",
    "#ddadaf",
    "#d70040",
    "#ffbcd9",
    "#f88379",
    "#008000",
    "#738678",
    "#98777b",
    "#922724",
    "#860111",
    "#da9100",
    "#dfff00",
    "#c19a6b",
    "#ff55a3",
    "#08e8de",
    "#002366",
    "#ab4e52",
    "#1c39bb",
    "#465945",
    "#ff8c69",
    "#5d8aa8",
    "#cf0",
    "#e97451",
    "#79443b",
    "#b39eb5",
    "#b03060",
    "#db7093",
    "#f28500",
    "#e66771",
    "#fc3",
    "#7df9ff",
    "#85bb65",
    "#ffd700",
    "#6da",
    "#ffe4c4",
    "#ff6961",
    "#000",
    "#fdee00",
    "#4b0082",
    "#faf0e6",
    "#ffdead",
    "#8a3324",
    "#e5aa70",
    "#dc143c",
    "#d70040",
    "#b31b1b",
    "#fba0e3",
    "#ffa700",
    "#00416a",
    "#c08081",
    "#da8a67",
    "#e25822",
    "#00fa9a",
    "#95b",
    "#e34234",
    "#3c1414",
    "#ff0090",
    "#5d8aa8",
    "#cb99c9",
    "#66023c",
    "#fbaed2",
    "#ffa812",
    "#8a795d",
    "#0fc0fc",
    "#915f6d",
    "#536878",
    "#e3dac9",
    "#882d17",
    "#ff6fff",
    "#00b7eb",
    "#0f0",
    "#49796b",
    "#fff0f5",
    "#c80815",
    "#e68fac",
    "#ffbf00",
    "#002fa7",
    "#2a52be",
    "#79443b",
    "#d8bfd8",
    "#009e60",
    "#905d5d",
    "#96c",
    "#00bfff",
    "#708090",
    "#e2062c",
    "#ff6347",
    "#de3163",
    "#ec3b83",
    "#2e8b57",
    "#704214",
    "#32cd32",
    "#03c03c",
    "#9acd32",
    "#c89",
    "#7c1c05",
    "#138808",
    "#d7837f",
    "#1e4d2b",
    "#e97451",
    "#de6fa1",
    "#1cac78",
    "#a2a2d0",
    "#9b870c",
    "#e9967a",
    "#3b3c36",
    "#f1a7fe",
    "#ff7518",
    "#de5d83",
    "#fc8eac",
    "#fff700",
    "#6a5acd",
    "#bc8f8f",
    "#5b92e5",
    "#9bc4e2",
    "#dbd7d2",
    "#aa4069",
    "#d0f0c0",
    "#fdf5e6",
    "#ffe5b4",
    "#ff91a4",
    "#fada5e",
    "#900",
    "#414a4c",
    "#ff9f00",
    "#ff1493",
    "#edc9af",
    "#afeeee",
    "#013220",
    "#d9004c",
    "#a7fc00",
    "#1f75fe",
    "#1560bd",
    "#bebebe",
    "#ecd540",
    "#004953",
    "#367588",
    "#f7f",
    "#b768a2",
    "#cb6d51",
    "#979aaa",
    "#da70d6",
    "#8601af",
    "#b2ffff",
    "#dcd0ff",
    "#fdd5b1",
    "#701c1c",
    "#0f52ba",
    "#417dc1",
    "#682860",
    "#ef98aa",
    "#5f9ea0",
    "#fafad2",
    "#c40233",
    "#b0e0e6",
    "#ff69b4",
    "#dea5a4",
    "#fbceb1",
    "#da1d81",
    "#efbbcc",
    "#da3287",
    "#f0e68c",
    "#ff6700",
    "#6f00ff",
    "#b19cd9",
    "#536895",
    "#f08080",
    "#f2003c",
    "#50c878",
    "#915c83",
    "#fff8e7",
    "#c04000",
    "#faf0be",
    "#73a9c2",
    "#fff8dc",
    "#f64a8a",
    "#1c352d",
    "#87cefa",
    "#b48395",
    "#f4f0ec",
    "#b78727",
    "#c90016",
    "#8db600",
    "#4f7942",
    "#c71585",
    "#3cd070",
    "#b38b6d",
    "#004225",
    "#c74375",
    "#ed872d",
    "#65000b",
    "#fe4eda",
    "#6ca0dc",
    "#fff5ee",
    "#060",
    "#51484f",
    "#2a8000",
    "#039",
    "#b53389",
    "#c8a2c8",
    "#eae0c8",
    "#faebd7",
    "#7b3f00",
    "#00008b",
    "#c154c1",
    "#fc0",
    "#8e4585",
    "#e52b50",
    "#23297a",
    "#0000cd",
    "#fc0",
    "#f88379",
    "#b3446c",
    "#100c08",
    "#c154c1",
    "#002395",
    "#ed9121",
    "#ab4e52",
    "#18453b",
    "#ff033e",
    "#fe2712",
    "#592720",
    "#d71868",
    "#9b111e",
    "#f0f8ff",
    "#bf94e4",
    "#7b1113",
    "#414833",
    "#007ba7",
    "#be0032",
    "#96ded1",
    "#555d50",
    "#fae7b5",
    "#4d5d53",
    "#ff8f00",
    "#4b5320",
    "#f8f8ff",
    "#ffef00",
    "#ffba00",
    "#0d98ba",
    "#fc0fc0",
    "#98fb98",
    "#cd5c5c",
    "#c0c0c0",
    "#966fd6",
    "#f7e98e",
    "#f4c430",
    "#e4717a",
    "#654321",
    "#ccf",
    "#c50",
    "#f88379",
    "#fadadd",
    "#8b008b",
    "#00a877",
    "#c71585",
    "#673147",
    "#c72c48",
    "#555",
    "#7c4848",
    "#8fbc8f",
    "#99badd",
    "#0067a5",
    "#e6be8a",
    "#00a693",
    "#ffcba4",
    "#fada5e",
    "#0ff",
    "#960018",
    "#f4c2c2",
    "#aa98a9",
    "#fb9902",
    "#522d80",
    "#da2c43",
    "#c23b22",
    "#cfb53b",
    "#6d9bc3",
    "#b22222",
    "#f2f3f4",
    "#00827f",
    "#7851a9",
    "#6f00ff",
    "#734f96",
    "#c41e3a",
    "#00a550",
    "#014421",
    "#cd5700",
    "#ffe4e1",
    "#eee600",
    "#836953",
    "#701c1c",
    "#cd7f32",
    "#ffa500",
    "#f984ef",
    "#0247fe",
    "#fbec5d",
    "#bdda57",
    "#0038a8",
    "#008b8b",
    "#0073cf",
    "#bb6528",
    "#ff4500",
    "#e30b5d",
    "#9f00ff",
    "#534b4f",
    "#e68fac",
    "#e30022",
    "#087830",
    "#fbab60",
    "#0047ab",
    "#ff0038",
    "#3f00ff",
    "#d9603b",
    "#b2beb5",
    "#779ecb",
    "#ff2400",
    "#87a96b",
    "#00563f",
    "#808080",
    "#a4f4f9",
    "#72a0c1",
    "#789",
    "#7fffd4",
    "#ccf",
    "#00ff7f",
    "#801818",
    "#507d2a",
    "#c66",
    "#5d3954",
    "#ff003f",
    "#035096",
    "#e62020",
    "#ff5349",
    "#26619c",
    "#bc987e",
    "#aaf0d1",
    "#f5f5f5",
    "#bb3385",
    "#6082b6",
    "#d2691e",
    "#ff3800",
    "#43b3ae",
    "#cc4e5c",
    "#03a",
    "#00755e",
    "#ffae42",
    "#6f4e37",
    "#73c2fb",
    "#daa520",
    "#cd853f",
    "#e8000d",
    "#9932cc",
    "#4166f5",
    "#a9203e",
    "#614051",
    "#b94e48",
    "#006a4e",
    "#fada5e",
    "#c9c0bb",
    "#fd0e35",
    "#00703c",
    "#a3c1ad",
    "#39ff14",
    "#880085",
    "#d40000",
    "#177245",
    "#663854",
    "#df00ff",
    "#253529",
    "#808000",
    "#fff",
    "#635147",
    "#fdbcb4",
    "#4b3621",
    "#a9a9a9",
    "#fc6c85",
    "#008000",
    "#123524",
    "#a0785a",
    "#5a4fcf",
    "#0077be",
    "#c72",
    "#f6c",
    "#039",
    "#deb887",
    "#318ce7",
    "#f49ac2",
    "#e9692c",
    "#fddde6",
    "#f5f5dc",
    "#f8de7e",
    "#0f0",
    "#00416a",
    "#efdecd",
    "#cf0",
    "#324ab2",
    "#ff6",
    "#6050dc",
    "#a020f0",
    "#c46210",
    "#f93",
    "#9bddff",
    "#8878c3",
    "#b76e79",
    "#746cc0",
    "#fbcce7",
    "#b2ec5d",
    "#ff1493",
    "#a8516e",
    "#fb607f",
    "#062a78",
    "#fffff0",
    "#a8e4a0",
    "#f0ead6",
    "#273be2",
    "#87ceeb",
    "#d73b3e",
    "#ff7f00",
    "#a67b5b",
    "#b5651d",
    "#fe28a2",
    "#f0fff0",
    "#78184a",
    "#228b22",
    "#ffa343",
    "#bf00ff",
    "#e7accf",
    "#92a1cf",
    "#f0f",
    "#ffb7c5",
    "#cf3476",
    "#195905",
    "#674c47",
    "#343434",
    "#e75480",
    "#800000",
    "#fcc200",
    "#ba160c",
    "#c2b280",
    "#fffaf0",
    "#d2691e",
    "#e2725b",
    "#d4af37",
    "#841b2d",
    "#50404d",
    "#cf0",
    "#e6e200",
    "#ef3038",
    "#7f00ff",
    "#fe4164",
    "#6c541e",
    "#29ab87",
    "#abcdef",
    "#b87333",
    "#96c8a2",
    "#fbec5d",
    "#adff2f",
    "#a52a2a",
    "#8b0000",
    "#bcd4e6",
    "#dcdcdc",
    "#de6fa1",
    "#ffddf4",
    "#93ccea",
    "#2c1608",
    "#321414",
    "#d10056",
    "#dda0dd",
    "#ce2029",
    "#e25098",
    "#eee8aa",
    "#b7410e",
    "#702963",
    "#e32636",
    "#ff7e00",
    "#00f",
    "#cb4154",
    "#aec6cf",
    "#08457e",
    "#120a8f",
    "#c33",
    "#191970",
    "#90ee90",
    "#0abab5",
    "#967bb6",
    "#1dacd6",
    "#32127a",
    "#7fff00",
    "#fffacd",
    "#e4d00a",
    "#002e63",
    "#933d41",
    "#f0dc82",
    "#f4c2c2",
    "#059033",
    "#e34234",
    "#004040",
    "#0f0",
    "#808080",
    "#80461b",
    "#9400d3",
    "#ca1f7b",
    "#a020f0",
    "#3d2b1f",
    "#004b49",
    "#c19a6b",
    "#e0115f",
    "#ca2c92",
    "#f8b878",
    "#e1a95f",
    "#0cc",
    "#ff1dce",
    "#71a6d2",
    "#f77fbe",
    "#20b2aa",
    "#002387",
    "#e18e96",
    "#ffc0cb",
    "#ffcba4",
    "#eb4c42",
    "#e9d66b",
    "#673147",
    "#5218fa",
    "#fefe33",
    "#d6cadd",
    "#1034a6",
    "#af4035",
    "#ffef00",
    "#8b4513",
    "#f400a1",
    "#fdfd96",
    "#ff7f50",
    "#e48400",
    "#c9a0dc",
    "#f3e5ab",
    "#f99",
    "#a2add0",
    "#ff007f",
    "#ffdb58",
    "#7cfc00",
    "#ff4040",
    "#ed1c24",
    "#00ffef",
    "#f3e5ab",
    "#808000",
    "#008080",
    "#ffa089",
    "#ec5800",
    "#b57edc",
    "#fad6a5",
    "#c9dc87",
    "#004f98",
    "#f984e5",
    "#fff600",
    "#4cbb17",
    "#e0ffff",
    "#7d7",
    "#6f0",
    "#cf71af",
    "#009f6b",
    "#ffdab9",
    "#7c0a02",
    "#f0e68c",
    "#e4717a",
    "#8a2be2",
    "#b8860b",
    "#e1ad21",
    "#826644",
    "#78866b",
    "#9457eb",
    "#bd33a4",
    "#e3a857",
    "#8b8589",
    "#93c572",
    "#7b68ee",
    "#a0d6b4",
    "#a50b5e",
    "#88d8c0",
    "#900",
    "#fe6f5e",
    "#006994",
    "#98817b",
    "#f8f4ff",
    "#6495ed",
    "#ffddca",
    "#6b8e23",
    "#c32148",
    "#f6adc6",
    "#e5e4e2",
    "#036",
    "#0c9",
    "#9678b6",
    "#f4bbff",
    "#ff5a36",
    "#ffd800",
    "#996515",
    "#893f45",
    "#007474",
    "#873260",
    "#1e90ff",
    "#f9429e",
    "#00ff7f",
    "#987654",
    "#e4d96f",
    "#a9ba9d",
    "#c19a6b",
    "#efcc00",
    "#9dc209",
    "#8f00ff",
    "#ee82ee",
    "#480607",
    "#ffebcd",
    "#df73ff",
    "#db7093",
    "#30ba8f",
    "#126180",
    "#800020",
    "#b03060",
    "#9ab973",
    "#cb410b",
    "#a81c07",
    "#f56991",
    "#e3256b",
    "#faebd7",
    "#00bfff",
    "#3b444b",
    "#98ff98",
    "#000080",
    "#ace5ee",
    "#bdb76b",
    "#966",
    "#89cff0",
    "#cfcfc4",
    "#ff43a4",
    "#ff355e",
    "#0067a5",
    "#d2b48c",
    "#c2b280",
    "#fd0e35",
    "#654321",
    "#92000a",
    "#3cb371",
    "#568203",
    "#d1e231",
    "#fffafa",
    "#f3c",
    "#ffbf00",
    "#0095b6",
    "#fffdd0",
    "#ad6f69",
    "#3eb489",
    "#696969",
    "#1a1110",
    "#9f8170",
    "#006b3c",
    "#1a2421",
    "#f94d00",
    "#01796f",

]