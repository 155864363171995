import { addNewEmptyHarvestToAnnualCropData } from "../../../actions/currentMonitoringData";
import { tmpData } from "./tmpData";

export const addNewAnnualCropsHarvest = (e, i, year, dispatch, parcelid) => {

    let funParam = {
        year: year,
        data: tmpData,
        parcelId: parcelid,
        annualCropsId: i
    }
    dispatch(addNewEmptyHarvestToAnnualCropData(funParam))
};