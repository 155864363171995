import { addNewEmptyHarvestToPerennialTreeAndCropData } from "../../../actions/currentMonitoringData";
import { tmpData } from "./tmpData";

export const addNewPerennialHarvest = (e, i, year, dispatch, parcelid) => {

    let funParam = {
        year: year,
        data: tmpData,
        parcelId: parcelid,
        perennialCropsId: i
    }
    dispatch(addNewEmptyHarvestToPerennialTreeAndCropData(funParam))
};