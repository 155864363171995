import {
    CheckCircle,
    CloudUpload as CloudUploadIcon,
    Info as InfoIcon
} from "@mui/icons-material";
import { Grid, Link, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Fragment, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurMonitoringData } from "../../actions/currentMonitoringData";
import { updateMonitoringData } from "../../actions/monitoringData";
import { setFarmVerificationDocuments, updateFarmVerificationDocuments } from "../../actions/verificationDocuments";
import { defaultValues } from "../../constants/verificationDocsDefaultValues";
import VerificationDocumentsUploadService from "../../farmservices/VerificationDocumentsUploadService";
import { SubmitButton, UploadedDocument } from "../../styles";
import ConfirmDialog from "../general/ConfirmDialog";
import { handleDownload } from "../general/utils/fileOperations";
import { MapContext } from "./MapParcelsComponent";

const DigitalMaps = ({ currentFarmVerificationDocuments, page }) => {
    const dispatch = useDispatch()
    const { t, i18n } = useTranslation();
    const currentMonitoringDataObject = useSelector(
        (state) => state.currentMonitoringDataReducer
    );

    const currentFarmDataObject = useSelector(
        (state) => state.currentFarmDataReducer
    );
    const currentMonitoringYearsObject = useSelector(state => state.monitoringYearsTrackerReducer);
    const allMonitoringYears = currentMonitoringYearsObject.allMonitoringYears
    const [saveCurrentDataFlag, setSaveCurrentDataFlag] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [state, setState] = useState({
        open: false,
        vertical: "top",
        horizontal: "center",
    });

    const {
        mapRef,
        drawRef,
    } = useContext(MapContext);
    const [deleteMapDialogOpen, setDeleteMapDialogOpen] = useState(false);
    const handleDeleteMapDialogClose = () => {
        setDeleteMapDialogOpen(false)
    };

    const showUploadButton = () => {
        if (page === 'Maps') {
            return true
        } else {
            return false
        }
    }

    const handleDeleteMapDialogContinue = async () => {
        await handleDeleteFile(
            0,
            currentFarmVerificationDocuments.kmlFiles[0]
                .folder,
            currentFarmVerificationDocuments.kmlFiles[0]
                .fileName
        );
        setDeleteMapDialogOpen(false);
    };

    const handleDeleteMapDialogFunction = async () => {
        if (page === "Maps") {
            setDeleteMapDialogOpen(true)
        } else {
            await handleDeleteFile(
                0,
                currentFarmVerificationDocuments.kmlFiles[0]
                    .folder,
                currentFarmVerificationDocuments.kmlFiles[0]
                    .fileName
            );
        }

    }

    const handleFileChange = (e, subfolder, year) => {
        // if no year default value

        setSaveCurrentDataFlag(true);

        let email = currentFarmDataObject.farmInfo.email.replace("@", "__");

        let file = e.target.files[0];

        let fileNameArr = e.target.files[0].name.split(".").map((ele) => {
            return ele.replace(/[^a-zA-Z0-9]/g, "");
        });

        let fileName = fileNameArr.join(".");

        if (year !== undefined && year !== "" && year !== null) {
            handleFileUpload(file, fileName, subfolder, email, year);
        } else {
            handleFileUpload(file, fileName, subfolder, email);
        }
    };

    const handleDeleteFile = async (index, subfolder, fileName, year) => {
        setSaveCurrentDataFlag(true);


        let server = '';

        let email = currentFarmDataObject.farmInfo.email.replace("@", "__");

        let tempObj = currentFarmVerificationDocuments;

        if (subfolder === "kmlFiles") {
            server = tempObj.kmlFiles[index].s3URL.split('/')[3];
            tempObj.kmlFiles.splice(index, 1);
        }

        const data = {
            subfolder: subfolder,
            year: year,
            server: server,
            filename: fileName,
            email: email,
            data: tempObj,
        };

        // delete file from S3 while creating a backup and delete from mongoDB
        try {
            let response = await VerificationDocumentsUploadService.deleteFile(
                currentFarmVerificationDocuments._id,
                data
            );

            if (response.status === 200) {
                dispatch(setFarmVerificationDocuments(response.data))
                    .then((res) => {
                        setSnackbarMessage(t("deleting_document_msg_success"));
                        setState({ open: true, vertical: "top", horizontal: "center" });
                        setSaveCurrentDataFlag(false);
                    })
                    .catch((error) => {
                        console.error('Error setting farm verification documents:', error);
                        setSnackbarMessage(t("deleting_document_msg_failure"));
                        setState({ open: true, vertical: "top", horizontal: "center" });
                        setSaveCurrentDataFlag(false);
                    });
            } else {
                setSnackbarMessage(t("deleting_document_msg_failure"));
                setState({ open: true, vertical: "top", horizontal: "center" });
                setSaveCurrentDataFlag(false);
            }
        } catch (error) {
            console.error('Error deleting file:', error);
            setSnackbarMessage(t("deleting_document_msg_failure"));
            setState({ open: true, vertical: "top", horizontal: "center" });
            setSaveCurrentDataFlag(false);
        }
        if (page === 'Maps') {
            let tempObj = JSON.parse(JSON.stringify(currentMonitoringDataObject))
            const yearlyFarmDataLength = tempObj.yearlyFarmData.length
            tempObj.yearlyFarmData[tempObj.yearlyFarmData.length - 1].parcelLevelData?.map(mRow => {
                delParcelFromMap(mRow?.id)
            })
            for (var i = 0; i < yearlyFarmDataLength; i++) {
                tempObj.yearlyFarmData[i].parcelLevelData = []
            }
            dispatch(updateMonitoringData(currentMonitoringDataObject._id, tempObj))
                .then((res) => {
                    if (res && res.data) {
                        dispatch(setCurMonitoringData(res.data));
                    } else {
                        console.error('No data returned from updateMonitoringData');
                    }
                })
                .catch((error) => {
                    console.error('Error updating monitoring data:', error);
                });
        }
    };

    const delParcelFromMap = (parcelIdToDelete) => {
        let { map } = mapRef?.current?.state;
        if (
            map?.getSource(parcelIdToDelete) !== null &&
            map?.getSource(parcelIdToDelete) !== undefined
        ) {
            map.removeLayer(parcelIdToDelete);
            let outlineId = "outline".concat(parcelIdToDelete);
            let hoverId = "hover_".concat(parcelIdToDelete);
            map.removeLayer(outlineId);
            map.removeLayer(hoverId);
            map.removeSource(parcelIdToDelete);
            drawRef?.current?.draw?.deleteAll();
        }
    };

    const handleFileUpload = async (file, fileName, subfolder, email, year) => {
        const formData = new FormData();

        const server = process.env.REACT_APP_NODE_ENV === 'production' ? 'monitoring_round_2024_uploads' : 'test';

        let tempData = {
            dateUploaded: Date.now().toString(),
            cfDocumentName: defaultValues[subfolder],

            folder: subfolder,
            fileName: fileName,
            documentType: "",
            s3URL: "",
            year: year,
            comment: "",
        };

        formData.append("subfolder", subfolder);
        if (year !== undefined && year !== "") {
            formData.append("year", year);
        }
        formData.append("email", email);
        formData.append("server", server);
        formData.append("file", file);

        const response = await VerificationDocumentsUploadService.upload(formData);

        tempData.s3URL = response.data.message;
        tempData.documentType = response.data.data.mimetype;

        let tempObj = currentFarmVerificationDocuments;


        if (subfolder === "kmlFiles") {
            tempObj.kmlFiles.push(tempData);
        }



        // upload to mongoDB
        dispatch(
            updateFarmVerificationDocuments(
                currentFarmVerificationDocuments._id,
                tempObj
            )
        )
            .then((res) => {
                setSnackbarMessage(t("uploading_document_msg_success"));
                setState({ open: true, vertical: "top", horizontal: "center" });
                setSaveCurrentDataFlag(false);
            })
            .catch((e) => {
                setSnackbarMessage(t("uploading_document_msg_failure"));
                setState({ open: true, vertical: "top", horizontal: "center" });
                setSaveCurrentDataFlag(false);
            });
    };

    const tutorial = { en_EN: 'https://www.youtube.com/watch?v=LQz3kUMKMwU', pt_PT: 'https://youtu.be/2MZ61-rYAWs', es_ES: 'https://www.youtube.com/watch?v=4kT9JNCEHjA' }
    const lang = i18n?.language

    return <><Grid container spacing={2} marginY={4}>
        <Grid
            marginTop={1}
            item
            xs={3}
            sm={3}
            md={3}
            lg={3}
            style={{ marginBottom: "10px" }}
            display={"flex"}
        >
            <Typography variant="h3" color="#000000">
                {t("ver_doc_kml")}
            </Typography>
            <Tooltip
                title={page === 'Maps' ?
                    <>
                        {t("pmo_map_info_1")}
                        <br />
                        <br />
                        <Typography component={Link}
                            color="#fff"
                            fontSize={11}
                            target="_blank"
                            href={tutorial[lang]}>
                            {t("pmo_map_info_2")}
                        </Typography>
                        <br />
                        <br />
                        {t("ver_doc_kml_info_box_3")}
                    </> :
                    <>
                        {t("ver_doc_kml_info_box_1")}
                        <br />
                        <br />
                        <Typography component={Link}
                            color="#fff"
                            fontSize={11}
                            target="_blank"
                            href={tutorial[lang]}>
                            {t("ver_doc_kml_info_box_2")}
                        </Typography>

                        <br />
                        <br />
                        {t("ver_doc_kml_info_box_3")}
                    </>
                }
                placement="top-start"
            >
                <InfoIcon
                    fontSize="small"
                    sx={{
                        padding: "0px 0px 0px 8px",
                        color: "#000000",
                    }}
                />
            </Tooltip>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>
            {currentFarmVerificationDocuments?.kmlFiles?.length > 0 ? (
                <Grid container spacing={2}>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        display={"flex"}
                        marginBottom={1}
                    >
                        <Grid>
                            <UploadedDocument
                                size="small"
                                label={
                                    <Fragment>
                                        <CheckCircle sx={{ marginRight: 1 }} />
                                        {currentFarmVerificationDocuments.kmlFiles[0].fileName}
                                    </Fragment>
                                }
                                disabled
                            ></UploadedDocument>
                        </Grid>
                        <Grid display={"flex"} alignItems={"center"}>
                            <Typography
                                variant="h4"
                                sx={{
                                    color: "#3e9781",
                                    cursor: "pointer",
                                    marginX: "10px",
                                }}
                                fontWeight={600}
                                component="span"
                                onClick={() =>
                                    handleDownload(
                                        currentFarmVerificationDocuments.kmlFiles[0].s3URL
                                    )
                                }
                            >
                                {t("ver_doc_download")}
                            </Typography>
                            |
                            {page === 'Maps' ?
                                <Typography
                                    variant="h4"
                                    sx={{
                                        color: "#3e9781",
                                        cursor: "pointer",
                                        marginLeft: "10px",
                                    }}
                                    fontWeight={600}
                                    component="span"
                                    onClick={() =>
                                        handleDeleteMapDialogFunction()
                                    }
                                >
                                    {t("ver_doc_delete")}
                                </Typography>
                                :
                                <Typography
                                    variant="h4"
                                    sx={{
                                        color: "#808080",
                                        userSelect: "none",
                                        marginX: "5px",
                                    }}
                                    fontWeight={600}
                                    fontSize={12}
                                    component="span"
                                >
                                    {t("ver_doc_delete")}
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            ) : showUploadButton() ?
                (<>
                    <Grid item>
                        <SubmitButton
                            sx={{ fontSize: 13, width: "250px" }}
                            component="label"
                            variant="contained"
                            startIcon={<CloudUploadIcon />}
                            onChange={(e) => handleFileChange(e, "kmlFiles")}
                        >
                            {t("ver_doc_upload")}
                            <input
                                type="file"
                                accept=".kml,
                      .kmz,
                      application/vnd.google-earth.kml+xml,
                      application/vnd.google-earth.kmz,
                      application/octet-stream,
                      application/geo+json"
                                hidden
                            />
                        </SubmitButton>
                    </Grid>
                </>) :
                <></>
            }
        </Grid>
    </Grid>

        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <ConfirmDialog onClose={handleDeleteMapDialogClose} open={deleteMapDialogOpen} textQuestion={t("pmo_delete_map_message")} textYes={t("pmo_delete_map_yes")} textNo={t("pmo_delete_map_no")} handleDelete={handleDeleteMapDialogContinue} textTitle={t("pmo_delete_map_heading")} />
            </Grid>
        </Grid>
    </>
}

export default DigitalMaps