import { TextField } from '@mui/material';
import React from 'react';
import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';


const NumericInput = ({ name, disabled, value, onChange, allowNegative = false, decimalScale = 0, ...props }) => {
    const currentFarmStatusReducer = useSelector(
        (state) => state.farmStatusReducer
    );

    const accountSettings = useSelector((state) => state.accountSettingsReducer);
    const isAdmin = accountSettings?.[0]?.role === 'admin'
    const allowEditing = currentFarmStatusReducer.allowEditing || isAdmin


    const withValueCap = (inputObj) => {
        const { value } = inputObj;
        const maxValid = props.max !== undefined ? value <= props.max : true;
        const minValid = props.min !== undefined ? value >= props.min : true;
        return maxValid && minValid;
    };

    return (
        <NumericFormat customInput={TextField} size="small" decimalScale={decimalScale} name={name}
            disabled={disabled || !allowEditing}
            isAllowed={withValueCap}
            value={value}
            fullWidth
            allowNegative={allowNegative}
            placeholder={props.placeholder} label={props.label} onChange={onChange}
            {...props} />
    )
}

export default NumericInput