import React, { useEffect } from 'react';

import "../css/Dashboard.css";

import { useUser } from '@clerk/clerk-react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { createAccountSettings, retrieveAccountSettings } from '../actions/accountSettings';
import AdminDashboardComponent from './AdminDashboardComponent';
import FarmerDashboardComponent from './FarmerDashboardComponent';

const DashboardComponent = () => {

  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const { user } = useUser();

  const email = user?.emailAddresses[0]?.emailAddress;

  const currentFarmDataObject = useSelector(
    (state) => state.currentFarmDataReducer
  );

  const fetchAccountSettings = () => {
    dispatch(retrieveAccountSettings(email))
      .then((res) => {
        if (!res.data) {
          if (currentFarmDataObject._id) {
            const tmpData = {
              farmPrimaryIdFromMongoose: currentFarmDataObject._id,
              email: email,
              language: i18n.language,
              role: 'farmer',
            };
            dispatch(createAccountSettings(tmpData))
              .then((res) => {
                console.log("Account settings created:", res);
              })
              .catch((error) => {
                console.error("Error creating account settings:", error);
              });
          }
        }
        else { i18n.changeLanguage(res.data[0]?.language) }
      })
      .catch((error) => {
        console.error("Error retrieving account settings:", error);
      });
  };



  useEffect(() => {
    fetchAccountSettings();
  }, [currentFarmDataObject._id]);

  const accountSettings = useSelector((state) => state.accountSettingsReducer);
  const isAdmin = accountSettings?.[0]?.role === 'admin'

  return (
    <div className='body__content' style={{ marginTop: 85 }}>

      {isAdmin ? <AdminDashboardComponent /> : <FarmerDashboardComponent />}

    </div>
  )
}

export default DashboardComponent