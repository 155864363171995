import HttpService from '../services/HttpService';

const getAll = () => {
    return HttpService.getAxiosClient().get("/api/monitoringdata/");
};

const get = id => {
    return HttpService.getAxiosClient().get(`/api/monitoringdata/${id}`);
};

const create = data => {
    return HttpService.getAxiosClient().post("/api/monitoringdata", data);
};

const update = (id, data) => {
    // console.log("Data:", data);
    return HttpService.getAxiosClient().patch(`/api/monitoringdata/${id}`, data);
};

const remove = id => {
    return HttpService.getAxiosClient().delete(`/api/monitoringdata/${id}`);
};

const findByFarmId = param => {
    return HttpService.getAxiosClient().get(`/api/monitoringdata?farmId=${param}`);
};

const findByEmail = param => {
    return HttpService.getAxiosClient().get(`/api/monitoringdata?email=${param}`);
};



const MonitoringDataService = {
    getAll,
    get,
    create,
    update,
    remove,
    findByFarmId,
    findByEmail
};

export default MonitoringDataService;
