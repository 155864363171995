import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  CheckCircle,
  CloseRounded as CloseRoundedIcon,
  CloudUpload as CloudUploadIcon,
  Info as InfoIcon,
} from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormLabel,
  Grid,
  InputAdornment,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import "../css/FormFields.css";
import { BaselineYears, ProjectYears, SubmitButton, UploadedDocument } from "../styles";

//import Select from 'react-select';

import {
  updateFarmVerificationDocuments
} from "../actions/verificationDocuments";

import { defaultValues } from "../constants/verificationDocsDefaultValues";


import BackHomeComponent from "./general/BackHomeComponent";


import { setCurMonitoringData } from "../actions/currentMonitoringData";
import { updateMonitoringData } from "../actions/monitoringData";
import VerificationDocumentsUploadService from "../farmservices/VerificationDocumentsUploadService";
import NumericInput from "./general/NumericInput";
import TrailingLine from "./general/TrailingLine";
import { emptyNumericFieldError } from "./general/Validations/fieldValueErrors";
import { max } from "./general/Validations/minMaxValues";
import { getYearsArray } from "./general/utils/allDBYears";
import { handleDeleteFile, handleDownload } from "./general/utils/fileOperations";


const Step2EnergyUsageComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentFarmDataObject = useSelector((state) => state.currentFarmDataReducer);
  const currentFarmVerificationDocuments = useSelector((state) => state.farmVerificationDocumentsReducer);
  const currentFarmStatusReducer = useSelector((state) => state.farmStatusReducer);
  const currentMonitoringDataObject = useSelector((state) => state.currentMonitoringDataReducer);
  const currentMonitoringYearsObject = useSelector(state => state.monitoringYearsTrackerReducer);
  const allMonitoringYears = currentMonitoringYearsObject.allMonitoringYears

  const allDBYears = getYearsArray()

  const [diesel, setDiesel] = useState(allDBYears.map((year, i) =>
    currentMonitoringDataObject?.yearlyFarmData?.[i]?.fuelUsage?.direct?.diesel
      ?.amount)
  );

  const [petrol, setPetrol] = useState(allDBYears.map((year, i) =>
    currentMonitoringDataObject?.yearlyFarmData?.[i]?.fuelUsage?.direct?.petrol
      ?.amount)
  );

  const [currentDocumentComment, setCurrentDocumentComment] = useState("");
  const [currentDocumentYear, setCurrentDocumentYear] = useState("");
  const [currentDocumentFolder, setCurrentDocumentFolder] = useState("");
  const [currentDocumentIndex, setCurrentDocumentIndex] = useState("");

  const [confirmPopUpOpen, setConfirmPopUpOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;
  const [showLoader, setShowLoader] = useState(false);
  const [saveCurrentDataFlag, setSaveCurrentDataFlag] = useState(false);

  const handleBackdropClose = () => {
    setSaveCurrentDataFlag(false);
  };

  const handleBackdropClickCaptureEvent = (e) => {
    e.stopPropagation();
    return false;
  };

  const handleSnackbarClose = () => {
    setState({ vertical: "top", horizontal: "center", open: false });
  };

  const handleConfirmPopUpOpen = () => {
    setConfirmPopUpOpen(true);
  };

  const handleConfirmPopUpClose = (event, reason) => {
    if (reason && reason == "backdropClick") {
      return;
    }
    setConfirmPopUpOpen(false);
  };


  const accountSettings = useSelector((state) => state.accountSettingsReducer);
  const isAdmin = accountSettings?.[0]?.role === 'admin'
  const allowEditing = currentFarmStatusReducer?.allowEditing || isAdmin

  const handleFuelUsageUpdate = async () => {
    setShowLoader(true);
    setSaveCurrentDataFlag(true);

    let updatedMonitoringDataObject = JSON.parse(JSON.stringify(currentMonitoringDataObject));

    let tempObj = JSON.parse(JSON.stringify(updatedMonitoringDataObject.yearlyFarmData));

    tempObj[0].fuelUsage.direct.diesel.amount = diesel[0];
    tempObj[1].fuelUsage.direct.diesel.amount = diesel[1];
    tempObj[2].fuelUsage.direct.diesel.amount = diesel[2];
    tempObj[3].fuelUsage.direct.diesel.amount = diesel[3];
    tempObj[4].fuelUsage.direct.diesel.amount = diesel[4];
    tempObj[5].fuelUsage.direct.diesel.amount = diesel[5];

    tempObj[0].fuelUsage.direct.petrol.amount = petrol[0];
    tempObj[1].fuelUsage.direct.petrol.amount = petrol[1];
    tempObj[2].fuelUsage.direct.petrol.amount = petrol[2];
    tempObj[3].fuelUsage.direct.petrol.amount = petrol[3];
    tempObj[4].fuelUsage.direct.petrol.amount = petrol[4];
    tempObj[5].fuelUsage.direct.petrol.amount = petrol[5];

    updatedMonitoringDataObject.yearlyFarmData = tempObj;
    dispatch(setCurMonitoringData(updatedMonitoringDataObject))
      .then((response) => {
        console.log('Current Monitoring Data Set Successfully:', response);
        return dispatch(updateMonitoringData(currentMonitoringDataObject._id, updatedMonitoringDataObject));
      })
      .then((updateResponse) => {
        console.log('Monitoring Data Updated Successfully:', updateResponse);
      })
      .catch((error) => {
        console.error('Error occurred while dispatching actions:', error);
      })
      .then((res) => {
        setSnackbarMessage(t("farmland_draft_submission_msg_success"));
        setState({ open: true, vertical: "top", horizontal: "center" });
        setShowLoader(false);
        setSaveCurrentDataFlag(false);
        if (!isAdmin) {
          navigate("/carbonplus/fuel/indirect");
        }
      })
      .catch((e) => {
        setSnackbarMessage(t("farmland_draft_submission_msg_failure"));
        setState({ open: true, vertical: "top", horizontal: "center" });
        setSaveCurrentDataFlag(false);
        setShowLoader(false);
      });
  };


  const handleFileChange = (e, subfolder, year) => {
    // if no year default value
    setShowLoader(true);
    setSaveCurrentDataFlag(true);

    let email = currentFarmDataObject.farmInfo.email.replace("@", "__");

    let file = e.target.files[0];
    // let fileName = e.target.files[0].name;

    let fileNameArr = e.target.files[0].name.split(".").map((ele) => {
      return ele.replace(/[^a-zA-Z0-9]/g, "");
    });

    let fileName = fileNameArr.join(".");

    if (year !== undefined && year !== "" && year !== null) {
      handleFileUpload(file, fileName, subfolder, email, year);
    } else {
      handleFileUpload(file, fileName, subfolder, email);
    }
  };

  const handleFileUpload = async (file, fileName, subfolder, email, year) => {
    const formData = new FormData();

    const server = process.env.REACT_APP_NODE_ENV === 'production' ? 'monitoring_round_2024_uploads' : 'test';

    let tempData = {
      dateUploaded: Date.now().toString(),
      cfDocumentName: defaultValues[subfolder],

      folder: subfolder,
      fileName: fileName,
      documentType: "",
      s3URL: "",
      year: year,
      comment: "",
    };

    formData.append("subfolder", subfolder);
    if (year !== undefined && year !== "") {
      formData.append("year", year);
    }
    formData.append("email", email);
    formData.append("server", server);
    formData.append("file", file);

    const response = await VerificationDocumentsUploadService.upload(formData);

    tempData.s3URL = response.data.message;
    tempData.documentType = response.data.data.mimetype;


    const monitoringYear = allDBYears.indexOf(year);

    let tempObj = currentFarmVerificationDocuments;

    if (subfolder === "dieselInvoices") {
      tempObj.yearlyVerificationDocuments[monitoringYear].dieselInvoices.push(
        tempData
      );
    }
    if (subfolder === "petrolInvoices") {
      tempObj.yearlyVerificationDocuments[monitoringYear].petrolInvoices.push(
        tempData
      );
    }

    // upload to mongoDB
    dispatch(
      updateFarmVerificationDocuments(
        currentFarmVerificationDocuments._id,
        tempObj
      )
    )
      .then((res) => {
        setSnackbarMessage(t("uploading_document_msg_success"));
        setShowLoader(false);
        setState({ open: true, vertical: "top", horizontal: "center" });
        setSaveCurrentDataFlag(false);
      })
      .catch((e) => {
        setSnackbarMessage(t("uploading_document_msg_failure"));
        setShowLoader(false);
        setState({ open: true, vertical: "top", horizontal: "center" });
        setSaveCurrentDataFlag(false);
      });
  };

  const saveVerificationDocumentComment = () => {
    let tempObj = currentFarmVerificationDocuments;

    tempObj.yearlyVerificationDocuments[currentDocumentYear][
      currentDocumentFolder
    ][currentDocumentIndex].comment = currentDocumentComment;

    dispatch(updateFarmVerificationDocuments(currentFarmVerificationDocuments._id, tempObj))
      .then((res) => {
        setCurrentDocumentComment("");
        setCurrentDocumentYear("");
        setCurrentDocumentFolder("");
        setCurrentDocumentIndex("");
        console.log('Farm verification documents updated successfully:', res);
      })
      .catch((error) => {
        console.error('Error updating farm verification documents:', error);
      });

    setConfirmPopUpOpen(false);
  };

  const baselineYear = (year) => {
    if (allMonitoringYears[0] === year || allMonitoringYears[1] === year || allMonitoringYears[2] === year) {
      return true;
    } else {
      return false;
    }
  };

  // when scrolling "diesel" or "petrol" both elements scroll simultaneously
  const s1 = document.getElementById('ScrollWrapper1');
  const s2 = document.getElementById('ScrollWrapper2');
  const s3 = document.getElementById('ScrollWrapper3');

  const select_scroll_1 = () => {
    s2.scrollLeft = s1.scrollLeft;
    s3.scrollLeft = s1.scrollLeft;
  }
  const select_scroll_2 = () => {
    s1.scrollLeft = s2.scrollLeft;
    s3.scrollLeft = s2.scrollLeft;
  }

  const select_scroll_3 = () => {
    s1.scrollLeft = s3.scrollLeft;
    s2.scrollLeft = s3.scrollLeft;
  }

  if (s1) {
    s1.addEventListener("scroll", select_scroll_1, false);
  }
  if (s2) {
    s2.addEventListener("scroll", select_scroll_2, false);
  }
  if (s3) {
    s3.addEventListener('scroll', select_scroll_3, false);
  }
  //

  const projectYearsWidth = {
    4: '250px',
    5: '520px',
    6: '787px',
  };

  return (
    <>

      {showLoader && (
        <Backdrop
          onClickCapture={(e) => handleBackdropClickCaptureEvent(e)}
          style={{ color: "#fff", zIndex: 99999 }}
          open={saveCurrentDataFlag}
          onClick={handleBackdropClose}
        >
          <div style={{ fontSize: 20, fontWeight: 600, color: "white", marginTop: "20px" }}>
            {t("generic_saving_data")}
          </div>
        </Backdrop>
      )}

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        key={vertical + horizontal}
      />

      {!isAdmin && (
        <Box marginLeft={6}>
          <BackHomeComponent></BackHomeComponent>
        </Box>
      )}


      <Box margin={12} marginTop={2}>
        {/* <Grid container spacing={2} > */}
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormControl fullWidth>
            <FormLabel>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 30,
                  marginBottom: 30,
                }}
              >
                <Typography
                  variant='h1'
                  color="#000000"
                >
                  {t("fu_dir_title")}
                </Typography>
                <Typography color="#000000" variant="subtitle1">
                  {t("fu_dir_description_1")} <b>{t("fu_dir_description_2")}</b>
                </Typography>
              </Grid>
            </FormLabel>
          </FormControl>
        </Grid>

        <Box marginTop={2} sx={{ display: 'flex', flexDirection: 'row' }}>
          <Stack width={'220px'} ></Stack>
          <div id="ScrollWrapper3" style={{ display: 'flex', flexDirection: 'row', overflowX: 'scroll' }}>
            <Stack marginLeft={2} >
              <BaselineYears sx={{ width: '782px' }}>{t("baseline_years")}</BaselineYears>
            </Stack>
            <Stack>
              <ProjectYears sx={{ marginLeft: 2, bgcolor: '#808080', minWidth: projectYearsWidth[allMonitoringYears.length] }}>{t("project_years")}</ProjectYears>
            </Stack>
          </div>
        </Box>

        {/*  DIESEL  */}
        <Box marginTop={2} sx={{ display: 'flex', flexDirection: 'row' }}>


          <Stack width={'220px'} >

            <Grid marginTop={6}>
              <Typography variant="h3" >{t("fuelusage_diesel")}</Typography>
            </Grid>
            <Grid marginTop={2} item xs={12} sm={12} md={12} lg={12}>
              <Typography variant="subtitle3" >{t("fu_section_subtitle_1")}</Typography>
            </Grid>
            <Grid marginTop={4} item xs={12} sm={12} md={12} lg={12} >
              <Typography variant="subtitle3"  >{t("fu_section_subtitle_2")}
                <Tooltip sx={{ paddingLeft: 1 }} title={t("fu_section_infobox")} placement="top-start">
                  <InfoIcon sx={{
                    fontSize: 'medium',
                    pl: 0.5,
                  }} />
                </Tooltip>
              </Typography>
            </Grid>

          </Stack>

          <div id="ScrollWrapper1" style={{ display: 'flex', flexDirection: 'row', overflowX: 'scroll' }}>
            {allMonitoringYears.map((year) => {
              const i = allDBYears.indexOf(year)
              return allMonitoringYears.includes(year) &&
                <Stack marginLeft={2} sx={{ minWidth: '250px' }} key={year}>
                  {baselineYear(year) ?
                    <BaselineYears sx={{ marginBottom: 2 }}>{year}</BaselineYears>
                    :
                    <ProjectYears sx={{ marginBottom: 2 }}>{year}</ProjectYears>
                  }

                  <NumericInput sx={{ marginBottom: 4, marginTop: 5 }}
                    error={emptyNumericFieldError(diesel[i])}
                    id='diesel'

                    label={t("fu_dir_quantity")}
                    value={diesel[i] !== -9999 ? diesel[i] : ''}
                    max={max.dieselL}
                    InputProps={{
                      endAdornment: <InputAdornment position="start">{t("generic_litres")}</InputAdornment>,
                    }}
                    onChange={(e) => {
                      setDiesel(prevDiesel => {
                        const updatedDiesel = [...prevDiesel];
                        updatedDiesel[i] = e.target.value;
                        return updatedDiesel;
                      });
                    }} />

                  {currentFarmVerificationDocuments?.yearlyVerificationDocuments?.[i]?.dieselInvoices?.length > 0 &&
                    currentFarmVerificationDocuments.yearlyVerificationDocuments[i].dieselInvoices.map((ele, index) => {
                      return <React.Fragment key={index}>
                        <Grid container spacing={2}>
                          <Stack display={'flex'} sx={{ mb: 1, mt: 1.5, ml: 2 }} >
                            <UploadedDocument
                              size="small"
                              label={
                                <Fragment>
                                  <CheckCircle sx={{ mr: 1 }} />
                                  {currentFarmVerificationDocuments?.yearlyVerificationDocuments?.[i]?.dieselInvoices[index].fileName}
                                </Fragment>
                              }
                              disabled
                            ></UploadedDocument>
                            <Grid item sx={{ mb: 1.5 }}>
                              <Typography
                                sx={{ color: '#3e9781', cursor: 'pointer', marginRight: '5px' }}
                                fontWeight={600}
                                fontSize={12}
                                component="span"
                                onClick={() => handleDownload(currentFarmVerificationDocuments.yearlyVerificationDocuments[i].dieselInvoices[index].s3URL)}
                              >{t("ver_doc_download")}</Typography>
                              |
                              {allowEditing && <Typography
                                sx={{ color: '#3e9781', cursor: 'pointer', marginX: '5px' }}
                                fontWeight={600}
                                fontSize={12}
                                component="span"
                                onClick={() => handleDeleteFile(index, currentFarmVerificationDocuments.yearlyVerificationDocuments[i].dieselInvoices[index].folder, currentFarmVerificationDocuments.yearlyVerificationDocuments[i].dieselInvoices[index].fileName, year, currentFarmDataObject,
                                  currentFarmVerificationDocuments,
                                  dispatch,
                                  setSnackbarMessage,
                                  setSaveCurrentDataFlag,
                                  setState,
                                  t)}
                              >{t("ver_doc_delete")}</Typography>}
                              |
                              {currentFarmVerificationDocuments.yearlyVerificationDocuments[i].dieselInvoices[index].comment ?
                                <Typography
                                  sx={{ color: '#3e9781', cursor: 'pointer', marginLeft: '5px' }}
                                  fontWeight={600}
                                  fontSize={12}
                                  component="span"
                                  onClick={() => {
                                    setCurrentDocumentComment(currentFarmVerificationDocuments.yearlyVerificationDocuments[i].dieselInvoices[index].comment);
                                    setCurrentDocumentYear(i); //year index
                                    setCurrentDocumentFolder('dieselInvoices');
                                    setCurrentDocumentIndex(index);
                                    handleConfirmPopUpOpen()
                                  }}
                                >{t("general_edit_comment")}</Typography>
                                :
                                <Typography
                                  sx={{ color: '#3e9781', cursor: 'pointer', marginLeft: '5px' }}
                                  fontWeight={600}
                                  fontSize={12}
                                  component="span"
                                  onClick={() => {
                                    setCurrentDocumentYear(i);
                                    setCurrentDocumentFolder('dieselInvoices');
                                    setCurrentDocumentIndex(index);
                                    handleConfirmPopUpOpen()
                                  }}
                                >{t("general_add_comment")}</Typography>
                              }
                            </Grid>
                          </Stack>
                        </Grid>
                      </React.Fragment>
                    })}
                  <Grid item style={{ marginBottom: '10px', display: 'flex' }}>
                    <SubmitButton sx={{ fontSize: 13, width: '250px' }}
                      component="label"
                      variant="contained"
                      startIcon={<CloudUploadIcon />}
                      onChange={(e) => handleFileChange(e, "dieselInvoices", year)}
                      disabled={!allowEditing}>
                      {t("ver_doc_upload")}
                      <input
                        type="file"
                        accept="application/msword,
                          application/pdf, 
                          application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                          image/png, 
                          image/jpeg"
                        hidden

                      />
                    </SubmitButton>
                  </Grid>
                </Stack>
            })}

          </div>
        </Box>
        <TrailingLine />
        {/* PETROL   */}
        <Box marginTop={4} sx={{ display: 'flex', flexDirection: 'row' }}>

          <Stack width={'220px'} >

            <Grid>
              <Typography variant="h3" >{t("fuelusage_petrol")}</Typography>
            </Grid>
            <Grid marginTop={2} item xs={12} sm={12} md={12} lg={12}>
              <Typography variant="subtitle3" >{t("fu_section_subtitle_1")}</Typography>
            </Grid>
            <Grid marginTop={4} item xs={12} sm={12} md={12} lg={12} >
              <Typography variant="subtitle3" >{t("fu_section_subtitle_2")}
                <Tooltip sx={{ paddingLeft: 1 }} title={t("fu_section_infobox")} placement="top-start">
                  <InfoIcon sx={{
                    fontSize: 'medium',
                    pl: 0.5,
                  }} />
                </Tooltip>
              </Typography>
            </Grid>

          </Stack>

          <div id="ScrollWrapper2" style={{ display: 'flex', flexDirection: 'row', overflowX: 'scroll' }}>
            {allMonitoringYears.map((year) => {
              const i = allDBYears.indexOf(year)
              return allMonitoringYears.includes(year) &&
                <Stack marginTop={5} marginLeft={2} sx={{ minWidth: '250px' }} key={year}>

                  <NumericInput
                    error={emptyNumericFieldError(petrol[i])}
                    sx={{ mb: 4 }}
                    id='petrol'

                    label={t("fu_dir_quantity")}
                    value={petrol[i] !== -9999 ? petrol[i] : ''}
                    max={max.petrolL}
                    InputProps={{
                      endAdornment: <InputAdornment position="start">{t("generic_litres")}</InputAdornment>,
                    }}
                    onChange={(e) => {
                      setPetrol(prevPetrol => {
                        const updatedPetrol = [...prevPetrol];
                        updatedPetrol[i] = e.target.value;
                        return updatedPetrol;
                      });
                    }} />

                  {currentFarmVerificationDocuments?.yearlyVerificationDocuments?.[i]?.petrolInvoices?.length > 0 &&
                    currentFarmVerificationDocuments.yearlyVerificationDocuments[i].petrolInvoices.map((ele, index) => {
                      return <React.Fragment key={index}>
                        <Grid container spacing={2}>
                          <Stack marginLeft={2} display={'flex'} sx={{ mb: 1.5, mt: 1 }}>
                            <UploadedDocument
                              size="small"
                              label={
                                <Fragment>
                                  <CheckCircle sx={{ marginRight: 1 }} />
                                  {currentFarmVerificationDocuments?.yearlyVerificationDocuments?.[i]?.petrolInvoices[index].fileName}
                                </Fragment>
                              }
                              disabled
                            ></UploadedDocument>
                            <Grid item sx={{ mb: 1.5 }}>
                              <Typography
                                sx={{ color: '#3e9781', cursor: 'pointer', marginRight: '5px' }}
                                fontWeight={600}
                                fontSize={12}
                                component="span"
                                onClick={() => handleDownload(currentFarmVerificationDocuments.yearlyVerificationDocuments[i].petrolInvoices[index].s3URL)}
                              >{t("ver_doc_download")}</Typography>
                              |
                              {allowEditing && <Typography
                                sx={{ color: '#3e9781', cursor: 'pointer', marginX: '5px' }}
                                fontWeight={600}
                                fontSize={12}
                                component="span"
                                onClick={() => handleDeleteFile(index, currentFarmVerificationDocuments.yearlyVerificationDocuments[i].petrolInvoices[index].folder, currentFarmVerificationDocuments.yearlyVerificationDocuments[i].petrolInvoices[index].fileName, year, currentFarmDataObject,
                                  currentFarmVerificationDocuments,
                                  dispatch,
                                  setSnackbarMessage,
                                  setSaveCurrentDataFlag,
                                  setState,
                                  t)}
                              >{t("ver_doc_delete")}</Typography>}
                              |
                              {currentFarmVerificationDocuments.yearlyVerificationDocuments[i].petrolInvoices[index].comment ?
                                <Typography
                                  sx={{ color: '#3e9781', cursor: 'pointer', marginLeft: '5px' }}
                                  fontWeight={600}
                                  fontSize={12}
                                  component="span"
                                  onClick={() => {
                                    setCurrentDocumentComment(currentFarmVerificationDocuments.yearlyVerificationDocuments[i].petrolInvoices[index].comment);
                                    setCurrentDocumentYear(i);
                                    setCurrentDocumentFolder('petrolInvoices');
                                    setCurrentDocumentIndex(index);
                                    handleConfirmPopUpOpen()
                                  }}
                                >{t("general_edit_comment")}</Typography>
                                :
                                <Typography
                                  sx={{ color: '#3e9781', cursor: 'pointer', marginLeft: '5px' }}
                                  fontWeight={600}
                                  fontSize={12}
                                  component="span"
                                  onClick={() => {
                                    setCurrentDocumentYear(i);
                                    setCurrentDocumentFolder('petrolInvoices');
                                    setCurrentDocumentIndex(index);
                                    handleConfirmPopUpOpen()
                                  }}
                                >{t("general_add_comment")}</Typography>
                              }
                            </Grid>
                          </Stack>
                        </Grid>
                      </React.Fragment>
                    })}
                  <Grid item style={{ marginBottom: '10px', display: 'flex' }}>
                    <SubmitButton
                      sx={{ fontSize: 13, width: '250px' }}
                      component="label"
                      variant="contained"
                      startIcon={<CloudUploadIcon />}
                      onChange={(e) => handleFileChange(e, "petrolInvoices", year)}
                      disabled={!allowEditing}>
                      {t("ver_doc_upload")}
                      <input
                        type="file"
                        accept='application/msword,
                          application/pdf, 
                          application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                          image/png, 
                          image/jpeg'
                        hidden />
                    </SubmitButton>
                  </Grid>

                </Stack>
            })}
          </div>
        </Box>

        {/* </Grid>  */}
        {allowEditing && (
          <Box display="flex" flexDirection="column">
            {isAdmin ?
              <SubmitButton sx={{ mt: "100px", alignSelf: "flex-end", width: "300px", }}
                onClick={handleFuelUsageUpdate}
              >
                {t("generic_save")}
              </SubmitButton> :
              <SubmitButton sx={{ mt: "100px", alignSelf: "flex-end", width: "300px", }}
                onClick={handleFuelUsageUpdate}
              >
                {t("generic_save_continue")}
              </SubmitButton>
            }
          </Box>
        )}
      </Box>


      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Dialog
            PaperProps={{ sx: { maxWidth: "revert", width: "700px" } }}
            open={confirmPopUpOpen}
            onClose={handleConfirmPopUpClose}
            aria-labelledby="alert-dialog-confirm-popup"
            aria-describedby="alert-dialog-confirm-popup-description"
          >
            <CloseRoundedIcon
              sx={{
                alignSelf: "flex-end",
                padding: 2,
                color: "#707070",
                "&:hover": { cursor: "pointer" },
              }}
              onClick={() => {
                setCurrentDocumentComment("");
                setCurrentDocumentYear("");
                setCurrentDocumentFolder("");
                setCurrentDocumentIndex("");
                setConfirmPopUpOpen(false);
              }}
            />
            <DialogTitle id="alert-dialog-confirm-popup">
              <Typography
                paddingX={2}
                paddingBottom={2}
                fontWeight={800}
                fontSize={24}
              >
                {t("general_add_comment")}
              </Typography>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description" color="black">
                <Typography paddingX={2}>
                  {t("fu_comment_description")}
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogContent sx={{ mx: 2 }}>
              <TextField
                id="verification-document-upload"
                multiline
                maxRows={4}
                sx={{ width: "100%" }}
                value={currentDocumentComment}
                placeholder={t("fu_comment_type_here")}
                onChange={(e) => setCurrentDocumentComment(e.target.value)}
                disabled={!allowEditing}
              ></TextField>
            </DialogContent>
            <br />
            <DialogActions style={{ padding: "0px 40px 20px 20px" }}>
              <SubmitButton
                disableElevation
                disableRipple
                onClick={saveVerificationDocumentComment}
              >
                {t("generic_save")}
              </SubmitButton>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    </>
  );
};

export default Step2EnergyUsageComponent;
