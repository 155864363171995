export const translationDE = {
home_welcome:"Willkommen",
home_frequently:"Häufig gestellte Fragen (FAQ)",
home_welcome_to_cf:"Willkommen bei Climate Farmers",
back_to_home:"ZURÜCK",
farminfo_tell:"Erzählen Sie uns ein wenig über Ihre Farm",
farminfo_owner_fname:"Vorname des Landbesitzers",
farminfo_owner_lname:"Nachname des Landbesitzers",
farminfo_email:"E-Mail-Addresse",
farminfo_postal_code:"Postleitzahl",
farminfo_coord_north:"Farmkoordinaten (° N)",
farminfo_coord_east:"Farmkoordinaten (° E)",
farminfo_country:"Land",
farminfo_phone:"Telefon, einschließlich Ländercode",
farminfo_duration:"Projektdauer (in Jahren)",
farminfo_challenges:"Herausforderungen",
farminfo_inst_barriers:"Institutionelle Barrieren",
farminfo_tech_barriers:"Technologische Barrieren",
farminfo_reg_trad:"Barrieren aufgrund regionaler Traditionen",
farminfo_mgmt_pract:"Barrieren, die sich durch bestehende Landmanagementpraktiken entwickeln",
farminfo_land_ownership:"Barrieren, die mit Landbesitz, Erbschaft oder Eigentumsrechten verbunden sind",
farminfo_no_labour:"Kein Zugang zu qualifizierten Arbeitskräften",
farminfo_no_coaching:"Kein Zugang zu qualifizierter Beratung/Coaching",
farminfo_no_barriers:"Keine Barrieren",
farminfo_remove:"Entfernen",
farminfo_save:"Speichern",
fuelusage_current:"Aktueller Kraftstoffverbrauch (optional)",
fuelusage_here:"Dies bezieht sich auf den Kraftstoffverbrauch pro Jahr für landwirtschaftliche Aktivitäten wie Maschinen",
fuelusage_fueltype:"Treibstoffart",
fuelusage_amt:"Betrag in Litern",
fuelusage_petrol:"Benzin",
fuelusage_diesel:"Diesel",
fuelusage_other:"Andere",
fuelusage_estimates:"Schätzungen der Kraftstoffverbrauch für das nächste Jahr (optional)",
fuelusage_save:"Speichern",
ls_no_data:"Keine Daten zum Anzeigen",
ls_current:"Aktuell",
ls_reset:"DATEN ZURÜCKSETZEN",
ls_year1:"JAHR 1",
ls_year2:"Jahr 2",
ls_year3:"Jahr 3",
ls_year4:"Jahr 4",
ls_year5:"Jahr 5",
ls_year6:"Jahr 6",
ls_year7:"JAHR 7",
ls_year8:"JAHR 8",
ls_year9:"JAHR 9",
ls_year10:"Jahr 10",
ls_yes:"Ja",
ls_no:"NEIN",
ls_dairy:"Milchkühe",
ls_calves:"Kälber (weniger als 1 Jahr)",
ls_buff:"Büffel",
ls_sows:"Sauen/Eber",
ls_pigs:"Schweine",
ls_lambs:"Lämmer (weniger als 1 Jahr)",
ls_goats:"Ziegen",
ls_poultry:"Geflügel",
ls_turkey:"Truthähne",
ls_ducks:"Enten",
ls_horses:"Pferde",
ls_mules:"Maultiere/Esel",
ls_camelids:"Kamelide",
ls_value:"Der Wert sollte größer als 0 sein",
ls_unplanned:"Ungeplante Beweidung",
ls_adaptive:"Adaptiver Multi-Paddock-Weiden / Weiden",
ls_rest:"Rest des Jahres",
ls_slaughtered:"Geschlachtet",
ls_outdoors:"Außenpaddock",
ls_sold:"Verkauft",
ls_percent:"Prozent",
ls_onfield:"Auf dem Feld",
ls_composted:"Kompostiert",
ls_scattered:"Direkt verstreut",
ls_collection:"Sammlung und Entsorgung",
ls_save:"SPEICHERN",
ls_close:"SCHLIESSEN",
lu_map_location_msg_1:"Der Standardstandort der Karte zeigt auf die Koordinaten deines Betriebs:",
lu_map_location_msg_2:"Wenn du glaubst, dass der Standort nicht korrekt ist, gib die Adresse/Koordinaten in die Suchleiste ein oder gehe bitte zurück zum Abschnitt 'Allgemeine Informationen', um dort deine Koordinaten zu aktualisieren. Bitte beachte, dass nur die Koordinaten in diesem Abschnitt als Standardkoordinaten des Betriebs gelten.",
lu_search:"Suchen",
lu_importing:"Importieren einer KML -Datei",
lu_importing_2:"Importieren einer KML -Datei",
lu_how_can:"Wie kann ich eine KML -Datei meiner Farm erstellen?",
lu_close:"SCHLIESSEN",
lu_create_new:"Neue Vorlage erstellen",
lu_actions:"Aktionen",
lu_rows_per_page:"Zeilen pro Seite",
temp_land_use:"Landnutzungstyp",
temp_forest:"Forstwirtschaft",
temp_type_1:"Art der Bäume",
temp_quercus:"Quercus",
temp_olea:"Olea Europea",
temp_other:"Andere",
temp_tree_name_1:"Baumname (wenn nicht vorhanden)",
temp_avg_1:"Durchschnittliche Anzahl von Bäumen pro Hektar",
temp_irrigation:"Bewässerung",
temp_syn_fert:"Synthetischer Dünger",
temp_fert:"Dünger (t/Jahr)",
temp_nitro:"% Stickstoff",
temp_per_compost:"% Kompost importiert",
temp_name:"Name, wenn nicht in Dropdown -Liste im Kalender",
temp_unplanned:"Ungeplante Beweidung",
temp_jan:"Jan",
temp_feb:"Feb",
temp_apr:"Apr",
temp_jun:"Jun",
temp_jul:"Jul",
temp_sep:"Sep",
temp_dec:"Dez",
temp_barley:"Gerste",
temp_beans:"Bohnen",
temp_corn:"Mais",
temp_forage_beet:"Futterrübe",
temp_forage_kale:"Futterkohl",
temp_garlic:"Knoblauch",
temp_millet:"Hirse",
temp_oats:"Hafer",
temp_onions:"Zwiebeln",
temp_other_vegetables:"Anderes Gemüse",
temp_peanuts:"Erdnüsse",
temp_peas:"Erbsen",
temp_potatoes:"Kartoffeln und Knollen",
temp_pumpkin:"Kürbis",
temp_rice:"Reis",
temp_rye:"Roggen",
temp_seeds:"Samen",
temp_sesame:"Sesam",
temp_soybean:"Sojabohne",
temp_squash:"Quetschen",
temp_sunflower:"Sonnenblume",
temp_triticale:"Triticale",
temp_wheat:"Weizen",
temp_harvest_yield:"Ernteertrag (t/ha)",
temp_grazing_yield:"Beweidungsausbeute (t/ha)",
temp_save:"Speichern",
temp_close:"Schließen",
parcel_import:"KML -Datei importieren",
parcel_actions:"Aktionen",
parcel_year1:"Y-1",
parcel_year2:"Y-2",
parcel_year3:"Y-3",
parcel_year4:"Y-4",
parcel_year5:"Y-5",
parcel_year6:"Y-6",
parcel_year7:"Y-7",
parcel_year8:"Y-8",
parcel_year9:"Y-9",
parcel_year10:"Y-10",
parcel_coordinates:"Koordinaten",
parcel_parcels:"Parzellen",
parcel_copy_data:"Daten kopieren",
parcel_templates:"Vorlagen",
parcel_import_data:"DATEN IMPORTIEREN",
parcel_applying:"Anwendung dieser Praktiken seit (Jahren)?*",
parcel_like_copy:"Ich möchte aktuelle Praktiken auf die nächsten 10 Jahre kopieren",
parcel_agree:"ZUSTIMMEN",
parcel_agree_2:"ZUSTIMMEN",
parcel_land_use:"Landnutzungstyp",
parcel_forest:"Forstwirtschaft",
parcel_type_1:"Art der Bäume",
parcel_quercus:"Quercus",
parcel_olea:"Olea Europea",
parcel_other:"Andere",
parcel_avg_no_2:"Durchschnittliche Anzahl von Bäumen pro Hektar",
parcel_tree_name_1:"Baumname (wenn nicht vorhanden)",
parcel_avg_1:"Durchschnittliche Anzahl von Bäumen pro Hektar",
parcel_tree_name_2:"Baumname (wenn nicht vorhanden)",
parcel_avg_2:"Durchschnittliche Anzahl von Bäumen pro Hektar",
parcel_tree_name_3:"Baumname (wenn nicht vorhanden)",
parcel_avg_3:"Durchschnittliche Anzahl von Bäumen pro Hektar",
parcel_irrigation:"Bewässerung",
parcel_syn_fert:"Synthetischer Dünger",
parcel_fert:"Dünger (t/Jahr)",
parcel_nitro:"% Stickstoff",
parcel_per_compost:"% Kompost importiert",
parcel_name:"Name, wenn nicht in Dropdown -Liste im Kalender",
parcel_unplanned:"Ungeplante Beweidung",
parcel_jan:"Jan",
parcel_feb:"Feb",
parcel_apr:"Apr",
parcel_jun:"Jun",
parcel_jul:"Jul",
parcel_sep:"Sep",
parcel_dec:"Dez",
parcel_barley:"Gerste",
parcel_beans:"Bohnen",
parcel_corn:"Mais",
parcel_forage_beet:"Futterrübe",
parcel_forage_kale:"Futterkohl",
parcel_garlic:"Knoblauch",
parcel_millet:"Hirse",
parcel_oats:"Hafer",
parcel_onions:"Zwiebeln",
parcel_other_vegetables:"Anderes Gemüse",
parcel_peanuts:"Erdnüsse",
parcel_peas:"Erbsen",
parcel_potatoes:"Kartoffeln und Knollen",
parcel_pumpkin:"Kürbis",
parcel_rice:"Reis",
parcel_rye:"Roggen",
parcel_seeds:"Samen",
parcel_sesame:"Sesam",
parcel_soybean:"Sojabohne",
parcel_squash:"Quetschen",
parcel_sunflower:"Sonnenblume",
parcel_triticale:"Triticale",
parcel_wheat:"Weizen",
parcel_harvest_yield:"Ernteertrag (t/ha)",
parcel_grazing_yield:"Beweidungsausbeute (t/ha)",
parcel_save:"Speichern",
parcel_close:"Schließen",
parcel_y1:"JAHR 1",
parcel_y2:"Jahr 2",
parcel_y3:"Jahr 3",
parcel_y4:"Jahr 4",
parcel_y5:"Jahr 5",
parcel_y6:"Jahr 6",
parcel_y7:"JAHR 7",
parcel_y8:"JAHR 8",
parcel_y9:"JAHR 9",
parcel_y10:"Jahr 10",
sa_sil_per:"Schlickgehalt (%)",
sa_text_desc:"Texturbeschreibung",
sa_sand:"Sand",
sa_lo_sand:"Lehmiger Sand",
sa_sandy_lo:"Sandiger Lehm",
sa_loam:"Lehm",
sa_sil_loam:"Schlicklehm",
sa_silt_2:"Schlick",
sa_clay_3:"Ton",
sa_clay_loam:"Tonlehm",
sa_sandy_clay:"Sandiger Lehm",
sa_silty_clay:"Schlammer Ton",
sa_bulk_density:"Schüttdichte",
sa_g_kg:"g / kg",
sa_g:"G",
sa_kg:"kg",
sa_carbon:"Kohlenstoffgehalt",
sa_kg_kg_per:"g / kg / %",
sa_g_2:"G",
sa_kg_2:"kg",
sa_per:"%",
sa_if_carbon:"Wenn der Kohlenstoffgehalt nicht verfügbar ist:",
sa_g_kg_per_2:"g / kg / %",
sa_g_3:"G",
sa_kg_3:"kg",
sa_per_3:"%",
sa_save:"Speichern",
generic_save:"Speichern",
faq_pdf:"Häufig gestellte Fragen (FAQ)",
nc_soil_step_regular:"Boden teilen",
nc_soil_step_bold:"Proben",
jan:"Januar",
feb:"Februar",
apr:"April",
jun:"Juni",
jul:"Juli",
aug:"August",
sep:"September",
oct:"Oktober",
nov:"November",
dec:"Dezember",
ls_first_year:"Dies ist mein erstes Jahr",
ls_value_percent:"Der Wert sollte zwischen 0 und 100 liegen",
ls_value_year:"Der Wert sollte zwischen 0 und 365 liegen",
generic_edit:"Bearbeiten",
generic_delete:"Löschen",
values_fresh:"Frisch",
values_dry:"Trocken",
generic_close:"Schließen",
generic_agree:"Zustimmen",
generic_disagree:"Verschiedener Meinung sein",
ls_table_year1:"Y-1",
ls_table_year2:"Y-2",
ls_table_year3:"Y-3",
ls_table_year4:"Y-4",
ls_table_year5:"Y-5",
ls_table_year6:"Y-6",
ls_table_year7:"Y-7",
ls_table_year8:"Y-8",
ls_table_year9:"Y-9",
ls_table_year10:"Y-10",
carbon_y1:"Jahr 1",
carbon_y2:"Jahr 2",
carbon_y3:"Jahr 3",
carbon_y4:"Jahr 4",
carbon_y5:"Jahr 5",
carbon_y6:"Jahr 6",
carbon_y7:"Jahr 7",
carbon_y8:"Jahr 8",
carbon_y9:"Jahr 9",
carbon_y10:"Jahr 10",
farmland_final_submission_msg_success:"Farmdetails wurden erfolgreich gerettet. Sie können die Farmdetails nicht mehr ändern. Für den Fall, dass Sie Fragen haben, wenden Sie sich bitte an Climate Farmers Customer Support unter support@climatefarmers.org",
farmland_final_submission_msg_failure:"Es gab einen Fehler, der die Farmdetails speichert. Bitte überprüfen Sie die Werte, die Sie eingegeben haben",
msg_mandatory_fields:"Alle als * gekennzeichneten Felder sind obligatorisch und müssen gefüllt werden, bevor sie mit dem nächsten Schritt fortfahren",
email_body_farmland_update:"Hallo, wir haben ein Update für Sie über Ihr Ackerland. Bitte melden Sie sich bei Ihrem Climate Farmers -Konto an, um das Update anzuzeigen.",
infobox_coordinates_2:"Notiz:",
infobox_coordinates_3:"Die erste Zahl ist die",
infobox_coordinates_8:"(-) Vor",
infobox_ls_mgmt_1:"Tierverwaltungsoptionen:",
infobox_ls_manure_2:"- auf dem Feld:",
infobox_ls_manure_4:"- Kompostiert:",
infobox_lu_copy_practices:"Hier können Sie Ihre Einträge für die nächsten 10 Jahre kopieren und dann die Korrekturen für jedes Jahr und das Paket vornehmen. Stellen Sie sicher, dass Sie diese Option deaktivieren, wenn Sie in den folgenden Jahren zusätzliche Änderungen vornehmen möchten (andernfalls können Sie dies nicht zulassen).",
infobox_lu_type_1:"Silvopasture:",
infobox_lu_type_2:"Die Integration von Bäumen, Futter und die Beweidung von Vieh auf für beide Seiten vorteilhafte Weise. z.B. Das Montado / Dehesa -System. Weiden können beweidet oder geerntet werden.",
infobox_lu_type_6:"Ein Bereich, in dem die Vegetation von einer nahezu kontinuierlichen Abdeckung von Gräsern dominiert wird. Kann beweidet oder geerntet werden (Heu).",

infobox_lu_type_10:"Weiden können geerntet werden.",
infobox_lu_type_11:"Forstwirtschaft:",
infobox_lu_type_12:"Wälder für die Holzproduktion. Kein Beweidung oder Ernte.",

home_your_c_app:"Deine Carbon+ Anmeldung",
home_complete_onboarding:"Schliesse deine Anmeldung ab",
home_tell_us:"Erzähl uns mehr über deinen Betrieb",
home_provide:"Allgemeine Informationen deines Betriebs",
home_indicate:"Angabe des Kraftstoffverbrauchs",
home_plan:"Dein Viehbestand",
home_map:"Deine Parzellen und Anbauplanung",
home_share:"Deine Bodenproben",
home_submit:"Anmeldung absenden",
home_getting:"Erste Schritte",
home_dashboard:"Benutzerhandbuch",
home_add_farm:"Füge deinen Betrieb hinzu",
home_carbon_values:"Schätzung der Kohlenstoffwerte über 10 Jahre (Tonnen)",
home_project_terms:"Allgemeine Geschäftsbedingungen",
farminfo_please_enter:"Bitte gib Informationen zu deinem Betrieb an",
farminfo_manager_fname:"Vorname des Betriebsleiters:in",
farminfo_manager_lname:"Nachname des Betriebsleiters:in",
farminfo_farmsize:"Betriebsgröße in ha",
farminfo_street:"Betriebsadresse: Straße und Hausnummer (falls zutreffend)",
farminfo_start_year:"Startjahr des Projektes",
farminfo_start_month:"Startmonat des Projektes",
farminfo_what_challenges:"Welche Herausforderungen halten dich derzeit davon ab, (mehr) regenerative Praktiken in deinem Betrieb einzuführen?",
farminfo_inv_barriers:"Finanzielle Barrieren",
farminfo_eco_circ:"Barrieren durch ökologische Bedingungen",
farminfo_other:"Sonstige - Details",
farminfo_describe:"Beschreibung",
farminfo_add_more:"Weitere hinzufügen",
fuelusage_this_refers:"Dies bezieht sich auf den geschätzten jährlichen Treibstoffverbrauch für landwirtschaftliche Tätigkeiten, z. B. für Maschinen in der Zukunft",
ls_mgmt:"Nutztierhaltung",
ls_here_we:"Hier benötigen wir einige Angaben zu den spezifischen Haltungsformen: die aktuelle Situation und Pläne und Änderunge, die für nächsten 10 Jahren auf dem Betrieb umgesetzt werden sollen.",
ls_add:"Weitere Tierbestände hinzufügen",
ls_edit:"Tierbestand bearbeiten",
ls_view:"Tierbestand ansehen",
ls_del:"Tierbestand löschen",
ls_past:"Bisherige Nutztierhaltung",
ls_here_past:"Hier bitten wir dich, Angaben zu den bisherigen Haltungsformen zu machen und diese zu beschreiben.",
ls_how_many:"Seit wie vielen Jahren praktizierst du die derzeitigen Haltungsformen?",
ls_pls_desc:"Kannst du beschreiben, was du vorher anders gemacht hast?",
ls_livestock:"Tierbestand",
ls_livestock_mgmt_form:"Nutztierhaltung",
ls_autofill:"Möchtest du die Angaben für die nächsten 10 Jahre automatisch ausfüllen lassen?",
ls_species:"Nutztierart",
ls_beef:"Rinder",
ls_pigglets:"Ferkel",
ls_sheep:"Schafe",
ls_goat_kids:"Ziegenkitze",
ls_no_of_heads:"Anzahl",
ls_stabled_caged:"Im Stall / Käfig",
ls_grazing:"Weidetage pro Jahr",
ls_stabled:"Im Stall",
ls_moved:"Umgezogen",
ls_manure:"Gülle / Mist",
ls_msg1:"Bitte wähle eine Zeile aus. Wenn noch keine Zeilen vorhanden sind, dann füge eine neue Zeile hinzu, indem du auf 'Weitere Tierbestände hinzufügen' klickst.",
ls_msg2:"Bitte wähle eine einzelne Zeile aus.",
ls_msg3:"Bitte wähle eine Zeile aus. Wenn noch keine Zeilen vorhanden sind, dann füge eine neue Zeile hinzu, indem du auf 'Zukünftige Nutztierhaltung hinzufügen' klickst.",
lu_instrucns:"Anleitung zur Erstellung einer Parzelle",
lu_how_parcel:"Wie erstelle ich eine Parzelle?",
lu_twoways:"Es gibt zwei Möglichkeiten, eine Parzelle anzulegen. Sie sind wie folgt:",
lu_drawing:"Einzeichnen einer Parzelle auf der Karte",
lu_make_sure:"Stelle sicher, dass du die KML-Datei für deinen Betrieb hast.",
lu_click:"Klicke auf die Schaltfläche 'Importiere eine KML -Datei'",
lu_select:"Wähle die KML -Datei aus, die du importieren möchtest",
lu_drawing_parcel:"Einzeichnen einer Parzelle auf der Karte",
lu_select_drawer:"Wähle das Zeichnensymbol in der oberen linken Ecke der Karte aus",
lu_identify:"Identifiziere den Standort auf deinem Betriebund zeichne Parzelle dort ein",
lu_every:"Jede Parzelle wird durch ihre eigene Farbe identifiziert.",
lu_when:"Wenn du auf eine Parzelle klickst, wird ein Pop-up mit dem Namen angezeigt.",
lu_i_have:"Ich habe eine KMZ -Datei. Kann ich diese importieren?",
lu_no:"Nein, du kannst keine KMZ -Datei importieren. Eine KMZ -Datei ist jedoch eine komprimierte Version einer KML-Datei. Wenn du die KMZ -Datei in deinem Computer entpackst, findest du die KML -Datei im unkomprimierten Ordner und wir bitten dich, diese KML -Datei hochzuladen.",
lu_a_common:"Eine gängige Methode zur Erstellung der KML-Datei ist die Verwendung von Google Earth. Nachdem du Google Earth auf deinem Desktop-Computer installiert hast, kannst du dort die Felder einzeichnen und dann die KML-Datei mit allen Feldern deines Betriebs exportieren. Falls du nur die KMZ-Datei exportieren kannst, entzippe die KMZ-Datei und lade die KML-Datei hoch, die sich in dem unkomprimierten Ordner befindet.",
lu_parcel:"Parzellen-Vorlagen ",
lu_if_you:"Wenn du Praktiken oder Kulturen hast, die du auf mehreren Feldern anwendest, dann kannst du hier Vorlagen erstellen und benennen und später in die jeweiligen Parzellen importieren.",
lu_temp:"Name der Vorlage",
temp_parcel_template:"Parzellen-Vorlagen ",
temp_template_name:"Name der Vorlage",
temp_silvopasture:"Silvopastorales System",
temp_arable:"Ackerbaukulturen",
temp_grass:"Grünland",
temp_agro:"Agroforst",
temp_tree_avg_1:"Durchschnittlicher DBH des Baumer in cm (Durchmesser auf Brusthöhe)",
temp_tree_avg_2:"Durchschnittlicher DBH des Baumer in cm (Durchmesser auf Brusthöhe)",
temp_tree_avg_3:"Durchschnittlicher DBH des Baumer in cm (Durchmesser auf Brusthöhe)",
temp_manure:"Ausbringung von Gülle / Mist  (t/ha)",
temp_per_manure:"% Gülle / Mist importiert",
temp_compost:"Ausbringung von Kompost (t/ha)",
temp_hay:"Ausbringung von Heu / Stroh (t/ha)",
temp_per_hay:"% Heu / Stroh importiert",
temp_bale:"Winterfütterung mit Rundballen",
temp_residue:"Rückstände nach Fütterung mit Rundballen (%)",
temp_cash:"Cash crops / Marktfrüchte",
temp_cover:"Zwischenfrucht",
temp_desc:"Beschreibe den Mix (mindestens 1 Grassart und 1 Hülsenfrucht)",
temp_multispecies:"Diverse Zwischenfrucht",
temp_at_least:"mindestens 3 verschiedene Pflanzenfamilien",
temp_grazing_multi:"Beweidung mit mehreren Tierarten",
temp_adaptive:"Rotationsweide / Koppelbeweidung",
temp_yields:"Sind Ernteerträge und Rückstände (t/ha) in trockener (bevorzugt) oder frischer Biomasse angegeben?",
temp_mar:"Mär",
temp_may:"Mai",
temp_aug:"Aug",
temp_oct:"Okt",
temp_nov:"Nov",
temp_cash_1:"Cash Crop 1",
temp_no_cash_1:"Kein Cash Crop",
temp_aromatics:"Aromatische Pflanzen",
temp_beets:"Rote Beete",
temp_clover:"Klee",
temp_cowpea:"Kuhbohne",
temp_radish:"Futterrettich",
temp_turnip:"Futterrübe",
temp_lupins:"Lupinen",
temp_other_grains:"Anderes Getreide",
temp_shrubb:"Stauden und Sträucher",
temp_sillage:"Silomais",
temp_sorghum_sudan:"Sudangrass",
temp_sorghum:"Sorghumhirse",
temp_sunn_hemp:"Hanf",
temp_cash_2:"Cash Crop 2",
temp_no_cash_2:"Kein Cash Crop",
temp_cover_crops:"Zwischenfrucht",
temp_fallow:"Grünbrache",
temp_grazing:"Beweidung",
temp_whats_left:"Rückstände nach Beweidung / Ernte (t/ha)",
temp_bare_soil:"Brache (unbedeckt)",
temp_tilling_event:"Bodenbearbeitung / Pflügen",
temp_min_till:"Minimalbodenbearbeitung (<10 cm Tiefe)",
parcel_maps:"Parzellen",
parcel_here:"Hier bitten wir dich um weitere Details zu deinen Parzellen:",
parcel_on:'Klicke in der folgenden Liste auf "Bearbeiten" um weitere Informationen für die jeweiligen Parzellen anzugeben. Hier bitten wir dich, sowohl um aktuelle Daten (Praktiken, Anbaukulturen, Beweidungsmonate,..) sowie Schätzungen für die nächsten 10 Jahre.',
parcel_you:"In der Leiste oben kannst du zwischen den verschiedenen Jahren navigieren.",
parcel_del:"Alle Parzellen löschen",
parcel_parcel_name:"Name der Parzelle",
parcel_past:"Vorherige und aktuelle Praktiken",
parcel_parcelname:"Name der Parzelle",
parcel_area:"Fläche (m2)",
parcel_choose:"Ich möchte die Fläche manuell eingeben",
parcel_manual:"Manuell eingebene Fläche (m2)",
parcel_are_sure:"Bist du sicher, dass du die Paketdaten kopieren möchtest?",
parcel_you_are_about:"Du bist dabei, die Daten der ausgewählten Parzelle in diese Parzelle zu kopieren. Möchtest du fortfahren?",
parcel_disagree:"Abbrechen",
parcel_are_you_import:"Bist du sicher, dass du die Daten aus der Vorlage importieren möchtest?",
parcel_about_import:"Du bist kurz davor, die Daten aus der Vorlage in diese Parzelle zu importieren. Möchtest du fortfahren?",
parcel_disagree_2:"Abbrechen",
parcel_this_refers:"Dies bezieht sich auf den landwirtschaftlichen Kontext dieser Parzelle ",
parcel_silvopasture:"Silvopastorales System",
parcel_arable:"Ackerbaukulturen",
parcel_grass:"Grünland",
parcel_agro:"Agroforst",
parcel_tree_avg_dbh:"Durchschnittlicher DBH des Baumer in cm (Durchmesser auf Brusthöhe)",
parcel_tree_avg_1:"Durchschnittlicher DBH des Baumer in cm (Durchmesser auf Brusthöhe)",
parcel_tree_avg_2:"Durchschnittlicher DBH des Baumer in cm (Durchmesser auf Brusthöhe)",
parcel_tree_avg_3:"Durchschnittlicher DBH des Baumer in cm (Durchmesser auf Brusthöhe)",
parcel_manure:"Ausbringung von Gülle / Mist  (t/ha)",
parcel_per_manure:"% Gülle / Mist importiert",
parcel_compost:"Ausbringung von Kompost (t/ha)",
parcel_hay:"Ausbringung von Heu / Stroh (t/ha)",
parcel_per_hay:"% Heu / Stroh importiert",
parcel_bale:"Winterfütterung mit Rundballen",
parcel_residue:"Rückstände nach Fütterung mit Rundballen (%)",
parcel_cash:"Cash crops / Marktfrüchte",
parcel_cover:"Zwischenfrucht",
parcel_desc:"Beschreibe den Mix (mindestens 1 Grassart und 1 Hülsenfrucht)",
parcel_multispecies:"Diverse Zwischenfrucht",
parcel_at_least:"mindestens 3 verschiedene Pflanzenfamilien",
parcel_grazing_multi:"Beweidung mit mehreren Tierarten",
parcel_adaptive:"Rotationsweide / Koppelbeweidung",
parcel_yields:"Sind Ernteerträge und Rückstände (t/ha) in trockener (bevorzugt) oder frischer Biomasse angegeben?",
parcel_mar:"Mär",
parcel_may:"Mai",
parcel_aug:"Aug",
parcel_oct:"Okt",
parcel_nov:"Nov",
parcel_cash_1:"Cash Crop 1",
parcel_no_cash_1:"Kein Cash Crop",
parcel_aromatics:"Aromatische Pflanzen",
parcel_beets:"Rote Beete",
parcel_clover:"Klee",
parcel_cowpea:"Kuhbohne",
parcel_radish:"Futterrettich",
parcel_turnip:"Futterrübe",
parcel_lupins:"Lupinen",
parcel_other_grains:"Anderes Getreide",
parcel_shrubb:"Stauden und Sträucher",
parcel_sillage:"Silomais",
parcel_sorghum_sudan:"Sudangrass",
parcel_sorghum:"Sorghumhirse",
parcel_sunn_hemp:"Hanf",
parcel_cash_2:"Cash Crop 2",
parcel_no_cash_2:"Kein Cash Crop",
parcel_cover_crops:"Zwischenfrucht",
parcel_fallow:"Grünbrache",
parcel_grazing:"Beweidung",
parcel_whats_left:"Rückstände nach Beweidung / Ernte (t/ha)",
parcel_bare_soil:"Brache (unbedeckt)",
parcel_tilling_event:"Bodenbearbeitung / Pflügen",
parcel_min_till:"Minimalbodenbearbeitung (<10 cm Tiefe)",
parcel_pls_save:"Bitte klicke auf 'Speichern', um deine Daten zu speichern, bevor du das Fenster schließt.",
sa_past:"Frühere Bodenproben",
sa_if_you:"Falls du in der Vergangenheit Bodenproben durchgeführt hast, fülle bitte die folgenden Informationen aus (optional).",
sa_clay_per:"Tongehalt (%)",
sa_if_clay:"Wenn Ton- und Schlickgehalt nicht verfügbar ist:",
sa_san_cl_loam:"Sandiger Tonlehm",
sa_silt_cl_loam:"Schluff-Ton-Lehm",
sa_organic:"Hummusgehalt (OM)",
generic_saving_data:"Daten werden gespeichert. Bitte warte",
dashboard_manual_pdf:"Benutzerhandbuch",
nc_text_1_not_submitted:"Schliesse deine Anmeldung ab",
nc_text_1_under_review:"Dein Plan ist eingereicht 🥳",
nc_text_1_data_accepted:"Deine Daten wurden akzeptiert",
nc_text_2_not_submitted:"Erzähl uns mehr über deinen Betrieb",
nc_text_2_under_review:"Daten werden geprüft",
nc_text_bottom_under_review:"Deine C+ -Anmeldung wurde eingereicht und wird von unserem wissenschaftlichen Team geprüft.",
nc_farminfo_step_regular:"Bitte teile deine",
nc_farminfo_step_bold:"Allgemeine Information",
nc_fuel_step_regular:"Gib an",
nc_fuel_step_bold:"Kraftstoffverbrauch",
nc_livestock_step_regular:"Plane deine",
nc_livestock_step_bold:"Viehbestand",
nc_landuse_step_regular:"Plane deine",
nc_landuse_step_bold:"Parzellen und Anbauplanung",
mar:"März",
may:"Mai",
generic_view:"Ansicht",
lu_title:"Datenübersicht der Parzelle",

parcel_delete:"Du bist dabei, diese Parzelle zu löschen. Möchtest du fortfahren?",
parcel_copy_sure:"Bist du sicher, dass du die aktuelles Praktiken kopieren möchtest?",
parcel_copy_continue:"Du bist dabei, die aktuellen Praktiken auf die nächsten 10 Jahre zu kopieren. Möchtest du fortfahren?",
parcel_delete_sure:"Bist du sicher, dass du alle Parzellen löschen möchtest?",
parcel_delete_continue:"Du bist dabei, alle Parzellen zu löschen. Möchtest du fortfahren?",
farmland_draft_submission_msg_success:"Deine Betriebsdaten wurden erfolgreich als Entwurf gespeichert. Du kannst das Formular erneut besuchen, um Änderungen vor der endgültigen Einreichung vorzunehmen",
farmland_draft_submission_msg_failure:"Es gab einen Fehler während der Speicherung deiner Daten. Bitte überprüfe die Werte, die du eingegeben hast oder kontaktiere unser Team unter support@climatefarmers.org",
farmland_updated_by_farmer_liaison_success:"Deine Betriebsdaten wurden erfolgreich aktualisiert.",
email_subject:"Updates von Climate Farmers",
email_subject_farm_draft_submission:"Climate Farmers | Data als Entwurf übermittelt",
email_subject_farm_final_submission:"Climarte Farmers |  Finale Datenübermittlung",
email_body_farmland_draft_submission:"Hallo, Du hast deine Betriebsdetails als Entwurf gespeichert. Du kannst diese jederzeit bearbeiten. Bei Fragen kannst du gerne auf diese E -Mail antworten.",
email_body_farmland_final_submission:"Hallo, vielen Dank, dass du deine Betriebsdaten bei Climate Farmers eingereicht hast. Wir werden die Informationen überprüfen und uns schnellstmöglich bei dir melden. Bei Fragen antworte bitte auf diese E -Mail.",
infobox_parcel_temp_1:"Wenn einige deiner Parzellen sich wiederholende Eigenschaften wie bestimmte Anbaululture oder Praktiken aufweisen, ist es hilfreich, Vorlagen zu erstellen.",
infobox_parcel_temp_2:"Auf diese Weise kannst du diese Vorlagen in anderen Parzellen replizieren. Diese Option ist insbesondere für Fruchtfolgen nützlich, wenn du für jedes Jahr vollständig unterschiedliche Daten eingeben musst.",
infobox_parcel_temp_3:'Dazu kannst zuerst eine Vorlage erstellen, indem du auf die blaue Schaltfläche klickst (direkt unter der Karte). Du kannst dann so viele verschiedene Vorlagen erstellen und benennen, wie du benötigst. Wir empfehlen, Namen zu wählen, die leicht zu erkenne sind, z. "Agroforst - Walnuss" oder "Ackerbau - Weizen".',
infobox_coordinates_1:"Wenn du deine Koordinaten nicht kennst, gehe zu Google Maps und klicke die rechte Maustaste über deinem Betrieb. Klicke die linke Maustaste über den 2 Zahlen, die in der ersten Zeile angezeigt werden, um diese in die Zwischenablage zu kopieren und dann hier einzufügen.",
infobox_coordinates_4:"Nord-",
infobox_coordinates_5:"Koordinate, und die zweite ist die",
infobox_coordinates_6:"östlich",
infobox_coordinates_7:". Wenn du eine westliche oder südliche Koordinate hast, setze einfach ein negatives",
infobox_coordinates_9:"diese Koordinate. Stelle sicher, dass du jede Nummer in das richtige Feld eingibst, andernfalls wird die Lage deiner Farm falsch auf der Karte angezeigt.",
infobox_coordinates_10:"Du kannst überprüfen, ob die Koordinaten auf der Seite „Parzellen und Landnutzung“ korrekt sind.",
infobox_ls_mgmt_2:"- Im Stall / Käfig",
infobox_ls_mgmt_3:"- ungeplante Beweidung (Set Stock Grazing)",
infobox_ls_mgmt_4:"Hierunter versteht man die Weidehaltung von Vieh auf einer großen Koppel über einen längeren Zeitraum. Du kannst diese Praxis auch auswählen, wenn du bereits traditionelle oder rotationale Beweidung praktizierst, jedoch ohne einen Beweidungsplan.",
infobox_ls_mgmt_5:"- Rotationsweide / Koppel-Beweidung",
infobox_ls_mgmt_6:"Hierbei handelt es sich um die Aufteilung großer Weideflächen in kleinere Parzellen, die mit physischen oder elektrischen Zäunen abgegrenzt werden. Die Rotation des Viehbestands erfolgt nach optimierter Besatzdichte und Weidedauer pro Paddock. Die Optimierung erfolgt unter Berücksichtigung der Merkmale der Tierarten, der verfügbaren natürlichen Ressourcen (Vermeidung von Überweidung und Überruhe), des örtlichen Klimas und der Topografie, der gewünschten positiven Landschaftsfunktion, des Erholungsbedarfs der Weide bzw. der täglichen Wachstumsrate der Pflanzen und der Leistung des Viehs. Zur Planung, Aufzeichnung, Überwachung und Anpassung wird ein",
infobox_ls_mgmt_7:"Beweidungsplan",
infobox_ls_mgmt_8:"verwendet, der uns vorgelegt werden muss.",
infobox_ls_manure_1:"Bitte wählen aus, wie der Dünger in deinem Betrieb (in %) gehandhabt wird:",
infobox_ls_manure_3:"Gülle / Mist bleibt während des Beweidung auf der Weide",
infobox_ls_manure_5:"Gülle / Mist wird für die Kompostierung im Betrieb verwendet",
infobox_ls_manure_6:"- direkt ausgebracht:",
infobox_ls_manure_7:"Dünger wird gesammelt und auf Felder und Weiden als Dünger ausgebracht",
infobox_ls_manure_8:"- Sammlung und Entsorgung:",
infobox_ls_manure_9:"Gülle / Mist wird gesammelt und nicht vor Ort verwendet",
infobox_lu_area_1:"Die Fläche jeder Parzelle wird automatisch aus der von Ihnen gezeichneten Fläche berechnet. Wenn du das Gefühl hast, dass diese Zahl nicht realistisch ist, dann kannst du rechts die Oberfläche manuell ändern.",
infobox_lu_area_2:"in Quadratmetern",
infobox_lu_parcels:'Wenn zwei Parzellen gleiche oder ähnliche Eigenschaften haben, dann kannst du die Daten auch schnell von einem zum anderen kopieren, indem du die gewünschte Parzelle auswählst und auf "Daten kopieren" klickst. Auf diese Weise wird die neue Parzelle eine genaue Kopie der vorherigen und du kannst noch Änderungen daran vornehmen.',
infobox_lu_temp:'Hier kannst du eine der Vorlagen importieren, die du vorher erstellt hast, indem du diese auswählst und auf "Daten importieren" klickst. Bitte beachte, dass wenn du eine Vorlage nach dem Kopieren in eine Parzelle änderst, die Daten in dieser nicht geändert werden.',
infobox_lu_type_3:"Ackerbaukulturen",
infobox_lu_type_4:"Getreide, Bohnen, Samen, Gemüse oder Faserpflanzen. Kann beweidet oder geerntet werden.",
infobox_lu_type_5:"Grünland",
infobox_lu_type_7:"Agroforst",
infobox_lu_type_8:"Ein Landnutzungssystem, bei dem Bäume oder Sträucher um oder zwischen Ackerbau oder Grünland angebaut werden. Beinhaltet Obst- oder Nussbäume oder andere Stauden.",
infobox_lu_type_9:"Ohne Beweidung",
infobox_lu_per_manure:"Bitte gib an, wie viel (in %) des Materials, das ausgebracht wird, seinen Ursprung außerhalb der Farm hat.",
infobox_lu_bale_grz_1:"Winterfütterung mit Rundballen",
infobox_lu_bale_grz_2:"Dies ist eine Methode zur Fütterung von Rindern in den Wintermonaten. Bei diesem System haben die Tiere Zugang zu Rundballen, die zuvor auf einem Feld oder einer Überwinterungsfläche abgelegt wurden. ",
infobox_lu_bale_grz_3:"Rückstände nach Rundballenfütterung (%) ",
infobox_lu_bale_grz_4:"Bitte gib an, wie viel der Biomasse nach dem Abweiden auf dem Feld verbleibt.",
infobox_lu_cash_crop_1:"Cash Crop",
infobox_lu_cash_crop_2:'"Der Begriff ""Cash Crop"" bezieht sich auf eine landwirtschaftliche Kultur, die zur Erzielung von Gewinnen und nicht zur Nutzung durch den Landwirt angebaut wird.',
infobox_lu_cover_crop_1:"Zwischenfrucht",
infobox_lu_cover_crop_2:"Bezeichnet die Aussaat von Pflanzenarten, die in erster Linie die physikalischen, chemischen und biologischen Eigenschaften des Bodens verbessern sollen. Die Zwischenfrucht kann entweder abgeweidet, geerntet, in den Boden eingearbeitet, mit einem Roller-Crimper gewalzt oder nach dem Mähen als Rückstände im Feld belassen werden.",
infobox_lu_multi_cover_crop_1:"Diverse Zwischenfrucht-Mischungen",
infobox_lu_multi_cover_crop_2:"Bezeichnet die Aussaat einer vielfältigen Mischung von Pflanzenarten, die in erster Linie der Verbesserung der physikalischen, chemischen und biologischen Eigenschaften des Bodens dienen soll. Der Anbau von Zwischenfrüchten kann vor, während oder nach der Hauptkultur erfolgen, wobei die Auswahl der Arten den Anforderungen des jeweiligen Bodens entsprechen muss. Die Zwischenfrucht kann entweder abgeweidet, geerntet, in den Boden eingearbeitet, mit einem Roller-Crimper gewalzt  oder nach dem Mähen als Rückstände belassen werden.",
infobox_lu_multi_cover_crop_3:"Der Mix muss  ",
infobox_lu_multi_cover_crop_4:"mindestens 3 Pflanzenarten enthalten:",
infobox_lu_multi_cover_crop_5:"- Mindestens eine Grasart (Poaceae)",
infobox_lu_multi_cover_crop_6:"- Mindestens einen Hülsenfrüchtler (Fabaceae)",
infobox_lu_multi_cover_crop_7:"- Mindestens eine Art aus einer der folgenden Familien: Brassicaceae, Amaranthaceae, Linaceae, Plantaginaceae, Asteraceae, Polygonaceae, Boraginaceae, Malvaceae, Rosaceae, Cucurbitaceae",
infobox_lu_unplanned_grz_1:"Traditionelle Weidehaltung (Set Stock Grazing)",
infobox_lu_unplanned_grz_2:"Darunter versteht man die Weidehaltung von Nutztieren auf einer großen Koppel über einen längeren Zeitraum.",
infobox_lu_unplanned_grz_3:"Du kannst diese Option auch wählen, wenn die Beweidung traditionell oder im Rotationsverfahren  ist aver es keinen Beweidungsplan gibt.",
infobox_lu_multi_grz_1:"Beweidung mit mehreren Tierarten (Multi Species Grazing)",
infobox_lu_multi_grz_2:"Bezieht sich auf die Beweidung mit ",
infobox_lu_multi_grz_3:"mindestens 2 verschiedenen Tierarten:",
infobox_lu_multi_grz_4:"Weide-/Browser-Wiederkäuer, Equidae (Pferdefamilie) und Camelidae (Kamelfamilie) und Weideschweine oder -hühner",
infobox_lu_adapt_graz_1:"Rotationsweide / Koppelbeweidung (Adaptive Multi Paddock Grazing)",
infobox_lu_adapt_graz_2:"Darunter versteht man die Aufteilung großer Weideflächen in kleinere Parzellen mit Hilfe von physischen oder elektrischen Zäunen. Die Rotation des Viehbestands erfolgt nach optimierter Besatzdichte und Weidedauer pro Paddock und unter Berücksichtigung anderer Faktoren, zum Beispiel die verfügbaren natürlichen Ressourcen (zur Vermeidung von Überweidung).",
infobox_lu_adapt_graz_3:"Zur Planung, Aufzeichnung, Überwachung und Anpassung wird ein  ",
infobox_lu_adapt_graz_4:"Beweidungsplan",
infobox_lu_adapt_graz_5:"(Grazing Plan) verwendet, der uns vorgelegt werden muss.",
infobox_lu_check_cover:"Bitte markiere jeden Monat, in dem (diverse) Zwischenfrüchte angebaut werden oder präsent sind",
infobox_lu_check_prod_fallow:"Grünbrache (Productive Fallow) bezieht sich auf eine Bodenbedeckung wie Gras oder andere spontane Vegetation, die nicht absichtlich gesät wird.",
infobox_lu_check_bare_soil:"Btte markiere jeden Monat, in dem der Boden unbedeckt bleibt.",
infobox_lu_check_granzing:"Bitte markiere jeden Monate, in denen diese Parzelle beweidet wird.",
infobox_lu_harvest_yield_1:"Bitte trage hier die geschätzten Ernteerträge ein (in Tonnen pro Hektar).",
infobox_lu_harvest_yield_2:"Wenn dies eine Parzelle mit Agroforst ist, dann kannst du das Feld leer lassen.",
infobox_lu_grz_yield_1:"Der Weideertrag bezieht sich auf die Biomasse, die die Tiere in dieser Parzelle konsumiert haben.",
infobox_lu_grz_yield_2:"Dies kann grob anhand der Anzahl der Tiere und der Weidetage oder in Tonnen Trockenmasse durch Probenahme und Wiegen berechnet werden. Die meisten Wiederkäuer nehmen pro Tag 3 % ihres Lebendgewichts an Trockenmasse an Futter auf.",
infobox_lu_whats_left:"Bitte gib die Menge der Rückstände, die nach der Ernte und/oder Beweidung auf dem Feld verbleiben, in Tonnen pro Hektar an.",
infobox_lu_min_till_1:"Darunter versteht man eine geringfügige Bodenbearbeitung mit einem Pflug, Scheibenpflug, Meißelpflug oder einer Bodenfräse mit folgenden Einschränkungen: bis zu 10 cm tief, wenn die gesamte Fläche bearbeitet wird, ODER wenn mehr als 30 % der Fläche bearbeitet werden, dann muss dies in Streifen erfolgen.",
infobox_lu_min_till_2:"Die Bearbeitung mit einem Subsoiler ist zulässig, sofern die Zinken die Bodenstruktur und -schichtung nicht zerstören.",
pp_since:"Carbon+ Mitglied seit",
nc_popup_you:"Du bist dabei deine Carbon+ - Anmeldung einzureichen.",
nc_popup_after:"Nachdem du deine Anmeldung eingereicht hast, überprüft unser Team deine Daten.",
nc_popup_not_1:"Du kannst du deine Anmeldung nach der Einreichung ",
nc_popup_not_2:"nicht",
nc_popup_not_3:"mehr bearbeiten.",
nc_popup_yes:"Ja, bitte einreichen.",
nc_popup_no:"Nein, weiterbearbeiten",
sb_signout:"Abmelden",
sb_home: "Startseite",

sb_profile:"Profil",
pp_personal_info: "Persönliche Angaben",
pp_name: "Offizieller Name",
pp_email: "Email",
pp_phone: "Telefonnummer",
pp_settings: "Einstellungen",
pp_language: "Sprache",
generic_cancel: "Stornieren",
pp_password: "Passwort",
nc_popup_sure: "Bist du dir sicher?",


};