import HttpService from '../services/HttpService';


const findByFarmerEmail = (email) => {
	return HttpService.getAxiosClient().get(`/api/verificationdocuments/${email}`);
};

const update = (id, data) => {
	return HttpService.getAxiosClient().patch(`/api/verificationdocuments/${id}`, data);
};

const create = (data) => {
	return HttpService.getAxiosClient().post("/api/verificationdocuments/", data);
}

// get from S3 bucket
const get = (data) => {
	return HttpService.getAxiosClient().post("/api/verificationdocuments/downloadfile", data);
};
// get from S3 bucket
const getObject = (data) => {
	return HttpService.getAxiosClient().post("/api/verificationdocuments/getFile", data);
};

const upload = (data) => {
	return HttpService.getAxiosClient().post("/api/verificationdocuments/upload", data);
};

// delete from S3 bucket and mongoDB
const deleteFile = (id, data) => {
	return HttpService.getAxiosClient().patch(`/api/verificationdocuments/deletefile/${id}`, data);
};

const convertKmzToKMLFile = (data) => {
	return HttpService.getAxiosClient().post("/api/kmztokml", data);
}

// not in use
const remove = id => {
	return HttpService.getAxiosClient().delete(`/api/verificationdocuments/deletefile`);
};

const VerificationDocumentsUploadService = {
	get,
	upload,
	remove,
	findByFarmerEmail,
	update,
	create,
	deleteFile,
	convertKmzToKMLFile,
	getObject
};

export default VerificationDocumentsUploadService;