import {
  CREATE_FARMLAND,
  RETRIEVE_FARMLANDS,
  UPDATE_FARMLAND,
  DELETE_FARMLAND,
  RESET_FARMLANDS,
  FIND_FARMLAND_BY_ID,
} from "../actions/types";

const initialState = [];

function farmlandReducer(farmlands = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case CREATE_FARMLAND:
      return [...farmlands, payload];
    case RETRIEVE_FARMLANDS:
      return payload;
    case UPDATE_FARMLAND:
      return farmlands.map((farmland) =>
        farmland._id === String(payload._id) ? payload : farmland
      );
    case DELETE_FARMLAND:
      return farmlands.filter(
        (farmland) => farmland._id !== String(payload._id)
      );
    case FIND_FARMLAND_BY_ID:
      return farmlands.find((farmland) => farmland._id !== String(payload.id));
    case RESET_FARMLANDS:
      return initialState;
    default:
      return farmlands;
  }
}

export default farmlandReducer;
