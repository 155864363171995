import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormLabel,
    Grid,
    Slide
} from "@mui/material";
import React from "react";
import "../../css/Dashboard.css";

import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import "../../css/FarmerDashboard.css";
const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const WelcomeScreen = ({ welcomeScreenOpen, handleWelcomeScreenClose }) => {
    const { t } = useTranslation();

    return (
        <Dialog
            open={welcomeScreenOpen}
            fullWidth
            maxWidth="lg"
            TransitionComponent={Transition}
            onClose={handleWelcomeScreenClose}
        >
            <DialogTitle>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormControl fullWidth>
                        <FormLabel>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Alert icon={false} severity="warning">
                                        <div style={{ fontWeight: "bold" }}>
                                            <h2>
                                                {t("home_welcome_to_cf")}
                                            </h2>
                                        </div>
                                    </Alert>
                                </Grid>
                            </Grid>
                        </FormLabel>
                    </FormControl>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <div>
                    <div style={{ padding: '2px' }}>
                        Welcome to Climate Farmers.
                    </div>
                    <div style={{ padding: '2px' }}>
                        Thank you for your interest in helping to regenerate soil, improve the ecosystem health and enhance your farm's biodiversity through the financing of the Climate Farmers' Carbon+ Credits Program.
                        Before starting to fill in your data, please read carefully the document <a target="_blank" href="https://dev-server-downloads.s3.eu-central-1.amazonaws.com/welcome_screen_cf.pdf" rel="noreferrer">here attached</a>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    color="error"
                    style={{ minWidth: "120px", maxWidth: "120px" }}
                    variant="contained"
                    onClick={handleWelcomeScreenClose}
                >
                    {t("generic_close")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default WelcomeScreen