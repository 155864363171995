import { updateAndResetFallowData, updateAnnualCropData, updateFallowData, updateHarvestDataOfAnnualCropData, updateHarvestDataOfFallowData, updateHarvestDataOfPasturesData, updateHarvestDataOfPerennialTreeAndCropData, updatePasturesAndResetGrassesProductivityAndHarvestedData, updatePasturesData, updatePerennialTreeAndCropData, updateProductivityDataOfPasturesData, updateResidueDataOfAnnualCropData, updateResidueDataOfFallowData, updateResidueDataOfPerennialTreeAndCropData, updateSpeciesAndResetAnnualCropData, updateSpeciesAndResetPerennialTreeAndCropData } from "../../actions/currentMonitoringData";
import { getLastTwelveMonths } from "./utils";

export const handlePerennialsRowChange = (e, i, year, dispatch, parcelid, yearlyFarmDataOb) => {
    const { name, value } = e.target;
    const previousSpeciesName = yearlyFarmDataOb.parcelLevelData.find((parcel) => parcel.parcelFixedValues.parcelID === parcelid).yearParcelData.perennialCrops[i].species
    const previousOther = yearlyFarmDataOb.parcelLevelData.find((parcel) => parcel.parcelFixedValues.parcelID === parcelid).yearParcelData.perennialCrops[i].other
    const previousPlantingYear = yearlyFarmDataOb.parcelLevelData.find((parcel) => parcel.parcelFixedValues.parcelID === parcelid).yearParcelData.perennialCrops[i].plantingYear
    const previousRotationYears = yearlyFarmDataOb.parcelLevelData.find((parcel) => parcel.parcelFixedValues.parcelID === parcelid).yearParcelData.perennialCrops[i].rotationYears

    let tmpData = {
        year: year,
        parcelId: parcelid,
        index: i,
        attrName: name,
        data: value,
        previousSpeciesValue: previousSpeciesName,
        previousOtherValue: previousOther,
        previousPlantingYearValue: previousPlantingYear,
        previousRotationYearsValue: previousRotationYears
    };
    if (name === "species") {
        dispatch(updateSpeciesAndResetPerennialTreeAndCropData(tmpData))
            .then(res => {
                console.log('Species updated and perennial tree and crop data reset successfully:', res);
            })
            .catch(error => {
                console.error('Error updating species and resetting perennial tree and crop data:', error);
            });
    } else {
        dispatch(updatePerennialTreeAndCropData(tmpData))
            .then(res => {
                console.log('Perennial tree and crop data updated successfully:', res);
            })
            .catch(error => {
                console.error('Error updating perennial tree and crop data:', error);
            });
    }

};

export const handleAnnualCropsRowChange = (e, i, year, dispatch, parcelid) => {

    const { name, value } = e.target;

    let tmpData = {
        year: year,
        parcelId: parcelid,
        index: i,
        attrName: name,
        data: value
    };

    if (name === "species") {
        dispatch(updateSpeciesAndResetAnnualCropData(tmpData))
    }
    dispatch(updateAnnualCropData(tmpData))

    if (name === "harvestDate") {
        var arrHarvestDate = String(value).split("-")
        var tmpHarvestDate = new Date(Number(arrHarvestDate[0]), Number(arrHarvestDate[1]), Number(arrHarvestDate[2]))
        var dates = [];

        dates = getLastTwelveMonths(String(value));
    }
}

export const handlePasturesRowChange = (e, i, year, dispatch, parcelid) => {
    const { name, value } = e.target;
    let tmpData = {
        year: year,
        parcelId: parcelid,
        index: i,
        attrName: name,
        data: value
    };
    if (name === "percentArea" && !value) {
        dispatch(updatePasturesAndResetGrassesProductivityAndHarvestedData(tmpData))

    }
    dispatch(updatePasturesData(tmpData))
}

export const handleFallowRowChange = (e, i, year, dispatch, parcelid) => {
    const { name, value } = e.target;

    let tmpData = {
        year: year,
        parcelId: parcelid,
        index: i,
        attrName: name,
        data: value
    };

    dispatch(updateFallowData(tmpData)).then(res => {
        if (name === "type") {
            dispatch(updateAndResetFallowData(tmpData))
        }
    });
}



export const handlePerennialsHarvestRowChange = (e, i, j, year, dispatch, parcelid) => {
    const { name, value } = e.target;
    let tmpData = {
        year: year,
        parcelId: parcelid,
        index: i,
        harvestIndex: j,
        attrName: name,
        data: value
    };
    dispatch(updateHarvestDataOfPerennialTreeAndCropData(tmpData))
};

export const handleAnnualCropsHarvestRowChange = (e, i, j, year, dispatch, parcelid) => {
    const { name, value } = e.target;
    let tmpData = {
        year: year,
        parcelId: parcelid,
        index: i,
        harvestIndex: j,
        attrName: name,
        data: value
    };
    dispatch(updateHarvestDataOfAnnualCropData(tmpData))
};

export const handleFallowHarvestRowChange = (e, i, j, year, dispatch, parcelid) => {
    const { name, value } = e.target;
    let tmpData = {
        year: year,
        parcelId: parcelid,
        index: i,
        harvestIndex: j,
        attrName: name,
        data: value
    };
    dispatch(updateHarvestDataOfFallowData(tmpData))
}

export const handlePasturesHarvestRowChange = (e, i, j, year, dispatch, parcelid) => {
    const { name, value } = e.target;
    let tmpData = {
        year: year,
        parcelId: parcelid,
        index: i,
        harvestIndex: j,
        attrName: name,
        data: value
    };
    dispatch(updateHarvestDataOfPasturesData(tmpData))
};

export const handlePerennialsResidueRowChange = (e, i, j, year, dispatch, parcelid) => {
    const { name, value } = e.target;
    let tmpData = {
        year: year,
        parcelId: parcelid,
        index: i,
        residueIndex: j,
        attrName: name,
        data: value
    };
    dispatch(updateResidueDataOfPerennialTreeAndCropData(tmpData))
}

export const handleAnnualCropsResidueRowChange = (e, i, j, year, dispatch, parcelid) => {
    const { name, value } = e.target;
    let tmpData = {
        year: year,
        parcelId: parcelid,
        index: i,
        residueIndex: j,
        attrName: name,
        data: value
    };
    dispatch(updateResidueDataOfAnnualCropData(tmpData))
}

export const handleFallowResidueRowChange = (e, i, j, year, dispatch, parcelid) => {
    const { name, value } = e.target;
    let tmpData = {
        year: year,
        parcelId: parcelid,
        index: i,
        residueIndex: j,
        attrName: name,
        data: value
    };

    dispatch(updateResidueDataOfFallowData(tmpData))
}

export const handlePasturesProductivityRowChange = (e, i, j, year, dispatch, parcelid) => {
    const { name, value } = e.target;
    let tmpData = {
        year: year,
        parcelId: parcelid,
        index: i,
        productivityIndex: j,
        attrName: name,
        data: value
    };
    dispatch(updateProductivityDataOfPasturesData(tmpData))
}