import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  CheckCircle,
  CloudUpload as CloudUploadIcon,
  Info as InfoIcon,
} from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Divider,
  Grid,
  Snackbar,
  Stack,
  Tooltip,
  Typography
} from "@mui/material";

import "../css/FormFields.css";
import { SubmitButton, UploadedDocument } from "../styles";

import BackHomeComponent from "./general/BackHomeComponent";
import { handleDeleteFile, handleDownload, handleFileChange } from "./general/utils/fileOperations";

const VerificationDocumentsComponent = () => {



  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currentFarmDataObject = useSelector((state) => state.currentFarmDataReducer);
  const currentFarmStatusReducer = useSelector((state) => state.farmStatusReducer);
  const currentFarmVerificationDocuments = useSelector((state) => state.farmVerificationDocumentsReducer);
  const accountSettings = useSelector((state) => state.accountSettingsReducer);
  const currentMonitoringYearsObject = useSelector(state => state.monitoringYearsTrackerReducer);

  const isAdmin = accountSettings?.[0]?.role === 'admin'
  const allowEditing = currentFarmStatusReducer?.allowEditing || isAdmin

  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;
  const [saveCurrentDataFlag, setSaveCurrentDataFlag] = useState(false);
  const allMonitoringYears = currentMonitoringYearsObject.allMonitoringYears

  const handleSnackbarClose = () => {
    setState({ vertical: "top", horizontal: "center", open: false });
  };

  const handleBackdropClickCaptureEvent = (e) => {
    e.stopPropagation();
    return false;
  };

  const onFileChange = (e, subfolder, year) => {
    handleFileChange(
      e,
      subfolder,
      year,
      currentFarmDataObject,
      currentFarmVerificationDocuments,
      dispatch,
      setSaveCurrentDataFlag,
      setSnackbarMessage,
      setState,
      t
    );
  };

  const onDeleteFile = (index, subfolder, fileName, year) => {
    handleDeleteFile(
      index,
      subfolder,
      fileName,
      year,
      currentFarmDataObject,
      currentFarmVerificationDocuments,
      dispatch,
      setSnackbarMessage,
      setSaveCurrentDataFlag,
      setState,
      t
    );
  };

  return (
    <>
      <Backdrop
        onClickCapture={(e) => handleBackdropClickCaptureEvent(e)}
        style={{ color: "#fff", zIndex: 99999 }}
        open={saveCurrentDataFlag}
      // onClick={handleBackdropClose}
      >
        <div style={{ fontSize: 20, fontWeight: 600, color: "white", marginTop: "20px" }}>
          {t("generic_loading")}
        </div>
      </Backdrop>

      {!isAdmin && (
        <Box marginLeft={6}>
          <BackHomeComponent></BackHomeComponent>
        </Box>
      )}

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        key={vertical + horizontal}
      />

      <Box margin={12} marginTop={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container spacing={2} marginBottom={6} marginTop={2}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ marginBottom: "10px" }}
              >
                <Typography
                  variant='h1'
                >
                  {t("ver_doc_title")}
                </Typography>
                <Typography variant="subtitle1">
                  {t("ver_doc_info_1")}
                  <b>{t("ver_doc_info_2")}</b>
                  {t("ver_doc_info_3")}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              style={{ marginTop: "4px", marginBottom: "10px" }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ marginBottom: "10px" }}
              >
                <Typography variant="h2">
                  {t("ver_doc_your_farm")}
                </Typography>
              </Grid>
            </Grid>

            {/* Land ownership */}
            <Grid
              container
              spacing={2}
              style={{ marginTop: "4px", marginBottom: "10px" }}
            >
              <Grid
                item
                xs={3}
                sm={3}
                md={3}
                lg={3}
                style={{ marginBottom: "10px" }}
                display={"flex"}
              >
                <Typography variant="h3">
                  {t("ver_doc_land_ownership")}
                </Typography>
                <Tooltip
                  title={
                    <>
                      {t("ver_doc_land_ownership_info_box_1")}
                      <br />
                      <br />
                      {t("ver_doc_land_ownership_info_box_2")}
                      <br />
                      <br />
                      {t("ver_doc_land_ownership_info_box_3")}
                      <br />
                      {t("ver_doc_land_ownership_info_box_4")}
                      <br />
                      {t("ver_doc_land_ownership_info_box_5")}
                      <br />
                      <br />
                      {t("ver_doc_land_ownership_info_box_6")}
                      <br />
                    </>
                  }
                  placement="top-start"
                >
                  <InfoIcon
                    fontSize="small"
                    sx={{
                      padding: "0px 0px 0px 8px",
                      color: "#000000",
                    }}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                {currentFarmVerificationDocuments?.landOwnership?.length > 0 &&
                  currentFarmVerificationDocuments.landOwnership.map(
                    (ele, index) => {
                      return (
                        <>
                          <Grid container spacing={2}>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              display={"flex"}
                              marginBottom={1}
                            >
                              <Grid>
                                <UploadedDocument
                                  size="small"
                                  label={
                                    <Fragment>
                                      <CheckCircle sx={{ marginRight: 1 }} />
                                      {currentFarmVerificationDocuments.landOwnership[index].fileName}
                                    </Fragment>
                                  }
                                  disabled
                                ></UploadedDocument>
                              </Grid>
                              <Grid display={"flex"} alignItems={"center"}>
                                <Typography
                                  variant="h4"
                                  sx={{
                                    color: "#3e9781",
                                    cursor: "pointer",
                                    marginX: "10px",
                                  }}
                                  fontWeight={600}
                                  component="span"
                                  onClick={() =>
                                    handleDownload(
                                      currentFarmVerificationDocuments
                                        .landOwnership[index].s3URL
                                    )
                                  }
                                >
                                  {t("ver_doc_download")}
                                </Typography>
                                |
                                {allowEditing && <Typography
                                  variant="h4"
                                  sx={{
                                    color: "#3e9781",
                                    cursor: "pointer",
                                    marginLeft: "10px",
                                  }}
                                  fontWeight={600}
                                  component="span"
                                  onClick={() =>
                                    onDeleteFile(
                                      index,
                                      currentFarmVerificationDocuments
                                        .landOwnership[index].folder,
                                      currentFarmVerificationDocuments
                                        .landOwnership[index].fileName
                                    )
                                  }

                                >
                                  {t("ver_doc_delete")}
                                </Typography>}
                              </Grid>
                            </Grid>
                          </Grid>
                        </>
                      );
                    }
                  )}
                <Grid item>
                  <SubmitButton
                    sx={{ fontSize: 13, width: "250px" }}
                    component="label"
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                    onChange={(e) => onFileChange(e, "landOwnership")}
                    disabled={!allowEditing}
                  >
                    {t("ver_doc_upload")}
                    <input
                      type="file"
                      accept="image/png,
                  impage/jpeg,
                  application/pdf, 
                  application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      hidden
                    />
                  </SubmitButton>
                </Grid>
              </Grid>
            </Grid>

            <Divider sx={{ marginY: 6 }}></Divider>

            {/* CAP */}
            <Grid
              container
              spacing={2}
              style={{ marginTop: "4px", marginBottom: "10px" }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ marginBottom: "10px" }}
              >
                <Typography variant="h2">
                  {t("ver_doc_cap")}
                </Typography>
                <Grid item display={"flex"} marginBottom={4}>
                  <Typography variant="subtitle2">
                    {t("ver_doc_cap_info")}
                  </Typography>
                  <Tooltip
                    title={
                      <>
                        {t("ver_doc_cap_info_box_1")}
                        <br />
                        <br />
                        {t("ver_doc_cap_info_box_2")}
                      </>
                    }
                    placement="top-start"
                  >
                    <InfoIcon
                      fontSize="small"
                      sx={{
                        padding: "0px 0px 0px 8px",
                        color: "#000000",
                      }}
                    />
                  </Tooltip>
                </Grid>

                {/* CAP 2018 */}
                {allMonitoringYears.includes(2018) && (
                  <Grid container marginTop={2}>
                    <Grid
                      item
                      xs={1}
                      sm={1}
                      md={1}
                      lg={1}
                      style={{
                        marginBottom: "10px",
                        marginTop: "10px",
                        display: "flex",
                      }}
                    >
                      <Typography
                        fontWeight={400}
                        fontSize={18}
                        color="#707070"
                      >
                        2018
                      </Typography>
                    </Grid>
                    <Stack>
                      {currentFarmVerificationDocuments
                        ?.yearlyVerificationDocuments?.[0]?.capDocument
                        ?.length > 0 &&
                        currentFarmVerificationDocuments.yearlyVerificationDocuments[0].capDocument.map(
                          (ele, index) => {
                            return (
                              <>
                                <Grid container spacing={2}>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    display={"flex"}
                                    marginBottom={1}
                                  >
                                    <Grid>
                                      <UploadedDocument
                                        size="small"
                                        label={
                                          <Fragment>
                                            <CheckCircle sx={{ marginRight: 1 }} />
                                            {currentFarmVerificationDocuments
                                              ?.yearlyVerificationDocuments?.[0]
                                              ?.capDocument[index].fileName}
                                          </Fragment>
                                        }
                                        disabled
                                      ></UploadedDocument>
                                    </Grid>

                                    <Grid
                                      display={"flex"}
                                      alignItems={"center"}
                                    >
                                      <Typography
                                        variant="h4"
                                        sx={{
                                          color: "#3e9781",
                                          cursor: "pointer",
                                          marginX: "10px",
                                        }}
                                        fontWeight={600}
                                        component="span"
                                        onClick={() =>
                                          handleDownload(
                                            currentFarmVerificationDocuments
                                              .yearlyVerificationDocuments[0]
                                              .capDocument[index].s3URL
                                          )
                                        }
                                      >
                                        {t("ver_doc_download")}
                                      </Typography>
                                      |
                                      {allowEditing && <Typography
                                        variant="h4"
                                        sx={{
                                          color: "#3e9781",
                                          cursor: "pointer",
                                          marginLeft: "10px",
                                        }}
                                        fontWeight={600}
                                        component="span"
                                        onClick={() =>
                                          onDeleteFile(
                                            index,
                                            currentFarmVerificationDocuments
                                              .yearlyVerificationDocuments[0]
                                              .capDocument[index].folder,
                                            currentFarmVerificationDocuments
                                              .yearlyVerificationDocuments[0]
                                              .capDocument[index].fileName,
                                            2018
                                          )
                                        }
                                      >
                                        {t("ver_doc_delete")}
                                      </Typography>}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </>
                            );
                          }
                        )}
                      <Grid
                        item
                        style={{
                          marginBottom: "10px",
                          marginTop: "10px",
                          display: "flex",
                        }}
                      >
                        <SubmitButton
                          sx={{ fontSize: 13, width: "250px" }}
                          component="label"
                          variant="contained"
                          startIcon={<CloudUploadIcon />}
                          onChange={(e) =>
                            onFileChange(e, "capDocument", 2018)
                          }
                          disabled={!allowEditing}
                        >
                          {t("ver_doc_upload")}
                          <input
                            type="file"
                            accept="application/msword,
                          application/pdf, 
                          application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                          image/png, 
                          image/jpeg"
                            hidden
                          />
                        </SubmitButton>
                      </Grid>
                    </Stack>
                  </Grid>
                )}

                {/* CAP 2019 */}
                {allMonitoringYears.includes(2019) && (
                  <Grid container marginTop={2}>
                    <Grid
                      item
                      xs={1}
                      sm={1}
                      md={1}
                      lg={1}
                      style={{
                        marginBottom: "10px",
                        marginTop: "10px",
                        display: "flex",
                      }}
                    >
                      <Typography
                        fontWeight={400}
                        fontSize={18}
                        color="#707070"
                      >
                        2019
                      </Typography>
                    </Grid>
                    <Stack>
                      {currentFarmVerificationDocuments
                        ?.yearlyVerificationDocuments?.[1]?.capDocument
                        ?.length > 0 &&
                        currentFarmVerificationDocuments.yearlyVerificationDocuments[1].capDocument.map(
                          (ele, index) => {
                            return (
                              <React.Fragment key={ele}>
                                <Grid container spacing={2}>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    display={"flex"}
                                    marginBottom={1}
                                  >
                                    <Grid>
                                      <UploadedDocument
                                        size="small"
                                        label={
                                          <Fragment>
                                            <CheckCircle sx={{ marginRight: 1 }} />
                                            {currentFarmVerificationDocuments
                                              ?.yearlyVerificationDocuments?.[1]
                                              ?.capDocument[index].fileName}
                                          </Fragment>
                                        }
                                        disabled
                                      ></UploadedDocument>
                                    </Grid>

                                    <Grid
                                      display={"flex"}
                                      alignItems={"center"}
                                    >
                                      <Typography
                                        variant="h4"
                                        sx={{
                                          color: "#3e9781",
                                          cursor: "pointer",
                                          marginX: "10px",
                                        }}
                                        fontWeight={600}
                                        component="span"
                                        onClick={() =>
                                          handleDownload(
                                            currentFarmVerificationDocuments
                                              .yearlyVerificationDocuments[1]
                                              .capDocument[index].s3URL
                                          )
                                        }
                                      >
                                        {t("ver_doc_download")}
                                      </Typography>
                                      |
                                      {allowEditing && <Typography
                                        variant="h4"
                                        sx={{
                                          color: "#3e9781",
                                          cursor: "pointer",
                                          marginLeft: "10px",
                                        }}
                                        fontWeight={600}
                                        component="span"
                                        onClick={() =>
                                          onDeleteFile(
                                            index,
                                            currentFarmVerificationDocuments
                                              .yearlyVerificationDocuments[1]
                                              .capDocument[index].folder,
                                            currentFarmVerificationDocuments
                                              .yearlyVerificationDocuments[1]
                                              .capDocument[index].fileName,
                                            2019
                                          )
                                        }
                                      >
                                        {t("ver_doc_delete")}
                                      </Typography>}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </React.Fragment>
                            );
                          }
                        )}
                      <Grid
                        item
                        style={{
                          marginBottom: "10px",
                          marginTop: "10px",
                          display: "flex",
                        }}
                      >
                        <SubmitButton
                          sx={{ fontSize: 13, width: "250px" }}
                          component="label"
                          variant="contained"
                          startIcon={<CloudUploadIcon />}
                          onChange={(e) =>
                            onFileChange(e, "capDocument", 2019)
                          }
                          disabled={!allowEditing}
                        >
                          {t("ver_doc_upload")}
                          <input
                            type="file"
                            accept="application/msword,
                          application/pdf, 
                          application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                          image/png, 
                          image/jpeg"
                            hidden
                          />
                        </SubmitButton>
                      </Grid>
                    </Stack>
                  </Grid>
                )}

                {/* CAP 2020 */}
                {allMonitoringYears.includes(2020) && (
                  <Grid container marginTop={2}>
                    <Grid
                      item
                      xs={1}
                      sm={1}
                      md={1}
                      lg={1}
                      style={{
                        marginBottom: "10px",
                        marginTop: "10px",
                        display: "flex",
                      }}
                    >
                      <Typography
                        fontWeight={400}
                        fontSize={18}
                        color="#707070"
                      >
                        2020
                      </Typography>
                    </Grid>
                    <Stack>
                      {currentFarmVerificationDocuments
                        ?.yearlyVerificationDocuments?.[2]?.capDocument
                        ?.length > 0 &&
                        currentFarmVerificationDocuments.yearlyVerificationDocuments[2].capDocument.map(
                          (ele, index) => {
                            return (
                              <React.Fragment key={ele}>
                                <Grid container spacing={2}>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    display={"flex"}
                                    marginBottom={1}
                                  >
                                    <Grid>
                                      <UploadedDocument
                                        size="small"
                                        label={
                                          <Fragment>
                                            <CheckCircle sx={{ marginRight: 1 }} />
                                            {currentFarmVerificationDocuments
                                              ?.yearlyVerificationDocuments?.[2]
                                              ?.capDocument[index].fileName}
                                          </Fragment>
                                        }
                                        disabled
                                      ></UploadedDocument>
                                    </Grid>

                                    <Grid
                                      display={"flex"}
                                      alignItems={"center"}
                                    >
                                      <Typography
                                        variant="h4"
                                        sx={{
                                          color: "#3e9781",
                                          cursor: "pointer",
                                          marginX: "10px",
                                        }}
                                        fontWeight={600}
                                        component="span"
                                        onClick={() =>
                                          handleDownload(
                                            currentFarmVerificationDocuments
                                              .yearlyVerificationDocuments[2]
                                              .capDocument[index].s3URL
                                          )
                                        }
                                      >
                                        {t("ver_doc_download")}
                                      </Typography>
                                      |
                                      {allowEditing && <Typography
                                        variant="h4"
                                        sx={{
                                          color: "#3e9781",
                                          cursor: "pointer",
                                          marginLeft: "10px",
                                        }}
                                        fontWeight={600}
                                        component="span"
                                        onClick={() =>
                                          onDeleteFile(
                                            index,
                                            currentFarmVerificationDocuments
                                              .yearlyVerificationDocuments[2]
                                              .capDocument[index].folder,
                                            currentFarmVerificationDocuments
                                              .yearlyVerificationDocuments[2]
                                              .capDocument[index].fileName,
                                            2020
                                          )
                                        }
                                      >
                                        {t("ver_doc_delete")}
                                      </Typography>}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </React.Fragment>
                            );
                          }
                        )}
                      <Grid
                        item
                        style={{
                          marginBottom: "10px",
                          marginTop: "10px",
                          display: "flex",
                        }}
                      >
                        <SubmitButton
                          sx={{ fontSize: 13, width: "250px" }}
                          disabled={!allowEditing}
                          component="label"
                          variant="contained"
                          startIcon={<CloudUploadIcon />}
                          onChange={(e) =>
                            onFileChange(e, "capDocument", 2020)
                          }
                        >
                          {t("ver_doc_upload")}
                          <input
                            type="file"
                            accept="application/msword,
                          application/pdf, 
                          application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                          image/png, 
                          image/jpeg"
                            hidden
                          />
                        </SubmitButton>
                      </Grid>
                    </Stack>
                  </Grid>
                )}

                {/* CAP 2021 */}
                {allMonitoringYears.includes(2021) && (
                  <Grid container marginTop={2}>
                    <Grid
                      item
                      xs={1}
                      sm={1}
                      md={1}
                      lg={1}
                      style={{
                        marginBottom: "10px",
                        marginTop: "10px",
                        display: "flex",
                      }}
                    >
                      <Typography
                        fontWeight={400}
                        fontSize={18}
                        color="#707070"
                      >
                        2021
                      </Typography>
                    </Grid>
                    <Stack>
                      {currentFarmVerificationDocuments
                        ?.yearlyVerificationDocuments?.[3]?.capDocument
                        ?.length > 0 &&
                        currentFarmVerificationDocuments.yearlyVerificationDocuments[3].capDocument.map(
                          (ele, index) => {
                            return (
                              <React.Fragment key={ele}>
                                <Grid container spacing={2}>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    display={"flex"}
                                    marginBottom={1}
                                  >
                                    <Grid>
                                      <UploadedDocument
                                        size="small"
                                        label={
                                          <Fragment>
                                            <CheckCircle sx={{ marginRight: 1 }} />
                                            {currentFarmVerificationDocuments
                                              ?.yearlyVerificationDocuments?.[3]
                                              ?.capDocument[index].fileName}
                                          </Fragment>
                                        }
                                        disabled
                                      ></UploadedDocument>
                                    </Grid>

                                    <Grid
                                      display={"flex"}
                                      alignItems={"center"}
                                    >
                                      <Typography
                                        variant="h4"
                                        sx={{
                                          color: "#3e9781",
                                          cursor: "pointer",
                                          marginX: "10px",
                                        }}
                                        fontWeight={600}
                                        component="span"
                                        onClick={() =>
                                          handleDownload(
                                            currentFarmVerificationDocuments
                                              .yearlyVerificationDocuments[3]
                                              .capDocument[index].s3URL
                                          )
                                        }
                                      >
                                        {t("ver_doc_download")}
                                      </Typography>
                                      |
                                      {allowEditing && <Typography
                                        variant="h4"
                                        sx={{
                                          color: "#3e9781",
                                          cursor: "pointer",
                                          marginLeft: "10px",
                                        }}
                                        fontWeight={600}
                                        component="span"
                                        onClick={() =>
                                          onDeleteFile(
                                            index,
                                            currentFarmVerificationDocuments
                                              .yearlyVerificationDocuments[3]
                                              .capDocument[index].folder,
                                            currentFarmVerificationDocuments
                                              .yearlyVerificationDocuments[3]
                                              .capDocument[index].fileName,
                                            2021
                                          )
                                        }
                                      >
                                        {t("ver_doc_delete")}
                                      </Typography>}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </React.Fragment>
                            );
                          }
                        )}
                      <Grid
                        item
                        style={{
                          marginBottom: "10px",
                          marginTop: "10px",
                          display: "flex",
                        }}
                      >
                        <SubmitButton
                          sx={{ fontSize: 13, width: "250px" }}
                          disabled={!allowEditing}
                          component="label"
                          variant="contained"
                          startIcon={<CloudUploadIcon />}
                          onChange={(e) =>
                            onFileChange(e, "capDocument", 2021)
                          }
                        >
                          {t("ver_doc_upload")}
                          <input
                            type="file"
                            accept="application/msword,
                          application/pdf, 
                          application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                          image/png, 
                          image/jpeg"
                            hidden
                          />
                        </SubmitButton>
                      </Grid>
                    </Stack>
                  </Grid>
                )}

                {/* CAP 2022 */}
                {allMonitoringYears.includes(2022) && (
                  <Grid container marginTop={2}>
                    <Grid
                      item
                      xs={1}
                      sm={1}
                      md={1}
                      lg={1}
                      style={{
                        marginBottom: "10px",
                        marginTop: "10px",
                        display: "flex",
                      }}
                    >
                      <Typography
                        fontWeight={400}
                        fontSize={18}
                        color="#707070"
                      >
                        2022
                      </Typography>
                    </Grid>
                    <Stack>
                      {currentFarmVerificationDocuments
                        ?.yearlyVerificationDocuments?.[4]?.capDocument
                        ?.length > 0 &&
                        currentFarmVerificationDocuments.yearlyVerificationDocuments[4].capDocument.map(
                          (ele, index) => {
                            return (
                              <React.Fragment key={ele}>
                                <Grid container spacing={2}>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    display={"flex"}
                                    marginBottom={1}
                                  >
                                    <Grid>
                                      <UploadedDocument
                                        size="small"
                                        label={
                                          <Fragment>
                                            <CheckCircle sx={{ marginRight: 1 }} />
                                            {currentFarmVerificationDocuments
                                              ?.yearlyVerificationDocuments?.[4]
                                              ?.capDocument[index].fileName}
                                          </Fragment>
                                        }
                                        disabled
                                      ></UploadedDocument>
                                    </Grid>

                                    <Grid
                                      display={"flex"}
                                      alignItems={"center"}
                                    >
                                      <Typography
                                        variant="h4"
                                        sx={{
                                          color: "#3e9781",
                                          cursor: "pointer",
                                          marginX: "10px",
                                        }}
                                        fontWeight={600}
                                        component="span"
                                        onClick={() =>
                                          handleDownload(
                                            currentFarmVerificationDocuments
                                              .yearlyVerificationDocuments[4]
                                              .capDocument[index].s3URL
                                          )
                                        }
                                      >
                                        {t("ver_doc_download")}
                                      </Typography>
                                      |
                                      {allowEditing && <Typography
                                        variant="h4"
                                        sx={{
                                          color: "#3e9781",
                                          cursor: "pointer",
                                          marginLeft: "10px",
                                        }}
                                        fontWeight={600}
                                        component="span"
                                        onClick={() =>
                                          onDeleteFile(
                                            index,
                                            currentFarmVerificationDocuments
                                              .yearlyVerificationDocuments[4]
                                              .capDocument[index].folder,
                                            currentFarmVerificationDocuments
                                              .yearlyVerificationDocuments[4]
                                              .capDocument[index].fileName,
                                            2022
                                          )
                                        }
                                      >
                                        {t("ver_doc_delete")}
                                      </Typography>}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </React.Fragment>
                            );
                          }
                        )}
                      <Grid
                        item
                        style={{
                          marginBottom: "10px",
                          marginTop: "10px",
                          display: "flex",
                        }}
                      >
                        <SubmitButton
                          sx={{ fontSize: 13, width: "250px" }}
                          disabled={!allowEditing}
                          component="label"
                          variant="contained"
                          startIcon={<CloudUploadIcon />}
                          onChange={(e) =>
                            onFileChange(e, "capDocument", 2022)
                          }
                        >
                          {t("ver_doc_upload")}
                          <input
                            type="file"
                            accept="application/msword,
                          application/pdf, 
                          application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                          image/png, 
                          image/jpeg"
                            hidden
                          />
                        </SubmitButton>
                      </Grid>
                    </Stack>
                  </Grid>
                )}

                {/* CAP 2023 */}
                {allMonitoringYears.includes(2023) && (
                  <Grid container marginTop={2}>
                    <Grid
                      item
                      xs={1}
                      sm={1}
                      md={1}
                      lg={1}
                      style={{
                        marginBottom: "10px",
                        marginTop: "10px",
                        display: "flex",
                      }}
                    >
                      <Typography
                        fontWeight={400}
                        fontSize={18}
                        color="#707070"
                      >
                        2023
                      </Typography>
                    </Grid>
                    <Stack>
                      {currentFarmVerificationDocuments
                        ?.yearlyVerificationDocuments?.[5]?.capDocument
                        ?.length > 0 &&
                        currentFarmVerificationDocuments.yearlyVerificationDocuments[5].capDocument.map(
                          (ele, index) => {
                            return (
                              <React.Fragment key={ele}>
                                <Grid container spacing={2}>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    display={"flex"}
                                    marginBottom={1}
                                  >
                                    <Grid>
                                      <UploadedDocument
                                        size="small"
                                        label={
                                          <Fragment>
                                            <CheckCircle sx={{ marginRight: 1 }} />
                                            {currentFarmVerificationDocuments
                                              ?.yearlyVerificationDocuments?.[5]
                                              ?.capDocument[index].fileName}
                                          </Fragment>
                                        }
                                        disabled
                                      ></UploadedDocument>
                                    </Grid>

                                    <Grid
                                      display={"flex"}
                                      alignItems={"center"}
                                    >
                                      <Typography
                                        variant="h4"
                                        sx={{
                                          color: "#3e9781",
                                          cursor: "pointer",
                                          marginX: "10px",
                                        }}
                                        fontWeight={600}
                                        component="span"
                                        onClick={() =>
                                          handleDownload(
                                            currentFarmVerificationDocuments
                                              .yearlyVerificationDocuments[5]
                                              .capDocument[index].s3URL
                                          )
                                        }
                                      >
                                        {t("ver_doc_download")}
                                      </Typography>
                                      |
                                      {allowEditing && <Typography
                                        variant="h4"
                                        sx={{
                                          color: "#3e9781",
                                          cursor: "pointer",
                                          marginLeft: "10px",
                                        }}
                                        fontWeight={600}
                                        component="span"
                                        onClick={() =>
                                          onDeleteFile(
                                            index,
                                            currentFarmVerificationDocuments
                                              .yearlyVerificationDocuments[5]
                                              .capDocument[index].folder,
                                            currentFarmVerificationDocuments
                                              .yearlyVerificationDocuments[5]
                                              .capDocument[index].fileName,
                                            2023
                                          )
                                        }
                                      >
                                        {t("ver_doc_delete")}
                                      </Typography>}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </React.Fragment>
                            );
                          }
                        )}
                      <Grid
                        item
                        style={{
                          marginBottom: "10px",
                          marginTop: "10px",
                          display: "flex",
                        }}
                      >
                        <SubmitButton
                          sx={{ fontSize: 13, width: "250px" }}
                          disabled={!allowEditing}
                          component="label"
                          variant="contained"
                          startIcon={<CloudUploadIcon />}
                          onChange={(e) =>
                            onFileChange(e, "capDocument", 2023)
                          }
                        >
                          {t("ver_doc_upload")}
                          <input
                            type="file"
                            accept="application/msword,
                          application/pdf, 
                          application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                          image/png, 
                          image/jpeg"
                            hidden
                          />
                        </SubmitButton>
                      </Grid>
                    </Stack>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Divider sx={{ marginY: 6 }}></Divider>

            {/* LIVESTOCK */}
            <Grid
              container
              spacing={2}
              style={{ marginTop: "4px", marginBottom: "10px" }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ marginBottom: "10px" }}
              >
                <Typography variant="h2">
                  {t("ver_doc_livestock")}
                </Typography>
                <Grid item display={"flex"} marginBottom={4}>
                  <Typography variant="subtitle2">
                    {t("ver_doc_livestock_info")}
                  </Typography>
                  <Tooltip
                    title={
                      <>
                        {t("ver_doc_livestock_info_box_1")}
                        <br />
                        <br />
                        {t("ver_doc_livestock_info_box_2")}
                        <br />
                        {t("ver_doc_livestock_info_box_3")}
                        <br />
                        {t("ver_doc_livestock_info_box_4")}
                        <br />
                        {t("ver_doc_livestock_info_box_5")}
                        <br />
                        <br />
                        {t("ver_doc_livestock_info_box_6")}
                        <br />
                        <br />
                        {t("ver_doc_livestock_info_box_7")}
                        <br />
                        <br />
                      </>
                    }
                    placement="top-start"
                  >
                    <InfoIcon
                      fontSize="small"
                      sx={{
                        padding: "0px 0px 0px 8px",
                        color: "#000000",
                      }}
                    />
                  </Tooltip>
                </Grid>

                {/* LIVESTOCK 2018 */}
                {allMonitoringYears.includes(2018) && (
                  <Grid container marginTop={2}>
                    <Grid
                      item
                      xs={1}
                      sm={1}
                      md={1}
                      lg={1}
                      style={{
                        marginBottom: "10px",
                        marginTop: "10px",
                        display: "flex",
                      }}
                    >
                      <Typography
                        fontWeight={400}
                        fontSize={18}
                        color="#707070"
                      >
                        2018
                      </Typography>
                    </Grid>
                    <Stack>
                      {currentFarmVerificationDocuments
                        ?.yearlyVerificationDocuments?.[0]?.livestockTotals
                        ?.length > 0 &&
                        currentFarmVerificationDocuments.yearlyVerificationDocuments[0].livestockTotals.map(
                          (ele, index) => {
                            return (
                              <React.Fragment key={ele}>
                                <Grid container spacing={2}>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    display={"flex"}
                                    marginBottom={1}
                                  >
                                    <Grid>
                                      <UploadedDocument
                                        size="small"
                                        label={
                                          <Fragment>
                                            <CheckCircle sx={{ marginRight: 1 }} />
                                            {currentFarmVerificationDocuments
                                              ?.yearlyVerificationDocuments?.[0]
                                              ?.livestockTotals[index].fileName}
                                          </Fragment>
                                        }
                                        disabled
                                      ></UploadedDocument>
                                    </Grid>

                                    <Grid
                                      display={"flex"}
                                      alignItems={"center"}
                                    >
                                      <Typography
                                        variant="h4"
                                        sx={{
                                          color: "#3e9781",
                                          cursor: "pointer",
                                          marginX: "10px",
                                        }}
                                        fontWeight={600}
                                        component="span"
                                        onClick={() =>
                                          handleDownload(
                                            currentFarmVerificationDocuments
                                              .yearlyVerificationDocuments[0]
                                              .livestockTotals[index].s3URL
                                          )
                                        }
                                      >
                                        {t("ver_doc_download")}
                                      </Typography>
                                      |
                                      {allowEditing && <Typography
                                        variant="h4"
                                        sx={{
                                          color: "#3e9781",
                                          cursor: "pointer",
                                          marginLeft: "10px",
                                        }}
                                        fontWeight={600}
                                        component="span"
                                        onClick={() =>
                                          onDeleteFile(
                                            index,
                                            currentFarmVerificationDocuments
                                              .yearlyVerificationDocuments[0]
                                              .livestockTotals[index].folder,
                                            currentFarmVerificationDocuments
                                              .yearlyVerificationDocuments[0]
                                              .livestockTotals[index].fileName,
                                            2018
                                          )
                                        }
                                      >
                                        {t("ver_doc_delete")}
                                      </Typography>}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </React.Fragment>
                            );
                          }
                        )}
                      <Grid
                        item
                        style={{
                          marginBottom: "10px",
                          marginTop: "10px",
                          display: "flex",
                        }}
                      >
                        <SubmitButton
                          sx={{ fontSize: 13, width: "250px" }}
                          disabled={!allowEditing}
                          component="label"
                          variant="contained"
                          startIcon={<CloudUploadIcon />}
                          onChange={(e) =>
                            onFileChange(e, "livestockTotals", 2018)
                          }
                        >
                          {t("ver_doc_upload")}
                          <input
                            type="file"
                            accept="application/msword,
                          application/pdf, 
                          application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                          image/png, 
                          image/jpeg"
                            hidden
                          />
                        </SubmitButton>
                      </Grid>
                    </Stack>
                  </Grid>
                )}

                {/* LIVESTOCK 2019 */}
                {allMonitoringYears.includes(2019) && (
                  <Grid container marginTop={2}>
                    <Grid
                      item
                      xs={1}
                      sm={1}
                      md={1}
                      lg={1}
                      style={{
                        marginBottom: "10px",
                        marginTop: "10px",
                        display: "flex",
                      }}
                    >
                      <Typography
                        fontWeight={400}
                        fontSize={18}
                        color="#707070"
                      >
                        2019
                      </Typography>
                    </Grid>
                    <Stack>
                      {currentFarmVerificationDocuments
                        ?.yearlyVerificationDocuments?.[1]?.livestockTotals
                        ?.length > 0 &&
                        currentFarmVerificationDocuments.yearlyVerificationDocuments[1].livestockTotals.map(
                          (ele, index) => {
                            return (
                              <React.Fragment key={ele}>
                                <Grid container spacing={2}>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    display={"flex"}
                                    marginBottom={1}
                                  >
                                    <Grid>
                                      <UploadedDocument
                                        size="small"
                                        label={
                                          <Fragment>
                                            <CheckCircle sx={{ marginRight: 1 }} />
                                            {currentFarmVerificationDocuments
                                              ?.yearlyVerificationDocuments?.[1]
                                              ?.livestockTotals[index].fileName}
                                          </Fragment>
                                        }
                                        disabled
                                      ></UploadedDocument>
                                    </Grid>

                                    <Grid
                                      display={"flex"}
                                      alignItems={"center"}
                                    >
                                      <Typography
                                        variant="h4"
                                        sx={{
                                          color: "#3e9781",
                                          cursor: "pointer",
                                          marginX: "10px",
                                        }}
                                        fontWeight={600}
                                        component="span"
                                        onClick={() =>
                                          handleDownload(
                                            currentFarmVerificationDocuments
                                              .yearlyVerificationDocuments[1]
                                              .livestockTotals[index].s3URL
                                          )
                                        }
                                      >
                                        {t("ver_doc_download")}
                                      </Typography>
                                      |
                                      {allowEditing && <Typography
                                        variant="h4"
                                        sx={{
                                          color: "#3e9781",
                                          cursor: "pointer",
                                          marginLeft: "10px",
                                        }}
                                        fontWeight={600}
                                        component="span"
                                        onClick={() =>
                                          onDeleteFile(
                                            index,
                                            currentFarmVerificationDocuments
                                              .yearlyVerificationDocuments[1]
                                              .livestockTotals[index].folder,
                                            currentFarmVerificationDocuments
                                              .yearlyVerificationDocuments[1]
                                              .livestockTotals[index].fileName,
                                            2019
                                          )
                                        }
                                      >
                                        {t("ver_doc_delete")}
                                      </Typography>}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </React.Fragment>
                            );
                          }
                        )}
                      <Grid
                        item
                        style={{
                          marginBottom: "10px",
                          marginTop: "10px",
                          display: "flex",
                        }}
                      >
                        <SubmitButton
                          sx={{ fontSize: 13, width: "250px" }}
                          disabled={!allowEditing}
                          component="label"
                          variant="contained"
                          startIcon={<CloudUploadIcon />}
                          onChange={(e) =>
                            onFileChange(e, "livestockTotals", 2019)
                          }
                        >
                          {t("ver_doc_upload")}
                          <input
                            type="file"
                            accept="application/msword,
                          application/pdf, 
                          application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                          image/png, 
                          image/jpeg"
                            hidden
                          />
                        </SubmitButton>
                      </Grid>
                    </Stack>
                  </Grid>
                )}

                {/* LIVESTOCK 2020 */}
                {allMonitoringYears.includes(2020) && (
                  <Grid container marginTop={2}>
                    <Grid
                      item
                      xs={1}
                      sm={1}
                      md={1}
                      lg={1}
                      style={{
                        marginBottom: "10px",
                        marginTop: "10px",
                        display: "flex",
                      }}
                    >
                      <Typography
                        fontWeight={400}
                        fontSize={18}
                        color="#707070"
                      >
                        2020
                      </Typography>
                    </Grid>
                    <Stack>
                      {currentFarmVerificationDocuments
                        ?.yearlyVerificationDocuments?.[2]?.livestockTotals
                        ?.length > 0 &&
                        currentFarmVerificationDocuments.yearlyVerificationDocuments[2].livestockTotals.map(
                          (ele, index) => {
                            return (
                              <React.Fragment key={ele}>
                                <Grid container spacing={2}>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    display={"flex"}
                                    marginBottom={1}
                                  >
                                    <Grid>
                                      <UploadedDocument
                                        size="small"
                                        label={
                                          <Fragment>
                                            <CheckCircle sx={{ marginRight: 1 }} />
                                            {currentFarmVerificationDocuments
                                              ?.yearlyVerificationDocuments?.[2]
                                              ?.livestockTotals[index].fileName}
                                          </Fragment>
                                        }
                                        disabled
                                      ></UploadedDocument>
                                    </Grid>

                                    <Grid
                                      display={"flex"}
                                      alignItems={"center"}
                                    >
                                      <Typography
                                        variant="h4"
                                        sx={{
                                          color: "#3e9781",
                                          cursor: "pointer",
                                          marginX: "10px",
                                        }}
                                        fontWeight={600}
                                        component="span"
                                        onClick={() =>
                                          handleDownload(
                                            currentFarmVerificationDocuments
                                              .yearlyVerificationDocuments[2]
                                              .livestockTotals[index].s3URL
                                          )
                                        }
                                      >
                                        {t("ver_doc_download")}
                                      </Typography>
                                      |
                                      {allowEditing && <Typography
                                        variant="h4"
                                        sx={{
                                          color: "#3e9781",
                                          cursor: "pointer",
                                          marginLeft: "10px",
                                        }}
                                        fontWeight={600}
                                        component="span"
                                        onClick={() =>
                                          onDeleteFile(
                                            index,
                                            currentFarmVerificationDocuments
                                              .yearlyVerificationDocuments[2]
                                              .livestockTotals[index].folder,
                                            currentFarmVerificationDocuments
                                              .yearlyVerificationDocuments[2]
                                              .livestockTotals[index].fileName,
                                            2020
                                          )
                                        }
                                      >
                                        {t("ver_doc_delete")}
                                      </Typography>}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </React.Fragment>
                            );
                          }
                        )}
                      <Grid
                        item
                        style={{
                          marginBottom: "10px",
                          marginTop: "10px",
                          display: "flex",
                        }}
                      >
                        <SubmitButton
                          sx={{ fontSize: 13, width: "250px" }}
                          disabled={!allowEditing}
                          component="label"
                          variant="contained"
                          startIcon={<CloudUploadIcon />}
                          onChange={(e) =>
                            onFileChange(e, "livestockTotals", 2020)
                          }
                        >
                          {t("ver_doc_upload")}
                          <input
                            type="file"
                            accept="application/msword,
                          application/pdf, 
                          application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                          image/png, 
                          image/jpeg"
                            hidden
                          />
                        </SubmitButton>
                      </Grid>
                    </Stack>
                  </Grid>
                )}

                {/* LIVESTOCK 2021 */}
                {allMonitoringYears.includes(2021) && (
                  <Grid container marginTop={2}>
                    <Grid
                      item
                      xs={1}
                      sm={1}
                      md={1}
                      lg={1}
                      style={{
                        marginBottom: "10px",
                        marginTop: "10px",
                        display: "flex",
                      }}
                    >
                      <Typography
                        fontWeight={400}
                        fontSize={18}
                        color="#707070"
                      >
                        2021
                      </Typography>
                    </Grid>
                    <Stack>
                      {currentFarmVerificationDocuments
                        ?.yearlyVerificationDocuments?.[3]?.livestockTotals
                        ?.length > 0 &&
                        currentFarmVerificationDocuments.yearlyVerificationDocuments[3].livestockTotals.map(
                          (ele, index) => {
                            return (
                              <React.Fragment key={ele}>
                                <Grid container spacing={2}>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    display={"flex"}
                                    marginBottom={1}
                                  >
                                    <Grid>
                                      <UploadedDocument
                                        size="small"
                                        label={
                                          <Fragment>
                                            <CheckCircle sx={{ marginRight: 1 }} />
                                            {currentFarmVerificationDocuments
                                              ?.yearlyVerificationDocuments?.[3]
                                              ?.livestockTotals[index].fileName}
                                          </Fragment>
                                        }
                                        disabled
                                      ></UploadedDocument>
                                    </Grid>

                                    <Grid
                                      display={"flex"}
                                      alignItems={"center"}
                                    >
                                      <Typography
                                        variant="h4"
                                        sx={{
                                          color: "#3e9781",
                                          cursor: "pointer",
                                          marginX: "10px",
                                        }}
                                        fontWeight={600}
                                        component="span"
                                        onClick={() =>
                                          handleDownload(
                                            currentFarmVerificationDocuments
                                              .yearlyVerificationDocuments[3]
                                              .livestockTotals[index].s3URL
                                          )
                                        }
                                      >
                                        {t("ver_doc_download")}
                                      </Typography>
                                      |
                                      {allowEditing && <Typography
                                        variant="h4"
                                        sx={{
                                          color: "#3e9781",
                                          cursor: "pointer",
                                          marginLeft: "10px",
                                        }}
                                        fontWeight={600}
                                        component="span"
                                        onClick={() =>
                                          onDeleteFile(
                                            index,
                                            currentFarmVerificationDocuments
                                              .yearlyVerificationDocuments[3]
                                              .livestockTotals[index].folder,
                                            currentFarmVerificationDocuments
                                              .yearlyVerificationDocuments[3]
                                              .livestockTotals[index].fileName,
                                            2021
                                          )
                                        }
                                      >
                                        {t("ver_doc_delete")}
                                      </Typography>}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </React.Fragment>
                            );
                          }
                        )}
                      <Grid
                        item
                        style={{
                          marginBottom: "10px",
                          marginTop: "10px",
                          display: "flex",
                        }}
                      >
                        <SubmitButton
                          sx={{ fontSize: 13, width: "250px" }}
                          disabled={!allowEditing}
                          component="label"
                          variant="contained"
                          startIcon={<CloudUploadIcon />}
                          onChange={(e) =>
                            onFileChange(e, "livestockTotals", 2021)
                          }
                        >
                          {t("ver_doc_upload")}
                          <input
                            type="file"
                            accept="application/msword,
                          application/pdf, 
                          application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                          image/png, 
                          image/jpeg"
                            hidden
                          />
                        </SubmitButton>
                      </Grid>
                    </Stack>
                  </Grid>
                )}

                {/* LIVESTOCK 2022 */}
                {allMonitoringYears.includes(2022) && (
                  <Grid container marginTop={2}>
                    <Grid
                      item
                      xs={1}
                      sm={1}
                      md={1}
                      lg={1}
                      style={{
                        marginBottom: "10px",
                        marginTop: "10px",
                        display: "flex",
                      }}
                    >
                      <Typography
                        fontWeight={400}
                        fontSize={18}
                        color="#707070"
                      >
                        2022
                      </Typography>
                    </Grid>
                    <Stack>
                      {currentFarmVerificationDocuments
                        ?.yearlyVerificationDocuments?.[4]?.livestockTotals
                        ?.length > 0 &&
                        currentFarmVerificationDocuments.yearlyVerificationDocuments[4].livestockTotals.map(
                          (ele, index) => {
                            return (
                              <React.Fragment key={ele}>
                                <Grid container spacing={2}>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    display={"flex"}
                                    marginBottom={1}
                                  >
                                    <Grid>
                                      <UploadedDocument
                                        size="small"
                                        label={
                                          <Fragment>
                                            <CheckCircle sx={{ marginRight: 1 }} />
                                            {currentFarmVerificationDocuments
                                              ?.yearlyVerificationDocuments?.[4]
                                              ?.livestockTotals[index].fileName}
                                          </Fragment>
                                        }
                                        disabled
                                      ></UploadedDocument>
                                    </Grid>

                                    <Grid
                                      display={"flex"}
                                      alignItems={"center"}
                                    >
                                      <Typography
                                        variant="h4"
                                        sx={{
                                          color: "#3e9781",
                                          cursor: "pointer",
                                          marginX: "10px",
                                        }}
                                        fontWeight={600}
                                        component="span"
                                        onClick={() =>
                                          handleDownload(
                                            currentFarmVerificationDocuments
                                              .yearlyVerificationDocuments[4]
                                              .livestockTotals[index].s3URL
                                          )
                                        }
                                      >
                                        {t("ver_doc_download")}
                                      </Typography>
                                      |
                                      {allowEditing && <Typography
                                        variant="h4"
                                        sx={{
                                          color: "#3e9781",
                                          cursor: "pointer",
                                          marginLeft: "10px",
                                        }}
                                        fontWeight={600}
                                        component="span"
                                        onClick={() =>
                                          onDeleteFile(
                                            index,
                                            currentFarmVerificationDocuments
                                              .yearlyVerificationDocuments[4]
                                              .livestockTotals[index].folder,
                                            currentFarmVerificationDocuments
                                              .yearlyVerificationDocuments[4]
                                              .livestockTotals[index].fileName,
                                            2022
                                          )
                                        }
                                      >
                                        {t("ver_doc_delete")}
                                      </Typography>}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </React.Fragment>
                            );
                          }
                        )}
                      <Grid
                        item
                        style={{
                          marginBottom: "10px",
                          marginTop: "10px",
                          display: "flex",
                        }}
                      >
                        <SubmitButton
                          sx={{ fontSize: 13, width: "250px" }}
                          disabled={!allowEditing}
                          component="label"
                          variant="contained"
                          startIcon={<CloudUploadIcon />}
                          onChange={(e) =>
                            onFileChange(e, "livestockTotals", 2022)
                          }
                        >
                          {t("ver_doc_upload")}
                          <input
                            type="file"
                            accept="application/msword,
                          application/pdf, 
                          application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                          image/png, 
                          image/jpeg"
                            hidden
                          />
                        </SubmitButton>
                      </Grid>
                    </Stack>
                  </Grid>
                )}

                {/* LIVESTOCK 2023 */}
                {allMonitoringYears.includes(2023) && (
                  <Grid container marginTop={2}>
                    <Grid
                      item
                      xs={1}
                      sm={1}
                      md={1}
                      lg={1}
                      style={{
                        marginBottom: "10px",
                        marginTop: "10px",
                        display: "flex",
                      }}
                    >
                      <Typography
                        fontWeight={400}
                        fontSize={18}
                        color="#707070"
                      >
                        2023
                      </Typography>
                    </Grid>
                    <Stack>
                      {currentFarmVerificationDocuments
                        ?.yearlyVerificationDocuments?.[5]?.livestockTotals
                        ?.length > 0 &&
                        currentFarmVerificationDocuments.yearlyVerificationDocuments[5].livestockTotals.map(
                          (ele, index) => {
                            return (
                              <React.Fragment key={ele}>
                                <Grid container spacing={2}>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    display={"flex"}
                                    marginBottom={1}
                                  >
                                    <Grid>
                                      <UploadedDocument
                                        size="small"
                                        label={
                                          <Fragment>
                                            <CheckCircle sx={{ marginRight: 1 }} />
                                            {currentFarmVerificationDocuments
                                              ?.yearlyVerificationDocuments?.[5]
                                              ?.livestockTotals[index].fileName}
                                          </Fragment>
                                        }
                                        disabled
                                      ></UploadedDocument>
                                    </Grid>

                                    <Grid
                                      display={"flex"}
                                      alignItems={"center"}
                                    >
                                      <Typography
                                        variant="h4"
                                        sx={{
                                          color: "#3e9781",
                                          cursor: "pointer",
                                          marginX: "10px",
                                        }}
                                        fontWeight={600}
                                        component="span"
                                        onClick={() =>
                                          handleDownload(
                                            currentFarmVerificationDocuments
                                              .yearlyVerificationDocuments[5]
                                              .livestockTotals[index].s3URL
                                          )
                                        }
                                      >
                                        {t("ver_doc_download")}
                                      </Typography>
                                      |
                                      {allowEditing && <Typography
                                        variant="h4"
                                        sx={{
                                          color: "#3e9781",
                                          cursor: "pointer",
                                          marginLeft: "10px",
                                        }}
                                        fontWeight={600}
                                        component="span"
                                        onClick={() =>
                                          onDeleteFile(
                                            index,
                                            currentFarmVerificationDocuments
                                              .yearlyVerificationDocuments[5]
                                              .livestockTotals[index].folder,
                                            currentFarmVerificationDocuments
                                              .yearlyVerificationDocuments[5]
                                              .livestockTotals[index].fileName,
                                            2023
                                          )
                                        }
                                      >
                                        {t("ver_doc_delete")}
                                      </Typography>}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </React.Fragment>
                            );
                          }
                        )}
                      <Grid
                        item
                        style={{
                          marginBottom: "10px",
                          marginTop: "10px",
                          display: "flex",
                        }}
                      >
                        <SubmitButton
                          sx={{ fontSize: 13, width: "250px" }}
                          disabled={!allowEditing}
                          component="label"
                          variant="contained"
                          startIcon={<CloudUploadIcon />}
                          onChange={(e) =>
                            onFileChange(e, "livestockTotals", 2023)
                          }
                        >
                          {t("ver_doc_upload")}
                          <input
                            type="file"
                            accept="application/msword,
                          application/pdf, 
                          application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                          image/png, 
                          image/jpeg"
                            hidden
                          />
                        </SubmitButton>
                      </Grid>
                    </Stack>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {allowEditing && (
          <Box display="flex" flexDirection="column">
            <SubmitButton
              component={Link}
              to="/carbonplus"
              sx={{
                marginTop: "100px",
                alignSelf: "flex-start",
                width: "200px",
                height: "45px",
                fontSize: "medium",
              }}
            >
              {t("sa_save")}
            </SubmitButton>
          </Box>
        )}
      </Box>
    </>
  );
};

export default VerificationDocumentsComponent;
