import { Trans, useTranslation } from "react-i18next";


import {
    Info as InfoIcon,
} from "@mui/icons-material";
import { Box, Collapse, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material';


import DropDownLabel from "../general/DropDownLabel";
import NumericInput from "../general/NumericInput";
import TrailingLine from "../general/TrailingLine";


import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import { addNewEmptyPerennialTreeAndCropData, deletePerennialTreeAndCropData } from "../../actions/currentMonitoringData";
import { interdependentError } from "../general/Validations/fieldValueErrors";
import { max, maxDynamic } from "../general/Validations/minMaxValues";
import { addNewPerennialHarvest } from "./addNewEmptySection/addNewPerennialHarvest";
import { addNewPerennialResidue } from "./addNewEmptySection/addNewPerennialResidue";
import { emptyPerennialCropsObject } from "./addNewEmptySection/tmpData";
import { cropsArray, monthArray2, monthArray3, residueTypeArray } from "./cropsArrays";
import { handlePerennialsHarvestRowChange, handlePerennialsResidueRowChange, handlePerennialsRowChange } from "./handleRowChange";


const PerennialSection = ({ yearParcel, year, tabValue, tabPanelIndex, parcelArea }) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    let { parcelid } = useParams();

    const currentMonitoringYearsObject = useSelector(state => state.monitoringYearsTrackerReducer);
    const currentMonitoringDataObject = useSelector(state => state.currentMonitoringDataReducer);

    const currentFarmStatusReducer = useSelector(
        (state) => state.farmStatusReducer
    );
    const accountSettings = useSelector((state) => state.accountSettingsReducer);
    const isAdmin = accountSettings?.[0]?.role === 'admin'
    const allowEditing = currentFarmStatusReducer.allowEditing || isAdmin

    const [arrPerennialCropsCollapsibleButtonStatus, setArrPerennialCropsCollapsibleButtonStatus] = useState(Array(100).fill(true))
    const [totalNumberOfTreeCrops, setTotalNumberOfTreeCrops] = useState(0);

    const yearlyFarmDataOb = currentMonitoringDataObject?.yearlyFarmData.find(ele => ele.year === year);

    useEffect(() => {
        let totalTreeCrops = 0;

        for (let z = 0; z < yearlyFarmDataOb.parcelLevelData.length; z++) {
            if (yearlyFarmDataOb.parcelLevelData[z].parcelFixedValues.parcelID === parcelid) {
                yearlyFarmDataOb.parcelLevelData[z].yearParcelData.perennialCrops.forEach(el => {
                    if (String(el?.species).includes("tree") && el?.treeNumber !== -9999) {
                        totalTreeCrops += Number(el?.treeNumber);
                    }
                });
            }
        }
        setTotalNumberOfTreeCrops(totalTreeCrops);
    }, [currentMonitoringDataObject?.yearlyFarmData.find(yFD => yFD.year === year).parcelLevelData.find(eleParcelData => eleParcelData?.parcelFixedValues?.parcelID === parcelid)?.yearParcelData?.perennialCrops]);


    const togglePerennialCropsCollapsibleState = (toggleIndex) => {
        setArrPerennialCropsCollapsibleButtonStatus(
            arrPerennialCropsCollapsibleButtonStatus.map((v, i) => (i === toggleIndex ? !v : v))
        );
    }

    const addNewPerennialTreeAndCrop = (e) => {
        let tmpData = {
            year: year,
            data: emptyPerennialCropsObject,
            parcelId: parcelid
        };
        dispatch(addNewEmptyPerennialTreeAndCropData(tmpData))
    };



    const handlePerennialsReadonlyStatus = (speciesName, otherName, plantingYearValue, treeNumberValue) => {
        var perennialReadOnly = false;
        if (tabPanelIndex > 0) {
            var tmpYearlyFarmData = currentMonitoringDataObject.yearlyFarmData.find(ele => ele.year === currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex - 1])
            var tmpParcelLevelPerennialDataPrevious = tmpYearlyFarmData?.parcelLevelData.find(pOb => pOb.parcelFixedValues.parcelID === parcelid)?.yearParcelData
            for (var j = 0; j < tmpParcelLevelPerennialDataPrevious?.perennialCrops?.length; j++) {
                if (speciesName && speciesName !== '-' && speciesName === tmpParcelLevelPerennialDataPrevious?.perennialCrops[j]?.species &&
                    otherName === tmpParcelLevelPerennialDataPrevious?.perennialCrops[j]?.other &&
                    plantingYearValue === tmpParcelLevelPerennialDataPrevious?.perennialCrops[j]?.plantingYear
                ) {
                    perennialReadOnly = true; break;
                }
            }
        }
        return perennialReadOnly;

    };

    const deletePerennialTreeAndCrop = (e, arrIndex) => {
        const previousSpeciesName = currentMonitoringDataObject?.yearlyFarmData.find(ele => ele.year === currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]).parcelLevelData.find((parcel) => parcel.parcelFixedValues.parcelID === parcelid).yearParcelData.perennialCrops[arrIndex].species
        const previousOther = currentMonitoringDataObject?.yearlyFarmData.find(ele => ele.year === currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]).parcelLevelData.find((parcel) => parcel.parcelFixedValues.parcelID === parcelid).yearParcelData.perennialCrops[arrIndex].other
        const previousPlantingYear = currentMonitoringDataObject?.yearlyFarmData.find(ele => ele.year === currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]).parcelLevelData.find((parcel) => parcel.parcelFixedValues.parcelID === parcelid).yearParcelData.perennialCrops[arrIndex].plantingYear
        const previousRotationYears = currentMonitoringDataObject?.yearlyFarmData.find(ele => ele.year === currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]).parcelLevelData.find((parcel) => parcel.parcelFixedValues.parcelID === parcelid).yearParcelData.perennialCrops[arrIndex].rotationYears

        let tmpData = {
            year: currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex],
            parcelId: parcelid,
            arrayIndex: arrIndex,
            previousSpeciesValue: previousSpeciesName,
            previousOtherValue: previousOther,
            previousPlantingYearValue: previousPlantingYear,
            previousRotationYearsValue: previousRotationYears
        };
        dispatch(deletePerennialTreeAndCropData(tmpData))
            .then(res => {
                console.log('Perennial tree and crop data deleted successfully:', res);
            })
            .catch(error => {
                console.error('Error deleting perennial tree and crop data:', error);
            });

    };
    let yearsTillTabYear = []
    for (let i = 2018; i <= tabValue; i++) {
        yearsTillTabYear.push(i)
    }

    return (
        <Grid marginBottom={2} marginTop={2} container>
            <Typography sx={{ my: 1 }} variant='h2'>{t("pci_perennial_title_1")} {totalNumberOfTreeCrops} {t("pci_perennial_title_2")}</Typography>
            <Typography sx={{ mb: 2 }} variant="subtitle2">{t("pci_perennial_subtitle")}
                <Tooltip sx={{ paddingLeft: 1 }} title={<Trans i18nKey={"pci_perennial_info"}></Trans>}>
                    <InfoIcon sx={{
                        fontSize: 'medium',
                        pl: 0.5,
                    }} />
                </Tooltip>
            </Typography>

            {yearParcel &&
                yearParcel?.yearParcelData?.perennialCrops.map((val, i) => {
                    const interdependentTreeArray = [val?.species, val?.plantingYear, val?.rotationYears, val?.treeNumber, val?.DBHmean?.diameter]
                    return <React.Fragment key={i}>
                        <Box display="flex" justifyContent="space-between" sx={{ width: 1 }}>
                            <DropDownLabel
                                color={val?.species ? 'black' : 'grey'}
                                onClick={() => togglePerennialCropsCollapsibleState(i)}
                                status={arrPerennialCropsCollapsibleButtonStatus[i]}
                                text={
                                    (val.species ? t(val.species) : t("pci_perennial_tree_label")) +
                                    (String(val?.species).includes("tree") ? " | " + (val?.treeNumber === -9999 ? 0 : val?.treeNumber) + t("pci_perennial_title_2") : "") +
                                    (val?.plantingYear ? ', ' + (String(val?.plantingYear).includes('before') ? t("pci_perennial_tree_list_year_before").toLowerCase() : val?.plantingYear) : '')
                                }
                            />

                            {allowEditing && <p onClick={(e) => deletePerennialTreeAndCrop(e, i)} style={{ color: "#db6160", fontWeight: 'bold', marginRight: '20px', cursor: 'pointer' }}>{t("pci_pastures_fallow_period_delete")}</p>
                            }
                        </Box>
                        <Collapse in={arrPerennialCropsCollapsibleButtonStatus[i]} timeout="auto"
                        >
                            <Grid marginLeft={1} marginTop={4} item xs={12} md={12} lg={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={2} md={2} lg={2}>
                                        <Typography variant="h3">{t("pci_perennial_subsection_desc")}
                                            <Tooltip sx={{ pl: 1 }} title={<Trans i18nKey={"pci_perennial_subsection_info"}></Trans>}>
                                                <InfoIcon sx={{
                                                    fontSize: 'medium',
                                                    pl: 0.5,
                                                }} />
                                            </Tooltip>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3} md={3} lg={3}>
                                        <FormControl fullWidth>
                                            <InputLabel shrink={val?.species} size='small' id="perennials-and-tree-label">{t("pci_perennial_tree_list_dd")}</InputLabel>
                                            <Select
                                                name="species"
                                                size="small"
                                                value={val?.species}
                                                disabled={handlePerennialsReadonlyStatus(val.species, val.other, val.plantingYear, val.treeNumber) || !allowEditing}
                                                labelId="perennials-and-tree-label"
                                                id="perennials-and-tree-select"
                                                label={t("pci_perennial_tree_list_dd")}
                                                onChange={(e) => handlePerennialsRowChange(e, i, year, dispatch, parcelid, yearlyFarmDataOb)}
                                            >
                                                {cropsArray.map((crop) => { return <MenuItem key={crop} value={crop}>{t(crop)}</MenuItem> })}

                                            </Select>
                                        </FormControl>

                                    </Grid>
                                    <Grid item xs={3} md={3} lg={3}>
                                        <FormControl fullWidth>
                                            <TextField
                                                error={val.species?.includes('other') && (!val.other || val.other === '-')}
                                                name="other"
                                                size="small"
                                                disabled={!String(val.species).includes("other") || handlePerennialsReadonlyStatus(val.species, val.other, val.plantingYear, val.treeNumber) || !allowEditing}
                                                value={val.other ? val.other : ""} fullWidth placeholder={t("pci_perennial_tree_list_specific")} label={t("pci_perennial_tree_list_specific")} onChange={(e) => handlePerennialsRowChange(e, i, year, dispatch, parcelid, yearlyFarmDataOb)} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={2} md={2} lg={2}>
                                        <FormControl fullWidth>
                                            <InputLabel shrink={val?.plantingYear} size='small' id="year-of-planting-label">{t("pci_perennial_tree_list_year_planting")}</InputLabel>
                                            <Select
                                                error={val?.species?.includes('tree') && interdependentError(val.plantingYear, interdependentTreeArray)}
                                                name="plantingYear"
                                                value={val.plantingYear}
                                                size="small"
                                                disabled={!String(val.species).includes("tree") || handlePerennialsReadonlyStatus(val.species, val.other, val.plantingYear, val.treeNumber) || !allowEditing}
                                                labelId="year-of-planting-label"
                                                id="year-of-planting-select"
                                                label={t("pci_perennial_tree_list_year_planting")}
                                                onChange={(e) => handlePerennialsRowChange(e, i, year, dispatch, parcelid, yearlyFarmDataOb)}
                                            >
                                                <MenuItem key="before 2018" value="before 2018">{t("pci_perennial_tree_list_year_before")}</MenuItem>
                                                {yearsTillTabYear.map((year) => { return <MenuItem key={year} value={year}>{year}</MenuItem> })}
                                            </Select>
                                        </FormControl>

                                    </Grid>
                                    <Grid item xs={2} md={2} lg={2}>
                                        <FormControl fullWidth>
                                            <NumericInput

                                                max={max.rotationYear}

                                                name="rotationYears"
                                                disabled={!String(val.species).includes("tree") || handlePerennialsReadonlyStatus(val.species, val.other, val.plantingYear, val.treeNumber)}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">{t("pci_perennial_tree_list_tree_span_yrs")}</InputAdornment>,
                                                }}
                                                value={val.rotationYears === -9999 ? '' : val.rotationYears} fullWidth placeholder={t("pci_perennial_tree_list_tree_span")} label={t("pci_perennial_tree_list_tree_span")} onChange={(e) => handlePerennialsRowChange(e, i, year, dispatch, parcelid, yearlyFarmDataOb)} />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={2} md={2} lg={2}>
                                        <Typography variant="h3">{t("pci_perennial_tree_list_number_growth")}
                                            <Tooltip title={<>{t("pci_perennial_tree_list_number_growth_info")}</>}>
                                                <InfoIcon sx={{
                                                    fontSize: 'medium',
                                                    padding: 0.5,
                                                }} />
                                            </Tooltip>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3} md={3} lg={3}>
                                        <FormControl fullWidth>
                                            <NumericInput
                                                error={val.species?.includes('tree') && interdependentError(val.treeNumber, interdependentTreeArray)}
                                                max={maxDynamic.treeNumber * parcelArea}
                                                name="treeNumber"
                                                disabled={!String(val.species).includes("tree")}
                                                value={val.treeNumber !== -9999 ? val.treeNumber : ''}
                                                fullWidth
                                                placeholder={t("pci_perennial_tree_list_number_trees")}
                                                label={t("pci_perennial_tree_list_number_trees")}
                                                onChange={(e) => handlePerennialsRowChange(e, i, year, dispatch, parcelid, yearlyFarmDataOb)} />
                                        </FormControl>

                                    </Grid>
                                    <Grid item xs={3} md={3} lg={3}>
                                        <FormControl fullWidth>
                                            <NumericInput
                                                error={val.species?.includes('tree') && interdependentError(val.DBHmean?.diameter, interdependentTreeArray)}
                                                max={max.DBH}
                                                name="DBHmean"
                                                decimalScale={3}
                                                disabled={!String(val.species).includes("tree")}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">{t("pci_perennial_tree_list_dbh_field_cm")}</InputAdornment>,
                                                }}
                                                value={val.DBHmean?.diameter !== -9999 ? val.DBHmean?.diameter : ''}
                                                fullWidth
                                                placeholder={t("pci_perennial_tree_list_dbh_field")}
                                                label={t("pci_perennial_tree_list_dbh_field")}
                                                onChange={(e) => handlePerennialsRowChange(e, i, year, dispatch, parcelid, yearlyFarmDataOb)} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4} md={4} lg={4}></Grid>

                                    {val.harvest.map((hValue, j) => {
                                        const interdependentHarvestArray = [hValue.date, hValue.type, hValue.amount]
                                        return (<React.Fragment key={j}>
                                            {j !== 0 && <Grid item xs={2} md={2} lg={2}>
                                            </Grid>}
                                            {j === 0 && <Grid item xs={2} md={2} lg={2}>
                                                <Typography variant="h3">{t("pci_perennial_tree_list_harvest")}
                                                    <Tooltip title={<Trans i18nKey={"pci_perennial_tree_list_harvest_info"}></Trans>}>
                                                        <InfoIcon sx={{
                                                            fontSize: 'medium',
                                                            pl: 0.5,
                                                        }} />
                                                    </Tooltip>
                                                </Typography>
                                                <Typography variant="subtitle3">{t("pci_perennial_tree_list_harvest_desc")}</Typography>
                                            </Grid>}
                                            <Grid item xs={3} md={3} lg={3}>
                                                <FormControl fullWidth>
                                                    <InputLabel shrink={hValue?.date} size='small' id="harvest-month-label">{t("pci_perennial_tree_list_harvest_month")}</InputLabel>
                                                    <Select

                                                        name="date"
                                                        value={hValue.date}
                                                        disabled={!val.species || !allowEditing}
                                                        size="small"
                                                        labelId="harvest-month-label"
                                                        id="harvest-month-select"
                                                        label={t("pci_perennial_tree_list_harvest_month")}
                                                        onChange={(e) => handlePerennialsHarvestRowChange(e, i, j, year, dispatch, parcelid)}
                                                    >
                                                        {monthArray2.map((item) => {
                                                            return <MenuItem
                                                                value={tabValue + item.value}
                                                                key={tabValue + item.value}>
                                                                {t(item.text)}{" "}{tabValue}
                                                            </MenuItem>
                                                        })}
                                                        <MenuItem
                                                            value="-"
                                                            key="-">
                                                            -
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>

                                            </Grid>
                                            <Grid item xs={3} md={3} lg={3}>
                                                <FormControl fullWidth>
                                                    <InputLabel shrink={hValue?.type} size='small' id="harvest-type-label">{t("pci_perennial_tree_list_harvest_type")}</InputLabel>
                                                    <Select
                                                        error={interdependentError(hValue.type, interdependentHarvestArray)}
                                                        name="type"
                                                        value={hValue.type}
                                                        size="small"
                                                        labelId="harvest-type-label"
                                                        id="harvest-type-select"
                                                        label={t("pci_perennial_tree_list_harvest_type")}
                                                        disabled={hValue.date === "-" || !hValue.date || !allowEditing}
                                                        onChange={(e) => handlePerennialsHarvestRowChange(e, i, j, year, dispatch, parcelid)}
                                                    >
                                                        {residueTypeArray.map(type => <MenuItem key={type.value} value={type.value}>{t(type.text)}</MenuItem>)}
                                                    </Select>
                                                </FormControl>

                                            </Grid>
                                            <Grid item xs={2} md={2} lg={2}>
                                                <FormControl fullWidth>
                                                    <NumericInput
                                                        max={maxDynamic.harvestT * parcelArea}
                                                        decimalScale={3}
                                                        name="amount"
                                                        error={interdependentError(hValue.amount, interdependentHarvestArray)}
                                                        disabled={hValue.date === "-" || !hValue.date}
                                                        value={hValue.amount !== -9999 ? hValue.amount : ''}
                                                        fullWidth
                                                        placeholder={t("pci_perennial_tree_list_harvest_type_qty")}
                                                        label={t("pci_perennial_tree_list_harvest_type_qty")}
                                                        onChange={(e) => handlePerennialsHarvestRowChange(e, i, j, year, dispatch, parcelid)} />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={2} md={2} lg={2}>
                                                <FormControl fullWidth>
                                                    <Select
                                                        name="units"
                                                        value={hValue.units}
                                                        size="small"
                                                        error={interdependentError(hValue.units, interdependentHarvestArray)}
                                                        disabled={hValue.date === "-" || !hValue.date || !allowEditing}
                                                        labelId="harvest-quantity-units-label"
                                                        id="harvest-quantity-units-select"
                                                        onChange={(e) => handlePerennialsHarvestRowChange(e, i, j, year, dispatch, parcelid)}
                                                    >
                                                        <MenuItem key="tonnes - dry" value="tonnes - dry">{t("pci_perennial_tree_list_harvest_fresh_dry_2")}</MenuItem>
                                                        <MenuItem key="tonnes - fresh" value="tonnes - fresh">{t("pci_perennial_tree_list_harvest_fresh_dry_1")}</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </React.Fragment>)
                                    })}
                                    <Grid item xs={2} md={2} lg={2}>

                                    </Grid>
                                    <Grid item style={{ paddingTop: 8 }} marginBottom={1} xs={4} md={4} lg={4}>
                                        {allowEditing && <span onClick={(e) => addNewPerennialHarvest(e, i, year, dispatch, parcelid)} style={{ color: "#409781", fontWeight: 'bold', cursor: 'pointer', fontSize: 14 }}>+ {t("pci_perennial_tree_list_harvest_fresh_add")}</span>}
                                    </Grid>
                                    <Grid item xs={6} md={6} lg={6}>

                                    </Grid>

                                    {val?.residue.map((rValue, j) => {
                                        const interdependentResidueArray = [rValue.date, rValue.type, rValue.amount]
                                        return (<React.Fragment key={j}>
                                            {j !== 0 && <Grid item xs={2} md={2} lg={2}>
                                            </Grid>}
                                            {j === 0 && <Grid item xs={2} md={2} lg={2}>
                                                <div>
                                                    <Typography variant="h3">{t("pci_perennial_tree_list_residue")}
                                                        <Tooltip title={<Trans i18nKey={"pci_perennial_tree_list_residue_info"}></Trans>}>
                                                            <InfoIcon sx={{
                                                                fontSize: 'medium',
                                                                pl: 0.5,
                                                            }} />
                                                        </Tooltip>
                                                    </Typography>
                                                    <Typography variant="subtitle3">{t("pci_perennial_tree_list_residue_desc")}</Typography>
                                                </div>
                                            </Grid>}
                                            <Grid item xs={3} md={3} lg={3}>
                                                <FormControl fullWidth>
                                                    <InputLabel shrink={rValue?.date} size='small' id="residue-month-label">{t("pci_perennial_tree_list_residue_month")}</InputLabel>
                                                    <Select
                                                        error={interdependentError(rValue.date, interdependentResidueArray)}
                                                        name="date"
                                                        disabled={!val.species || !allowEditing}
                                                        value={rValue.date}
                                                        size="small"
                                                        labelId="residue-month-label"
                                                        id="residue-month-select"
                                                        label={t("pci_perennial_tree_list_residue_month")}
                                                        onChange={(e) => handlePerennialsResidueRowChange(e, i, j, year, dispatch, parcelid)}
                                                    >
                                                        {monthArray3.map((item) => {
                                                            return <MenuItem
                                                                value={tabValue + item.value}
                                                                key={tabValue + item.value}>
                                                                {t(item.text)}{" "}{tabValue}
                                                            </MenuItem>
                                                        })}

                                                        <MenuItem
                                                            value="-"
                                                            key="-">
                                                            -
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={3} md={3} lg={3}>
                                                <FormControl fullWidth>
                                                    <InputLabel shrink={rValue?.type} size='small' id="residue-type-label">{t("pci_perennial_tree_list_residue_dd_type")}</InputLabel>
                                                    <Select
                                                        error={interdependentError(rValue.type, interdependentResidueArray)}
                                                        name="type"
                                                        value={rValue.type}
                                                        size="small"
                                                        labelId="residue-type-label"
                                                        id="residue-type-select"
                                                        label={t("pci_perennial_tree_list_residue_dd_type")}
                                                        disabled={rValue.date === "-" || !rValue.date || !allowEditing}
                                                        onChange={(e) => handlePerennialsResidueRowChange(e, i, j, year, dispatch, parcelid)}
                                                    >
                                                        {residueTypeArray.map(type => <MenuItem key={type.value} value={type.value}>{t(type.text)}</MenuItem>)}

                                                    </Select>
                                                </FormControl>

                                            </Grid>
                                            <Grid item xs={2} md={2} lg={2}>
                                                <FormControl fullWidth>
                                                    <NumericInput
                                                        error={interdependentError(rValue.amount, interdependentResidueArray)}
                                                        max={maxDynamic.residueT * parcelArea}
                                                        decimalScale={3}
                                                        name="amount"
                                                        disabled={rValue.date === "-" || !rValue.date}
                                                        value={rValue.amount !== -9999 ? rValue.amount : ''}
                                                        fullWidth
                                                        placeholder={t("pci_perennial_tree_list_residue_type_qty")}
                                                        label={t("pci_perennial_tree_list_residue_type_qty")}
                                                        onChange={(e) => handlePerennialsResidueRowChange(e, i, j, year, dispatch, parcelid)} />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={2} md={2} lg={2}>
                                                <FormControl fullWidth>
                                                    <Select
                                                        name="units"
                                                        size="small"
                                                        value={rValue.units}
                                                        error={interdependentError(rValue.units, interdependentResidueArray)}
                                                        disabled={rValue.date === "-" || !rValue.date || !allowEditing}
                                                        labelId="residue-quantity-units-label"
                                                        id="residue-quantity-units-select"
                                                        onChange={(e) => handlePerennialsResidueRowChange(e, i, j, year, dispatch, parcelid)}
                                                    >
                                                        <MenuItem key="tonnes - dry" value="tonnes - dry">{t("pci_perennial_tree_list_residue_fresh_dry_2")}</MenuItem>
                                                        <MenuItem key="tonnes - fresh" value="tonnes - fresh">{t("pci_perennial_tree_list_residue_fresh_dry_1")}</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                        </React.Fragment>)

                                    })}
                                    <Grid item xs={2} md={2} lg={2}>

                                    </Grid>
                                    <Grid item marginBottom={2} style={{ paddingTop: 8 }} xs={2} md={2} lg={2}>
                                        {allowEditing && <span onClick={(e) => addNewPerennialResidue(e, i, year, dispatch, parcelid)} style={{ color: "#409781", fontWeight: 'bold', cursor: 'pointer', fontSize: 14 }}>+ {t("pci_perennial_tree_list_residue_fresh_add")}</span>}
                                    </Grid>
                                    <Grid item xs={8} md={8} lg={8}></Grid>
                                </Grid>
                            </Grid>

                        </Collapse>

                        <TrailingLine />

                    </React.Fragment>
                }
                )
            }
            {allowEditing && <span onClick={addNewPerennialTreeAndCrop} style={{ color: "#409781", fontWeight: 'bold', cursor: 'pointer', fontSize: 16 }}>+ {t("pci_perennial_tree_list_add_new")}</span>}

        </Grid >
    )
}

export default PerennialSection