import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Grid, Typography } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';



const ThanksForSubmit = ({ confirmPopUpOpen, setConfirmPopUpOpen }) => {
    const { t } = useTranslation();
    return (

        <Grid>
            <Dialog
                PaperProps={{ sx: { maxWidth: 'revert', width: '700px', textAlign: 'center' } }}
                open={confirmPopUpOpen}
                onClose={() => setConfirmPopUpOpen(false)}
                aria-labelledby="alert-dialog-confirm-popup"
                aria-describedby="alert-dialog-confirm-popup-description"
            >
                <CloseRoundedIcon sx={{ alignSelf: 'flex-end', padding: 2, color: '#707070', '&:hover': { cursor: 'pointer' } }} onClick={() => {
                    setConfirmPopUpOpen(false)
                }} />
                <DialogTitle id="alert-dialog-confirm-popup">
                    <Typography variant='h1'>{t("home_submit_popup_1")}</Typography>
                </DialogTitle>
                <DialogContent sx={{ marginBottom: 6 }}>
                    <DialogContentText id="alert-dialog-description" color='black'>
                        <Typography variant='subtitle1'>{t("home_submit_popup_2")}</Typography>
                    </DialogContentText>
                </DialogContent>

            </Dialog>
        </Grid>

    )
}

export default ThanksForSubmit