import { CREATE_FARM_SETTINGS, RETRIEVE_FARM_SETTINGS, UPDATE_FARM_SETTINGS, DELETE_FARM_SETTINGS } from "../actions/types";
const initialState = [];

function farmSettingsReducer(farmSettings = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case CREATE_FARM_SETTINGS:
            return [...farmSettings, payload];
        case RETRIEVE_FARM_SETTINGS:
            return payload;
        case UPDATE_FARM_SETTINGS:
            return farmSettings.map((setting) => {
                if (setting.id === payload.id) {
                    return {
                        ...setting, ...payload
                    };
                } else {
                    return setting;
                }
            });
        case DELETE_FARM_SETTINGS:
            return farmSettings.filter(({
                id
            }) => id !== payload.id);
        default:
            return farmSettings;
    }
};

export default farmSettingsReducer;