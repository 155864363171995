import HttpService from "../services/HttpService";


const sendMail = (data) => {
    return HttpService.getAxiosClient().post('/api/mail/', data);
};

const MailService = {
    sendMail,
};

export default MailService;

