import { RETRIEVE_FARM_AND_FARM_SETTINGS } from "../actions/types";
const initialState = [];

function farmAndFarmSettingsReducer(farmAndFarmSettings = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case RETRIEVE_FARM_AND_FARM_SETTINGS:
            return payload;
        default:
            return farmAndFarmSettings;
    }
};

export default farmAndFarmSettingsReducer;