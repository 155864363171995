import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
    Info as InfoIcon,
} from "@mui/icons-material";
import { Collapse, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, Tooltip, Typography } from '@mui/material';


import DropDownLabel from "../general/DropDownLabel";
import NumericInput from "../general/NumericInput";
import TrailingLine from "../general/TrailingLine";
import { handleFallowHarvestRowChange, handleFallowResidueRowChange, handleFallowRowChange } from "./handleRowChange";


import React, { useState } from "react";
import { useSelector } from "react-redux";
import { interdependentError } from '../general/Validations/fieldValueErrors';
import { max, maxDynamic } from "../general/Validations/minMaxValues";
import { addNewFallowHarvest } from './addNewEmptySection/addNewFallowHarvest';
import { addNewFallowResidue } from './addNewEmptySection/addNewFallowResidue';
import { addNewFallow } from "./addNewEmptySection/addNewFalow";
import { monthArray } from "./cropsArrays";
import { deleteFallow } from "./deleteSection";
import { getLastTwelveMonthsInclusive } from "./utils";





const FallowSection = ({ year, tabValue, parcelArea }) => {

    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    let { parcelid } = useParams();

    const [arrFallowCollapsibleButtonStatus, setArrFallowCollapsibleButtonStatus] = useState(Array(100).fill(true))

    const currentMonitoringDataObject = useSelector(state => state.currentMonitoringDataReducer);
    const currentFarmStatusReducer = useSelector(
        (state) => state.farmStatusReducer
    );
    const accountSettings = useSelector((state) => state.accountSettingsReducer);
    const isAdmin = accountSettings?.[0]?.role === 'admin'
    const allowEditing = currentFarmStatusReducer.allowEditing || isAdmin

    const toggleFallowCollapsibleState = (toggleIndex) => {
        setArrFallowCollapsibleButtonStatus(
            arrFallowCollapsibleButtonStatus.map((v, i) => (i === toggleIndex ? !v : v))
        );
    }

    return (
        <div>
            <Typography variant="h2" marginBottom={1}>{t("pci_pastures_fallow_title")}{' ('}{year}{')'}</Typography>
            <Typography variant="subtitle2">{t("pci_pastures_fallow_subtitle")}{year} <Tooltip sx={{ paddingLeft: 1 }} title={<>{t("pci_pastures_fallow_info")}</>}>
                <InfoIcon sx={{
                    fontSize: 'medium',
                    pl: 0.5
                }} />
            </Tooltip>
            </Typography>
            <Grid container>
                {currentMonitoringDataObject?.yearlyFarmData.find(yFD => yFD.year === year)?.parcelLevelData.find(eleParcelData => eleParcelData?.parcelFixedValues?.parcelID === parcelid) &&
                    currentMonitoringDataObject?.yearlyFarmData.find(yFD => yFD.year === year)?.parcelLevelData.find(eleParcelData => eleParcelData?.parcelFixedValues?.parcelID === parcelid)?.yearParcelData?.fallow?.map((val, i) => {

                        return <div key={i}>
                            <Grid container marginBottom={1} marginTop={1}>
                                {<Grid container spacing={2}>
                                    <Grid item xs={9} md={9} lg={9}>
                                        <DropDownLabel color={val?.type ? 'black' : 'grey'} onClick={() => toggleFallowCollapsibleState(i)} status={arrFallowCollapsibleButtonStatus[i]} text={val.type ? t(val.type) : t("pci_pastures_fallow_title")} />
                                    </Grid>

                                    {allowEditing && <Grid item xs={3} md={3} lg={3}>
                                        <div style={{ textAlign: 'right' }}>
                                            <span onClick={(e) => deleteFallow(e, i, year, dispatch, parcelid)} style={{ color: "#db6160", fontWeight: 'bold', marginRight: '20px', cursor: 'pointer' }}>{t("pci_pastures_fallow_period_delete")}</span>
                                        </div>
                                    </Grid>}

                                </Grid>}
                            </Grid>
                            <Collapse in={arrFallowCollapsibleButtonStatus[i]} timeout="auto"
                            >
                                <Grid marginLeft={1} marginTop={1} item xs={12} md={12} lg={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={2} md={2} lg={2}>
                                            <Typography variant="h3">{t("pci_pastures_fallow_period_desc")}
                                                <Tooltip sx={{ paddingLeft: 1 }} title={<Trans i18nKey={"pci_pastures_fallow_period_info"}></Trans>}>
                                                    <InfoIcon sx={{
                                                        fontSize: 'medium',
                                                        pl: 0.5,
                                                    }} />
                                                </Tooltip>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3} md={3} lg={3}>
                                            <FormControl fullWidth>
                                                <InputLabel size='small' shrink={val?.type}
                                                    id="fallow-list-label">{t("pci_pastures_fallow_period_type_dd")}</InputLabel>
                                                <Select
                                                    name="type"
                                                    value={val.type}
                                                    disabled={!allowEditing}
                                                    size="small"
                                                    labelId="fallow-list-label"
                                                    id="fallow-list-select"
                                                    label={t("pci_pastures_fallow_period_type_dd")}
                                                    onChange={(e) => handleFallowRowChange(e, i, year, dispatch, parcelid)}
                                                >
                                                    <MenuItem key="productive fallow" value="productive fallow">{t("pci_pastures_fallow_period_type_dd_1")}</MenuItem>
                                                    <MenuItem key="bare soil" value="bare soil">{t("pci_pastures_fallow_period_type_dd_2")}</MenuItem>
                                                    <MenuItem
                                                        value=""
                                                        key="-">
                                                        -
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>

                                        </Grid>
                                        <Grid item xs={3} md={3} lg={3}>
                                            <FormControl fullWidth>
                                                <NumericInput max={max.percent} name="percentArea"
                                                    error={interdependentError(val.percentArea, [val.type]) && val.type === "bare soil"}
                                                    disabled={val.type === "productive fallow" || !val.type}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                                    }}
                                                    value={val.percentArea === -9999 ? '' : val.percentArea} fullWidth label={t("pci_percent_parcel")} onChange={(e) => handleFallowRowChange(e, i, year, dispatch, parcelid)} />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4}>

                                        </Grid>

                                        <Grid item xs={2} md={2} lg={2}>
                                            <Typography variant="h3">{t("pci_pastures_fallow_period_cycle")}
                                                <Tooltip sx={{ paddingLeft: 1 }} title={<>{t("pci_pastures_fallow_period_cycle_info_1")}<br /><br />{t("pci_pastures_fallow_period_cycle_info_2")}<br /><br />{t("pci_pastures_fallow_period_cycle_info_3")}<br /></>}>
                                                    <InfoIcon sx={{
                                                        fontSize: 'medium',
                                                        pal: 0.5,
                                                    }} />
                                                </Tooltip>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3} md={3} lg={3}>
                                            <FormControl fullWidth>
                                                <InputLabel size='small' shrink={val?.endDate} id="fallow-end-month-label">{t("pci_pastures_fallow_period_end_month")}</InputLabel>
                                                <Select
                                                    name="endDate"
                                                    error={interdependentError(val.endDate, [val.type])}
                                                    value={val.endDate}
                                                    disabled={!val.type || !allowEditing}
                                                    size="small"
                                                    labelId="fallow-end-month-label"
                                                    id="fallow-end-month-select"
                                                    label={t("pci_pastures_fallow_period_end_month")}
                                                    onChange={(e) => handleFallowRowChange(e, i, year, dispatch, parcelid)}
                                                >
                                                    {monthArray.map((item) => {
                                                        return <MenuItem
                                                            value={tabValue + item.value}
                                                            key={tabValue + item.value}>
                                                            {t(item.text)}{" "}{tabValue}
                                                        </MenuItem>
                                                    })}

                                                </Select>
                                            </FormControl>

                                        </Grid>
                                        <Grid item xs={3} md={3} lg={3}>
                                            <FormControl fullWidth>
                                                <InputLabel shrink={val?.endDate} size='small' id="fallow-start-month-label">{t("pci_pastures_fallow_period_start_month")}</InputLabel>
                                                <Select
                                                    name="startDate"
                                                    error={interdependentError(val.startDate, [val.type])}
                                                    disabled={!val.endDate || !allowEditing}
                                                    value={val.startDate}
                                                    size="small"
                                                    labelId="fallow-start-month-label"
                                                    id="fallow-start-month-select"
                                                    label={t("pci_pastures_fallow_period_start_month")}
                                                    onChange={(e) => handleFallowRowChange(e, i, year, dispatch, parcelid)}
                                                >
                                                    {
                                                        val.endDate && getLastTwelveMonthsInclusive(String(val.endDate)).map((fallowEndDateValue, ix) =>
                                                            <MenuItem
                                                                value={String(fallowEndDateValue).concat("-01")}
                                                                key={String(fallowEndDateValue).concat("-01")}>
                                                                {i18n?.language === "en_EN" && new Date(fallowEndDateValue).toLocaleDateString("en-US", { year: "numeric", month: "long" })}
                                                                {i18n?.language === "es_ES" && new Date(fallowEndDateValue).toLocaleDateString("es-ES", { year: "numeric", month: "long" })[0].toUpperCase() + new Date(fallowEndDateValue).toLocaleDateString("es-ES", { year: "numeric", month: "long" }).replace('de ', '').slice(1)}
                                                                {i18n?.language === "pt_PT" && new Date(fallowEndDateValue).toLocaleDateString("pt-PT", { year: "numeric", month: "long" })[0].toUpperCase() + new Date(fallowEndDateValue).toLocaleDateString("pt-PT", { year: "numeric", month: "long" }).replace('de ', '').slice(1)}
                                                            </MenuItem>
                                                        )
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4}>
                                        </Grid>
                                        {val.harvest.map((hValue, j) => {
                                            return (<React.Fragment key={j}>
                                                {j !== 0 && <Grid item xs={2} md={2} lg={2}>
                                                </Grid>}
                                                {j === 0 && <Grid item xs={2} md={2} lg={2}>
                                                    <div>
                                                        <Typography variant="h3">{t("pci_pastures_fallow_period_harvest")}
                                                            <Tooltip sx={{ paddingLeft: 1 }} title={<>{t("pci_pastures_fallow_period_harvest_info_1")}<br /><br />{t("pci_pastures_fallow_period_harvest_info_2")}<br /><br />{t("pci_pastures_fallow_period_harvest_info_3")}<br /></>}>
                                                                <InfoIcon sx={{
                                                                    fontSize: 'medium',
                                                                    padding: '0px 0px 0px 8px',
                                                                }} />
                                                            </Tooltip>
                                                        </Typography>
                                                        <Typography variant="subtitle3">{t("pci_pastures_fallow_period_harvest_desc")}</Typography>
                                                    </div>
                                                </Grid>}
                                                <Grid item xs={3} md={3} lg={3}>
                                                    <FormControl fullWidth>
                                                        <InputLabel shrink={hValue?.type} size='small' id="fallow-harvest-type-label">{t("pci_pastures_fallow_period_harvest_type_dd")}</InputLabel>
                                                        <Select
                                                            name="type"
                                                            value={hValue.type}
                                                            disabled={val.type === "bare soil" || !val.type || !allowEditing}
                                                            size="small"
                                                            labelId="fallow-harvest-type-label"
                                                            id="fallow-harvest-type-select"
                                                            label={t("pci_pastures_fallow_period_harvest_type_dd")}
                                                            onChange={(e) => handleFallowHarvestRowChange(e, i, j, year, dispatch, parcelid)}
                                                        >
                                                            <MenuItem
                                                                value="leaves, stalks, herbs (non-woody)"
                                                                key="leaves, stalks, herbs (non-woody)">
                                                                {t("pci_pastures_fallow_period_harvest_type_dd_1")}
                                                            </MenuItem>
                                                            <MenuItem
                                                                value="grass"
                                                                key="grass">
                                                                {t("pci_pastures_fallow_period_harvest_type_dd_2")}
                                                            </MenuItem>
                                                            <MenuItem
                                                                value="-"
                                                                key="-">
                                                                -
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={3} md={3} lg={3}>
                                                    <FormControl fullWidth>
                                                        <NumericInput
                                                            error={interdependentError(hValue.amount, [hValue.type])}
                                                            max={maxDynamic.harvestT * parcelArea}
                                                            decimalScale={3}
                                                            name="amount"
                                                            inputMode='decimal'
                                                            size="small"
                                                            disabled={val.type === "bare soil" || hValue.type === "-" || !hValue.type}
                                                            value={hValue.amount !== -9999 ? hValue.amount : ''} fullWidth placeholder={t("pci_pastures_fallow_period_harvest_type_qty")} label={t("pci_pastures_fallow_period_harvest_type_qty")} onChange={(e) => handleFallowHarvestRowChange(e, i, j, year, dispatch, parcelid)} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={2} md={2} lg={2}>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            name="units"
                                                            value={hValue.units}
                                                            error={interdependentError(hValue.units, [hValue.type])}
                                                            disabled={val.type === "bare soil" || hValue.type === "-" || !hValue.type || !allowEditing}
                                                            size="small"
                                                            labelId="fallow-harvest-quantity-units-label"
                                                            id="fallow-harvest-quantity-units-select"
                                                            onChange={(e) => handleFallowHarvestRowChange(e, i, j, year, dispatch, parcelid)}
                                                        >
                                                            <MenuItem key="tonnes - dry" value="tonnes - dry">{t("pci_pastures_fallow_period_harvest_type_qty_dry")}</MenuItem>
                                                            <MenuItem key="tonnes - fresh" value="tonnes - fresh">{t("pci_pastures_fallow_period_harvest_type_qty_fresh")}</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={2} md={2} lg={2}></Grid>
                                            </React.Fragment>)
                                        })}
                                        <Grid style={{ marginTop: "-10px" }} item xs={2} md={2} lg={2}>
                                        </Grid>
                                        <Grid item marginBottom={2} style={{ paddingTop: 8 }} xs={2} md={2} lg={2}>
                                            {allowEditing && <span onClick={(e) => addNewFallowHarvest(e, i, year, dispatch, parcelid)} style={{ color: "#409781", fontWeight: 'bold', cursor: 'pointer', fontSize: 14 }}>+ {t("pci_pastures_fallow_period_harvest_add_harvest")}</span>}
                                        </Grid>
                                        <Grid item xs={8} md={8} lg={8}>
                                        </Grid>
                                        {val?.residue.map((rValue, j) => {
                                            return (<React.Fragment key={j}>
                                                {j !== 0 && <Grid item xs={2} md={2} lg={2}>
                                                </Grid>}
                                                {j === 0 && <Grid item xs={2} md={2} lg={2}>
                                                    <div>
                                                        <Typography variant="h3">{t("pci_pastures_fallow_period_residue")}
                                                            <Tooltip sx={{ paddingLeft: 1 }} title={<>{t("pci_pastures_fallow_period_residue_info_1")}<br /><br />{t("pci_pastures_fallow_period_residue_info_2")}<br /><br />{t("pci_pastures_fallow_period_residue_info_3")}<br /></>}>
                                                                <InfoIcon sx={{
                                                                    fontSize: 'medium',
                                                                    padding: '0px 0px 0px 8px',
                                                                }} />
                                                            </Tooltip>
                                                        </Typography>
                                                        <Typography variant="subtitle3">{t("pci_pastures_fallow_period_residue_desc")}</Typography>
                                                    </div>
                                                </Grid>}
                                                <Grid item xs={3} md={3} lg={3}>
                                                    <FormControl fullWidth>
                                                        <InputLabel shrink={rValue?.type} size='small' id="fallow-residue-type-label">{t("pci_pastures_fallow_period_residue_type_dd")}</InputLabel>
                                                        <Select
                                                            name="type"
                                                            value={rValue.type}
                                                            size="small"
                                                            disabled={val.type === "bare soil" || !val.type || !allowEditing}
                                                            labelId="fallow-residue-type-label"
                                                            id="fallow-residue-type-select"
                                                            label={t("pci_pastures_fallow_period_residue_type_dd")}
                                                            onChange={(e) => handleFallowResidueRowChange(e, i, j, year, dispatch, parcelid)}
                                                        >
                                                            <MenuItem
                                                                value="leaves, stalks, herbs (non-woody)"
                                                                key="leaves, stalks, herbs (non-woody)">
                                                                {t("pci_pastures_fallow_period_residue_type_dd_1")}
                                                            </MenuItem>
                                                            <MenuItem
                                                                value="grass"
                                                                key="grass">
                                                                {t("pci_pastures_fallow_period_residue_type_dd_2")}
                                                            </MenuItem>
                                                            <MenuItem
                                                                value="-"
                                                                key="-">
                                                                -
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={3} md={3} lg={3}>
                                                    <FormControl fullWidth>
                                                        <NumericInput
                                                            error={interdependentError(rValue.amount, [rValue.type])}
                                                            max={maxDynamic.residueT * parcelArea}
                                                            decimalScale={3}
                                                            name="amount"
                                                            inputMode='decimal'
                                                            disabled={val.type === "bare soil" || rValue.type === "-" || !rValue.type}
                                                            value={rValue.amount !== -9999 ? rValue.amount : ''} fullWidth placeholder={t("pci_pastures_fallow_period_residue_qty")} label={t("pci_pastures_fallow_period_residue_qty")} onChange={(e) => handleFallowResidueRowChange(e, i, j, year, dispatch, parcelid)} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={2} md={2} lg={2}>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            name="units"
                                                            value={rValue.units}
                                                            error={interdependentError(rValue.units, [rValue.type])}
                                                            disabled={val.type === "bare soil" || rValue.type === "-" || !rValue.type || !allowEditing}
                                                            size="small"
                                                            labelId="fallow-residue-quantity-units-label"
                                                            id="fallow-residue-quantity-units-select"
                                                            onChange={(e) => handleFallowResidueRowChange(e, i, j, year, dispatch, parcelid)}
                                                        >
                                                            <MenuItem key="tonnes - dry" value="tonnes - dry">{t("pci_pastures_fallow_period_residue_qty_dry")}</MenuItem>
                                                            <MenuItem key="tonnes - fresh" value="tonnes - fresh">{t("pci_pastures_fallow_period_residue_qty_fresh")}</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={2} md={2} lg={2}></Grid>
                                            </React.Fragment>)
                                        })}
                                        <Grid item xs={2} md={2} lg={2}>

                                        </Grid>
                                        <Grid item marginBottom={2} style={{ paddingTop: 8 }} xs={2} md={2} lg={2}>
                                            {allowEditing && <span onClick={(e) => addNewFallowResidue(e, i, year, dispatch, parcelid)} style={{ color: "#409781", fontWeight: 'bold', cursor: 'pointer', fontSize: 14 }}>+ {t("pci_pastures_fallow_period_residue_add_new")}</span>}
                                        </Grid>
                                        <Grid item xs={8} md={8} lg={8}></Grid>
                                    </Grid>
                                </Grid>
                            </Collapse>
                            <TrailingLine />
                        </div>
                    }
                    )
                }
            </Grid>

            {allowEditing && <p onClick={() => addNewFallow(year, dispatch, parcelid)} style={{ color: "#409781", fontWeight: 'bold', fontSize: '16px', cursor: 'pointer' }}>+ {t("pci_pastures_fallow_period_add_new")}</p>}

        </div>
    )
}

export default FallowSection