import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { GoBackButton } from '../../styles';


const BackHomeComponent = ({ adminMaps }) => {

    const { t } = useTranslation();

    return (
        <Box marginTop='80px' marginLeft={2.8} display='flex' alignItems='center'>
            {adminMaps ?
                <GoBackButton>
                    <ArrowBackIosRoundedIcon />
                    <Typography sx={{ fontWeight: 800, paddingLeft: 2 }}>{t("back_to_home")}</Typography>
                </GoBackButton> :
                <GoBackButton component={Link} to='/carbonplus'>
                    <ArrowBackIosRoundedIcon />
                    <Typography sx={{ fontWeight: 800, paddingLeft: 2 }}>{t("back_to_home")}</Typography>
                </GoBackButton>
            }
        </Box>
    )
}

export default BackHomeComponent;