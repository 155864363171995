import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  FirstPage as FirstPageIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage as LastPageIcon
} from "@mui/icons-material";
import {
  Backdrop,
  Box,
  IconButton,
  Snackbar,
  Typography
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import "../../css/LiveStockPopUp.css";

import PropTypes from "prop-types";


import { findSettingsByFarmerPrimaryId } from "../../actions/farmSettings";
import { setFarmStatus } from "../../actions/farmStatus";
import { updateFarmland } from "../../actions/farmlands";




import ParcelListItem from "./ParcelListItem";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const ParcelListUnderMap = () => {

  const currentMonitoringDataObject = useSelector(
    (state) => state.currentMonitoringDataReducer
  );

  const accountSettings = useSelector((state) => state.accountSettingsReducer);
  const isAdmin = accountSettings?.[0]?.role === 'admin'

  const [state, setState] = useState({
    snackOpen: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, snackOpen } = state;
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleSnackbarClose = () => {
    setState({ vertical: "top", horizontal: "center", snackOpen: false });
  };

  const currentFarmDataObject = useSelector(
    (state) => state.currentFarmDataReducer
  );
  const currentFarmStatusReducer = useSelector(
    (state) => state.farmStatusReducer
  );

  const dispatch = useDispatch();

  const [allowEditing, setAllowEditing] = useState(true);

  useEffect(() => {
    if (!isAdmin) {
      dispatch(
        updateFarmland(currentFarmDataObject._id, currentFarmDataObject)
      ).then((res) => {
        console.log('Farm data updated:', res);
      })
        .catch((error) => {
          console.error('Error during updating farm data in ParcelList:', error);
        });
    }
  }, [currentFarmDataObject?.landUse?.landUseSummaryOrPractices]);

  const arrDocumentAndYearsMapping = {
    2018: 0,
    2019: 1,
    2020: 2,
    2021: 3,
    2022: 4,
    2023: 5,
    2024: 6,
    2025: 7,
    2026: 8,
    2027: 9,
    2028: 10,
  }

  const fetchFarmStatus = () => {
    if (!isAdmin) {
      dispatch(findSettingsByFarmerPrimaryId(currentFarmDataObject._id)).then(
        (res) => {
          let editMyFarm = false;
          if (res[0]?.allowEditing) {
            editMyFarm = true;
          }
          let tmpFarmStatusData = {
            status: res[0]?.farmStatus,
            allowEditing: editMyFarm,
            editFarm: editMyFarm,
          };
          return dispatch(setFarmStatus(tmpFarmStatusData))
        }
      ).then((res2) => {
        console.log('Farm status set:', res2);
      })
        .catch((error) => {
          console.error('Error during setting farm status in ParcelList:', error);
        });;
    }
    setAllowEditing(
      currentFarmStatusReducer.allowEditing && currentFarmStatusReducer.editFarm
    );
  };

  const [showLoader, setShowLoader] = useState(false);
  const [saveCurrentDataFlag, setSaveCurrentDataFlag] = useState(false);

  const handleBackdropClose = () => {
    setSaveCurrentDataFlag(false);
  };

  const handleBackdropClickCaptureEvent = (e) => {
    e.stopPropagation();
    return false;
  };


  useEffect(() => {
    fetchFarmStatus();
  }, [
    currentFarmStatusReducer.allowEditing,
    currentFarmStatusReducer.editFarm,
  ]);


  return (
    <div>
      {showLoader && (
        <Backdrop
          onClickCapture={(e) => handleBackdropClickCaptureEvent(e)}
          style={{ color: "#fff", zIndex: 99999 }}
          open={saveCurrentDataFlag}
          onClick={handleBackdropClose}
        >
          <div style={{ fontSize: 20, fontWeight: 600, color: "white", marginTop: "20px" }}>
            Saving Data. Please Wait
          </div>
        </Backdrop>
      )}
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={snackOpen}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        key={vertical + horizontal}
      />
      <Box
        sx={{
          width: 0.99,
        }}
      >
        {currentMonitoringDataObject &&
          currentMonitoringDataObject?.yearlyFarmData?.length > 0 &&
          currentMonitoringDataObject?.yearlyFarmData[
            arrDocumentAndYearsMapping[
            currentMonitoringDataObject.projectStartYear
            ]
          ].parcelLevelData.map((obj, i) => {
            return (
              <ParcelListItem obj={obj} i={i} key={i} />
            );
          })}


      </Box>


    </div>
  );
};

export default ParcelListUnderMap;
