import {
    Box,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import React from "react";
import "../../css/FormFields.css";
import NumericInput from "../general/NumericInput";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ServiceCategoryArray, externalServicesArray } from '../general/dropdownValueArrays';
import { interdependentError } from "../general/Validations/fieldValueErrors";
import { getTotalAreaOfTheFarm } from "../MapAndParcels/getTotalAreaOfTheFarm";

const YearlyServices = ({ setYearServices, yearServices, year }) => {
    const { t } = useTranslation();

    const currentMonitoringYearsObject = useSelector(state => state.monitoringYearsTrackerReducer);
    const currentFarmStatusReducer = useSelector((state) => state.farmStatusReducer);
    const currentMonitoringDataObject = useSelector((state) => state.currentMonitoringDataReducer);

    const allYears = currentMonitoringYearsObject.allMonitoringYears
    const accountSettings = useSelector((state) => state.accountSettingsReducer);
    const isAdmin = accountSettings?.[0]?.role === 'admin'
    const allowEditing = currentFarmStatusReducer?.allowEditing || isAdmin
    const totalAreaOfTheFarm = getTotalAreaOfTheFarm(currentMonitoringDataObject)

    const newService = {
        service: "",
        area: {
            amount: -9999,
            units: "hectares",
        },
        serviceDetail: "",
        serviceCategory: "",
    };

    return (<>
        {allYears.includes(year) && (
            <Stack marginTop={1} marginLeft={2} sx={{ minWidth: "250px" }}>
                {yearServices?.map((serv, i) => {
                    return <Box key={i} sx={{ mb: 3 }}>
                        <FormControl fullWidth size="small" sx={{ marginBottom: 1 }}>
                            <InputLabel id="external-service-dropdown">
                                {t("fu_ind_ext_serv")}
                            </InputLabel>
                            <Select
                                error={interdependentError(serv.service, [serv.serviceDetail, serv.area.amount])}
                                id="external-service"
                                value={serv?.service}
                                label="External service"
                                onChange={(e) => {
                                    const updatedServices = yearServices.map((item, index) => {
                                        if (index === i) {
                                            return { ...item, service: e.target?.value };
                                        }
                                        return item;
                                    });
                                    setYearServices(updatedServices);
                                }}
                            >
                                {externalServicesArray.map(serv => <MenuItem value={serv?.value} key={serv?.value}>
                                    {t(serv?.text)}
                                </MenuItem>)}

                            </Select>
                        </FormControl>

                        <TextField
                            error={interdependentError(serv.serviceDetail, [serv.service, serv.area.amount])}
                            size='small'
                            sx={{ mb: 1 }}
                            fullWidth id='yearServiceDetails'
                            disabled={!allowEditing || !serv.service}
                            label={t("fu_ind_ext_serv_describe")}
                            value={serv?.serviceDetail}

                            onChange={(e) => {
                                const updatedServiceDetail = yearServices.map((item, index) => {
                                    if (index === i) {
                                        return { ...item, serviceDetail: e.target?.value };
                                    }
                                    return item;
                                });
                                setYearServices(updatedServiceDetail);
                            }}></TextField>
                        <NumericInput
                            error={interdependentError(serv.area.amount, [serv.service, serv.serviceDetail])}
                            id='yearServiceArea'
                            disabled={!serv.service}
                            label={t("generic_area")}
                            value={serv?.area?.amount !== -9999 ? serv?.area?.amount : ''}
                            max={totalAreaOfTheFarm}
                            decimalScale={3}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">{t("generic_hectares")}</InputAdornment>,
                            }}
                            onChange={(e) => {
                                const updatedArea = yearServices.map((item, index) => {
                                    if (index === i) {
                                        return { ...item, area: { amount: e.target?.value, units: 'hectares' } };
                                    }
                                    return item;
                                });
                                setYearServices(updatedArea);
                            }}
                        />
                        {isAdmin && <FormControl fullWidth size="small" sx={{ mt: 1 }}>
                            <InputLabel id="external-servicecategory-dropdown">
                                Service Category
                            </InputLabel>
                            <Select
                                error={interdependentError(serv.serviceCategory, [serv.serviceDetail, serv.area.amount, serv.service])}
                                id="external-service"
                                value={serv?.serviceCategory}
                                label="External service category"
                                onChange={(e) => {
                                    const updatedServices = yearServices.map((item, index) => {
                                        if (index === i) {
                                            return { ...item, serviceCategory: e.target?.value };
                                        }
                                        return item;
                                    });
                                    setYearServices(updatedServices);
                                }}
                            >
                                {ServiceCategoryArray.map(ser => ser.category === serv?.service ? <MenuItem value={ser?.value} key={ser?.value}>
                                    {ser?.value}
                                </MenuItem> : "")}
                            </Select>
                        </FormControl>}
                        <Typography
                            sx={{
                                color: "#db6160",
                                cursor: "pointer",
                                mb: 2
                            }}
                            fontWeight={600}
                            fontSize={14}
                            component="span"
                            onClick={() => {
                                const updatedyearServices = [...yearServices];
                                updatedyearServices.splice(i, 1);
                                setYearServices(updatedyearServices);
                            }}
                        >
                            {t("generic_delete")}
                        </Typography>
                    </Box>
                }
                )}

                <Grid
                    item
                    style={{ marginBottom: "10px", display: "flex" }}
                >
                    <Typography
                        sx={{
                            color: "#3e9781",
                            cursor: "pointer",
                            marginRight: "5px",
                        }}
                        fontWeight={600}
                        fontSize={14}
                        component="span"
                        onClick={() => setYearServices([...yearServices, newService])}
                    >
                        {t("fu_ind_ext_serv_add")}
                    </Typography>

                </Grid>

            </Stack>
        )
        }
    </>
    )
}

export default YearlyServices