import React, { useEffect } from 'react'
import CookieConsent, {
  getCookieConsentValue,
  Cookies,
} from "react-cookie-consent";
import { initGA } from '../cookies/utils';

const FooterComponent = () => {

  const handleDeclineCookie = () => {
    //remove google analytics cookies
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
  };

  const handleAcceptCookie = () => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    }
  };

  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      handleAcceptCookie();
    }
  }, []);

  return (
    <div>
      <span style={{
        display: 'flex',
        height: '100%',
        flex: '1 1 auto',
        padding: '10px',
        flexShrink: '0',
        marginTop: "100px",
        textAlign: 'center',
        justifyContent: 'center',
        fontFamily: 'Gotham Book, Arial',
        fontSize: '12px'
      }}>

        {/*&copy; Climate Farmers UG 2022 &nbsp; All Rights Reserved &nbsp; <a href="https://www.climatefarmers.org/privacy-policy/" target="_blank" style={{textDecoration: "none", color: "black"}}>Privacy Policy</a> */}</span>
      <CookieConsent enableDeclineButton
        onAccept={handleAcceptCookie}
        onDecline={handleDeclineCookie}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </div>
  )
}

export default FooterComponent