export const translationEN = {
  home_welcome: "Welcome",
  home_your_c_app: "Your Carbon+ application",
  home_complete_onboarding: "Complete your onboarding",
  home_tell_us: "Tell us about your farm",
  home_provide: "Your farm basics",
  home_indicate: "Indicate fuel usage",
  home_plan: "Plan your livestock",
  home_map: "Map your fields and plan crops",
  home_share: "Share soil samples",
  home_submit: "Submit Application",
  home_getting: "Getting Started",
  home_dashboard: "Dashboard Manual",
  home_frequently: "Frequently Asked Questions (FAQ)",
  home_add_farm: "Add your Farm",
  home_carbon_values: "The Carbon Values Predicted for 10 years (tons)",
  home_project_terms: "Terms and Conditions",
  home_project_terms_agree: `By clicking "Submit for review" you agree to our `,
  home_project_terms_details: `  <p>
    <strong>Binding Data:</strong> The data you submit here is binding. You confirm that the information is accurate and complete to the best of your knowledge. You can request a download of all your data, as it is your responsibility to maintain a separate, accurate record of all submitted data for your reference in case of any technical discrepancies, data loss, and possible audit.
  </p>
  <p>
    <strong>Data Archiving and Retention:</strong> Your data will be electronically archived and retained for a minimum of five years following the end of the last aggregated project crediting period (until 2050). This retention period applies even if you are excluded from a crediting period and no longer working with Climate Farmers.
  </p>
  <p>
    <strong>Auditing and Compliance:</strong> You confirm that your data may be audited by Climate Farmers or an appointed third-party auditor, such as TÜV Rheinland. During an audit, additional information may be requested, and farm visits may be conducted to verify the data accuracy.
  </p>
  <p>
    <strong>Consent to Data Use:</strong> By submitting your data on this dashboard, you consent to its use for monitoring purposes related to the calculation and verification of Climate Farmers Carbon+ Credits as outlined above.
  </p>`,
  home_welcome_to_cf: "WELCOME TO CLIMATE FARMERS",
  farminfo_back: "Back",
  farminfo_tell: "Tell us a little about your farm",
  farminfo_please_enter: "Please enter the information about your farm/business.",
  farminfo_manager_fname: "Farm Manager's First Name",
  farminfo_manager_lname: "Farm Manager's Last Name",
  farminfo_owner_fname: "Land Owner's First Name",
  farminfo_owner_lname: "Land Owner's Last Name",
  farminfo_email: "Email Address",
  farminfo_farmsize: "Farm Size in HA",
  farminfo_street: "Farm Street & Street Number (if applicable)",
  farminfo_postal_code: "Postal Code",
  farminfo_coord_north: "Farm Coordinates (°N)",
  farminfo_coord_east: "Farm Coordinates (°E)",
  farminfo_country: "Country",
  farminfo_phone: "Telephone, includinf country code",
  farminfo_start_year: "Project Start Year",
  farminfo_start_month: "Project Start Month",
  farminfo_duration: "Project Duration",
  farminfo_challenges: "Challenges",
  farminfo_what_challenges: "What challenges are currently holding you back from implementing (more) regenerative practices on your farm?",
  farminfo_inv_barriers: "Investment Barriers",
  farminfo_inst_barriers: "Institutional Barriers",
  farminfo_tech_barriers: "Technological Barriers",
  farminfo_reg_trad: "Barriers due to regional traditions",
  farminfo_mgmt_pract: "Barriers evolving through existing land management practices",
  farminfo_eco_circ: "Barriers through ecological circumstances",
  farminfo_land_ownership: "Barriers connected to land ownership, inheritance or property rights",
  farminfo_no_labour: "No access to qualified labour",
  farminfo_no_coaching: "No access to qualified consultancy/coaching",
  farminfo_other: "Other - describe",
  farminfo_no_barriers: "No Barriers",
  farminfo_describe: "Describe",
  farminfo_add_more: "Add More",
  farminfo_remove: "Remove",
  farminfo_save: "Save",

  fu_dir_title: "Fuel Usage > Direct",
  fu_dir_description_1: "Indicate the direct fuel usage on your farm. This includes all fuel that you purchase and use on your property. However, exclude any fuel used on other farms or by external service providers working on your farm. ",
  fu_dir_description_2: "The rows and columns marked with * are mandatory.",
  fu_dir_quantity: "Quantity used",
  fuelusage_diesel: "Diesel",
  fu_section_subtitle_1: "Indicate the amount of liters used on your farm for each year *",
  fu_section_subtitle_2: "Upload invoices as supporting documentation for this amount (PDF or JPG)",
  fu_section_infobox: "Note that in the event that you are selected for an audit, you will be asked to provide a written declaration for the fuel usage in case you do not have invoices or other supporting documentation available.",
  fuelusage_petrol: "Petrol",
  fu_comment_description: "If you want to provide additional information or contextual details about the document provided, please comment below.",
  fu_comment_type_here: "Type here",

  generic_save_continue: "Save and Continue",
  general_add_comment: "Comment",
  general_edit_comment: "Comment",

  fu_indir_title: "Fuel Usage > Indirect",
  fu_indir_description_1: "Provide information about all fuel-consuming services performed by external service providers on your farm (e.g. sowing, tilling, haying, etc.). ",
  fu_indir_description_2: "If you have an invoice for the external service, you can upload it in the first section. If you don't have an invoice, simply indicate the type of service, describe the field operation, and specify the size of the area where the service was carried out in the second section below. ",
  fu_indir_description_3: "If you didn't use external services you can click on 'I don't have any external service' at the bottom of the page. ",
  fu_indir_description_4: "The columns marked with * are mandatory.",
  fu_ind_section_subtitle_1: "External Services with invoices",
  fu_ind_section_subtitle_2: "External Services with or without invoices",
  fu_ind_info_box_1: "Add all the external services for which you don't have an invoice by selecting the type of service, describing the specific field operation (e.g. grain harvesting, hay harvesting, hay baling), and specifying the size of the area the service was carried out on.",
  fu_ind_info_box_2: "Note that in the event that you are selected for an audit, you will be asked to provide a written declaration for the receipt of this service",
  fu_ind_ext_serv: "External service",
  fu_ind_ext_serv_1: "Fertilization",
  fu_ind_ext_serv_2: "Tillage",
  fu_ind_ext_serv_3: "Planting",
  fu_ind_ext_serv_4: "Weed control",
  fu_ind_ext_serv_5: "Harvesting",
  fu_ind_ext_serv_describe: "Describe field operation",
  fu_ind_ext_serv_add: "+ Add service",
  fu_ind_no_ext_serv: "I don't have any external service",

  generic_submit: "Submit",

  fuelusage_fueltype: "Fuel Type",
  fuelusage_amt: "Amount in Liters",
  fuelusage_other: "Other",
  fuelusage_estimates: "FUEL USAGE ESTIMATES FOR NEXT YEAR (OPTIONAL)",
  fuelusage_this_refers: "This refers to how much fuel you estimate you will be using in the future per year for agricultural activities, such as machineries",

  ls_mgmt: "LIVESTOCK MANAGEMENT",
  ls_here_we: "Here we need you to enter some details about the specific livestock management practices that are currently in place and also will be implemented on the farm for the next 10 years.",
  ls_add: "Add Livestock",
  ls_edit: "Edit Livestock",
  ls_view: "View Livestock",
  ls_del: "Delete Livestock",
  ls_no_data: "No data to display",
  ls_past: "PAST LIVESTOCK MANAGEMENT",
  ls_here_past: "Here we need you to enter details about the past practices of your livestock and describe them.",
  ls_how_many: "For how many years have you been using current livestock management?",
  ls_pls_desc: "Please describe what you did differently before that?",
  ls_current: "Current",
  ls_livestock: "Livestock",
  ls_livestock_mgmt_form: "Livestock Management Form",
  ls_reset: "RESET DATA",
  ls_year1: "YEAR 1",
  ls_year2: "YEAR 2",
  ls_year3: "YEAR 3",
  ls_year4: "YEAR 4",
  ls_year5: "YEAR 5",
  ls_year6: "YEAR 6",
  ls_year7: "YEAR 7",
  ls_year8: "YEAR 8",
  ls_year9: "YEAR 9",
  ls_year10: "YEAR 10",
  ls_autofill: "Would you like to auto-fill the details for the 3 baseline years?",
  ls_yes: "Yes",
  ls_no: "No",
  ls_species: "Species",
  ls_dairy: "Dairy Cattle",
  ls_beef: "Beef Cattle",
  ls_calves: "Calves (Less than 1 year)",
  ls_buff: "Buffalo",
  ls_sows: "Sows/Boars",
  ls_pigs: "Pigs",
  ls_pigglets: "Pigglets (Less than 1 year)",
  ls_sheep: "Sheep",
  ls_lambs: "Lambs (Less than 1 year)",
  ls_goats: "Goats",
  ls_goat_kids: "Goat Kids (Less than 1 year)",
  ls_poultry: "Poultry",
  ls_turkey: "Turkeys",
  ls_ducks: "Ducks",
  ls_horses: "Horses",
  ls_mules: "Mules/Asses",
  ls_camelids: "Camelids",
  ls_no_of_heads: "Number of Heads",
  ls_value: "Value should be greater than 0",
  ls_stabled_caged: "Stabled/Caged",
  ls_unplanned: "Unplanned Grazing",
  ls_adaptive: "Adaptive Multi-Paddock Grazing / Pastured",
  ls_grazing: "Grazing / Pastured Days Per Year",
  ls_rest: "Rest of the Year",
  ls_stabled: "Stabled",
  ls_slaughtered: "Slaughtered",
  ls_outdoors: "Outdoors Paddock",
  ls_sold: "Sold",
  ls_moved: "Moved Out",
  ls_manure: "Manure",
  ls_percent: "Percent",
  ls_onfield: "On Field",
  ls_composted: "Composted",
  ls_scattered: "Scattered Directly",
  ls_collection: "Collection and Disposal",
  ls_msg1: "Please select a row from the LiveStock Management Table. If there are no rows yet, add a new row by clicking Add Livestock.",
  ls_msg2: "Please select a single row from the LiveStock Management Table",
  ls_msg3: "Please select a row from the Livestock Management Table to delete. If there are no rows yet, add a new row by clicking Add Future Management Data.",
  ls_save: "SAVE",
  ls_close: "CLOSE",
  lu_map_location_msg_1: "The map's default location is pointing to your farm coordinates: ",
  lu_map_location_msg_2: "If you feel the location is incorrect, type the address/coordinates in the search bar or kindly go back to the Farm Info section to update your coordinates. Please note that only the farm coordinates in the Farm Info section are considered as default farm coordinates.",
  lu_search: "Search",
  lu_instrucns: "INSTRUCTIONS TO CREATE A PARCEL",
  lu_how_parcel: "How do I create a parcel?",
  lu_twoways: "There are two ways to create a parcel. They are as follows:",
  lu_importing: "Importing a KML File",
  lu_drawing: "Drawing a parcel on the Map",
  lu_importing_2: "Importing a KML File",
  lu_make_sure: "Make sure you have the KML file for your farm.",
  lu_click: "Click on the button Import a KML File",
  lu_select: "Select the KML file you wish to import",
  lu_drawing_parcel: "Drawing a parcel on the Map",
  lu_select_drawer: "Select the drawer icon on the top-left corner of the map",
  lu_identify: "Identify the parcel location in your farm and draw your parcel",
  lu_every: "Every parcel is identified by its own color.",
  lu_when: "When you click a parcel, a pop-up with the parcel name will appear.",
  lu_i_have: "I have a KMZ file. Can I import?",
  lu_no: "No. You cannot import a KMZ file. However, a KMZ file is a zipped version of a KML file. When you unzip the KMZ file in your computer, you will find the KML file in the uncompressed folder. We request you to upload the KML file.",
  lu_how_can: "How can I create a KML file of my farm?",
  lu_a_common: "A common practice to create the KML file is using Google Earth. We request you to install Google Earth on your desktop computer. Create your farm parcels and export the KML file that contains the parcels of your farm. In case, you are only able to export the KMZ file, please unzip the KMZ file and upload the KML file present in the uncompressed folder.",
  lu_close: "CLOSE",
  lu_parcel: "PARCEL TEMPLATES",
  lu_if_you: "If you have practices or crops that you apply on several fields you can create and name templates here and later import them to your parcels.",
  lu_create_new: "CREATE NEW TEMPLATE",
  lu_actions: "Actions",
  lu_temp: "Template Name",
  lu_rows_per_page: "Rows per page",
  temp_parcel_template: "Parcel Template",
  temp_template_name: "Template Name",
  temp_land_use: "Land Use Type",
  temp_silvopasture: "Silvopasture",
  temp_arable: "Arable Crops",
  temp_grass: "Grasslands",
  temp_agro: "Agroforestry",
  temp_forest: "Forestry",
  temp_type_1: "Type of Trees",
  temp_quercus: "Quercus",
  temp_olea: "Olea europea",
  temp_other: "Other",
  temp_tree_name_1: "Tree Name (if not present)",
  temp_avg_1: "Average Number of Trees per hectare",
  temp_tree_avg_1: "Tree average DBH in cm (diameter at breast height)",
  temp_tree_name_2: "Tree Name (if not present)",
  temp_avg_2: "Average Number of Trees per hectare",
  temp_tree_avg_2: "Tree average DBH in cm (diameter at breast height)",
  temp_tree_name_3: "Tree Name (if not present)",
  temp_avg_3: "Average Number of Trees per hectare",
  temp_tree_avg_3: "Tree average DBH in cm (diameter at breast height)",
  temp_irrigation: "Irrigation",
  temp_syn_fert: "Synthetic Fertilizer",
  temp_fert: "Fertilizer (t/year)",
  temp_nitro: "% Nitrogen",
  temp_manure: "Manure Application (t/ha)",
  temp_per_manure: "% Manure Imported",
  temp_compost: "Compost Application (t/ha)",
  temp_per_compost: "% Compost Imported",
  temp_hay: "Hay Straw Application (t/ha)",
  temp_per_hay: "% Hay Straw Imported",
  temp_bale: "Bale Grazing",
  temp_residue: "Residue Left After Bale Grazing (%)",
  temp_cash: "Cash Crop",
  temp_name: "Name if not in drop down list in calendar",
  temp_cover: "Cover Crop",
  temp_desc: "Describe Mix (at least 1 grass and 1 legume)",
  temp_multispecies: "Multispecies Cover Crop",
  temp_at_least: "at least 3 different types",
  temp_unplanned: "Unplanned Grazing",
  temp_grazing_multi: "Multi Species Grazing",
  temp_adaptive: "Adaptive Multi Paddock Grazing",
  temp_yields: "Are yields and residue values (tC/ha) provided as dry (preferred) or fresh biomass?",
  temp_jan: "Jan",
  temp_feb: "Feb",
  temp_mar: "Mar",
  temp_apr: "Apr",
  temp_may: "May",
  temp_jun: "Jun",
  temp_jul: "Jul",
  temp_aug: "Aug",
  temp_sep: "Sep",
  temp_oct: "Oct",
  temp_nov: "Nov",
  temp_dec: "Dec",
  temp_cash_1: "Cash Crop - 1",
  temp_no_cash_1: "No Cash Crops - 1",
  temp_aromatics: "Aromatics",
  temp_barley: "Barley",
  temp_beans: "Beans",
  temp_beets: "Beets",
  temp_clover: "Clover",
  temp_corn: "Corn",
  temp_cowpea: "Cowpea",
  temp_forage_beet: "Forage Beet",
  temp_forage_kale: "Forage Kale",
  temp_radish: "Forage Radish",
  temp_turnip: "Forage Turnip",
  temp_garlic: "Garlic",
  temp_lupins: "Lupins",
  temp_millet: "Millet",
  temp_oats: "Oats",
  temp_onions: "Onions",
  temp_other_grains: "Other Grains",
  temp_other_vegetables: "Other Vegetables",
  temp_peanuts: "Peanuts",
  temp_peas: "Peas",
  temp_potatoes: "Potatoes and Tubers",
  temp_pumpkin: "Pumpkin",
  temp_rice: "Rice",
  temp_rye: "Rye",
  temp_seeds: "Seeds",
  temp_sesame: "Sesame",
  temp_shrubb: "Shrubby perennials",
  temp_sillage: "Sillage Corn",
  temp_sorghum_sudan: "Sorghum Sudan",
  temp_sorghum: "Sorghum",
  temp_soybean: "Soybean",
  temp_squash: "Squash",
  temp_sunflower: "Sunflower",
  temp_sunn_hemp: "Sunn Hemp",
  temp_triticale: "Triticale",
  temp_wheat: "Wheat",
  temp_cash_2: "Cash Crop -2",
  temp_no_cash_2: "No Cash Crops - 2",
  temp_cover_crops: "Cover Crops",
  temp_fallow: "Productive Fallow",
  temp_grazing: "Grazing",
  temp_harvest_yield: "Harvest Yield (t/ha)",
  temp_grazing_yield: "Grazing Yield (t/ha)",
  temp_whats_left: "What's left after residue grazing/harvest (t/ha)",
  temp_bare_soil: "Bare Soil Fallow",
  temp_tilling_event: "Tilling Event?",
  temp_min_till: "Minimum Tilling Event? (<10cm deep)",
  temp_save: "Save",
  temp_close: "Close",
  parcel_maps: "MAP PARCELS",
  parcel_here: "Here we need you to provide us some more details about your parcels:",
  parcel_on: "On the list below, click on 'EDIT' to add information to your parcels. Here, we ask you to provide both current data (what practices, crops, grazing months, etc. do you manage at the moment) and estimates for the next 10 years.",
  parcel_you: "You can navigate between the tabs for each year at the top of each panel window.",
  parcel_import: "IMPORT KML FILE",
  parcel_del: "DELETE ALL PARCELS",
  parcel_actions: "Actions",
  parcel_parcel_name: "Parcel Name",
  parcel_past: "Past and Current Practices",
  parcel_year1: "Y-1",
  parcel_year2: "Y-2",
  parcel_year3: "Y-3",
  parcel_year4: "Y-4",
  parcel_year5: "Y-5",
  parcel_year6: "Y-6",
  parcel_year7: "Y-7",
  parcel_year8: "Y-8",
  parcel_year9: "Y-9",
  parcel_year10: "Y-10",
  parcel_parcelname: "Parcel Name",
  parcel_coordinates: "Coordinates",
  parcel_area: "Area (sq.m)",
  parcel_choose: "I choose to manually enter area",
  parcel_manual: "Manually Entered Area (sq.m.)",
  parcel_parcels: "Parcels",
  parcel_copy_data: "COPY DATA",
  parcel_templates: "Templates",
  parcel_import_data: "IMPORT DATA",
  parcel_applying: "Applying these Practices Since (Years)?*",
  parcel_like_copy: "I would like to copy current practices to the next 10 years",
  parcel_are_sure: "Are you sure you want to copy the parcel data?",
  parcel_you_are_about: "You are about to copy the data of the selected parcel in the drop down to the current parcel. Would you like to continue?",
  parcel_agree: "AGREE",
  parcel_disagree: "DISAGREE",
  parcel_are_you_import: "Are you sure you want to import the template data?",
  parcel_about_import: "You are about to import the data from the template to the current parcel for the given year. Would you like to continue?",
  parcel_agree_2: "AGREE",
  parcel_disagree_2: "DISAGREE",
  parcel_land_use: "Land Use Type",
  parcel_this_refers: "This refers to the agricultural setting on the farm's parcel",
  parcel_silvopasture: "Silvopasture",
  parcel_arable: "Arable Crops",
  parcel_grass: "Grasslands",
  parcel_agro: "Agroforestry",
  parcel_forest: "Forestry",
  parcel_type_1: "Type of Trees",
  parcel_quercus: "Quercus",
  parcel_olea: "Olea europea",
  parcel_other: "Other",
  parcel_avg_no_2: "Average Number of Trees per hectare",
  parcel_tree_avg_dbh: "Tree average DBH in cm (diameter at breast height)",
  parcel_tree_name_1: "Tree Name (if not present)",
  parcel_avg_1: "Average Number of Trees per hectare",
  parcel_tree_avg_1: "Tree average DBH in cm (diameter at breast height)",
  parcel_tree_name_2: "Tree Name (if not present)",
  parcel_avg_2: "Average Number of Trees per hectare",
  parcel_tree_avg_2: "Tree average DBH in cm (diameter at breast height)",
  parcel_tree_name_3: "Tree Name (if not present)",
  parcel_avg_3: "Average Number of Trees per hectare",
  parcel_tree_avg_3: "Tree average DBH in cm (diameter at breast height)",
  parcel_irrigation: "Irrigation",
  parcel_syn_fert: "Synthetic Fertilizer",
  parcel_fert: "Fertilizer (t/year)",
  parcel_nitro: "% Nitrogen",
  parcel_manure: "Manure Application (t/ha)",
  parcel_per_manure: "% Manure Imported",
  parcel_compost: "Compost Application (t/ha)",
  parcel_per_compost: "% Compost Imported",
  parcel_hay: "Hay Straw Application (t/ha)",
  parcel_per_hay: "% Hay Straw Imported",
  parcel_bale: "Bale Grazing",
  parcel_residue: "Residue Left After Bale Grazing (%)",
  parcel_cash: "Cash Crop",
  parcel_name: "Name if not in drop down list in calendar",
  parcel_cover: "Cover Crop",
  parcel_desc: "Describe Mix (at least 1 grass and 1 legume)",
  parcel_multispecies: "Multispecies Cover Crop",
  parcel_at_least: "at least 3 different types",
  parcel_unplanned: "Unplanned Grazing",
  parcel_grazing_multi: "Multi Species Grazing",
  parcel_adaptive: "Adaptive Multi Paddock Grazing",
  parcel_yields: "Are yields and residue values (tC/ha) provided as dry (preferred) or fresh biomass?",
  parcel_jan: "Jan",
  parcel_feb: "Feb",
  parcel_mar: "Mar",
  parcel_apr: "Apr",
  parcel_may: "May",
  parcel_jun: "Jun",
  parcel_jul: "Jul",
  parcel_aug: "Aug",
  parcel_sep: "Sep",
  parcel_oct: "Oct",
  parcel_nov: "Nov",
  parcel_dec: "Dec",
  parcel_cash_1: "Cash Crop - 1",
  parcel_no_cash_1: "No Cash Crops - 1",
  parcel_aromatics: "Aromatics",
  parcel_barley: "Barley",
  parcel_beans: "Beans",
  parcel_beets: "Beets",
  parcel_clover: "Clover",
  parcel_corn: "Corn",
  parcel_cowpea: "Cowpea",
  parcel_forage_beet: "Forage Beet",
  parcel_forage_kale: "Forage Kale",
  parcel_radish: "Forage Radish",
  parcel_turnip: "Forage Turnip",
  parcel_garlic: "Garlic",
  parcel_lupins: "Lupins",
  parcel_millet: "Millet",
  parcel_oats: "Oats",
  parcel_onions: "Onions",
  parcel_other_grains: "Other Grains",
  parcel_other_vegetables: "Other Vegetables",
  parcel_peanuts: "Peanuts",
  parcel_peas: "Peas",
  parcel_potatoes: "Potatoes and Tubers",
  parcel_pumpkin: "Pumpkin",
  parcel_rice: "Rice",
  parcel_rye: "Rye",
  parcel_seeds: "Seeds",
  parcel_sesame: "Sesame",
  parcel_shrubb: "Shrubby perennials",
  parcel_sillage: "Sillage Corn",
  parcel_sorghum_sudan: "Sorghum Sudan",
  parcel_sorghum: "Sorghum",
  parcel_soybean: "Soybean",
  parcel_squash: "Squash",
  parcel_sunflower: "Sunflower",
  parcel_sunn_hemp: "Sunn Hemp",
  parcel_triticale: "Triticale",
  parcel_wheat: "Wheat",
  parcel_cash_2: "Cash Crop -2",
  parcel_no_cash_2: "No Cash Crops - 2",
  parcel_cover_crops: "Cover Crops",
  parcel_fallow: "Productive Fallow",
  parcel_grazing: "Grazing",
  parcel_harvest_yield: "Harvest Yield (t/ha)",
  parcel_grazing_yield: "Grazing Yield (t/ha)",
  parcel_whats_left: "What's left after residue grazing/harvest (t/ha)",
  parcel_bare_soil: "Bare Soil Fallow",
  parcel_tilling_event: "Tilling Event?",
  parcel_min_till: "Minimum Tilling Event? (<10cm deep)",
  parcel_save: "Save",
  parcel_close: "Close",
  parcel_y1: "YEAR 1",
  parcel_y2: "YEAR 2",
  parcel_y3: "YEAR 3",
  parcel_y4: "YEAR 4",
  parcel_y5: "YEAR 5",
  parcel_y6: "YEAR 6",
  parcel_y7: "YEAR 7",
  parcel_y8: "YEAR 8",
  parcel_y9: "YEAR 9",
  parcel_y10: "YEAR 10",
  parcel_pls_save: "Please click the Save button to save your data before clicking on Close ",
  sa_past: "PAST SOIL ANALYSIS",
  sa_if_you: "If you have done soil analysis in the past, fill out the following information at the farm level (optional)",
  sa_clay_per: "Clay Content (%)",
  sa_sil_per: "Silt Content (%)",
  sa_if_clay: "If clay and silt content aren't available:",
  sa_text_desc: "Texture Description",
  sa_sand: "Sand",
  sa_lo_sand: "Loamy Sand",
  sa_sandy_lo: "Sandy Loam",
  sa_san_cl_loam: "Sandy Clay Loam",
  sa_loamy_sand: "Loamy Sand",
  sa_sil_loam: "Silt Loam",
  sa_silt_2: "Silt",
  sa_silt_cl_loam: "Silt Clay Loam",
  sa_loam: "Loam",
  sa_clay_3: "Clay",
  sa_clay_loam: "Clay Loam",
  sa_sandy_clay: "Sandy Clay",
  sa_silty_clay: "Silty Clay",
  sa_bulk_density: "Bulk density",
  sa_g_kg: "g / kg",
  sa_g: "g",
  sa_kg: "kg",
  sa_carbon: "Carbon Content",
  sa_kg_kg_per: "g / kg / %",
  sa_g_2: "g",
  sa_kg_2: "kg",
  sa_per: "%",
  sa_if_carbon: "If carbon content isn't available:",
  sa_organic: "Organic Matter Content",
  sa_g_kg_per_2: "g / kg / %",
  sa_g_3: "g",
  sa_kg_3: "kg",
  sa_per_3: "%",
  sa_save: "Save",
  generic_saving_data: "Saving Data. Please Wait",
  generic_save: "Save",
  back_to_home: "BACK",
  dashboard_manual_pdf: "Dashboard Manual",
  faq_pdf: "Frequently Asked Questions (FAQ)",
  nc_text_1_not_submitted: "Complete your onboarding",
  nc_text_1_under_review: "Farm Plan Submitted 🥳",
  nc_text_1_data_accepted: "Your farm data has been accepted",
  nc_text_2_not_submitted: "Tell us about your farm",
  nc_text_2_under_review: "Under Review",
  nc_text_bottom_under_review: "Your C+ Application has been submitted and is being reviewed by our science team.",
  nc_farminfo_step_regular: "Your",
  nc_farminfo_step_bold: "farm basics",
  nc_fuel_step_regular: "Indicate",
  nc_fuel_step_bold: "fuel usage",
  nc_livestock_step_regular: "Plan your",
  nc_livestock_step_bold: "livestock",
  nc_landuse_step_regular: "Map your",
  nc_landuse_step_bold: "fields and plan crops",
  nc_soil_step_regular: "Share soil",
  nc_soil_step_bold: "samples",
  jan: "January",
  feb: "February",
  mar: "March",
  apr: "April",
  may: "May",
  jun: "June",
  jul: "July",
  aug: "August",
  sep: "September",
  oct: "October",
  nov: "November",
  dec: "December",
  ls_first_year: "This is my first year",
  ls_value_percent: "Value should be between 0 and 100",
  ls_value_year: "Value should be between 0 and 365",
  generic_edit: "Edit",
  generic_view: "View",
  generic_delete: "Delete",
  lu_title: "Land Use Summary Management Data",
  values_fresh: "Fresh",
  values_dry: "Dry",
  generic_close: "Close",
  generic_agree: "Agree",
  generic_disagree: "Disagree",
  ls_table_year1: "Y-1",
  ls_table_year2: "Y-2",
  ls_table_year3: "Y-3",
  ls_table_year4: "Y-4",
  ls_table_year5: "Y-5",
  ls_table_year6: "Y-6",
  ls_table_year7: "Y-7",
  ls_table_year8: "Y-8",
  ls_table_year9: "Y-9",
  ls_table_year10: "Y-10",
  carbon_y1: "Year 1",
  carbon_y2: "Year 2",
  carbon_y3: "Year 3",
  carbon_y4: "Year 4",
  carbon_y5: "Year 5",
  carbon_y6: "Year 6",
  carbon_y7: "Year 7",
  carbon_y8: "Year 8",
  carbon_y9: "Year 9",
  carbon_y10: "Year 10",
  parcel_copy_sure: "Are you sure you want to copy current practices?",
  parcel_copy_continue: "You are about to copy the current practices to the next 10 years. Would you like to continue?",
  parcel_delete: "You're about to delete the parcel. Would you like to continue?",
  parcel_delete_sure: "Are you sure you want to delete all the parcels?",
  parcel_delete_continue: "You are about to delete all the parcels. Do you wish to proceed?",
  farmland_draft_submission_msg_success: "Farm details have been saved as draft successfully. You can revisit the form to make modifications before final submission",
  farmland_draft_submission_msg_failure: "There was an error saving the farmland draft details. Please verify the values you have entered or contact the Climate Farmers Customer Support on support@climatefarmers.org",
  farmland_final_submission_msg_success: "Farm details have been saved successfully. You cannot modify the farm details anymore. In case, you have any queries, please contact Climate Farmers Customer Support on support@climatefarmers.org",
  farmland_final_submission_msg_failure: "There was an error saving the farm details. Please verify the values you have entered or contact Climate Farmers Customer Support on support@climatefarmers.org",
  farmland_updated_by_farmer_liaison_success: "The farm details were successfully updated.",
  msg_mandatory_fields: "All fields marked as * are mandatory and need to be filled before proceeding to the next step",
  email_subject: "Climate Farmers Farm Updates",
  email_subject_farm_draft_submission: "Climate Farmers Farm Data Submission as a Draft",
  email_subject_farm_final_submission: "Climate Farmers Final Farm Data Submission",
  email_body_farmland_update: "Hello, We have an update for you regarding your farmland. Please login to your Climate Farmers account to view the update.",
  email_body_farmland_draft_submission: "Hello, You have saved your farm details as a draft. You can edit your farm details anytime. Feel free to reply to this email if you have any questions.",
  email_body_farmland_final_submission: "Hello, Thank you for submitting your farm details to Climate Farmers. We will review the information and get back to you at the earliest. For any other queries, please feel free to reply to this email.",
  infobox_parcel_temp_1: "When some of your fields have recurrent characteristics such as certain trees, crops or practices, it is helpful to create templates. ",
  infobox_parcel_temp_2: "This way, you'll be able to replicate these templates in other fields. This option is useful especially when you practice crop rotations, and therefore need to input completely different data for each year.",
  infobox_parcel_temp_3: 'To do that, you can first create a template by clicking on the blue button (right below your map). You can then create and  name as many different templates as you need. We recommend to choose names that are easy to identify e.g. "Olives - Grazed" or "Arable crops - Wheat".',
  infobox_coordinates_1: "If you don't know your farm coordinates, go to Google Maps and press the right mouse button over your farm. Press the left mouse button over the 2 numbers that come up in the first line to copy them to the clipboard and then paste them here.",
  infobox_coordinates_2: "Note: ",
  infobox_coordinates_3: "the first number is the ",
  infobox_coordinates_4: "North ",
  infobox_coordinates_5: "coordinate, and the second is ",
  infobox_coordinates_6: "East",
  infobox_coordinates_7: ". If you have a West or South coordinate, just put a negative ",
  infobox_coordinates_8: "(-) before ",
  infobox_coordinates_9: "that coordinate. Make sure you enter each number in the correct field, otherwise the location of your map will show incorrectly.",
  infobox_coordinates_10: "You can check if the coordinates are correct in the “MAP PARCELS AND LAND USE” page.",
  infobox_ls_mgmt_1: "Livestock Management options:",
  infobox_ls_mgmt_2: "- Stabled / Caged",
  infobox_ls_mgmt_3: "- Unplanned Grazing (Set Stock Grazing)",
  infobox_ls_mgmt_4: "This describes the practice of grazing livestock in a large paddock for an extended period. You can also select this practice if you already practice traditional or rotational grazing but without a grazing plan / chart.",
  infobox_ls_mgmt_5: "- Adaptive Multi-Paddock Grazing / Pastured",
  infobox_ls_mgmt_6: "This refers to the division of large grazing areas into smaller parcels, using physical or electric fences. The rotation of livestock is performed according to optimized stocking density and grazing duration per paddock keeping in mind factors such as the available natural resources (avoid overgrazing and over resting). A ",
  infobox_ls_mgmt_7: "grazing chart",
  infobox_ls_mgmt_8: " is used to plan, record and monitor and needs to be submitted to us.",
  infobox_ls_manure_1: "Please choose how you manage the manure on your farm (in %):",
  infobox_ls_manure_2: "- On Field:",
  infobox_ls_manure_3: " manure is left on pasture during grazing",
  infobox_ls_manure_4: "- Composted:",
  infobox_ls_manure_5: " manure is used for on-farm composting",
  infobox_ls_manure_6: "- Scattered directly:",
  infobox_ls_manure_7: " manure is collected and applied to fields and pastures as fertiliser",
  infobox_ls_manure_8: "- Collection and Disposal:",
  infobox_ls_manure_9: " manure is collected and not used on-site",
  infobox_lu_area_1: "The area of each parcel will be automatically calculated from the map you created. However, if you feel like this number is not realistic, on the right you can manually enter its surface area ",
  infobox_lu_area_2: "in square meters.",
  infobox_lu_parcels: "When two parcels have equal, or similar, characteristics, you can also quickly copy the information from one to the other by selecting the parcel from which to get it, and clicking on “COPY DATA”. This way, the new parcel will be an exact copy of the previous one, and you'll still be able to make changes to it.",
  infobox_lu_copy_practices: "Here you can copy your entries for the next 10 years and then make the corrections for each year and parcel. Make sure to de-select this option if you want to make additional changes in the following years (otherwise the system will not allow you to do so).",
  infobox_lu_temp: "Here, you can import any of the templates you have created earlier by selecting it and clicking on “IMPORT DATA”. Remember that if you modify the template after copying it into a parcel, the data here will not be affected.",
  infobox_lu_type_1: "Silvopasture: ",
  infobox_lu_type_2: "The integration of trees, forage, and the grazing of livestock in a mutually beneficial way. e.g. the Montado / Dehesa system. Pastures can be grazed or harvested.",
  infobox_lu_type_3: "Arable Crops: ",
  infobox_lu_type_4: "Grains, beans, seeds, vegetables or fiber crops. Can be grazed or harvested.",
  infobox_lu_type_5: "Grasslands: ",
  infobox_lu_type_6: "An area in which the vegetation is dominated by a nearly continuous cover of grasses. Can be grazed or harvested (hay).",
  infobox_lu_type_7: "Agroforestry: ",
  infobox_lu_type_8: "A land use system in which trees or shrubs are grown around or among crops or pastureland. Includes fruit or nut trees or other perennials such as aromatics. ",
  infobox_lu_type_9: "No grazing.",
  infobox_lu_type_10: " Pastures can be harvested.",
  infobox_lu_type_11: "Forestry: ",
  infobox_lu_type_12: "Woodlands used for timber production. No grazing or harvesting.",
  infobox_lu_per_manure: "Please define how much (in %) of the material you apply is brought in from outside the farm.",
  infobox_lu_bale_grz_1: "Bale grazing ",
  infobox_lu_bale_grz_2: "on fields is a method of providing feed to beef cattle during the winter months. With this system, livestock are allowed access to bales previously placed on a field or wintering site.",
  infobox_lu_bale_grz_3: "Residue Left After Bale Grazing (%) ",
  infobox_lu_bale_grz_4: "Please indicate how much of the applied biomass is left on the field after grazing.",
  infobox_lu_cash_crop_1: "Cash crop",
  infobox_lu_cash_crop_2: "Refers to an agricultural crop which is grown to sell for profit.",
  infobox_lu_cover_crop_1: "Cover crop ",
  infobox_lu_cover_crop_2: "Refers to the practice of sowing plant species (at least 2 different species, one grass and one legume) whose use is intended primarily to improve the physical, chemical, and biological characteristics of the soil. Cover crops can be either grazed, harvested, incorporated into the soil, terminated with a roller-crimper or left as residue after cutting.",
  infobox_lu_multi_cover_crop_1: "Multispecies Cover Crop",
  infobox_lu_multi_cover_crop_2: "Refers to the practice of sowing a diverse mix of plant species whose use is intended primarily to improve the physical, chemical, and biological characteristics of the soil.",
  infobox_lu_multi_cover_crop_3: "It needs to include at ",
  infobox_lu_multi_cover_crop_4: "least 3 species of plants:",
  infobox_lu_multi_cover_crop_5: "- At least one Grass species (Poaceae)",
  infobox_lu_multi_cover_crop_6: "- At least one Legume species (Fabaceae)",
  infobox_lu_multi_cover_crop_7: "- At least one of the following families: Brassicaceae, Amaranthaceae,  Linaceae, Plantaginaceae, Asteraceae, Polygonaceae, Boraginaceae, Malvaceae, Rosaceae, Cucurbitaceae.",
  infobox_lu_unplanned_grz_1: "Unplanned Grazing (Set Stock Grazing)",
  infobox_lu_unplanned_grz_2: "This describes the practice of grazing livestock in a large paddock for an extended period.",
  infobox_lu_unplanned_grz_3: "You can also select this practice if you already practice traditional or rotational grazing but without a grazing plan / chart.",
  infobox_lu_multi_grz_1: "Multi Species Grazing",
  infobox_lu_multi_grz_2: "Refers to the grazing of",
  infobox_lu_multi_grz_3: " at least 2 different species ",
  infobox_lu_multi_grz_4: "between: grazing/browser ruminants, equids and camelids and pastured pigs or fowls.",
  infobox_lu_adapt_graz_1: "Adaptive Multi Paddock Grazing",
  infobox_lu_adapt_graz_2: "Refers to the division of large grazing areas into smaller parcels, using physical or electric fences. The rotation of livestock is performed according to optimised stocking density and grazing duration per paddock keeping in mind factors such as the available natural resources (avoid overgrazing and over resting).",
  infobox_lu_adapt_graz_3: "A ",
  infobox_lu_adapt_graz_4: "grazing chart ",
  infobox_lu_adapt_graz_5: "is used to plan, record and monitor and needs to be submitted to us.",
  infobox_lu_check_cover: "Please tick off every month where you grow Cover Crops or Multispecies Cover Crops",
  infobox_lu_check_prod_fallow: "Productive fallow refers to a ground cover that is not intentionally sown such as grass or other spontaneous vegetation.",
  infobox_lu_check_bare_soil: "Please tick off every month where your soil is left bare.",
  infobox_lu_check_granzing: "Please tick off any months where grazing takes place in this parcel.",
  infobox_lu_harvest_yield_1: "Please add the estimated yields of crops that you harvest (arable crops, cover crops, hay,...) in tons per hectare.",
  infobox_lu_harvest_yield_2: "If this is an agroforestry parcel you can leave the field empty.",
  infobox_lu_grz_yield_1: "Grazing yield refers to what the animals have consumed after being on that parcel.",
  infobox_lu_grz_yield_2: "This can be calculated roughly using the number of animals & days of grazing or in tons of DM (dry matter) by sampling and weighing. Most ruminant animals consume 3% of their Live Weight in Dry Matter of forage per day.",
  infobox_lu_whats_left: "Please add the amount of residues that are left on the field after harvest and/or grazing took place in tons per hectare.",
  infobox_lu_min_till_1: "This refers to a small soil disturbance using a plow, disc plow, chisel plow, or tiller, with the following limitations: up to 10 cm deep, if the entire area is tilled; OR if more than 30% of the area is tilled, then it must be done in strips.",
  infobox_lu_min_till_2: "Subsoiling is allowed as long as using tines that do not cause destruction of soil structure and stratification.",
  sb_profile: "Profile",
  sb_signout: "Sign out",
  sb_home: "Home",
  pp_since: "Carbon+ Member since",
  pp_personal_info: "Personal Information",
  pp_name: "Official Name",
  pp_email: "Email",
  pp_phone: "Phone Number",
  pp_settings: "Settings",
  pp_language: "Language",
  generic_cancel: "Cancel",
  pp_password: "Password",
  nc_popup_sure: "Are you sure?",
  nc_popup_you: "You are about to submit your Carbon+ Application.",
  nc_popup_after: "After submission, our science team will begin reviewing your application.",
  nc_popup_not_1: "You will ",
  nc_popup_not_2: "not",
  nc_popup_not_3: " be able to edit your application after submission.",
  nc_popup_yes: "Yes, submit",
  nc_popup_no: "No, keep editing",

  infobox_farminfo_general: "If you notice any inaccuracies in the information provided, please don't hesitate to get in touch with Iris/Maria via WhatsApp. We'll respond to your message during business hours.",
  infobox_farminfo_start_year_1: "Credits are calculated on a calendar year basis. You have the flexibility to select your project start year depending on when you began implementing practices to enhance soil quality and farm productivity. It's important to understand that data from the three years preceding your chosen project start year will be utilized to establish your 'baseline' scenario, representing the condition of the land before regeneration efforts began. For example, if your project started in 2023, data from the years 2020, 2021, and 2022 will be considered for baseline calculation purposes. The earliest possible project start year is 2021.",
  infobox_farminfo_start_year_2: "Improvements with respect to the baseline will determine the number of credits generated. Therefore, it is important to choose your project start year carefully.",
  ver_doc_info_1: "Submit ",
  ver_doc_info_2: "all available documents ",
  ver_doc_info_3: "requested on this page. If you are missing certain documents or need help, don't hesitate to get in touch with us. You can start a chat with our chatbot in the bottom right corner or contact Iris/Maria via WhatsApp. We'll respond to your message during business hours.The documents marked with * are mandatory for you to proceed to the next step of the process.",

  uploading_document_msg_success: "Document uploaded successfully.",
  uploading_document_msg_failure: "There was an error uploading your file. Please try again or contact the Climate Farmers Customer Support on support@climatefarmers.org",
  downloading_document_msg_success: "Document downloaded successfully.",
  downloading_document_msg_failure: "There was an error downloading your file. Please try again or contact the Climate Farmers Customer Support on support@climatefarmers.org",
  deleting_document_msg_success: "Document deleted successfully.",
  deleting_document_msg_failure: "There was an error deleting your file. Please try again or contact the Climate Farmers Customer Support on support@climatefarmers.org",

  generic_loading: "Loading. Please wait.",
  farminfo_farm_information: "Farm information",
  farminfo_farmname: "Farm name",

  ver_doc_title: "Verification documents",
  ver_doc_your_farm: "Your farm",
  ver_doc_land_ownership: "Land access. Documents that prove that you have access to the land for at least the next 5 years.*",

  ver_doc_land_ownership_info_box_1: "The documents should reflect the number of hectares on which you will generate credits.",
  ver_doc_land_ownership_info_box_2: "If you own the land, this should be a land registry certificate in your name (name on the contract) (PT: Certidão permanente do registo predial, ES: Extracto del registro).",
  ver_doc_land_ownership_info_box_3: "If you lease the land, your contract must not expire within five years from the Carbon + project start year. For leased land we require:",
  ver_doc_land_ownership_info_box_4: "- The cadastral certificate of the owner of the land (PT: Certidão permanente do registo predial, ES: Extracto del registro)",
  ver_doc_land_ownership_info_box_5: "- The lease contract between you and the owner of the land.",
  ver_doc_land_ownership_info_box_6: "Format: PDF/JPG",

  ver_doc_download: "Download",
  ver_doc_delete: "Delete",
  ver_doc_upload: "Upload new document",

  ver_doc_kml: "Digital map with parcels",
  ver_doc_kml_info_box_1: "Make sure that all the parcels in your application are included as individual parcels on the map, and that the KML files of your parcels are bundled in 1 KML/KMZ file.",
  ver_doc_kml_info_box_2: "Learn how to create your digital map in this video: https://www.youtube.com/watch?v=LQz3kUMKMwU   ",
  ver_doc_kml_info_box_3: "Format: KML/KMZ",

  ver_doc_cap: "CAP",
  ver_doc_cap_info: "Upload your 'solicitud unica (es)/ pedido unico (pt)' documents for each year.",
  ver_doc_cap_info_box_1: "Baseline years are the three years prior to your project start year, which will be used to calculate your “baseline” scenario, or the state of the land prior to regeneration.    ",
  ver_doc_cap_info_box_2: "Format: PDF/JPG",

  ver_doc_livestock: "Livestock",
  ver_doc_livestock_info: "If you have animals on your farm, upload official livestock count documents for each year.",
  ver_doc_livestock_info_box_1: "To accurately assess the animal population on your farm, we require documentation for each species indicating the number of animals per year.",
  ver_doc_livestock_info_box_2: "Ideally, these documents should also include the following details:",
  ver_doc_livestock_info_box_3: "- Date of birth",
  ver_doc_livestock_info_box_4: "- Date of entering the farm",
  ver_doc_livestock_info_box_5: "- Date of exiting the farm ",
  ver_doc_livestock_info_box_6: "You can obtain this information from governmental agricultural services where your animals are registered (e.g., PT: SNIRA - Sistema Nacional de Informação e Registo Animal, ES: Libro de Registro) or from your CAP documents.",
  ver_doc_livestock_info_box_7: "Format: PDF/JPG",

  nc_ver_doc_step_regular: "Upload ",
  nc_ver_doc_step_bold: "verification documents",


  home_ver_doc_submit: "Submit for review",
  home_submit_popup_1: "Thank you for your submission",
  home_submit_popup_2: "Our team has been notified about your submission, and will review your data shortly. You will not be able to modify your data. In case you want to make any changes, you may write to us and we will reopen the platform for you.",

  baseline_years: "Baseline",
  project_years: "Project years",

  // farm wide info
  fwi_screen_title: "Farm-wide Information",
  fwi_farmSize_tooLarge: "The sum of parcel areas ({{ calculatedFarmSize }}HA) is over 10% larger than the farm area specified in your contract ({{ contractFarmSize }}HA). Your farm map or contract needs to be revised.",
  fwi_farmSize_largerWithinLimit: "The sum of parcel areas ({{ calculatedFarmSize }}HA) is larger than the farm area specified in your contract ({{ contractFarmSize }}HA) but within a 10% tolerance limit. You can adjust the parcel areas manually to reduce this difference. Otherwise, parcel sizes will be adjusted automatically to match the farm size.",
  fwi_screen_subtitle_1: "Provide farm-wide information about the synthetic fertilisers, organic amendments imported, animal fodder imported, and seeds.",
  fwi_screen_subtitle_2: "If you don't have an invoice and get selected for an audit, you will have to provide a written declaration for the purchase of the below inputs.",
  fwi_nitrogen_fert_title: "Nitrogen-based synthetic fertiliser*",
  fwi_nitrogen_fert_subtitle_1: "Provide the quantity and % of nitrogen of all nitrogen-based synthetic fertilisers ",
  fwi_nitrogen_fert_subtitle_2: "used in ",
  fwi_nitrogen_fert_subtitle_3: "on the whole farm. If you did not use any N-based synthetic fertiliser, you may enter zero. ",
  fwi_nitrogen_fert_subtitle_4: "Upload invoices as supporting documentation for this amount (PDF or JPG).",
  fwi_nitrogen_fert: "Synthetic fertiliser",
  fwi_quantity_tonnes: "tonnes",
  fwi_percent_nitro: "% of nitrogen",
  fwi_add_fert: "+ Add fertilizer",
  fwi_upload_invoice: "Upload invoice",
  fwi_percent_imported: "% imported",

  fwi_org_amend_title: "Imported fraction of organic amendments",
  fwi_org_amend_subtitle_1: "For each type of organic amendment, provide the percentage that you ",
  fwi_org_amend_subtitle_2: "imported from outside your farm in ",
  fwi_org_amend_subtitle_3: ". If you did not apply the organic amendment, you can enter zero. If available, upload supporting documentation for this amount (PDF or JPG).",
  fwi_org_amend_info: "Example: if you bought 5 tonnes of compost from outside your farm and produced 7 tonnes in your farm, the percentage would be 5/12 = 42%",
  fwi_biochar: "Biochar",
  fwi_percent: "%",
  fwi_compost: "Compost",
  fwi_manure: "Manure",
  fwi_mulch: "Mulch",
  fwi_slurry: "Slurry",
  fwi_documents: "Documents",
  fwi_documents_info: "Examples: invoices of purchasing organic material, field notes, etc.",
  fwi_documents_upload: "Upload document",

  fwi_animal_fod_title: "Animal fodder imported*",
  fwi_animal_fod_subtitle_1: "Provide the percentage of hay/straw used for bale grazing that you ",
  fwi_animal_fod_subtitle_2: "imported from outside your farm in ",
  fwi_animal_fod_subtitle_3: ". If you did not use hay/straw for bale grazing, you can enter zero. If available, upload invoices as supporting documentation for this amount (PDF or JPG).",
  fwi_animal_fod_info: "Example: if you bought 30 tonnes of straw from outside your farm and produced 20 tonnes yourself, the percentage would be 30/50 = 60%",
  fwi_hay: "Hay/straw",
  fwi_invoices: "Invoices",

  fwi_seeds_title: "Seeds",
  fwi_seeds_subtitle: "Provide the invoices for seeds for the annual crops you harvested in ",


  // parcel map overview
  pmo_map_title: "Parcel map",
  pmo_map_subtitle: 'The map below shows all the parcels you have uploaded. You may rename your parcel by clicking on it in the map. Fill out the "Farm-wide information" section for your farm, and for each parcel, fill out the "General info" as well as "Crops" page.',
  pmo_dig_maps: "Digital map with parcels",
  pmo_map_info_1: "Make sure that all the parcels in your application are included as individual parcels on the map, and that the KML files of your parcels are bundled in 1 KML/KMZ file.",
  pmo_map_info_2: "Learn how to create your digital map in this video: https://www.youtube.com/watch?v=LQz3kUMKMwU",

  pmo_popup_title: "Parcel",
  pmo_popup_name: "[Name: ",
  pmo_popup_rename: "Edit parcel",
  pmo_popup_delete: "Delete parcel",
  pmo_popup_search: "Search",
  pmo_fwi: "Farm-wide Information",
  pmo_fwi_farm_from: "'s farm",
  pmo_fwi_complete: "Enter data",
  pmo_list_title: "Parcel List",
  pmo_list_subtitle: "Here you see an overview of your parcels. Please check if the number of hectares is correct and provide General and Crops information for each parcel.",
  pmo_list_info: "By clicking the pencil icon you can rename the parcel, report a different parcel size, or delete it.",
  pmo_list_gen_info: "General info",
  pmo_list_crops: "Crops",
  pmo_edit_title: "Parcel-specific data",
  pmo_edit_description: "You can rename the parcel, report a different parcel size, or delete it.",
  pmo_edit_ha_auto: " HA (calculated based on the map)",
  pmo_edit_ha_manual: "If the calculated parcel size is incorrect, indicate the correct parcel size (in hectares) below: ",

  pmo_delete_map_heading: "Delete map",
  pmo_delete_map_message: "Are you sure you want to delete the map? This will also erase all the data that you entered in your parcels.",
  pmo_delete_map_no: "No, keep the map and data",
  pmo_delete_map_yes: "Yes, erase map and data",
  pmo_delete_parcel_message: "Are you sure you want to delete the parcel? This will also erase all the data that you entered.",
  pmo_delete_parcel_no: "No, keep the parcel and data",
  pmo_delete_parcel_yes: "Yes, erase parcel and data",



  //parcels - general info
  pgi_back_button: "< Back",
  pgi_screen_title_1: "Parcel ",
  pgi_screen_title_2: "General Information",
  pgi_crops_info_button: "Crops information >",
  pgi_subtitle: 'Provide information about the land use, organic amendments, animal fodder, grazing, tillage, irrigation, and felled trees for this specific parcel. If the information is the same for a previously submitted year or parcel, you can copy the data by clicking "copy data from other year / parcel."',
  pgi_copy_data_button_1: "Copy data from other year / parcel",
  pgi_pre_fill_data_pop_up: "Pre-fill parcel data",
  pgi_pre_fill_data_pop_up_desc: "Choose the years and parcel name to copy data to the parcel you are currently editing. If you select 'all years', data from the selected parcel  will be copied to the current parcel for all years.",
  pgi_copy_to: "Copy to",

  pgi_copy_data_to_parcel: "(Current parcel)",
  pgi_copy_data_with_data: "with data from",
  pgi_copy_data_with_years_dropdown: "Year",
  pgi_copy_data_all_years: "All years",
  pgi_copy_data_button_2: "Copy data",
  pgi_warning_overwrite_1: "Warning overwrite data",
  pgi_warning_overwrite_2: "You have already completed some information on this parcel. By copying data from another parcel, this information will be deleted, except for information about perennials. Are you sure you want to continue?",
  general_continue_button: "Continue",
  pgi_cancel_button: "Cancel",
  pgi_landuse_title: "Land use",
  pgi_landuse_subtitle: "Select the primary and if applicable secondary land use for this parcel in ",
  pgi_primary_lu: "Primary land use*",
  pgi_secondary_lu: "Secondary land use",
  pgi_lu_type_dd_name: "Land use type",
  pgi_lu_type_dd_value_1: "Silvopasture",
  pgi_lu_type_dd_value_2: "Arable crops",
  pgi_lu_type_dd_value_3: "Grassland",
  pgi_lu_type_dd_value_4: "Agroforestry / Tree crops",
  pgi_lu_type_dd_value_5: "Forestry",
  pgi_organic_amendments_title: "Organic amendments",
  pgi_organic_amendments_desc_1: "Provide information for all the organic matter you <strong>added</strong> to this parcel as a <strong>soil amendment</strong> in {{year}}.<br/>Note: This <strong>does not include</strong> crop residues <strong>left</strong> on the parcel after harvest or pruning.",
  pgi_organic_amendments_biochar: "Biochar",
  pgi_organic_amendments_biochar_qty: "Quantity added",
  pgi_organic_amendments_biochar_tonnes: "tonnes",
  pgi_organic_amendments_compost: "Compost",
  pgi_organic_amendments_compost_info: "If you want to delete a compost type, select the hyphen (-) in the compost type and the row will not be saved.",
  pgi_organic_amendments_compost_type_dd: "Compost type",
  pgi_organic_amendments_compost_type_dd_1: "Green/Plant-based compost",
  pgi_organic_amendments_compost_type_dd_2: "Manure-based compost",
  pgi_organic_amendments_compost_type_dd_3: "Mushroom compost",
  pgi_organic_amendments_compost_type_qty: "Quantity added",
  pgi_organic_amendments_compost_type_tonnes: "tonnes",
  pgi_organic_amendments_compost_type_add: "Add a compost type",


  pgi_organic_amendments_manure: "Manure",
  pgi_organic_amendments_manure_info: "If you want to delete a manure type, select the hyphen (-) in the compost type and the row will not be saved.",
  pgi_organic_amendments_manure_type_dd: "Manure type",
  pgi_organic_amendments_manure_type_dd_1: "Cattle manure",
  pgi_organic_amendments_manure_type_dd_2: "Chicken manure",
  pgi_organic_amendments_manure_type_dd_3: "Horse manure",
  pgi_organic_amendments_manure_type_dd_4: "Pig manure",
  pgi_organic_amendments_manure_type_dd_5: "Sheep manure",
  pgi_organic_amendments_manure_type_qty: "Quantity added",
  pgi_organic_amendments_manure_type_tonnes: "tonnes",
  pgi_organic_amendments_manure_type_add: "Add a manure type",

  pgi_organic_amendments_mulch: "Mulch",
  pgi_organic_amendments_mulch_info: "If you want to delete a mulch type, select the hyphen (-) in the compost type and the row will not be saved.",
  pgi_organic_amendments_mulch_type_dd: "Mulch type",
  pgi_organic_amendments_mulch_type_dd_1: "Hay / Straw",
  pgi_organic_amendments_mulch_type_dd_2: "Leaves",
  pgi_organic_amendments_mulch_type_dd_3: "Wood bark / Wood chips",
  pgi_organic_amendments_mulch_type_qty: "Quantity added",
  pgi_organic_amendments_mulch_type_tonnes: "tonnes",
  pgi_organic_amendments_mulch_type_add: "Add a mulch type",

  pgi_organic_amendments_slurry: "Slurry",
  pgi_organic_amendments_slurry_info: "If you want to delete a slurry type, select the hyphen (-) in the compost type and the row will not be saved.",
  pgi_organic_amendments_slurry_type_dd: "Slurry type",
  pgi_organic_amendments_slurry_type_dd_1: "Cattle slurry",
  pgi_organic_amendments_slurry_type_dd_2: "Pig slurry",
  pgi_organic_amendments_slurry_type_qty: "Quantity added",
  pgi_organic_amendments_slurry_type_tonnes: "litres",
  pgi_organic_amendments_slurry_type_add: "Add a slurry type",

  pgi_organic_amendments_other: "Other",
  pgi_organic_amendments_other_info: "Add all types of soil amendments that are not listed above. Specify the type of organic matter and the quantity added. Examples: by-products of production facility like alperujo, leftovers from the food industry, etc.",
  pgi_organic_amendments_soil_text: "Specify type of soil amendment",
  pgi_organic_amendments_other_qty: "Quantity added",
  pgi_organic_amendments_other_qty_tonnes: "tonnes",
  pgi_organic_amendments_other_add: "Add other",

  pgi_organic_amendments_animal_fodder: "Animal fodder",
  pgi_organic_amendments_af_desc: "Provide the amount of hay and straw put in this parcel for bale grazing in ",
  pgi_organic_amendments_af_info: "Note that this refers to the amount of hay and straw put in the parcel for <strong>bale grazing</strong>. If you added hay or straw as soil amendment you can add it in the previous section under 'mulch'",
  pgi_organic_amendments_af_2: "Animal fodder",
  pgi_organic_amendments_af_qty: "Quantitiy of hay / straw put out in the field",
  pgi_organic_amendments_af_tonnes: "tonnes",


  pgi_organic_amendments_grazing: "Grazing",
  pgi_organic_amendments_grazing_desc_1: "Select the months this parcel was grazed in during ",
  pgi_organic_amendments_grazing_desc_2: ", if any.",
  pgi_organic_amendments_grazing_months: "Grazing months",
  pgi_organic_amendments_grazing_choose: "Choose",
  pgi_organic_amendments_grazing_months_dd: "Months (Multiple selection allowed)",
  pgi_organic_amendments_grazing_months_1: "January",
  pgi_organic_amendments_grazing_months_2: "February",
  pgi_organic_amendments_grazing_months_3: "March",
  pgi_organic_amendments_grazing_months_4: "April",
  pgi_organic_amendments_grazing_months_5: "May",
  pgi_organic_amendments_grazing_months_6: "June",
  pgi_organic_amendments_grazing_months_7: "July",
  pgi_organic_amendments_grazing_months_8: "August",
  pgi_organic_amendments_grazing_months_9: "September",
  pgi_organic_amendments_grazing_months_10: "October",
  pgi_organic_amendments_grazing_months_11: "November",
  pgi_organic_amendments_grazing_months_12: "December",

  pgi_organic_amendments_tillage: "Tillage",
  pgi_organic_amendments_tillage_desc_1: "Add all tillage events on this parcel during ",
  pgi_organic_amendments_tillage_desc_2: ", if any.",
  pgi_organic_amendments_tillage_events: "Tillage events",
  pgi_organic_amendments_tillage_info: "For each tillage event indicate:<br/><br/>Month -  The month in which the soil was tilled.<br/><br/>Type - Deep tillage if the soil disturbance was deeper than 10 cm below the surface and Minimum tillage if it was less deep than 10 cm.<br/><br/>Percentage - The percentage of the parcel area that was tilled. If the whole parcel was tilled you can fill in 100%. If only a part of the parcel was tilled e.g. for fire hazard or strip tilling, estimate the percentage that was tilled.<br/><br/>If you want to delete a tillage event, select the hyphen (-) in the month and the row will not be saved.",
  pgi_organic_amendments_tillage_months: "Month",
  pgi_organic_amendments_tillage_months_1: "January",
  pgi_organic_amendments_tillage_months_2: "February",
  pgi_organic_amendments_tillage_months_3: "March",
  pgi_organic_amendments_tillage_months_4: "April",
  pgi_organic_amendments_tillage_months_5: "May",
  pgi_organic_amendments_tillage_months_6: "June",
  pgi_organic_amendments_tillage_months_7: "July",
  pgi_organic_amendments_tillage_months_8: "August",
  pgi_organic_amendments_tillage_months_9: "September",
  pgi_organic_amendments_tillage_months_10: "October",
  pgi_organic_amendments_tillage_months_11: "November",
  pgi_organic_amendments_tillage_months_12: "December",
  pgi_organic_amendments_tillage_type_dd: "Tillage type",
  pgi_organic_amendments_tillage_type_dd_1: "Minimum tillage (<10 cm)",
  pgi_organic_amendments_tillage_type_dd_2: "Deep tillage (>10 cm)",
  pgi_organic_amendments_tillage_percentage: "Percentage %",
  pgi_organic_amendments_tillage_add: "Add a tillage event",

  pgi_organic_amendments_irrigation: "Irrigation",
  pgi_organic_amendments_irrigation_desc: "Select whether or not this parcel was irrigated in ",
  pgi_organic_amendments_irrigation_label: "Irrigation",
  pgi_organic_amendments_irrigation_dd_1: "Yes",
  pgi_organic_amendments_irrigation_dd_2: "No",

  pgi_organic_amendments_felled_trees: "Felled trees",
  pgi_organic_amendments_felled_trees_desc_1: "If any trees were felled on this parcel in ",
  pgi_organic_amendments_felled_trees_desc_2: " indicate per tree species the number of trees felled, and average diameter at breast height (DBH) of the trees in cm.",
  pgi_organic_amendments_felled_trees_label: "Felled trees",
  pgi_organic_amendments_felled_trees_info: "Diameter at Breast Height (DBH) is the diameter of a tree stem at 1.3 meters from the ground. Please provide the average DBH of the felled trees in cm.<br/><br/>If you want to delete a tree species, select the hyphen (-) in the tree species dropdown and the row will not be saved.",
  pgi_organic_amendments_felled_trees_dd: "Trees species",
  pgi_organic_amendments_felled_trees_dd_1: "Ash",
  pgi_organic_amendments_felled_trees_dd_2: "Eucalyptus",
  pgi_organic_amendments_felled_trees_dd_3: "Carob",
  pgi_organic_amendments_felled_trees_dd_4: "Cork Oak",
  pgi_organic_amendments_felled_trees_dd_5: "Cypress",
  pgi_organic_amendments_felled_trees_dd_6: "Holm Oak",
  pgi_organic_amendments_felled_trees_dd_7: "Maple/Sycamore",
  pgi_organic_amendments_felled_trees_dd_8: "Pine",
  pgi_organic_amendments_felled_trees_dd_9: "Fruit tree - Apple",
  pgi_organic_amendments_felled_trees_dd_10: "Fruit tree - Apricot",
  pgi_organic_amendments_felled_trees_dd_11: "Fruit tree - Avocado",
  pgi_organic_amendments_felled_trees_dd_12: "Fruit tree - Clementine",
  pgi_organic_amendments_felled_trees_dd_13: "Fruit tree - Date palm",
  pgi_organic_amendments_felled_trees_dd_14: "Fruit tree - Fig",
  pgi_organic_amendments_felled_trees_dd_15: "Fruit tree - Lemon",
  pgi_organic_amendments_felled_trees_dd_16: "Fruit tree - Mandarin",
  pgi_organic_amendments_felled_trees_dd_17: "Fruit tree - Mango",
  pgi_organic_amendments_felled_trees_dd_18: "Fruit tree - Olive",
  pgi_organic_amendments_felled_trees_dd_19: "Fruit tree - Orange",
  pgi_organic_amendments_felled_trees_dd_20: "Fruit tree - Peach",
  pgi_organic_amendments_felled_trees_dd_21: "Fruit tree - Pear",
  pgi_organic_amendments_felled_trees_dd_22: "Fruit tree - Pomegranate",
  pgi_organic_amendments_felled_trees_dd_23: "Fruit tree - Pomelo",
  pgi_organic_amendments_felled_trees_dd_24: "Fruit tree - Other",
  pgi_organic_amendments_felled_trees_dd_25: "Nut tree - Almond",
  pgi_organic_amendments_felled_trees_dd_26: "Nut tree - Chestnut",
  pgi_organic_amendments_felled_trees_dd_27: "Nut tree - Hazelnut",
  pgi_organic_amendments_felled_trees_dd_28: "Nut tree - Pistachio",
  pgi_organic_amendments_felled_trees_dd_29: "Nut tree - Walnut",
  pgi_organic_amendments_felled_trees_dd_30: "Nut tree - Other",
  pgi_organic_amendments_felled_trees_dd_31: "Other tree",
  pgi_organic_amendments_felled_trees_specific_name: "Specific name",
  pgi_organic_amendments_felled_trees_number: "Number of trees",
  pgi_organic_amendments_felled_trees_add_another: "Add another tree species",
  pgi_organic_amendments_save: "Save",

  //parcels - crop info
  pci_back: "< Back",
  pci_screen_title_1: "Parcel ",
  pci_screen_title_2: "Crops Information",
  pci_crops_info_btn: "General information >",
  pci_subtitle: 'Provide information about perennial and annual crops, permanent pastures and grasses, and fallow periods for this specific parcel. If the information is the same for a previously submitted year or parcel, you can copy the data by clicking "copy data from other year / parcel."',
  pci_copy_data: "Copy data from other year / parcel",
  pci_pre_fill: "Pre-fill parcel data",
  pci_pre_fill_1: "Choose the years and parcel name to copy data to the parcel you are currently editing.",
  pci_pre_fill_2: "If you select 'all years', data from the selected parcel  will be copied to the current parcel for all years.",
  pci_year: "Year",
  pci_all_years: "All years",
  pci_copy_data_btn: "Copy data",
  pci_copy_data_overwrite_heading: "Warning overwrite data",
  pci_copy_data_overwrite_msg: "You have already completed some information on this parcel. By copying data from another parcel, this information will be deleted, except for information about perennials. Are you sure you want to continue?",
  pci_copy_data_overwrite_continue: "Continue",
  pci_copy_data_overwrite_cancel: "Cancel",
  pci_perennial_title_1: "Trees and Other Perennial Crops | Total ",
  pci_perennial_title_2: " trees",
  pci_perennial_tree_label: "New tree or perennial crop",
  pci_perennial_subtitle: "Add all trees and perennial crops on this parcel. You can exclude trees from diverse systems where tree age or species are variable (e.g. dehesa/montado or forest systems). Tree crops are grouped in cohorts per species and year of planting (e.g. Fruit Tree - Olive, 2019).",
  pci_perennial_info: "Tree crops are grouped in cohorts per species and year of planting (e.g. Fruit Tree - Olive, 2019). They will automatically appear in the next years. For the next years you can adjust<br/>- the number of trees if trees from this cohort were removed<br/>- the Diameter at Breast Height (DBH) depending on how much the trees grew<br/>You can also delete the cohort if you removed all the trees.<br/><br/>Perennial crops added to previous years will automatically appear in the next years. You can delete them if you removed them from the parcel.",
  pci_perennial_subsection_heading: "Perennial crop / Tree type / Year of planting",
  pci_perennial_subsection_delete: "Delete",
  pci_perennial_subsection_desc: "Description*",
  pci_perennial_subsection_info: "Tree lifespan - If the tree plantation is periodically removed and replanted, indicate the typical age of the trees at which the orchards are renewed (e.g.  apple tree lifespan - 13 years).<br/><br/>If the trees are permanent and are not removed periodically you can leave this field blank.",
  pci_perennial_tree_list_dd: "Perennials and trees list*",
  "herbaceous perennials - strawberry": "Herbaceous perennials - Strawberry",
  "herbaceous perennials - other": "Herbaceous perennials - Other",
  "bushes/shrubs - blackberry": "Bushes/Shrubs - Blackberry",
  "bushes/shrubs - blueberry": "Bushes/Shrubs - Blueberry",
  "bushes/shrubs - broom": "Bushes/Shrubs - Broom",
  "bushes/shrubs - chamise/greasewood": "Bushes/Shrubs - Chamise/Greasewood",
  "bushes/shrubs - heather/erica": "Bushes/Shrubs - Heather/Erica",
  "bushes/shrubs - laustrine": "Bushes/Shrubs - Laustrine",
  "bushes/shrubs - lavender": "Bushes/Shrubs - Lavender",
  "bushes/shrubs - lentisk/mastic": "Bushes/Shrubs - Lentisk/Mastic",
  "bushes/shrubs - rockrose": "Bushes/Shrubs - Rockrose",
  "bushes/shrubs - rosemary": "Bushes/Shrubs - Rosemary",
  "bushes/shrubs - sagebrush": "Bushes/Shrubs - Sagebrush",
  "bushes/shrubs - other": "Bushes/Shrubs - Other",
  "fruit tree - apple": "Fruit Tree - Apple",
  "fruit tree - apricot": "Fruit Tree - Apricot",
  "fruit tree - avocado": "Fruit Tree - Avocado",
  "fruit tree - clementine": "Fruit Tree - Clementine",
  "fruit tree - date palm": "Fruit Tree - Date Palm",
  "fruit tree - fig": "Fruit Tree - Fig",
  "fruit tree - lemon": "Fruit Tree - Lemon",
  "fruit tree - mandarin": "Fruit Tree - Mandarin",
  "fruit tree - mango": "Fruit Tree - Mango",
  "fruit tree - olive": "Fruit Tree - Olive",
  "fruit tree - orange": "Fruit Tree - Orange",
  "fruit tree - peach": "Fruit Tree - Peach",
  "fruit tree - pear": "Fruit Tree - Pear",
  "fruit tree - pomegranate": "Fruit Tree - Pomegranate",
  "fruit tree - pomelo": "Fruit Tree - Pomelo",
  "fruit tree - other": "Fruit Tree - Other",
  "nut tree - almond": "Nut Tree - Almond",
  "nut tree - chestnut": "Nut Tree - Chestnut",
  "nut tree - hazelnut": "Nut Tree - Hazelnut",
  "nut tree - pistachio": "Nut Tree - Pistachio",
  "nut tree - walnut": "Nut Tree - Walnut",
  "nut tree - other": "Nut Tree - Other",
  "tree - ash": "Tree - Ash",
  "tree - eucalyptus": "Tree - Eucalyptus",
  "tree - carob": "Tree - Carob",
  "tree - cork oak": "Tree - Cork Oak",
  "tree - cypress": "Tree - Cypress",
  "tree - holm oak": "Tree - Holm Oak",
  "tree - maple/sycamore": "Tree - Maple/Sycamore",
  "tree - pine": "Tree - Pine",
  "tree - poplar": "Tree - Poplar",
  "tree - other": "Tree - Other",
  "vine - grapevine": "Vine - Grape vine",
  "vine - other": "Vine - Other",
  "other perennial crop": "Other Perennial Crop",
  pci_perennial_tree_list_specific: "Specific name",
  pci_perennial_tree_list_year_planting: "Year of planting*",
  pci_perennial_tree_list_year_before: "Before 2018",
  pci_perennial_tree_list_tree_span: "Tree lifespan (in years)",
  pci_perennial_tree_list_tree_span_yrs: "years",
  pci_perennial_tree_list_number_growth: "Number and Growth*",
  pci_perennial_tree_list_number_growth_info: "Diameter at Breast Height (DBH) is the diameter of a tree stem at 1.3 meters from the ground. Please provide the average DBH of the tree cohort in cm. For Olive tree crops, please provide the average diameter of the tree trunk at its base in cm.",
  pci_perennial_tree_list_number_trees: "Number of trees",
  pci_perennial_tree_list_dbh_field: "Average DBH",
  pci_perennial_tree_list_dbh_field_cm: "cm",
  pci_perennial_tree_list_harvest: "Harvest*",
  pci_perennial_tree_list_harvest_desc: "Organic material that was removed from the parcel",
  pci_perennial_tree_list_harvest_info: "Harvest is defined as organic material that was removed from the parcel. Add all the harvests from this perennial for the year you are editing and indicate:<br/><br/>Month - The month in which the harvest took place.<br/><br/>Type of harvest - Type of material that was harvested.<br/><br/>Quantity - The quantity that was harvested in tonnes of fresh or dry weight. Note that dry means completely dehydrated so that no moisture is left.<br/><br/>If there was no harvest, indicate zero as quantity.<br/><br/>If you want to delete a harvest, select the hyphen (-) in the month and the row will not be saved.",
  pci_perennial_tree_list_harvest_month: "Month",
  pci_perennial_tree_list_harvest_month_jan: "January",
  pci_perennial_tree_list_harvest_month_feb: "February",
  pci_perennial_tree_list_harvest_month_mar: "March",
  pci_perennial_tree_list_harvest_month_apr: "April",
  pci_perennial_tree_list_harvest_month_may: "May",
  pci_perennial_tree_list_harvest_month_jun: "June",
  pci_perennial_tree_list_harvest_month_jul: "July",
  pci_perennial_tree_list_harvest_month_aug: "August",
  pci_perennial_tree_list_harvest_month_sep: "September",
  pci_perennial_tree_list_harvest_month_oct: "October",
  pci_perennial_tree_list_harvest_month_nov: "November",
  pci_perennial_tree_list_harvest_month_dec: "December",
  pci_perennial_tree_list_harvest_type: "Type of harvest",
  pci_perennial_tree_list_harvest_type_dd_1: "Fruit",
  pci_perennial_tree_list_harvest_type_dd_2: "Nut",
  pci_perennial_tree_list_harvest_type_dd_3: "Bark / Cork",
  pci_perennial_tree_list_harvest_type_dd_4: "Leaves, stalks, herbs (non-woody)",
  pci_perennial_tree_list_harvest_type_dd_5: "Pruning or woody material",
  pci_perennial_tree_list_harvest_type_dd_6: "Grass",
  pci_perennial_tree_list_harvest_type_qty: "Quantity",
  pci_perennial_tree_list_harvest_fresh_dry_1: "Tonnes - Fresh",
  pci_perennial_tree_list_harvest_fresh_dry_2: "Tonnes - Dry",
  pci_perennial_tree_list_harvest_fresh_add: "Add harvest",
  pci_perennial_tree_list_residue: "Residue",
  pci_perennial_tree_list_residue_desc: "Organic material left on the field",
  pci_perennial_tree_list_residue_info: "Residue is defined as plant/organic material that was left on the field. Add all the residues from this perennial for the year you are editing and indicate:<br/><br/>Month - The month in which the residue was left on field.<br/><br/>Type of harvest - Type of material of the residue.<br/><br/>Quantity - The quantity of residues that was left on field in tonnes of fresh or dry weight. Note that dry means completely dehydrated so that no moisture is left.<br/><br/>If there was no residue, indicate zero in the quantity or leave this row blank.<br/><br/>If you want to delete a residue, select the hyphen (-) in the month and the row will not be saved.",
  pci_perennial_tree_list_residue_month: "Month",
  pci_perennial_tree_list_residue_month_jan: "January",
  pci_perennial_tree_list_residue_month_feb: "February",
  pci_perennial_tree_list_residue_month_mar: "March",
  pci_perennial_tree_list_residue_month_apr: "April",
  pci_perennial_tree_list_residue_month_may: "May",
  pci_perennial_tree_list_residue_month_jun: "June",
  pci_perennial_tree_list_residue_month_jul: "July",
  pci_perennial_tree_list_residue_month_aug: "August",
  pci_perennial_tree_list_residue_month_sep: "September",
  pci_perennial_tree_list_residue_month_oct: "October",
  pci_perennial_tree_list_residue_month_nov: "November",
  pci_perennial_tree_list_residue_month_dec: "December",
  pci_perennial_tree_list_residue_dd_type: "Type of residue",
  pci_perennial_tree_list_residue_type_dd_1: "Fruit",
  pci_perennial_tree_list_residue_type_dd_2: "Nut",
  pci_perennial_tree_list_residue_type_dd_3: "Bark / Cork",
  pci_perennial_tree_list_residue_type_dd_4: "Leaves, stalks, herbs (non-woody)",
  pci_perennial_tree_list_residue_type_dd_5: "Pruning or woody material",
  pci_perennial_tree_list_residue_type_dd_6: "Grass",
  pci_perennial_tree_list_residue_type_qty: "Quantity",
  pci_perennial_tree_list_residue_fresh_dry_1: "Tonnes - Fresh",
  pci_perennial_tree_list_residue_fresh_dry_2: "Tonnes - Dry",
  pci_perennial_tree_list_residue_fresh_add: "Add residue",
  pci_perennial_tree_list_add_new: "Add a tree or other perennial crop",

  pci_annual_crops_title_1: "Annual Crops (",
  pci_annual_crops_title_2: ")",
  pci_annual_crops_subtitle: "Add all annual crops that <strong>were harvested</strong> in {{year}} on this parcel. If you sowed the crop in {{year}} and harvested in {{yearAfter}}, you can add it in {{yearAfter}}.",
  pci_annual_crops_label: "Annual Crop",
  pci_annual_crops_delete: "Delete",
  pci_annual_crops_desc: "Description*",
  pci_annual_crops_info: 'Select the annual crop type from the list. If your crop is not in the list, select the type followed by "other" (e.g. Legumes - other) and provide the specific name.<br/><br/>A cover crop can be either:<br/>- Single or multiple grasses (Poaceae)<br/>- Single or multiple legumes (Fabaceae)<br/>- Mix of grasses and legumes (Poaceae and Fabaceae)<br/>- Multispecies Cover Crop should be a mix of at least 3 species of plants, with the first two being a grass (Poaceae) and a legume (Fabaceae), and the third of one of the following families: Brassicaceae, Amaranthaceae, Linaceae, Plantaginaceae, Asteraceae, Polygonaceae, Boraginaceae, Malvaceae, Rosaceae, Cucurbitaceae — also called non-leguminous forbs',
  pci_annual_crops_dd_list: "Annual crops list",
  "aromatics": "Aromatics",
  "cover crop - single or multiple grasses": "Cover crop - Single or multiple grasses",
  "cover crop - single or multiple legumes": "Cover crop - Single or multiple legumes",
  "cover crop - mix of grass and legumes": "Cover crop - Mix of grass and legumes",
  "cover crop - multi-species cover crop": "Cover crop - Multi-species cover crop",
  "cover crop - other": "Cover crop - Other",
  "forage - grass-clover mixtures": "Forage - Grass-Clover Mixtures",
  "forage - italian ryegrass": "Forage - Italian Ryegrass",
  "forage - n-fixing forages": "Forage - N-fixing forages",
  "forage - non-n-fixing forages": "Forage - Non-N-fixing forages",
  "forage - non-legume hay": "Forage - Non-legume hay",
  "forage - sudangrass": "Forage - Sudangrass",
  "forage - other": "Forage - Other",
  "grains/cereals - barley": "Grains/Cereals - Barley",
  "grains/cereals - maize": "Grains/Cereals - Maize",
  "grains/cereals - millet": "Grains/Cereals - Millet",
  "grains/cereals - oats": "Grains/Cereals - Oats",
  "grains/cereals - rice": "Grains/Cereals - Rice",
  "grains/cereals - rye": "Grains/Cereals - Rye",
  "grains/cereals - sorghum": "Grains/Cereals - Sorghum",
  "grains/cereals - spring wheat": "Grains/Cereals - Spring Wheat",
  "grains/cereals - triticale": "Grains/Cereals - Triticale",
  "grains/cereals - wheat": "Grains/Cereals - Wheat",
  "grains/cereals - winter wheat": "Grains/Cereals - Winter Wheat",
  "grains/cereals - other": "Grains/Cereals - Other",
  "legumes - alfalfa": "Legumes - Alfalfa",
  "legumes - beans": "Legumes - Beans",
  "legumes - cowpea": "Legumes - Cowpea",
  "legumes - lupins": "Legumes - Lupins",
  "legumes - peas": "Legumes - Peas",
  "legumes - soybeans": "Legumes - Soybeans",
  "legumes - vetch": "Legumes - Vetch",
  "legumes - other": "Legumes - Other",
  "roots/tubers - potatoes": "Roots/Tubers - Potatoes",
  "roots/tubers - beets": "Roots/Tubers - Beets",
  "roots/tubers - other": "Roots/Tubers - Other",
  "fruits and vegetables - squash": "Fruits and Vegetables - Squash",
  "fruits and vegetables - other fruit": "Fruits and Vegetables - Other fruit",
  "fruits and vegetables - other vegetable": "Fruits and Vegetables - Other vegetable",
  "oilseeds - peanuts": "Oilseeds - Peanuts",
  "oilseeds - rapeseed": "Oilseeds - Rapeseed",
  "oilseeds - sunflower": "Oilseeds - Sunflower",
  "oilseeds - other": "Oilseeds - Other",
  "other annual crop": "Other annual crop",
  pci_annual_crops_specific_name: "Specific name",
  pci_annual_crops_crops_cycle: "Crop cycle*",
  pci_annual_crops_crops_cycle_info: "Note that you can only select harvest months in the year that you are currently editing. If you sowed a crop this year but harvested it in the following year, add it while editing the following year.",
  pci_annual_crops_harvest_month_dd: "Harvest month",
  pci_annual_crops_harvest_month_jan: "January",
  pci_annual_crops_harvest_month_feb: "February",
  pci_annual_crops_harvest_month_mar: "March",
  pci_annual_crops_harvest_month_apr: "April",
  pci_annual_crops_harvest_month_may: "May",
  pci_annual_crops_harvest_month_jun: "June",
  pci_annual_crops_harvest_month_jul: "July",
  pci_annual_crops_harvest_month_aug: "August",
  pci_annual_crops_harvest_month_sep: "September",
  pci_annual_crops_harvest_month_oct: "October",
  pci_annual_crops_harvest_month_nov: "November",
  pci_annual_crops_harvest_month_dec: "December",

  pci_annual_crops_sowing_month_dd: "Sowing month",
  pci_annual_crops_sowing_month_jan: "January",
  pci_annual_crops_sowing_month_feb: "February",
  pci_annual_crops_sowing_month_mar: "March",
  pci_annual_crops_sowing_month_apr: "April",
  pci_annual_crops_sowing_month_may: "May",
  pci_annual_crops_sowing_month_jun: "June",
  pci_annual_crops_sowing_month_jul: "July",
  pci_annual_crops_sowing_month_aug: "August",
  pci_annual_crops_sowing_month_sep: "September",
  pci_annual_crops_sowing_month_oct: "October",
  pci_annual_crops_sowing_month_nov: "November",
  pci_annual_crops_sowing_month_dec: "December",

  pci_annual_crops_harvest_label: "Harvest*",
  pci_annual_crops_harvest_label_desc: "Organic material that was removed from the parcel",
  pci_annual_crops_harvest_label_info: "Harvest is defined as organic material that was removed from the parcel. Add all the harvest types of this crop and indicate the quantity that was harvested in tonnes of fresh or dry weight. Note that dry means completely dehydrated so that no moisture is left.<br/><br/><strong>If there was no harvest, indicate zero as quantity.</strong><br/><br/>If you want to delete a harvest, select the hyphen (-) in the Harvest type list and the row will not be saved.",

  pci_annual_crops_harvest_type_dd: "Harvest type",
  pci_annual_crops_harvest_type_dd_1: "Grains/Cereals",
  pci_annual_crops_harvest_type_dd_2: "Seeds",
  pci_annual_crops_harvest_type_dd_3: "Fruits/Vegetables",
  pci_annual_crops_harvest_type_dd_4: "Roots/Tubers",
  pci_annual_crops_harvest_type_dd_5: "Grass",
  pci_annual_crops_harvest_type_dd_6: "Leaves, stalks, herbs (non-woody)",
  pci_annual_crops_harvest_type_qty: "Quantity",
  pci_annual_crops_harvest_qty_fresh: "Tonnes - Fresh",
  pci_annual_crops_harvest_qty_dry: "Tonnes - Dry",
  pci_annual_crops_harvest_add_new: "Add harvest",
  pci_annual_crops_residue_label: "Residue",
  pci_annual_crops_residue_label_desc: "Organic material left on the field",
  pci_annual_crops_residue_info_1: "Residue is defined as plant/organic material that was left on the field Add all the types of residues from this crop and indicate the quantity that was left on field in tonnes of fresh or dry weight. Note that dry means completely dehydrated so that no moisture is left.",
  pci_annual_crops_residue_info_2: "If there was no residue, indicate zero in the quantity.",
  pci_annual_crops_residue_info_3: "If you want to delete a residue, select the hyphen (-) in the Residue type list and the row will not be saved.",
  pci_annual_crops_residue_type_dd: "Residue type",
  pci_annual_crops_residue_type_dd_1: "Grains/Cereals",
  pci_annual_crops_residue_type_dd_2: "Seeds",
  pci_annual_crops_residue_type_dd_3: "Fruits/Vegetables",
  pci_annual_crops_residue_type_dd_4: "Roots/Tubers",
  pci_annual_crops_residue_type_dd_5: "Grass",
  pci_annual_crops_residue_type_dd_6: "Leaves, stalks, herbs (non-woody)",
  pci_annual_crops_residue_type_qty: "Quantity",
  pci_annual_crops_residue_qty_fresh: "Tonnes - Fresh",
  pci_annual_crops_residue_qty_dry: "Tonnes - Dry",
  pci_annual_crops_residue_add_new: "Add residue",
  pci_annual_crops_add_new_row: "Add an annual crop",


  pci_pastures_title_1: "Pastures and Grasses",
  pci_pastures_subtitle_1: "If (a part of) this parcel had permanent pasture or grasses during ",
  pci_pastures_subtitle_2: ", indicate the percentage of the parcel area, the productivity, and grass harvested.",
  pci_pastures_permanent_pastures: "Percentage of the parcel that was permanent pasture or grasses",
  pci_pastures_permanent_pastures_info: "Estimate the area of the parcel that was pasture or grasses. If the whole parcel consists of grasses, this is 100%. Else, provide a best approximation of how much of the surface is covered by grasses. This can be any number between 0% and 100%.",
  pci_pastures_permanent_pastures_percent: "Percentage",
  pci_pastures_permanent_pastures_peak: "Estimated TOTAL productivity*",
  pci_pastures_permanent_pastures_peak_info_1: `Please estimate here the TOTAL amount of biomass that your pasture has produced in this year. If you harvest or graze this parcel several times please add several productivity values OR add them all into one TOTAL value.
<br/> Harvest amounts (biomass removed from the parcel) need to be indicated below. Grazing amounts are not asked for here since they are automatically calculated by the model. `,
  pci_pastures_permanent_pastures_peak_month: "Month",
  pci_pastures_permanent_pastures_peak_month_jan: "January",
  pci_pastures_permanent_pastures_peak_month_feb: "February",
  pci_pastures_permanent_pastures_peak_month_mar: "March",
  pci_pastures_permanent_pastures_peak_month_apr: "April",
  pci_pastures_permanent_pastures_peak_month_may: "May",
  pci_pastures_permanent_pastures_peak_month_jun: "June",
  pci_pastures_permanent_pastures_peak_month_jul: "July",
  pci_pastures_permanent_pastures_peak_month_aug: "August",
  pci_pastures_permanent_pastures_peak_month_sep: "September",
  pci_pastures_permanent_pastures_peak_month_oct: "October",
  pci_pastures_permanent_pastures_peak_month_nov: "November",
  pci_pastures_permanent_pastures_peak_month_dec: "December",
  pci_pastures_permanent_pastures_peak_qty: "Quantity",
  pci_pastures_permanent_pastures_peak_fresh: "kg/m² - Fresh",
  pci_pastures_permanent_pastures_peak_dry: "kg/m² - Dry",
  pci_pastures_permanent_pastures_add_estimate: "Add another estimate (max 5)",
  pci_pastures_grass_harvested: "Grass harvested",
  pci_pastures_grass_harvested_desc: "The quantity of grass harvested from this parcel. This does not include grazing.",
  pci_pastures_grass_harvested_info_1: "Add all the grass harvests in this parcel and indicate the quantity that was harvested in tonnes of fresh or dry weight. Note that dry means completely dehydrated so that no moisture is left.",
  pci_pastures_grass_harvested_info_2: "If there was no harvest, indicate zero in the quantity, or leave this row blank.",
  pci_pastures_grass_harvested_info_3: "If you want to delete a harvest, select the hyphen (-) in the month and the row will not be saved.",
  pci_pastures_grass_harvested_month: "Month",
  pci_pastures_grass_harvested_month_jan: "January",
  pci_pastures_grass_harvested_month_feb: "February",
  pci_pastures_grass_harvested_month_mar: "March",
  pci_pastures_grass_harvested_month_apr: "April",
  pci_pastures_grass_harvested_month_may: "May",
  pci_pastures_grass_harvested_month_jun: "June",
  pci_pastures_grass_harvested_month_jul: "July",
  pci_pastures_grass_harvested_month_aug: "August",
  pci_pastures_grass_harvested_month_sep: "September",
  pci_pastures_grass_harvested_month_oct: "October",
  pci_pastures_grass_harvested_month_nov: "November",
  pci_pastures_grass_harvested_month_dec: "December",
  pci_pastures_grass_harvested_qty: "Quantity",
  pci_pastures_grass_harvested_qty_fresh: "Tonnes - Fresh",
  pci_pastures_grass_harvested_qty_dry: "Tonnes - Dry",
  pci_pastures_grass_harvested_add_new: "Add another harvest",

  pci_pastures_fallow_title: "Fallow periods",
  pci_pastures_fallow_subtitle: "Add all the periods this parcel was left fallow in ",
  pci_pastures_fallow_info: "In a fallow period the soil is left without being sown or cultivated for a definite period of time.",
  pci_pastures_fallow_period_label: "Fallow period",
  pci_pastures_fallow_period_delete: "Delete",
  pci_pastures_fallow_period_desc: "Description*",
  pci_pastures_fallow_period_info: "Indicate the condition of the soil during the fallow period by choosing the fallow type:<br/><br/>Productive fallow - The soil is partially or completely covered with spontaneous vegetation or residues.<br/><br/>Bare soil - The soil is completely uncovered<br/>If you select bare soil, indicate the percentage of the area that was bare in this parcel.",
  pci_pastures_fallow_period_type_dd: "Fallow type",
  pci_pastures_fallow_period_type_dd_1: "Productive fallow",
  pci_pastures_fallow_period_type_dd_2: "Bare soil",
  pci_pastures_fallow_period_percent: "Percentage %",
  pci_pastures_fallow_period_cycle: "Cycle*",
  pci_pastures_fallow_period_cycle_info_1: "Indicate the end and start month of the fallow period.",
  pci_pastures_fallow_period_cycle_info_2: "If the parcel was left fallow for less than 1 month, choose this month as both end and start month.",
  pci_pastures_fallow_period_cycle_info_3: "If the parcel was left fallow for the whole year or more, choose December as end month and January as start month. Add the remaining fallow period in the next year while editing the next year tab.",
  pci_pastures_fallow_period_end_month: "End month",
  pci_pastures_fallow_period_end_month_jan: "January",
  pci_pastures_fallow_period_end_month_feb: "February",
  pci_pastures_fallow_period_end_month_mar: "March",
  pci_pastures_fallow_period_end_month_apr: "April",
  pci_pastures_fallow_period_end_month_may: "May",
  pci_pastures_fallow_period_end_month_jun: "June",
  pci_pastures_fallow_period_end_month_jul: "July",
  pci_pastures_fallow_period_end_month_aug: "August",
  pci_pastures_fallow_period_end_month_sep: "September",
  pci_pastures_fallow_period_end_month_oct: "October",
  pci_pastures_fallow_period_end_month_nov: "November",
  pci_pastures_fallow_period_end_month_dec: "December",
  pci_pastures_fallow_period_start_month: "Start month",
  pci_pastures_fallow_period_start_month_jan: "January",
  pci_pastures_fallow_period_start_month_feb: "February",
  pci_pastures_fallow_period_start_month_mar: "March",
  pci_pastures_fallow_period_start_month_apr: "April",
  pci_pastures_fallow_period_start_month_may: "May",
  pci_pastures_fallow_period_start_month_jun: "June",
  pci_pastures_fallow_period_start_month_jul: "July",
  pci_pastures_fallow_period_start_month_aug: "August",
  pci_pastures_fallow_period_start_month_sep: "September",
  pci_pastures_fallow_period_start_month_oct: "October",
  pci_pastures_fallow_period_start_month_nov: "November",
  pci_pastures_fallow_period_start_month_dec: "December",
  pci_pastures_fallow_period_harvest: "Harvest",
  pci_pastures_fallow_period_harvest_desc: "Organic material that was removed from the parcel",
  pci_pastures_fallow_period_harvest_info_1: "Harvest is defined as organic material that was removed from the parcel. Add all the harvests from the productive fallow in this parcel and indicate the quantity that was harvested in tonnes of fresh or dry weight. Note that dry means completely dehydrated so that no moisture is left.",
  pci_pastures_fallow_period_harvest_info_2: "If there was no harvest, indicate zero in the quantity, or leave this row blank.",
  pci_pastures_fallow_period_harvest_info_3: "If you want to delete a harvest, select the hyphen (-) in the month and the row will not be saved.",
  pci_pastures_fallow_period_harvest_type_dd: "Harvest",
  pci_pastures_fallow_period_harvest_type_dd_1: "Leaves, stalks, herbs (non-woody)",
  pci_pastures_fallow_period_harvest_type_dd_2: "Grass",
  pci_pastures_fallow_period_harvest_type_qty: "Quantity",
  pci_pastures_fallow_period_harvest_type_qty_fresh: "Tonnes - Fresh",
  pci_pastures_fallow_period_harvest_type_qty_dry: "Tonnes - Dry",
  pci_pastures_fallow_period_harvest_add_harvest: "Add harvest",
  pci_pastures_fallow_period_residue: "Residue",
  pci_pastures_fallow_period_residue_desc: "Organic material left on the field",
  pci_pastures_fallow_period_residue_info_1: "Residue is defined as plant/organic material that was left on the field. Add all the residues from the productive fallow in this parcel and indicate the quantity of residue in tonnes of fresh or dry weight. Note that dry means completely dehydrated so that no moisture is left.",
  pci_pastures_fallow_period_residue_info_2: "If there was no residue, indicate zero in the quantity, or leave this row blank.",
  pci_pastures_fallow_period_residue_info_3: "If you want to delete a harvest, select the hyphen (-) in the month and the row will not be saved.",
  pci_pastures_fallow_period_residue_type_dd: "Residue",
  pci_pastures_fallow_period_residue_type_dd_1: "Leaves, stalks, herbs (non-woody)",
  pci_pastures_fallow_period_residue_type_dd_2: "Grass",
  pci_pastures_fallow_period_residue_qty: "Quantity",
  pci_pastures_fallow_period_residue_qty_fresh: "Tonnes - Fresh",
  pci_pastures_fallow_period_residue_qty_dry: "Tonnes - Dry",
  pci_pastures_fallow_period_residue_add_new: "Add residue",
  pci_pastures_fallow_period_add_new: "Add a fallow period",
  pci_save_button: "Save",
  pci_percent_parcel: "% of parcel",

  // livestock - in farm
  ls_infarm_title: "Your livestock",
  ls_infarm_desc: "The livestock documents you provided can be found here. Below this section, you will find your livestock counts based on the documents you provided. Update this page by providing details about your livestock management and practices.",
  ls_infarm_info: "If the numbers of animals do not correspond to the reality of the farm, please get in touch with Iris or Maria.",
  ls_infarm_uploaded_docs: "Uploaded documents",
  ls_infarm_grazing: "Grazing management",
  ls_infarm_grazing_desc_1: "Indicate the main form of grazing management on your farm. If you do planned grazing for at least one species select 'Planned grazing'. Note that in the parcel information we will account more in detail for the effects of planned grazing. ",
  ls_infarm_grazing_desc_2: "Unplanned grazing - Animals are generally left to graze on larger areas. There has been done no or minimal planning to move animals in order to optimize pasture productivity and minimize overgrazing.  ",
  ls_infarm_grazing_desc_3: "Planned grazing - Grazing areas are subdivided in smaller plots. Animals are  regularly moved between these plots to optimize pasture productivity and minimize overgrazing.",
  ls_infarm_grazing_unplanned: "Unplanned grazing",
  ls_infarm_grazing_planned: "Planned grazing",
  ls_infarm_cattle: "Cattle",
  ls_infarm_cattle_females: "Mature females, non-dairy",
  ls_infarm_cattle_females2: "(> 2 years)",
  ls_infarm_cattle_males: "Mature males (> 2 years)",
  ls_infarm_cattle_males_info: "Mature male cattle of any species  ( > 2 years).",
  ls_infarm_cattle_growing: "Growing Cattle",
  ls_infarm_cattle_growing2: "(6 - 24 months)",
  ls_infarm_cattle_growing_info: "Growing cattle of all genders and any species (6 - 24 months).",
  ls_infarm_cattle_calves: "Calves ( < 6 months) ",
  ls_infarm_cattle_calves_info: "The number of young animals is calculated automatically by multiplying the female fertility rate with the number of mature females(dairy and non- dairy). Please enter female fertility information for cattle and dairy cows to see a number here. <br> <br> If the number doesn't correspond with reality, please get in touch with Iris or Maria.",
  ls_infarm_purchased_info: "This is the number of young animals you have purchased directly, that were not born on your farm. <br> <br> If the number doesn't correspond with reality, please get in touch with Iris or Maria.",
  ls_infarm_grazing_days: "Grazing days per year",
  ls_infarm_grazing_days_info: "The number of days your animals were grazing on your farm i.e. not stabled or outside the farm.",
  ls_infarm_grazing_days_unit: "days",
  ls_infarm_manure: "Primary method of treating manure from stabled animals",
  ls_infarm_manure_info_1: "This refers to the manure from the animals that are stabled or indoors, not the manure from the animals grazing. If your animals are always grazing you can leave this blank.",
  ls_infarm_manure_info_2: "Otherwise, select the primary way you treated the manure:",
  ls_infarm_manure_info_3: "Exported from farm - Manure is collected and exported from the farm",
  ls_infarm_manure_info_4: "Solid storage - Dung and urine are excreted in a stable. The solids (with or without litter) are collected and stored in bulk for a long time (months)  ",
  ls_infarm_manure_info_5: "Pit storage > 1 month - Dung and urine are stored in a pit below the stables for more than a month.",
  ls_infarm_manure_info_6: "Liquid / Slurry - Dung and urine are collected and transported as a liquid to tanks for storage. Liquid may be stored for a long time (months).",
  ls_infarm_manure_info_7: "Dry lot - Animals are in unpaved feedlots where the manure is allowed to dry until it is periodically removed.",
  ls_infarm_manure_info_8: "Anaerobic digestion - biogas - Dung and urine in liquid / slurry are collected and anaerobically digested. CH4 may be burned, flared or vented.",
  ls_infarm_manure_info_9: "Anaerobic lagoon - Manure is transported to lagoons by a flush system with water. The manure stays in the lagoon for at least one month. The water from the lagoon may be recycled as flush water or used to irrigate and fertilise fields.",
  ls_infarm_manure_info_10: "Daily Spread - Dung and urine are collected daily e.g. through scraping. The collected waste is applied to fields.",
  ls_infarm_manure_info_11: "Composting - Intensive - Dung and urine are collected and placed in a vessel or tunnel, there is forced aeration of the manure.",
  ls_infarm_manure_info_12: "Composting - Extensive - Dung and urine collected, stacked and regularly turned for aeration.",
  ls_infarm_manure_info_13: "Deep litter - Cattle/swine dung and urine are excreted on stall floor. The accumulated waste is removed after a long time.",
  ls_infarm_manure_info_14: "Aerobic treatment - Dung and urine are collected as a liquid. The waste undergoes forced aeration, or treated in aerobic pond or wetland systems to provide nitrification and denitrification.",
  ls_infarm_manure_treatment: "Manure treatment",
  ls_infarm_manure_exported: "Exported from farm",
  ls_infarm_manure_solid: "Solid storage",
  ls_infarm_manure_pit: "Pit storage > 1 month",
  ls_infarm_manure_liquid: "Liquid / Slurry",
  ls_infarm_manure_dry: "Dry lot",
  ls_infarm_manure_biogas: "Anaerobic digestion - biogas",
  ls_infarm_manure_anae_lagoon: "Anaerobic lagoon",
  ls_infarm_manure_spread: "Daily Spread",
  ls_infarm_manure_comp_intensive: "Composting - Intensive",
  ls_infarm_manure_comp_extensive: "Composting - Extensive",
  ls_infarm_manure_deep_litter: "Deep litter",
  ls_infarm_manure_aerobic: "Aerobic treatment",
  ls_infarm_fertility: "Female fertility rate",
  ls_infarm_fertility_info_1: "This is the average number of offspring per female of reproductive age of this species. E.g. If you have 9 calves born to 20 cows, your female fertility rate is 9/20 = 0.45",
  ls_infarm_fertility_info_2: "Based on the fertility rate, the number of calves is calculated.",
  ls_infarm_dairy_cows: "Dairy Cows",
  ls_infarm_dairy_cows_mature: "Mature females (> 2 years)",
  ls_infarm_dairy_cows_mature_info: "Mature female dairy cattle ( > 2 years).",
  ls_infarm_swine: "Swine",
  ls_infarm_swine_sows: "Sows (> 6 months)",
  ls_infarm_swine_sows_info: "Mature female swine for reproduction ( > 6 months).",
  ls_infarm_swine_boars: "Boars (> 6 months)",
  ls_infarm_swine_boars_info: "Mature male swine for reproduction ( > 6 months).",
  ls_infarm_swine_finishers: "Finishers (3 - 6 months)",
  ls_infarm_swine_finishers_info: "Non-reproductive swine for meat, between 3 - 6 months of age (all genders).",
  ls_infarm_swine_other: "Other swine",
  ls_infarm_swine_other_info: "No information is available to categorise these animals into finishers, sows, or boars.",
  ls_infarm_swine_piglets: "Piglets",
  ls_infarm_young_animals_info: "The number of young animals is calculated by multiplying the female fertility rate with the number of mature females. Please enter female fertility information in the mature animals section to see a number here. <br> <br> If the number doesn't correspond with reality, please get in touch with Iris or Maria.",
  ls_infarm_swine_fertility_info_1: "This is the average number of offspring per female of reproductive age of this species. E.g. If you have 32  piglets born to 4 sows, your female fertility rate is 32/4 = 8",
  ls_infarm_swine_fertility_info_2: "Based on the fertility rate, the number of piglets is calculated.",
  ls_infarm_chicken: "Chicken",
  ls_infarm_chicken_layer: "Layer hens",
  ls_infarm_chicken_layer_info: "Hens for laying eggs.",
  ls_infarm_chicken_broilers: "Broilers",
  ls_infarm_chicken_broilers_info: "Chicken for meat.",
  ls_infarm_chicken_other: "Other chicken",
  ls_infarm_chicken_other_info: "No information is available to categorise these chickens into layer hens or broilers.",
  ls_infarm_sheep: "Sheep",
  ls_infarm_sheep_females: "Mature females (> 1 year)",
  ls_infarm_sheep_males: "Mature males (> 1 year)",
  ls_infarm_sheep_lambs: "Lambs (< 1 year)",
  ls_infarm_sheep_fertility_info_1: "This is the average number of offspring per female of reproductive age of this species. E.g. If you have 25  lambs born to 20 ewes, your female fertility rate is 25/20 = 1.25",
  ls_infarm_sheep_fertility_info_2: "Based on the fertility rate, the number of lambs is calculated.",
  ls_infarm_goats: "Goats",
  ls_infarm_goats_females: "Mature females (> 1 year)",
  ls_infarm_goats_males: "Mature males (> 1 year)",
  ls_infarm_goats_kids: "Goat kids (< 1 year)",
  ls_infarm_goats_fertility_info_1: "This is the average number of offspring per female of reproductive age of this species. E.g. If you have 25 goat kids born to 20 mature female goats, your female fertility rate is 25/20 = 1.25",
  ls_infarm_goats_fertility_info_2: "Based on the fertility rate, the number of goat kids is calculated.",
  ls_infarm_turkeys: "Turkeys",
  ls_infarm_ducks: "Ducks",
  ls_infarm_add_out_farm: "Add external animals",

  ls_no_docs_title: "Livestock",
  ls_no_docs_1: "Note that the process for collecting livestock data is a two step process:",
  ls_no_docs_2: "Step 1. You upload  your official livestock documents. Based on these documents, our agronomist will calculate your animal numbers.",
  ls_no_docs_3: "Step 2. You provide information about the management of your animals.",
  ls_no_docs_4: "Currently, we are still reviewing your livestock documents. When we have reviewed them, you can proceed to step 2.",
  ls_no_docs_5: "If you haven't uploaded all your livestock documents,  upload them by clicking the button below. Please notify Iris or Maria when you have uploaded them.",
  ls_no_docs_6: 'If you allowed animals from outside your farm to graze in your farm, you can add details about that by clicking "Add external animals" below.',
  ls_no_docs_button: "Go to the upload screen",

  ls_outfarm_title: "Animals from outside your farm",
  ls_outfarm_desc: "If livestock owned by another farmer grazed on your parcels, add them below. Select the species and provide the number of animals and grazing days for each year.",
  ls_outfarm_species: "Species",
  ls_outfarm_cattle: "Cattle (non-dairy)",
  ls_outfarm_dairy_cows: "Dairy Cows",
  ls_outfarm_swine: "Swine",
  ls_outfarm_sheep: "Sheep",
  ls_outfarm_goats: "Goats",
  ls_outfarm_num_animals: "Number of animals",
  ls_outfarm_info: "If you want to delete a species, select the hyphen (-) in the Species list and the row will not be saved.",
  ls_outfarm_grazing_days: "Grazing days per year",
  ls_outfarm_grazing_days_info: "The number of days the animals were grazing on your farm i.e. not stabled or outside the farm.",
  ls_outfarm_grazing_days_num: "Number of grazing days",
  ls_outfarm_add: "+ Add livestock species",
  ls_outfarm_no_animals: "I don't have any animals from outside",

  generic_litres: 'litres',
  generic_area: 'Area',
  generic_hectares: 'hectares',
  generic_complete_fields: 'Complete the missing fields marked in red before saving your data.',
  generic_complete_yes: 'Complete information',
  generic_complete_no: 'Delete incomplete information',
  generic_complete_title: 'Missing information',
  general_parcel: "Parcel",
  generic_calculated: 'calculated',
  generic_purchased: 'purchased',



  "productive fallow": "Productive fallow",
  "bare soil": "Bare soil",

  validation_fuel: 'The indicator for this page will turn green when data for petrol and diesel usage for all years has been provided.',
  validation_livestock: 'The indicator for this page will turn green when data about livestock management practices for all species for all years has been provided.',
  validation_map: `The indicator for this page will turn green when valid data has been entered in all your parcels (general info and crops pages) for all years.
<br/><br/>
    Additionally, the farm-wide information page must have valid data in it for all years.`,
  validation_farmwide: 'The indicator for this page will turn green when data about synthetic fertiliser usage, and the imported percentage of organic amendments has been provided for your whole farm for all years.',
  validation_parcel: 'The indicator for this parcel will turn green when both the general info and crops pages have valid data in them. Hover over the progress indicators of the respective pages to know more.',
  validation_crops: `The indicator for this page will turn green when valid information has been entered in at least one of the following land use types for the parcel for each year:
<br/><br/>
- Perennials and trees <br/>
- Annual crops<br/>
- Pastures<br/>
- Fallow periods<br/>
<br/>
<br/>
If you started entering data for any of the above types of land use, you must complete it.`,
  validation_landuse: 'The indicator for this page will turn green when data about primary land use, and irrigation is provided for all years. ',

  documents_collection: "Documents collection",
  data_collection: "Data collection",
  submitted_for_review: "Submitted for review",
  internal_review: "Internal review",
  audit: "Audit",
  carbon_plus_credit_posting: "Carbon+ Credit posting",
  carbon_plus_credit_sales: "Carbon+ Credit sales",


  subtitle_documents_collection: "",
  subtitle_farm_data_yet_to_submit: "The platform is currently open for data collection. Click 'Submit for review' to submit your data",
  subtitle_submitted_review: "We will soon be reviewing your data. Contact us if you need to make any changes.",
  subtitle_internal_review: "We are reviewing your data and documents. Contact us if you need to make any changes.",
  subtitle_audit: "Your data has been submitted to the auditor. We will contact you with more information.",
  subtitle_carbon_credit_posting: "Your Carbon+ Credits have been posted to the registry. Please contact us to know your credit numbers.",
  subtitle_carbon_sales: "Your Carbon+ Credits have been sold, and the proceeds have been transferred to your account.",

  next_steps: "Next steps",

};