import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";

import "../css/FarmerOnboardingSteps.css";

import {
  Backdrop,
  Box,
  Snackbar,
  Step,
  StepButton,
  StepLabel,
  Stepper,
  Typography
} from "@mui/material";

import FinalStepInOnboardingComponent from "./FinalStepInOnboardingComponent";
import Step2IndirectFuelUsageComponent from "./IndirectFuel/Step2IndirectFuelUsageComponent";
import Step1FarmInfoComponent from "./Step1FarmInfoComponent";
import Step2EnergyUsageComponent from "./Step2EnergyUsageComponent";


import {
  INPUT_TEMPLATE_STEP_1,
  INPUT_TEMPLATE_STEP_2,
  INPUT_TEMPLATE_STEP_3,
  INPUT_TEMPLATE_STEP_4,
  INPUT_TEMPLATE_STEP_5,
  INPUT_TEMPLATE_STEP_6,
  INPUT_TEMPLATE_STEP_7
} from "../constants/constants";


import InFarmLivestockComponent from "./InFarm/InFarmLivestockComponent";
import MapParcelsComponent from "./MapAndParcels/MapParcelsComponent";
import OutFarmLivestockComponent from "./OutFarmLivestockComponent";
import VerificationDocumentsComponent from "./VerificationDocumentsComponent";


const steps = [
  INPUT_TEMPLATE_STEP_1,
  INPUT_TEMPLATE_STEP_2,
  INPUT_TEMPLATE_STEP_3,
  INPUT_TEMPLATE_STEP_4,
  INPUT_TEMPLATE_STEP_5,
  INPUT_TEMPLATE_STEP_6,
  INPUT_TEMPLATE_STEP_7,
];

const FarmerOnboardingStepsComponent = () => {
  const navigate = useNavigate();

  const [farmstatusAndInfo, setFarmstatusAndInfo] = useState(useLocation());

  const currentFarmDataObject = useSelector(
    (state) => state.currentFarmDataReducer
  );

  const accountSettings = useSelector((state) => state.accountSettingsReducer);
  const isAdmin = accountSettings?.[0]?.role === 'admin'


  const currentFarmStatusReducer = useSelector(
    (state) => state.farmStatusReducer
  );
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const handleSnackbarClose = () => {
    setState({ vertical: "top", horizontal: "center", open: false });
  };


  const [showLoader, setShowLoader] = useState(false);





  const [activeStep, setActiveStep] = useState(0);

  const [saveCurrentDataFlag, setSaveCurrentDataFlag] = useState(false);
  const handleBackdropClose = () => {
    setSaveCurrentDataFlag(false);
  };

  const handleStep = (newStep) => () => {
    setActiveStep(newStep);
  };



  const [allowEditing, setAllowEditing] = useState(true);
  const getFarmlandDetails = () => {
    setAllowEditing(
      currentFarmStatusReducer.allowEditing && currentFarmStatusReducer.editFarm
    );
  };
  useEffect(() => {
    getFarmlandDetails();
    if (
      farmstatusAndInfo &&
      farmstatusAndInfo.state
    ) {
      setFarmstatusAndInfo(farmstatusAndInfo.state);
    } else {
      if (isAdmin) {
        navigate("/");
      }
    }
  }, []);

  const handleBackdropClickCaptureEvent = (e) => {
    e.stopPropagation();
    return false;
  };

  return (
    <div className="farmer__onboarding_content">
      {showLoader && (
        <Backdrop
          onClickCapture={(e) => handleBackdropClickCaptureEvent(e)}
          style={{ color: "#fff", zIndex: 99999 }}
          open={saveCurrentDataFlag}
          onClick={handleBackdropClose}
        >
          <div style={{ fontSize: 20, fontWeight: 600, color: "white", marginTop: "20px" }}>
            Saving Data. Please Wait
          </div>
        </Backdrop>
      )}
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        key={vertical + horizontal}
      />
      <Box sx={{ width: "100%", marginTop: 10 }}>
        <Typography variant="h2" sx={{ mb: 3 }}>{currentFarmDataObject?.farmInfo.email}</Typography>
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};

            return (
              <Step key={label} {...stepProps}>
                <StepButton color="inherit" onClick={handleStep(index)}>
                  <StepLabel
                    StepIconProps={{ style: { color: "#409781" } }}
                    {...labelProps}
                  >
                    {label}
                  </StepLabel>
                </StepButton>
              </Step>
            );
          })}
        </Stepper>

        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography component={"span"} sx={{ mt: 2, mb: 1 }}>
              <FinalStepInOnboardingComponent
                farmerName={
                  currentFarmDataObject["farmInfo"]["landOwnerFirstName"]
                }
              />
            </Typography>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Typography component={"span"} sx={{ mt: 2, mb: 1 }}>
              {activeStep === 0 && <Step1FarmInfoComponent />}
              {activeStep === 1 && <Step2EnergyUsageComponent />}
              {activeStep === 2 && <Step2IndirectFuelUsageComponent />}
              {activeStep === 3 && <InFarmLivestockComponent />}
              {activeStep === 4 && <OutFarmLivestockComponent />}
              {activeStep === 5 && <MapParcelsComponent />}
              {activeStep === 6 && <VerificationDocumentsComponent />}
            </Typography>
          </React.Fragment>
        )}
      </Box>


    </div>
  );
};

export default FarmerOnboardingStepsComponent;
