import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Info as InfoIcon } from "@mui/icons-material";
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Tooltip, Typography } from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';

import { useDispatch } from "react-redux";
import { updateLivestockGrazingDays, updateLivestockManure } from "../../actions/currentMonitoringData";
import NumericInput from "../general/NumericInput";
import { interdependentError } from "../general/Validations/fieldValueErrors";
import { max } from "../general/Validations/minMaxValues";


const CommonCategories = ({ animal, allYears, isAdmin }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const currentMonitoringDataObject = useSelector((state) => state.currentMonitoringDataReducer);
    const currentFarmStatusReducer = useSelector((state) => state.farmStatusReducer);
    const allowEditing = currentFarmStatusReducer?.allowEditing || isAdmin

    const arrDocumentAndYearsMapping = {
        2018: 0,
        2019: 1,
        2020: 2,
        2021: 3,
        2022: 4,
        2023: 5,
        2024: 6,
        2025: 7,
        2026: 8,
        2027: 9,
        2028: 10
    };
    const animalsArray = ['CATTLE', 'DAIRY COWS', 'SWINE', 'SHEEP', 'GOATS', 'CHICKEN', 'TURKEYS', 'DUCKS']

    const handleLivestockGrazingDaysUpdate = (year, species, value) => {

        let tempData = {
            year: year,
            species: species,
            data: value
        };

        dispatch(updateLivestockGrazingDays(tempData))
            .then((res) => {
                console.log('Livestock grazing days updated successfully:', res);
            })
            .catch((error) => {
                console.error('Error updating livestock grazing days:', error);
            });
    };

    const handleLivestockManureUpdate = (year, species, value) => {

        let tempData = {
            year: year,
            species: species,
            data: value
        };

        dispatch(updateLivestockManure(tempData))
            .then((res) => {
                console.log('Livestock manure updated successfully:', res);
            })
            .catch((error) => {
                console.error('Error updating livestock manure:', error);
            });
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "row", mt: 2 }}>
            <Stack minWidth={"220px"} width={"220px"}>
                <Grid item marginTop={2} xs={12} sm={12} md={6} lg={6}>
                    <Typography variant="h3">{t("ls_infarm_grazing_days")}
                        <Tooltip
                            sx={{ paddingLeft: 1 }}
                            title={t("ls_infarm_grazing_days_info")}
                            placement="top-start"
                        >
                            <InfoIcon sx={{ fontSize: "medium", padding: "0px 0px 0px 8px" }} />
                        </Tooltip>
                    </Typography>
                </Grid>
                <Grid marginTop={6} item xs={12} sm={12} md={12} lg={12}>
                    <Typography variant="h3">{t("ls_infarm_manure")}
                        <Tooltip
                            sx={{ paddingLeft: 1 }}
                            title={<>
                                {t("ls_infarm_manure_info_1")}<br /><br />
                                {t("ls_infarm_manure_info_2")}<br /><br />
                                {t("ls_infarm_manure_info_3")}<br /><br />
                                {t("ls_infarm_manure_info_4")}<br /><br />
                                {t("ls_infarm_manure_info_5")}<br /><br />
                                {t("ls_infarm_manure_info_6")}<br /><br />
                                {t("ls_infarm_manure_info_7")}<br /><br />
                                {t("ls_infarm_manure_info_8")}<br /><br />
                                {t("ls_infarm_manure_info_9")}<br /><br />
                                {t("ls_infarm_manure_info_10")}<br /><br />
                                {t("ls_infarm_manure_info_11")}<br /><br />
                                {t("ls_infarm_manure_info_12")}<br /><br />
                                {t("ls_infarm_manure_info_13")}<br /><br />
                                {t("ls_infarm_manure_info_14")}
                            </>}
                            placement="top-start"
                        >
                            <InfoIcon sx={{ fontSize: "medium", padding: "0px 0px 0px 8px" }} />
                        </Tooltip>
                    </Typography>
                </Grid>
            </Stack>

            <div
                name="ScrollWrapper"
                style={{
                    display: "flex",
                    flexDirection: "row",
                    overflowX: "scroll",
                }}
            >
                {allYears.length > 0 && allYears.map((year, index) => {
                    const animalInfo = currentMonitoringDataObject?.yearlyFarmData?.[arrDocumentAndYearsMapping[year]]?.livestock?.inFarm?.[animalsArray.indexOf(animal)]
                    const catAmountArray = animalInfo?.category?.map(cat => cat.amount === 0 ? null : cat.amount)
                    return <Stack marginLeft={2} marginTop={1} sx={{ minWidth: "250px" }} key={index}>
                        <NumericInput
                            max={max.yearDays}
                            error={interdependentError(animalInfo?.grazingDays, catAmountArray)}
                            sx={{ mb: 2 }}
                            id="grazing-days-per-year"

                            InputProps={{
                                endAdornment: <InputAdornment position="start">{t("ls_infarm_grazing_days_unit")}</InputAdornment>,
                            }}
                            value={animalInfo?.grazingDays === -9999 ? '' : animalInfo?.grazingDays}
                            onChange={(e) => {
                                handleLivestockGrazingDaysUpdate(year, animal, e.target.value)
                            }}
                        ></NumericInput>
                        <FormControl fullWidth sx={{ marginBottom: 2, marginTop: 2 }}>
                            <InputLabel size='small' id="manure-treatment">
                                {t("ls_infarm_manure_treatment")}
                            </InputLabel>
                            <Select
                                error={interdependentError(animalInfo?.manureTreatment, catAmountArray) && animalInfo?.manureTreatment !== '-'}
                                size='small'
                                name='manure-treatment'
                                disabled={!allowEditing}
                                value={animalInfo?.manureTreatment === null ? '' : animalInfo?.manureTreatment}
                                onChange={(e) => handleLivestockManureUpdate(year, animal, e.target.value)}
                                id="manure-treatment-select" labelId='manure-treatment' label="manure-treatment">
                                <MenuItem value="Exported from farm">{t("ls_infarm_manure_exported")}</MenuItem>
                                <MenuItem value="Solid storage">{t("ls_infarm_manure_solid")}</MenuItem>
                                <MenuItem value="Pit storage > 1 month">{t("ls_infarm_manure_pit")}</MenuItem>
                                <MenuItem value="Liquid/Slurry">{t("ls_infarm_manure_liquid")}</MenuItem>
                                <MenuItem value="Dry lot">{t("ls_infarm_manure_dry")}</MenuItem>
                                <MenuItem value="Anaerobic digestion - biogas">{t("ls_infarm_manure_biogas")}</MenuItem>
                                <MenuItem value="Anaerobic lagoon">{t("ls_infarm_manure_anae_lagoon")}</MenuItem>
                                <MenuItem value="Daily Spread">{t("ls_infarm_manure_spread")}</MenuItem>
                                <MenuItem value="Composting - Intensive">{t("ls_infarm_manure_comp_intensive")}</MenuItem>
                                <MenuItem value="Composting - Extensive">{t("ls_infarm_manure_comp_extensive")}</MenuItem>
                                <MenuItem value="Deep litter">{t("ls_infarm_manure_deep_litter")}</MenuItem>
                                <MenuItem value="Aerobic treatment">{t("ls_infarm_manure_aerobic")}</MenuItem>
                                <MenuItem value="-">-</MenuItem>
                            </Select>
                        </FormControl>
                    </Stack>
                })}
            </div>
        </Box>
    )
}

export default CommonCategories