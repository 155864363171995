import { addNewEmptyProductivityToPasturesData } from "../../../actions/currentMonitoringData";
import { tmpDataKg } from "./tmpData";

export const addNewPasturesEstimatedProductivity = (e, i, year, dispatch, parcelid) => {

    let funParam = {
        year: year,
        data: tmpDataKg,
        parcelId: parcelid,
        pasturesId: i
    }
    dispatch(addNewEmptyProductivityToPasturesData(funParam))
}