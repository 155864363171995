import HttpService from '../services/HttpService';


const convertKMZtoKML = data => {
    return HttpService.getAxiosClient().post("/api/kmztokml", data);
};

const ConvertKMZtoKMLService = {
    convertKMZtoKML
};

export default ConvertKMZtoKMLService;
