import { CREATE_EMPTY_FARMLAND, UPDATE_CUR_ENERGY_USAGE, UPDATE_CUR_FARM_DATA, UPDATE_CUR_FARM_INFO, UPDATE_CUR_LAND_USE, UPDATE_CUR_LIVESTOCK, UPDATE_CUR_LIVESTOCK_BY_SPECIFIC_ID, UPDATE_CUR_SOIL_ANALYSIS, UPDATE_CUR_SUBMISSION_DATE, UPDATE_FUT_LIVESTOCK, UPDATE_FUT_LIVESTOCK_BY_SPECIFIC_ID, UPDATE_PAST_LIVESTOCK, REMOVE_LIVESTOCK_BY_SPECIFIC_ID, REMOVE_ALL_MAP_PARCELS, ADD_NEW_PARCEL, REMOVE_MAP_PARCEL_BY_ID, UPDATE_CUR_LAND_USE_BY_ID, GET_EXISTING_FARMLAND, GET_EMPTY_FARMLAND, RESET_FARMLAND_DATA, SET_CUR_FARM_DATA, CREATE_EMPTY_FARMLAND_FROM_LANDBOT } from "./types";


export const createEmptyFarmland = (data) => async(dispatch) => {
    try{
        const res = dispatch({
            type: CREATE_EMPTY_FARMLAND,
            payload: data
        });
        return Promise.resolve(res);
    }catch(error){
        return Promise.reject(error);
    }  
};

export const createEmptyFarmlandFromLandbot = (data) => async(dispatch) => {
    try{
        const res = dispatch({
            type: CREATE_EMPTY_FARMLAND_FROM_LANDBOT,
            payload: data
        });
        return Promise.resolve(res);
    }catch(error){
        return Promise.reject(error);
    }  
};

export const getEmptyFarmland = () => async(dispatch) => {
    try{
        const res = dispatch({
            type: GET_EMPTY_FARMLAND
        });
        return Promise.resolve(res);
    }catch(error){
        return Promise.reject(error);
    }  
};

export const setCurFarmData = () => async(dispatch) => {
    try{
        const res = dispatch({
            type: SET_CUR_FARM_DATA
        });
        return Promise.resolve(res);
    }catch(error){
        return Promise.reject(error);
    }  
};

export const updateCurrentFarmInfo = (data) => async(dispatch) => {
    try{
        const res = dispatch({
            type: UPDATE_CUR_FARM_INFO,
            payload: data
        });
        return Promise.resolve(res);
    }catch(error){
        return Promise.reject(error);
    }  
};

export const updateCurrentEnergyUsage = (data) => async(dispatch) => {
    try{
        const res = dispatch({
            type: UPDATE_CUR_ENERGY_USAGE,
            payload: data
        });
        return Promise.resolve(res);
    }catch(error){
        return Promise.reject(error);
    }  
};

export const updateCurrentLivestock = (data) => async (dispatch) => {
    try{
        const res = dispatch({
            type: UPDATE_CUR_LIVESTOCK,
            payload: data
        });
        return Promise.resolve(res);
    }catch(error){
        return Promise.reject(error);
    }  
};
export const updateCurrentLivestockBySpecificId = (data) => async(dispatch) => {
    try{
        const res = dispatch({
            type: UPDATE_CUR_LIVESTOCK_BY_SPECIFIC_ID,
            payload: data
        });
        return Promise.resolve(res);
    }catch(error){
        return Promise.reject(error);
    }  
};

export const removeLivestockBySpecificId = (data) => async(dispatch) => {
    try{
        const res = dispatch({
            type: REMOVE_LIVESTOCK_BY_SPECIFIC_ID,
            payload: data
        });
        return Promise.resolve(res);
    }catch(error){
        return Promise.reject(error);
    }  
};

export const updateFutureLivestock = (data) => async(dispatch) => {
    try{
        const res = dispatch({
            type: UPDATE_FUT_LIVESTOCK,
            payload: data
        });
        return Promise.resolve(res);
    }catch(error){
        return Promise.reject(error);
    }  
};

export const updateFutureLivestockBySpecificId = (data) => async(dispatch) => {
    try{
        const res = dispatch({
            type: UPDATE_FUT_LIVESTOCK_BY_SPECIFIC_ID,
            payload: data
        });
        return Promise.resolve(res);
    }catch(error){
        return Promise.reject(error);
    }  
};

export const updatePastLivestock = (data) => async(dispatch) => {
    try{
        const res = dispatch({
            type: UPDATE_PAST_LIVESTOCK,
            payload: data
        });
        return Promise.resolve(res);
    }catch(error){
        return Promise.reject(error);
    }  
};

export const updateCurrentLandUse = (data) => async(dispatch) => {
    try{
        const res = dispatch({
            type: UPDATE_CUR_LAND_USE,
            payload: data
        });
        return Promise.resolve(res);
    }catch(error){
        return Promise.reject(error);
    }  
};

export const updateCurrentLandUseById = (data) => async(dispatch) => {
    try{
        const res = dispatch({
            type: UPDATE_CUR_LAND_USE_BY_ID,
            payload: data
        });
        return Promise.resolve(res);
    }catch(error){
        return Promise.reject(error);
    }  
};

export const addNewParcel = (data) => async(dispatch) => {
    try{
        const res = dispatch({
            type: ADD_NEW_PARCEL,
            payload: data
        });
        return Promise.resolve(res);
    }catch(error){
        return Promise.reject(error);
    }  
};

export const removeAllMapParcels = () => async(dispatch) => {
    try{
        const res = dispatch({
            type: REMOVE_ALL_MAP_PARCELS
        });
        return Promise.resolve(res);
    }catch(error){
        return Promise.reject(error);
    }  
};

export const removeMapParcelById = (data) => async(dispatch) => {
    try{
        const res = dispatch({
            type: REMOVE_MAP_PARCEL_BY_ID,
            payload: data
        });
        return Promise.resolve(res);
    }catch(error){
        return Promise.reject(error);
    }  
};

export const updateCurrentSoilAnalysis = (data) => async(dispatch) => {
    try{
        const res = dispatch({
            type: UPDATE_CUR_SOIL_ANALYSIS,
            payload: data
        });
        return Promise.resolve(res);
    }catch(error){
        return Promise.reject(error);
    }  
};

export const updateCurrentSubmissionDate = (data) => async(dispatch) => {
    try{
        const res = dispatch({
            type: UPDATE_CUR_SUBMISSION_DATE,
            payload: data
        });
        return Promise.resolve(res);
    }catch(error){
        return Promise.reject(error);
    }  
};


export const updateCurrentFarmData = (data) => async(dispatch) => {
    try{
        const res = dispatch({
            type: UPDATE_CUR_FARM_DATA,
            payload: data
        });
        return Promise.resolve(res);
    }catch(error){
        return Promise.reject(error);
    }  
};

export const getExistingFarmland = (data) => async(dispatch) => {
    try{
        const res = dispatch({
            type: GET_EXISTING_FARMLAND,
            payload: data
        });
        return Promise.resolve(res);
    }catch(error){
        return Promise.reject(error);
    }  
};

export const resetFarmlandData = () => async(dispatch) => {
    try{
        const res = dispatch({
            type: RESET_FARMLAND_DATA
        });
        return Promise.resolve(res);
    }catch(error){
        return Promise.reject(error);
    }  
};