import { emptyNumericFieldError } from "./fieldValueErrors";

export const interdependencyValidation = (arrayOfObjects) => {
    let filledCount = 0;
    let emptyCount = 0;

    arrayOfObjects.forEach(obj => {
        const values = Object.values(obj);
        if (values?.every(value => value !== null && value !== undefined && value !== -9999 && value !== '' && value !== '-')) {
            filledCount++;
        } else if (values?.every(value => value === null || value === '' || value === undefined || value === -9999 || value === '-')) {
            emptyCount++;
        }
    });

    // Check if all values are either all filled or all empty
    const allFilledOrEmpty = filledCount === arrayOfObjects.length || emptyCount === arrayOfObjects.length;


    // Return true if either all fields are filled or none are filled
    return allFilledOrEmpty
}

export const isAllFilled = (arrayOfObjects, allowHyphen) => {
    let filledCount = 0;

    arrayOfObjects.forEach(obj => {
        const values = Object.values(obj);
        if (values?.every(value => value !== null && value !== undefined && value !== -9999 && value !== '' && (allowHyphen || value !== '-'))) {
            filledCount++;
        }
    });
    // Return true if all fields are filled
    return filledCount === arrayOfObjects.length
}

export const isSomeFilled = (arrayOfArrays) => {
    let filledCount = 0;
    arrayOfArrays.forEach(values => {

        if (values.some(value => value !== null && value !== undefined && value !== -9999 && value !== '' && value !== '-' && value !== 0)) {
            filledCount++;
        }
    });
    // Return true if each subArray has some values
    return filledCount === arrayOfArrays.length
}

export const showExpectedValue = (value) => emptyNumericFieldError(value) ? '' : value

