export const tmpData = {
    "date": '',
    "type": '',
    "amount": -9999,
    "units": "",
    "dry": true
};

export const plantBiomassObject = {
    "date": '',
    "type": '',
    "amount": -9999,
    "units": "",
    "dry": true
};

export const tmpDataKg = {
    "date": '',
    "type": '',
    "amount": -9999,
    "units": "",
    "dry": true
};

export const emptyAnnualCropsObject = {
    "species": '',
    "other": '',
    "sowingDate": '',
    "harvestDate": '',
    "harvest": [plantBiomassObject],
    "residue": [plantBiomassObject]
};


export const emptyFallowObject = {
    "type": '',
    "percentArea": -9999,
    "startDate": '',
    "endDate": '',
    "harvest": [plantBiomassObject],
    "residue": [plantBiomassObject]
};

export const emptyPerennialCropsObject = {
    "cohortID": '',
    "species": '',
    "other": '',
    "plantingYear": 0,
    "treeNumber": -9999,
    "DBHmean": { "diameter": -9999, "units": '' },
    "hasRotation": true,
    "rotationYears": -9999,
    "harvest": [plantBiomassObject],
    "residue": [plantBiomassObject]
};