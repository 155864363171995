


//new states
export const DOCUMENTS_COLLECTION = "Documents collection";
export const FARM_DATA_IS_YET_TO_BE_SUBMITTED = "Farm Data is yet to be submitted";
//export const DATA_COLLECTION = "Farm Data is yet to be submitted";
export const SUBMITTED_FOR_REVIEW = "Submitted for review";
export const INTERNAL_REVIEW = "Internal review";
export const AUDIT = "Audit";
export const CARBON_PLUS_CREDIT_POSTING = "Carbon+ Credit posting";
export const CARBON_PLUS_CREDIT_SALES = "Carbon+ Credit sales";

export const FARM_STATUS = [
    DOCUMENTS_COLLECTION,
    FARM_DATA_IS_YET_TO_BE_SUBMITTED,
    // DATA_COLLECTION,
    SUBMITTED_FOR_REVIEW,
    INTERNAL_REVIEW,
    AUDIT,
    CARBON_PLUS_CREDIT_POSTING,
    CARBON_PLUS_CREDIT_SALES
];

export const FARMLAND_UPDATED_BY_FARMER_LIAISON_SUCCESS = "The farm details were successfully updated.";

export const INPUT_TEMPLATE_STEP_1 = 'FARM INFORMATION'
export const INPUT_TEMPLATE_STEP_2 = 'DIRECT FUEL USAGE'
export const INPUT_TEMPLATE_STEP_3 = 'INDIRECT FUEL USAGE'
export const INPUT_TEMPLATE_STEP_4 = 'LIVESTOCK INFARM'
export const INPUT_TEMPLATE_STEP_5 = 'LIVESTOCK OUTFARM'
export const INPUT_TEMPLATE_STEP_6 = 'MAP PARCELS AND LAND USE'
export const INPUT_TEMPLATE_STEP_7 = 'VERIFICATION DOCUMENTS UPLOAD'

export const MONTHLY_LAND_USE_PRACTICES_CASH_CROPS_1_DROP_DOWN = "Cash Crop - 1 - Drop Down"
export const MONTHLY_LAND_USE_PRACTICES_NO_CASH_CROPS_1 = "No Cash Crops - 1"
export const MONTHLY_LAND_USE_PRACTICES_CASH_CROPS_1 = "Cash Crop - 1"
export const MONTHLY_LAND_USE_PRACTICES_CASH_CROPS_2_DROP_DOWN = "Cash Crop - 2 - Drop Down"
export const MONTHLY_LAND_USE_PRACTICES_NO_CASH_CROPS_2 = "No Cash Crops - 2"
export const MONTHLY_LAND_USE_PRACTICES_CASH_CROPS_2 = "Cash Crop - 2"
export const MONTHLY_LAND_USE_PRACTICES_COVER_CROPS = "Cover Crops"
export const MONTHLY_LAND_USE_PRACTICES_PRODUCTIVE_FALLOW = "Productive Fallow"
export const MONTHLY_LAND_USE_PRACTICES_GRAZING = "Grazing"
export const MONTHLY_LAND_USE_PRACTICES_HARVEST_YIELD = "Harvest yield (t/ha)"
export const MONTHLY_LAND_USE_PRACTICES_GRAZING_YIELD = "Grazing yield (t/ha)"
export const MONTHLY_LAND_USE_PRACTICES_BARE_SOIL_FALLOW = "Bare Soil Fallow"
export const MONTHLY_LAND_USE_PRACTICES_TILLING_EVENT = "Tilling Event?"
export const MONTHLY_LAND_USE_PRACTICES_MINIMUM_TILLING_EVENT = "Minimum Tilling Event? (<10cm deep)"
export const MONTHLY_LAND_USE_PRACTICES_RESIDUE_LEFT_AFTER_GRAZING_OR_HARVEST = "What's left after residue grazing/harvest (t/ha)"
