import { useTheme } from '@mui/material/styles';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';

import SwipeableViews from 'react-swipeable-views';

import { CheckCircle } from '@mui/icons-material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import InfoIcon from '@mui/icons-material/Info';

// import TabList from '@mui/joy/TabList';
import { Backdrop, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, Grid, InputAdornment, Snackbar, Stack, Tab, Tabs, TextField, Tooltip, Typography } from '@mui/material';

import NumericInput from "./general/NumericInput";
import { getTotalAreaOfTheFarm } from "./MapAndParcels/getTotalAreaOfTheFarm";

import { addEmptyFertilizerUsage, addEmptyImportedOrganicMatter, updateFertilizerUsage, updateImportedOrganicMatter } from '../actions/currentMonitoringData';
import { updateMonitoringData } from '../actions/monitoringData';
import { updateFarmVerificationDocuments } from '../actions/verificationDocuments';
import { defaultValues } from '../constants/verificationDocsDefaultValues';
import VerificationDocumentsUploadService from '../farmservices/VerificationDocumentsUploadService';
import { SubmitButton, UploadedDocument } from '../styles';
import BackToMapsComponent from './general/BackToMapsComponent';
import { a11yProps, TabPanel } from './general/TabPanel';
import { handleDeleteFile, handleDownload } from './general/utils/fileOperations';
import { emptyNumericFieldError } from "./general/Validations/fieldValueErrors";
import { max, maxDynamic } from './general/Validations/minMaxValues';

const FarmWideInformationComponent = () => {
  const { t } = useTranslation();

  const currentMonitoringDataObject = useSelector(state => state.currentMonitoringDataReducer);
  const currentFarmDataObject = useSelector(state => state.currentFarmDataReducer);
  const currentFarmVerificationDocuments = useSelector(state => state.farmVerificationDocumentsReducer);
  const currentMonitoringYearsObject = useSelector(state => state.monitoringYearsTrackerReducer);
  const currentFarmStatusReducer = useSelector(
    (state) => state.farmStatusReducer
  );
  const accountSettings = useSelector((state) => state.accountSettingsReducer);
  const isAdmin = accountSettings?.[0]?.role === 'admin'
  const allowEditing = currentFarmStatusReducer.allowEditing || isAdmin

  //const [currentTabValue, setCurrentTabValue] = useState(0);
  //const [currentTabIndex, setCurrentTabIndex] = useState(0);
  //Refer to energy usage to populat e this array - there are functions already
  const [numberOfTabs, setNumberOfTabs] = useState([]);
  //const [value, setValue] = React.useState(0);
  const [tabPanelIndex, setTabPanelIndex] = useState(0);
  const theme = useTheme();
  const dispatch = useDispatch();
  const [currentDocumentComment, setCurrentDocumentComment] = useState('');
  const [currentDocumentYear, setCurrentDocumentYear] = useState('');
  const [currentDocumentFolder, setCurrentDocumentFolder] = useState('');
  const [currentDocumentIndex, setCurrentDocumentIndex] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [saveCurrentDataFlag, setSaveCurrentDataFlag] = useState(false);

  const [confirmPopUpOpen, setConfirmPopUpOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [state, setState] = useState({
    snackBarOpen: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const { vertical, horizontal, snackBarOpen } = state;

  const handleBackdropClose = () => {
    setSaveCurrentDataFlag(false);
  };

  const handleBackdropClickCaptureEvent = (e) => {
    e.stopPropagation();
    return false;
  };

  const handleSnackbarClose = () => {
    setState({ vertical: "top", horizontal: "center", snackBarOpen: false });
  };

  const handleConfirmPopUpOpen = () => {
    setConfirmPopUpOpen(true);
  };

  const handleConfirmPopUpClose = (event, reason) => {
    if (reason && reason == "backdropClick") {
      return;
    }
    setConfirmPopUpOpen(false);
  };

  const [arrDocumentAndYearsMapping, setArrDocumentAndYearsMapping] = useState({
    2018: 0,
    2019: 1,
    2020: 2,
    2021: 3,
    2022: 4,
    2023: 5,
    2024: 6,
    2025: 7,
    2026: 8,
    2027: 9,
    2028: 10
  });

  var emptySyntheticFertlizerObject = {
    "amount": -9999,
    "units": null,
    "percentN": -9999
  };
  const handleFileChange = (e, subfolder, year) => {
    // if no year default value
    setShowLoader(true);
    setSaveCurrentDataFlag(true);

    let email = currentFarmDataObject.farmInfo.email.replace('@', '__');

    let file = e.target.files[0];

    let fileNameArr = e.target.files[0].name.split('.').map(ele => {
      return ele.replace(/[^a-zA-Z0-9]/g, '');
    });

    let fileName = fileNameArr.join('.');

    if (year !== undefined && year !== '' && year !== null) {
      handleFileUpload(file, fileName, subfolder, email, year);
    } else {
      handleFileUpload(file, fileName, subfolder, email);
    }

  };

  const handleFileUpload = async (file, fileName, subfolder, email, year) => {
    const formData = new FormData();

    const server = process.env.REACT_APP_NODE_ENV === 'production' ? 'monitoring_round_2024_uploads' : 'test';

    let tempData = {
      dateUploaded: Date.now().toString(),
      cfDocumentName: defaultValues[subfolder],

      folder: subfolder,
      fileName: fileName,
      documentType: "",
      s3URL: '',
      year: year,
      comment: ''
    };

    formData.append('subfolder', subfolder);
    if (year !== undefined && year !== '') {
      formData.append('year', year);
    }
    formData.append('email', email);
    formData.append('server', server);
    formData.append('file', file);

    const response = await VerificationDocumentsUploadService.upload(formData);

    tempData.s3URL = response.data.message;
    tempData.documentType = response.data.data.mimetype;

    const monitoringYear = arrDocumentAndYearsMapping[year]; // year index

    let tempObj = currentFarmVerificationDocuments;

    if (subfolder === 'fertiliserInvoices') {
      tempObj.yearlyVerificationDocuments[monitoringYear].fertiliserInvoices?.push(tempData);
    }
    if (subfolder === 'importedOrganicAmendmentsInvoices') {
      tempObj.yearlyVerificationDocuments[monitoringYear].importedOrganicAmendmentsInvoices?.push(tempData);
    }
    if (subfolder === 'animalFodderInvoices') {
      tempObj.yearlyVerificationDocuments[monitoringYear].animalFodderInvoices?.push(tempData);
    }
    if (subfolder === 'seedsInvoices') {
      tempObj.yearlyVerificationDocuments[monitoringYear].seedsInvoices?.push(tempData);
    }


    // upload to mongoDB
    dispatch(updateFarmVerificationDocuments(currentFarmVerificationDocuments._id, tempObj))
      .then(res => {
        setSnackbarMessage(t("uploading_document_msg_success"));
        setShowLoader(false);
        setState({ open: true, vertical: 'top', horizontal: 'center' });
        setSaveCurrentDataFlag(false);
      }).catch(e => {
        setSnackbarMessage(t("uploading_document_msg_failure"));
        setShowLoader(false);
        setState({ open: true, vertical: 'top', horizontal: 'center' });
        setSaveCurrentDataFlag(false);
      });
  };

  const saveVerificationDocumentComment = () => {
    let tempObj = currentFarmVerificationDocuments;

    tempObj.yearlyVerificationDocuments[currentDocumentYear][currentDocumentFolder][currentDocumentIndex].comment = currentDocumentComment;

    dispatch(updateFarmVerificationDocuments(currentFarmVerificationDocuments._id, tempObj))
      .then((res) => {
        setCurrentDocumentComment('');
        setCurrentDocumentYear('');
        setCurrentDocumentFolder('');
        setCurrentDocumentIndex('');
      })
      .catch((error) => {
        console.error('Error updating farm verification documents:', error);
      });
    setConfirmPopUpOpen(false);
  };

  const handleChange = (event, newValue) => {
    setTabPanelIndex(newValue);
    if (currentMonitoringDataObject.yearlyFarmData.find(ele => ele.year === currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]).fertilizerUsage.length === 0) {
      addNewSyntheticFertilizer();
    }
    handleMonitoringDataSave(false);
  };

  const handleChangeIndex = (index) => {
    setTabPanelIndex(index)
  };

  const handleSyntheticFertilizerChange = (e, i) => {
    const { name, value } = e.target;

    let tmpData = {
      year: currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex],
      fertlizerUsageIndex: i,
      attrName: name,
      data: value
    };

    dispatch(updateFertilizerUsage(tmpData))
  };

  const handleImportedOrganicMatterChange = (e) => {
    const { name, value } = e.target;

    let tmpData = {
      year: currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex],
      attrName: name,
      data: value
    };
    dispatch(updateImportedOrganicMatter(tmpData))
      .then((res) => {
        console.log('Imported organic matter updated successfully.');
      })
      .catch((error) => {
        console.error('Error updating imported organic matter:', error);
      });
  };



  const addNewSyntheticFertilizer = () => {
    let tmpOb = {
      yearValue: currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex],
      emptyFertlizerOb: emptySyntheticFertlizerObject
    };
    dispatch(addEmptyFertilizerUsage(tmpOb))
    dispatch(addEmptyFertilizerUsage(tmpOb))
      .then((res) => {
        console.log('Empty fertilizer usage added successfully.');
      })
      .catch((error) => {
        console.error('Error adding empty fertilizer usage:', error);
      });
  };



  const initializeTabData = () => {


    let initialImportedOrganicMatterData = [
      {
        "type": "biochar",
        "percentImported": -9999
      },
      {
        "type": "compost",
        "percentImported": -9999
      },
      {
        "type": "manure",
        "percentImported": -9999
      },
      {
        "type": "mulch",
        "percentImported": -9999
      },
      {
        "type": "slurry",
        "percentImported": -9999
      },
      {
        "type": "fodder",
        "percentImported": -9999
      }

    ];

    if (currentMonitoringDataObject?.yearlyFarmData.find(ele => ele.year === currentMonitoringYearsObject?.allMonitoringYears[tabPanelIndex])?.fertilizerUsage.length === 0) {
      addNewSyntheticFertilizer();
    }
    if (currentMonitoringDataObject?.yearlyFarmData.find(ele => ele.year === currentMonitoringYearsObject?.allMonitoringYears[tabPanelIndex])?.importedOrganicMatter.length === 0) {
      dispatch(addEmptyImportedOrganicMatter(initialImportedOrganicMatterData))
        .then((res) => {
          console.log('Empty imported organic matter added successfully.');
        })
        .catch((error) => {
          console.error('Error adding empty imported organic matter:', error);
        });
    }
  };

  const savePageData = () => {
    handleMonitoringDataSave(true);
  }

  const handleMonitoringDataSave = (showSavingDataMessage) => {
    if (showSavingDataMessage) {
      setShowLoader(true);
      setSaveCurrentDataFlag(true);
    }
    dispatch(updateMonitoringData(currentMonitoringDataObject._id, currentMonitoringDataObject))
      .then((res) => {
        console.log('Monitoring data updated successfully.');
        setSnackbarMessage(t("farmland_draft_submission_msg_success"));
        setState({ snackBarOpen: true, vertical: "top", horizontal: "center" });
        if (showSavingDataMessage) {
          setShowLoader(false);
          setSaveCurrentDataFlag(false);
        }
      })
      .catch((error) => {
        console.error('Error updating monitoring data:', error);
      });
  }

  const addYears = (newNum) => {
    setNumberOfTabs(numberOfTabs => [...numberOfTabs, newNum])
  };



  useEffect(() => {
    setNumberOfTabs([])
    for (var x = 0; x < currentMonitoringYearsObject.allMonitoringYears.length; x++) {
      addYears(currentMonitoringYearsObject.allMonitoringYears[x]);
    }
    initializeTabData();

  }, []);

  useEffect(() => {
  }, [currentFarmVerificationDocuments])

  useEffect(() => {
    initializeTabData()
  }, [tabPanelIndex])

  const totalAreaOfTheFarm = getTotalAreaOfTheFarm(currentMonitoringDataObject)

  return (
    <>
      {showLoader && (
        <Backdrop
          onClickCapture={(e) => handleBackdropClickCaptureEvent(e)}
          style={{ color: "#fff", zIndex: 99999 }}
          open={saveCurrentDataFlag}
          onClick={handleBackdropClose}
        >
          <div style={{ fontSize: 20, fontWeight: 600, color: "white", marginTop: "20px" }}>
            {t("generic_saving_data")}
          </div>
        </Backdrop>
      )}

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={snackBarOpen}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        key={vertical + horizontal}
      />

      {!isAdmin &&
        <Box marginLeft={6} >
          <BackToMapsComponent />
        </Box>}

      <Box marginLeft={12} marginRight={12} marginTop={5} sx={{ flexGrow: 1 }}>
        <div ><Grid container marginBottom={4} spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', flexDirection: 'column', marginTop: 30, marginBottom: 30 }}>
              <Typography sx={{ marginBottom: 3 }} fontWeight={800} fontSize={26}>{t("fwi_screen_title")}</Typography>
              <Typography fontSize={15}>{t("fwi_screen_subtitle_1")}{" "}{t("fwi_screen_subtitle_2")}</Typography>
            </Grid>

          </Grid>
        </Grid>
        </div>

        <Tabs
          value={tabPanelIndex}
          onChange={handleChange}
          sx={{
            zIndex: 1,
            position: 'sticky',
            top: 64,
            '& .MuiTabs-indicator': {
              height: '5px'
            }
          }}
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          {
            currentMonitoringYearsObject.allMonitoringYears.map((val, i) => {
              let bgColor = '#e6e6e6';
              if (i > 2) { bgColor = '#808080' }
              return (<Tab sx={{ color: 'black', border: 2, borderColor: 'white', fontSize: 18, fontWeight: 600, backgroundColor: bgColor }} label={val} {...a11yProps({ i })} key={i} />)
            })
          }
        </Tabs>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={tabPanelIndex}
          onChangeIndex={handleChangeIndex}
        >
          {
            currentMonitoringYearsObject.allMonitoringYears.map((tabValue, tabIndex) => {

              const importedOrganicMatter = currentMonitoringDataObject?.yearlyFarmData.find(yEl => yEl.year === tabValue)?.importedOrganicMatter
              return (

                <TabPanel style={{ overflow: "auto" }} value={tabPanelIndex} index={tabIndex} dir={theme.direction} key={tabIndex}>
                  <div>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', flexDirection: 'column', marginTop: 30, marginBottom: 30 }}>
                        <Typography sx={{ marginBottom: 3 }} fontWeight={800} fontSize={20}>{t("fwi_nitrogen_fert_title")}</Typography>
                        <Typography fontSize={15}>{t("fwi_nitrogen_fert_subtitle_1")}<b>{t("fwi_nitrogen_fert_subtitle_2")}</b>{tabValue} {t("fwi_nitrogen_fert_subtitle_3")}{t("fwi_nitrogen_fert_subtitle_4")}</Typography>
                      </Grid>


                      <Grid item xs={12} md={12} lg={12}></Grid>
                      {currentMonitoringDataObject?.yearlyFarmData.find(yFD => yFD.year === tabValue)?.fertilizerUsage?.map((val, i) => {
                        return <React.Fragment key={i}>
                          {i === 0 && <Grid item xs={2} md={2} lg={2}>
                            <Typography fontSize={15} fontWeight={600}>{t("fwi_nitrogen_fert")}</Typography>
                          </Grid>}
                          {i !== 0 && <Grid item xs={2} md={2} lg={2}>

                          </Grid>}
                          <Grid item xs={3} md={3} lg={3}>
                            <FormControl fullWidth>
                              <NumericInput
                                error={emptyNumericFieldError(val.amount)}
                                decimalScale={3}
                                name="amount"
                                max={maxDynamic.fertilseT * totalAreaOfTheFarm}
                                InputProps={{
                                  endAdornment: <InputAdornment position="end">{t("fwi_quantity_tonnes")}</InputAdornment>,
                                }}

                                value={val.amount === -9999 ? '' : val.amount} fullWidth placeholder={t("pci_perennial_tree_list_harvest_type_qty")} label={t("pci_perennial_tree_list_harvest_type_qty")} onChange={(e) => handleSyntheticFertilizerChange(e, i)} />
                            </FormControl>
                          </Grid>
                          <Grid item xs={3} md={3} lg={3}>
                            <FormControl fullWidth>
                              <NumericInput max={max.percent} name="percentN"
                                error={emptyNumericFieldError(val.percentN)}
                                InputProps={{
                                  endAdornment: <InputAdornment position="end">{t("fwi_percent_nitro")}</InputAdornment>,
                                }}

                                value={val.percentN === -9999 ? '' : val.percentN} fullWidth placeholder={t("fwi_percent_nitro")} label={t("fwi_percent_nitro")} onChange={(e) => handleSyntheticFertilizerChange(e, i)} />
                            </FormControl>
                          </Grid>
                          <Grid item xs={3} md={3} lg={3}></Grid>
                        </React.Fragment>
                      })
                      }


                      <Grid item xs={2} md={2} lg={2}>
                      </Grid>
                      <Grid item xs={2} md={2} lg={2}>
                        {allowEditing && <Typography
                          sx={{ color: '#3e9781', cursor: 'pointer', marginRight: '5px' }}
                          fontWeight={800}

                          fontSize={14}
                          component="span"
                          onClick={addNewSyntheticFertilizer}
                        >{t("fwi_add_fert")}</Typography>}
                      </Grid>
                      <Grid item xs={8} md={8} lg={8}>
                      </Grid>

                      <Grid item xs={2} md={2} lg={2}>
                        <Typography fontSize={15} fontWeight={600}>{t("fwi_invoices")}</Typography>
                      </Grid>
                      <Grid item xs={10} md={10} lg={10}>

                        {currentFarmVerificationDocuments?.yearlyVerificationDocuments?.[arrDocumentAndYearsMapping[currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]]]?.fertiliserInvoices?.length > 0 &&
                          currentFarmVerificationDocuments.yearlyVerificationDocuments[arrDocumentAndYearsMapping[currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]]].fertiliserInvoices.map((ele, index) => {
                            return <React.Fragment key={index}>
                              <Grid container spacing={2}>
                                <Stack marginLeft={2} display={'flex'} marginBottom={1} marginTop={1.5}>
                                  <UploadedDocument
                                    size="small"
                                    label={
                                      <Fragment>
                                        <CheckCircle sx={{ marginRight: 1 }} />
                                        {currentFarmVerificationDocuments?.yearlyVerificationDocuments?.[arrDocumentAndYearsMapping[currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]]]?.fertiliserInvoices[index].fileName}
                                      </Fragment>
                                    }
                                    disabled
                                  ></UploadedDocument>
                                  <Grid item marginBottom={1.5}>
                                    <Typography
                                      sx={{ color: '#3e9781', cursor: 'pointer', marginRight: '5px' }}
                                      fontWeight={600}
                                      fontSize={12}
                                      component="span"
                                      onClick={() => handleDownload(currentFarmVerificationDocuments.yearlyVerificationDocuments[arrDocumentAndYearsMapping[currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]]].fertiliserInvoices[index].s3URL)}
                                    >{t("ver_doc_download")}</Typography>
                                    |
                                    <Typography
                                      sx={{ color: '#3e9781', cursor: 'pointer', marginX: '5px' }}
                                      fontWeight={600}
                                      fontSize={12}
                                      component="span"
                                      onClick={() => handleDeleteFile(index, currentFarmVerificationDocuments.yearlyVerificationDocuments[arrDocumentAndYearsMapping[currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]]].fertiliserInvoices[index].folder, currentFarmVerificationDocuments.yearlyVerificationDocuments[arrDocumentAndYearsMapping[currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]]].fertiliserInvoices[index].fileName, currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex], currentFarmDataObject,
                                        currentFarmVerificationDocuments,
                                        dispatch,
                                        setSnackbarMessage,
                                        setSaveCurrentDataFlag,
                                        setState,
                                        t)}
                                    >{t("ver_doc_delete")}</Typography>
                                    |
                                    {currentFarmVerificationDocuments.yearlyVerificationDocuments[arrDocumentAndYearsMapping[currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]]].fertiliserInvoices[index].comment !== null &&
                                      currentFarmVerificationDocuments.yearlyVerificationDocuments[arrDocumentAndYearsMapping[currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]]].fertiliserInvoices[index].comment !== undefined &&
                                      currentFarmVerificationDocuments.yearlyVerificationDocuments[arrDocumentAndYearsMapping[currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]]].fertiliserInvoices[index].comment !== '' ?
                                      <Typography
                                        sx={{ color: '#3e9781', cursor: 'pointer', marginLeft: '5px' }}
                                        fontWeight={600}
                                        fontSize={12}
                                        component="span"
                                        onClick={() => {
                                          setCurrentDocumentComment(currentFarmVerificationDocuments.yearlyVerificationDocuments[arrDocumentAndYearsMapping[currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]]].fertiliserInvoices[index].comment);
                                          setCurrentDocumentYear(arrDocumentAndYearsMapping[currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]]); //year index
                                          setCurrentDocumentFolder('fertiliserInvoices');
                                          setCurrentDocumentIndex(index);
                                          handleConfirmPopUpOpen()
                                        }}
                                      >{t("general_edit_comment")}</Typography>
                                      :
                                      <Typography
                                        sx={{ color: '#3e9781', cursor: 'pointer', marginLeft: '5px' }}
                                        fontWeight={600}
                                        fontSize={12}
                                        component="span"
                                        onClick={() => {
                                          setCurrentDocumentYear(arrDocumentAndYearsMapping[currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]]);
                                          setCurrentDocumentFolder('fertiliserInvoices');
                                          setCurrentDocumentIndex(index);
                                          handleConfirmPopUpOpen()
                                        }}
                                      >{t("general_add_comment")}</Typography>
                                    }
                                  </Grid>
                                </Stack>
                              </Grid>
                            </React.Fragment>
                          })}
                        <Grid item style={{ display: 'flex' }}>
                          <SubmitButton sx={{ fontSize: 13, width: '250px' }} component="label" variant="contained" startIcon={<CloudUploadIcon />}
                            disabled={!allowEditing}
                            onChange={(e) => handleFileChange(e, "fertiliserInvoices", currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex])}>
                            {t("fwi_upload_invoice")}
                            <input
                              type="file"
                              accept='application/msword,
                          application/pdf, 
                          application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                          image/png, 
                          image/jpeg'
                              hidden />
                          </SubmitButton>
                        </Grid>

                      </Grid>

                      <Grid item xs={12} md={12} lg={12}>
                        <Divider sx={{ marginY: 6 }}></Divider>

                        <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', flexDirection: 'column', marginTop: 30, marginBottom: 30 }}>
                          <Typography sx={{ marginBottom: 3 }} fontWeight={800} fontSize={20}>{t("fwi_org_amend_title")}</Typography>
                          <Typography fontSize={15}>{t("fwi_org_amend_subtitle_1")}<b>{t("fwi_org_amend_subtitle_2")}</b>{currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]}{t("fwi_org_amend_subtitle_3")}
                            <Tooltip sx={{ paddingLeft: 1 }} title={t("fwi_org_amend_info")} placement="top-start">
                              <InfoIcon sx={{
                                fontSize: 'medium',
                                padding: '0px 0px 0px 8px',
                              }} />
                            </Tooltip>
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid item xs={2} md={2} lg={2}>
                        <Typography fontSize={15} fontWeight={600}>{t("fwi_biochar")}</Typography>
                      </Grid>
                      <Grid item xs={3} md={3} lg={3}>
                        <FormControl fullWidth>
                          <NumericInput max={max.percent} name="biochar"
                            error={emptyNumericFieldError(importedOrganicMatter[0]?.percentImported)}
                            InputProps={{
                              endAdornment: <InputAdornment position="end">{t("fwi_percent")}</InputAdornment>,
                            }}
                            value={importedOrganicMatter[0]?.percentImported === -9999 ? '' : importedOrganicMatter[0]?.percentImported} fullWidth placeholder={t("fwi_percent_imported")} label={t("fwi_percent_imported")} onChange={(e) => handleImportedOrganicMatterChange(e)} />
                        </FormControl>
                      </Grid>
                      <Grid item xs={7} md={7} lg={7}></Grid>

                      <Grid item xs={2} md={2} lg={2}>
                        <Typography fontSize={15} fontWeight={600}>{t("fwi_compost")}</Typography>
                      </Grid>
                      <Grid item xs={3} md={3} lg={3}>
                        <FormControl fullWidth>


                          <NumericInput
                            name="compost"
                            error={emptyNumericFieldError(importedOrganicMatter[1]?.percentImported)}
                            max={max.percent}
                            InputProps={{
                              endAdornment: <InputAdornment position="end">{t("fwi_percent")}</InputAdornment>,
                            }}
                            value={importedOrganicMatter[1]?.percentImported === -9999 ? '' : importedOrganicMatter[1]?.percentImported} fullWidth placeholder={t("fwi_percent_imported")} label={t("fwi_percent_imported")} onChange={(e) => handleImportedOrganicMatterChange(e)} />
                        </FormControl>
                      </Grid>
                      <Grid item xs={7} md={7} lg={7}></Grid>


                      <Grid item xs={2} md={2} lg={2}>
                        <Typography fontSize={15} fontWeight={600}>{t("fwi_manure")}</Typography>
                      </Grid>
                      <Grid item xs={3} md={3} lg={3}>
                        <FormControl fullWidth>
                          <NumericInput
                            max={max.percent}
                            name="manure"
                            error={emptyNumericFieldError(importedOrganicMatter[2]?.percentImported)}

                            InputProps={{
                              endAdornment: <InputAdornment position="end">{t("fwi_percent")}</InputAdornment>,
                            }}
                            value={importedOrganicMatter[2]?.percentImported === -9999 ? '' : importedOrganicMatter[2]?.percentImported} fullWidth placeholder={t("fwi_percent_imported")} label={t("fwi_percent_imported")} onChange={(e) => handleImportedOrganicMatterChange(e)} />
                        </FormControl>
                      </Grid>
                      <Grid item xs={7} md={7} lg={7}></Grid>


                      <Grid item xs={2} md={2} lg={2}>
                        <Typography fontSize={15} fontWeight={600}>{t("fwi_mulch")}</Typography>
                      </Grid>
                      <Grid item xs={3} md={3} lg={3}>
                        <FormControl fullWidth >
                          <NumericInput
                            name="mulch"
                            error={emptyNumericFieldError(importedOrganicMatter[3]?.percentImported)}
                            max={max.percent}
                            InputProps={{
                              endAdornment: <InputAdornment position="end">{t("fwi_percent")}</InputAdornment>,
                            }}
                            value={importedOrganicMatter[3]?.percentImported === -9999 ? '' : importedOrganicMatter[3]?.percentImported} fullWidth placeholder={t("fwi_percent_imported")} label={t("fwi_percent_imported")} onChange={(e) => handleImportedOrganicMatterChange(e)} />
                        </FormControl>
                      </Grid>
                      <Grid item xs={7} md={7} lg={7}></Grid>


                      <Grid item xs={2} md={2} lg={2}>
                        <Typography fontSize={15} fontWeight={600}>{t("fwi_slurry")}</Typography>
                      </Grid>
                      <Grid item xs={3} md={3} lg={3}>
                        <FormControl fullWidth>
                          <NumericInput
                            name="slurry"
                            error={emptyNumericFieldError(importedOrganicMatter[4]?.percentImported)}
                            max={max.percent}
                            InputProps={{
                              endAdornment: <InputAdornment position="end">{t("fwi_percent")}</InputAdornment>,
                            }}
                            value={importedOrganicMatter[4]?.percentImported === -9999 ? '' : importedOrganicMatter[4]?.percentImported} fullWidth placeholder={t("fwi_percent_imported")} label={t("fwi_percent_imported")} onChange={(e) => handleImportedOrganicMatterChange(e)} />
                        </FormControl>
                      </Grid>
                      <Grid item xs={7} md={7} lg={7}></Grid>


                      <Grid item xs={2} md={2} lg={2}>
                        <Typography fontSize={15} fontWeight={600}>{t("fwi_documents")}
                          <Tooltip sx={{ paddingLeft: 1 }} title={t("fwi_documents_info")} placement="top-start">
                            <InfoIcon sx={{
                              fontSize: 'medium',
                              padding: '0px 0px 0px 8px',
                            }} />
                          </Tooltip>
                        </Typography>
                      </Grid>
                      <Grid item xs={3} md={3} lg={3}>
                        {currentFarmVerificationDocuments?.yearlyVerificationDocuments?.[arrDocumentAndYearsMapping[currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]]]?.importedOrganicAmendmentsInvoices?.length > 0 &&
                          currentFarmVerificationDocuments.yearlyVerificationDocuments[arrDocumentAndYearsMapping[currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]]].importedOrganicAmendmentsInvoices.map((ele, index) => {
                            return <React.Fragment key={index}>
                              <Grid container spacing={2}>
                                <Stack marginLeft={2} display={'flex'} marginBottom={1} marginTop={1.5}>
                                  <UploadedDocument
                                    size="small"
                                    label={
                                      <Fragment>
                                        <CheckCircle sx={{ marginRight: 1 }} />
                                        {currentFarmVerificationDocuments?.yearlyVerificationDocuments?.[arrDocumentAndYearsMapping[currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]]]?.importedOrganicAmendmentsInvoices[index].fileName}
                                      </Fragment>
                                    }
                                    disabled
                                  ></UploadedDocument>
                                  <Grid item marginBottom={1.5}>
                                    <Typography
                                      sx={{ color: '#3e9781', cursor: 'pointer', marginRight: '5px' }}
                                      fontWeight={600}
                                      fontSize={12}
                                      component="span"
                                      onClick={() => handleDownload(currentFarmVerificationDocuments.yearlyVerificationDocuments[arrDocumentAndYearsMapping[currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]]].importedOrganicAmendmentsInvoices[index].s3URL)}
                                    >{t("ver_doc_download")}</Typography>
                                    |
                                    <Typography
                                      sx={{ color: '#3e9781', cursor: 'pointer', marginX: '5px' }}
                                      fontWeight={600}
                                      fontSize={12}
                                      component="span"
                                      onClick={() => handleDeleteFile(index, currentFarmVerificationDocuments.yearlyVerificationDocuments[arrDocumentAndYearsMapping[currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]]].importedOrganicAmendmentsInvoices[index].folder, currentFarmVerificationDocuments.yearlyVerificationDocuments[arrDocumentAndYearsMapping[currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]]].importedOrganicAmendmentsInvoices[index].fileName, currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex], currentFarmDataObject,
                                        currentFarmVerificationDocuments,
                                        dispatch,
                                        setSnackbarMessage,
                                        setSaveCurrentDataFlag,
                                        setState,
                                        t)}
                                    >{t("ver_doc_delete")}</Typography>
                                    |
                                    {currentFarmVerificationDocuments.yearlyVerificationDocuments[arrDocumentAndYearsMapping[currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]]].importedOrganicAmendmentsInvoices[index].comment !== null &&
                                      currentFarmVerificationDocuments.yearlyVerificationDocuments[arrDocumentAndYearsMapping[currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]]].importedOrganicAmendmentsInvoices[index].comment !== undefined &&
                                      currentFarmVerificationDocuments.yearlyVerificationDocuments[arrDocumentAndYearsMapping[currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]]].importedOrganicAmendmentsInvoices[index].comment !== '' ?
                                      <Typography
                                        sx={{ color: '#3e9781', cursor: 'pointer', marginLeft: '5px' }}
                                        fontWeight={600}
                                        fontSize={12}
                                        component="span"
                                        onClick={() => {
                                          setCurrentDocumentComment(currentFarmVerificationDocuments.yearlyVerificationDocuments[arrDocumentAndYearsMapping[currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]]].importedOrganicAmendmentsInvoices[index].comment);
                                          setCurrentDocumentYear(arrDocumentAndYearsMapping[currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]]); //year index
                                          setCurrentDocumentFolder('importedOrganicAmendmentsInvoices');
                                          setCurrentDocumentIndex(index);
                                          handleConfirmPopUpOpen()
                                        }}
                                      >{t("general_edit_comment")}</Typography>
                                      :
                                      <Typography
                                        sx={{ color: '#3e9781', cursor: 'pointer', marginLeft: '5px' }}
                                        fontWeight={600}
                                        fontSize={12}
                                        component="span"
                                        onClick={() => {
                                          setCurrentDocumentYear(arrDocumentAndYearsMapping[currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]]);
                                          setCurrentDocumentFolder('importedOrganicAmendmentsInvoices');
                                          setCurrentDocumentIndex(index);
                                          handleConfirmPopUpOpen()
                                        }}
                                      >{t("general_add_comment")}</Typography>
                                    }
                                  </Grid>
                                </Stack>
                              </Grid>
                            </React.Fragment>
                          })}
                        <Grid item style={{ display: 'flex' }}>
                          <SubmitButton sx={{ fontSize: 13, width: '250px' }} component="label" variant="contained" startIcon={<CloudUploadIcon />}
                            disabled={!allowEditing}
                            onChange={(e) => handleFileChange(e, "importedOrganicAmendmentsInvoices", currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex])}>
                            {t("fwi_documents_upload")}
                            <input
                              type="file"
                              accept='application/msword,
                          application/pdf, 
                          application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                          image/png, 
                          image/jpeg'
                              hidden />
                          </SubmitButton>
                        </Grid>
                      </Grid>
                      <Grid item xs={7} md={7} lg={7}></Grid>


                      <Grid item xs={12} md={12} lg={12}>
                        <Divider sx={{ marginY: 6 }}></Divider>

                        <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', flexDirection: 'column', marginTop: 30, marginBottom: 30 }}>
                          <Typography sx={{ marginBottom: 3 }} fontWeight={800} fontSize={20}>{t("fwi_animal_fod_title")}</Typography>
                          <Typography fontSize={15}>{t("fwi_animal_fod_subtitle_1")}<b>{t("fwi_animal_fod_subtitle_2")}</b>{currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]}{t("fwi_animal_fod_subtitle_3")}
                            <Tooltip sx={{ paddingLeft: 1 }} title={t("fwi_animal_fod_info")} placement="top-start">
                              <InfoIcon sx={{
                                fontSize: 'medium',
                                padding: '0px 0px 0px 8px',
                              }} />
                            </Tooltip>
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid item xs={2} md={2} lg={2}>
                        <Typography fontSize={15} fontWeight={600}>{t("fwi_hay")}</Typography>
                      </Grid>
                      <Grid item xs={3} md={3} lg={3}>
                        <FormControl fullWidth>
                          <NumericInput
                            name="fodder"
                            error={emptyNumericFieldError(importedOrganicMatter[5]?.percentImported)}
                            max={max.percent}
                            InputProps={{
                              endAdornment: <InputAdornment position="end">{t("fwi_percent")}</InputAdornment>,
                            }}
                            value={importedOrganicMatter[5]?.percentImported === -9999 ? '' : importedOrganicMatter[5]?.percentImported} fullWidth placeholder={t("fwi_percent_imported")} label={t("fwi_percent_imported")} onChange={(e) => handleImportedOrganicMatterChange(e)} />
                        </FormControl>
                      </Grid>
                      <Grid item xs={7} md={7} lg={7}></Grid>


                      <Grid item xs={2} md={2} lg={2}>
                        <Typography fontSize={15} fontWeight={600}>{t("fwi_invoices")}</Typography>
                      </Grid>
                      <Grid item xs={6} md={6} lg={6}>
                        {currentFarmVerificationDocuments?.yearlyVerificationDocuments?.[arrDocumentAndYearsMapping[currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]]]?.animalFodderInvoices?.length > 0 &&
                          currentFarmVerificationDocuments.yearlyVerificationDocuments[arrDocumentAndYearsMapping[currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]]].animalFodderInvoices.map((ele, index) => {
                            return <React.Fragment key={index}>
                              <Grid container spacing={2}>
                                <Stack marginLeft={2} display={'flex'} marginBottom={1} marginTop={1.5}>
                                  <UploadedDocument
                                    size="small"
                                    label={
                                      <Fragment>
                                        <CheckCircle sx={{ marginRight: 1 }} />
                                        {currentFarmVerificationDocuments?.yearlyVerificationDocuments?.[arrDocumentAndYearsMapping[currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]]]?.animalFodderInvoices[index].fileName}
                                      </Fragment>
                                    }
                                    disabled
                                  ></UploadedDocument>
                                  <Grid item marginBottom={1.5}>
                                    <Typography
                                      sx={{ color: '#3e9781', cursor: 'pointer', marginRight: '5px' }}
                                      fontWeight={600}
                                      fontSize={12}
                                      component="span"
                                      onClick={() => handleDownload(currentFarmVerificationDocuments.yearlyVerificationDocuments[arrDocumentAndYearsMapping[currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]]].animalFodderInvoices[index].s3URL)}
                                    >{t("ver_doc_download")}</Typography>
                                    |
                                    <Typography
                                      sx={{ color: '#3e9781', cursor: 'pointer', marginX: '5px' }}
                                      fontWeight={600}
                                      fontSize={12}
                                      component="span"
                                      onClick={() => handleDeleteFile(index, currentFarmVerificationDocuments.yearlyVerificationDocuments[arrDocumentAndYearsMapping[currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]]].animalFodderInvoices[index].folder, currentFarmVerificationDocuments.yearlyVerificationDocuments[arrDocumentAndYearsMapping[currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]]].animalFodderInvoices[index].fileName, currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex], currentFarmDataObject,
                                        currentFarmVerificationDocuments,
                                        dispatch,
                                        setSnackbarMessage,
                                        setSaveCurrentDataFlag,
                                        setState,
                                        t)}
                                    >{t("ver_doc_delete")}</Typography>
                                    |
                                    {currentFarmVerificationDocuments.yearlyVerificationDocuments[arrDocumentAndYearsMapping[currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]]].animalFodderInvoices[index].comment !== null &&
                                      currentFarmVerificationDocuments.yearlyVerificationDocuments[arrDocumentAndYearsMapping[currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]]].animalFodderInvoices[index].comment !== undefined &&
                                      currentFarmVerificationDocuments.yearlyVerificationDocuments[arrDocumentAndYearsMapping[currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]]].animalFodderInvoices[index].comment !== '' ?
                                      <Typography
                                        sx={{ color: '#3e9781', cursor: 'pointer', marginLeft: '5px' }}
                                        fontWeight={600}
                                        fontSize={12}
                                        component="span"
                                        onClick={() => {
                                          setCurrentDocumentComment(currentFarmVerificationDocuments.yearlyVerificationDocuments[arrDocumentAndYearsMapping[currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]]].animalFodderInvoices[index].comment);
                                          setCurrentDocumentYear(arrDocumentAndYearsMapping[currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]]); //year index
                                          setCurrentDocumentFolder('animalFodderInvoices');
                                          setCurrentDocumentIndex(index);
                                          handleConfirmPopUpOpen()
                                        }}
                                      >{t("general_edit_comment")}</Typography>
                                      :
                                      <Typography
                                        sx={{ color: '#3e9781', cursor: 'pointer', marginLeft: '5px' }}
                                        fontWeight={600}
                                        fontSize={12}
                                        component="span"
                                        onClick={() => {
                                          setCurrentDocumentYear(arrDocumentAndYearsMapping[currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]]);
                                          setCurrentDocumentFolder('animalFodderInvoices');
                                          setCurrentDocumentIndex(index);
                                          handleConfirmPopUpOpen()
                                        }}
                                      >{t("general_add_comment")}</Typography>
                                    }
                                  </Grid>
                                </Stack>
                              </Grid>
                            </React.Fragment>
                          })}
                        <Grid item style={{ display: 'flex' }}>
                          <SubmitButton sx={{ fontSize: 13, width: '250px' }} component="label" variant="contained" startIcon={<CloudUploadIcon />}
                            disabled={!allowEditing} onChange={(e) => handleFileChange(e, "animalFodderInvoices", currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex])}>
                            {t("fwi_upload_invoice")}
                            <input
                              type="file"
                              accept='application/msword,
                        application/pdf, 
                        application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                        image/png, 
                        image/jpeg'
                              hidden />
                          </SubmitButton>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} md={12} lg={12}>
                        <Divider sx={{ marginY: 6 }}></Divider>

                        <Grid item xs={9} sm={9} md={9} lg={9} style={{ display: 'flex', flexDirection: 'column', marginTop: 30, marginBottom: 30 }}>
                          <Typography sx={{ marginBottom: 3 }} fontWeight={800} fontSize={20}>{t("fwi_seeds_title")}</Typography>
                          <Typography fontSize={15}>{t("fwi_seeds_subtitle")}{currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]}.</Typography>
                        </Grid>
                      </Grid>

                      <Grid item xs={2} md={2} lg={2}>
                        <Typography fontSize={15} fontWeight={600}>{t("fwi_invoices")}</Typography>
                      </Grid>
                      <Grid item xs={6} md={6} lg={6}>

                        {currentFarmVerificationDocuments?.yearlyVerificationDocuments?.[arrDocumentAndYearsMapping[currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]]]?.seedsInvoices?.length > 0 &&
                          currentFarmVerificationDocuments.yearlyVerificationDocuments[arrDocumentAndYearsMapping[currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]]].seedsInvoices.map((ele, index) => {
                            return <React.Fragment key={index}>
                              <Grid container spacing={2}>
                                <Stack marginLeft={2} display={'flex'} marginBottom={1} marginTop={1.5}>
                                  <UploadedDocument
                                    size="small"
                                    label={
                                      <Fragment>
                                        <CheckCircle sx={{ marginRight: 1 }} />
                                        {currentFarmVerificationDocuments?.yearlyVerificationDocuments?.[arrDocumentAndYearsMapping[currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]]]?.seedsInvoices[index].fileName}
                                      </Fragment>
                                    }
                                    disabled
                                  ></UploadedDocument>
                                  <Grid item marginBottom={1.5}>
                                    <Typography
                                      sx={{ color: '#3e9781', cursor: 'pointer', marginRight: '5px' }}
                                      fontWeight={600}
                                      fontSize={12}
                                      component="span"
                                      onClick={() => handleDownload(currentFarmVerificationDocuments.yearlyVerificationDocuments[arrDocumentAndYearsMapping[currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]]].seedsInvoices[index].s3URL)}
                                    >{t("ver_doc_download")}</Typography>
                                    |
                                    <Typography
                                      sx={{ color: '#3e9781', cursor: 'pointer', marginX: '5px' }}
                                      fontWeight={600}
                                      fontSize={12}
                                      component="span"
                                      onClick={() => handleDeleteFile(index, currentFarmVerificationDocuments.yearlyVerificationDocuments[arrDocumentAndYearsMapping[currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]]].seedsInvoices[index].folder, currentFarmVerificationDocuments.yearlyVerificationDocuments[arrDocumentAndYearsMapping[currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]]].seedsInvoices[index].fileName, currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex], currentFarmDataObject,
                                        currentFarmVerificationDocuments,
                                        dispatch,
                                        setSnackbarMessage,
                                        setSaveCurrentDataFlag,
                                        setState,
                                        t)}
                                    >{t("ver_doc_delete")}</Typography>
                                    |
                                    {currentFarmVerificationDocuments.yearlyVerificationDocuments[arrDocumentAndYearsMapping[currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]]].seedsInvoices[index].comment !== null &&
                                      currentFarmVerificationDocuments.yearlyVerificationDocuments[arrDocumentAndYearsMapping[currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]]].seedsInvoices[index].comment !== undefined &&
                                      currentFarmVerificationDocuments.yearlyVerificationDocuments[arrDocumentAndYearsMapping[currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]]].seedsInvoices[index].comment !== '' ?
                                      <Typography
                                        sx={{ color: '#3e9781', cursor: 'pointer', marginLeft: '5px' }}
                                        fontWeight={600}
                                        fontSize={12}
                                        component="span"
                                        onClick={() => {
                                          setCurrentDocumentComment(currentFarmVerificationDocuments.yearlyVerificationDocuments[arrDocumentAndYearsMapping[currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]]].seedsInvoices[index].comment);
                                          setCurrentDocumentYear(arrDocumentAndYearsMapping[currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]]); //year index
                                          setCurrentDocumentFolder('seedsInvoices');
                                          setCurrentDocumentIndex(index);
                                          handleConfirmPopUpOpen()
                                        }}
                                      >{t("general_edit_comment")}</Typography>
                                      :
                                      <Typography
                                        sx={{ color: '#3e9781', cursor: 'pointer', marginLeft: '5px' }}
                                        fontWeight={600}
                                        fontSize={12}
                                        component="span"
                                        onClick={() => {
                                          setCurrentDocumentYear(arrDocumentAndYearsMapping[currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]]);
                                          setCurrentDocumentFolder('seedsInvoices');
                                          setCurrentDocumentIndex(index);
                                          handleConfirmPopUpOpen()
                                        }}
                                      >{t("general_add_comment")}</Typography>
                                    }
                                  </Grid>
                                </Stack>
                              </Grid>
                            </React.Fragment>
                          })}
                        <Grid item style={{ marginBottom: '10px', display: 'flex' }}>
                          <SubmitButton sx={{ fontSize: 13, width: '250px' }} component="label" variant="contained" startIcon={<CloudUploadIcon />}
                            disabled={!allowEditing} onChange={(e) => handleFileChange(e, "seedsInvoices", currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex])}>
                            {t("fwi_upload_invoice")}
                            <input
                              type="file"
                              accept='application/msword,
                          application/pdf, 
                          application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                          image/png, 
                          image/jpeg'
                              hidden />
                          </SubmitButton>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Dialog
                      PaperProps={{ sx: { maxWidth: 'revert', width: '700px' } }}
                      open={confirmPopUpOpen}
                      onClose={handleConfirmPopUpClose}
                      aria-labelledby="alert-dialog-confirm-popup"
                      aria-describedby="alert-dialog-confirm-popup-description"
                    >
                      <CloseRoundedIcon sx={{ alignSelf: 'flex-end', padding: 2, color: '#707070', '&:hover': { cursor: 'pointer' } }} onClick={() => {
                        setCurrentDocumentComment('');
                        setCurrentDocumentYear('');
                        setCurrentDocumentFolder('');
                        setCurrentDocumentIndex('');
                        setConfirmPopUpOpen(false)
                      }} />
                      <DialogTitle id="alert-dialog-confirm-popup">
                        <Typography paddingX={2} paddingBottom={2} fontWeight={800} fontSize={24}>{t("general_add_comment")}</Typography>
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description" color='black'>
                          <Typography paddingX={2} >{t("fu_comment_description")}</Typography>
                        </DialogContentText>
                      </DialogContent>
                      <DialogContent sx={{ marginX: 2 }}>
                        <TextField id="verification-document-upload" multiline maxRows={4} sx={{ width: '100%' }} value={currentDocumentComment} placeholder={t("fu_comment_type_here")} label={t("fu_comment_type_here")} onChange={(e) => setCurrentDocumentComment(e.target.value)}></TextField>
                      </DialogContent>
                      <br />
                      <DialogActions style={{ padding: '0px 40px 20px 20px' }}>
                        <SubmitButton disableElevation disableRipple onClick={saveVerificationDocumentComment}>{t("generic_save")}</SubmitButton>
                      </DialogActions>
                    </Dialog>
                  </div>
                </TabPanel>
              )
            })}
        </SwipeableViews>
        {/* </TabList> */}
        <Grid marginTop={4} marginBottom={4} container spacing={2}>
          <Grid item xs={9} sm={9} md={9} lg={9}>

          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3}>
            <SubmitButton style={{ width: '280px' }} onClick={savePageData}>{t("generic_save")}</SubmitButton>
          </Grid>
        </Grid>
      </Box >
    </>
  )
}

export default FarmWideInformationComponent