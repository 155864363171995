import { setAllMonitoringYears } from "../../../actions/currentMonitoringYears";
import { createFarmerAcceptConditions, findFarmerConditionsByEmail } from "../../../actions/farmerAcceptConditions";
import { findFarmlandByEmail } from "../../../actions/farmlands";
import { getFarmMonitoringDetailsByEmail, setFarmMonitoringDetails } from "../../../actions/farmMonitoringDetails";

export const checkIfFirstTimeUser = (dispatch, email) => {
    dispatch(findFarmerConditionsByEmail(email))
        .then(data => {
            if (!data || data.length === 0) {
                const farmerAcceptConditionsData = {
                    email: email,
                    readTermsAndConditionsDuringSignUp: true,
                    readGDPRDuringSignUp: true,
                    readWelcomeScreen: true,
                    readFarmConditions: false,
                };
                dispatch(createFarmerAcceptConditions(farmerAcceptConditionsData))
                    .then(res => {
                        console.log('Farmer accept conditions created successfully:', res);
                    })
                    .catch(error => {
                        console.error('Error creating farmer accept conditions:', error);
                    });
            } else {
                console.log('Farmer accept conditions already exist:', data);
            }
        })
        .catch(error => {
            console.error('Error fetching farmer conditions:', error);
        });

};

export const fetchMonitoringDetails = (dispatch, email) => {
    dispatch(getFarmMonitoringDetailsByEmail(email))
        .then((resFMD) => {
            return dispatch(setFarmMonitoringDetails(resFMD));
        })
        .then((res) => {
            console.log('Set farm monitoring details response:', res);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
};

export const allMonitoringYears = (dispatch, startYear) => {
    const thisYear = new Date().getFullYear()
    const allYears = []
    const pYear = Number(startYear);
    for (var i = pYear - 3; i < thisYear; i++) {
        allYears.push(i);
    }
    dispatch(setAllMonitoringYears(allYears))
        .then((res) => {
            console.log("All monitoring years set successfully:", res);
        })
        .catch((error) => console.error("Error setting all monitoring years:", error));

}

export const findFarmIndex = (dispatch, email, farmId) => {
    return dispatch(findFarmlandByEmail(email)).then((data) => {
        console.log('Data from findFarmlandByEmail:', data);
        const farmIds = data.map(farm => farm._id);
        const currentId = farmId;
        const farmIndex = farmIds.indexOf(currentId);
        return farmIndex === -1 ? 0 : farmIndex;
    }).catch((error) => {
        console.error("Error in findFarmIndex:", error);
        throw error;
    });
};


