import React, { useState } from "react";

import {
  Box,
  Button,
  Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "../../css/LiveStockPopUp.css";
import { emptyNumericFieldErrorZero } from "../general/Validations/fieldValueErrors";
import SubmitValidation from "../general/Validations/SubmitValidation";
import ParcelEditDialog from "../PopUps/ParcelEditDialog";

const ParcelListItem = ({ obj, i }) => {
  const { t } = useTranslation();
  const [valid, setValid] = useState({ parcel: false });
  const [validG, setValidG] = useState({ general: false });
  const [validC, setValidC] = useState({ crops: false });
  const [currentlyEditingParcelName, setCurrentlyEditingParcelName] =
    useState(obj.parcelFixedValues.parcelName);
  const [
    currentlyEditingParcelManualArea,
    setCurrentlyEditingParcelManualArea,
  ] = useState(obj.parcelFixedValues.areaManualEntry?.area);
  const [editParcelNamePopUpOpen, setEditParcelNamePopUpOpen] = useState(false);


  return (
    <Box marginTop={1} spacing={2} alignItems='center' display='flex' gap={2}>

      <SubmitValidation page='parcel' valid={valid['parcel']} setValid={setValid} parcelId={obj.id} infoText='validation_parcel' />

      <Box sx={{ p: 1, border: '1px solid grey', borderRadius: 1, display: "flex", justifyContent: "space-between", alignItems: "center" }} width={600} >
        <Typography variant="subtitle2">   {`${i + 1}  ${obj.parcelFixedValues.parcelName}  | 
  
    ${!emptyNumericFieldErrorZero(obj.parcelFixedValues.areaManualEntry?.area) ?
            ` ${obj.parcelFixedValues.areaManualEntry?.area} HA` : `${obj.parcelFixedValues.areaGeoFile?.area?.toFixed(2)} HA`}`
        }
        </Typography>
        <Button sx={{ color: '#409781', fontSize: '16px' }} onClick={() => setEditParcelNamePopUpOpen(true)}>Edit Parcel</Button>
      </Box>

      <Box alignItems='center' display='flex' >

        <SubmitValidation page='general' valid={validG['general']} setValid={setValidG} parcelId={obj.id} infoText='validation_landuse' />

        <Link
          style={{
            textDecoration: "none",
            color: "#409781",
            fontWeight: "bold",
            width: '130px'
          }}
          to={{
            pathname: "/carbonplus/farmerlanduse/".concat(
              String(obj.parcelFixedValues.parcelID)
            ),
          }}
        >
          {t("pmo_list_gen_info")}
        </Link>{" "}


        <SubmitValidation page='crops' valid={validC['crops']} setValid={setValidC} parcelId={obj.id} infoText='validation_crops' />

        <Link
          style={{
            textDecoration: "none",
            color: "#409781",
            fontWeight: "bold",

          }}
          to={{
            pathname: "/carbonplus/farmercropspage/".concat(
              String(obj.parcelFixedValues.parcelID)
            ),
          }}
        >
          {t("pmo_list_crops")}
        </Link>
      </Box>
      <ParcelEditDialog
        currentlyEditingParcelName={currentlyEditingParcelName}
        setCurrentlyEditingParcelName={setCurrentlyEditingParcelName}
        currentlyEditingParcelManualArea={currentlyEditingParcelManualArea}
        setCurrentlyEditingParcelManualArea={setCurrentlyEditingParcelManualArea}
        parcel={obj}
        editParcelNamePopUpOpen={editParcelNamePopUpOpen}
        setEditParcelNamePopUpOpen={setEditParcelNamePopUpOpen}
      // setDeleteMapDialogOpen={setDeleteMapDialogOpen}
      />

    </Box>
  );

};

export default ParcelListItem;
