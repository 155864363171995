
import React from "react";

import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    InputAdornment,
    TextField
} from "@mui/material";
import NumericInput from '../general/NumericInput';

import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "../../css/LiveStockPopUp.css";
import "../../css/MapParcel.css";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateParcelArea, updateParcelName } from "../../actions/currentMonitoringData";
import { SubmitButton } from "../../styles";



const ParcelEditDialog = ({ currentlyEditingParcelName,
    setCurrentlyEditingParcelName,
    currentlyEditingParcelManualArea,
    setCurrentlyEditingParcelManualArea,
    parcel,
    setMapPopUpForParcel,
    editParcelNamePopUpOpen,
    setEditParcelNamePopUpOpen,
    setDeleteMapDialogOpen,
}) => {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const accountSettings = useSelector((state) => state.accountSettingsReducer);
    const isAdmin = accountSettings?.[0]?.role === 'admin'

    const currentMonitoringDataObject = useSelector(
        (state) => state.currentMonitoringDataReducer
    );

    // useEffect(() => {
    //     dispatch(updateMonitoringData(currentMonitoringDataObject._id, currentMonitoringDataObject));
    // }, [dispatch, currentMonitoringDataObject]);

    const handleParcelNameRenameAndSave = () => {
        let tmpFunctionParamName = {
            newName: currentlyEditingParcelName,
            parcel: parcel,
        };

        let tmpFunctionParamArea = {
            newArea: currentlyEditingParcelManualArea,
            parcel: parcel,
        };
        dispatch(updateParcelName(tmpFunctionParamName))
            .then((res) => {
                return dispatch(updateParcelArea(tmpFunctionParamArea));
            })
            .then((res3) => {
                setEditParcelNamePopUpOpen(false);
                if (setMapPopUpForParcel) {
                    setMapPopUpForParcel(null);
                }
            })
            .catch((error) => {
                console.error('Error in updating parcel process:', error);
            });
    };

    return (
        <Dialog
            open={editParcelNamePopUpOpen}
            onClose={() => setEditParcelNamePopUpOpen(false)}
        >
            <DialogTitle id="alert-dialog-confirm-popup" fontWeight={800}
                fontSize={24}>


                <Grid container spacing={2}>
                    <Grid item xs={6} md={6} lg={6}>
                        <span style={{ fontWeight: "bold" }}>{t("pmo_edit_title")}</span>
                    </Grid>
                    <Grid style={{ alignContent: "right", textAlign: "right" }} item xs={6} md={6} lg={6}>
                        <span onClick={() => setEditParcelNamePopUpOpen(false)} style={{ marginRight: "3px", fontWeight: "bold", cursor: "pointer" }} >X</span>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" color="black">
                    {t("pmo_edit_description")}
                </DialogContentText>
            </DialogContent>
            <DialogContent sx={{ marginX: 2 }}>
                <TextField size='small'
                    style={{ margin: "4px" }}
                    id="parcel-name-textfield"
                    maxRows={4}
                    sx={{ width: "100%" }}
                    value={currentlyEditingParcelName}
                    onChange={(e) => setCurrentlyEditingParcelName(e.target.value)}
                    placeholder="%Parcel%"
                ></TextField>
                <TextField size='small'
                    style={{ margin: "4px" }}
                    id="parcel-area-auto-textfield"
                    maxRows={4}
                    sx={{ width: "100%" }}
                    disabled
                    value={String(
                        parcel?.parcelFixedValues
                            ?.areaGeoFile?.area.toFixed(2)
                    ).concat(t("pmo_edit_ha_auto"))}
                    placeholder="%Parcel%"
                ></TextField>
                <div>
                    <span>
                        {t("pmo_edit_ha_manual")}
                    </span>
                </div>
                <div>
                    <NumericInput
                        decimalScale={3}
                        max={parcel?.parcelFixedValues
                            ?.areaGeoFile?.area * 1.2}
                        style={{ margin: "4px" }}
                        id="parcel-manual-area-textfield"
                        sx={{ width: "100%" }}
                        value={currentlyEditingParcelManualArea === -9999 ? '' : currentlyEditingParcelManualArea}
                        onChange={(e) =>
                            setCurrentlyEditingParcelManualArea(e.target.value)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{"%Area HA%"}</InputAdornment>,
                        }}
                    />
                </div>
                <div
                    style={{
                        margin: "3px",
                        textAlign: "center",
                        justifyContent: "center",
                    }}
                >
                    <SubmitButton
                        style={{ width: "280px" }}
                        onClick={handleParcelNameRenameAndSave}
                    >
                        {t("generic_save")}
                    </SubmitButton>
                </div>
                {isAdmin && setDeleteMapDialogOpen && <div
                    style={{
                        margin: "3px",
                        textAlign: "center",
                        justifyContent: "center",
                    }}
                >
                    <SubmitButton
                        onClick={() => setDeleteMapDialogOpen(true)}
                        style={{ width: "280px" }}
                    >
                        {t("pmo_popup_delete")}
                    </SubmitButton>
                </div>}
            </DialogContent>
        </Dialog >
    )
}

export default ParcelEditDialog