import HttpService from '../services/HttpService';

const getAll = () => {
    return HttpService.getAxiosClient().get("/api/farmsettings/");
};

const get = id => {
    return HttpService.getAxiosClient().get(`/api/farmsettings/${id}`);
};

const create = data => {
    return HttpService.getAxiosClient().post("/api/farmsettings", data);
};

const update = (id, data) => {
    return HttpService.getAxiosClient().patch(`/api/farmsettings/${id}`, data);
};

const remove = id => {
    return HttpService.getAxiosClient().delete(`/api/farmsettings/${id}`);
};

const findByFarmPrimaryId = id => {
    return HttpService.getAxiosClient().get(`/api/farmsettings?farmPrimaryIdFromMongoose=${id}`);
};



const FarmSettingsDataService = {
    getAll,
    get,
    create,
    update,
    remove,
    findByFarmPrimaryId
};

export default FarmSettingsDataService;
