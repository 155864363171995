import React, { forwardRef, useEffect } from 'react';

import '../../css/Dashboard.css';

import {
    Alert,
    Box,
    Checkbox,
    Dialog,
    FormControl,
    FormControlLabel,
    MenuItem,
    Select,
    Slide
} from "@mui/material";

import { useDispatch } from "react-redux";
import {
    FARM_STATUS
} from '../../constants/constants';

import { useState } from 'react';
import { updateFarmSettings } from '../../actions/farmSettings';
import { SubmitButton } from '../../styles';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



const FarmStatusDialog = ({ farmStatusOpen, setFarmStatusOpen, allowEditing, farmAndFarmSettingsData, farmID, farmSettingsStatus, setFarmSettingsStatus, fetchAllFarmers }) => {
    const dispatch = useDispatch();


    const [isDataBlocked, setIsDataBlocked] = useState(!allowEditing)

    useEffect(() => {
        setIsDataBlocked(!allowEditing);
    }, [allowEditing]);

    const handleFarmStatusDialogClose = () => {
        setFarmStatusOpen(false);
    };

    const handleFarmStatusChange = () => {
        farmAndFarmSettingsData.forEach(farmSettings => {
            if (farmSettings.farmPrimaryIdFromMongoose === farmID) {
                let farmSettingsObject = {
                    allowEditing: !isDataBlocked,
                    farmCreatedByUserId: farmSettings.farmCreatedByUserId,
                    farmPrimaryIdFromMongoose: farmID,
                    farmStatus: farmSettingsStatus,
                    updationDate: Date.now().toString()
                };

                dispatch(updateFarmSettings(farmSettings._id, farmSettingsObject))
                    .then(res => {
                        setFarmStatusOpen(false);
                        fetchAllFarmers();
                    })
                    .catch(error => {
                        console.error(error);
                    });
            }
        });
    };
    return (
        <Dialog open={farmStatusOpen} TransitionComponent={Transition} onClose={handleFarmStatusDialogClose} fullWidth>

            <Alert icon={false} severity="warning">
                <div style={{ fontWeight: 'bold' }}>
                    <h2>Farm Status</h2>
                </div>
            </Alert>
            <FormControl>
                <Select
                    style={{ margin: '30px' }}
                    id='farmStatusID'
                    name='farmStatusID'
                    value={farmSettingsStatus}
                    onChange={(e) => setFarmSettingsStatus(e.target.value)}
                >
                    {FARM_STATUS.map(status => <MenuItem value={status} key={status}>
                        {status}
                    </MenuItem>)}
                </Select>
            </FormControl>
            <Box display='flex' justifyContent='space-around' marginBottom='10px'>
                <FormControlLabel
                    control={
                        <Checkbox
                            name="Data Blocked"
                            checked={isDataBlocked}
                            onChange={(e) => setIsDataBlocked(e.target.checked)}
                        />
                    }
                    label="Data Blocked" />
                <SubmitButton onClick={handleFarmStatusChange}>Save</SubmitButton>
                <SubmitButton sx={{ backgroundColor: 'red' }} onClick={handleFarmStatusDialogClose}>Close</SubmitButton>
            </Box>

        </Dialog>
    )
}

export default FarmStatusDialog