import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { v4 as uuidv4 } from "uuid";

import "../css/Dashboard.css";

import {
  Button,
  Grid,
  Typography
} from "@mui/material";

import "../css/FarmerDashboard.css";

import {
  createAccountSettings,
  retrieveAccountSettings,
} from "../actions/accountSettings";
import {
  createFarmMonitoringDetails,
  getFarmMonitoringDetailsByEmail,
  setFarmMonitoringDetails,
} from "../actions/farmMonitoringDetails";
import {
  createFarmSettings,
  findSettingsByFarmerPrimaryId,
} from "../actions/farmSettings";
import { setFarmStatus } from "../actions/farmStatus";
import {
  createFarmVerificationDocuments,
  setFarmVerificationDocuments
} from "../actions/verificationDocuments";

import {
  createEmptyFarmland,
  updateCurrentFarmData
} from "../actions/currentFarmland";
import { setCurMonitoringData } from "../actions/currentMonitoringData";
import {
  createFarmland,
  findFarmlandByEmail,
  retrieveFarmlands,
} from "../actions/farmlands";
import {
  createMonitoringData,
  findMonitoringDataByFarmId
} from "../actions/monitoringData";

import { FARM_DATA_IS_YET_TO_BE_SUBMITTED } from "../constants/constants";

import { useUser } from "@clerk/clerk-react";
import GettingStartedComponent from "../components/GettingStartedComponent";
import NavigationComponent from "./NavigationComponent";
import WelcomeScreen from "./PopUps/WelcomeScreen";
import StatusProgress from "./StatusProgress";

import FarmChange from "./general/FarmChange";
import { docTypes, yearlyFarmData } from "./general/utils/emptyTempDataObjects";
import { allMonitoringYears, checkIfFirstTimeUser, fetchMonitoringDetails } from "./general/utils/farmHandlers";
import { getVerificationDocuments } from "./general/utils/getVerificationDocuments";

const FarmerDashboardComponent = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { user } = useUser();
  const email = user?.emailAddresses[0]?.emailAddress;

  const currentFarmDataObject = useSelector((state) => state.currentFarmDataReducer);
  const currentFarmMonitoringDetails = useSelector((state) => state.farmMonitoringDetailsReducer);
  const existingFarmlands = useSelector((state) => state.farmlandReducer);

  const [welcomeScreenOpen, setWelcomeScreenOpen] = useState(false);
  const [newFarmButton, setNewFarmButton] = useState(false);

  const handleWelcomeScreenClose = () => {
    setNewFarmButton(true);
    setWelcomeScreenOpen(false);
  };

  const fetchFarmland = async () => {
    dispatch(findFarmlandByEmail(email)).then((data) => {
      const farmIds = data.map(farm => farm.farmInfo.farmId)
      const currentId = currentFarmDataObject.farmInfo.farmId
      const farmIndex = farmIds.indexOf(currentId) === -1 ? 0 : farmIds.indexOf(currentId)
      if (data.length > 0) {
        dispatch(updateCurrentFarmData(data[farmIndex])).then((res) => {
          dispatch(getFarmMonitoringDetailsByEmail(email))
            .then((res) => {
              dispatch(setFarmMonitoringDetails(res[farmIndex]))
                .then((res) => {
                  let tempData = {
                    farmId: data[farmIndex]._id,
                    email: email,
                    uniqueCfFarmId: currentFarmDataObject.farmInfo.uniqueCfFarmId,
                    projectStartYear: res.payload.startYear,
                    yearlyFarmData: yearlyFarmData
                  };

                  getVerificationDocuments(dispatch, currentFarmDataObject.farmInfo.email, farmIndex)


                  dispatch(findMonitoringDataByFarmId(data[farmIndex]._id))
                    .then((res4) => {
                      if (res4.data.length === 0) {
                        dispatch(createMonitoringData(tempData))
                          .then((res) => {
                            dispatch(setCurMonitoringData(res.data))
                              .then((res3) => { });

                          })
                          .catch(error => {
                            console.error(error);
                          });

                      } else {
                        dispatch(setCurMonitoringData(res4.data[0]))
                          .then((res3) => { })
                          .catch(error => {
                            console.error(error);
                          });
                      }
                    })
                    .catch(error => {
                      console.error(error);
                    });

                });
            });
        });
        dispatch(findSettingsByFarmerPrimaryId(data[farmIndex]?._id))
          .then((res) => {
            let editMyFarm = false;
            if (res[0]?.allowEditing) {
              editMyFarm = true;
            }
            let tmpFarmStatusData = {
              status: res[0]?.farmStatus,
              allowEditing: editMyFarm,
              editFarm: editMyFarm,
            };
            dispatch(setFarmStatus(tmpFarmStatusData))
              .then((res2) => { })
              .catch(error => {
                console.error(error);
              });
          })
          .catch(error => {
            console.error(error);
          });

      } else {
        setWelcomeScreenOpen(true);
        createEmptyFarm();
      }
    });
  };

  const createEmptyFarm = () => {
    dispatch(retrieveFarmlands())
      .then((res) => {
        let nextCfFarmId = "";
        let numOfFarms = res.data.length;

        numOfFarms < 9
          ? (nextCfFarmId = `CF_00${numOfFarms + 1}`)
          : numOfFarms >= 9 && numOfFarms < 99
            ? (nextCfFarmId = `CF_0${numOfFarms + 1}`)
            : (nextCfFarmId = `CF_${numOfFarms + 1}`);

        let tmpData = {
          farmId: uuidv4().toString(),
          emailAddress: email,
          uniqueCfFarmId: nextCfFarmId,
        };

        dispatch(createEmptyFarmland(tmpData))
          .then((newRes) => { })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });

  };

  const handleAddYourFarm = () => {
    if (currentFarmDataObject.farmInfo.email) {
      dispatch(findFarmlandByEmail(email))
        .then((data) => {
          if (data === null || data === undefined || data.length === 0) {
            postNewFarmland();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const createNewFarmVerificationDocuments = (email, id) => {
    let dataObj = {
      farmPrimaryIdFromMongoose: id,
      email: email,
      landOwnership: [],
      kmlFiles: [],
      yearlyVerificationDocuments: [
        docTypes,
        docTypes,
        docTypes,
        docTypes,
        docTypes,
        docTypes,
      ],
    };
    dispatch(createFarmVerificationDocuments(dataObj))
      .then((res) => {
        console.log("Farm verification documents created:", res);
        dispatch(setFarmVerificationDocuments(res))
      })
      .catch((error) => {
        console.error("Error creating farm verification documents:", error);
      });

  };

  const postNewFarmland = () => {

    if (!currentFarmDataObject._id) {
      dispatch(createFarmland(currentFarmDataObject))
        .then((res) => {
          if (res?.data?._id) {
            let farmSettingsObject = {
              farmStatus: FARM_DATA_IS_YET_TO_BE_SUBMITTED,
              farmPrimaryIdFromMongoose: res.data._id,
              farmCreatedByUserId: "",
              allowEditing: false,
            };
            let accountSettingsObject = {
              farmPrimaryIdFromMongoose: res.data._id,
              email: res.data.farmInfo.email,
              language: i18n.language,
              role: 'farmer',
              id: uuidv4(),
            };

            let farmMonitoringDetailsObject = {
              farmPrimaryIdFromMongoose: res.data._id,
              email: res.data.farmInfo.email,
              monitoringRound: "1",
              startYear: "",
            };


            dispatch(createFarmSettings(farmSettingsObject))
              .then(
                (fsRes) => {
                  console.log("Farm settings created:", fsRes);
                }
              ).catch((error) => console.error("Error creating farm settings:", error));

            dispatch(createAccountSettings(accountSettingsObject))
              .then(
                (asRes) => {
                  console.log("Account settings created:", asRes);
                }
              ).catch((error) => console.error("Error creating account settings:", error));

            dispatch(createFarmMonitoringDetails(farmMonitoringDetailsObject))
              .then(
                (fmdRes) => {
                  console.log("Farm monitoring details created:", fmdRes);
                }
              ).catch((error) => console.error("Error creating farm monitoring details:", error));


            fetchFarmland();

            createNewFarmVerificationDocuments(
              res.data.farmInfo.email,
              res.data._id
            );
            setNewFarmButton(false);
          }
        })
        .catch((e) => {
          console.log("Unable to post farm", e);
        });
    }
  };

  const fetchAccountSettings = () => {
    dispatch(retrieveAccountSettings(email))
      .then((res) => {
        if (!res.data) {
          if (currentFarmDataObject._id) {
            const tmpData = {
              farmPrimaryIdFromMongoose: currentFarmDataObject._id,
              email: email,
              language: i18n.language,
              role: 'farmer',
              id: uuidv4(),
            };
            dispatch(createAccountSettings(tmpData))
              .then((res) => {
                console.log("Account settings created:", res);
              })
              .catch((error) => {
                console.error("Error creating account settings:", error);
              });
          }
        }
        else { i18n.changeLanguage(res.data[0]?.language) }
      })
      .catch((error) => {
        console.error("Error retrieving account settings:", error);
      });
  };

  useEffect(() => {
    fetchFarmland()
  }, [currentFarmDataObject._id]);

  useEffect(() => {
    fetchAccountSettings();
    fetchMonitoringDetails(dispatch, email);
    checkIfFirstTimeUser(dispatch, email);
  }, []);

  useEffect(() => {
    allMonitoringYears(dispatch, currentFarmMonitoringDetails.startYear);
  }, [currentFarmMonitoringDetails.startYear]);


  const foundElement = existingFarmlands?.find(ele => ele.farmInfo.email === email);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} xl={12}>
        <Typography
          variant="h1" >
          {t("home_your_c_app")}
        </Typography>
      </Grid>
      {foundElement && (
        <Grid item xs={12} sm={12} md={4} xl={3}>
          <FarmChange />
          <NavigationComponent />
        </Grid>
      )}
      {newFarmButton && (
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#409781",
              minWidth: "200px",
              maxWidth: "200px",
            }}
            onClick={handleAddYourFarm}
          >
            {t("home_add_farm")}
          </Button>
        </Grid>
      )}
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <GettingStartedComponent />
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <StatusProgress />
      </Grid>
      <WelcomeScreen welcomeScreenOpen={welcomeScreenOpen} handleWelcomeScreenClose={handleWelcomeScreenClose} />
    </Grid>
  );
};

export default FarmerDashboardComponent;