
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import produce from 'immer';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import {
    Info as InfoIcon,
} from "@mui/icons-material";
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { Backdrop, Box, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, Snackbar, Tab, Tabs, TextField, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import SwipeableViews from 'react-swipeable-views';

import { useUser } from '@clerk/clerk-react';
import React from 'react';
import { addEmptyFelledTrees, addEmptyTillingEvent, addNewEmptyOm, updateAnimalFodderByYear, updateFelledTrees, updateGrazingMonthsByYear, updateIrrigation, updateOMAmount, updateOMSubType, updatePrimaryLandUse, updateSecondaryLandUse, updateTillingEvent } from '../actions/currentMonitoringData';
import { updateMonitoringData } from '../actions/monitoringData';
import { GoBackButton, SubmitButton } from '../styles';
import ConfirmDialog from "./general/ConfirmDialog";
import CopyFromOtherYear from './general/CopyFromOtherYear';
import NumericInput from "./general/NumericInput";
import { TabPanel, a11yProps } from './general/TabPanel';
import TrailingLine from "./general/TrailingLine";
import { interdependentError } from "./general/Validations/fieldValueErrors";
import { max, maxDynamic } from "./general/Validations/minMaxValues";
import { interdependencyValidation } from "./general/Validations/validateValues";



const FarmerLanduseComponent = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const { user } = useUser();
    const email = user?.emailAddresses[0]?.emailAddress;

    const [tabPanelIndex, setTabPanelIndex] = useState(0);
    const currentMonitoringDataObject = useSelector(state => state.currentMonitoringDataReducer);
    const currentMonitoringYearsObject = useSelector(state => state.monitoringYearsTrackerReducer);
    const currentFarmStatusReducer = useSelector(
        (state) => state.farmStatusReducer
    );


    const accountSettings = useSelector((state) => state.accountSettingsReducer);
    const isAdmin = accountSettings?.[0]?.role === 'admin'
    const allowEditing = currentFarmStatusReducer?.allowEditing || isAdmin
    const dispatch = useDispatch();
    let { parcelid } = useParams();
    const [numberOfTabs, setNumberOfTabs] = useState([]);
    const [openCopyDataParcelsDialog, setOpenCopyDataParcelsDialog] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [state, setState] = useState({
        snackBarOpen: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal, snackBarOpen } = state;
    const [showLoader, setShowLoader] = useState(false);
    const [saveCurrentDataFlag, setSaveCurrentDataFlag] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [isFilled, setIsFilled] = useState([]);

    const handleBackdropClose = () => {
        setSaveCurrentDataFlag(false);
    };

    const handleBackdropClickCaptureEvent = (e) => {
        e.stopPropagation();
        return false;
    };
    var emptyTillageObject = {
        "type": null,
        "date": null,
        "percent": -9999
    };

    var emptyTreeFellingObject = {
        "species": null,
        "other": null,
        "treeNumber": -9999,
        "DBHmean": { "diameter": -9999, "units": "in cms" },
    };

    var emptyCompostOMObject = {
        "id": uuidv4(),
        "type": "compost",
        "subType": "",
        "other": "",
        "amount": -9999,
        "units": "tonnes"
    };
    var emptyManureOMObject = {
        "id": uuidv4(),
        "type": "manure",
        "subType": "",
        "other": "",
        "amount": -9999,
        "units": "tonnes"
    };

    var emptyMulchOMObject = {
        "id": uuidv4(),
        "type": "mulch",
        "subType": "",
        "other": "",
        "amount": -9999,
        "units": "tonnes"
    };

    var emptySlurryOMObject = {
        "id": uuidv4(),
        "type": "slurry",
        "subType": "",
        "other": "",
        "amount": -9999,
        "units": "litres"
    };

    var emptyOtherOMObject = {
        "id": uuidv4(),
        "type": "other",
        "subType": "",
        "other": "",
        "amount": -9999,
        "units": "tonnes"
    };


    const [grazingMonths, setGrazingMonths] = useState([]);

    const [irrigation, setIrrigation] = useState(null);


    const year = currentMonitoringYearsObject?.allMonitoringYears[tabPanelIndex]
    const currentParcel = currentMonitoringDataObject?.yearlyFarmData.find(yFD => yFD.year === currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]).parcelLevelData.find(eleParcelData => eleParcelData?.parcelFixedValues?.parcelID === parcelid)
    const parcelData = currentParcel?.yearParcelData

    const handleValidate = (event, newValue, path) => {
        const organicAmendments = parcelData?.addedOrganicMatter.map(matter => { return { type: matter.subType, amount: matter.amount } }).slice(1);
        const tillage = parcelData?.tillage.map(event => { return { type: event.type, date: event.date, percent: event.percent } })
        const treeFelling = parcelData?.treeFelling.map(tree => { return { DBHmean: tree.DBHmean.diameter, species: tree.species, treeNumber: tree.treeNumber, other: tree.species?.includes('other') ? tree.other : tree.species && tree.species !== '-' ? 'No other' : '' } })
        const toCheckArray = [organicAmendments, tillage, treeFelling]
        let isAllFilledForService = [[{ biochar: 'biochar' }], [], [],]
        toCheckArray.forEach((parcelDataSegment, index) => {
            const parcelDataSegmentLength = parcelDataSegment.length
            for (let i = 0; i < parcelDataSegmentLength; i++) {
                // check for each rows if they are valid (all filled or all empty)
                isAllFilledForService[index].push(interdependencyValidation([parcelDataSegment[i]]))
            }
        })
        // boolean array of isValid turned into indexes
        let falseIndices = isAllFilledForService.map(isFilledArray => { return isFilledArray.map((value, index) => value === false ? index : '').filter(index => index !== '') })
        setIsFilled(falseIndices)
        if (isAllFilledForService.flat().includes(false)) {
            setDialogOpen(true)
        } else if (newValue || newValue === 0) { handleTabChange(event, newValue) }
        else if (path) {
            savePageData()
            navigate(path)
        } else {
            savePageData()
        }
    }

    const handleDeleteUnFilled = () => {
        isFilled.forEach((segment, index) => {
            segment.reverse().forEach(value => {

                switch (index) {
                    case 0:
                        handleAddedOrganicMatterChange({
                            target: { value: '-', name: 'subType' }
                        }, parcelData.addedOrganicMatter[value].id)
                        break;
                    case 1:
                        handleTillageScheduleChange({
                            target: {
                                "value": "-",
                                "name": "date"
                            }
                        }, value)
                        break;
                    case 2:
                        handleFelledTreesChange({
                            target: {
                                "value": "-",
                                "name": "species"
                            }
                        }, value)
                        break;
                    default:
                        break;
                }
            });
        });
        savePageData()
        setDialogOpen(false)
    }

    const handleTabChange = (event, newValue) => {
        setTabPanelIndex(newValue);
        handleMonitoringDataSave(false);
    };

    const handleChangeIndex = (index) => {
        setTabPanelIndex(index);
    };

    const handlePrimaryLandUseChange = (e) => {

        let funParam = {
            year: currentMonitoringYearsObject?.allMonitoringYears[tabPanelIndex],
            parcelId: parcelid,
            data: e.target.value
        };

        dispatch(updatePrimaryLandUse(funParam))
            .then(res => {
                console.log('Primary land use updated successfully:', res);
            })
            .catch(error => {
                console.error('Error updating primary land use:', error);
            });

    };

    const handleAnimalFodderChange = (e) => {

        let funParam = {
            year: currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex],
            parcelId: parcelid,
            amount: e.target.value,
            units: 'tonnes'
        };
        dispatch(updateAnimalFodderByYear(funParam))
            .then(res => {
                console.log('Animal fodder updated successfully:', res);
            })
            .catch(error => {
                console.error('Error updating animal fodder:', error);
            });
    };

    const handleSecondaryLandUseChange = (e) => {

        let funParam = {
            year: currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex],
            parcelId: parcelid,
            data: e.target.value
        };

        dispatch(updateSecondaryLandUse(funParam))
            .then(res => {
                console.log('Secondary land use updated successfully:', res);
            })
            .catch(error => {
                console.error('Error updating secondary land use:', error);
            });
    };

    const removeDuplicateMonths = (months) => {
        return months.filter((v, i) => months.indexOf(v) === i)
    }
    const handleGrazingMonthsChange = (e) => {
        let monthValues = e.target.value;
        monthValues = removeDuplicateMonths(monthValues);
        let arrMonths = Array(12).fill(false);
        for (var x = 0; x < monthValues?.length; x++) {
            switch (String(monthValues[x]).toUpperCase()) {
                case "JANUARY":
                    arrMonths[0] = true
                    break;
                case "FEBRUARY":
                    arrMonths[1] = true
                    break;
                case "MARCH":
                    arrMonths[2] = true
                    break;
                case "APRIL":
                    arrMonths[3] = true
                    break;
                case "MAY":
                    arrMonths[4] = true
                    break;
                case "JUNE":
                    arrMonths[5] = true
                    break;

                case "JULY":
                    arrMonths[6] = true
                    break;
                case "AUGUST":
                    arrMonths[7] = true
                    break;
                case "SEPTEMBER":
                    arrMonths[8] = true
                    break;
                case "OCTOBER":
                    arrMonths[9] = true
                    break;
                case "NOVEMBER":
                    arrMonths[10] = true
                    break;
                case "DECEMBER":
                    arrMonths[11] = true
                    break;

                default:
                    break;
            }
        };

        let funParam = {
            year: currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex],
            parcelId: parcelid,
            data: arrMonths
        };
        dispatch(updateGrazingMonthsByYear(funParam))
            .then(res => {
                console.log('Grazing months updated successfully:', res);
            })
            .catch(error => {
                console.error('Error updating grazing months:', error);
            });

    };

    const handleTillageScheduleChange = (e, i) => {
        const { name, value } = e.target;
        let tmpData = {
            year: currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex],
            parcelId: parcelid,
            tillageIndex: i,
            attrName: name,
            data: value
        };

        dispatch(updateTillingEvent(tmpData))
            .then(res => {
                console.log('Tilling event updated successfully:', res);
            })
            .catch(error => {
                console.error('Error updating tilling event:', error);
            });
    };

    const addNewTillageSchedule = () => {
        let tmpData = {
            year: currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex],
            parcelId: parcelid,
            data: emptyTillageObject
        };
        dispatch(addEmptyTillingEvent(tmpData))
            .then(res => {
                console.log('Empty tilling event added successfully:', res);
            })
            .catch(error => {
                console.error('Error adding empty tilling event:', error);
            });

    };

    const handleIrrigationChange = (e) => {

        let tmpData = {
            year: currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex],
            parcelId: parcelid,
            data: e.target.value
        };
        dispatch(updateIrrigation(tmpData))
            .then(res => {
                console.log('Irrigation data updated successfully:', res);
            })
            .catch(error => {
                console.error('Error updating irrigation data:', error);
            });
    };

    const handleFelledTreesChange = (e, i) => {
        const { name, value } = e.target;
        let tmpData = {
            year: currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex],
            parcelId: parcelid,
            felledTreesIndex: i,
            attrName: name,
            data: value
        };

        dispatch(updateFelledTrees(tmpData))
            .then(res => {
                console.log('Felled trees data updated successfully:', res);
            })
            .catch(error => {
                console.error('Error updating felled trees data:', error);
            });

    };

    const addNewFelledTrees = () => {
        let tmpData = {
            year: currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex],
            parcelId: parcelid,
            data: emptyTreeFellingObject
        };
        dispatch(addEmptyFelledTrees(tmpData))
            .then(res => {
                console.log('Empty felled trees added successfully:', res);
            })
            .catch(error => {
                console.error('Error adding empty felled trees:', error);
            });

    };

    const addNewOrganicMatter = (omType) => {
        var emptyOb = null
        switch (String(omType).toUpperCase()) {
            case "COMPOST":
                emptyOb = emptyCompostOMObject;
                break;
            case "MANURE":
                emptyOb = emptyManureOMObject;
                break;
            case "MULCH":
                emptyOb = emptyMulchOMObject;
                break;
            case "SLURRY":
                emptyOb = emptySlurryOMObject;
                break;
            case "OTHER":
                emptyOb = emptyOtherOMObject;
                break;

            default:
                break;
        }

        let tmpData = {
            year: currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex],
            parcelId: parcelid,
            data: emptyOb
        };
        dispatch(addNewEmptyOm(tmpData))
            .then(res => {
                console.log('New empty OM added successfully:', res);
            })
            .catch(error => {
                console.error('Error adding new empty OM:', error);
            });

    };

    const handleAddedOrganicMatterChange = (e, omId) => {
        let { name, value } = e.target;
        let funParam = {
            year: currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex],
            parcelId: parcelid,
            data: value,
            omId: omId
        };
        if (String(name).toUpperCase() === "SUBTYPE") {
            dispatch(updateOMSubType(funParam))
                .then(res => {
                    console.log('OM subtype updated successfully:', res);
                })
                .catch(error => {
                    console.error('Error updating OM subtype:', error);
                });
        } else {
            dispatch(updateOMAmount(funParam))
                .then(res => {
                    console.log('OM amount updated successfully:', res);
                })
                .catch(error => {
                    console.error('Error updating OM amount:', error);
                });
        }


    }

    const savePageData = () => {
        handleMonitoringDataSave(true);
    }

    const handleMonitoringDataSave = (showSavingDataMessage) => {
        if (showSavingDataMessage) {
            setShowLoader(true);
            setSaveCurrentDataFlag(true);
        }
        var tmpMonitoringDataObject = _.cloneDeep(currentMonitoringDataObject);
        const cleanedMonitoringDataObject = produce(tmpMonitoringDataObject, draft => {

            //const obYear = draft?.yearlyFarmData?.find(ele => ele.year === currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]);
            draft?.yearlyFarmData?.map(obYear => {
                const obParcelData = obYear?.parcelLevelData?.find(ele => ele.parcelFixedValues.parcelID === parcelid);
                const obNewValue = obParcelData?.yearParcelData.addedOrganicMatter.filter((v, i) => ((v?.subType !== "-" && i >= 6) || (i < 6)))
                if (obNewValue !== undefined && obNewValue !== null) {
                    obParcelData.yearParcelData.addedOrganicMatter = []
                    obNewValue.map((el, aOMIndex) => {
                        if (aOMIndex < 6 && el.subType === "-") {
                            obParcelData?.yearParcelData.addedOrganicMatter.push({
                                "id": el.id,
                                "type": el.type,
                                "subType": "",
                                "other": "",
                                "amount": el.amount,
                                "units": el.units
                            })
                        } else {
                            obParcelData?.yearParcelData.addedOrganicMatter.push(el);
                        }
                    })
                }

                const obTillageArray = obParcelData?.yearParcelData.tillage
                if (obTillageArray !== undefined && obTillageArray !== null) {
                    obParcelData.yearParcelData.tillage = []
                    obTillageArray.map(el => {
                        if (el.date !== "-") {
                            obParcelData.yearParcelData.tillage.push(el);
                        }
                    })
                    if (obTillageArray.length === 0) {
                        obParcelData.yearParcelData.tillage.push({
                            "type": "",
                            "date": null,
                            "percent": -9999
                        })
                    }
                }

                const obFellingArray = obParcelData?.yearParcelData.treeFelling
                if (obFellingArray !== undefined && obFellingArray !== null) {
                    obParcelData.yearParcelData.treeFelling = []
                    obFellingArray.map(el => {
                        if (el.species !== "-") {
                            obParcelData.yearParcelData.treeFelling.push(el);
                        }
                    })
                    if (obFellingArray.length === 0) {
                        obParcelData.yearParcelData.treeFelling.push({
                            "species": "",
                            "other": "",
                            "treeNumber": -9999,
                            "DBHmean": { "diameter": -9999, "units": "cm" },
                        })
                    }
                }



            });
        });
        dispatch(updateMonitoringData(currentMonitoringDataObject._id, cleanedMonitoringDataObject))
            .then(res => {
                setSnackbarMessage(t("farmland_draft_submission_msg_success"));
                setState({ snackBarOpen: true, vertical: "top", horizontal: "center" });
                if (showSavingDataMessage) {
                    setShowLoader(false);
                    setSaveCurrentDataFlag(false);
                }
            })
            .catch(error => {
                console.error('Error updating monitoring data:', error);
                setShowLoader(false);
                setSaveCurrentDataFlag(false);
            });

    }

    const initializeTabData = () => {
        let tmpArr = Array()
        currentParcel?.yearParcelData?.grazingMonthlyEvent.map((gME, i) => {
            if (gME) {
                switch (i) {
                    case 0:
                        tmpArr.push("january")
                        break;
                    case 1:
                        tmpArr.push("february")
                        break;
                    case 2:
                        tmpArr.push("march")
                        break;
                    case 3:
                        tmpArr.push("april")
                        break;
                    case 4:
                        tmpArr.push("may")
                        break;
                    case 5:
                        tmpArr.push("june")
                        break;
                    case 6:
                        tmpArr.push("july")
                        break;
                    case 7:
                        tmpArr.push("august")
                        break;
                    case 8:
                        tmpArr.push("september")
                        break;
                    case 9:
                        tmpArr.push("october")
                        break;

                    case 10:
                        tmpArr.push("november")
                        break;

                    case 11:
                        tmpArr.push("december")
                        break;

                    default:
                        break;
                }
            }
        });
        setGrazingMonths(tmpArr);
    }

    const parcelArea = currentParcel?.parcelFixedValues.areaGeoFile.area


    const handleCopyDataDialog = () => {
        setOpenCopyDataParcelsDialog(true);
    }

    const handleSnackbarClose = () => {
        setState({ vertical: "top", horizontal: "center", snackBarOpen: false });
    };

    useEffect(() => {
        setNumberOfTabs([currentMonitoringYearsObject.allMonitoringYears])
        initializeTabData();
    }, [])

    useEffect(() => {
        initializeTabData();
    }, [currentMonitoringDataObject])

    useEffect(() => {
        initializeTabData();
    }, [tabPanelIndex])

    return (
        <>

            {!isAdmin &&
                <Box marginTop='80px' marginLeft={2.8} display='flex' alignItems='center'>
                    <GoBackButton onClick={() => handleValidate('', '', '/carbonplus/landuse')}>
                        <ArrowBackIosRoundedIcon />
                        <Typography sx={{ fontWeight: 800, paddingLeft: 2 }}>{t("back_to_home")}</Typography>
                    </GoBackButton>
                </Box>}

            {showLoader && (
                <Backdrop
                    onClickCapture={(e) => handleBackdropClickCaptureEvent(e)}
                    style={{ color: "#fff", zIndex: 99999 }}
                    open={saveCurrentDataFlag}
                    onClick={handleBackdropClose}
                >
                    <div style={{ fontSize: 20, fontWeight: 600, color: "white", marginTop: "20px" }}>
                        {t("generic_saving_data")}
                    </div>
                </Backdrop>
            )}

            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={snackBarOpen}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
                key={vertical + horizontal}
            />
            <Box marginLeft={12} marginRight={12} marginBottom={4} marginTop={6} sx={{ flexGrow: 1 }}>
                <Grid container marginBottom={2} spacing={2}>
                    <Grid item xs={9} sm={9} md={9} lg={9}>
                        <Typography variant="h1">
                            {t("pgi_screen_title_1")} {currentParcel?.parcelFixedValues?.parcelName} | {t("pgi_screen_title_2")}

                        </Typography>
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} lg={3} marginTop={3.5} display={'flex'} sx={{ justifyContent: 'flex-end' }}>
                        <Typography variant='h2' style={{ color: '#409781', cursor: "pointer" }} onClick={() => handleValidate('', '', `/carbonplus/farmercropspage/${parcelid}`)}>
                            {t("pgi_crops_info_button")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography variant="subtitle1">
                            {t("pgi_subtitle")}
                        </Typography>
                        {allowEditing && <p style={{ cursor: "pointer", color: '#409781', fontWeight: 'bold', marginTop: '40px', marginBottom: '30px' }} onClick={handleCopyDataDialog}>{t("pci_copy_data")}</p>}
                    </Grid>
                </Grid>
                <Tabs
                    value={tabPanelIndex}
                    onChange={handleValidate}
                    sx={{
                        zIndex: 1,
                        position: 'sticky',
                        top: 64,
                        '& .MuiTabs-indicator': {
                            height: '5px'
                        }
                    }}
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    {
                        currentMonitoringYearsObject.allMonitoringYears.map((val, i) => {
                            let bgColor = '#e6e6e6';
                            if (i > 2) { bgColor = '#808080' } return (<Tab sx={{ color: 'black', border: 2, borderColor: 'white', fontSize: 18, fontWeight: 600, backgroundColor: bgColor }} label={val} {...a11yProps({ i })} key={i} />)
                        })
                    }
                </Tabs>

                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={tabPanelIndex}
                    onChangeIndex={handleChangeIndex}
                >
                    {
                        currentMonitoringYearsObject?.allMonitoringYears?.map((tabValue, tabIndex) => {

                            return (<TabPanel style={{ overflow: "auto", marginTop: 14 }} value={tabPanelIndex} index={tabIndex} dir={theme.direction} key={tabIndex}>
                                <div>
                                    <Typography marginBottom={1} variant="h2">{t("pgi_landuse_title")}</Typography>

                                    <Typography variant="subtitle2">
                                        {t("pgi_landuse_subtitle")}{currentMonitoringYearsObject?.allMonitoringYears[tabPanelIndex]}
                                    </Typography>
                                    <Grid container sx={{ marginTop: 3 }} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                        <Grid item xs={2} md={2} lg={2}>
                                            <Typography variant="h3">{t("pgi_primary_lu")}</Typography>
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4}>
                                            <FormControl sx={{ fieldset: { legend: { maxWidth: currentParcel?.yearParcelData?.primaryLandUse && '100%' } } }} fullWidth>
                                                <InputLabel shrink={currentParcel?.yearParcelData?.primaryLandUse} size="small" id="primary-land-use-label">{t("pgi_lu_type_dd_name")}</InputLabel>
                                                <Select
                                                    label={t("pgi_lu_type_dd_name")}
                                                    labelId="primary-land-use-label"
                                                    size="small"
                                                    id="primary-land-use-select"
                                                    disabled={!allowEditing}
                                                    error={!currentParcel?.yearParcelData?.primaryLandUse}
                                                    value={currentParcel?.yearParcelData?.primaryLandUse}
                                                    onChange={(e) => handlePrimaryLandUseChange(e)}
                                                >
                                                    <MenuItem value="silvopasture">{t("pgi_lu_type_dd_value_1")}</MenuItem>
                                                    <MenuItem value="arable crops">{t("pgi_lu_type_dd_value_2")}</MenuItem>
                                                    <MenuItem value="grassland">{t("pgi_lu_type_dd_value_3")}</MenuItem>
                                                    <MenuItem value="agroforestry/tree crops">{t("pgi_lu_type_dd_value_4")}</MenuItem>
                                                    <MenuItem value="forestry">{t("pgi_lu_type_dd_value_5")}</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6} md={6} lg={6}></Grid>

                                        <Grid item xs={2} md={2} lg={2}>
                                            <Typography variant="h3">{t("pgi_secondary_lu")}</Typography>
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4}>
                                            <FormControl sx={{ fieldset: { legend: { maxWidth: currentParcel?.yearParcelData?.secondaryLandUse && '100%' } } }} fullWidth>
                                                <InputLabel shrink={currentParcel?.yearParcelData?.secondaryLandUse} size="small" id="secondary-land-use-label">{t("pgi_lu_type_dd_name")}</InputLabel>
                                                <Select
                                                    label={t("pgi_lu_type_dd_name")}
                                                    labelId="secondary-land-use-label"
                                                    size="small"
                                                    id="secondary-land-use-select"
                                                    disabled={!allowEditing}
                                                    value={currentParcel?.yearParcelData?.secondaryLandUse}
                                                    onChange={(e) => handleSecondaryLandUseChange(e)}
                                                >
                                                    <MenuItem value="silvopasture">{t("pgi_lu_type_dd_value_1")}</MenuItem>
                                                    <MenuItem value="arable crops">{t("pgi_lu_type_dd_value_2")}</MenuItem>
                                                    <MenuItem value="grassland">{t("pgi_lu_type_dd_value_3")}</MenuItem>
                                                    <MenuItem value="agroforestry/tree crops">{t("pgi_lu_type_dd_value_4")}</MenuItem>
                                                    <MenuItem value="forestry">{t("pgi_lu_type_dd_value_5")}</MenuItem>
                                                    <MenuItem value="-">-</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6} md={6} lg={6}></Grid>
                                    </Grid>
                                    <TrailingLine type='thick' />
                                </div>
                                <div>
                                    <Typography variant="h2" marginBottom={1}>{t("pgi_organic_amendments_title")}</Typography>
                                    <Typography variant="subtitle2">
                                        <Trans i18nKey={"pgi_organic_amendments_desc_1"} values={{ year: currentMonitoringYearsObject?.allMonitoringYears[tabPanelIndex] }}>
                                        </Trans>
                                    </Typography>
                                    <Grid container sx={{ marginTop: 3 }} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                        {
                                            currentParcel?.yearParcelData?.addedOrganicMatter?.filter((aOM) => String(aOM.type).toUpperCase() === "BIOCHAR")?.map((val, i) =>
                                                <React.Fragment key={i}>
                                                    {i === 0 && <Grid item xs={2} md={2} lg={2}>
                                                        <Typography variant="h3">{t("pgi_organic_amendments_biochar")}</Typography>
                                                    </Grid>}
                                                    {i !== 0 && <Grid item xs={2} md={2} lg={2}>
                                                    </Grid>}
                                                    <Grid item xs={4} md={4} lg={4}>
                                                    </Grid>
                                                    <Grid marginBottom={2} item xs={3} md={3} lg={3}>
                                                        <FormControl fullWidth>
                                                            <NumericInput max={maxDynamic.organicAmendments * parcelArea} decimalScale={3} name="amount"
                                                                InputProps={{
                                                                    endAdornment: <InputAdornment position="end">{t("pgi_organic_amendments_biochar_tonnes")}</InputAdornment>,
                                                                }}

                                                                value={val.amount === -9999 ? '' : val.amount} size="small" fullWidth placeholder={t("pgi_organic_amendments_biochar_qty")} label={t("pgi_organic_amendments_biochar_qty")} onChange={(e) => handleAddedOrganicMatterChange(e, val.id)} />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={3} md={3} lg={3}>
                                                    </Grid>
                                                </React.Fragment >)
                                        }
                                        {
                                            currentParcel?.yearParcelData?.addedOrganicMatter?.filter((aOM) => String(aOM.type).toUpperCase() === "COMPOST")?.map((val, i) =>
                                                <React.Fragment key={i}>
                                                    {i === 0 && <Grid item xs={2} md={2} lg={2}>
                                                        <Typography variant="h3">{t("pgi_organic_amendments_compost")}
                                                            <Tooltip sx={{ pl: 1 }} title={<>{t("pgi_organic_amendments_compost_info")} <br /> </>}>
                                                                <InfoIcon sx={{
                                                                    fontSize: 'medium',
                                                                    pl: 0.5,
                                                                }} />
                                                            </Tooltip>
                                                        </Typography>
                                                    </Grid>}
                                                    {i !== 0 && <Grid item xs={2} md={2} lg={2}>

                                                    </Grid>}
                                                    <Grid item xs={4} md={4} lg={4}>
                                                        <FormControl fullWidth>
                                                            <InputLabel size='small' id="compost-label">{t("pgi_organic_amendments_compost_type_dd")}</InputLabel>
                                                            <Select
                                                                error={interdependentError(val?.subType, [val.amount])}
                                                                name="subType"
                                                                value={val?.subType}
                                                                size="small"
                                                                labelId="compost-label"
                                                                disabled={!allowEditing}
                                                                id="compost-select"
                                                                label={t("pgi_organic_amendments_compost_type_dd")}
                                                                onChange={(e) => handleAddedOrganicMatterChange(e, val.id)}
                                                            >
                                                                <MenuItem key="green/plant-based compost" value="green/plant-based compost">{t("pgi_organic_amendments_compost_type_dd_1")}</MenuItem>
                                                                <MenuItem key="manure-based compost" value="manure-based compost">{t("pgi_organic_amendments_compost_type_dd_2")}</MenuItem>
                                                                <MenuItem key="mushroom compost" value="mushroom compost">{t("pgi_organic_amendments_compost_type_dd_3")}</MenuItem>
                                                                <MenuItem key="-" value="-">-</MenuItem>
                                                            </Select>
                                                        </FormControl>

                                                    </Grid>
                                                    <Grid item xs={3} md={3} lg={3}>
                                                        <FormControl fullWidth>
                                                            <NumericInput max={maxDynamic.organicAmendments * parcelArea} decimalScale={3} name="amount"
                                                                error={interdependentError(val?.amount, [val.subType])}
                                                                InputProps={{
                                                                    endAdornment: <InputAdornment position="end">{t("pgi_organic_amendments_compost_type_tonnes")}</InputAdornment>,
                                                                }}
                                                                disabled={val.subType === "-"}
                                                                value={val.amount === -9999 ? '' : val.amount} fullWidth placeholder={t("pgi_organic_amendments_compost_type_qty")} label={t("pgi_organic_amendments_compost_type_qty")} onChange={(e) => handleAddedOrganicMatterChange(e, val.id)} />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={3} md={3} lg={3}></Grid>

                                                </React.Fragment>)
                                        }
                                        <Grid item xs={2} md={2} lg={2}>
                                        </Grid>
                                        <Grid item marginBottom={1} xs={4} md={4} lg={4}>
                                            {allowEditing && <span onClick={(e) => addNewOrganicMatter("compost")} style={{ color: "#409781", fontWeight: 'bold', cursor: "pointer", fontSize: 14 }}>+ {t("pgi_organic_amendments_compost_type_add")}</span>}
                                        </Grid>
                                        <Grid item xs={6} md={6} lg={6}></Grid>

                                        {
                                            currentParcel?.yearParcelData?.addedOrganicMatter?.filter(aOM => String(aOM.type).toUpperCase() === "MANURE")?.map((val, i) =>
                                                <React.Fragment key={i}>
                                                    {i === 0 && <Grid item xs={2} md={2} lg={2}>
                                                        <Typography variant="h3">{t("pgi_organic_amendments_manure")}
                                                            <Tooltip title={t("pgi_organic_amendments_manure_info")}>
                                                                <InfoIcon sx={{
                                                                    fontSize: 'medium',
                                                                    pl: 0.5,
                                                                }} />
                                                            </Tooltip>
                                                        </Typography>
                                                    </Grid>}
                                                    {i !== 0 && <Grid item xs={2} md={2} lg={2}>

                                                    </Grid>}
                                                    <Grid item xs={4} md={4} lg={4}>
                                                        <FormControl fullWidth>
                                                            <InputLabel size='small' id="manure-label">{t("pgi_organic_amendments_manure_type_dd")}</InputLabel>
                                                            <Select
                                                                error={interdependentError(val?.subType, [val.amount])}
                                                                name="subType"
                                                                size="small"
                                                                disabled={!allowEditing}
                                                                value={val.subType}
                                                                labelId="manure-label"
                                                                id="manure-select"
                                                                label={t("pgi_organic_amendments_manure_type_dd")}
                                                                onChange={(e) => handleAddedOrganicMatterChange(e, val.id)}
                                                            >
                                                                <MenuItem value="cattle manure">{t("pgi_organic_amendments_manure_type_dd_1")}</MenuItem>
                                                                <MenuItem value="chicken manure">{t("pgi_organic_amendments_manure_type_dd_2")}</MenuItem>
                                                                <MenuItem value="horse manure">{t("pgi_organic_amendments_manure_type_dd_3")}</MenuItem>
                                                                <MenuItem value="pig manure">{t("pgi_organic_amendments_manure_type_dd_4")}</MenuItem>
                                                                <MenuItem value="sheep manure">{t("pgi_organic_amendments_manure_type_dd_5")}</MenuItem>
                                                                <MenuItem value="-">-</MenuItem>
                                                            </Select>
                                                        </FormControl>

                                                    </Grid>
                                                    <Grid item xs={3} md={3} lg={3}>
                                                        <FormControl fullWidth>
                                                            <NumericInput max={maxDynamic.organicAmendments * parcelArea} decimalScale={3} name="amount"
                                                                error={interdependentError(val?.amount, [val.subType])}

                                                                InputProps={{
                                                                    endAdornment: <InputAdornment position="end">{t("pgi_organic_amendments_manure_type_tonnes")}</InputAdornment>,
                                                                }}
                                                                disabled={val.subType === "-"}
                                                                value={val.amount === -9999 ? '' : val.amount} size="small" fullWidth placeholder={t("pgi_organic_amendments_manure_type_qty")} label={t("pgi_organic_amendments_manure_type_qty")} onChange={(e) => handleAddedOrganicMatterChange(e, val.id)} />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={3} md={3} lg={3}></Grid>
                                                </React.Fragment>)
                                        }


                                        <Grid item xs={2} md={2} lg={2}>
                                        </Grid>
                                        <Grid item marginBottom={1} xs={3} md={3} lg={3}>
                                            {allowEditing && <div>
                                                <span onClick={(e) => addNewOrganicMatter("manure")} style={{ color: "#409781", fontWeight: 'bold', cursor: 'pointer', fontSize: 14 }}>+ {t("pgi_organic_amendments_manure_type_add")}</span>
                                            </div>}
                                        </Grid>
                                        <Grid item xs={7} md={7} lg={7}></Grid>

                                        {
                                            currentParcel?.yearParcelData?.addedOrganicMatter?.filter(aOM => String(aOM.type).toUpperCase() === "MULCH")?.map((val, i) =>
                                                <React.Fragment key={i}>
                                                    {i === 0 && <Grid item xs={2} md={2} lg={2}>
                                                        <Typography variant="h3">{t("pgi_organic_amendments_mulch")}
                                                            <Tooltip title={t("pgi_organic_amendments_mulch_info")} >
                                                                <InfoIcon sx={{
                                                                    fontSize: 'medium',
                                                                    pl: 0.5
                                                                }} />
                                                            </Tooltip>
                                                        </Typography>
                                                    </Grid>}
                                                    {i !== 0 && <Grid item xs={2} md={2} lg={2}>

                                                    </Grid>}
                                                    <Grid item xs={4} md={4} lg={4}>
                                                        <FormControl fullWidth>
                                                            <InputLabel size='small' id="mulch-label">{t("pgi_organic_amendments_mulch_type_dd")}</InputLabel>
                                                            <Select
                                                                error={interdependentError(val?.subType, [val.amount])}
                                                                name="subType"
                                                                size="small"
                                                                disabled={!allowEditing}
                                                                value={val.subType}
                                                                labelId="mulch-label"
                                                                id="mulch-select"
                                                                label={t("pgi_organic_amendments_mulch_type_dd")}
                                                                onChange={(e) => handleAddedOrganicMatterChange(e, val.id)}
                                                            >
                                                                <MenuItem value="hay/straw">{t("pgi_organic_amendments_mulch_type_dd_1")}</MenuItem>
                                                                <MenuItem value="leaves">{t("pgi_organic_amendments_mulch_type_dd_2")}</MenuItem>
                                                                <MenuItem value="wood bark/wood chips">{t("pgi_organic_amendments_mulch_type_dd_3")}</MenuItem>
                                                                <MenuItem value="-">-</MenuItem>
                                                            </Select>
                                                        </FormControl>

                                                    </Grid>
                                                    <Grid item xs={3} md={3} lg={3}>
                                                        <FormControl fullWidth>
                                                            <NumericInput decimalScale={3} max={maxDynamic.organicAmendments * parcelArea} name="amount"
                                                                error={interdependentError(val?.amount, [val.subType])}
                                                                InputProps={{
                                                                    endAdornment: <InputAdornment position="end">{t("pgi_organic_amendments_mulch_type_tonnes")}</InputAdornment>,
                                                                }}
                                                                disabled={val.subType === "-" ? true : false}
                                                                value={val.amount === -9999 ? '' : val.amount} fullWidth placeholder={t("pgi_organic_amendments_mulch_type_qty")} label={t("pgi_organic_amendments_mulch_type_qty")} onChange={(e) => handleAddedOrganicMatterChange(e, val.id)} />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={3} md={3} lg={3}></Grid>
                                                </React.Fragment>)
                                        }
                                        <Grid item xs={2} md={2} lg={2}>
                                        </Grid>
                                        <Grid item marginBottom={1} xs={4} md={4} lg={4}>
                                            {allowEditing && <span onClick={(e) => addNewOrganicMatter("mulch")} style={{ color: "#409781", fontWeight: 'bold', cursor: 'pointer', fontSize: 14 }}>+ {t("pgi_organic_amendments_mulch_type_add")}</span>}
                                        </Grid>
                                        <Grid item xs={6} md={6} lg={6}></Grid>

                                        {
                                            currentParcel?.yearParcelData?.addedOrganicMatter?.filter(aOM => String(aOM.type).toUpperCase() === "SLURRY")?.map((val, i) =>
                                                <React.Fragment key={i}>
                                                    {i === 0 && <Grid item xs={2} md={2} lg={2}>
                                                        <Typography variant="h3">{t("pgi_organic_amendments_slurry")}
                                                            <Tooltip title={t("pgi_organic_amendments_slurry_info")} >
                                                                <InfoIcon sx={{
                                                                    fontSize: 'medium',
                                                                    pl: 0.5
                                                                }} />
                                                            </Tooltip>
                                                        </Typography>
                                                    </Grid>}
                                                    {i !== 0 && <Grid item xs={2} md={2} lg={2}>

                                                    </Grid>}
                                                    <Grid item xs={4} md={4} lg={4}>
                                                        <FormControl fullWidth>
                                                            <InputLabel size='small' id="slurry-label">{t("pgi_organic_amendments_slurry_type_dd")}</InputLabel>
                                                            <Select
                                                                error={interdependentError(val?.subType, [val.amount])}
                                                                name="subType"
                                                                disabled={!allowEditing}
                                                                size="small"
                                                                value={val.subType}
                                                                labelId="slurry-label"
                                                                id="slurry-select"
                                                                label={t("pgi_organic_amendments_slurry_type_dd")}
                                                                onChange={(e) => handleAddedOrganicMatterChange(e, val.id)}
                                                            >
                                                                <MenuItem value="cattle slurry">{t("pgi_organic_amendments_slurry_type_dd_1")}</MenuItem>
                                                                <MenuItem value="pig slurry">{t("pgi_organic_amendments_slurry_type_dd_2")}</MenuItem>
                                                                <MenuItem value="-">-</MenuItem>
                                                            </Select>
                                                        </FormControl>

                                                    </Grid>
                                                    <Grid item xs={3} md={3} lg={3}>
                                                        <FormControl fullWidth>
                                                            <NumericInput
                                                                error={interdependentError(val?.amount, [val.subType])}
                                                                max={maxDynamic.organicAmendments * parcelArea}
                                                                decimalScale={3}
                                                                name="amount"
                                                                InputProps={{
                                                                    endAdornment: <InputAdornment position="end">{t("pgi_organic_amendments_slurry_type_tonnes")}</InputAdornment>,
                                                                }}
                                                                disabled={val.subType === "-"}
                                                                value={val.amount === -9999 ? '' : val.amount} fullWidth placeholder={t("pgi_organic_amendments_slurry_type_qty")} label={t("pgi_organic_amendments_slurry_type_qty")} onChange={(e) => handleAddedOrganicMatterChange(e, val.id)} />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={3} md={3} lg={3}></Grid>
                                                </React.Fragment>)
                                        }

                                        <Grid item xs={2} md={2} lg={2}>
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4}>
                                            {allowEditing && <span onClick={(e) => addNewOrganicMatter("slurry")} style={{ color: "#409781", fontWeight: 'bold', cursor: 'pointer', fontSize: 14 }}>+ {t("pgi_organic_amendments_slurry_type_add")}</span>}
                                        </Grid>
                                        <Grid item xs={6} md={6} lg={6}></Grid>

                                        {
                                            currentParcel?.yearParcelData?.addedOrganicMatter?.filter(aOM => String(aOM.type).toUpperCase() === "OTHER")?.map((val, i) =>
                                                <React.Fragment key={i}>
                                                    {i === 0 && <Grid item xs={2} md={2} lg={2}>
                                                        <Typography variant="h3">{t("pgi_organic_amendments_other")}
                                                            <Tooltip title={t("pgi_organic_amendments_other_info")} >
                                                                <InfoIcon sx={{
                                                                    fontSize: 'medium',
                                                                    pl: 0.5,
                                                                }} />
                                                            </Tooltip >
                                                        </Typography>
                                                    </Grid >}
                                                    {i !== 0 && <Grid item xs={2} md={2} lg={2}>

                                                    </Grid>}
                                                    <Grid item xs={4} md={4} lg={4}>
                                                        <FormControl fullWidth>
                                                            <TextField name="subType"
                                                                error={interdependentError(val?.subType, [val.amount])}
                                                                value={val.subType}
                                                                size="small"
                                                                fullWidth
                                                                disabled={!allowEditing}
                                                                placeholder={t("pgi_organic_amendments_soil_text")}
                                                                label={t("pgi_organic_amendments_soil_text")}
                                                                onChange={(e) => handleAddedOrganicMatterChange(e, val.id)} />
                                                        </FormControl>

                                                    </Grid>
                                                    <Grid item xs={3} md={3} lg={3}>
                                                        <FormControl fullWidth>
                                                            <NumericInput
                                                                max={maxDynamic.organicAmendments * parcelArea}
                                                                decimalScale={3}
                                                                name="amount"
                                                                error={interdependentError(val?.amount, [val.subType])}
                                                                InputProps={{
                                                                    endAdornment: <InputAdornment position="end">{t("pgi_organic_amendments_other_qty_tonnes")}</InputAdornment>,
                                                                }}
                                                                disabled={val.subType === "-"}
                                                                value={val.amount === -9999 ? '' : val.amount} fullWidth placeholder={t("pgi_organic_amendments_other_qty")} label={t("pgi_organic_amendments_other_qty")} onChange={(e) => handleAddedOrganicMatterChange(e, val.id)} />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={3} md={3} lg={3}></Grid>
                                                </React.Fragment>)
                                        }


                                        <Grid item xs={2} md={2} lg={2}>
                                        </Grid>
                                        <Grid item xs={3} md={3} lg={3}>

                                            {allowEditing && <span onClick={(e) => addNewOrganicMatter("other")} style={{ color: "#409781", fontWeight: 'bold', cursor: 'pointer', fontSize: 14 }}>+ {t("pgi_organic_amendments_other_add")}</span>}

                                        </Grid>
                                        <Grid item xs={7} md={7} lg={7}></Grid>
                                        <div style={{ marginTop: "20px", marginBottom: "20px" }}>

                                        </div>


                                    </Grid>
                                    <TrailingLine type='thick' />
                                    <div>

                                        <Typography variant="h2" marginBottom={1}>{t("pgi_organic_amendments_animal_fodder")}</Typography>
                                        <Typography variant="subtitle2" >{t("pgi_organic_amendments_af_desc")}{currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]}
                                            <Tooltip sx={{ paddingLeft: 1 }} title={<Trans i18nKey={"pgi_organic_amendments_af_info"}></Trans>}>
                                                <InfoIcon sx={{
                                                    fontSize: 'medium',
                                                    padding: '0px 0px 0px 8px',
                                                }} />
                                            </Tooltip>
                                        </Typography>

                                        <Grid container marginTop={3} spacing={2}>
                                            <Grid item xs={2} md={2} lg={2}>
                                                <Typography variant="h3">{t("pgi_organic_amendments_af_2")}</Typography>
                                            </Grid>
                                            <Grid item xs={4} md={4} lg={4}>
                                                <FormControl fullWidth>
                                                    <NumericInput

                                                        max={maxDynamic.animalFodderT * parcelArea}
                                                        decimalScale={3}
                                                        value={currentParcel?.yearParcelData?.animalFodder?.amount !== -9999 ? currentParcel?.yearParcelData?.animalFodder?.amount : ''}
                                                        onChange={(e) => handleAnimalFodderChange(e)}
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="end">{t("pgi_organic_amendments_af_tonnes")}</InputAdornment>,
                                                        }}
                                                        fullWidth placeholder={t("pgi_organic_amendments_af_qty")} label={t("pgi_organic_amendments_af_qty")} />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={2} md={2} lg={2}>
                                            </Grid>
                                        </Grid>
                                    </div >

                                    <TrailingLine type='thick' />
                                    <div>
                                        <Typography variant="h2" marginBottom={1}>
                                            {t("pgi_organic_amendments_grazing")}
                                        </Typography>

                                        <Typography variant="subtitle2">
                                            {t("pgi_organic_amendments_grazing_desc_1")} {currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]} {t("pgi_organic_amendments_grazing_desc_2")}
                                        </Typography>
                                        <Grid container style={{ marginTop: "20px", marginBottom: "20px" }} spacing={2}>
                                            <Grid item xs={2} md={2} lg={2}>
                                                <Typography variant="h3">{t("pgi_organic_amendments_grazing_months")}</Typography>
                                            </Grid>
                                            <Grid item xs={5} md={5} lg={5}>
                                                <FormControl fullWidth >
                                                    <InputLabel size='small' id="grazing-months-label">{t("pgi_organic_amendments_grazing_months_dd")}</InputLabel>
                                                    <Select
                                                        labelId="grazing-months-label"
                                                        size="small"
                                                        id="grazing-months-select"
                                                        disabled={!allowEditing}
                                                        multiple
                                                        value={grazingMonths}
                                                        onChange={handleGrazingMonthsChange}

                                                        label={t("pgi_organic_amendments_grazing_months_dd")}
                                                    >
                                                        <MenuItem key="january" value="january">{t("pgi_organic_amendments_grazing_months_1")}</MenuItem>
                                                        <MenuItem key="february" value="february">{t("pgi_organic_amendments_grazing_months_2")}</MenuItem>
                                                        <MenuItem key="march" value="march">{t("pgi_organic_amendments_grazing_months_3")}</MenuItem>
                                                        <MenuItem key="april" value="april">{t("pgi_organic_amendments_grazing_months_4")}</MenuItem>
                                                        <MenuItem key="may" value="may">{t("pgi_organic_amendments_grazing_months_5")}</MenuItem>
                                                        <MenuItem key="june" value="june">{t("pgi_organic_amendments_grazing_months_6")}</MenuItem>
                                                        <MenuItem key="july" value="july">{t("pgi_organic_amendments_grazing_months_7")}</MenuItem>
                                                        <MenuItem key="august" value="august">{t("pgi_organic_amendments_grazing_months_8")}</MenuItem>
                                                        <MenuItem key="september" value="september">{t("pgi_organic_amendments_grazing_months_9")}</MenuItem>
                                                        <MenuItem key="october" value="october">{t("pgi_organic_amendments_grazing_months_10")}</MenuItem>
                                                        <MenuItem key="november" value="november">{t("pgi_organic_amendments_grazing_months_11")}</MenuItem>
                                                        <MenuItem key="december" value="december">{t("pgi_organic_amendments_grazing_months_12")}</MenuItem>
                                                        <MenuItem key="-" value="-">-</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={5} md={5} lg={5}>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <TrailingLine type='thick' />

                                    <Typography variant="h2" marginBottom={1}>{t("pgi_organic_amendments_tillage")}</Typography>


                                    <Typography variant="subtitle2">{t("pgi_organic_amendments_tillage_desc_1")}{currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]}  {t("pgi_organic_amendments_tillage_desc_2")}
                                        <Tooltip title={<Trans i18nKey={"pgi_organic_amendments_tillage_info"}></Trans>} >
                                            <InfoIcon sx={{
                                                fontSize: 'medium',
                                                pl: 0.5
                                            }} />
                                        </Tooltip>
                                    </Typography>


                                    <Grid container marginTop={3} spacing={2}>
                                        {currentParcel?.yearParcelData?.tillage?.map((val, i) =>
                                            <React.Fragment key={i}>
                                                {i === 0 && <Grid item xs={2} md={2} lg={2}>
                                                    <Typography variant="h3">{t("pgi_organic_amendments_tillage_events")}</Typography>
                                                </Grid>}
                                                {i !== 0 && <Grid item xs={2} md={2} lg={2}>
                                                </Grid>}
                                                <Grid item xs={3} md={3} lg={3}>
                                                    <FormControl sx={{ fieldset: { legend: { maxWidth: val.date && '100%' } } }} fullWidth>
                                                        <InputLabel shrink={val.date} size='small' id="tillage-schedule-month-label">{t("pgi_organic_amendments_tillage_months")}</InputLabel>
                                                        <Select
                                                            error={interdependentError(val.date, [val.percent, val.type])}
                                                            name="date"
                                                            size="small"
                                                            disabled={!allowEditing}
                                                            value={val.date}
                                                            labelId="tillage-schedule-month-label"
                                                            id="tillage-schedule-month-select"
                                                            label={t("pgi_organic_amendments_tillage_months")}
                                                            onChange={(e) => handleTillageScheduleChange(e, i)}
                                                        >
                                                            <MenuItem
                                                                value={"".concat(tabValue).concat("-01-01")}
                                                                key={"".concat(tabValue).concat("-01-01")}>
                                                                {t("pgi_organic_amendments_tillage_months_1")}{" "}{tabValue}
                                                            </MenuItem>

                                                            <MenuItem
                                                                value={"".concat(tabValue).concat("-02-01")}
                                                                key={"".concat(tabValue).concat("-02-01")}>
                                                                {t("pgi_organic_amendments_tillage_months_2")}{" "}{tabValue}
                                                            </MenuItem>

                                                            <MenuItem
                                                                value={"".concat(tabValue).concat("-03-01")}
                                                                key={"".concat(tabValue).concat("-03-01")}>
                                                                {t("pgi_organic_amendments_tillage_months_3")}{" "}{tabValue}
                                                            </MenuItem>

                                                            <MenuItem
                                                                value={"".concat(tabValue).concat("-04-01")}
                                                                key={"".concat(tabValue).concat("-04-01")}>
                                                                {t("pgi_organic_amendments_tillage_months_4")}{" "}{tabValue}
                                                            </MenuItem>

                                                            <MenuItem
                                                                value={"".concat(tabValue).concat("-05-01")}
                                                                key={"".concat(tabValue).concat("-05-01")}>
                                                                {t("pgi_organic_amendments_tillage_months_5")}{" "}{tabValue}
                                                            </MenuItem>

                                                            <MenuItem
                                                                value={"".concat(tabValue).concat("-06-01")}
                                                                key={"".concat(tabValue).concat("-06-01")}>
                                                                {t("pgi_organic_amendments_tillage_months_6")}{" "}{tabValue}
                                                            </MenuItem>

                                                            <MenuItem
                                                                value={"".concat(tabValue).concat("-07-01")}
                                                                key={"".concat(tabValue).concat("-07-01")}>
                                                                {t("pgi_organic_amendments_tillage_months_7")}{" "}{tabValue}
                                                            </MenuItem>

                                                            <MenuItem
                                                                value={"".concat(tabValue).concat("-08-01")}
                                                                key={"".concat(tabValue).concat("-08-01")}>
                                                                {t("pgi_organic_amendments_tillage_months_8")}{" "}{tabValue}
                                                            </MenuItem>

                                                            <MenuItem
                                                                value={"".concat(tabValue).concat("-09-01")}
                                                                key={"".concat(tabValue).concat("-09-01")}>
                                                                {t("pgi_organic_amendments_tillage_months_9")}{" "}{tabValue}
                                                            </MenuItem>

                                                            <MenuItem
                                                                value={"".concat(tabValue).concat("-10-01")}
                                                                key={"".concat(tabValue).concat("-10-01")}>
                                                                {t("pgi_organic_amendments_tillage_months_10")}{" "}{tabValue}
                                                            </MenuItem>

                                                            <MenuItem
                                                                value={"".concat(tabValue).concat("-11-01")}
                                                                key={"".concat(tabValue).concat("-11-01")}>
                                                                {t("pgi_organic_amendments_tillage_months_11")}{" "}{tabValue}
                                                            </MenuItem>

                                                            <MenuItem
                                                                value={"".concat(tabValue).concat("-12-01")}
                                                                key={"".concat(tabValue).concat("-12-01")}>
                                                                {t("pgi_organic_amendments_tillage_months_12")}{" "}{tabValue}
                                                            </MenuItem>
                                                            <MenuItem
                                                                value="-"
                                                                key="-">
                                                                -
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>

                                                </Grid>
                                                <Grid item xs={4} md={4} lg={4}>
                                                    <FormControl sx={{ fieldset: { legend: { maxWidth: val.type && '100%' } } }} fullWidth>
                                                        <InputLabel shrink={val.type} size='small' id="tillage-schedule-type-label">{t("pgi_organic_amendments_tillage_type_dd")}</InputLabel>
                                                        <Select
                                                            error={interdependentError(val.type, [val.percent, val.date])}
                                                            name="type"
                                                            size="small"
                                                            value={val.type}
                                                            labelId="tillage-schedule-type-label"
                                                            id="tillage-schedule-type-select"
                                                            label={t("pgi_organic_amendments_tillage_type_dd")}
                                                            onChange={(e) => handleTillageScheduleChange(e, i)}
                                                            disabled={val.date === "-" || !allowEditing}
                                                        >
                                                            <MenuItem key="minimum tillage (<10 cm)" value="minimum tillage (<10 cm)">{t("pgi_organic_amendments_tillage_type_dd_1")}</MenuItem>
                                                            <MenuItem key="tillage (>10 cm)" value="tillage (>10 cm)">{t("pgi_organic_amendments_tillage_type_dd_2")}</MenuItem>

                                                        </Select>
                                                    </FormControl>

                                                </Grid>
                                                <Grid item xs={3} md={3} lg={3}>
                                                    <FormControl fullWidth>
                                                        <NumericInput
                                                            max={max.percent}
                                                            error={interdependentError(val.percent, [val.date, val.type])}
                                                            value={val.percent === -9999 ? '' : val.percent}
                                                            name="percent"
                                                            disabled={val.date === "-" ? true : false}
                                                            onChange={(e) => handleTillageScheduleChange(e, i)}
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                            }}
                                                            fullWidth placeholder={t("pgi_organic_amendments_tillage_percentage")} label={t("pgi_organic_amendments_tillage_percentage")} />
                                                    </FormControl>
                                                </Grid>
                                            </React.Fragment >)
                                        }


                                        <Grid item xs={2} md={2} lg={2}>
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4}>
                                            <div>
                                                {allowEditing && <span onClick={addNewTillageSchedule} style={{ color: "#409781", fontWeight: 'bold', cursor: 'pointer', fontSize: 14 }}>+ {t("pgi_organic_amendments_tillage_add")}</span>}
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} md={6} lg={6}>
                                        </Grid>
                                    </Grid>
                                    <TrailingLine type='thick' />
                                    <div>

                                        <Typography variant="h2" marginBottom={1}>{t("pgi_organic_amendments_irrigation")}</Typography>


                                        <Typography variant="subtitle2">{t("pgi_organic_amendments_irrigation_desc")} {currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]} </Typography>

                                        <Grid container marginTop={3} spacing={2}>
                                            <Grid item xs={2} md={2} lg={2}>
                                                <Typography variant="h3">{t("pgi_organic_amendments_irrigation_label")}</Typography>
                                            </Grid>
                                            <Grid item xs={3} md={3} lg={3}>
                                                <FormControl sx={{ fieldset: { legend: { maxWidth: irrigation && '100%' } } }} fullWidth >
                                                    <InputLabel shrink={currentParcel?.yearParcelData?.irrigation} size='small' id="irrigation-label">{t("pgi_organic_amendments_irrigation_label")}</InputLabel>
                                                    <Select
                                                        label={t("pgi_organic_amendments_irrigation_label")}
                                                        labelId="irrigation-label"
                                                        id="irrigation-select"
                                                        size="small"
                                                        disabled={!allowEditing}
                                                        error={!currentParcel?.yearParcelData?.irrigation}
                                                        value={currentParcel?.yearParcelData?.irrigation}
                                                        onChange={(e) => handleIrrigationChange(e)}
                                                    >
                                                        <MenuItem key={"true"} value={"true"}>{t("pgi_organic_amendments_irrigation_dd_1")}</MenuItem>
                                                        <MenuItem key={"false"} value={"false"}>{t("pgi_organic_amendments_irrigation_dd_2")}</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={7} md={7} lg={7}>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <TrailingLine type='thick' />
                                    <Typography variant="h2" marginBottom={1}>{t("pgi_organic_amendments_felled_trees")}</Typography>
                                    <Typography variant='subtitle2'>
                                        {t("pgi_organic_amendments_felled_trees_desc_1")} {currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]}  {t("pgi_organic_amendments_felled_trees_desc_2")}
                                        <Tooltip title={<Trans i18nKey={"pgi_organic_amendments_felled_trees_info"}></Trans>} >
                                            <InfoIcon sx={{
                                                fontSize: 'medium',
                                                pl: 0.5
                                            }} />
                                        </Tooltip>
                                    </Typography>
                                    <Grid container spacing={2} marginTop={3} >
                                        {
                                            currentParcel?.yearParcelData?.treeFelling?.map((val, i) =>
                                                <React.Fragment key={i}>
                                                    {i === 0 && <Grid item xs={2} md={2} lg={2}>
                                                        <Typography variant="h3">{t("pgi_organic_amendments_felled_trees_label")}</Typography>
                                                    </Grid>}
                                                    {i !== 0 && <Grid item xs={2} md={2} lg={2}>

                                                    </Grid>}
                                                    <Grid item xs={3} md={3} lg={3}>
                                                        <FormControl sx={{ fieldset: { legend: { maxWidth: val.species && '100%' } } }} fullWidth>
                                                            <InputLabel shrink={val.species} size='small' id="tree-type-label">{t("pgi_organic_amendments_felled_trees_dd")}</InputLabel>
                                                            <Select
                                                                error={interdependentError(val.species, [val?.DBHmean?.diameter, val?.treeNumber])}
                                                                name="species"
                                                                size="small"
                                                                value={val.species}
                                                                labelId="tree-type-label"
                                                                id="tree-type-select"
                                                                label={t("pgi_organic_amendments_felled_trees_dd")}
                                                                disabled={!allowEditing}
                                                                onChange={(e) => handleFelledTreesChange(e, i)}
                                                            >
                                                                <MenuItem key="ash" value="ash">{t("pgi_organic_amendments_felled_trees_dd_1")}</MenuItem>
                                                                <MenuItem key="eucalyptus" value="eucalyptus">{t("pgi_organic_amendments_felled_trees_dd_2")}</MenuItem>
                                                                <MenuItem key="carob" value="carob">{t("pgi_organic_amendments_felled_trees_dd_3")}</MenuItem>
                                                                <MenuItem key="cork oak" value="cork oak">{t("pgi_organic_amendments_felled_trees_dd_4")}</MenuItem>
                                                                <MenuItem key="cypress" value="cypress">{t("pgi_organic_amendments_felled_trees_dd_5")}</MenuItem>
                                                                <MenuItem key="holm oak" value="holm oak">{t("pgi_organic_amendments_felled_trees_dd_6")}</MenuItem>
                                                                <MenuItem key="maple/sycamore" value="maple/sycamore">{t("pgi_organic_amendments_felled_trees_dd_7")}</MenuItem>
                                                                <MenuItem key="pine" value="pine">{t("pgi_organic_amendments_felled_trees_dd_8")}</MenuItem>
                                                                <MenuItem key="fruit tree - apple" value="fruit tree - apple">{t("pgi_organic_amendments_felled_trees_dd_9")}</MenuItem>
                                                                <MenuItem key="fruit tree - apricot" value="fruit tree - apricot">{t("pgi_organic_amendments_felled_trees_dd_10")}</MenuItem>
                                                                <MenuItem key="fruit tree - avocado" value="fruit tree - avocado">{t("pgi_organic_amendments_felled_trees_dd_11")}</MenuItem>
                                                                <MenuItem key="fruit tree - clementine" value="fruit tree - clementine">{t("pgi_organic_amendments_felled_trees_dd_12")}</MenuItem>
                                                                <MenuItem key="fruit tree - date palm" value="fruit tree - date palm">{t("pgi_organic_amendments_felled_trees_dd_13")}</MenuItem>
                                                                <MenuItem key="fruit tree - fig" value="fruit tree - fig">{t("pgi_organic_amendments_felled_trees_dd_14")}</MenuItem>
                                                                <MenuItem key="fruit tree - lemon" value="fruit tree - lemon">{t("pgi_organic_amendments_felled_trees_dd_15")}</MenuItem>
                                                                <MenuItem key="fruit tree - mandarin" value="fruit tree - mandarin">{t("pgi_organic_amendments_felled_trees_dd_16")}</MenuItem>
                                                                <MenuItem key="fruit tree - mango" value="fruit tree - mango">{t("pgi_organic_amendments_felled_trees_dd_17")}</MenuItem>
                                                                <MenuItem key="fruit tree - olive" value="fruit tree - olive">{t("pgi_organic_amendments_felled_trees_dd_18")}</MenuItem>
                                                                <MenuItem key="fruit tree - orange" value="fruit tree - orange">{t("pgi_organic_amendments_felled_trees_dd_19")}</MenuItem>
                                                                <MenuItem key="fruit tree - peach" value="fruit tree - peach">{t("pgi_organic_amendments_felled_trees_dd_20")}</MenuItem>
                                                                <MenuItem key="fruit tree - pear" value="fruit tree - pear">{t("pgi_organic_amendments_felled_trees_dd_21")}</MenuItem>
                                                                <MenuItem key="fruit tree - pomegranate" value="fruit tree - pomegranate">{t("pgi_organic_amendments_felled_trees_dd_22")}</MenuItem>
                                                                <MenuItem key="fruit tree - pomelo" value="fruit tree - pomelo">{t("pgi_organic_amendments_felled_trees_dd_23")}</MenuItem>
                                                                <MenuItem key="fruit tree - other" value="fruit tree - other">{t("pgi_organic_amendments_felled_trees_dd_24")}</MenuItem>
                                                                <MenuItem key="nut tree - almond" value="nut tree - almond">{t("pgi_organic_amendments_felled_trees_dd_25")}</MenuItem>
                                                                <MenuItem key="nut tree - chestnut" value="nut tree - chestnut">{t("pgi_organic_amendments_felled_trees_dd_26")}</MenuItem>
                                                                <MenuItem key="nut tree - hazelnut" value="nut tree - hazelnut">{t("pgi_organic_amendments_felled_trees_dd_27")}</MenuItem>
                                                                <MenuItem key="nut tree - pistachio" value="nut tree - pistachio">{t("pgi_organic_amendments_felled_trees_dd_28")}</MenuItem>
                                                                <MenuItem key="nut tree - walnut" value="nut tree - walnut">{t("pgi_organic_amendments_felled_trees_dd_29")}</MenuItem>
                                                                <MenuItem key="nut tree - other" value="nut tree - other">{t("pgi_organic_amendments_felled_trees_dd_30")}</MenuItem>
                                                                <MenuItem key="other tree" value="other tree">{t("pgi_organic_amendments_felled_trees_dd_31")}</MenuItem>
                                                                <MenuItem value="-">-</MenuItem>
                                                            </Select>
                                                        </FormControl>

                                                    </Grid>


                                                    <Grid item xs={3} md={3} lg={3}>
                                                        <FormControl fullWidth>
                                                            <TextField name="other" size="small"
                                                                error={String(val.species).toUpperCase().includes("OTHER") && (!val.other || val.other === '-')}
                                                                disabled={!String(val.species).toUpperCase().includes("OTHER") || String(val.species) === "-" || !allowEditing}
                                                                value={val.other !== null ? val.other : ""}
                                                                fullWidth
                                                                placeholder={t("pgi_organic_amendments_felled_trees_specific_name")}
                                                                label={t("pgi_organic_amendments_felled_trees_specific_name")}
                                                                onChange={(e) => handleFelledTreesChange(e, i)} />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={2} md={2} lg={2}>
                                                        <FormControl fullWidth>
                                                            <NumericInput
                                                                max={max.treeFelling}
                                                                name="treeNumber"
                                                                error={interdependentError(val.treeNumber, [val?.DBHmean?.diameter, val?.species])}
                                                                disabled={String(val.species) === "-"}
                                                                value={val.treeNumber === -9999 ? '' : val.treeNumber}
                                                                fullWidth placeholder={t("pgi_organic_amendments_felled_trees_number")}
                                                                label={t("pgi_organic_amendments_felled_trees_number")}
                                                                onChange={(e) => handleFelledTreesChange(e, i)} />
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item xs={2} md={2} lg={2}>
                                                        <FormControl fullWidth>
                                                            <NumericInput max={max.DBH} name="DBHmean"
                                                                error={interdependentError(val.DBHmean?.diameter, [val?.treeNumber, val?.species])}
                                                                decimalScale={3}
                                                                InputProps={{
                                                                    endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                                                                }}
                                                                value={val?.DBHmean?.diameter === -9999 ? '' : val?.DBHmean?.diameter}
                                                                disabled={String(val.species) === "-"}
                                                                fullWidth
                                                                placeholder={t("pci_perennial_tree_list_dbh_field")}
                                                                label={t("pci_perennial_tree_list_dbh_field")}
                                                                onChange={(e) => handleFelledTreesChange(e, i)} />
                                                        </FormControl>
                                                    </Grid>
                                                </React.Fragment>)
                                        }

                                        <Grid item xs={4} md={4} lg={4}>
                                            {allowEditing && <span onClick={addNewFelledTrees} style={{ color: "#409781", fontWeight: 'bold', cursor: 'pointer', fontSize: 14 }}>+ {t("pgi_organic_amendments_felled_trees_add_another")}</span>}
                                        </Grid>
                                    </Grid>
                                </div >
                            </TabPanel >)
                        })
                    }
                    <ConfirmDialog onClose={() => setDialogOpen(false)} open={dialogOpen} textQuestion={t("generic_complete_fields")} textYes={t("generic_complete_no")} textNo={t("generic_complete_yes")} textTitle={t("generic_complete_title")} handleDelete={handleDeleteUnFilled} />

                </SwipeableViews >

                <Grid marginTop={4} marginBottom={4} container spacing={2}>
                    <Grid item xs={9} sm={9} md={9} lg={9}>

                    </Grid>
                    <Grid item xs={3} sm={3} md={3} lg={3}>
                        <SubmitButton style={{ width: '280px' }} onClick={handleValidate} >{t("pgi_organic_amendments_save")}</SubmitButton>
                    </Grid>
                </Grid>
                <CopyFromOtherYear page='landuse' parcelid={parcelid} year={year} openCopyDataParcelsDialog={openCopyDataParcelsDialog} setOpenCopyDataParcelsDialog={setOpenCopyDataParcelsDialog} setShowLoader={setShowLoader} setSaveCurrentDataFlag={setSaveCurrentDataFlag} setSnackbarMessage={setSnackbarMessage} setState={setState} />
            </Box >
        </>
    )
}

export default FarmerLanduseComponent