import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";


import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import InfoIcon from "@mui/icons-material/Info";
import {
  Backdrop,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import "../../css/FormFields.css";
import { BaselineYears, GoBackButton, ProjectYears, SubmitButton } from "../../styles";

import { partialUpdateYearlyFarmData } from "../../actions/partialUpdates";
import {
  updateFarmVerificationDocuments
} from "../../actions/verificationDocuments";


import ConfirmDialog from "../general/ConfirmDialog";
import { interdependencyValidation } from "../general/Validations/validateValues";
import YearlyInvoices from './YearlyInvoices';
import YearlyServices from './YearlyServices';

const Step2EnergyUsageComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentFarmDataObject = useSelector((state) => state.currentFarmDataReducer);
  const currentFarmVerificationDocuments = useSelector((state) => state.farmVerificationDocumentsReducer);
  const currentMonitoringDataObject = useSelector((state) => state.currentMonitoringDataReducer);
  const currentMonitoringYearsObject = useSelector(state => state.monitoringYearsTrackerReducer)
  const accountSettings = useSelector((state) => state.accountSettingsReducer);

  const isAdmin = accountSettings?.[0]?.role === 'admin'
  const allYears = currentMonitoringYearsObject.allMonitoringYears

  const temp = JSON.parse(JSON.stringify(currentMonitoringDataObject));
  const temp2018 = temp.yearlyFarmData?.[0]?.fuelUsage.indirect
  const [year2018Services, setYear2018Services] = useState(temp2018);

  const temp2019 = temp.yearlyFarmData?.[1]?.fuelUsage.indirect
  const [year2019Services, setYear2019Services] = useState(temp2019);

  const temp2020 = temp.yearlyFarmData?.[2]?.fuelUsage.indirect
  const [year2020Services, setYear2020Services] = useState(temp2020);

  const temp2021 = temp.yearlyFarmData?.[3]?.fuelUsage.indirect
  const [year2021Services, setYear2021Services] = useState(temp2021);

  const temp2022 = temp.yearlyFarmData?.[4]?.fuelUsage.indirect
  const [year2022Services, setYear2022Services] = useState(temp2022);

  const temp2023 = temp.yearlyFarmData?.[5]?.fuelUsage.indirect
  const [year2023Services, setYear2023Services] = useState(temp2023);

  const [currentDocumentComment, setCurrentDocumentComment] = useState("");
  const [currentDocumentYear, setCurrentDocumentYear] = useState(""); // year index
  const [currentDocumentFolder, setCurrentDocumentFolder] = useState("");
  const [currentDocumentIndex, setCurrentDocumentIndex] = useState("");

  const [confirmPopUpOpen, setConfirmPopUpOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;
  const [showLoader, setShowLoader] = useState(false);
  const [saveCurrentDataFlag, setSaveCurrentDataFlag] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isFilled, setIsFilled] = useState([]);

  const handleBackdropClose = () => {
    setSaveCurrentDataFlag(false);
  };

  const handleBackdropClickCaptureEvent = (e) => {
    e.stopPropagation();
    return false;
  };

  const handleSnackbarClose = () => {
    setState({ vertical: "top", horizontal: "center", open: false });
  };

  const handleConfirmPopUpOpen = () => {
    setConfirmPopUpOpen(true);
  };

  const handleConfirmPopUpClose = (event, reason) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    setConfirmPopUpOpen(false);
  };

  const yearServicesArray = [year2018Services, year2019Services, year2020Services, year2021Services, year2022Services, year2022Services]

  const handleValidate = (path) => {

    let isAllFilledForService = [[], [], [], [], [], []]
    yearServicesArray.forEach((yearService, index) => {
      const yearServicesLength = yearService.length
      for (let i = 0; i < yearServicesLength; i++) {
        //get all data belonging to all services of one year
        // check for each service if they are valid (all filled or all empty) and push it to an array
        isAllFilledForService[index].push(interdependencyValidation([{ area: yearService[i]?.area.amount, service: yearService[i].service, serviceDetail: yearService[i].serviceDetail }]))
      }
    })
    //get the index of the service that are not filled
    let falseIndices = isAllFilledForService.map(isFilledArray => { return isFilledArray.map((value, index) => value === false ? index : '').filter(index => index !== '') })

    setIsFilled(falseIndices)
    if (isAllFilledForService.flat().includes(false)) {
      setDialogOpen(true)
    } else if (path) {
      handleFuelUsageUpdate()
      navigate(path)
    } else {
      handleFuelUsageUpdate()
    }
  }


  const handleDeleteUnFilled = () => {
    isFilled.forEach((year, yearIndex) => {
      year.reverse().forEach(value => {
        const updatedYearServices = yearServicesArray[yearIndex];
        updatedYearServices.splice(value, 1);

        switch (yearIndex) {
          case 0:
            setYear2018Services(updatedYearServices);
            break;
          case 1:
            setYear2019Services(updatedYearServices)
            break;
          case 2:
            setYear2020Services(updatedYearServices);
            break;
          case 3:
            setYear2021Services(updatedYearServices);
            break;
          case 4:
            setYear2022Services(updatedYearServices);
            break;
          case 5:
            setYear2023Services(updatedYearServices);
            break;

          default:
            break;
        }
      }
      );
    });
    handleFuelUsageUpdate()
    setDialogOpen(false)
  }

  const handleFuelUsageUpdate = async () => {
    setShowLoader(true);
    setSaveCurrentDataFlag(true);

    let tempObj = JSON.parse(JSON.stringify(currentMonitoringDataObject.yearlyFarmData));
    tempObj[0].fuelUsage.indirect = year2018Services
    tempObj[1].fuelUsage.indirect = year2019Services
    tempObj[2].fuelUsage.indirect = year2020Services
    tempObj[3].fuelUsage.indirect = year2021Services
    tempObj[4].fuelUsage.indirect = year2022Services
    tempObj[5].fuelUsage.indirect = year2023Services

    dispatch(
      partialUpdateYearlyFarmData(currentMonitoringDataObject._id, tempObj)
    )
      .then((res) => {
        setSnackbarMessage(t("farmland_draft_submission_msg_success"));
        setState({ open: true, vertical: "top", horizontal: "center" });
        setShowLoader(false);
        setSaveCurrentDataFlag(false);
      })
      .catch((e) => {
        setSnackbarMessage(t("farmland_draft_submission_msg_failure"));
        setState({ open: true, vertical: "top", horizontal: "center" });
        setSaveCurrentDataFlag(false);
        setShowLoader(false);
      });
  };

  const saveVerificationDocumentComment = () => {
    let tempObj = currentFarmVerificationDocuments;

    tempObj.yearlyVerificationDocuments[currentDocumentYear][
      currentDocumentFolder
    ][currentDocumentIndex].comment = currentDocumentComment;

    dispatch(updateFarmVerificationDocuments(currentFarmVerificationDocuments._id, tempObj))
      .then((res) => {
        setCurrentDocumentComment("");
        setCurrentDocumentYear("");
        setCurrentDocumentFolder("");
        setCurrentDocumentIndex("");
        console.log('Farm verification documents updated successfully:', res);
      })
      .catch((error) => {
        console.error('Error updating farm verification documents:', error);
      });

    setConfirmPopUpOpen(false);
  };

  // when scrolling "external services with invoices" or "external services without invoices" both elements scroll simultaneously
  const s1 = document.getElementById('ScrollWrapper1');
  const s2 = document.getElementById('ScrollWrapper2');
  const s3 = document.getElementById('ScrollWrapper3');

  const select_scroll_1 = () => {
    s2.scrollLeft = s1.scrollLeft;
    s3.scrollLeft = s1.scrollLeft;
  }
  const select_scroll_2 = () => {
    s1.scrollLeft = s2.scrollLeft;
    s3.scrollLeft = s2.scrollLeft;
  }
  const select_scroll_3 = () => {
    s1.scrollLeft = s3.scrollLeft;
    s2.scrollLeft = s3.scrollLeft;
  }

  if (s1) {
    s1.addEventListener("scroll", select_scroll_1, false);
  }
  if (s2) {
    s2.addEventListener("scroll", select_scroll_2, false);
  }
  if (s3) {
    s3.addEventListener('scroll', select_scroll_3, false);
  }

  const projectYearsWidth = {
    4: '250px',
    5: '520px',
    6: '787px',
  };

  return (
    <>
      {showLoader && (
        <Backdrop
          onClickCapture={(e) => handleBackdropClickCaptureEvent(e)}
          style={{ color: "#fff", zIndex: 99999 }}
          open={saveCurrentDataFlag}
          onClick={handleBackdropClose}
        >
          <div style={{ fontSize: 20, fontWeight: 600, color: "white", marginTop: "20px" }}>
            {t("generic_saving_data")}
          </div>
        </Backdrop>
      )}

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        key={vertical + horizontal}
      />
      {!isAdmin && (
        <Box marginLeft={6} marginTop='80px' display='flex' alignItems='center'>
          <GoBackButton onClick={() => handleValidate("/carbonplus")}>
            <ArrowBackIosRoundedIcon />
            <Typography sx={{ fontWeight: 800, paddingLeft: 2 }}>{t("back_to_home")}</Typography>
          </GoBackButton>
        </Box>
      )}


      <Box margin={12} marginTop={2}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <FormLabel>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 30,
                  marginBottom: 30,
                }}
              >
                <Typography variant="h1" color="#000000">
                  {t("fu_indir_title")}
                </Typography>
                <Typography color="#000000" variant="subtitle1">
                  {t("fu_indir_description_1")} {t("fu_indir_description_2")}{" "}
                  <b>{t("fu_indir_description_4")}</b>
                </Typography>
              </Grid>
            </FormLabel>
          </FormControl>
        </Grid>

        <Box marginTop={2} sx={{ display: "flex", flexDirection: "row" }}>
          <Stack sx={{ minWidth: 220 }}></Stack>
          <div
            id="ScrollWrapper3"
            style={{ display: "flex", flexDirection: "row", overflowX: "scroll" }}
          >
            <Stack marginLeft={2}>
              <BaselineYears sx={{ width: "782px" }}>{t("baseline_years")}</BaselineYears>
            </Stack>
            <Stack>
              <ProjectYears
                sx={{
                  marginLeft: 2,
                  bgcolor: "#808080",
                  minWidth: projectYearsWidth[allYears.length],
                }}
              >
                {t("project_years")}
              </ProjectYears>
            </Stack>
          </div>
        </Box>

        {/* External Services with invoices */}
        <Box marginTop={2} sx={{ display: "flex", flexDirection: "row" }}>
          <Stack sx={{ minWidth: 220 }}>
            <Typography variant="h3" marginTop={6}>
              {t("fu_ind_section_subtitle_1")}
            </Typography>
          </Stack>

          <div
            id="ScrollWrapper1"
            style={{ display: "flex", flexDirection: "row", overflowX: "scroll" }}
          >
            {/* 2018 */}
            <YearlyInvoices
              setState={setState}
              setShowLoader={setShowLoader}
              setSaveCurrentDataFlag={setSaveCurrentDataFlag}
              setSnackbarMessage={setSnackbarMessage}
              setCurrentDocumentComment={setCurrentDocumentComment}
              setCurrentDocumentYear={setCurrentDocumentYear}
              setCurrentDocumentFolder={setCurrentDocumentFolder}
              setCurrentDocumentIndex={setCurrentDocumentIndex}
              handleConfirmPopUpOpen={handleConfirmPopUpOpen}
              documentYear={0}
            />

            {/* 2019 */}
            <YearlyInvoices
              setState={setState}
              setShowLoader={setShowLoader}
              setSaveCurrentDataFlag={setSaveCurrentDataFlag}
              setSnackbarMessage={setSnackbarMessage}
              setCurrentDocumentComment={setCurrentDocumentComment}
              setCurrentDocumentYear={setCurrentDocumentYear}
              setCurrentDocumentFolder={setCurrentDocumentFolder}
              setCurrentDocumentIndex={setCurrentDocumentIndex}
              handleConfirmPopUpOpen={handleConfirmPopUpOpen}
              documentYear={1}
            />

            {/* 2020 */}
            <YearlyInvoices
              setState={setState}
              setShowLoader={setShowLoader}
              setSaveCurrentDataFlag={setSaveCurrentDataFlag}
              setSnackbarMessage={setSnackbarMessage}
              setCurrentDocumentComment={setCurrentDocumentComment}
              setCurrentDocumentYear={setCurrentDocumentYear}
              setCurrentDocumentFolder={setCurrentDocumentFolder}
              setCurrentDocumentIndex={setCurrentDocumentIndex}
              handleConfirmPopUpOpen={handleConfirmPopUpOpen}
              documentYear={2}
            />

            {/* 2021 */}
            <YearlyInvoices
              setState={setState}
              setShowLoader={setShowLoader}
              setSaveCurrentDataFlag={setSaveCurrentDataFlag}
              setSnackbarMessage={setSnackbarMessage}
              setCurrentDocumentComment={setCurrentDocumentComment}
              setCurrentDocumentYear={setCurrentDocumentYear}
              setCurrentDocumentFolder={setCurrentDocumentFolder}
              setCurrentDocumentIndex={setCurrentDocumentIndex}
              handleConfirmPopUpOpen={handleConfirmPopUpOpen}
              documentYear={3}
            />

            {/* 2022 */}
            <YearlyInvoices
              setState={setState}
              setShowLoader={setShowLoader}
              setSaveCurrentDataFlag={setSaveCurrentDataFlag}
              setSnackbarMessage={setSnackbarMessage}
              setCurrentDocumentComment={setCurrentDocumentComment}
              setCurrentDocumentYear={setCurrentDocumentYear}
              setCurrentDocumentFolder={setCurrentDocumentFolder}
              setCurrentDocumentIndex={setCurrentDocumentIndex}
              handleConfirmPopUpOpen={handleConfirmPopUpOpen}
              documentYear={4}
            />

            {/* 2023 */}
            <YearlyInvoices
              setState={setState}
              setShowLoader={setShowLoader}
              setSaveCurrentDataFlag={setSaveCurrentDataFlag}
              setSnackbarMessage={setSnackbarMessage}
              setCurrentDocumentComment={setCurrentDocumentComment}
              setCurrentDocumentYear={setCurrentDocumentYear}
              setCurrentDocumentFolder={setCurrentDocumentFolder}
              setCurrentDocumentIndex={setCurrentDocumentIndex}
              handleConfirmPopUpOpen={handleConfirmPopUpOpen}
              documentYear={5}
            />
          </div>
        </Box>

        <Divider sx={{ marginY: 6 }}></Divider>

        {/* External Services without invoices */}
        <Box marginTop={3} sx={{ display: "flex", flexDirection: "row" }}>
          <Stack marginTop={1} sx={{ minWidth: 220 }}>
            <Typography variant="h3">
              {t("fu_ind_section_subtitle_2")}
              <Tooltip
                sx={{ paddingLeft: 1 }}
                title={
                  <>
                    {t("fu_ind_info_box_1")}
                    <br />
                    <br />
                    {t("fu_ind_info_box_2")}
                  </>
                }
                placement="top-start"
              >
                <InfoIcon sx={{ fontSize: "medium", padding: "0px 0px 0px 8px" }} />
              </Tooltip>
            </Typography>
          </Stack>

          <div
            id="ScrollWrapper2"
            style={{
              display: "flex",
              flexDirection: "row",
              overflowX: "scroll",
            }}
          >
            {/* 2018 */}
            <YearlyServices
              setYearServices={setYear2018Services}
              yearServices={year2018Services}
              year={2018}
            />

            {/* 2019 */}
            <YearlyServices
              setYearServices={setYear2019Services}
              yearServices={year2019Services}
              year={2019}
            />

            {/* 2020 */}
            <YearlyServices
              setYearServices={setYear2020Services}
              yearServices={year2020Services}
              year={2020}
            />

            {/* 2021 */}
            <YearlyServices
              setYearServices={setYear2021Services}
              yearServices={year2021Services}
              year={2021}
            />

            {/* 2022 */}
            <YearlyServices
              setYearServices={setYear2022Services}
              yearServices={year2022Services}
              year={2022}
            />

            {/* 2023 */}
            <YearlyServices
              setYearServices={setYear2023Services}
              yearServices={year2023Services}
              year={2023}
            />
          </div>
          <ConfirmDialog
            onClose={() => setDialogOpen(false)}
            open={dialogOpen}
            textQuestion={t("generic_complete_fields")}
            textYes={t("generic_complete_no")}
            textNo={t("generic_complete_yes")}
            textTitle={t("generic_complete_title")}
            handleDelete={handleDeleteUnFilled}
          />
        </Box>

        <Box display="flex" flexDirection="row" justifyContent="end">
          <SubmitButton sx={{ marginTop: "100px", width: "150px" }} onClick={handleValidate}>
            {t("generic_save")}
          </SubmitButton>
        </Box>
      </Box>


      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Dialog
            PaperProps={{ sx: { maxWidth: "revert", width: "700px" } }}
            open={confirmPopUpOpen}
            onClose={handleConfirmPopUpClose}
            aria-labelledby="alert-dialog-confirm-popup"
            aria-describedby="alert-dialog-confirm-popup-description"
          >
            <CloseRoundedIcon
              sx={{
                alignSelf: "flex-end",
                padding: 2,
                color: "#707070",
                "&:hover": { cursor: "pointer" },
              }}
              onClick={() => {
                setCurrentDocumentComment("");
                setCurrentDocumentYear("");
                setCurrentDocumentFolder("");
                setCurrentDocumentIndex("");
                setConfirmPopUpOpen(false);
              }}
            />
            <DialogTitle id="alert-dialog-confirm-popup">
              <Typography
                paddingX={2}
                paddingBottom={2}
                fontWeight={800}
                fontSize={24}
              >
                {t("general_add_comment")}
              </Typography>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description" color="black">
                <Typography paddingX={2}>
                  {t("fu_comment_description")}
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogContent sx={{ marginX: 2 }}>
              <TextField
                id="verification-document-upload"
                multiline
                maxRows={4}
                sx={{ width: "100%" }}
                value={currentDocumentComment}
                placeholder={t("fu_comment_type_here")}
                onChange={(e) => setCurrentDocumentComment(e.target.value)}
              ></TextField>
            </DialogContent>
            <br />
            <DialogActions style={{ padding: "0px 40px 20px 20px" }}>
              <SubmitButton
                disableElevation
                disableRipple
                onClick={saveVerificationDocumentComment}
              >
                {t("generic_save")}
              </SubmitButton>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    </>
  );
};

export default Step2EnergyUsageComponent;
