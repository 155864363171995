import { GET_FARM_MONITORING_DETAILS, CREATE_FARM_MONITORING_DETAILS, UPDATE_FARM_MONITORING_DETAILS, DELETE_FARM_MONITORING_DETAILS, GET_FARM_MONITORING_DETAILS_BY_EMAIL, SET_FARM_MONITORING_DETAILS } from "./types";
import FarmMonitoringDetailsDataService from "../farmservices/FarmMonitoringDetailsDataService";

export const createFarmMonitoringDetails = (data) => async (dispatch) => {
    try {
        const res = await FarmMonitoringDetailsDataService.create(data);
        dispatch({
            type: CREATE_FARM_MONITORING_DETAILS,
            payload: res.data
        });
        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const getFarmMonitoringDetails = (email) => async (dispatch) => {
    try {
        const res = await FarmMonitoringDetailsDataService.get(email);
        dispatch({
            type: GET_FARM_MONITORING_DETAILS,
            payload: res.data,
        });
        return Promise.resolve(res);
    } catch (error) {
        console.log(error);
    }
};

export const updateFarmMonitoringDetails = (id, data) => async (dispatch) => {
    try {
        const res = await FarmMonitoringDetailsDataService.update(id, data);
        dispatch({
            type: UPDATE_FARM_MONITORING_DETAILS,
            payload: data,
        });
        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const getFarmMonitoringDetailsByEmail = (email) => async(dispatch) => {
    try {
        const res = await FarmMonitoringDetailsDataService.findByFarmerEmail(email);
        dispatch({
            type: GET_FARM_MONITORING_DETAILS_BY_EMAIL,
            payload: res.data,
        });
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const deleteFarmMonitoringDetails = (id) => async (dispatch) => {
    try {
        await FarmMonitoringDetailsDataService.remove(id);
        dispatch({
            type: DELETE_FARM_MONITORING_DETAILS,
            payload: {
                id
            },
        });
    } catch (error) {
        console.log(error);
    }
};

export const setFarmMonitoringDetails = (data) => async (dispatch) => {
    try {
        const res = dispatch({
            type: SET_FARM_MONITORING_DETAILS,
            payload: data,
        });
        return Promise.resolve(res);
    } catch (error) {
        console.log(error);
    }
};
