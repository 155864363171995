import HttpService from '../services/HttpService';

const findByFarmId = farmId => {
    return HttpService.getAxiosClient().get(`/api/carbonvalues/carbon?farmId=${farmId}`);
};

const CarbonResultsDataService = {
    findByFarmId
};

export default CarbonResultsDataService;
