import { addNewEmptyResidueToFallowData } from "../../../actions/currentMonitoringData";
import { tmpData } from "./tmpData";

export const addNewFallowResidue = (e, i, year, dispatch, parcelid) => {

    let funParam = {
        year: year,
        data: tmpData,
        parcelId: parcelid,
        fallowId: i
    }
    dispatch(addNewEmptyResidueToFallowData(funParam))
}