import HttpService from '../services/HttpService';

const getAll = () => {
    return HttpService.getAxiosClient().get("/api/farmlands/");
};

const get = id => {
    return HttpService.getAxiosClient().get(`/api/farmlands/${id}`);
};

const create = data => {
    return HttpService.getAxiosClient().post("/api/farmlands", data);
};

const update = (id, data) => {
    return HttpService.getAxiosClient().patch(`/api/farmlands/${id}`, data);
};

const remove = id => {
    return HttpService.getAxiosClient().delete(`/api/farmlands/${id}`);
};

const findByFarmerName = name => {
    return HttpService.getAxiosClient().get(`/api/farmlands?name=${name}`);
};

const findByFarmerEmail = param => {
    return HttpService.getAxiosClient().get(`/api/farmlands?farmInfo.email=${param}`);
};

const FarmlandDataService = {
    getAll,
    get,
    create,
    update,
    remove,
    findByFarmerName,
    findByFarmerEmail
};

export default FarmlandDataService;
