import { RETRIEVE_CARBON_VALUES_BY_FARM_ID } from "./types";
import CarbonResultsDataService from "../farmservices/CarbonResultsDataService";


export const findCarbonByFarmId = (farmId) => async(dispatch) => {
    try {
        const res = await CarbonResultsDataService.findByFarmId(farmId);
        dispatch({
            type: RETRIEVE_CARBON_VALUES_BY_FARM_ID,
            payload: res.data,
        });
        return res.data;
    } catch (error) {
        console.log(error);
    }
}