import { Trans, useTranslation } from "react-i18next";


import {
    Info as InfoIcon,
} from "@mui/icons-material";
import { Box, Collapse, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material';


import DropDownLabel from "../general/DropDownLabel";
import NumericInput from "../general/NumericInput";
import TrailingLine from "../general/TrailingLine";


import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { addNewEmptyAnnualCropData } from "../../actions/currentMonitoringData";
import { interdependentError } from "../general/Validations/fieldValueErrors";
import { maxDynamic } from "../general/Validations/minMaxValues";
import { addNewAnnualCropsHarvest } from "./addNewEmptySection/addNewAnnualCropsHarvest";
import { addNewAnnualCropsResidue } from "./addNewEmptySection/addNewAnnualCropsResidue";
import { emptyAnnualCropsObject } from "./addNewEmptySection/tmpData";
import { annualCropsArray, monthArray4 } from "./cropsArrays";
import { deleteAnnualCrop } from "./deleteSection";
import { handleAnnualCropsHarvestRowChange, handleAnnualCropsResidueRowChange, handleAnnualCropsRowChange } from "./handleRowChange";
import { getLastTwelveMonths } from "./utils";



const AnnualCropsSection = ({ year, tabValue, parcelArea }) => {

    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    let { parcelid } = useParams();

    const currentMonitoringDataObject = useSelector(state => state.currentMonitoringDataReducer);
    const currentFarmStatusReducer = useSelector(
        (state) => state.farmStatusReducer
    );

    const accountSettings = useSelector((state) => state.accountSettingsReducer);
    const isAdmin = accountSettings?.[0]?.role === 'admin'
    const allowEditing = currentFarmStatusReducer?.allowEditing || isAdmin


    const [arrAnnualCropsCollapsibleButtonStatus, setArrAnnualCropsCollapsibleButtonStatus] = useState(Array(100).fill(true))


    const toggleAnnualCropsCollapsibleState = (toggleIndex) => {
        setArrAnnualCropsCollapsibleButtonStatus(
            arrAnnualCropsCollapsibleButtonStatus.map((v, i) => (i === toggleIndex ? !v : v))
        );
    }

    const addNewAnnualCrop = (e) => {
        let tmpData = {
            year: year,
            data: emptyAnnualCropsObject,
            parcelId: parcelid
        };
        dispatch(addNewEmptyAnnualCropData(tmpData))
    };

    return (
        <Grid marginTop={2} >
            <Typography marginBottom={1} variant="h2">{t("pci_annual_crops_title_1")}{year}{t("pci_annual_crops_title_2")}</Typography>
            <Typography variant="subtitle2" sx={{ mb: 2 }}>
                <Trans i18nKey={"pci_annual_crops_subtitle"} values={{ year: year, yearAfter: Number(year) + 1 }}></Trans>
            </Typography>
            {currentMonitoringDataObject?.yearlyFarmData.find(yFD => yFD.year === year)?.parcelLevelData.find(eleParcelData => eleParcelData?.parcelFixedValues?.parcelID === parcelid) &&
                currentMonitoringDataObject?.yearlyFarmData.find(yFD => yFD.year === year)?.parcelLevelData.find(eleParcelData => eleParcelData?.parcelFixedValues?.parcelID === parcelid)?.yearParcelData?.annualCrops?.map((val, i) => {
                    return <React.Fragment key={i}>
                        {allowEditing && <Box display="flex" justifyContent="space-between" sx={{ width: 1 }} >
                            <DropDownLabel color={(val?.species ? 'black' : 'grey')} onClick={() => toggleAnnualCropsCollapsibleState(i)} status={arrAnnualCropsCollapsibleButtonStatus[i]} text={val.species ? t(val.species) : t("pci_annual_crops_label")} />
                            <p onClick={(e) => deleteAnnualCrop(e, i, year, dispatch, parcelid)} style={{ color: "#db6160", fontWeight: 'bold', marginRight: '20px', cursor: 'pointer' }}>{t("pci_annual_crops_delete")}</p>
                        </Box>}
                        <Collapse in={arrAnnualCropsCollapsibleButtonStatus[i]} timeout="auto"
                        >
                            <Grid item marginLeft={1} xs={12} md={12} lg={12}>
                                <Grid container marginTop={1} spacing={2}>
                                    <Grid item xs={2} md={2} lg={2}>
                                        <Typography variant="h3">{t("pci_annual_crops_desc")}
                                            <Tooltip title={<Trans i18nKey={"pci_annual_crops_info"}></Trans>}>
                                                <InfoIcon sx={{
                                                    fontSize: 'medium',
                                                    pl: 0.5,
                                                }} />
                                            </Tooltip>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3} md={3} lg={3}>
                                        <FormControl fullWidth>
                                            <InputLabel shrink={val?.species} size='small' id="annuals-crop-list-label">{t("pci_annual_crops_dd_list")}</InputLabel>
                                            <Select
                                                name="species"
                                                value={val?.species}
                                                size="small"
                                                labelId="annuals-crop-list-label"
                                                disabled={!allowEditing}
                                                id="annuals-crop-list-select"
                                                label="Annual crops list"
                                                onChange={(e) => handleAnnualCropsRowChange(e, i, year, dispatch, parcelid)}
                                            >
                                                {annualCropsArray.map((acrop) => { return <MenuItem key={acrop} value={acrop}>{t(acrop)}</MenuItem> })}

                                            </Select>
                                        </FormControl>

                                    </Grid>
                                    <Grid item xs={3} md={3} lg={3}>
                                        <FormControl fullWidth>
                                            <TextField
                                                error={val.species?.includes('other') && (!val.other || val.other === '-')}
                                                name="other"
                                                size="small"
                                                disabled={!String(val.species).includes("other") || !allowEditing}
                                                value={val.other ? val.other : ""} fullWidth placeholder={t("pci_annual_crops_specific_name")} label={t("pci_annual_crops_specific_name")} onChange={(e) => handleAnnualCropsRowChange(e, i, year, dispatch, parcelid)} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4} md={4} lg={4}>
                                    </Grid>
                                    <Grid item xs={2} md={2} lg={2}>
                                        <Typography variant="h3">{t("pci_annual_crops_crops_cycle")}
                                            <Tooltip title={<>{t("pci_annual_crops_crops_cycle_info")}<br /></>}>
                                                <InfoIcon sx={{
                                                    fontSize: 'medium',
                                                    pl: 0.5,
                                                }} />
                                            </Tooltip>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3} md={3} lg={3}>
                                        <FormControl fullWidth>
                                            <InputLabel shrink={val?.harvestDate} size='small' id="annuals-crop-harvest-label">{t("pci_annual_crops_harvest_month_dd")}</InputLabel>
                                            <Select
                                                error={interdependentError(val.harvestDate, [val.species])}
                                                name="harvestDate"
                                                value={val.harvestDate}
                                                disabled={!val.species || !allowEditing}
                                                size="small"
                                                labelId="annuals-crop-harvest-label"
                                                id="annuals-crop-harvest-select"
                                                label={t("pci_annual_crops_harvest_month_dd")}
                                                onChange={(e) => handleAnnualCropsRowChange(e, i, year, dispatch, parcelid)}
                                            >
                                                {monthArray4.map((item) => {
                                                    return <MenuItem
                                                        value={tabValue + item.value}
                                                        key={tabValue + item.value}>
                                                        {t(item.text)}{" "}{tabValue}
                                                    </MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>

                                    </Grid>
                                    <Grid item xs={3} md={3} lg={3}>
                                        <FormControl fullWidth>
                                            <InputLabel shrink={val?.sowingDate} size='small' id="annuals-crop-sowing-label">{t("pci_annual_crops_sowing_month_dd")}</InputLabel>
                                            <Select
                                                error={interdependentError(val.sowingDate, [val.species, val.harvestDate])}
                                                disabled={!allowEditing}
                                                name="sowingDate"
                                                size="small"
                                                value={val.sowingDate}
                                                labelId="annuals-crop-sowing-label"
                                                id="annuals-crop-sowing-select"
                                                label={t("pci_annual_crops_sowing_month_dd")}
                                                onChange={(e) => handleAnnualCropsRowChange(e, i, year, dispatch, parcelid)}
                                            >
                                                {
                                                    val.harvestDate && getLastTwelveMonths(val.harvestDate).map((sowDateValue, ix) =>
                                                        <MenuItem
                                                            value={String(sowDateValue).concat("-01")}
                                                            key={String(sowDateValue).concat("-01")}>
                                                            {i18n?.language === "en_EN" && new Date(sowDateValue).toLocaleDateString("en-US", { year: "numeric", month: "long" })}
                                                            {i18n?.language === "es_ES" && new Date(sowDateValue).toLocaleDateString("es-ES", { year: "numeric", month: "long" })[0].toUpperCase() + new Date(sowDateValue).toLocaleDateString("es-ES", { year: "numeric", month: "long" }).replace('de ', '').slice(1)}
                                                            {i18n?.language === "pt_PT" && new Date(sowDateValue).toLocaleDateString("pt-PT", { year: "numeric", month: "long" })[0].toUpperCase() + new Date(sowDateValue).toLocaleDateString("pt-PT", { year: "numeric", month: "long" }).replace('de ', '').slice(1)}
                                                        </MenuItem>
                                                    )
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4} md={4} lg={4}>
                                    </Grid>
                                    {val.harvest.map((hValue, j) => {
                                        return (<React.Fragment key={j}>
                                            {j !== 0 && <Grid item xs={2} md={2} lg={2}>
                                            </Grid>}
                                            {j === 0 && <Grid item xs={2} md={2} lg={2}>
                                                <div>
                                                    <Typography variant="h3">{t("pci_annual_crops_harvest_label")}
                                                        <Tooltip title={<Trans i18nKey={"pci_annual_crops_harvest_label_info"}></Trans>}>
                                                            <InfoIcon sx={{
                                                                fontSize: 'medium',
                                                                padding: '0px 0px 0px 8px',
                                                            }} />
                                                        </Tooltip>
                                                    </Typography>
                                                    <Typography variant="subtitle3">{t("pci_annual_crops_harvest_label_desc")}</Typography>
                                                </div>
                                            </Grid>}
                                            <Grid item xs={3} md={3} lg={3}>
                                                <FormControl fullWidth>
                                                    <InputLabel shrink={hValue?.type} size='small' id="annual-crops-harvest-type-label">{t("pci_annual_crops_harvest_type_dd")}</InputLabel>
                                                    <Select
                                                        name="type"
                                                        value={hValue.type}
                                                        disabled={!val.species || !allowEditing}
                                                        size="small"
                                                        labelId="annual-crops-harvest-type-label"
                                                        id="annual-crops-harvest-type-select"
                                                        label={t("pci_annual_crops_harvest_type_dd")}
                                                        onChange={(e) => handleAnnualCropsHarvestRowChange(e, i, j, year, dispatch, parcelid)}
                                                    >
                                                        <MenuItem
                                                            value="grains / cereals"
                                                            key="grains / cereals">
                                                            {t("pci_annual_crops_harvest_type_dd_1")}
                                                        </MenuItem>
                                                        <MenuItem
                                                            value="seeds"
                                                            key="seeds">
                                                            {t("pci_annual_crops_harvest_type_dd_2")}
                                                        </MenuItem>
                                                        <MenuItem
                                                            value="fruits / vegetables"
                                                            key="fruits / vegetables">
                                                            {t("pci_annual_crops_harvest_type_dd_3")}
                                                        </MenuItem>
                                                        <MenuItem
                                                            value="roots / tubers"
                                                            key="roots / tubers">
                                                            {t("pci_annual_crops_harvest_type_dd_4")}
                                                        </MenuItem>
                                                        <MenuItem
                                                            value="grass"
                                                            key="grass">
                                                            {t("pci_annual_crops_harvest_type_dd_5")}
                                                        </MenuItem>
                                                        <MenuItem
                                                            value="leaves, stalks, herbs (non-woody)"
                                                            key="leaves, stalks, herbs (non-woody)">
                                                            {t("pci_annual_crops_harvest_type_dd_6")}
                                                        </MenuItem>
                                                        <MenuItem
                                                            value="-"
                                                            key="-">
                                                            -
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={3} md={3} lg={3}>
                                                <FormControl fullWidth>
                                                    <NumericInput
                                                        error={interdependentError(hValue.amount, [hValue.type])}
                                                        max={maxDynamic.annualCropsharvestT * parcelArea}
                                                        decimalScale={3}
                                                        name="amount"
                                                        inputMode='decimal'
                                                        size="small"
                                                        disabled={hValue.type === "-" || !hValue.type}
                                                        value={hValue.amount !== -9999 ? hValue.amount : ''} fullWidth placeholder={t("pci_annual_crops_harvest_type_qty")} label={t("pci_annual_crops_harvest_type_qty")} onChange={(e) => handleAnnualCropsHarvestRowChange(e, i, j, year, dispatch, parcelid)} />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={2} md={2} lg={2}>
                                                <FormControl fullWidth>
                                                    <Select
                                                        name="units"
                                                        size="small"
                                                        error={interdependentError(hValue.units, [hValue.type])}
                                                        value={hValue.units}
                                                        disabled={hValue.type === "-" || !hValue.type || !allowEditing}
                                                        labelId="annual-crops-harvest-quantity-units-label"
                                                        id="annual-crops-harvest-quantity-units-select"
                                                        onChange={(e) => handleAnnualCropsHarvestRowChange(e, i, j, year, dispatch, parcelid)}
                                                    >
                                                        <MenuItem key="tonnes - dry" value="tonnes - dry">{t("pci_annual_crops_harvest_qty_dry")}</MenuItem>
                                                        <MenuItem key="tonnes - fresh" value="tonnes - fresh">{t("pci_annual_crops_harvest_qty_fresh")}</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={2} md={2} lg={2}></Grid>
                                        </React.Fragment>)
                                    })}
                                    <Grid item xs={2} md={2} lg={2}>

                                    </Grid>
                                    <Grid item marginBottom={2} style={{ paddingTop: 8 }} xs={2} md={2} lg={2}>
                                        {allowEditing && <span onClick={(e) => addNewAnnualCropsHarvest(e, i, year, dispatch, parcelid)} style={{ color: "#409781", fontWeight: 'bold', cursor: 'pointer', fontSize: 14 }}>+ {t("pci_annual_crops_harvest_add_new")}</span>}
                                    </Grid>
                                    <Grid item xs={8} md={8} lg={8}>

                                    </Grid>

                                    {val?.residue.map((rValue, j) => {
                                        return (<React.Fragment key={j}>
                                            {j !== 0 && <Grid item xs={2} md={2} lg={2}>
                                            </Grid>}
                                            {j === 0 && <Grid item xs={2} md={2} lg={2}>
                                                <div>
                                                    <Typography variant="h3">{t("pci_annual_crops_residue_label")}
                                                        <Tooltip title={<><br />{t("pci_annual_crops_residue_info_1")}<br /><br />{t("pci_annual_crops_residue_info_2")}<br /><br />{t("pci_annual_crops_residue_info_3")}<br /></>}>
                                                            <InfoIcon sx={{
                                                                fontSize: 'medium',
                                                                pl: 0.5,
                                                            }} />
                                                        </Tooltip>
                                                    </Typography>
                                                    <Typography variant="subtitle3">{t("pci_perennial_tree_list_residue_desc")}</Typography>
                                                </div>
                                            </Grid>}
                                            <Grid item xs={3} md={3} lg={3}>
                                                <FormControl fullWidth>
                                                    <InputLabel shrink={rValue?.type} size='small' id="annuals-crops-residue-type-label">{t("pci_annual_crops_residue_type_dd")}</InputLabel>
                                                    <Select
                                                        name="type"
                                                        size="small"
                                                        disabled={!val.species || !allowEditing}
                                                        value={rValue.type}
                                                        labelId="annuals-crops-residue-type-label"
                                                        id="annuals-crops-residue-type-select"
                                                        label={t("pci_annual_crops_residue_type_dd")}
                                                        onChange={(e) => handleAnnualCropsResidueRowChange(e, i, j, year, dispatch, parcelid)}
                                                    >
                                                        <MenuItem
                                                            value="grains / cereals"
                                                            key="grains / cereals">
                                                            {t("pci_annual_crops_residue_type_dd_1")}
                                                        </MenuItem>
                                                        <MenuItem
                                                            value="seeds"
                                                            key="seeds">
                                                            {t("pci_annual_crops_residue_type_dd_2")}
                                                        </MenuItem>
                                                        <MenuItem
                                                            value="fruits / vegetables"
                                                            key="fruits / vegetables">
                                                            {t("pci_annual_crops_residue_type_dd_3")}
                                                        </MenuItem>
                                                        <MenuItem
                                                            value="roots / tubers"
                                                            key="roots / tubers">
                                                            {t("pci_annual_crops_residue_type_dd_4")}
                                                        </MenuItem>
                                                        <MenuItem
                                                            value="grass"
                                                            key="grass">
                                                            {t("pci_annual_crops_residue_type_dd_5")}
                                                        </MenuItem>
                                                        <MenuItem
                                                            value="leaves, stalks, herbs (non-woody)"
                                                            key="leaves, stalks, herbs (non-woody)">
                                                            {t("pci_annual_crops_residue_type_dd_6")}
                                                        </MenuItem>
                                                        <MenuItem
                                                            value="-"
                                                            key="-">
                                                            -
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>

                                            </Grid>
                                            <Grid item xs={3} md={3} lg={3}>
                                                <FormControl fullWidth>
                                                    <NumericInput max={maxDynamic.residueT * parcelArea} decimalScale={3} name="amount"
                                                        error={interdependentError(rValue.amount, [rValue.type])}
                                                        inputMode='decimal'
                                                        size="small"
                                                        disabled={rValue.type === "-" || !rValue.type}
                                                        value={rValue.amount !== -9999 ? rValue.amount : ''} fullWidth placeholder={t("pci_annual_crops_residue_type_qty")} label={t("pci_annual_crops_residue_type_qty")} onChange={(e) => handleAnnualCropsResidueRowChange(e, i, j, year, dispatch, parcelid)} />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={2} md={2} lg={2}>
                                                <FormControl fullWidth>
                                                    <Select
                                                        name="units"
                                                        value={rValue.units}
                                                        error={interdependentError(rValue.units, [rValue.type])}
                                                        size="small"
                                                        disabled={rValue.type === "-" || !rValue.type || !allowEditing}
                                                        labelId="annuals-crops-residue-quantity-units-label"
                                                        id="annuals-crops-residue-quantity-units-select"
                                                        onChange={(e) => handleAnnualCropsResidueRowChange(e, i, j, year, dispatch, parcelid)}
                                                    >
                                                        <MenuItem key="tonnes - dry" value="tonnes - dry">{t("pci_annual_crops_residue_qty_dry")}</MenuItem>
                                                        <MenuItem key="tonnes - fresh" value="tonnes - fresh">{t("pci_annual_crops_residue_qty_fresh")}</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={2} md={2} lg={2}></Grid>
                                        </React.Fragment>)
                                    })}
                                    <Grid item xs={2} md={2} lg={2}>
                                    </Grid>
                                    <Grid item marginBottom={2} style={{ paddingTop: 8 }} xs={2} md={2} lg={2}>
                                        {allowEditing && <span onClick={(e) => addNewAnnualCropsResidue(e, i, year, dispatch, parcelid)} style={{ color: "#409781", fontWeight: 'bold', cursor: 'pointer', fontSize: 14 }}>+ {t("pci_annual_crops_residue_add_new")}</span>}
                                    </Grid>
                                    <Grid item xs={8} md={8} lg={8}></Grid>
                                </Grid>
                            </Grid>

                        </Collapse>
                        <TrailingLine />
                    </React.Fragment>
                }
                )
            }
            {allowEditing && <span onClick={addNewAnnualCrop} style={{ color: "#409781", fontWeight: 'bold', cursor: 'pointer', fontSize: 16 }}>+ {t("pci_annual_crops_add_new_row")}</span>}

        </Grid>
    )
}

export default AnnualCropsSection