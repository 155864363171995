import { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import produce from 'immer';
import _ from 'lodash';

import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { Backdrop, Box, Snackbar, Tab, Tabs, Typography } from '@mui/material';

import { useTheme } from '@mui/material/styles';
import SwipeableViews from 'react-swipeable-views';
import { updateMonitoringData } from '../../actions/monitoringData';
import { GoBackButton, SubmitButton } from '../../styles';
import TrailingLine from "../general/TrailingLine";


import ConfirmDialog from '../general/ConfirmDialog';
import CopyFromOtherYear from "../general/CopyFromOtherYear";
import { TabPanel, a11yProps } from '../general/TabPanel';
import AnnualCropsSection from './AnnualCropsSection';
import FallowSection from './FallowSection';
import PasturesSection from './PasturesSection';
import PerennialSection from './PerennialSection';
import { plantBiomassObject } from "./addNewEmptySection/tmpData";
import { handleAnnualCropsRowChange, handleFallowRowChange, handlePasturesRowChange, handlePerennialsRowChange } from "./handleRowChange";


const FarmerCropsPageComponent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let { parcelid } = useParams();
  const theme = useTheme();

  const currentMonitoringYearsObject = useSelector(state => state.monitoringYearsTrackerReducer);

  const currentMonitoringDataObject = useSelector(state => state.currentMonitoringDataReducer);

  const [tabPanelIndex, setTabPanelIndex] = useState(0);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [state, setState] = useState({
    snackBarOpen: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, snackBarOpen } = state;
  const [openCopyDataParcelsDialog, setOpenCopyDataParcelsDialog] = useState(false)
  const currentFarmStatusReducer = useSelector(
    (state) => state.farmStatusReducer
  );

  const accountSettings = useSelector((state) => state.accountSettingsReducer);
  const isAdmin = accountSettings?.[0]?.role === 'admin'
  const allowEditing = currentFarmStatusReducer?.allowEditing || isAdmin


  const [showLoader, setShowLoader] = useState(false);
  const [saveCurrentDataFlag, setSaveCurrentDataFlag] = useState(false);
  const [isFilled, setIsFilled] = useState([]);



  const handleBackdropClose = () => {
    setSaveCurrentDataFlag(false);
  };

  const handleBackdropClickCaptureEvent = (e) => {
    e.stopPropagation();
    return false;
  };


  const handleTabChange = (event, newValue) => {
    setTabPanelIndex(newValue);
    handleMonitoringDataSave(false);
  };

  const handleSnackbarClose = () => {
    setState({ vertical: "top", horizontal: "center", snackBarOpen: false });
  };

  const handleChangeIndex = (index) => {
    setTabPanelIndex(index);
  };
  const year = currentMonitoringYearsObject?.allMonitoringYears[tabPanelIndex]
  const yearlyFarmDataOb = currentMonitoringDataObject?.yearlyFarmData.find(ele => ele.year === year);


  const handleValidate = (event, newValue, path) => {

    if (newValue || newValue === 0) { handleTabChange(event, newValue) }
    else if (path) {
      savePageData()
      navigate(path)
    } else {
      savePageData()
    }
  }

  const handleDeleteUnFilled = () => {
    isFilled.forEach((segment, index) => {
      segment.reverse().forEach(value => {

        switch (index) {
          case 0:   //perennial
            handlePerennialsRowChange({ target: { value: '-', name: 'species' } }, value, year, dispatch, parcelid, yearlyFarmDataOb)

            break;
          case 1: //annual crops
            handleAnnualCropsRowChange({ target: { value: '-', name: 'species' } }, value, year, dispatch, parcelid)
            break;
          case 2:
            handlePasturesRowChange({
              target: {
                "value": '',
                "name": "percentArea"
              }
            }, value, year, dispatch, parcelid)
            break;
          case 3:
            handleFallowRowChange({
              target: {
                "value": '-',
                "name": "type"
              }
            }, value, year, dispatch, parcelid)

            break;
          default:
            break;
        }
      });
    });
    savePageData()
    setDialogOpen(false)
  }

  const savePageData = () => {
    handleMonitoringDataSave(true);
  }

  const handleMonitoringDataSave = (showSavingDataMessage) => {
    if (showSavingDataMessage) {
      setShowLoader(true);
      setSaveCurrentDataFlag(true);
    }
    var tmpMonitoringDataObject = _.cloneDeep(currentMonitoringDataObject);
    const cleanedMonitoringDataObject = produce(tmpMonitoringDataObject, draft => {
      draft?.yearlyFarmData?.map(obYear => {
        const obParcelData = obYear?.parcelLevelData?.find(ele => String(ele?.parcelFixedValues?.parcelID) === String(parcelid));
        if (obParcelData) {

          obParcelData.yearParcelData?.perennialCrops.map(el => {

            const perennialHarvestArray = el.harvest;
            const perennialResidueArray = el.residue;

            el.harvest = [];
            el.residue = [];

            perennialHarvestArray.map(newHarvest => {
              if (newHarvest?.date !== "-") {
                el?.harvest.push(newHarvest)
              }
              return newHarvest
            });

            perennialResidueArray.map(newResidue => {
              if (newResidue?.date !== "-") {
                el?.residue.push(newResidue)
              }
              return newResidue
            });

            if (el?.harvest?.length === 0) {
              el.harvest.push(plantBiomassObject);
            }
            if (el?.residue?.length === 0) {
              el?.residue.push(plantBiomassObject);
            }

          });

          obParcelData.yearParcelData?.annualCrops.map(el => {

            const annualHarvestArray = el?.harvest;
            const annualResidueArray = el?.residue;

            el.harvest = [];
            el.residue = [];

            annualHarvestArray.map(newHarvest => {
              if (newHarvest.type !== "-") {
                el.harvest.push(newHarvest)
              }
              return newHarvest
            });

            annualResidueArray.map(newResidue => {
              if (newResidue.type !== "-") {
                el.residue.push(newResidue)
              }
              return newResidue
            });

            if (el.harvest.length === 0) {
              el.harvest.push(plantBiomassObject);
            }
            if (el.residue.length === 0) {
              el.residue.push(plantBiomassObject);
            }
            return el
          });

          obParcelData.yearParcelData.pastures.map(el => {

            const pasturesHarvestArray = el.harvest;
            const pasturesProductivityArray = el.productivity;

            el.harvest = [];
            el.productivity = [];

            pasturesHarvestArray.map(newHarvest => {
              if (newHarvest.date !== "-") {
                el.harvest.push(newHarvest)
              }
              return newHarvest
            });

            pasturesProductivityArray.map(newProductivity => {
              if (newProductivity.date !== "-") {
                el.productivity.push(newProductivity)
              }
              return newProductivity
            });

            if (el.harvest.length === 0) {
              el.harvest.push(plantBiomassObject);
            }
            if (el.productivity.length === 0) {
              el.productivity.push(plantBiomassObject);
            }
            return el
          });

          obParcelData.yearParcelData.fallow.map(el => {

            const fallowHarvestArray = el.harvest;
            const fallowResidueArray = el.residue;

            el.harvest = [];
            el.residue = [];

            fallowHarvestArray.map(newHarvest => {
              if (newHarvest.type !== "-") {
                el.harvest.push(newHarvest)
              }
              return newHarvest
            });

            fallowResidueArray.map(newResidue => {
              if (newResidue.type !== "-") {
                el.residue.push(newResidue)
              }
              return newResidue
            });

            if (el.harvest.length === 0) {
              el.harvest.push(plantBiomassObject);
            }
            if (el.residue.length === 0) {
              el.residue.push(plantBiomassObject);
            }
            return el
          });

        }
        return obYear
      });
    });
    dispatch(updateMonitoringData(currentMonitoringDataObject._id, cleanedMonitoringDataObject))
      .then(res => {
        setSnackbarMessage(t("farmland_draft_submission_msg_success"));
        setState({ snackBarOpen: true, vertical: 'top', horizontal: 'center' });
        if (showSavingDataMessage) {
          setShowLoader(false);
          setSaveCurrentDataFlag(false);
        }
      }).catch((error) => {
        console.error('Error updating monitoring data:', error);
      });
  }



  const handleCopyDataDialog = () => {
    setOpenCopyDataParcelsDialog(true);
  }



  const parcelArea = currentMonitoringDataObject?.yearlyFarmData.find(yFD => yFD.year === currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]).parcelLevelData.find(eleParcelData => eleParcelData?.parcelFixedValues?.parcelID === parcelid)?.parcelFixedValues.areaGeoFile.area
  return (
    <>
      {showLoader && (
        <Backdrop
          onClickCapture={(e) => handleBackdropClickCaptureEvent(e)}
          style={{ color: "#fff", zIndex: 99999 }}
          open={saveCurrentDataFlag}
          onClick={handleBackdropClose}
        >
          <div style={{ fontSize: 20, fontWeight: 600, color: "white", marginTop: "20px" }}>
            {t("generic_saving_data")}
          </div>
        </Backdrop>
      )}


      <Box marginTop='80px' marginLeft={2.8} display='flex' alignItems='center'>
        <GoBackButton onClick={() => handleValidate('', '', '/carbonplus/landuse')}>
          <ArrowBackIosRoundedIcon />
          <Typography sx={{ fontWeight: 800, paddingLeft: 2 }}>{t("back_to_home")}</Typography>
        </GoBackButton>
      </Box>

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={snackBarOpen}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        key={vertical + horizontal}
      />

      <Box sx={{ flexGrow: 1, mx: 12, my: 6 }}>

        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ width: 1 }} >
          <Typography variant="h1">
            {t("pci_screen_title_1")} {currentMonitoringDataObject?.yearlyFarmData.find(yFD => yFD.year === currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]).parcelLevelData.find(eleParcelData => eleParcelData?.parcelFixedValues?.parcelID === parcelid)?.parcelFixedValues?.parcelName} | {t("pci_screen_title_2")}
          </Typography>
          <Typography variant='h2' style={{ color: '#409781', cursor: "pointer" }} onClick={() => handleValidate('', '', `/carbonplus/farmerlanduse/${parcelid}`)}>
            {t("pci_crops_info_btn")}
          </Typography>
        </Box>
        <Typography variant="subtitle2">
          {t("pci_subtitle")}
        </Typography>
        {allowEditing && <p style={{ cursor: "pointer", color: '#409781', fontWeight: 'bold', marginTop: '40px', marginBottom: '30px' }} onClick={handleCopyDataDialog}>{t("pci_copy_data")}</p>}
        <Tabs
          value={tabPanelIndex}
          onChange={handleValidate}
          forceRenderTabPanel={true}
          sx={{
            zIndex: 1,
            position: 'sticky',
            top: 64,
            '& .MuiTabs-indicator': {
              height: '5px'
            }
          }}
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          {
            currentMonitoringYearsObject.allMonitoringYears.map((val, i) => {
              let bgColor = '#e6e6e6';
              if (i > 2) { bgColor = '#808080' } return (<Tab sx={{ color: 'black', border: 2, borderColor: 'white', fontSize: 18, fontWeight: 600, backgroundColor: bgColor }} label={val} {...a11yProps({ i })} key={i} />)
            })
          }
        </Tabs>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={tabPanelIndex}
          onChangeIndex={handleChangeIndex}
        >
          {
            currentMonitoringYearsObject.allMonitoringYears.map((tabValue, tabIndex) => {
              return (
                <Box marginRight={4} key={tabIndex}>
                  <TabPanel forceRender={true} style={{ overflow: "auto" }} value={tabPanelIndex} index={tabIndex} dir={theme.direction}>

                    <PerennialSection parcelArea={parcelArea} year={year} tabPanelIndex={tabPanelIndex} tabValue={tabValue} yearParcel={currentMonitoringDataObject?.yearlyFarmData.find(yFD => yFD.year === currentMonitoringYearsObject.allMonitoringYears[tabPanelIndex]).parcelLevelData.find(eleParcelData => eleParcelData?.parcelFixedValues?.parcelID === parcelid)} />
                    <TrailingLine type='thick' />

                    <AnnualCropsSection parcelArea={parcelArea} year={year} tabValue={tabValue} />
                    <TrailingLine type='thick' />

                    <PasturesSection parcelArea={parcelArea} year={year} tabValue={tabValue} />
                    <TrailingLine type='thick' />

                    <FallowSection year={year} tabValue={tabValue} parcelArea={parcelArea} />

                  </TabPanel >
                </Box >
              )
            })
          }
          <ConfirmDialog onClose={() => setDialogOpen(false)} open={dialogOpen} textQuestion={t("generic_complete_fields")} textYes={t("generic_complete_no")} textNo={t("generic_complete_yes")} textTitle={t("generic_complete_title")} handleDelete={handleDeleteUnFilled} />
        </SwipeableViews >
        <Box display='flex' justifyContent='end'>
          <SubmitButton style={{ width: '280px' }} onClick={handleValidate} >{t("generic_save")}</SubmitButton>
        </Box>

        <CopyFromOtherYear page='crops' parcelid={parcelid} year={year} openCopyDataParcelsDialog={openCopyDataParcelsDialog} setOpenCopyDataParcelsDialog={setOpenCopyDataParcelsDialog} setShowLoader={setShowLoader} setSaveCurrentDataFlag={setSaveCurrentDataFlag} setSnackbarMessage={setSnackbarMessage} setState={setState} />
      </Box >
    </>
  )
}

export default FarmerCropsPageComponent
