export const interdependentError = (dependentField, relatedFieldsArray) => {
    const isError = (relatedFieldsArray?.some(value => value !== null && value !== undefined && value !== -9999 && value !== '' && value !== '-'))
        && (dependentField === null || dependentField === '' || dependentField === undefined || dependentField === -9999 || dependentField === '-')
    return isError
}

export const emptyNumericFieldError = (value) => {
    const isError = value === null || value === undefined || value === -9999 || value === '' || value === '-'
    return isError
}

export const emptyNumericFieldErrorZero = (value) => {
    const isError = value === null || value === undefined || value === -9999 || value === '' || value === '-' || value === 0
    return isError
}