import FarmlandDataService from "../farmservices/FarmlandDataService";
import { CREATE_FARMLAND, DELETE_FARMLAND, FIND_FARMLAND_BY_ID, RESET_FARMLANDS, RETRIEVE_FARMLANDS, UPDATE_FARMLAND } from "./types";

export const createFarmland = (data) => async (dispatch) => {
    try {

        const res = await FarmlandDataService.create(data);
        dispatch({
            type: CREATE_FARMLAND,
            payload: data

        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const retrieveFarmlands = () => async (dispatch) => {
    try {
        const res = await FarmlandDataService.getAll();
        dispatch({
            type: RETRIEVE_FARMLANDS,
            payload: res.data,
        });
        return Promise.resolve(res);
    } catch (error) {
        console.log(error);
    }
};

export const updateFarmland = (id, data) => async (dispatch) => {
    try {
        const res = await FarmlandDataService.update(id, data);
        dispatch({
            type: UPDATE_FARMLAND,
            payload: data,
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const findFarmlandById = (id) => async (dispatch) => {
    try {
        const res = await FarmlandDataService.get(id);
        dispatch({
            type: FIND_FARMLAND_BY_ID,
            payload: id,
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};


export const deleteFarmland = (id) => async (dispatch) => {
    try {
        await FarmlandDataService.remove(id);
        dispatch({
            type: DELETE_FARMLAND,
            payload: {
                id
            },
        });
    } catch (error) {
        console.log(error);
    }
};

export const findFarmlandByName = (farmerName) => async (dispatch) => {
    try {
        const res = await FarmlandDataService.findByFarmerName(farmerName);
        dispatch({
            type: RETRIEVE_FARMLANDS,
            payload: res.data,
        });
    } catch (error) {
        console.log(error);
    }
}

export const findFarmlandByEmail = (email) => async (dispatch) => {
    try {
        const res = await FarmlandDataService.findByFarmerEmail(email);
        dispatch({
            type: RETRIEVE_FARMLANDS,
            payload: res.data,
        });
        return res.data;
    } catch (error) {
        console.log(error);
    }
}

export const resetFarmlands = () => async (dispatch) => {
    try {
        const res = dispatch({
            type: RESET_FARMLANDS
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
}