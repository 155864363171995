import { deleteAnnualCropData, deleteFallowData } from "../../actions/currentMonitoringData";


export const deleteFallow = (e, arrIndex, year, dispatch, parcelid) => {
    let tmpData = {
        year: year,
        parcelId: parcelid,
        arrayIndex: arrIndex
    };
    dispatch(deleteFallowData(tmpData))
};

export const deleteAnnualCrop = (e, arrIndex, year, dispatch, parcelid) => {
    let tmpData = {
        year: year,
        parcelId: parcelid,
        arrayIndex: arrIndex
    };
    dispatch(deleteAnnualCropData(tmpData))
};

