
import { RETRIEVE_FARM_AND_FARM_SETTINGS } from "./types";
import FarmAndFarmSettingsDataService from "../farmservices/FarmAndFarmSettingsDataService";

export const retrieveFarmAndFarmSettings = () => async (dispatch) => {
    try {
        const res = await FarmAndFarmSettingsDataService.getAll();
        dispatch({
            type: RETRIEVE_FARM_AND_FARM_SETTINGS,
            payload: res.data,
        });
        return Promise.resolve(res);
    } catch (error) {
        console.log(error);
    }
};

