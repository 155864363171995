import { CREATE_FARM_TEMPLATE, RETRIEVE_FARM_TEMPLATES, RETRIEVE_FARM_TEMPLATE, UPDATE_FARM_TEMPLATE, DELETE_FARM_TEMPLATE } from "../actions/types";
const initialState = [];

function farmTemplatesReducer(farmTemplates = Array(), action) {
    const { type, payload } = action;
    switch (type) {
        case CREATE_FARM_TEMPLATE:
            return [...farmTemplates, payload];
        case RETRIEVE_FARM_TEMPLATES:
            return payload;
        case RETRIEVE_FARM_TEMPLATE:
            return payload;
        case UPDATE_FARM_TEMPLATE:
            return farmTemplates.map((farmTemplate) =>
                farmTemplate.tID === String(payload.tID)
                    ? payload : farmTemplate
            );
        case DELETE_FARM_TEMPLATE:
            return farmTemplates.filter((farmTemplate) => farmTemplate._id !== String(payload.id));
        default:
            return farmTemplates;
    }
};

export default farmTemplatesReducer;