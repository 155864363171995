import { CREATE_FARM_VERIFICATION_DOCUMENTS, GET_FARM_VERIFICATION_DOCUMENTS, GET_FARM_VERIFICATION_DOCUMENTS_BY_EMAIL, RESET_FARM_VERIFICATION_DOCUMENTS, SET_FARM_VERIFICATION_DOCUMENTS, UPDATE_FARM_VERIFICATION_DOCUMENTS } from "../actions/types";


const initialState = {
    farmPrimaryIdFromMongoose: '',
    email: '',
    landOwnership: [],
    kmlFiles: [],
    yearlyVerificationDocuments: [
        {
            year: 2018,
            capDocument: [],
            livestockTotals: [],
            ampGrazingCharts: [],
            fertilizerInvoices: [],
            dieselInvoices: [],
            petrolInvoices: [],
            externalServicesInvoices: [],
            seedsInvoices: [],
            importedOrganicAmendmentsInvoices: [],
            animalFodderInvoices: []
        },
        {
            year: 2019,
            capDocument: [],
            livestockTotals: [],
            ampGrazingCharts: [],
            fertilizerInvoices: [],
            dieselInvoices: [],
            petrolInvoices: [],
            externalServicesInvoices: [],
            seedsInvoices: [],
            importedOrganicAmendmentsInvoices: [],
            animalFodderInvoices: []
        },
        {
            year: 2020,
            capDocument: [],
            livestockTotals: [],
            ampGrazingCharts: [],
            fertilizerInvoices: [],
            dieselInvoices: [],
            petrolInvoices: [],
            externalServicesInvoices: [],
            seedsInvoices: [],
            importedOrganicAmendmentsInvoices: [],
            animalFodderInvoices: []
        },
        {
            year: 2021,
            capDocument: [],
            livestockTotals: [],
            ampGrazingCharts: [],
            fertilizerInvoices: [],
            dieselInvoices: [],
            petrolInvoices: [],
            externalServicesInvoices: [],
            seedsInvoices: [],
            importedOrganicAmendmentsInvoices: [],
            animalFodderInvoices: []
        },
        {
            year: 2022,
            capDocument: [],
            livestockTotals: [],
            ampGrazingCharts: [],
            fertilizerInvoices: [],
            dieselInvoices: [],
            petrolInvoices: [],
            externalServicesInvoices: [],
            seedsInvoices: [],
            importedOrganicAmendmentsInvoices: [],
            animalFodderInvoices: []
        },
        {
            year: 2023,
            capDocument: [],
            livestockTotals: [],
            ampGrazingCharts: [],
            fertilizerInvoices: [],
            dieselInvoices: [],
            petrolInvoices: [],
            externalServicesInvoices: [],
            seedsInvoices: [],
            importedOrganicAmendmentsInvoices: [],
            animalFodderInvoices: []
        },
    ]
};


function farmVerificationDocumentsReducer(farmVerificationDocuments = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case CREATE_FARM_VERIFICATION_DOCUMENTS:
            return { ...farmVerificationDocuments, payload };
        case SET_FARM_VERIFICATION_DOCUMENTS:
            farmVerificationDocuments = payload;
            return farmVerificationDocuments;
        case GET_FARM_VERIFICATION_DOCUMENTS:
            return payload;
        case GET_FARM_VERIFICATION_DOCUMENTS_BY_EMAIL:
            return payload;
        case UPDATE_FARM_VERIFICATION_DOCUMENTS:
            return {
                ...farmVerificationDocuments, ...payload
            };
        case RESET_FARM_VERIFICATION_DOCUMENTS:
            return initialState;
        default:
            return farmVerificationDocuments;
    }
};

export default farmVerificationDocumentsReducer;