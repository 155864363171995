import HttpService from '../services/HttpService';

const get = (email) => {
    return HttpService.getAxiosClient().get(`/api/accountsettings/${email}`);
};


const create = data => {
    return HttpService.getAxiosClient().post("/api/accountsettings", data);
};

const update = (id, data) => {
    return HttpService.getAxiosClient().patch(`/api/accountsettings/${id}`, data);
};

const remove = id => {
    return HttpService.getAxiosClient().delete(`/api/accountsettings/${id}`);
};



const FarmSettingsDataService = {
    get,
    create,
    update,
    remove,
};

export default FarmSettingsDataService;