export const getLastTwelveMonths = (date) => {
    let months = [];
    for (let i = 1; i <= 12; i++) {
        let d0 = new Date(date);
        let d = new Date(d0.getTime() - d0.getTimezoneOffset() * 60000)
        d.setMonth(d.getMonth() - i);

        months.push(d.toISOString().slice(0, 7));
    }
    return months;
}

export const getLastTwelveMonthsInclusive = (date) => {
    let months = [];
    for (let i = 0; i <= 12; i++) {
        let d0 = new Date(date);
        let d = new Date(d0.getTime() - d0.getTimezoneOffset() * 60000)
        d.setMonth(d.getMonth() - i);
        months.push(d.toISOString().slice(0, 7));
    }
    return months;
}