//T tonne, L liter, P percent


export const max = {
    month: 12,
    percent: 100,
    yearDays: 365,
    dieselL: 100000,
    petrolL: 100000,
    fertilizerT: 10000,
    fertility: 36,
    inFarmAmmount: 10000,
    outFarmAmmount: 10000,
    organicAmendments: 2000,
    animalFodderT: 5000,
    treeFelling: 10000,
    DBH: 300,
    treeNumber: 500000,
    residueT: 5000,
    harvestT: 5000,
    pasturesT: 10,
    rotationYear: 100000000000,
    coordinateN: 90,
    coordinateE: 180,
    farmSize: 10000000
}

export const maxDynamic = {
    fertilseT: 1000000000, // 6,
    organicAmendments: 1000000000, //20,
    animalFodderT: 1000000000, // 20,
    treeNumber: 1000000000, // 2000,
    harvestT: 1000000000, //20,
    residueT: 1000000000, // 20,
    annualCropsharvestT: 1000000000, // 100,

}

export const min = {
    coordinateN: -90,
    coordinateE: -180,
    rotationYear: 1,
}