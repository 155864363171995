import { Button, List, ListItemButton, Typography } from "@mui/material";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { Box } from "@mui/system";
import { useEffect, useState } from "react";

import { useUser } from "@clerk/clerk-react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { createFarmSettings, findSettingsByFarmerPrimaryId, updateFarmSettings } from "../actions/farmSettings";
import { setFarmStatus } from '../actions/farmStatus';
import { createFarmland, findFarmlandByEmail, updateFarmland } from '../actions/farmlands';
import { angleRight } from "../assets/svgs";
import {
  FARM_DATA_IS_YET_TO_BE_SUBMITTED,
  SUBMITTED_FOR_REVIEW
} from '../constants/constants';
import MailServerDataService from '../mailservices/MailServerDataService';
import TermsAndConditions from "./PopUps/TermsAndConditions";
import ThanksForSubmit from './PopUps/ThanksForSubmit';
import SubmitValidation from './general/Validations/SubmitValidation';

const NavigationComponent = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const email = user?.emailAddresses[0]?.emailAddress;
  const currentFarmStatusReducer = useSelector(state => state.farmStatusReducer);
  const currentFarmDataObject = useSelector(state => state.currentFarmDataReducer);

  const currentMonitoringDataObject = useSelector(state => state.currentMonitoringDataReducer);
  const currentFarmMonitoringDetails = useSelector(state => state.farmMonitoringDetailsReducer);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [indexOnHover, setIndexOnHover] = useState(-1);

  const [startYear, setStartYear] = useState(true);

  const LinearProgressBar = styled(LinearProgress)(() => ({
    height: 10,
    width: '100%',
    borderRadius: 4,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#DBDBDB',
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: '4px 0px 0px 4px',
      backgroundColor: '#80C3AB',
    },
  }));

  const stepsFirstMessage = () => {
    switch (currentFarmStatusReducer?.status) {
      case FARM_DATA_IS_YET_TO_BE_SUBMITTED:
        return t("nc_text_1_not_submitted");
      case SUBMITTED_FOR_REVIEW:
        return t("submitted_for_review")
      default:
        return '';
    };
  };

  const stepsSecondtMessage = () => {
    switch (currentFarmStatusReducer?.status) {
      case FARM_DATA_IS_YET_TO_BE_SUBMITTED:
        return t("nc_text_2_not_submitted");
      case SUBMITTED_FOR_REVIEW:
        return t("nc_text_bottom_under_review")
      default:
        return '';
    };
  };

  const [showLoader, setShowLoader] = useState(false);
  const [confirmPopUpOpen, setConfirmPopUpOpen] = useState(false);
  const [termsPopUpOpen, setTermsPopUpOpen] = useState(false);

  const [state, setState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [saveCurrentDataFlag, setSaveCurrentDataFlag] = useState(false);

  const fetchFarmStatus = () => {
    dispatch(findSettingsByFarmerPrimaryId(currentFarmDataObject._id))
      .then((res) => {
        let editMyFarm = false;
        if (res[0]?.allowEditing) {
          editMyFarm = true;
        }
        const tmpFarmStatusData = {
          status: res[0]?.farmStatus,
          allowEditing: editMyFarm,
          editFarm: editMyFarm,
        };
        return dispatch(setFarmStatus(tmpFarmStatusData));
      })
      .then((res) => {
        console.log('Farm status set successfully.', res);
      })
      .catch((error) => {
        console.error('Error setting farm status:', error);
      });
  };

  useEffect(() => {
    fetchFarmStatus();
  }, []);

  useEffect(() => {
    if (currentMonitoringDataObject.projectStartYear === "" || currentFarmMonitoringDetails.startYear === "") {
      setStartYear(true)
    } else { setStartYear(false) };
  }, [currentMonitoringDataObject.projectStartYear, currentFarmMonitoringDetails.startYear]);


  const handleFarmDataFinalSubmission = () => {
    setSaveCurrentDataFlag(true);
    setShowLoader(true);
    dispatch(findFarmlandByEmail(email))
      .then((res) => {
        if (res) {
          const farmId = res[0]._id;
          return dispatch(findSettingsByFarmerPrimaryId(farmId))
            .then((settings) => {
              if (settings) {
                return dispatch(updateFarmland(settings[0].farmPrimaryIdFromMongoose, currentFarmDataObject))
                  .then((farm) => {
                    let newFarmStatusAndFarmData = {
                      currentFarmStatus: null,
                      farmData: farm.data
                    };

                    if (farm?.data?._id) {
                      //necessary for updating farm during non farm data is yet to be submitted status.
                      let farmSettingsObject = {
                        farmStatus: settings[0].farmStatus,
                        farmPrimaryIdFromMongoose: farm.data._id,
                        farmCreatedByUserId: '',
                        allowEditing: false
                      };
                      //only change the state if the farmer is in the data collection state
                      if (settings[0].farmStatus === FARM_DATA_IS_YET_TO_BE_SUBMITTED) {
                        farmSettingsObject = {
                          farmStatus: SUBMITTED_FOR_REVIEW,
                          farmPrimaryIdFromMongoose: farm.data._id,
                          farmCreatedByUserId: '',
                          allowEditing: false
                        };
                      }

                      return dispatch(updateFarmSettings(settings[0]._id, farmSettingsObject))
                        .then((fsRes) => {
                          newFarmStatusAndFarmData.currentFarmStatus = fsRes.data;
                          setSnackbarMessage(t("farmland_final_submission_msg_success"));
                          setState({ open: true, vertical: 'top', horizontal: 'center' });

                          navigate('/carbonplus');
                          setSaveCurrentDataFlag(false);
                          setShowLoader(false);
                        });
                    }
                  })
                  .catch((e) => {
                    setSnackbarMessage(t("farmland_final_submission_msg_failure"));
                    setState({ open: true, vertical: 'top', horizontal: 'center' });
                    setSaveCurrentDataFlag(false);
                    setShowLoader(false);
                  });
              } else {
                return dispatch(createFarmland(currentFarmDataObject))
                  .then((res) => {
                    if (res?.data?._id) {
                      let farmSettingsObject = {
                        farmStatus: SUBMITTED_FOR_REVIEW,
                        farmPrimaryIdFromMongoose: res.data._id,
                        farmCreatedByUserId: '',
                        allowEditing: false
                      };
                      return dispatch(createFarmSettings(farmSettingsObject))
                        .then(() => {
                          setSnackbarMessage(t("farmland_final_submission_msg_success"));
                          setState({ open: true, vertical: 'top', horizontal: 'center' });
                          MailServerDataService.sendMail({
                            subject: t("email_subject_farm_final_submission"),
                            body: t("email_body_farmland_final_submission"),
                            to: email
                          });
                          navigate('/carbonplus');
                          setSaveCurrentDataFlag(false);
                          setShowLoader(false);
                        });
                    }
                  })
                  .catch((e) => {
                    setSnackbarMessage(t("farmland_final_submission_msg_failure"));
                    setState({ open: true, vertical: 'top', horizontal: 'center' });
                    setSaveCurrentDataFlag(false);
                    setShowLoader(false);
                  });
              }
            });
        }
      })
      .catch((error) => {
        console.error('Error finding farmland by email:', error);
      });

  };

  const sendNotificationToFLM = () => {
    MailServerDataService.sendMail({
      subject: "Farmer submitted verification documents",
      body: `${currentFarmDataObject.farmInfo.email} has submitted some documents for review.`,
      to: process.env.REACT_APP_NODE_ENV === 'production' ? "maria@climatefarmers.org,iris@climatefarmers.org,michelle@climatefarmers.org" : '',
      farmerEmail: email,
      farmName: currentFarmDataObject.farmInfo.farmName,
      farmerName: `${currentFarmDataObject.farmInfo.farmManagerFirstName} ${currentFarmDataObject.farmInfo.farmManagerLastName}`
    });
  };


  const handleSubmitData = () => {
    handleFarmDataFinalSubmission()
    sendNotificationToFLM()
    setConfirmPopUpOpen(true);
  };

  const navigationList = [
    { page: "farminfo", text1: "nc_farminfo_step_regular", text2: "nc_farminfo_step_bold", path: "/carbonplus/farm", infoText: '' },
    { page: "ver_doc", text1: "nc_ver_doc_step_regular", text2: "nc_ver_doc_step_bold", path: "/carbonplus/verificationdocuments", infoText: '' },
    { page: "fuel", text1: "nc_fuel_step_regular", text2: "nc_fuel_step_bold", path: "/carbonplus/fuel/direct", infoText: 'validation_fuel' },
    { page: "livestock", text1: "nc_livestock_step_regular", text2: "nc_livestock_step_bold", path: "/carbonplus/livestock", infoText: 'validation_livestock' },
    { page: "landuse", text1: "nc_landuse_step_regular", text2: "nc_landuse_step_bold", path: "/carbonplus/landuse", infoText: 'validation_map' }
  ]

  const [valid, setValid] = useState({ farminfo: false, ver_doc: false, fuel: false, livestock: false, landuse: false })

  const allTrue = Object.values(valid)?.every(value => value === true);
  const trueCount = Object.values(valid).filter(value => value === true).length;

  return (
    <>
      <Box sx={{
        border: 1,
        borderColor: '#DBDBDB',
        borderRadius: '12px',
        display: 'flex',
        flexDirection: 'column',
        padding: '1.5rem',
      }}>
        <Typography variant='h2'>
          {stepsFirstMessage()}
        </Typography>
        <Typography variant='subtitle2' >
          {stepsSecondtMessage()}
        </Typography>
        <Box style={{ padding: '16px , 0', margin: '8px 0' }}  >
          <LinearProgressBar variant="determinate" value={trueCount * 20} />
        </Box>
        <List style={{ paddingBottom: 15 }}>
          {navigationList.map((ele, index) => {
            return <ListItemButton
              key={index}
              disabled={startYear && ele.page !== 'farminfo'}
              component={Link}
              to={ele.path}
              id={ele.page}
              onMouseEnter={() => setIndexOnHover(index)}
              onMouseLeave={() => setIndexOnHover(-1)}
              sx={{
                height: 56,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{
                  width: 30,
                  display: 'flex',
                  alignItems: 'center',
                  paddingRight: 17
                }}><SubmitValidation page={ele.page} valid={valid[ele.page]} setValid={setValid} infoText={ele.infoText} /></div>
                <p style={{ fontFamily: 'Montserrat' }}>{t(ele.text1)}{' '}<span style={{ fontWeight: 600 }}>{t(ele.text2)}</span></p>,
              </div>
              {indexOnHover === index &&
                <div style={{
                  width: 14,
                  display: 'flex',
                  alignItems: 'center',
                  paddingRight: 17,
                }}>
                  {angleRight}
                </div>
              }
            </ListItemButton>
          })
          }
        </List>

        <Button
          disableRipple
          disabled={!allTrue || !currentFarmStatusReducer.allowEditing}
          sx={{
            width: '90%',
            alignSelf: 'center',
            margin: '8px 18px 15px 18px',
            backgroundColor: '#409781',
            color: '#ffff',
            fontWeight: 600,
            fontSize: 16,
            textTransform: 'none',
            border: 1,
            borderColor: '#979797',
            borderRadius: '8px',

            '&:hover': {
              backgroundColor: '#80C3AB',
            }
          }}
          onClick={handleSubmitData}
        >{t("home_ver_doc_submit")}</Button>
        <Typography variant='subtitle3' >{t('home_project_terms_agree')} <span style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline', lineHeight: 2 }} onClick={() => setTermsPopUpOpen(true)}>{t("home_project_terms")}</span></Typography>


        <ThanksForSubmit setConfirmPopUpOpen={setConfirmPopUpOpen} confirmPopUpOpen={confirmPopUpOpen} />
        <TermsAndConditions setConfirmPopUpOpen={setTermsPopUpOpen} confirmPopUpOpen={termsPopUpOpen} />

      </Box>
    </>
  )
}

export default NavigationComponent;
