import { Box, Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import { SubmitButton } from "../../styles";

const ConfirmDialog = ({ onClose, open, textQuestion, handleDelete, textYes, textNo, textTitle }) => {

    return (<Dialog
        PaperProps={{ sx: { width: '400px' } }}
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-confirm-popup"
        aria-describedby="alert-dialog-confirm-popup-description"
    >
        <DialogTitle id="alert-delete-map-dialog-confirm-popup">
            <Box display="flex" justifyContent="space-between" >
                <Typography variant="h2">{textTitle}</Typography>
                <p onClick={onClose} style={{ marginRight: "3px", fontWeight: "bold", cursor: "pointer" }} >X</p>
            </Box>
        </DialogTitle>
        <DialogContent>
            <Typography variant="subtitle2">
                {textQuestion}
            </Typography>
            <SubmitButton fullWidth sx={{ my: 2 }} disableElevation disableRipple onClick={onClose} >{textNo}</SubmitButton>
            <SubmitButton sx={{
                bgcolor: "white",
                color: "#db6160",
                borderColor: "#db6160",
                border: 2,
                "&:hover": { backgroundColor: "white" },
            }} fullWidth disableElevation disableRipple onClick={handleDelete} type='error' > {textYes}</SubmitButton>
        </DialogContent >
    </Dialog >
    )
}

export default ConfirmDialog